import React from 'react';

//http
import axios from 'axios';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//confirm modal
import { confirmAlert } from 'react-confirm-alert';


import _ from 'lodash';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';


class CloneProjectRenderer extends React.Component {

  componentDidMount() {
    if(this.props.data) {
        this.mobxState.originalProjectId = this.props.data.id
    }
  }

  componentDidUpdate() {
    //wait until button is loaded to assign a project_id
    if(this.props.data) {
        this.mobxState.originalProjectId = this.props.data.id
    }
  }

  mobxState = {
    newProjectName: null,
    newProjectId: null,
    originalProjectId: null, //value is given in lifecycle methods from grid props once props are available
    isLoading: false, //to indicate cloning process is underway
    hasBids: null,
    hasItems: null,
    projectCreated: false,
  };

  //used to toggle "Cloning Project" caption and SpinLoader Icon in parent grid header
  onClone = (val) => this.props.frameworkComponentWrapper.agGridReact.props.onProjectClone(val)

  handleClick = () => {
    confirmAlert({
      message: 'Clone project and all related data?',
      buttons: [
        {
          label: 'Yes',
          onClick: _.debounce(async () => {
            await this.checkBids();
            this.mobxState.isLoading = true;
            await this.handleClone();
          })
        },
        {
          label: 'No',
          onClick: () => console.log('Cancel Cloning')
        }
      ]
    });
  };

  handleClone = async () => {
    this.onClone(true);
    //hit project clone endpoint
    await axios
      .post(`/v1/projects/clone_project/${this.mobxState.originalProjectId}`)
      .then(result => {
        console.log(result);
        this.mobxState.newProjectId = result.data.newProjectId
        this.mobxState.projectCreated = true;
      })
      .catch(err => {
        console.log(err);
        this.onClone(false)
      });
    //get new project_id
    if(this.mobxState.projectCreated === true){

        if(this.mobxState.newProjectId !== null || undefined){
          //newly created project_id to clone into
          let paramsToSend = { clone_into_project_id: this.mobxState.newProjectId };
          console.log(paramsToSend)
          //create scenarios
          await axios
            .post('/v1/projects/' + this.mobxState.originalProjectId + '/scenarios/clone_all', paramsToSend)
            .then(result => {
              console.log('Project scenarios cloned with result', result);
            })
            .catch(err => {
              console.log(err);
              this.onClone(false);
            });

          //create custom_fields in new row
          await axios
            .post(`/v1/projects/${this.mobxState.originalProjectId}/project_field_schemas/clone_new`, paramsToSend)
            .then(result => {
              console.log('Cloning Custom Fields ', result);
            })
            .catch(err => {
              console.log(err);
              this.onClone(false);
            });

          //create suppliers
          await axios
            .post(`/v1/projects/${this.mobxState.originalProjectId}/project_suppliers/clone_all`, paramsToSend)
            .then(result => {
              console.log('Suppliers Cloned', result);
            })
            .catch(err => {
              console.log(err);
              this.onClone(false);
            });
          //create freight_brackets and freight_bids
          //will create freight_brackets and any freight_bids that belong if they exist
  				await axios
  					.post(`/v1/projects/${this.mobxState.originalProjectId}/freight_brackets/clone_all`, paramsToSend)
  					.then(result => {
  						console.log("Freight Brackets/ Freight Bids ", result)
  						this.mobxState.submitMessage = true;
  					})
  					.catch((error) => {
  						console.log(error);
              this.onClone(false);
  					})

          await this.itemAndBidSubmit(this.mobxState.newProjectId)

          await this.clearState();
        }
    }
  };

  checkBids = async () => {
    //see if project has bids and or items
    await axios.get(`/v1/projects/${this.mobxState.originalProjectId}/bids`).then(bidResult => {
      if (bidResult.length === 0 || bidResult === null) {
        this.mobxState.hasBids = false;
        console.log('No bids');
      } else {
        this.mobxState.hasBids = true;
        console.log('Has Bids');
      }
    });

    await axios.get(`/v1/projects/${this.mobxState.originalProjectId}/items`).then(itemResult => {
      if (itemResult.length === 0 || itemResult === null) {
        this.mobxState.hasItems = false;
        console.log('No items');
      } else {
        this.mobxState.hasItems = true;
        console.log('Has Items');
      }
    });
  };

  itemAndBidSubmit = async (newId) => {
    //newly created project_id to clone into
    let newParams = { clone_into_project_id: newId};
    if (this.mobxState.hasBids === false) {
      await axios
        .post(`/v1/projects/${this.mobxState.originalProjectId}/items/clone_all`, newParams)
        .then(result => {
          console.log('Items Cloned ', result);
          this.mobxState.isLoading = false;
          this.props.agGridReact.props.fetchData();
          this.onClone(false);
        })
        .catch(err => {
          console.log(err);
          this.onClone(false);
        });
    } else if(this.mobxState.hasBids === true) {
      axios
        .post(`/v1/projects/${this.mobxState.originalProjectId}/bids/clone_all`, newParams)
        .then(result => {
          console.log('Bids Cloned ', result);
          this.mobxState.isLoading = false;
          this.onClone(false);
          this.props.agGridReact.props.fetchData();
        })
        .catch(err => {
          console.log(err);
          this.onClone(false);
        });
    } else if(this.mobxState.hasBids && this.mobxState.hasItems === false) {
      this.mobxState.isLoading = false;
      this.props.agGridReact.props.fetchData();
      this.onClone(false);
    }
  };

  clearState = () => {
    this.mobxState.newProjectName = null;
    this.mobxState.newProjectId = null;
    this.mobxStateOriginalProjectId = null;
    this.mobxState.hasBids = null;
  }

  render() {
    return (
      <div>
        <span className="link_style">
          <a className="link_style">
            <FontAwesomeIcon
              icon={['fal', 'copy']}
              size='2x'
              style={{ marginLeft: '15%', marginTop: '8px' }}
              onClick={this.handleClick}
              title={`Copy Project ${this.mobxState.originalProjectId}`}
            />
          </a>
        </span>

        <span>
          <Link to={`/sourcing/projects/${this.mobxState.originalProjectId}/edit`} className="link_style">
            <FontAwesomeIcon
              icon={['fal', 'pencil']}
              size='2x'
              style={{ marginLeft: '13%', marginTop: '8px' }}
              // onClick={() => {console.log("Clicked")}}
              title={`Edit Project ${this.mobxState.originalProjectId}`}
            />
          </Link>
        </span>
      </div>
    );
  }
}

decorate(CloneProjectRenderer, {
  mobxState: observable
});

export default observer(CloneProjectRenderer);

// export default CloneProjectRenderer;
