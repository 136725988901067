import React from 'react';
import { render } from 'react-dom';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

const _ = require('lodash'); //extra utilities

// Props
// - Steps: [jsx-elements]
// Shows one step at a time and provides a method of transitioning between them
class Wizard extends React.Component {
  mobxState = {
    currentStep: 0
  };
  // Getters for the current state of component
  canStepToStep = stepNumber => {
    let availableSteps = this.props.steps.length;
    return 0 <= stepNumber && stepNumber < availableSteps;
  };
  canStepForward = () => this.canStepToStep(this.mobxState.currentStep + 1);
  canStepBack = () => this.canStepToStep(this.mobxState.currentStep - 1);
  stepForward = () => this.setStep(this.mobxState.currentStep + 1);
  stepBack = () => this.setStep(this.mobxState.currentStep - 1);
  setStep = stepNumber => {
    if (this.canStepToStep(stepNumber)) {
      this.mobxState.currentStep = stepNumber;
    } else {
      throw new Error('Invalid Step Number');
    }
  };
  render() {
    // Inject attributes for the child components
    let steps = this.props.steps.map(component => {
      return React.cloneElement(component, {
        stepForward: this.stepForward,
        stepBack: this.stepBack,
        canStepForward: this.canStepForward(),
        canStepBack: this.canStepBack(),
        stepNumber: this.mobxState.currentStep,
        totalSteps: this.props.steps.length
      });
    });
    return (
      <React.Fragment>
        {steps[this.mobxState.currentStep]}
      </React.Fragment>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Wizard, {
  mobxState: observable
});

export default observer(Wizard);
