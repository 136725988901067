import React from 'react';
import { render } from 'react-dom';
import ReactDOM from 'react-dom';
//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, extractToken } from '../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//inputs for forms
import ItemCustomFieldInput from '../shared/ItemCustomFieldInput';
import BidPriceInput from '../shared/BidPriceInput';
import BidRoundInput from '../shared/BidRoundInput';
import ProjectSupplierSelect from '../shared/ProjectSupplierSelect';
import FreightBracketIdSelect from '../shared/FreightBracketIdSelect';

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

//imports for custom fields form
import { Form, Input, Button } from 'antd';
import NumericInput from 'react-numeric-input';
import { element } from 'prop-types';

//file importation
import SpreadSheetImport from '../FileImportation/SpreadSheetImport';

import { uniq, union } from 'lodash';
//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class CreateNewFreightBidForm extends React.Component {

  projectId = this.props.projectId;
  // scenarioId = extractToken(window.location.pathname, '/', 5);

  //state
  mobxState = {
    formState: {
      itemCustomField: null, // ItemCustomFieldInput value
      freightBidPrice: null, // BidPriceInput value
      freightBidRound: null, // FreightBidRoundInput value
      selectedFreightBracket: null, //FreightBracketSelect value
      selectedSupplierId: null, // ProjectSupplierSelect value
      custom_fields: {}, //holds data from custom_fields
      custom_fields_submit: {},//holds custom field data as well as null values for empty input fields
      formValid: false,
      showHelperIcons: false,
      sortOptions: [{ id: 'display_order', desc: true }],
      submitMessage: false
    },
    freightBidFieldAliases: {},
    customForm: {
      //holds data for dynamically generated form
      response_data: {}
    },
    customOptions: [],
    cardData: {},
    tableData: {
      new_freight_bids: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    submitMessage: false //controls message that confirms constraint was saved
  };

  componentDidMount() {
    this.fetchData();
  }

  //Form submission
  handleFormSubmit = event => {

    //sort through custom fields to add null values to empty input fields
    let filledValues = []; //values for filled of custom_fields inputs
    let originalValues = []; //complete list of custom_fields from database

    //holds values for filled of custom_fields inputs
    Object.entries(this.mobxState.formState.custom_fields).forEach((item) => {
      filledValues.push([item[0],item[1]])
    })
    //complete list of custom_fields from database with added value of null
    Object.entries(this.mobxState.customForm.response_data).forEach((item) => {
      originalValues.push( [item[0], item = null] )
    })

    let filledArr = originalValues.concat(filledValues)
    let filteredArr = union(filledArr) //remove duplicates
    this.mobxState.formState.custom_fields_submit = Object.fromEntries(filteredArr)


    axios
      .post(`/v1/projects/${this.projectId}/freight_bids`, {
        item_custom_field: this.mobxState.formState.itemCustomField,
        price: this.mobxState.formState.freightBidPrice,
        freight_bracket_id: this.mobxState.formState.selectedFreightBracket.value,
        custom_fields: this.mobxState.formState.custom_fields_submit,
        supplier_id: this.mobxState.formState.selectedSupplierId.value,
        round: this.mobxState.formState.freightBidRound,
        project_id: this.projectId,
      })
      .then(() => {
        this.mobxState.submitMessage = true;
        this.mobxState.formState.showHelperIcons = false;
        this.props.newFreightBidFetchData(); //calls parent to refresh the grid
        setTimeout(() => { this.mobxState.submitMessage = false; }, 7000);
      });

  };

  //HTTP request for data to populate form
  fetchData = () => {
    //Dynamic Form
    axios
      .get(`/microservices/v1/projects/${this.projectId}/freight/freight_bid_custom_form`, { params: {} })
      .then(response => {
        response.data.custom_fields_list !== null || undefined ?
        this.mobxState.customForm.response_data = response.data.custom_fields_list :
        response.data.custom_fields_list =  {data: "No Data"}
        console.log(response.data.custom_fields_list)
      })
      .catch(error => {
        console.log(error);
      });

    //system field aliases
    axios
      .get('/v1/custom_schemas', { params: { name: 'system_aliases' } }) //query the project custom field schema for all projects
      .then(response => {
        let fieldMap = {};
        //get array of freight bid system field labels from API and map it over into key value pair object of field_name:field_alias
        response.data.custom_schemas[0].schema_definition.freight_bid.map(obj => {
          let newObj = {[obj.field_name]: obj.field_alias};
          fieldMap = {...newObj, ...fieldMap};
        });
        //supplier aliases
        response.data.custom_schemas[0].schema_definition.supplier.map(obj => {
          let prependedFieldName = 'supplier__' + obj.field_name;
          let newObj = {[prependedFieldName]: obj.field_alias};
          fieldMap = {...newObj, ...fieldMap};
        });
        //bracket aliases
        response.data.custom_schemas[0].schema_definition.freight_bracket.map(obj => {
          let prependedFieldName = 'freight_bracket__' + obj.field_name;
          let newObj = {[prependedFieldName]: obj.field_alias};
          fieldMap = {...newObj, ...fieldMap};
        });
        this.mobxState.freightBidFieldAliases = fieldMap;
        console.log('system fields', fieldMap);
      })
      .catch(console.log.bind(console)); //error catcher
  };

  //react-select form state handlers
  handleFormChange = (field, value) => {
    console.log('handleFormChange with params:', field, value);
    this.mobxState.formState[field] = value;
    //call validationResult
    this.validateForm();
  };
  handleCustomFormChange = (field, value) => {
    this.mobxState.formState.custom_fields[field] = value;
    //call validate form
    this.validateForm();
  };

  validateForm = () => {
    //For now all custom fields do not need to be filled out
    let formConditionsMet =
      this.mobxState.formState.itemCustomField !== null &&
      this.mobxState.formState.freightBidPrice !== null &&
      this.mobxState.formState.freightBidRound !== null &&
      this.mobxState.formState.selectedSupplierId !== null &&
      this.mobxState.formState.selectedFreightBracket !== null;
    //for now individual custom fields can be left empty, a jsonb attribute will be added to determine if a field can be left empty
    let formValid = formConditionsMet;

    if (formValid) {
      this.mobxState.formState.formValid = true;
      this.mobxState.formState.showHelperIcons = true;
    } else {
      this.mobxState.formState.formValid = false;
      this.mobxState.formState.showHelperIcons = false;
    }
    //console.log(this.mobxState.formState);
  };

  handleImportSubmit = () => {
    this.props.newFreightBidFetchData();
    this.fetchData();
  }

  render() {

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="panel panel-default">
            <div className="panel-heading panel_bg clearfix">
              <b>Create a New Bid</b>
              <span className="pull-right">
                {/* Constraint Saved Message */}
                <Bounce left when={this.mobxState.submitMessage}>
                  <b className="text-success" style={{ marginRight: '17px', marginTop: '4px' }}>
                    Freight Bid Saved
                  </b>
                </Bounce>
                <Bounce left when={this.mobxState.formState.showHelperIcons}>
                  <span>
                    <FontAwesomeIcon
                      icon={['fad', 'long-arrow-alt-right']}
                      size="2x"
                      transform="down-3 grow-10"
                      color="#53C8C5"
                      style={{ marginRight: '15px' }}
                    />
                  </span>
                </Bounce>
                <button
                  type="button"
                  disabled={!this.mobxState.formState.formValid}
                  className="btn btn-success btn-md"
                  onClick={this.handleFormSubmit}
                >
                  <FontAwesomeIcon icon={['fal', 'save']} />&nbsp; Save Bid
                </button>
                &nbsp;
                { /* <button type="button"
                  className="btn btn-primary btn-md disabled"
                  onClick={this.handleFormSubmit}>
                  <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Bulk Add
                </button>
                */}
                 {/* //file importation button */}
                <button
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Import constraints from an Excel SpreadSheet or CSV file."
                    className="btn btn-primary"
                    type="button"
                    data-toggle="collapse"
                    data-target="#importForm"
                    aria-expanded="false"
                    aria-controls="importForm">
                    <FontAwesomeIcon icon={['fal', 'file-import']} />&nbsp; Import Excel/CSV
                  </button>
              </span>
            </div>

            {/* collapsing form for importing data from external excel or csv file */}
            <div className="row">
              <div className="col-lg-12">
                <div className="collapse multi-collapse" id="importForm">
                  <div className="card card-body">
                    {/* holds import excel and csv import forms and buttons */}
                    <SpreadSheetImport
                      requiredCols={['item custom field', 'price', 'freight bracket','supplier']} //columns required to be in spreadsheet for form submission
                      tableName={'freight_bids'} //name of DB that the form will submit to
                      projectId={this.projectId}
                      handleSheetSubmit={this.handleImportSubmit} //refreshes grid on submit
                      systemFieldCols={['item_custom_field', 'price', 'freight_bracket', 'supplier']}
                      isConstraint={false}
                      scenarioId={null}
                      optionalCols={['quantity_supplied', 'baseline_price']} //cols that arent required and arent custom aliases
                      templateToBuild={'freight_bids'} //used for deciding what kind of spreadsheet template to download
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="panel-body">
              <form id="main-form">
                <div className="row">
                  <div className="col-sm-12">
                    <div className={'top_panel_info'}>Select where to place the freight bid</div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-4">
                    <ItemCustomFieldInput
                      formField={'itemCustomField'}
                      projectId={this.projectId}
                      handleFormChange={this.handleFormChange}
                      fieldAlias={this.mobxState.freightBidFieldAliases.item_custom_field}
                      placeholder={'Enter ' + this.mobxState.freightBidFieldAliases.item_custom_field}
                    />
                  </div>
                  <div className="col-lg-4">
                    <BidPriceInput
                      projectId={this.projectId}
                      handleFormChange={this.handleFormChange}
                      formField={'freightBidPrice'}
                      fieldAlias={this.mobxState.freightBidFieldAliases.price}
                      placeholder={'Enter bid for bracket'}
                    />
                  </div>
                  <div className="col-lg-4">
                    <BidRoundInput
                      formField={'freightBidRound'}
                      projectId={this.projectId}
                      handleFormChange={this.handleFormChange}
                      fieldAlias={this.mobxState.freightBidFieldAliases.round}
                      placeholder={'Enter bid round'}
                    />
                  </div>
                </div>
                {/* End row */}
                <br />
                <div className="row">
                  <div className="col-sm-12">
                    <div className={'top_panel_info'}>Select the bidder and bracket</div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-lg-4">
                    <ProjectSupplierSelect
                      projectId={this.projectId}
                      handleFormChange={this.handleFormChange}
                      formField={'selectedSupplierId'}
                      fieldAlias={this.mobxState.freightBidFieldAliases.supplier__name}
                      placeholder={'Select supplier'}
                    />
                  </div>
                  <div className="col-lg-4">
                    <FreightBracketIdSelect
                      formField={'selectedFreightBracket'}
                      projectId={this.projectId}
                      handleFormChange={this.handleFormChange}
                      fieldAlias={this.mobxState.freightBidFieldAliases.freight_bracket__name}
                      placeholder={'Select freight bracket'}
                    />
                  </div>
                  <div className="col-lg-4">
                  </div>
                </div>
                <br />
                {/* //Custom fields Form */}
                <div className="row custom-fields">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="top_panel_info"> Input freight bid level custom fields</div>
                      </div>
                    </div>
                    <div className="table-responsive bid-create-tables" sorted={this.mobxState.formState.sortOptions}>
                      <div className="col-lg-12 tab">
                        {/* dynamically generated custom field form */}
                        {/* <div className="custom-form-header">
                          <b>Input a Value Custom Fields</b>
                        </div> */}
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th>Field Name</th>
                              {/* <th>Field Alias</th>
                                <th>Display Order</th> */}
                              <th>Value Input</th>
                            </tr>
                          </thead>
                          <tbody className="input-tables">
                            {/* Maps table rows based on schema type */}
                            {Object.entries(this.mobxState.customForm.response_data).map(([key, value]) => {
                              if (value.schema.type == 'string') {
                                //console.log(key, value)
                                return (
                                  <tr key={key} ref="textInput">
                                    <td key={value.field_alias}>{value.field_alias}</td>
                                    {/* <td key={value.field_name}>{value.field_name}</td> */}
                                    {/* <td key={value.display_order}>{value.display_order}</td> */}
                                    <td key={value.table_alias}>
                                      <Input
                                        formfield={'custom_fields'}
                                        className="custom-input"
                                        placeholder="Enter a text value"
                                        name={value.field_name}
                                        onChange={e =>this.handleCustomFormChange(value.field_name, e.target.value)}
                                        allowClear
                                      />
                                    </td>
                                  </tr>
                                );
							                }
							                else if (value.schema.type == 'number') {
                                return (
                                  <tr key={key}>
                                    <td key={value.field_alias}>{value.field_alias}</td>
                                    {/* <td key={value.field_name}>{value.field_name}</td> */}
                                    {/* <td key={value.display_order}>{value.display_order}</td> */}
                                    <td key={value.table_alias}>
                                      <NumericInput
                                        //strict={true}
                                        formfield={'custom_fields'}
                                        name={value.field_name}
                                        onChange={e => this.handleCustomFormChange(value.field_name, e)}
                                        className="form-control custom-input"
                                        min={0}
                                        placeholder="Input a number"
                                      />
                                    </td>
                                  </tr>
                                );
                              } else {
                                console.log('Date Type Schema');
                              }
                            })}
                          </tbody>
                        </table>
                      </div>{' '}
                      {/* End of col-lg-6*/}
                    </div>{' '}
                    {/* End of .create-tables container */}
                  </div>
                </div>{' '}
                {/*END OF ROW */}
              </form>
            </div>
          </div>
        </div>
        {/* END col-sm-12*/}
      </div>
    );
  }
}
// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(CreateNewFreightBidForm, {
  mobxState: observable
});
export default observer(CreateNewFreightBidForm);
