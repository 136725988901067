import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//icons
import merge from '../../../assets/images/icons/merge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumericInput from 'react-numeric-input';

class QuantitativeValueInput extends React.Component {

    //taken from scope selection renamed to qulitative selection
    
      mobxState = {
        customFieldValue: [],
      }
    
      //mount data through http requests
      componentDidMount() {
        //call the data loader to load data into the view
        this.fetchData();
      }
    
    
      //CHECK PROJECT ID
    
      //////////////////HTTP/////////////////
      //data loader
      
      fetchData = () => {
        ////////////////////
        //FORM LOAD
        //QUANTITATIVES:
        //END FORM LOAD
        //////////////////
    
      } //end fetchData()  
    
      render(){
    
        // const validate = value => {
        //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
        // }
    
        return (
          <div>         
    
            <div className={this.props.scopeValueCssClass}>
            {/* Dependent dropdown menu that appears after selection is made in the first menu */}
                  <div>
                    
    
                    <div className={'customFieldNumberInput'} style={{display: 'flow-root'}}>
                      <b>Custom Field Value</b>
                      <br />
                      <NumericInput style={{
                        input: {                    
                          height: '38px',
                          //width: '24.826vw'
                          }
                        }} 
                        min={0}
                        className="form-control"
                        onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
                        min={0}
                        placeholder={'Specify Value #' }
                        defaultValue={0}
                      />
    
                    </div>
                  </div> 
            </div>
          </div>
        )
      }
    
    }
    
    // when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
    decorate(QuantitativeValueInput , {
        mobxState: observable
    })
    
    export default observer(QuantitativeValueInput );
    
    // export default QuantitativeValueInput 
    