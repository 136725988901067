import React from "react";
import { render } from 'react-dom';

//other components built
import Badge from "../../../../badges/Badge";
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns

import ProjectName from "../../shared/ProjectName";

//filters
import DockRenderer from "../../../../filters/DockRenderer";
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls
import Select from 'react-select'; //select box for filter

//ag grid
import GridRenderer from "../../../../grids/GridRenderer";

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//quick buttons
import QuickLaunchButtons from '../../shared/QuickLaunchButtons';

//utility
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { zeroNull, extractToken, useInterval } from "../../../../services/Utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class Scenarios extends React.Component {
	// Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
	projectId = extractToken(window.location.pathname, '/', 3);
	mobxState = {
		formState: { scenarioName: '' },
		totalTimer: 1000,
		timeoutCounter: 1,
		customOptions: {
		},
		cardData: {
			"itemCount": 0,
			"bidCount": 0,
			"supplierCount": 0,
			"scenarioCount": 0,
			"baselinePrice": 0
		},
		tableData: {
			"scenarios": []
		},
		errorPanel: { //holds data related to error panel
			scenarioContainsErrors: false, //bool to toogle the panel
			//ITEM ERROR INFO
			hasDuplicateItemNames: false, //toggles list of dupliate item names
			dupliateItemNameList: [], //holds list of items with duplicate names
			hasInvalidItemQuantity: false, //toggles list of null or 0 quantity items
			nullQuantityList: [], //holds list of item names and quantities that are null or 0
			//BID AND ITEM ERROR INFO
			itemIsMissingBids: false, //toggles list of items with out bids
			itemsMissingBidsList: [], //holds list of items without bids
			hasInvalidBidPrice: false, //toggles list of bids weith invalid prices
			invalidBidPriceList: {}, //holds list of bids with 0 or null prices

		},
		selectedModels: {
		}, //multi select data model for http
		selectedOptions: {
		}, //multi select data model in filter value/label format
		scenarioAutofill: [],
		dockOptions: {
			isVisible: false,
			size: 0.2
		}, //sidebar defaults
		pivotData: [],
		pivotState: [],
		pivotFilter: [],
		filterModelNames: [], //autobuilds filters and clear functions
		inDeletion: false, //toggles icon and "Deleting selected rows" caption
		windowDimensions: { width: 0, height: 0 }, //default window dims.. for resizing listener
	};

	//mount data through http requests
	componentDidMount() {
		this.updateWindowDimensions();
		this.fetchData();
		//use the setTimeout to poll continuously, but each time increase the timer
		this.timer = setTimeout(this.timeoutIncreaser, this.mobxState.timeoutCounter);
		//check scenario's items and bids
		this.scenarioItemCheck();
		this.scenarioBidPriceCheck();
		this.scenarioAssociationCheck();
		//listen to winwo resizes
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	//unmounting process AKA customer leaves page
	componentWillUnmount() {
		//!!! IMPORTANT CLEAR TIMEOUTS ON COMPONENT EXIT TO PREVENT THEM RUNNING FOR LIFETIME OF THE PAGE BEING OPEN
		this.timer = null;
		this.timeoutIncreaser = null;
		//remove the window resizing listener
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		console.log('in function resize');
		//actually update window dimensions in the state
		this.mobxState.windowDimensions = ({ width: window.innerWidth, height: window.innerHeight });
	}

	//increase by timeout by certain amount each time this is ran, and call fetchData() to reload screen
	timeoutIncreaser = () => {
		this.mobxState.timeoutCounter += 1000 * 2; //increase by * amount of seconds
		this.fetchData(false);
		setTimeout(this.timeoutIncreaser, this.mobxState.timeoutCounter);
	}

	//////////////////HTTP/////////////////
	//checks items for duplicate names and/or 0 or null quantity
	scenarioItemCheck = () => {
		console.log("Checking Scenario Items")
		axios.get(`/v1/projects/${this.projectId}/items/check_items`)
			.then((result) => {
				console.log("Item Result ", result)
				//toggle error panel and any lists of duplicate item names and/or invalid item quantities
				if (result.data.duplicates.length > 0 || result.data.nullList.length > 0) {
					this.mobxState.errorPanel.scenarioContainsErrors = true;
					//toggle DuplicateItemNames and/or InvalidItemQuantity lists
					console.log(" ITEM LIST", result.data.duplicates[0])
					if (result.data.duplicates.length > 0) {
						this.mobxState.errorPanel.dupliateItemNameList = result.data.duplicates;
						this.mobxState.errorPanel.hasDuplicateItemNames = true;
					}
					if (result.data.nullList.length > 0) {
						this.mobxState.errorPanel.nullQuantityList = result.data.nullList;
						this.mobxState.errorPanel.hasInvalidItemQuantity = true;
					}
				}
			})
	}
	//checks to see if any bid has 0 or null for the price
	scenarioBidPriceCheck = () => {
		console.log("Checking Scenario Bids")
		axios.get(`/v1/projects/${this.projectId}/bids/price_check`)
			.then((result) => {
				console.log("BID Result ", result.data)
				if (!_.isEmpty(result.data)) {
					this.mobxState.errorPanel.scenarioContainsErrors = true
					if (result.data) {
						this.mobxState.errorPanel.invalidBidPriceList = result.data
						this.mobxState.errorPanel.hasInvalidBidPrice = true;
					}
				}
			})
			.catch((error) => { console.log(error) })
	}

	//checks for items without bids
	scenarioAssociationCheck = () => {
		console.log('Checking Item Associations');
		axios.get(`/v1/projects/${this.projectId}/items/item_bids`).then(result => {
			console.log("Scenario Item/Bid check ", result.data)
			if (result.data.length === 0) {
				return
			} else {
				let resultArr = result.data[0][1]; //grab first array to check length to see if there are errors (empty array means no errors)
				console.log('Item/Bid Result ', resultArr);
				if (!_.isEmpty(resultArr)) {
					this.mobxState.errorPanel.scenarioContainsErrors = true;
					result.data.forEach(field => {
						console.log(field[1])
						if (field[1] !== null || {}) {
							this.mobxState.errorPanel.itemsMissingBidsList = result.data;
							console.log("!!!!!!!!!", this.mobxState.errorPanel.itemsMissingBidsList)
							this.mobxState.errorPanel.itemIsMissingBids = true;
						}
					});
				}
			}
		})
			.catch((error) => { console.log(error) })
	};



	//data loader
	fetchData = (willAutofit = true) => {
		//add to interval so the next call gets delayed more to not hog up the server
		//this.mobxState.totalTimer = this.mobxState.totalTimer + 5000;

		//quick workaround -- pull simple dropdown value from nested component instead of passing around state
		var table_rows = window.$("#table_rows_dropdown").val();

		//merge the filter model with the amount of rows selected
		let models_with_rows = { ...this.mobxState.selectedModels, ...{ "table_rows": table_rows } };

		//analytics data load
		axios.get('/v1/projects/' + this.projectId + '/scenarios/analytics', { params: models_with_rows })
			.then(response => {
				this.mobxState.cardData = response.data.scenario_analytics;
			})

		//scenario input box autofill suggestions
		axios.get('/v1/projects/' + this.projectId + '/scenarios/index_filter', { params: models_with_rows })
			.then(response => {
				this.mobxState.scenarioAutofill = ['Lowest Price', '1 Supplier Per DC', '5% Discount']; //response.data.scenarios;
			})

		//set up custom options for components, then get data for components
		// Merge System Aliases Into Custom Options (Keeping existing values)
		grabSystemAliases().then(systemAliases => {
			this.mobxState.customOptions = {
				...systemAliases,
				...(this.mobxState.customOptions || {}), // Prefer existing values
			}
		});

		axios.get('/v1/projects/' + this.projectId + '/project_field_schemas', { params: { 'project_id': this.projectId } })
			.then((response) => {
				//get map of field labels from API
				this.mobxState.customOptions = {
					...this.mobxState.customOptions,
					...(response.data || {})
				};

				//after ag grid is set up, get the rest of the data
				//table data load
				axios.get('/v1/projects/' + this.projectId + '/scenarios', { params: models_with_rows })
					.then(response => {
						this.mobxState.tableData = response.data;
						//set the column definitions for ag grid, look into buildColumnDefs class to see options
						this.mobxState.columnDefsScenarios = gridColumns.buildColumnDefs({
							"customOptions": this.mobxState.customOptions,
							"columnArray": ['sourcing_scenarios'],
							"customFieldSchema": this.mobxState.customOptions.project_field_schemas[0],
							"dataType": 'sourcing_scenario', //special tag to signify that this is project data
							"windowDimensions": this.mobxState.windowDimensions
						});

						//if autofit param is passed as true, then autofit window. this is put in here because after the timeout function reloads the screen to update, the screen redoes any user column widths, so the auto update will send a willAutofit flag of false to prevent this
						if (willAutofit) {
							//autofit the table columns (hits the child function), this runs after the columns were created
							this.autofitChild();
						};
					})
			})
			.catch(console.log.bind(console)) //error catcher

		//custom pivot configurations load
		axios.get("/v1/resources/index_filter", { params: { "page_id": 12 } })
			.then((response) => {
				this.mobxState.pivotFilter = response.data.resources;
			});
		//autofit the table columns (hits the child function), this runs after the columns were created
		//this.autofitChild();
	} //end fetchData()

	//FORM HANDLING FUNCTIONS
	//handle submission by sending the formState to server
	handleFormSubmit = (event) => {
		event.preventDefault();

		axios.post('/v1/projects/' + this.projectId + '/scenarios', { "name": this.mobxState.formState.scenarioName })
			.then((response) => {
				console.log(response);
				this.fetchData();
			});
	}

	//changes the formState of whatever <input> called this onChange.. input name is the formState key, input value is object
	handleFormChange = (event) => {
		let inputName = event.target.name;
		let inputValue = event.target.value;
		this.mobxState.formState[inputName] = inputValue;
	}
	//END FORM HANDLING

	deleteRequest = (toSendArr) => {
		this.mobxState.inDeletion = true;
		console.log("IN DELETION");
		axios.post('/v1/projects/' + this.projectId + '/scenarios/batch_destroy', { "id_set": toSendArr })
			.then((response) => {
				this.mobxState.inDeletion = false
				console.log(response);
				this.fetchData();
			});
	}
	//////////////////END HTTP/////////////////

	//handles dropdown and chart filters
	handleFilter = (filterParam, selectedFilter) => {
		console.log('Handle Filter Callback: ');
		console.log('selectedFilter ', selectedFilter);
		console.log('filterParam ', filterParam);
		//in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
		this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
		//also store the selections in the original form. This goes back to the filter as selected values
		this.mobxState.selectedOptions[filterParam] = selectedFilter;
		//fetch called, it reads the filter params from state
		this.fetchData();
	}

	//handles dropdown and chart filters
	handleClear = () => {
		//in the state, clear the model. This goes as params to http request
		this.mobxState.selectedModels = {};
		//also store the explicit cleared selections in the original form. This goes back to the filter as selected values
		//loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
		//from ["supplier", "item"] to {"supplier":[], "item":[]}
		const optionsClear = {};
		for (const key of this.mobxState.filterModelNames) { optionsClear[key] = []; }
		this.mobxState.selectedOptions = optionsClear;
		//fetch called, it reads the filter params from state
		this.fetchData();
	}

	//for sidebar dock to change sizes
	handleSizeChange = size => {
		this.mobxState.dockOptions.size = size;
	}

	//sidebar dock toggle
	toggleDock = () => {
		this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible
	}

	render() {

		const hcHeight = '300px';
		//need to add form validation
		const validate = value => {
			return !value || value.length < 3 ? 'Must be at least three characters' : null;
		};

		return (

			<div>

				<div className="jumbotron jumbotron_full_width">
					<div className="jumbotron_full_width_container">
						<div className="row">
							<div className="col-sm-10">
								<span className="fa-layers fa-fw fa-2x">
									<FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#009C9C" style={{ marginTop: '4px' }} />
									<FontAwesomeIcon icon={['fal', 'greater-than-equal']} color="white" transform="shrink-4" style={{ marginTop: '4px' }} />
								</span>
								&nbsp;
								<b className="dash_header"><ProjectName /> Optimization Scenarios</b>
							</div>
							<div className="col-sm-2 gps">
								<FontAwesomeIcon icon={['fad', 'location-arrow']} color="#53C8C5" transform='grow-15 left-10' style={{}} />
								Next: Create scenarios below
							</div>
						</div>

					</div>
				</div>

				{/* BADGES */}
				<div className="row">
					<div className="col-lg-8">
						<div className="row">
							<div className="col-lg-12">
								<div className="badge-container">
									{/*<Badge
                      badgeNumber={zeroNull(this.mobxState.cardData.scenarioCount).toLocaleString('en-US')}
                      badgeName={'Scenarios'}
                      faClass={'circle-play'}
                    />*/}
									<Badge
										badgeNumber={zeroNull(this.mobxState.cardData.supplierCount).toLocaleString('en-US')}
										badgeName={'Bidders'}
										faClass={'briefcase'}
									/>
									<Badge
										badgeNumber={zeroNull(this.mobxState.cardData.itemCount).toLocaleString('en-US')}
										badgeName={'Items'}
										faClass={'archive'}
									/>
									<Badge
										badgeNumber={zeroNull(this.mobxState.cardData.bidCount).toLocaleString('en-US')}
										badgeName={'Bids'}
										faClass={'gavel'}
									/>
									<Badge
										badgeNumber={Math.round(zeroNull(this.mobxState.cardData.baselinePrice)).toLocaleString('en-US')}
										badgeName={'Ttl Baseline Cost'}
										faClass={'check-circle'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						{/* QUICK LAUNCH BUTTONS */}
						<QuickLaunchButtons />
					</div>
				</div>

				{/* WARN PANEL*/}
				{this.mobxState.errorPanel.scenarioContainsErrors &&

					<div>
						<br />
						<div className="row">
							<div className="col-lg-12">
								<div className="panel panel-default">
									<div className="panel-heading panel_bg">
										<b>Optimization Data Issues</b>
									</div>
									<div className="panel-body scenario-error-panel">
										<div className="row">
											{/* DUPLICATE ITEM WARN */}
											{this.mobxState.errorPanel.hasDuplicateItemNames &&
												<div className="col-lg-12">
													<div className="panel-heading panel_bg bg-danger text-danger">
														<b className="text-danger">Duplicate items! Items have to be unique in order to optimize. The following item names need to be renamed.</b>
													</div>
													<ol className="scenario-error-list" style={{ listStyle: "none" }}>
														{Object.entries(this.mobxState.errorPanel.dupliateItemNameList).map(([key, value]) => {
															return (
																<React.Fragment>
																	{
																		parseInt(key) + 1 === this.mobxState.errorPanel.dupliateItemNameList.length ?
																			<li key={key}>{value.name}</li>
																			:
																			<li key={key}>{value.name}&nbsp;|</li>
																	}
																</React.Fragment>
															)
														})}
													</ol>
												</div>
											}
											{/* ITEM 0 OR NULL QUANTITY WARN*/}
											{this.mobxState.errorPanel.hasInvalidItemQuantity &&
												<div className="col-lg-12">
													<div className="panel-heading panel_bg bg-danger text-danger">
														<b className="text-danger">The following items have 0 quantity, please remove them from the project or adjust the quantity to be 0.</b>
													</div>
													<ol className="scenario-error-list" style={{ listStyle: "none" }}>
														{Object.entries(this.mobxState.errorPanel.nullQuantityList).map(([key, value]) => {
															return (
																<React.Fragment>
																	{
																		parseInt(key) + 1 === this.mobxState.errorPanel.nullQuantityList.length ?
																			<li key={key}>{value.name === "" ? `No name for Item ID:${value.id} ` : value.name}</li>
																			:
																			<li key={key}>{value.name === "" ? `No name for Item ID:${value.id} ` : value.name} |</li>
																	}
																</React.Fragment>
															)
														})}
													</ol>
												</div>
											}
											{/* ITEMS THST HAVE NO BIDS WARN */}
											{this.mobxState.errorPanel.itemIsMissingBids &&
												<div className="col-lg-12">
													<div className="panel-heading  panel_bg bg-danger text-danger">
														<b className="text-danger">Missing coverage. Not all items have a bid.</b>
													</div>
													<ol style={{ listStyle: "none" }}>
														{Object.entries(this.mobxState.errorPanel.itemsMissingBidsList).map(([key, value]) => {
															let round = value[0]
															let items = value[1]
															return (
																<div className="bid-error-list" key={key}>
																	<b className="error-list-header">Round {round}:</b>
																	{
																		items.map((subValue, i) => {
																			return (
																				<React.Fragment>
																					{
																						i + 1 === items.length ?
																							<ul id="items-missing-bids" key={i}>
																								<li> {subValue['name'] === "" ? "No Name for Item" : subValue['name']}</li>
																							</ul>
																							:
																							<ul id="items-missing-bids" key={i}>
																								<li> {subValue['name'] === "" ? "No Name for Item" : subValue['name']} |</li>
																							</ul>
																					}
																				</React.Fragment>
																			)
																		})
																	}
																</div>
															)
														})
														}
													</ol>
												</div>
											}
											{/* BID 0 OR NULL PRICE WARN*/}
											{this.mobxState.errorPanel.hasInvalidBidPrice &&
												<div className="col-lg-12">
													<div className="panel-heading panel_bg bg-danger text-danger">
														<b className="text-danger">The following bids have a 0 for the price, please add a price to these bids before running a scenario.</b>
													</div>
													{/* Map out errors by round number*/}
													{/* Bids are grouped by the value in the 'round' column */}
													{Object.entries(this.mobxState.errorPanel.invalidBidPriceList).map(([key, value]) => {
														return (
															<div className="bid-error-list" key={key}>
																<b className="error-list-header">Round {key}:</b>
																{
																	value.map((subValue, i) => {
																		return (
																			<React.Fragment>
																				<ul key={i}>
																					<li> Bid Id: {subValue['id']},&nbsp; </li>
																					<li> Bid Item Name: {subValue['item.name']},&nbsp; </li>
																					<li> Bid Price: {subValue['price'] === 0 ? subValue['price'] : "No Price"},&nbsp;</li>
																					{
																						i + 1 === value.length ?
																							<li> Bidder Name: {subValue['supplier.name']}&nbsp;</li> :
																							<li> Bidder Name: {subValue['supplier.name']}&nbsp; |</li>
																					}
																				</ul>
																			</React.Fragment>
																		)
																	})
																}
															</div>
														)
													})
													}
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
				<br />

				{/* END OF WARN*/}
				{/* FORM */}
				<div className="row">
					<div className="col-sm-8">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg">
								<b>Scenario Creation {this.mobxState.formState.scenarioName}</b>
							</div>
							<div className="panel-body">

								<div className="row">
									<form>
										<div className="col-sm-8">
											<input name="scenarioName" type="text" placeholder="Enter new scenario name" className="form-control" onChange={this.handleFormChange} />
										</div>
										<div className="col-sm-4">
											<button type="submit" className="btn btn-success btn-block" onClick={this.handleFormSubmit}>
												<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Create
											</button>
										</div>
									</form>
								</div>

							</div>
						</div>
					</div>
					{/* END FORM*/}

					{/* Select Filter allows you to select the pivot_settings config to display*/}
					<div className="col-sm-4">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg">
								<b>Saved Table Views</b>
							</div>
							<div className="panel-body">
								{/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
								<Select
									onChange={(e) => this.clickChild(e)}
									options={this.mobxState.pivotFilter}
									closeMenuOnSelect={true}
									maxMenuHeight={400}
									placeholder={'Select From List of Saved Project Tables'}
								/>
							</div>
						</div>
					</div>
				</div>
				{/* PROJECT GRID */}
				<div className="row">
					<div className="col-lg-12">
						{/* AG Grid */}
						<GridRenderer
							gridHeight={"700px"}
							columnDefs={this.mobxState.columnDefsScenarios}
							rowData={this.mobxState.tableData.scenarios}

							fetchData={this.fetchData}
							handleClear={this.handleClear}
							toggleDock={this.toggleDock}
							pageId={12}

							savedState={this.mobxState.pivotState}
							isPivotMode={false}
							pivotMode={false}
							suppressPivots={true}
							suppressPivotMode={true}
							gridTitle={"Created Optimization Scenarios"}

							isTableRowSelectable={false}
							customTableRowCountIndicator={
								<div style={{ display: 'inline-block' }}>
									Showing
									<span>
										<select value={`{this.mobxState.tableData.scenarios && this.mobxState.tableData.scenarios.length}`} id="table_rows_dropdown" className="simple_dropdown" >
											<option value="{this.mobxState.tableData.scenarios && this.mobxState.tableData.scenarios.length}">{this.mobxState.tableData.scenarios && this.mobxState.tableData.scenarios.length}</option>
										</select>
									</span>
									entries
								</div>
							}

							setClick={click => this.clickChild = click}
							deleteRequest={this.deleteRequest}
							idColumn={"id"}

							modelsToUpdateArr={[{ url: 'v1/projects/' + this.projectId + '/scenarios/', idName: "id" }]}
							//modelsToUpdateArr={false}
							enableSetResizeChild={true}
							setResize={click => this.autofitChild = click}
							// enableSetResizeChildColumns={true}
							// setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

							singleClickEdit={true}
							suppressClickEdit={false}

							isSaveable={true}
							isAwardable={false}
							isDeletable={true}
							inDeletion={this.mobxState.inDeletion}
						/>

					</div>
				</div>{/* END CHART ROW */}

				<br />

			</div>
		);
	}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Scenarios, {
	mobxState: observable
})

export default observer(Scenarios);

// export default Scenarios;
