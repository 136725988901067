import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//icons
import merge from '../../../assets/images/icons/merge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { element } from 'prop-types';

//handles rendring form components and their validation
class FreightFieldSelection extends React.Component {

//taken from scope selection renamed to qulitative selection

  mobxState = {
   freightFieldSelection: [], //select options for dropdown
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }
  

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  
  fetchData = () => {
   
    ////////////////////
    //FORM LOAD
    //QUALITATIVES:
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/freight_match_constraints_freight_field', {params: {} })
    .then((response) => {
      this.mobxState.freightFieldSelection = response.data.freight_field
    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  //handle selecting the qualitative in the dropdown and update qualitative_value dropdown in the state


  render(){


    return (
      <div>
     
            {/* <div className="col-lg-2">
              <div>
                <FontAwesomeIcon icon={['fad', 'columns']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{marginRight:'20px'}}/>
              </div>
            </div> */}
        
              {/* main form that is always rendered */}
              <b>Freight Field</b>
              <Select
                onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
                options={this.mobxState.freightFieldSelection}
                closeMenuOnSelect={true}
                maxMenuHeight = {400}
                placeholder={'Select Freight Field'}
              />
            </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FreightFieldSelection, {
	mobxState: observable
})

export default observer(FreightFieldSelection);


