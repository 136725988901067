import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

class ProjectDropdownForm extends React.Component {
  render() {
    const handleFormAdd = []
    const formDisplay = []
    let i = 0;
    if (typeof this.props.projectDropdownSchema !== 'undefined' && this.props.projectDropdownSchema){
      Object.entries(this.props.projectDropdownSchema).map(([key, value]) => {
        formDisplay.push(
          <tr key={key}>
            <td>
              <Input
                value={value}
                placeholder={value || 'Enter option'}
                className="form-control"
                onChange={(e) => this.props.handleFormChange(this.props.schemaLevel, this.props.projectDropdown, e.target.value, key)}
                onBlur={(e) => this.props.handleFormBlur(this.props.schemaLevel, this.props.projectDropdown)}
              />
            </td>
            <td>
              <div>
                <span>
                  <a data-toggle="collapse" href={'#modal_'+this.props.projectDropdown+'_'+i}>
                    <span className="glyphicon glyphicon-remove-circle modal_glyphi text-danger" aria-hidden="true" style={{marginTop: '10px'}}></span>
                    <span> Remove </span>
                  </a>
                </span>
                <div id={'modal_'+this.props.projectDropdown+'_'+i} className="panel-collapse collapse">
                  <div>
                    Delete option?
                    &nbsp;
                    <a data-toggle="collapse" href={'#modal_'+this.props.projectDropdown+'_'+i} onClick={() => this.props.handleFormDelete(this.props.schemaLevel, this.props.projectDropdown, value)}>Yes</a>
                    &nbsp;&nbsp;
                    <a data-toggle="collapse" href={'#modal_'+this.props.projectDropdown+'_'+i}>No </a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        );
        i++;
      })
    }

    return (
      <div>

        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th><b>Option</b></th>
                <th style={{width:'10%'}}><b>Action</b></th>
              </tr>
            </thead>
            <tbody>
              {formDisplay}
            </tbody>
          </table>
        </form>

      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ProjectDropdownForm, {
  mobxState: observable
});

export default observer(ProjectDropdownForm);

// export default ProjectOverview;
