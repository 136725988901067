import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';

 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//handles rendring form components and their validation
class ConstraintOperatorSelection extends React.Component {

  mobxState = {
    operatorSelect: [], //options for select box
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    ////////////////////
    //FORM LOAD
    //OPERATORS:
    axios.get('/microservices/v1/operators', {params: {} })
    .then((response) => {
      this.mobxState.operatorSelect = response.data.operators;
      // console.log('INSIDE COMPONENT');
      console.log(this.mobxState.operatorSelect);
    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  render(){

    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }
         
    return (
      
      <div className={this.props.scopeCssClass}>
        <div className="row">    
            <div className=" col-md-11">
              <b>{this.props.inputName}</b>
              <Select
                onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
                options={this.mobxState.operatorSelect}
                closeMenuOnSelect={true}
                maxMenuHeight = {400}
                placeholder={'How you would like to award'}
              />
          </div>   
  
            <div className="col-md-1" style={{marginTop: '1.80em', paddingRight: '5px',marginLeft: '-1.65em' }}>  
            <span className="operatorSelectionIcon">
             { this.props.selectedIcon === null &&																
                <span>
                <FontAwesomeIcon icon={['fad', 'arrows-alt-h']} mask={['fas', 'circle']} size='2x' transform="shrink-5" color="#53C8C5" style={{}}/>
                </span>
            }
            {this.props.selectedIcon === '<=' &&
               <span>
                <FontAwesomeIcon icon={['fad', 'less-than-equal']} mask={['fas', 'circle']} size='2x' transform="shrink-4" color="#53C8C5" style={{}}/>
                </span>
             }
             { this.props.selectedIcon === '>=' &&
                <span>
                 <FontAwesomeIcon icon={['fad', 'greater-than-equal']} mask={['fas', 'circle']} size='2x' transform="shrink-4" color="#53C8C5" style={{}}/>
               </span>
             }														
             </span>              
          </div>  

          
         </div>
      </div>
    )
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ConstraintOperatorSelection, {
	mobxState: observable
})

export default observer(ConstraintOperatorSelection);

// export default ConstraintOperatorSelection
