import React from 'react';
import { Modal } from 'react-bootstrap';
import { render } from 'react-dom';

//other components built
import { MultiValueContainer } from './ConfigureButton';

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select, { components } from 'react-select'; //select box for filter

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull } from '../../../../services/Utilities';
const _ = require('lodash'); //extra utilities

/*
Component Hierarchy

PageFilters (Component for our page)
| Module Panel (Render each seperate page)
| | Page Panel (Render system & custom filters for particular page)
| | | SystemFilterPanel (Render & Handle Selection In The Custom Field)
| | | | SystemFilterEditor
| | | | | BasicFilterForm
| | | CustomFilterPanel (Render & Handle Selection In The Custom Field)
| | | | CustomFilterEditor
| | | | | Select -- (Uses replaced ValueWrapper component)
| | | | | ColumnFilterForm (Form for editing custom filter)
| | | | | NewFilterForm (Form for creating new filter) // TODO: create this
*/

console.info('DECLARING PAGE FILTER');
//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class PageFilters extends React.Component {
  mobxState = {
    customOptions: [],
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    filters: [],
    filterModelNames: [
    ], //autobuilds filters and clear functions
    // Page Filters Specific State
    page_filters_by_module: [],
    modules: []
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    console.log('MOUNTED');
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    console.info('FETCHING DATA');
    axios.get('/v1/page_filters').then(resp => {
      this.mobxState.page_filters_by_module = resp.data;
      let module_id_count = 0;
      _.forEach(resp.data, (pages, module_name) => {
        this.mobxState.modules.push(
          <ModulePanel key={(module_name || "null_module_panel") + module_id_count} name={module_name} pages={pages} />
        );
        module_id_count += 1
      });
    });
    //set up custom options for components, then get data for components
  }; //end fetchData()

  deleteRequest = toSendArr => {
    console.log('IN DELETION');
    axios
      .post('/v1/projects/batch_destroy', { id_set: toSendArr })
      .then(response => {
        console.log(response);
        this.fetchData();
      });
  };
  //////////////////END HTTP/////////////////

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    console.log('Handle Filter Callback: ');
    console.log('selectedFilter ', selectedFilter);
    console.log('filterParam ', filterParam);
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels[filterParam] = selectedFilter.map(
      a => a.value
    );
    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions
      .isVisible;
  };

  render() {
    const hcHeight = '300px';
    // Do not remove the following console.debug statement or
    // the build will break. It will not be obvious at development time.
    // See commit df76c442eb3f342c87b4620453ae7eda23ebd6e9 for details.
    // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
    console.debug('There are ', this.mobxState.modules.length, 'modules');

    return (
      <div>
        <div onClick={this.toggleDock} className='sidebar_button filter_toggle'>
          <i className='glyphicon glyphicon-chevron-left'></i>
        </div>

        <div className='jumbotron jumbotron_full_width'>
          <div className='jumbotron_full_width_container'>
            <b className='dash_header'>Page Filters</b>
          </div>
        </div>
        <div>{this.mobxState.modules}</div>
      </div>
    );
  }
}

function ModulePanel(props) {
  let pages = props.pages.map((p, ix) => <PagePanel key={`${props.name}-page-${ix}`} page={p} />);
  return (
    <div className='row'>
      <h3> {props.name} </h3>
      {pages}
    </div>
  );
}

function PagePanel(props) {
  return (
    <div className='col-xs-12'>
      <h4>
        {props.page.id}: {props.page.name}
      </h4>
      <p>Description: {props.page.description}</p>
      <div className='row'>
        <div name='system-filters-panel' className='col-md-6'>
          <SystemFilterPanel filters={props.page.system_filters} />
        </div>
        <div name='custom-filters-panel' className='col-md-6'>
          <CustomFilterPanel filters={props.page.custom_filters} />
        </div>
      </div>
      <pre style={{ display: 'none' }}>{JSON.stringify(props.page, 0, 2)}</pre>
    </div>
  );
}

class SystemFilterPanel extends React.Component {
  // STATE OVERVIEW
  // filters  :: [<filter>] - The filters themselves
  // options  :: [<option>] - Options representing filters in select
  // selected :: [<option>] - List of options selected
  // currentlyEditing :: UNION[<filter> || null] - Filter we're currently editing
  // error :: UNION[<string> || null] - Error message, null if no error
  // ===========================================
  // Methods For Select (BULK UPDATE :enabled)
  // ===========================================
  constructor(props) {
    super(props);
    let filters = props.filters;
    let options = [];
    let selected = [];
    // Build options from our filters
    // & decide which are enabled
    filters.forEach(filter => {
      let opt = { value: filter.id, label: `${filter.model}.${filter.name}` };
      options.push(opt);
      if (filter.enabled) selected.push(opt);
    });

    this.state = {
      filters: filters,
      options: options,
      selected: selected,
      currentlyEditing: null,
      error: null
    };
  }
  // bulkUpdateBackend :: ([Int], [Int]) => Promise
  // Bulk updates which filters are enabled
  bulkUpdateBackend(enabledIds, disabledIds) {
    return axios.post('/v1/system_filters/batch_update', {
      enabled_ids: enabledIds,
      disabled_ids: disabledIds
    });
  }
  handleSelectChange = selectedOptions => {
    let selectedIds = selectedOptions.map(x => x['value']);
    let newFilters = [];
    let enabledIds = [];
    let disabledIds = [];
    this.state.filters.forEach(filter => {
      if (selectedIds.includes(filter.id)) {
        enabledIds.push(filter.id);
        newFilters.push({ ...filter, ...{ enabled: true } });
      } else {
        disabledIds.push(filter.id);
        newFilters.push({ ...filter, ...{ enabled: false } });
      }
    });
    this.bulkUpdateBackend(enabledIds, disabledIds)
      .then(resp => {
        this.setState({ filters: newFilters, selected: selectedOptions });
      })
      .catch(e => {
        // Revert to previous state
        console.error(e);
      });
  };

  // ===========================================
  // Methods For Editor (PATCH)
  // ===========================================
  handleClose = () => {
    this.setState({
      currentlyEditing: null,
      error: null
    });
  };
  handleConfigure = option => {
    let filter = this.state.filters.find(filter => filter.id === option.value);
    this.setState({ currentlyEditing: filter });
  };
  // Methods For Editor (PATCH)
  handleEditorSubmit = (newFilterValues) => {
    // Patch the filter
    axios.put(
      '/v1/system_filters/' + this.state.currentlyEditing.id,
      newFilterValues
    ).then((resp) => {
      // If it's successful, update the filter currently being edited
      // Then unset currentlyEditing (to close the dropdown)
      this.setState({currentlyEditing: null});
      document.location.reload(); // TODO: Actually update the options used by the select
    }).catch((error) => {
      // TODO: If it's unsuccessful, display the error
      this.setState({error: "Something when wrong please try again later"});
      setTimeout(5000, () => {this.setState({error: null})});
    });
  };

  // ===========================================
  // Methods For New Filter (POST)
  // ===========================================
  // Methods For New Editor (POST) // TODO
  handleNewEditorSubmit = () => {};

  // ===========================================
  // Display Methods
  // ===========================================
  renderErrors = () => {
    if (this.state.error === null) {
      return <div name='error'></div>;
    } else {
      return <div className="alert alert-danger" name='error'>{this.state.error}</div>;
    }
  };

  render() {
    return (
      <div>
        <h5>System Filters</h5>
        <Select
          defaultValue={this.state.selected}
          options={this.state.options}
          onChange={this.handleSelectChange}
          onConfigure={this.handleConfigure}
          isDisabled={this.state.options.length === 0 || (this.state.currentlyEditing !== null)}
          components={{
            MultiValueContainer: MultiValueContainer(this.handleConfigure)
          }}
          isMulti
        />
        {this.renderErrors()}
        <SystemFilterEditor
          filter={this.state.currentlyEditing}
          handleSubmit={this.handleEditorSubmit}
          handleCancel={this.handleClose}
        />
      </div>
    );
  }
}

class CustomFilterPanel extends React.Component {
  // STATE OVERVIEW
  // filters  :: [<filter>] - The filters themselves
  // options  :: [<option>] - Options representing filters in select
  // selected :: [<option>] - List of options selected
  // currentlyEditing :: UNION[<filter> || null] - Filter we're currently editing
  // error :: [Components] - Error message, null if no error
  // ===========================================
  // Methods For Select (BULK UPDATE :enabled)
  // ===========================================
  constructor(props) {
    super(props);
    let filters = props.filters;
    let options = [];
    let selected = [];
    // Build options from our filters
    // & decide which are enabled
    filters.forEach(filter => {
      let opt = { value: filter.id, label: filter.name };
      options.push(opt);
      if (filter.enabled) selected.push(opt);
    });

    this.state = {
      filters: filters,
      options: options,
      selected: selected,
      currentlyEditing: null,
      error: null
    };
  }
  // bulkUpdateBackend :: ([Int], [Int]) => Promise
  // Bulk updates which filters are enabled
  bulkUpdateBackend(enabledIds, disabledIds) {
    return axios.post('/v1/custom_filters/batch_update', {
      enabled_ids: enabledIds,
      disabled_ids: disabledIds
    });
  }
  handleSelectChange = selectedOptions => {
    let selectedIds = selectedOptions.map(x => x['value']);
    let newFilters = [];
    let enabledIds = [];
    let disabledIds = [];
    this.state.filters.forEach(filter => {
      if (selectedIds.includes(filter.id)) {
        enabledIds.push(filter.id);
        newFilters.push({ ...filter, ...{ enabled: true } });
      } else {
        disabledIds.push(filter.id);
        newFilters.push({ ...filter, ...{ enabled: false } });
      }
    });
    this.bulkUpdateBackend(enabledIds, disabledIds)
      .then(resp => {
        this.setState({ filters: newFilters, selected: selectedOptions });
      })
      .catch(e => {
        // Revert to previous state
        console.error(e);
      });
  };

  // ===========================================
  // Methods For Editor (PATCH)
  // ===========================================
  handleClose = () => {
    this.setState({
      currentlyEditing: null,
      error: null
    });
  };
  // Callback for when clicking configure button
  handleConfigure = option => {
    let filter = this.state.filters.find(filter => filter.id === option.value);
    this.setState({ currentlyEditing: filter });
  };
  // Methods For Editor (PATCH)
  handleEditorSubmit = (newFilterValues) => {
    // Patch the filter
    axios.put(
      '/v1/custom_filters/' + this.state.currentlyEditing.id,
      newFilterValues
    ).then((resp) => {
      // If it's successful, update the filter currently being edited
      // Then unset currentlyEditing (to close the dropdown)
      this.setState({currentlyEditing: null});
      document.location.reload(); // TODO: Actually update the options used by the select
    }).catch((error) => {
      // TODO: If it's unsuccessful, display the error
      this.setState({error: "Something when wrong please try again later"});
      setTimeout(5000, () => {this.setState({error: null})});
    });
  };

  // Methods For New Editor (POST) // TODO
  handleNewEditorSubmit = () => {};

  // ===========================================
  // Methods For New Filter (POST)
  // ===========================================
  // TODO
  // ===========================================
  // Display Methods
  // ===========================================
  renderErrors = () => {
    if (this.state.error === null) {
      return <div name='error'></div>;
    } else {
      return <div className="alert alert-danger" name='error'>{this.state.error}</div>;
    }
  };

  render() {
    return (
      <div>
        <h5>Custom Filters</h5>
        <Select
          defaultValue={this.state.selected}
          options={this.state.options}
          onChange={this.handleSelectChange}
          onConfigure={this.handleConfigure}
          isDisabled={this.state.options.length === 0 || (this.state.currentlyEditing !== null)}
          components={{
            MultiValueContainer: MultiValueContainer(this.handleConfigure)
          }}
          isMulti
        />
        {this.renderErrors()}
        <CustomFilterEditor
          filter={this.state.currentlyEditing}
          handleSubmit={this.handleEditorSubmit}
          handleCancel={this.handleClose}
        />
      </div>
    );
  }
}

class SystemFilterEditor extends React.Component {
  render() {
    // Default to rendering nothing
    // Otherwise render the form for the appropriate column
    if (this.props.filter === null) {
      return <div></div>;
    } else {
      let formInstance = React.createElement(
        BasicFilterForm,
        { handleChange: this.props.handleChange, // TODO: not currently recieving handleChange
          handleSubmit: this.props.handleSubmit,
          filter: this.props.filter}
      );
      return (
        <div className='panel'>
          {/* Close Button */}
          <div
            className='glyphicon glyphicon-remove pull-right'
            onClick={this.props.handleCancel}
          />
          {/* Form */}
          {formInstance}
        </div>
      );
    }
  }
}

class CustomFilterEditor extends React.Component {
  // This is kinda sloppy.
  // I'm closing over the dispatch table here in order
  // to make sure everything is defined at runtime.
  typeToFormComponent = filter_type => {
    // WHEN A NEW COLUMN TYPE IS ADDED, UPDATE THIS
    return {
      column_filter: ColumnFilterForm,
      column_filter_by_position: ColumnFilterByPositionForm
    }[filter_type];
  };

  render() {
    // Default to rendering nothing
    // Otherwise render the form for the appropriate column
    if (this.props.filter === null) {
      return <div></div>;
    } else {
      let formInstance = React.createElement(
        this.typeToFormComponent(this.props.filter.type),
        { handleChange: this.props.handleChange, // TODO: not currently recieving handleChange
          handleSubmit: this.props.handleSubmit,
          filter: this.props.filter}
      );
      return (
        <div className='panel'>
          {/* Close Button */}
          <div
            className='glyphicon glyphicon-remove pull-right'
            onClick={this.props.handleCancel}
          />
          {/* Form */}
          {formInstance}
        </div>
      );
    }
  }
}

class ColumnFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {uses_db_for_search: this.props.filter.uses_db_for_search};
    this.titleCasedModelName = _.startCase(_.toLower(this.props.filter.model));
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // Handle Validation Of Column Filters
  // Update
  handleChange(event) {
    if (event.target.type === 'checkbox') {
      this.setState({[event.target.name]: !(this.state[event.target.name])});
      // console.log("Setting: ", {[event.target.name]: !(this.state[event.target.name])})
    } else {
      this.setState({[event.target.name]: event.target.value})
    }
  }

  handleSubmit(event) {
    // Potentially Do Client Side Validation
    // Construct the new filter and pass it to parent's handleSubmit
    let newFilter = _.cloneDeep(this.props.filter);
    newFilter.config.n = this.state.n || this.props.filter.config.n;
    newFilter.name = `First ${newFilter.config.n} ${this.titleCasedModelName} Fields`;
    newFilter.uses_db_for_search = this.state.uses_db_for_search || false;
    newFilter.widget_type = this.state.widget_type || this.props.filter.widget_type;
    this.props.handleSubmit(newFilter);
    event.preventDefault(); // Don't redirect
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Display first __ fields from the {this.titleCasedModelName} Schema:
          <input
            name="n"
            type='number'
            min="1"
            max="80"
            defaultValue={this.props.filter.config.n}
            onChange={this.handleChange}
          />
        </label>
        <br/>
        <label>
          Please enter widget_types as a comma-separated list:
          <br/>
          <textarea
            name="widget_type"
            type='text'
            pattern='^.*$'
            defaultValue={this.props.filter.widget_type}
            onChange={this.handleChange}
          />
        </label>
        <br/>
        <label>
          Use database for search?
          <input
            name="uses_db_for_search"
            type='checkbox'
            defaultChecked={this.state.uses_db_for_search}
            onChange={this.handleChange}
            />
        </label>
        <br/>
        <ul>
          <li>Valid widget types include:
            <ul>
              <li>'multi_select'</li>
              <li>'range_slider'</li>
              <li>'year_picker'</li>
              <li>'year_month_picker'</li>
              <li>'date_picker'</li>
            </ul>
          </li>
        </ul>
        <input type='submit' value='Save'/>
      </form>
    );
  }
}

class BasicFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uses_db_for_search: this.props.filter.uses_db_for_search,
      widget_type: this.props.filter.widget_type
    };
    this.titleCasedModelName = _.startCase(_.toLower(this.props.filter.model));
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // Handle Validation Of Column Filters
  // Update
  handleChange(event) {
    if (event.target.type === 'checkbox') {
      this.setState({[event.target.name]: !(this.state[event.target.name])});
      // console.log("Setting: ", {[event.target.name]: !(this.state[event.target.name])})
    } else {
      this.setState({[event.target.name]: event.target.value})
    }
  }

  handleSubmit(event) {
    // Potentially Do Client Side Validation
    // Construct the new filter and pass it to parent's handleSubmit
    let newFilter = _.cloneDeep(this.props.filter);
    newFilter.uses_db_for_search = this.state.uses_db_for_search || false;
    newFilter.widget_type = this.state.widget_type || 'multi_select';
    this.props.handleSubmit(newFilter);
    event.preventDefault(); // Don't redirect
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Use database for search?
          <input
            name="uses_db_for_search"
            type='checkbox'
            defaultChecked={this.state.uses_db_for_search}
            onChange={this.handleChange}
            />
        </label>
        <br/>
        <label>
          Widget type (Options: "multi_select", "range_slider", "year_picker", "year_month_picker", "date_picker")
          <input
            name='widget_type'
            type='text'
            value={this.state.widget_type}
            onChange={this.handleChange}
            />
        </label>
        <br/>
        <input type='submit' value='Save'/>
      </form>
    );
  }
}

class ColumnFilterByPositionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {uses_db_for_search: this.props.filter.uses_db_for_search};
    this.titleCasedModelName = _.startCase(_.toLower(this.props.filter.model));
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // Handle Validation Of Column Filters
  // Update
  handleChange(event) {
    if (event.target.type === 'checkbox') {
      this.setState({[event.target.name]: !(this.state[event.target.name])});
      // console.log("Setting: ", {[event.target.name]: !(this.state[event.target.name])})
    } else {
      this.setState({[event.target.name]: event.target.value})
    }
  }

  handleSubmit(event) {
    // Potentially Do Client Side Validation
    // Construct the new filter and pass it to parent's handleSubmit
    let newFilter = _.cloneDeep(this.props.filter);
    newFilter.config.field_positions = this.state.field_positions || this.props.filter.config.field_positions;
    newFilter.widget_type = this.state.widget_type || this.props.filter.widget_type;
    newFilter.uses_db_for_search = this.state.uses_db_for_search || false;
    this.props.handleSubmit(newFilter);
    event.preventDefault(); // Don't redirect
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          Please enter the positions of fields to include
          from the {this.titleCasedModelName} Schema
          as a comma-separated list:
          <input
            name="field_positions"
            type='text'
            pattern='^\d+(,\d+)*$'
            defaultValue={this.props.filter.config.field_positions}
            onChange={this.handleChange}
          />
        </label>
        <br/>
        <label>
          Please enter widget_types as a comma-separated list:
          <br/>
          <textarea
            name="widget_type"
            type='text'
            pattern='^.*$'
            defaultValue={this.props.filter.widget_type}
            onChange={this.handleChange}
          />
        </label>
        <br/>
        <label>
          Use database for search?
          <input
            name="uses_db_for_search"
            type='checkbox'
            defaultChecked={this.state.uses_db_for_search}
            onChange={this.handleChange}
            />
        </label>
        <br/>
        <input type='submit' value='Save'/>
        <ul>
          <li>Valid widget types include:
            <ul>
              <li>'multi_select'</li>
              <li>'range_slider'</li>
              <li>'year_picker'</li>
              <li>'year_month_picker'</li>
              <li>'date_picker'</li>
            </ul>
          </li>
          <li>Field positions should start at 1. If you want the 1st, 2nd, 5th, and 8th fields, you would enter "1,2,5,8".</li>
        </ul>
      </form>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(PageFilters, {
  mobxState: observable
});

export default observer(PageFilters);

// export default ProjectOverview;
