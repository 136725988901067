import React from 'react';
import IdleTimer from "./IdleTimer";

// USAGE
export const timeoutLogout = (secondsToTimeout) => {
  const timer = new IdleTimer({
    timeout: secondsToTimeout, // 60*60, //expire after 1 hour
    onTimeout: () => {
      console.log('In Timeout');
      window.location.href = '/logout';
    },
    // onExpired: () => {
    //   //do something if expired on load
    //   console.log('Expired page');
    //   //setIsTimeout(true);
    // }
  });

  return () => {
    timer.cleanUp();
  };
};
