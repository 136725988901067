import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from '../../../badges/Badge';
import GridColumn from '../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../services/UrlSniffer'; //work with rails paths to get project token

//filters
import DockRenderer from '../../../filters/DockRenderer';
import FilterQuickAction from '../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//highcharts
import BarChart from '../../../charts/basics/BarChart';
import PieChart from '../../../charts/basics/PieChart';
import LineChart from '../../../charts/basics/LineChart';

//percentage donut
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull } from '../../../services/Utilities';
// import FontAwesome from 'react-fontawesome'
// import faStyles from 'font-awesome/css/font-awesome.css'

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class ProjectOverview extends React.Component {
  mobxState = {
    customOptions: [],
    cardData: {
      contractValue: 0,
      contractCount: 0,
      contractSupplierCount: 0,
      supplierContractSum: [], //charts
      on_contract_count: [], //charts
      dbe_count: [], //charts
      cat_1_top_sum: [], //charts
      projectCat1Count: [],
      projectCat2Count: [],
      projectCat3Count: [],
      projectCat4Count: [],
      year_month_sum_line: []
    },
    tableData: {
      projects: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    schemaDefinition: {
      categories: 4
    },
    //categoryBootstrapWidth: 'col-lg-3', //makes charts overlap if you enable default
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [] //['Cat_Desc', 'Cat1', 'Cat2', 'Cat3', 'Cat4', 'year', 'month'] //autobuilds filters and clear functions
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = window.$('#table_rows_dropdown').val();

    //merge the filter model with the amount of rows selected
    let models_with_rows = { ...this.mobxState.selectedModels, ...{ table_rows: table_rows } };

    //card data
    axios.get('/microservices/v1/analytics/project_overview', { params: models_with_rows }).then(response => {
      this.mobxState.cardData = response.data;
    });

    //card data
    axios.get('/v1/custom_schemas', { params: { name: 'insights' } }).then(response => {
      //get the amount of categories and adjust based on that
      console.log('SCHEMAS', response.data.custom_schemas[0].schema_definition);
      this.mobxState.schemaDefinition = response.data.custom_schemas[0].schema_definition;
      this.mobxState.categoryBootstrapWidth = 'col-lg-' + 12 / this.mobxState.schemaDefinition.categories;
      let catFilters = [];
      if (this.mobxState.schemaDefinition.categories == 1) {
        catFilters = ['Cat1'];
      } else if (this.mobxState.schemaDefinition.categories == 2) {
        catFilters = ['Cat1', 'Cat2'];
      } else if (this.mobxState.schemaDefinition.categories == 3) {
        catFilters = ['Cat1', 'Cat2', 'Cat3'];
      } else {
        catFilters = ['Cat1', 'Cat2', 'Cat3', 'Cat4'];
      }

      axios
        .get('/v1/custom_schemas', { params: { name: 'project' } }) //query the project custom field schema for all projects
        .then(async response => {
          //get map of field labels from API
          this.mobxState.customSchema = response.data;
          //get map of field labels from API
          this.mobxState.customOptions = response.data;
          console.log('this.mobxState.customSchema.custom_schemas[0]');
          console.log(this.mobxState.customSchema.custom_schemas[0]);
          console.log('customOptions', this.mobxState.customOptions);

          //LOOP THRU THE SCHEMA DEFINITION FIELDS TO CREATE A MAP FOR customOptions
          //add to filter array
          //figure out why filters aren't actually filtering.. probably need where clause
          let schemaDefinition = this.mobxState.customSchema.custom_schemas[0]['schema_definition'];
          let customSchemaResponse = await this.processSchema(schemaDefinition);
          let customFieldAliasMap = customSchemaResponse.fieldMap;
          let customFiltersPreTrim = customSchemaResponse.filterArray;
          //only alpha fields as filers
          let customFilters = customFiltersPreTrim.filter(
            value => value.includes('Supplier_T') || value.includes('Supplier_A')
          );

          //combine all cat and tag custom filters
          this.mobxState.filterModelNames = [...catFilters, ...['Cat_Desc', 'year', 'month'], ...customFilters];

          //include any custom field names not in db
          this.mobxState.customOptions = {
            ...this.mobxState.customOptions,
            ...customFieldAliasMap,
            ...{
              year: 'Created Year',
              month: 'Created Month',
              Cat1: 'Category 1',
              Cat2: 'Category 2',
              Cat3: 'Category 3',
              Cat4: 'Category 4',
              ID: 'Row ID',
              Cat_ID: 'Project ID',
              Cat_Desc: 'Project Desc',
              DateCreated: 'Created Date',
              URL_Addr: 'URL',
              Ref_XDate: 'Ref Date',
              proj_desc: 'Desc 2',
              Annual_Period: 'Year',
              MostRecent_Period: 'Most Recent Year',
              RFI_Scoring: 'RFI Scoring',
              Pay_Term: 'Pmt Term',
              Shipment_qty: 'Ship Qty',
              RFI_End_Time: 'RFI Due Date',
              RFP_End_Time: 'RFP Due Date',
              Base_Currency: 'Currency',
              Mfg_Name: 'Mfg',
              volume: 'Volume',
              LastMod_Date: 'Last Modified',
              Proj_Type: 'Type',
              ProjGrp: 'Group',
              RoundNo: 'Round',
              MultiRound: 'Multi Round',
              Supp_No: 'Supp No',
              supplier: 'Supplier'
            }
          };

          //after ag grid is set up, get the rest of the data
          //table data load
          axios
            .get('/microservices/v1/analytics/project_overview_data', { params: models_with_rows })
            .then(response => {
              this.mobxState.tableData = response.data;
              //set the column definitions for ag grid, look into buildColumnDefs class to see options
              this.mobxState.columnDefsProjects = gridColumns.buildColumnDefs({
                customOptions: this.mobxState.customOptions,
                columnArray: ['project_overview'],
                projectType: 0,
                isScenario: 0,
                customFieldSchema: this.mobxState.customSchema.custom_schemas[0],
                dataType: 'project_overview',
                categoryNum: this.mobxState.schemaDefinition.categories
              });

              axios.get('/v1/resources/index_filter', { params: { page_id: 7 } }).then(response => {
                this.mobxState.pivotFilter = response.data.resources;
              });
            });
        })
        .catch(console.log.bind(console)); //error catcher
    }); //end insights custom_schemas axios call
  }; //end fetchData

  //return customField list from schema
  processSchema = async passedSchema => {
    let customFields = {};
    let customFilters = [];
    let i = 0;
    if (typeof passedSchema !== 'undefined' && passedSchema) {
      Object.entries(passedSchema).map(([key, value]) => {
        customFields[value.flex_field_name] = value.field_alias;
        console.log('customFields', customFields);
        customFilters.push(value.flex_field_name);
      });
    }
    return { fieldMap: customFields, filterArray: customFilters };
  };

  deleteRequest = toSendArr => {
    console.log('IN DELETION');
    axios.post('/v1/ref_categories/batch_destroy', { id_set: toSendArr }).then(response => {
      console.log(response);
      this.fetchData();
    });
  };
  //////////////////END HTTP/////////////////

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    console.log('Handle Filter Callback: ');
    console.log('selectedFilter ', selectedFilter);
    console.log('filterParam ', filterParam);
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //handles dropdown and chart filters
  handleClear = () => {
    //in the state, clear the model. This goes as params to http request
    this.mobxState.selectedModels = {};
    //also store the explicit cleared selections in the original form. This goes back to the filter as selected values
    //loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
    //from ["supplier", "item"] to {"supplier":[], "item":[]}
    const optionsClear = {};
    for (const key of this.mobxState.filterModelNames) {
      optionsClear[key] = [];
    }
    this.mobxState.selectedOptions = optionsClear;
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };

  render() {
    const hcHeight = '300px';

    return (
      <div>
        {/* Small Left Toggle */}
        <div onClick={this.toggleDock} className='sidebar_button filter_toggle'>
          <i className='glyphicon glyphicon-chevron-left'></i>
        </div>

        <div className='jumbotron jumbotron_full_width'>
          <div className='jumbotron_full_width_container'>
            <b className='dash_header'>Executive Project Details</b>
          </div>
        </div>

        {/* BADGES */}
        <div className='row'>
          <div className='col-lg-4'>
            <Badge
              badgeNumber={zeroNull(this.mobxState.cardData.projectCount).toLocaleString('en-US')}
              badgeName={'Projects'}
              faClass={'briefcase'}
            />
          </div>
          <div className='col-lg-4'>
            <Badge
              badgeNumber={zeroNull(this.mobxState.cardData.rfpCount).toLocaleString('en-US')}
              badgeName={'RFPs'}
              faClass={'list-alt'}
            />
          </div>
          <div className='col-lg-4'>
            <Badge
              badgeNumber={zeroNull(this.mobxState.cardData.rfiCount).toLocaleString('en-US')}
              badgeName={'RFIs'}
              faClass={'list-alt'}
            />
          </div>
        </div>

        <br />
        <br />

        {/* SUMMARY CHARTS */}
        <div className='row'>
          <div className='col-lg-6'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>Projects With an RFP Due Date</b>
                <span className='pull-right'>
                  <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                </span>
              </div>
              <div className='panel-body'>
                <div className='col-md-12'>
                  <LineChart
                    renderInDivId={'line_rfp_end_count'}
                    handleFilterFunction={this.handleFilter}
                    lineData={[this.mobxState.cardData.rfpEndCount]}
                    lineTitle={'Upcoming RFPs'}
                    lineYTitle={'RFPs'}
                    height={hcHeight}
                    filterParam={'rfp_year_month'}
                    lineType={'area'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>Projects With an RFI Due Date</b>
                <span className='pull-right'>
                  <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                </span>
              </div>
              <div className='panel-body'>
                <div className='col-md-12'>
                  <LineChart
                    renderInDivId={'line_rfi_end_count'}
                    handleFilterFunction={this.handleFilter}
                    lineData={[this.mobxState.cardData.rfiEndCount]}
                    lineTitle={'Upcoming RFIs'}
                    lineYTitle={'RFIs'}
                    height={hcHeight}
                    filterParam={'rfi_year_month'}
                    lineType={'area'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END CHART ROW */}
        <br />
        {/* SUMMARY CHARTS */}
        <div className='row'>
          {this.mobxState.schemaDefinition.categories >= 1 && (
            <div className={this.mobxState.categoryBootstrapWidth}>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg'>
                  <b>Category 1 Projects</b>
                  <span className='pull-right'>
                    <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                  </span>
                </div>
                <div className='panel-body'>
                  <div className='col-lg-12'>
                    <BarChart
                      renderInDivId={'cat1_bar'}
                      handleFilterFunction={this.handleFilter}
                      barData={this.mobxState.cardData.projectCat1Count}
                      barTitle={'Projects'}
                      height={hcHeight}
                      filterParam={'Cat1'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.mobxState.schemaDefinition.categories >= 2 && (
            <div className={this.mobxState.categoryBootstrapWidth}>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg'>
                  <b>Category 2 Projects</b>
                  <span className='pull-right'>
                    <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                  </span>
                </div>
                <div className='panel-body'>
                  <div className='col-lg-12'>
                    <BarChart
                      renderInDivId={'cat2_bar'}
                      handleFilterFunction={this.handleFilter}
                      barData={this.mobxState.cardData.projectCat2Count}
                      barTitle={'Projects'}
                      height={hcHeight}
                      filterParam={'Cat2'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.mobxState.schemaDefinition.categories >= 3 && (
            <div className={this.mobxState.categoryBootstrapWidth}>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg'>
                  <b>Category 3 Projects</b>
                  <span className='pull-right'>
                    <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                  </span>
                </div>
                <div className='panel-body'>
                  <div className='col-lg-12'>
                    <BarChart
                      renderInDivId={'cat3_bar'}
                      handleFilterFunction={this.handleFilter}
                      barData={this.mobxState.cardData.projectCat3Count}
                      barTitle={'Projects'}
                      height={hcHeight}
                      filterParam={'Cat3'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.mobxState.schemaDefinition.categories == 4 && (
            <div className={this.mobxState.categoryBootstrapWidth}>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg'>
                  <b>Category 4 Projects</b>
                  <span className='pull-right'>
                    <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                  </span>
                </div>
                <div className='panel-body'>
                  <div className='col-lg-12'>
                    <BarChart
                      renderInDivId={'cat4_bar'}
                      handleFilterFunction={this.handleFilter}
                      barData={this.mobxState.cardData.projectCat4Count}
                      barTitle={'Projects'}
                      height={hcHeight}
                      filterParam={'Cat4'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* END CHART ROW */}

        <br />
        {/* Select Filter allows you to select the pivot_settings config to display*/}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>Saved Project Analysis Configurations</b>
              </div>
              <div className='panel-body'>
                {/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
                <Select
                  onChange={e => this.clickChild(e)}
                  options={this.mobxState.pivotFilter}
                  closeMenuOnSelect={true}
                  maxMenuHeight={400}
                  placeholder={'Select From List of Saved Project Tables'}
                />
              </div>
            </div>
          </div>
        </div>
        {/* PROJECT GRID */}
        <div className='row'>
          <div className='col-lg-12'>
            {/* AG Grid */}
            <GridRenderer
              gridHeight={'650px'}
              columnDefs={this.mobxState.columnDefsProjects}
              rowData={this.mobxState.tableData.projects}
              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={7}
              savedState={this.mobxState.pivotState}
              isPivotMode={true}
              gridTitle={'Project Details'}
              setClick={click => (this.clickChild = click)}
              deleteRequest={this.deleteRequest}
              idColumn={'Cat_ID'}
              modelsToUpdateArr={[{ url: 'v1/ref_categories/', idName: 'sourcing_id' }]}
              //modelsToUpdateArr={false}

              singleClickEdit={true}
              suppressClickEdit={false}
              isSaveable={true}
              isAwardable={false}
              isDeletable={false}
            />
          </div>
        </div>
        {/* END CHART ROW */}

        <br />
        {/* Sidebar */}
        <DockRenderer
          position={'right'}
          size={this.mobxState.dockOptions.size}
          dimMode={'none'}
          isVisible={this.mobxState.dockOptions.isVisible}
          onVisibleChange={this.handleVisibleChange}
          onSizeChange={this.handleSizeChange}
          fluid={true}
          toggleDock={this.toggleDock}
          handleClear={this.handleClear}
          value={this.mobxState.selectedOptions}
          options={this.mobxState.cardData}
          closeMenuOnSelect={true}
          maxMenuHeight={400}
          handleFilter={this.handleFilter}
          customOptions={this.mobxState.customOptions}
          filterModelNames={this.mobxState.filterModelNames}
        />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ProjectOverview, {
  mobxState: observable
});

export default observer(ProjectOverview);

// export default ProjectOverview;
