import React from 'react';
import moment from 'moment';

// Formatters - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//allows for custom definition of decimals based on decimalSpots param
export function customDecimalFormatter(decimalSpots) {
  return (params) => {
    // if (decimalSpots > 0) {
    if(params.value) {
      decimalSpots = decimalSpots || 2;
      var i;
      let decimalMultiplier = '1';
      //loops over the decimal spots and adds 0s to the multiplier used in actual rounding logic
      for (i = 1; i < decimalSpots; i++) {
        decimalMultiplier += '0';
      }
      //coerce to numeric
      decimalMultiplier = decimalMultiplier + 0;
      //actual rounding below
      var n = Math.round(params.value * decimalMultiplier) / decimalMultiplier;
      var parts=n.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    }
    // }
    // else {
    //   return Math.round(params.value)
    // };
  }
}

export function dateFormatter(params) {
  let formattedDate = "";
  if (params.value){
    //remove the Z because MSSQL adds it in the query
    formattedDate = moment(params.value.replace('Z','')).format('YYYY-MM-DD');
  }
  else {
    formattedDate = params.value;
  };
  return formattedDate; //moment(params.value).format('MM/DD/YYYY');
}

//(params object, what logic to highlight, css object of cell style)
export function conditionalCellStyler(params, threshold, styleObject) {
  if (eval(threshold)) {
    //Here you can check the value and based on that you can change the color
    //mark police cells as color passed in
    return styleObject;
  } else {
    return null;
  }
}

export function percentWidth(percentage){
    var div = window.$('.ag-body-viewport');  // HTML CLASS of Grid
    var parent = div.parent();
    var width = parent.width() * percentage/100;  // Pixel calculation
    console.log('width', width)
    return width;
}

export function jsonFormatter(params) {
    return JSON.stringify(params.value);
}

export function twoDecimalPaddedFormatter(params) {
    var n = Math.round(params.value * 1000) / 1000;
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? ("." + parts[1] + '00').slice(0,3) : ".00");
}

export function numberParser(params) {
  return Number(params.newValue);
}

export function decimalFormatter(params) {
    var n = Math.round(params.value * 100) / 100;
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

export function threeDecimalFormatter(params) {
    var n = Math.round(params.value * 1000) / 1000;
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

export function roundFormatter(params) {
    var n = Math.round(params.value);
    var parts=n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

export function percentFormatter(params) {
  return (`${params.value}%` || "");
}

export function percentThreeDecimalFormatter(params) {
  try{
    var n = Math.round(params.value * 1000) / 1000;
    var parts=n.toString().split(".");
    var numStr = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    return (`${numStr}%` || "")
  } catch (e){
    return ''
  }
}

export function percentDifferenceFormatter(params){
  let difference = params.value - 100;

  if (difference >= 0) {
    return `+${difference}%`;
  } else {
    return `${difference}%`;
  }
}

export function ratingFormatter(scale){
  return function(params){
    return "★".repeat(params.value || 0) + "☆".repeat(scale - (params.value || 0));
  }
}
// Color Gradient For Table Scaled Between Minimum and Maximum
// For a table
export function colorScaleFormatter(min, max, minColor, maxColor){
  return (params) => {
    if ((min <= params.value) && (params.value <= max)){
      let generated = calculateColorAlongGradient( minColor, maxColor, percentAlongScale(min, max, params.value) );
      return { background: `rgb(${generated.red}, ${generated.green}, ${generated.blue})` }
    }
  }
}
// Helpers - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// https://stackoverflow.com/questions/22218140/calculate-the-color-at-a-given-point-on-a-gradient-between-two-colors
export function calculateColorAlongGradient(startColor, stopColor, percent){
  return {
    red: Math.round(startColor.red + (percent * (stopColor.red - startColor.red))),
    green: Math.round(startColor.green + (percent * (stopColor.green - startColor.green))),
    blue: Math.round(startColor.blue + (percent * (stopColor.blue - startColor.blue))),
  }
}
export function percentAlongScale(min, max, value){
  return (value - min) / (max - min);
}
// Comparators - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export function numericComparator(valueA, valueB, nodeA, nodeB, isInverted){
  return valueA - valueB;
}

// deconstructedAvgFnFactory - Creates aggFunc and valueGetter to use over derived statistics.
// Parameters:
//  countColName: field name containing number of observations in a sample
//  totalColName: field name containing sum of observed values for a sample
// Returns:
//  {valueGetter: avgValueGetter, aggFunc: avgAggFunc} for use in columnDefs
// Explanation:
//  Due to size and computation constraints it doesn't make sense in many case to return ALL
//  of a resource (e.g. Transactions). Rather we want to group entries by some criteria (e.g. Category or Supplier)
//  We can't just return averages though since that runs into issues where groups are weighted evenly even though they
//  have different numbers of observations.  (Mean of means is not equal to the real mean).
//  To get around this, instead we want the weighted average where the number of samples informs the calculation.
// See Further:
//  https://math.stackexchange.com/questions/2091521/how-do-i-calculate-a-weighted-average-from-two-averages
//  https://www.ag-grid.com/javascript-grid-aggregation/
export function weightedAverageFunctionFactory(countColName, totalColName){
  let avgValueGetter = params  => {
      if (!params.node.group) {
          // no need to handle group levels - calculated in the 'avgAggFunc'
          return createValueObject(params.data[countColName], params.data[totalColName]);
      }
  };
  let avgAggFunc = values => {
      var countSum = 0;
      var totalSum = 0;
      values.forEach(function(value) {
          if (value && value[countColName]) {
              countSum += value[countColName];
          }
          if (value && value[totalColName]) {
              totalSum += value[totalColName];
          }
      });
      return createValueObject(countSum, totalSum);
  };
  let createValueObject = (transaction_count, transaction_total) => {
      return {
          [countColName]: transaction_count,
          [totalColName]: transaction_total,
          toString: function() {
              return ((transaction_count && transaction_total) ? transaction_total / transaction_count : 0).toString();
          }
      }
  };
  return {valueGetter: avgValueGetter, aggFunc: avgAggFunc}
};
