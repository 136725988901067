import React from 'react';
//icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import {zeroNull, extractToken} from '../../services/Utilities';
import NumericInput from 'react-numeric-input';

class BidQuantitySuppliedInput extends React.Component {

  render(){
    return(
      <div className='row'>
        <div className='col-lg-2'>
          <div>
            <FontAwesomeIcon
              icon={['fad', 'calculator']}
              mask={['fas', 'circle']}
              size='4x'
              transform='shrink-6'
              color='#4CABA9'
              style={{ marginTop: '5px' }}
            />
          </div>
        </div>
        <div className='col-lg-10'>
          <b>{this.props.fieldAlias} - Optional</b>
          <NumericInput
            onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
            className='form-control'
            name='bidQuantityInput'
            style={false}
            step={1}
            placeholder='Quantity if different from item'
          />
        </div>
      </div>
    )//end return
  }//end render

}//end class

export default BidQuantitySuppliedInput;
