import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import { zeroNull, extractToken } from '../../../services/Utilities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
const projectId = extractToken(window.location.pathname, '/', 3);

//handles rendering form components and their validation
class NotInProjectSupplierIdSelect extends React.Component {
  mobxState = {
    supplierIdSelection: [], //options for select box
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //data loader
  fetchData = () => {
    //FORM LOAD
    axios.get('/v1/projects/'+projectId+'/project_suppliers/not_in_project_index_filter', { params: {} }).then((response) => {
      this.mobxState.supplierIdSelection = response.data;
    });
    //END FORM LOAD
  }; //end fetchData()

  render() {
    return (
      <div className='row'>
        <div className='col-lg-1'>
          <div>
            <FontAwesomeIcon
              icon={['fad', 'briefcase']}
              mask={['fas', 'circle']}
              size='4x'
              transform='shrink-6'
              color='#4CABA9'
              style={{ marginTop: '5px' }}
            />
          </div>
        </div>
        <div className='col-lg-11'>
          <b>{this.props.fieldAlias}</b>
          <Select
            onChange={(e) => {
              this.props.handleFormChange(this.props.formField, e.value);
            }}
            options={this.mobxState.supplierIdSelection}
            closeMenuOnSelect={true}
            maxMenuHeight={400}
            placeholder={this.props.placeholder}
          />
        </div>
      </div>
    ); //end return
  } //end render
} //end class

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(NotInProjectSupplierIdSelect, {
  mobxState: observable,
});

export default observer(NotInProjectSupplierIdSelect);
