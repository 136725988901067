import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//loops through filterModelNames property, and renders a filter for each, including onChange callback
//NOTE about 10% slower than manually rendering the filters
class FilterQuickAction extends React.Component {
    render() {
    	return(
	    	<span>
					<span onClick={this.props.handleClear} className="clear_button">
            <FontAwesomeIcon icon={['fal', 'fast-backward']} transform='' style={{}} />
					</span>

				&nbsp; &nbsp;

					<span onClick={this.props.toggleDock} className="filter_toggle">
            <FontAwesomeIcon icon={['fal', 'filter']} transform='' style={{}} />
					</span>

			</span>
		)
	}
}

export default FilterQuickAction
