import axios from 'axios';

export const grabSystemAliases = async () => {
  let output = {};
  // Grab map of user (or developer) defined field aliases from the api and return it.
  // Most often this will be merged by pages into mobxState.customOptions
  // The page must merge the options themselves to allow them to override defaults
  await axios
    .get('/v1/custom_schemas', { params: { name: 'system_aliases' } }) //query the project custom field schema for all projects
    .then(response => {
      Object.entries(response.data.custom_schemas[0].schema_definition).forEach(([modelName, fields]) => {
        fields.map(({ field_name, field_alias }) => {
          output[`${modelName}__${field_name}`] = field_alias; // Prefer local definition
        });
      });
    })
    .catch(console.log.bind(console));
  return output;
};

export const grabAndMergeSystemAliases = async (target, options={}) => {
  let system_aliases = await grabSystemAliases();
  Object.assign(target,
    options.override ?
    system_aliases :
    Object.assign(system_aliases, target)
  );
};

export default grabAndMergeSystemAliases;

