import React from 'react';
import PropTypes from 'prop-types';

import {customDecimalFormatter} from '../../services/GridColumnFormatters'

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from '../HCGlobal';

const _ = require('lodash'); //extra utilities

//NOTE:
//  - Eye chart is something one off for current pitch.
//  - It's assumed that data is provided with the following (kinda nonsensical) mapping
//        name: Name of the toplevel category (custom_field_1)
//        label: Name of the lower level category (custom_field_2)
//        x:  Total spend for the toplevel category   (custom_field_1) (Get this via partition in query)
//        y:  Total spend for the subcategory         (custom_field_2)

class EyeChart extends React.Component {
  //fires on initial mount
  componentDidMount() {
    setHighChartsGlobals();
    this.loadChart();
  }

  // //mount data ON CHANGE
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.barData !== this.props.barData) {
      setHighChartsGlobals();
      this.loadChart();
    } //end if statement state check
  } //end component did update

  //load chart function with options and cart drawer
  //issue with separating options and chart drawer.. probably something to do with it being a js drawer.. and maybe an async issue
  loadChart = () => {
  };

  // A bit hacky, but nothing too terrible
  handleFilterFactoryLevel1 = datum => () => {
    this.props.handleFilterFunction('custom_fields.category__custom_field_1', [{label: `${datum.name}`, value: datum.name}]);
  }
  handleFilterFactoryLevel2 = datum => () => {
    this.props.handleFilterFunction('custom_fields.category__custom_field_2', [{label: `${datum.label}`, value: datum.label}]);
  }

  render() {
    // bars
    let makeRow = (i, colls) => {
      return (
        <React.Fragment key={`eye-chart-${i}`}>
          <div className='eye-chart-row'>
            <div className='eye-chart-row-background'></div>
            <div className='eye-chart-row-content'>{colls}</div>
          </div>
          <br/>
        </React.Fragment>
      )
    };
    let makeCol = (i, j, data) => {
      if (j === 0){
        return (
          <div key={`eye-chart-${i}-${j}`} data-value={`${JSON.stringify(data)}`} className='eye-chart-card eye-chart-card-primary'>
            <div className='eye-chart-card-content'>
              <div className='eye-chart-card-content-label' onClick={this.handleFilterFactoryLevel1(data)}>{data.name}</div>
              <div className='eye-chart-card-content-value'>${customDecimalFormatter(2)({value: data.x})}</div>
            </div>
          </div>
        )
      } else {
        return (
          <div key={`eye-chart-${i}-${j}`} data-value={`${JSON.stringify(data)}`} className='eye-chart-card eye-chart-card-secondary'>
            <div className='eye-chart-card-content'>
              <div className='eye-chart-card-content-label' onClick={this.handleFilterFactoryLevel2(data)}>{data.label}</div>
              <div className='eye-chart-card-content-value'>${customDecimalFormatter(2)({value: data.y})}</div>
            </div>
          </div>
        )
      }
    };
    let data = JSON.parse(JSON.stringify(this.props.barData)); // Deep copy so it shows in debug tools
    data = Object.values(_.groupBy(data, (x) => x.name));      // Group by custom_field_1
    data = data.sort((a,b) => (b[0].x - a[0].x));              // Order by custom_field_1's spend
    data = data.map(r => [{...r[0]}, ...r]);                   // Add an extra entry for custom_field_1
    // debugger;
    let rows = [];
    for (var i = 0; i < data.length; i++) {
      let cols = [];
      for (var j = 0; j < data[i].length; j++) {
        cols.push(makeCol(i, j, data[i][j]));
      }
      rows.push(makeRow(i, cols));
    }

    return (
      <div id={'outer-' + this.props.renderInDivId}>
        {/*<div id={this.props.renderInDivId} style={{ height: this.props.height }}></div>*/}
        <div style={{ height: this.props.height }}>
          <div className='eye-chart-container'>
            {rows}
          </div>
        </div>
      </div>
    );
  }
}
export default EyeChart;
