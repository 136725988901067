import React from 'react';
import { render } from 'react-dom';
import ReactDom from 'react-dom';

//other components built
import Badge from '../../../../badges/Badge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token
import SchemaForm from '../../../../forms/SchemaForm'; //the form renderer for custom fields

import ProjectName from '../../shared/ProjectName';

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { grabSystemAliases } from '../../../../services/SystemAliases';
import {
  zeroNull,
  deleteIfEmpty,
  extractToken,
  formatForTreeSelect,
  determineCategoryLabel,
  newJsonFieldSchema
} from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditProjectForm from './EditProjectForm';

const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

class Edit extends React.Component {
  projectId = extractToken(window.location.pathname, '/', 3);
  mobxState = {
    projectFieldSchema: {
      item_level: {},
      bid_level: {},
      freight_bid_level: {},
      product_decimal_spots: 2,
      freight_decimal_spots: 2
    },
    formState: {
      formValid: true
    },
    formOptions: {},
    customOptions: {
      project__name: 'Project Name',
      project__department: 'Department',
      project__status: 'Project Status',
      project__savings: 'Project Savings',
      supplier: 'Bidder'
    },
    projectData: {},
    project_type: 'Project', //if project type is not "Freight" freight related data will be hidden
    isFreight: null //for toggling freight related inputs
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////

  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = window.$('#table_rows_dropdown').val();

    //set up custom options for components, then get data for components
    // axios.get("/v1/ref_cmfields_labels")
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}) // Prefer existing values
      };
    });

    // Determine if client categorizes their projects
    // And if so what the categories are and what the options for them are
    Promise.all([
      axios.get('/v1/custom_schemas', { params: { name: 'project_category_settings' } }),
      axios.get('/v1/custom_schemas', { params: { name: 'category_selector_settings' } })
    ]).then(([projectCategorySettingsResp, categorySelectorSettingsResp]) => {
      this.mobxState.projectCategorySettings =
        projectCategorySettingsResp.data &&
        projectCategorySettingsResp.data.custom_schemas &&
        projectCategorySettingsResp.data.custom_schemas[0] &&
        projectCategorySettingsResp.data.custom_schemas[0]['schema_definition'];
      this.mobxState.categorySelectorSettings =
        categorySelectorSettingsResp.data &&
        categorySelectorSettingsResp.data.custom_schemas &&
        categorySelectorSettingsResp.data.custom_schemas[0] &&
        categorySelectorSettingsResp.data.custom_schemas[0]['schema_definition'];
      if (this.mobxState.projectCategorySettings && this.mobxState.projectCategorySettings.enabled) {
        //grab categories for category selection
        axios.post('/v1/categories_index', {}).then(response => {
          console.error('Got categories');
          let start = new Date();
          let tmpResult = formatForTreeSelect(
            response.data.categories,
            [
              r => (r.custom_fields && r.custom_fields.custom_field_1) || '',
              r => (r.custom_fields && r.custom_fields.custom_field_2) || '',
              r => (r.custom_fields && r.custom_fields.custom_field_3) || '',
              r => (r.custom_fields && r.custom_fields.custom_field_4) || '',
              r => (r.custom_fields && r.custom_fields.custom_field_5) || ''
            ],
            this.mobxState.categorySelectorSettings.show_id_in_label
          );
          // console.error(`formatted categories +${(new Date() - start) / 1000}s`);
          this.mobxState.formOptions = this.mobxState.formOptions || {};
          this.mobxState.formOptions['category_id'] = tmpResult;
          // console.error(`updates              +${(new Date() - start) / 1000}s`);
        });
      }
    });

    //project data load
    //load custom field schemas for project
    let projectSchemaPromise = axios
      .get('/v1/custom_schemas', { params: { name: 'project' } }) //query the project custom field schema for all projects
      .then(response => {
        //get map of field labels from API
        this.mobxState.customSchema = response.data;
      });
    let projectDataPromise = axios.get(`/v1/projects/${this.projectId}`).then(response => {
      this.mobxState.projectData = response.data;
      response.data.project_type === 'Freight' ? (this.mobxState.isFreight = true) : (this.mobxState.isFreight = false);
      // Short circuit the value and label for the selected category so it appears natural
      let temp = this.mobxState.projectData &&
        this.mobxState.projectData.category && {
          value: `${this.mobxState.projectData.category.id}`,
          label: determineCategoryLabel(this.mobxState.projectData.category, this.mobxState.categorySelectorSettings.show_id_in_label)
        };
      this.mobxState.projectData.initial_category_option = temp;
    });
    Promise.all([projectDataPromise, projectSchemaPromise]).then(() => {
      let keysInSchema = Object.keys(this.mobxState.customSchema.custom_schemas[0].schema_definition);
      let keysInProject = Object.keys(this.mobxState.projectData.custom_fields);
      let keysInEither = [...keysInSchema, ...keysInProject];
      keysInEither.forEach(k => {
        if (keysInSchema.includes(k) && keysInProject.includes(k)) {
          this.mobxState.projectData.custom_fields[k] = this.mobxState.projectData.custom_fields[k];
        } else if (keysInSchema.includes(k)) {
          this.mobxState.projectData.custom_fields[k] = null; // Insert it in project.custom_fields if missing
        } else if (keysInProject.includes(k)) {
          delete this.mobxState.projectData.custom_fields[k]; // Remove it from project.custom_fields if removed from schema
        }
      });
    });

    //set up custom options for components, then get data for components
    axios
      .get('/v1/projects/' + this.projectId + '/project_field_schemas', { params: { project_id: this.projectId } })
      .then(response => {
        //add to customOptions
        this.mobxState.customOptions = { ...this.mobxState.customOptions, ...response.data };
        //store the projectFieldSchema of the project to loop thru and create the form later
        this.mobxState.projectFieldSchema = response.data.project_field_schemas[0];
        console.log('projectFieldSchema', response.data.project_field_schemas[0]);
        //console.log('projectFieldSchema', this.mobxState.projectFieldSchema);
      })
      .catch(console.log.bind(console)); //error catcher
  }; //end fetchData() ///////////////

  //project form handlers/////////////
  handleProjectDataChange = (path, value) => {
    _.set(this.mobxState.projectData, path, value);
    this.handleProjectDataSubmit();
  };

  handleProjectDataSubmit = _.debounce(() => {
    return axios
      .put(`/v1/projects/${this.projectId}`, this.mobxState.projectData)
      .then(result => {
        console.log('PUT UPDATED WITH:', JSON.parse(JSON.stringify(this.mobxState.projectData)));
      })
      .catch(e => {
        // TODO: Work out error handling
        alert('Something went wrong. Please check your inputs and try again.');
      });
  });

  //project form handlers/////////////
  handleChangeWithReload = _.debounce((path, value) => {
    _.set(this.mobxState.projectData, path, value);
    return axios
      .put(`/v1/projects/${this.projectId}`, this.mobxState.projectData)
      .then(result => {
        console.log('PUT UPDATED WITH:', JSON.parse(JSON.stringify(this.mobxState.projectData)));
        //need reload because the sidebar needs to update based on the project options as well
        window.location.reload(false); //reload to show freight options
        // if(this.mobxState.projectData.project_type == 'Freight'){ // this.mobxState.isFreight = true; // } else { // this.mobxState.isFreight = false // }
      })
      .catch(e => {
        // TODO: Work out error handling
        alert('Something went wrong. Please check your inputs and try again.');
      });
  });

  //end project form handlers/////////

  handleAddField = fieldLevel => {
    console.log(fieldLevel);
    //get all schema fields in array
    let schemaFields = this.mobxState.projectFieldSchema[fieldLevel]
      ? Object.keys(this.mobxState.projectFieldSchema[fieldLevel])
      : []; //make sure to null handle w empty array if schema doesn't exist
    let schemaFieldNumbers = [];
    //loop thru custom fields
    schemaFields.forEach(element => {
      //push in the number only of the custom field
      schemaFieldNumbers.push(Math.trunc(extractToken(element, '_', 2)));
    });
    //sort array of fields
    let schemaFieldNumbersSorted = _.sortBy(schemaFieldNumbers);
    //take last field which is now suppposed to be the highest number
    let lastSchemaFieldNumber = _.last(schemaFieldNumbersSorted) || 0; //if null use 0 as the number
    //add 1 to the last schema field number and create a new custom field name
    let newCustomFieldName = 'custom_field_'.concat(lastSchemaFieldNumber + 1);
    //creates the actual json object for new schema that can be inserted into the jsonb field in db params: (name of new field, number to put into display_order)
    let newFieldSchema = newJsonFieldSchema(newCustomFieldName, lastSchemaFieldNumber + 1);
    //create a new schema entry for the new custom field.. combine the already existing schema and add an object to it with new field
    this.mobxState.projectFieldSchema[fieldLevel] = {
      ...this.mobxState.projectFieldSchema[fieldLevel],
      ...newFieldSchema
    };
    //SAVING SCHEMA CHANGES in db - this is a put not post request because you are editing a schema that already exists, you are just adding a key to a jsonb field here
    axios
      .put('/v1/projects/' + this.projectId + '/project_field_schemas/' + this.mobxState.projectFieldSchema.id, {
        [fieldLevel]: this.mobxState.projectFieldSchema[fieldLevel]
      }) //query the project custom field schema for all projects
      .then(response => {
        console.log(response);
      })
      .catch(console.log.bind(console)); //error catcher;
  };

  handleDeleteField = (fieldLevel, fieldName) => {
    console.log('Deleting: ', fieldLevel, fieldName);
    delete this.mobxState.projectFieldSchema[fieldLevel][fieldName];
    //SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/projects/' + this.projectId + '/project_field_schemas/' + this.mobxState.projectFieldSchema.id, {
        [fieldLevel]: this.mobxState.projectFieldSchema[fieldLevel]
      }) //query the project custom field schema for all projects
      .then(response => {
        console.log(response);
      })
      .catch(console.log.bind(console)); //error catcher;
  };

  //handles change in the projectFieldSchma by looking up the path of the property given in param with lodash and setting the property to the fieldValue
  handleFormChange = (propertyPath, fieldValue) => {
    //set the value in schema
    _.set(this.mobxState.projectFieldSchema, propertyPath, fieldValue);
    //extract the fieldLevel in order to use it in api call
    let fieldLevel = extractToken(propertyPath, '.', 0);
    console.log(this.mobxState.projectFieldSchema[fieldLevel]);
    //SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/projects/' + this.projectId + '/project_field_schemas/' + this.mobxState.projectFieldSchema.id, {
        [fieldLevel]: this.mobxState.projectFieldSchema[fieldLevel]
      }) //query the project custom field schema for all projects
      .then(response => {
        console.log(response);
      })
      .catch(console.log.bind(console)); //error catcher;
  };

  //DEBOUNCE the submit, so form doesn't freeze up if too many requests are sent
  handleSchemaDebouncedChange = (fieldName, selection) => {
    console.log('changing', fieldName, selection);
    this.mobxState.projectFieldSchema[fieldName] = selection;
    this.handleSchemaSubmit(fieldName, selection);
  };

  handleSchemaSubmit = _.debounce(async (fieldName, selection) => {
    console.log('submit', fieldName, selection);
    //SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/projects/' + this.projectId + '/project_field_schemas/' + this.mobxState.projectFieldSchema.id, {
        [fieldName]: selection
      }) //query the project custom field schema for all projects
      .then(response => {
        console.log(response);
      })
      .catch(console.log.bind(console)); //error catcher;
  });

  //////////////////END HTTP/////////////////

  render() {
    return (
      <div>
        <div
          className='jumbotron jumbotron_full_width'
          style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
        >
          <div className='jumbotron_full_width_container'>
            <div className='row'>
              <div className='col-sm-7'>
                <span className='fa-layers fa-fw fa-2x'>
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform='grow-3'
                    color='#009C9C'
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fal', 'folders']}
                    color='white'
                    transform='shrink-5'
                    style={{ marginTop: '3px' }}
                  />
                </span>
                &nbsp;
                <b className='dash_header'>
                  Edit <ProjectName />{' '}
                </b>
              </div>
              <div className='col-sm-1'></div>
              <div className='col-sm-4 gps'>
                <FontAwesomeIcon
                  icon={['fad', 'location-arrow']}
                  color='#53C8C5'
                  transform='grow-15 left-10'
                  style={{}}
                />
                <Link to={'/sourcing/projects/' + this.projectId + '/scenarios'} className='link_style'>
                  Next: Manage Bidder Invites
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/*
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg clearfix">
                  <b>Settings</b>
                  <span className="pull-right">
                  </span>
              </div>
              <div className="panel-body">

                <form>
                  <div className="row">
                    <div className="col-sm-4">
                      Project Release Date
                    </div>
                    <div className="col-sm-4">
                      RFP Close Date
                    </div>
                    <div className="col-sm-4">
                      RFI Close Date
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
        */}

        {/* PROJECT EDIT FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Project Details</b>
              </div>
              <div className='panel-body'>
                <EditProjectForm
                  schemaDefinition={
                    (this.mobxState.customSchema &&
                      this.mobxState.customSchema.custom_schemas &&
                      this.mobxState.customSchema.custom_schemas[0] &&
                      this.mobxState.customSchema.custom_schemas[0].schema_definition) ||
                    {}
                  }
                  projectData={this.mobxState.projectData}
                  projectCategorySettings={this.mobxState.projectCategorySettings}
                  formOptions={this.mobxState.formOptions}
                  handleChange={this.handleProjectDataChange}
                  handleChangeWithReload={this.handleChangeWithReload}
                  editUrl={`/v1/projects/${this.projectId}`}
                />
              </div>
            </div>
          </div>
          {/* END col-sm-12*/}
        </div>
        {/* END row*/}
        {/* END PROJECT EDIT FORM*/}

        {/* PROJECT EDIT FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Project Settings</b>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div>
                      <b>Decimal Spots on Reports</b>
                    </div>
                    <div>
                      <InputNumber
                        style={{ width: '100%', paddingTop: '3px' }}
                        min={1}
                        //defaultValue={'Number of decimals'}
                        onChange={e => this.handleSchemaDebouncedChange('product_decimal_spots', e)}
                        value={this.mobxState.projectFieldSchema.product_decimal_spots}
                      />
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div>
                      <b>Decimal Spots on Freight Reports</b>
                    </div>
                    <div>
                      <InputNumber
                        style={{ width: '100%', paddingTop: '3px' }}
                        min={1}
                        //defaultValue={'Number of decimals'}
                        onChange={e => this.handleSchemaDebouncedChange('freight_decimal_spots', e)}
                        value={this.mobxState.projectFieldSchema.freight_decimal_spots}
                      />
                    </div>
                  </div>
                  <div className='col-sm-4'></div>
                </div>
              </div>
            </div>
          </div>
          {/* END col-sm-12*/}
        </div>
        {/* END row*/}
        {/* END PROJECT EDIT FORM*/}

        {/* ITEM FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Custom Item Fields</b>
                <span className='pull-right'>
                  {/* Constraint Saved Message
                    <Bounce left when={this.mobxState.submitMessage}>
                      <b className="text-success" style={{marginRight: '1.5em',marginTop: '4px'}}>
                        Constraint Saved
                      </b>
                    </Bounce>
                    <Bounce left when={this.mobxState.formState.showHelperIcons}>
                      <span>
                        <FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{marginRight:'15px'}}/>
                      </span>
                    </Bounce>
                    */}
                  <button
                    type='button'
                    disabled={!this.mobxState.formState.formValid}
                    className='btn btn-primary btn-md'
                    onClick={() => this.handleAddField('item_level')}
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Add Custom Field
                  </button>
                </span>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className={'top_panel_info'}>
                      Custom fields for the items in the project. These fields describe or provide additional info about
                      the items. Sometimes referred to as lots.
                    </div>
                  </div>
                </div>
                <br />

                <SchemaForm
                  handleFormChange={this.handleFormChange}
                  handleDeleteField={this.handleDeleteField}
                  projectFieldSchema={this.mobxState.projectFieldSchema}
                  schemaLevel={'item_level'}
                />
              </div>
            </div>
          </div>
          {/* END col-sm-12*/}
        </div>
        {/* END row*/}
        {/* END ITEM FORM*/}

        {/* BID FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Custom Bid Fields</b>
                <span className='pull-right'>
                  {/* Constraint Saved Message
                    <Bounce left when={this.mobxState.submitMessage}>
                      <b className="text-success" style={{marginRight: '1.5em',marginTop: '4px'}}>
                        Constraint Saved
                      </b>
                    </Bounce>
                    <Bounce left when={this.mobxState.formState.showHelperIcons}>
                      <span>
                        <FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{marginRight:'15px'}}/>
                      </span>
                    </Bounce>
                    */}
                  <button
                    type='button'
                    disabled={!this.mobxState.formState.formValid}
                    className='btn btn-primary btn-md'
                    onClick={() => this.handleAddField('bid_level')}
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Add Custom Field
                  </button>
                </span>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className={'top_panel_info'}>
                      Custom fields for the bids in the project. These are used to gather additional,
                      customized information about a bid
                    </div>
                  </div>
                </div>
                <br />

                <SchemaForm
                  handleFormChange={this.handleFormChange}
                  handleDeleteField={this.handleDeleteField}
                  projectFieldSchema={this.mobxState.projectFieldSchema}
                  schemaLevel={'bid_level'}
                />
              </div>
            </div>
          </div>
          {/* END col-sm-12*/}
        </div>
        {/* END row*/}
        {/* END BID FORM*/}

        {/* FREIGHT BID FORM */}
        {this.mobxState.isFreight && (
          <div className='row'>
            <div className='col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg clearfix'>
                  <b>Custom Freight Bracket Bid Fields</b>
                  <span className='pull-right'>
                    {/* Constraint Saved Message
                  <Bounce left when={this.mobxState.submitMessage}>
                    <b className="text-success" style={{marginRight: '1.5em',marginTop: '4px'}}>
                      Constraint Saved
                    </b>
                  </Bounce>
                  <Bounce left when={this.mobxState.formState.showHelperIcons}>
                    <span>
                      <FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{marginRight:'15px'}}/>
                    </span>
                  </Bounce>
                  */}
                    <button
                      type='button'
                      disabled={!this.mobxState.formState.formValid}
                      className='btn btn-primary btn-md'
                      onClick={() => this.handleAddField('freight_bid_level')}
                    >
                      <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Add Custom Field
                    </button>
                  </span>
                </div>
                <div className='panel-body'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className={'top_panel_info'}>
                        {' '}
                        Custom fields for the bidder freight bracket bids in the project. These are used to gather
                        additional, customized information about a freight bid
                      </div>
                    </div>
                  </div>
                  <br />

                  <SchemaForm
                    handleFormChange={this.handleFormChange}
                    handleDeleteField={this.handleDeleteField}
                    projectFieldSchema={this.mobxState.projectFieldSchema}
                    schemaLevel={'freight_bid_level'}
                  />
                </div>
              </div>
            </div>
            {/* END col-sm-12*/}
          </div>
        )}

        {/* END row*/}
        {/* END FREIGHT BID FORM*/}
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Edit, {
  mobxState: observable
});

export default observer(Edit);

// export default ProjectOverview;
