import React from 'react';
import { render } from 'react-dom';

//other components built
import AsyncBadge from '../../../../badges/AsyncBadge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//forms
import ClientColorSchemeForm from './ClientColorSchemeForm';

//highcharts
import CustomChartRenderer from '../../../../charts/custom/CovidChartRenderer';

//percentage donut
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, deleteIfEmpty, formatForTreeSelect, isValidColor } from '../../../../services/Utilities';
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';
import { asyncLoadCharts } from '../../../../services/ChartUtilities';

// Override client globals for this one page
import { InjectedStyle } from '../../../../services/InjectedStyle';

const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

// DEVELOPER NOTES:
// - The easiest way to get searchable filter options is just
//   to use the existing setupFilters().
// - Make sure not to do anything to load existing filter options?

class ClientColorSchemePage extends React.Component {
  pageId = 215;
  mobxState = {
    cardData: {
      spendCount: 0,
      supplierCount: 0,
      contractCount: 0
    },
    chartRenderData: [],
    tableData: {
      spend_reclassification_rules: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    customFilterModelNames: [],
    filterOptions: {},
    customFilterOptions: {},
    customOptions: {},
    filtersForServer: {}, // Filters in normal form to use for the server
    defaultFormState: {}, // Form for new or editing reclassification rule
    // Form specific stuff
    schemaDefinition: {},
    editId: null,
    runAllResults: null
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //helpers for system filters
  //helpers for custom filters
  //setup filters
  setupFilters = async () => {};
  //data loader
  fetchData = () => {
    this.setupFilters();

    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}) // Prefer existing values
      };

      // Grab the existing values for the form
      axios.get('/v1/custom_schemas', { params: { name: 'client_color_scheme' } }).then(resp => {
        let customSchema = resp.data.custom_schemas[0];
        this.mobxState.editId = customSchema.id;
        let schemaDefinition = customSchema.schema_definition;
        this.mobxState.schemaDefinition = schemaDefinition;

        let defaultFormState = {};
        let defaultFormValues = {};
        Object.keys(schemaDefinition).forEach(k => {
          defaultFormState[k] = schemaDefinition[k].value;
          defaultFormValues = schemaDefinition[k].default;
        });
        this.mobxState.formState = _.cloneDeep(defaultFormState);
        this.mobxState.defaultFormState = defaultFormState;
        this.mobxState.defaultFormValues = defaultFormValues;

        this.mobxState.styleInjector = new InjectedStyle();
        this.mobxState.styleInjector.loadClientColorScheme(schemaDefinition);
        this.mobxState.styleInjector.apply();
      });
    });
  }; //end fetchData() ///////////////
  handleChange = (path, value) => {
    _.set(this.mobxState.schemaDefinition, path, value);
    this.mobxState.styleInjector.clearRules();
    this.mobxState.styleInjector.loadClientColorScheme(this.mobxState.schemaDefinition);
    this.mobxState.styleInjector.refresh();
  };
  handleReset = name => {
    this.mobxState.schemaDefinition[name].value = this.mobxState.schemaDefinition[name].default;
    this.mobxState.styleInjector.clearRules();
    this.mobxState.styleInjector.loadClientColorScheme(this.mobxState.schemaDefinition);
    this.mobxState.styleInjector.refresh();
  };
  validateState = () => {
    Object.entries(this.mobxState.schemaDefinition).forEach(([k, v]) => {
      if (k === 'highcharts_globals') {
        if (!Array.isArray(v.value)) {
          throw new Error('highcharts_globals should be array');
        }
        let nonColors = v.value.filter(s => !isValidColor(s));
        if (nonColors.length !== 0) {
          alert('Please check that Highcharts Globals is valid');
          // throw new Error('Highcharts Globals contains a non-color value');
        }
      } else {
        if (!isValidColor(v.value)) {
          alert(`Please check that ${k} is set to a valid color`);
          // throw new Error(`${k} has invalid value ${v}`);
        }
      }
    });
    return true;
  };
  handleSubmit = () => {
    if (this.validateState()) {
      axios
        .put(`/v1/custom_schemas/${this.mobxState.editId}`, { schema_definition: this.mobxState.schemaDefinition })
        .then(resp => {
          alert('Saved Successfully');
        });
    }
  };

  //////////////////END HTTP/////////////////
  render() {
    const hcHeight = '300px';

    return (
      <div>
        <div
          className='jumbotron jumbotron_full_width'
          style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
        >
          <div className='jumbotron_full_width_container'>
            <div className='row'>
              <div className='col-sm-12'>
                <span className='fa-layers fa-fw fa-2x'>
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform='grow-3'
                    color='#53C8C5'
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fas', 'palette']}
                    color='white'
                    transform='shrink-5'
                    style={{ marginTop: '3px' }}
                  />
                </span>
                &nbsp;
                <b className='dash_header'>Site Color Scheme</b>
              </div>
            </div>
          </div>
        </div>
        {/* BADGES */}
        {/* TODO: ADD BADGES*/}
        <br />
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>
                  <FontAwesomeIcon icon={['fas', 'tint']} />&nbsp; Edit Default Site Color Scheme
                </b>
              </div>
              <div className='panel-body'>
                <ClientColorSchemeForm
                  editId={this.mobxState.editId}
                  schemaDefinition={this.mobxState.schemaDefinition}
                  defaultFormState={this.mobxState.defaultFormState}
                  defaultFormValues={this.mobxState.defaultFormValues}
                  handleChange={this.handleChange}
                  handleReset={this.handleReset}
                  handleSubmit={this.handleSubmit}
                  customOptions={this.mobxState.customOptions || {}}
                  modelFields={this.mobxState.formModelFields}
                  modelOptions={this.mobxState.formModelOptions}
                  searchFunctions={this.mobxState.formOptionSearchFunctions}
                  customModelFields={this.mobxState.formCustomModelFields}
                  customModelOptions={this.mobxState.formModelOptions}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ClientColorSchemePage, {
  mobxState: observable
});

export default observer(ClientColorSchemePage);

// export default ClientColorSchemePage ;
