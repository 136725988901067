import React from 'react';
import { render } from 'react-dom';

//other components built
import AsyncBadge from '../../../../badges/AsyncBadge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter
import FilterCountIndicator from '../../../../filters/FilterCountIndicator';
import FilterTray from '../../../../filters/FilterTray';

//forms
import NewRuleForm from '../spend_reclassification_rules/NewRuleForm.jsx';
import EditRuleForm from '../spend_reclassification_rules/EditRuleForm.jsx';
//highcharts
import CustomBadgeRenderer from '../../../../badges/custom/CustomBadgeRenderer';
import CustomChartRenderer from '../../../../charts/custom/CovidChartRenderer';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, deleteIfEmpty, formatForTreeSelect, humanizeString } from '../../../../services/Utilities';
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';
import { asyncLoadCharts } from '../../../../services/ChartUtilities';
const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

class SpendReclassificationRuleOverview extends React.Component {
  pageId = 209;
  autofitFunctions = []; // Have to keep track of autoclick childs rather than setting componentwide property
  mobxState = {
    cardData: {
      spendCount: 0,
      supplierCount: 0,
      contractCount: 0
    },
    chartRenderData: [],
    tableData: {
      spend_reclassification_rules: [],
      runAllResults: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    bottomDockOptions: {
      isVisible: false,
      size: 0.2
    },
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    customFilterModelNames: [],
    filterOptions: {},
    customFilterOptions: {},
    customOptions: {
      spend_reclassification_rule__id: 'Id',
      spend_reclassification_rule__name: 'Name',
      spend_reclassification_rule__description: 'Description',
      spend_reclassification_rule__priority: 'Priority',
      spend_reclassification_rule__conditions: 'Conditions',
      spend_reclassification_rule__category_id: 'Category Id',
      spend_reclassification_rule__created_at: 'Created At',
      spend_reclassification_rule__updated_at: 'Updated At'
    },
    filtersForServer: {}, // Filters in normal form to use for the server
    defaultFormState: {}, // Form for new or editing reclassification rule
    formOptions: {
      category_id: []
    },
    // Form specific stuff
    formModelFields: [],
    formCustomModelFields: [],
    formModelOptions: {},
    formOptionSearchFunctions: { customSearchFunctions: {}, searchFunctions: {} },
    editId: null,
    // Job Related metadata
    runAllResultsLoaded: false,
    pollingInterval: 1000,
    jobIsRunning: true // Assume a job is running until we verify
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }
  componentWillUnmount() {
    this.mobxState.pollingForJob = false;
    this.mobxState.pollingInterval = 1000;
  }

  //////////////////HTTP/////////////////
  //helpers for system filters
  //helpers for custom filters
  //setup filters
  // TODO:
  // - Remove system filters from customOptions once we have api call to populate aliases in place
  setupFilters = async () => {
    const urls = {
      system: '/v1/system_filters',
      systemOptions: '/v1/transactions/system_field_options',
      custom: '/v1/custom_filters_formatted',
      customOptions: '/v1/transactions/custom_field_options',
      systemOptionsSearchUrl: `/v1/transactions/system_field_options`,
      customOptionsSearchUrl: `/v1/transactions/custom_field_options`
    };
    const [systemFilterResp, customFilterResp] = await Promise.all([
      axios.get(urls.system, {
        params: processingFunctions.system.filters.formatParams({
          enabled: true,
          page_id: this.pageId
        })
      }),
      axios.get(urls.custom, {
        params: processingFunctions.custom.filters.formatParams({
          page_id: this.pageId,
          enabled: true
        })
      })
    ]);

    let systemFilters = processingFunctions.system.filters.formatResp(systemFilterResp);
    let customFilters = processingFunctions.custom.filters.formatResp(customFilterResp);

    // Handle System Filters First
    let systemFilterModelNames = [];
    let systemFilterOptions = {};
    let systemFilterCustomOptions = {};
    let systemFilterWidgetTypes = {};

    systemFilters
      .filter(filter => filter.enabled)
      .forEach(filter => {
        let { name, widget_type } = filter; // Name of filter in the table, usually this is the column name of the sequelize model as well
        systemFilterModelNames.push(name); // Construct Select
        systemFilterWidgetTypes[name] = widget_type;
      });

    // Get options (name-label format) for our selects in model-nested format
    // then convert it to name-label format
    systemFilterOptions = await axios
      .post(
        urls.systemOptions,
        processingFunctions.system.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          system_fields: systemFilterModelNames
        })
      )
      .then(processingFunctions.system.options.formatResp);

    // Then Handle Custom Filters
    let customFilterModelNames = [];
    let customFilterOptions = {};
    let customFilterCustomOptions = {};
    let customFilterWidgetTypes = {};

    // Manipulate custom filters into a format where they can be handled
    // by the constructors for our filter panel
    customFilters.forEach(filter => {
      let [name, data] = _.toPairs(filter)[0]; // _.toPairs splits object into list of [key, value] pairs
      customFilterModelNames.push(name);
      customFilterCustomOptions[name] = humanizeString(data.field_alias); // Construct the filter
      customFilterWidgetTypes[name] = data.widget_type;
    });

    customFilterOptions = await axios
      .post(
        urls.customOptions,
        processingFunctions.custom.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          custom_fields: customFilterModelNames
        })
      )
      .then(processingFunctions.custom.options.formatResp);

    // ------------------------------------------------------------
    // Finally Setup The Filters
    // Use the current filter generation that gets triggered when filterModelNames change
    this.mobxState.filterModelNames = systemFilterModelNames;
    this.mobxState.customFilterModelNames = customFilterModelNames;

    this.mobxState.filterOptions = systemFilterOptions;
    this.mobxState.customFilterOptions = customFilterOptions;

    this.mobxState.filterWidgetTypes = systemFilterWidgetTypes;
    this.mobxState.customFilterWidgetTypes = customFilterWidgetTypes;

    this.mobxState.customOptions = {
      ...this.mobxState.customOptions,
      ...systemFilterCustomOptions,
      ...customFilterCustomOptions
    };
    this.mobxState.optionSearchFunctions = processingFunctions.general.makeSearchFunctions({
      system_filters: systemFilters,
      custom_filters: customFilters,
      system_url: urls.systemOptionsSearchUrl,
      custom_url: urls.customOptionsSearchUrl,
      selected_filters: this.mobxState.filtersForServer,
      system_fields: systemFilterModelNames,
      custom_fields: customFilterModelNames
    });

    // Fill in fields for our form
    this.mobxState.formModelFields = systemFilterModelNames;
    this.mobxState.formCustomModelFields = customFilterModelNames;
    this.mobxState.formModelOptions = { ...systemFilterOptions, ...customFilterOptions };
    this.mobxState.formOptionSearchFunctions = this.mobxState.optionSearchFunctions;
  };
  //data loader
  fetchData = () => {
    // Restore filters (NOTE: SKIP THIS ON SPEND RECLASS RULES PAGE)
    // this.mobxState.selectedModels = JSON.parse(localStorage.getItem('selectedModels')) || {}; //multi select data model for http
    // this.mobxState.selectedOptions = JSON.parse(localStorage.getItem('selectedOptions')) || {}; //multi select data model in filter value/label format
    // this.mobxState.filtersForServer = JSON.parse(localStorage.getItem('filtersForServer')) || {};
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = window.$('#table_rows_dropdown').val();
    //merge the filter model with the amount of rows selected
    this.setupFilters();

    //merge the filter model with the amount of rows selected
    let models_with_rows = {
      ...this.mobxState.filtersForServer,
      ...{ table_rows: table_rows || [] }
    };
    //custom pivot configurations load
    axios
      .get('/v1/resources/index_filter', { params: { page_id: this.pageId } })
      .then(response => {
        this.mobxState.pivotFilter = response.data.resources;
      })
      .catch(console.log.bind(console)); //error catcher

    //analytics data load
    this.mobxState.cardDataLoading = true;
    axios.post('/v1/spend_reclassification_rule_analytics/', models_with_rows).then(response => {
      this.mobxState.cardData = response.data.spend_reclassification_rule_analytics;
      this.mobxState.cardDataLoading = false;
    });
    //load custom chart data for this page (updates mobxState.chartRenderData)
    asyncLoadCharts(this.mobxState, {
      page_id: this.pageId,
      selected_models: this.mobxState.filtersForServer
    });

    //set up custom options for components, then get data for components
    // axios.get("/v1/ref_cmfields_labels")
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}) // Prefer existing values
      };
    });
    // Grab aliases for custom fields (one off)
    ['category', 'location', 'transaction'].forEach(schemaName => {
      axios.get('/v1/custom_schemas', { params: { name: schemaName } }).then(response => {
        let customFieldAliases = {};
        let customSchemaDef =
          response.data &&
          response.data.custom_schemas &&
          response.data.custom_schemas[0] &&
          response.data.custom_schemas[0].schema_definition;
        if (customSchemaDef) {
          Object.keys(customSchemaDef).forEach(k => {
            customFieldAliases[schemaName + '__' + k] = humanizeString(customSchemaDef[k].field_alias);
          });
        }

        this.mobxState.customOptions = {
          ...customFieldAliases,
          ...(this.mobxState.customOptions || {}) // Prefer existing values
        };
        console.log('Expanding Custom options', customFieldAliases);
      });
    });
    axios
      .get('/v1/custom_schemas', { params: { name: 'transaction' } }) //query the project custom field schema for all projects
      .then(response => {
        window.cheapGlobal = { mobxState: this.mobxState };
        //get map of field labels from API
        this.mobxState.customSchema = response.data;
        // console.log(this.mobxState.customSchema.custom_schemas[0]);

        //after ag grid is set up, get the rest of the data
        //table data load
        axios.post('/v1/spend_reclassification_rules_index', models_with_rows).then(response => {
          this.mobxState.tableData = { ...this.mobxState.tableData, ...response.data };
          //set the column definitions for ag grid, look into buildColumnDefs class to see options
          this.mobxState.columnDefsTransactions = gridColumns.buildColumnDefs({
            customOptions: this.mobxState.customOptions,
            columnArray: ['spend_reclassification_rules'],
            customFieldSchema: this.mobxState.customSchema.custom_schemas[0] || {},
            dataType: 'spend_reclassification_rule',
            metadata: {
              run_button_handler: this.handleIndividualRunButton,
              edit_button_handler: this.handleEditButton,
              delete_button_handler: this.handleDeleteButton
            }
          });
          //call child function to autofit child columns
          this.autofitFunctions[0]();
        });

        //Set up column definitions runAllResults' ag-grid
        this.mobxState.columnDefsRunAllResults = gridColumns.buildColumnDefs({
          customOptions: this.mobxState.customOptions,
          columnArray: ['spend_reclassifiction_rules_run_all_results'],
          customFieldSchema: this.mobxState.customSchema.custom_schemas[0] || {},
          dataType: 'spend_reclassifiction_rules_run_all_result', //special tag to signify that this is project data
          metadata: {}
        });

        // Load running job if it's in progress
        this.getMostRecentJob();

        // Load our form options
        axios
          .get('/v1/custom_schemas', { params: { name: 'category_selector_settings' } })
          .then(categorySelectorSettingsResp => {
            this.mobxState.categorySelectorSettings =
              categorySelectorSettingsResp.data &&
              categorySelectorSettingsResp.data.custom_schemas &&
              categorySelectorSettingsResp.data.custom_schemas[0] &&
              categorySelectorSettingsResp.data.custom_schemas[0]['schema_definition'];

            //grab categories for category selection
            axios.post('/v1/categories_index', {}).then(response => {
              let tmpResult = formatForTreeSelect(
                response.data.categories,
                [
                  r => (r.custom_fields && r.custom_fields.custom_field_1) || '',
                  r => (r.custom_fields && r.custom_fields.custom_field_2) || '',
                  r => (r.custom_fields && r.custom_fields.custom_field_3) || '',
                  r => (r.custom_fields && r.custom_fields.custom_field_4) || '',
                  r => (r.custom_fields && r.custom_fields.custom_field_5) || ''
                ],
                this.mobxState.categorySelectorSettings.show_id_in_label
              );
              this.mobxState.formOptions = this.mobxState.formOptions || {};
              this.mobxState.formOptions['category_id'] = tmpResult;
            });
          });
      })
      .catch(console.log.bind(console)); //error catcher
  }; //end fetchData() ///////////////
  deleteRequest = toSendArr => {
    axios
      .post('/v1/spend_reclassification_rules/batch_destroy', { id_set: JSON.stringify(toSendArr) })
      .then(resp => {
        this.fetchData();
      })
      .catch(err => {
        alert('Something went wrong. Please try again later.');
      });
  };
  //////////////////END HTTP/////////////////
  handleChartFilter = (filterParam, selectedFilter) => {
    // KLUDGE : If the filterParam begins with 'custom_fields.' then it should be treated as if
    // a custom_filter was selected
    if (filterParam.indexOf('custom_fields.') !== -1) {
      return this.handleCustomFilter(filterParam.replace(/custom_fields./, ''), selectedFilter);
    } else {
      return this.handleFilter(filterParam, selectedFilter);
    }
  };

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    // In the state, pick the model based on filter that is sent and turn it into the array of values of the filter.
    // This goes as params to http request
    // Update state with selected options, removing the property if none selected
    // Equivalent to this.mobxState[filterParam] = selectedFilter.map(a => a.value)
    // but handles nested assignment
    this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, filterParam);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    if (_.isEmpty(this.mobxState.selectedOptions[filterParam])) {
      delete this.mobxState.selectedOptions[selectedFilter];
    }
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);

    // Update stored filters
    localStorage.setItem('selectedOptions', JSON.stringify(this.mobxState.selectedOptions));
    localStorage.setItem('filtersForServer', JSON.stringify(this.mobxState.filtersForServer));
    localStorage.setItem('selectedModels', JSON.stringify(this.mobxState.selectedModels));
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  handleCustomFilter = (filterParam, selectedFilter) => {
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels['custom_fields'] = this.mobxState.selectedModels['custom_fields'] || {};
    this.mobxState.selectedModels['custom_fields'][filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields`);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions['custom_fields'] = this.mobxState.selectedOptions['custom_fields'] || {};
    this.mobxState.selectedOptions.custom_fields[filterParam] = selectedFilter;
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields`);
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);
    // Update stored filters
    localStorage.setItem('selectedOptions', JSON.stringify(this.mobxState.selectedOptions));
    localStorage.setItem('filtersForServer', JSON.stringify(this.mobxState.filtersForServer));
    localStorage.setItem('selectedModels', JSON.stringify(this.mobxState.selectedModels));

    //fetch called, it reads the filter params from state
    this.fetchData();
  };
  //handles dropdown and chart filters
  handleClear = () => {
    //in the state, clear the model. This goes as params to http request
    this.mobxState.selectedModels = {};

    // Clear the filters we send to the server
    this.mobxState.filtersForServer = {};

    //also store the explicit cleared selections in the original form. This goes back to the filter as selected values
    //loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
    //from ["supplier", "item"] to {"supplier":[], "item":[]}
    let newSelectedOptions = { custom_fields: {} };
    this.mobxState.filterModelNames.forEach(name => {
      newSelectedOptions[name] = [];
    });
    this.mobxState.customFilterModelNames.forEach(name => {
      newSelectedOptions.custom_fields[name] = [];
    });
    this.mobxState.selectedOptions = newSelectedOptions;
    localStorage.setItem('selectedOptions', '{}');
    localStorage.setItem('filtersForServer', '{}');
    localStorage.setItem('selectedModels', '{}');

    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  handleEditButton = agGridParams => {
    let rowId = agGridParams.data.id;
    axios.get(`/v1/spend_reclassification_rules/${rowId}`).then(resp => {
      let { conditions, ...toplevelProperties } = resp.data;
      let flatConditions = processingFunctions.general.unsequelizeAndFlattenConditions(resp.data.conditions);
      Object.entries(flatConditions).forEach(([k, v]) => {
        flatConditions[k] = v.map(v => ({ label: v, value: v }));
      });

      this.mobxState.defaultFormState = { ...toplevelProperties, ...flatConditions };
      this.mobxState.editId = rowId;
      setTimeout(() => {
        document.getElementById('ruleEditPanel').scrollIntoView();
      }, 200);
    });
  };
  handleCloseEditButton = () => {
    this.mobxState.editId = null;
  };
  handleDeleteButton = agGridParams => {
    let rowId = agGridParams.data.id;
    this.deleteRequest([rowId]);
  };
  handleRunAllButton = () => {
    const runAllUrl = '/v1/run_spend_reclassification_rules';
    this.mobxState.tableData.runAllResults = [];
    this.mobxState.runAllResultsLoaded = false;
    this.mobxState.jobIsRunning = true;
    axios.post(runAllUrl, {}).then(resp => {
      this.getMostRecentJob();
    });
  };
  handleIndividualRunButton = agGridParams => {
    const url = '/v1/run_spend_reclassification_rules';
    const rule = agGridParams.data;
    this.mobxState.tableData.runAllResults = [rule.id];
    this.mobxState.runAllResultsLoaded = false;
    this.mobxState.jobIsRunning = true;
    axios
      .post(url, {
        spend_reclassification_rule: {
          id: [rule.id]
        }
      })
      .then(resp => {
        this.getMostRecentJob();
      });
  };
  getMostRecentJob = () => {
    return axios
      .post(`/v1/spend_reclassification_rules_runs_index`, {
        table_rows: 1
      })
      .then(updateResp => {
        let mostRecent = updateResp.data.spend_reclassification_rules_runs[0];
        if (typeof mostRecent === 'undefined') {
          // No existing jobs
          this.mobxState.jobIsRunning = false;
          this.mobxState.runAllResultsLoaded = false;
          this.mobxState.tableData.runAllResults = [];
        } else {
          // Need to see if most recent job is running
          this.mobxState.jobIsRunning = mostRecent.status === 'running';
          this.mobxState.mostRecentJobErrored = mostRecent.status === 'errored';
          this.mobxState.runAllResultsLoaded = true;
          this.mobxState.tableData.runAllResults = [...mostRecent.details];
          this.autofitFunctions[1]();
          if (this.mobxState.jobIsRunning) {
            this.mobxState.pollingForJob = true;
            setTimeout(() => {
              this.mobxState.pollingInterval = this.mobxState.pollingInterval * 1.1;
              if (this.mobxState.pollingForJob) {
                this.getMostRecentJob();
              }
            }, this.mobxState.pollingInterval);
          }
        }
      });
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };
  toggleBottomDock = () => {
    this.mobxState.bottomDockOptions.isVisible = !this.mobxState.bottomDockOptions.isVisible;
  };

  render() {
    const hcHeight = '300px';

    return (
      <div>
        <div
          className='jumbotron jumbotron_full_width'
          style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
        >
          <div className='jumbotron_full_width_container'>
            <div className='row'>
              <div className='col-sm-12'>
                <span className='fa-layers fa-fw fa-2x'>
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform='grow-3'
                    color='#009C9C'
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fal', 'folders']}
                    color='white'
                    transform='shrink-5'
                    style={{ marginTop: '3px' }}
                  />
                </span>
                &nbsp;
                <b className='dash_header'>Spend Reclassifications</b>
                <span style={{ minWidth: '28.608vw', float: 'right' }}>
                  {/* Conditionally rendered dropdown if project exists or else it will display "create a project" text*/}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* BADGES */}
        {/* TODO: ADD BADGES*/}
        <br />
        <div className='row'>
          <div className='col-sm-12'>
            {/*Conditionally render either NEW or EDIT form*/}
            {this.mobxState.editId === null ? (
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg'>
                  <b>New Spend Reclassification Rule</b>
                </div>
                <div className='panel-body'>
                  <NewRuleForm
                    schemaDefinition={{}}
                    defaultFormState={{}}
                    formOptions={this.mobxState.formOptions}
                    customOptions={this.mobxState.customOptions || {}}
                    modelFields={this.mobxState.formModelFields}
                    modelOptions={this.mobxState.formModelOptions}
                    searchFunctions={this.mobxState.formOptionSearchFunctions}
                    customModelFields={this.mobxState.formCustomModelFields}
                    customModelOptions={this.mobxState.formModelOptions}
                  />
                </div>
              </div>
            ) : (
              <div className='panel panel-default' id='ruleEditPanel'>
                <div className='panel-heading panel_bg'>
                  <b>Edit Spend Reclassification Rule</b>
                  <div className='pull-right clear_button' onClick={this.handleCloseEditButton}>
                    ✖
                  </div>
                </div>
                <div className='panel-body'>
                  <EditRuleForm
                    ruleId={this.mobxState.editId}
                    schemaDefinition={{}}
                    defaultFormState={this.mobxState.defaultFormState}
                    formOptions={this.mobxState.formOptions}
                    customOptions={this.mobxState.customOptions || {}}
                    modelFields={this.mobxState.formModelFields}
                    modelOptions={this.mobxState.formModelOptions}
                    searchFunctions={this.mobxState.formOptionSearchFunctions}
                    customModelFields={this.mobxState.formCustomModelFields}
                    customModelOptions={this.mobxState.formModelOptions}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* SUMMARY CHARTS */}
        <div className='row'>
          <CustomChartRenderer
            handleFilter={this.handleChartFilter}
            toggleDock={this.toggleDock}
            handleClear={this.handleClear}
            chartRenderData={this.mobxState.chartRenderData}
          />
        </div>{' '}
        {/* END ROW */}
        {/* END SUMMARY CHARTS */}
        {/* PROJECT GRID */}
        <div className='row'>
          <div className='col-lg-12'>
            {/* AG Grid */}
            <GridRenderer
              gridHeight={'650px'}
              columnDefs={this.mobxState.columnDefsTransactions}
              rowData={this.mobxState.tableData.spend_reclassification_rules}
              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={this.pageId}
              savedState={this.mobxState.pivotState}
              isPivotMode={true}
              gridTitle={'Reclassification Rule Details'}
              setClick={click => (this.clickChild = click)}
              deleteRequest={this.deleteRequest}
              idColumn={'id'}
              modelsToUpdateArr={[{ url: 'v1/spend_reclassification_rules_index' }]}
              //modelsToUpdateArr={false}
              enableSetResizeChild={true}
              setResize={click => (this.autofitFunctions[0] = click)}
              // enableSetResizeChildColumns={true}
              // setResizeToColumnWidth={click => (this.autofitFunctions[0] = click)}
              singleClickEdit={true}
              suppressClickEdit={false}
              isSaveable={false}
              isAwardable={false}
              isDeletable={true}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b> Quick Run </b>
              </div>
              <div className='panel-body'>
                <div className='col-xs-12'>
                  <button
                    type='button'
                    className='btn btn-primary btn-block pull-right'
                    disabled={this.mobxState.jobIsRunning}
                    onClick={this.handleRunAllButton}
                  >
                    Run All
                  </button>
                </div>
                <div className='col-xs-12'>
                  {/* RUN ALL RESULTS TABLE */}
                  {/* Display this only when there is data available */}
                  <div style={this.mobxState.runAllResultsLoaded !== true ? { display: 'none' } : {}}>
                    <br />
                    <GridRenderer
                      gridHeight={'330px'}
                      columnDefs={this.mobxState.columnDefsRunAllResults}
                      rowData={this.mobxState.tableData.runAllResults}
                      fetchData={this.fetchData}
                      handleClear={this.handleClear}
                      toggleDock={this.toggleDock}
                      pageId={this.pageId}
                      savedState={this.mobxState.pivotState}
                      isPivotMode={true}
                      gridTitle={'Quick Run Results'}
                      setClick={click => (this.clickChild = click)}
                      deleteRequest={this.deleteRequest}
                      idColumn={'id'}
                      modelsToUpdateArr={[{ url: '' }]}
                      //modelsToUpdateArr={false}
                      enableSetResizeChild={true}
                      setResize={click => (this.autofitFunctions[1] = click)}
                      //enableSetResizeChildColumns={true}
                      //setResizeToColumnWidth={click => (this.autofitFunctions[1] = click)}
                      singleClickEdit={false}
                      suppressClickEdit={false}
                      isSaveable={false}
                      isAwardable={false}
                      isDeletable={false}
                      isTableRowSelectable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/* Sidebar */}
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(SpendReclassificationRuleOverview, {
  mobxState: observable
});

export default observer(SpendReclassificationRuleOverview);

// export default SpendReclassificationRuleOverview ;
