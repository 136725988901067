import React, { Component } from 'react';
import {timeoutLogout} from '../../services/Timing';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//images
import CompanyLogoWithText from '../../../assets/images/simfoni_logo_1920x1080_transparent_background.png'
import CompanyLogoWithoutText from '../../../assets/images/simfoni_transparent_icon.png'

//project views
import UserSettings from './views/projects/UserSettings';
import Schema from './views/projects/Schema';
import User from './views/users/User';
import GlobalUser from './views/users/GlobalUser';
import UserRole from './views/users/UserRole';
import RoleModel from './views/users/RoleModel';
import Role from './views/users/Role';
import PageFilters from './views/page_filters/PageFilters';
import ProjectFieldAliases from './views/projects/FieldAliases';
import SpendFieldAliases from './views/spend/FieldAliases';
import DefaultFilters from './views/spend/DefaultFilters';
import ClientColorScheme from './views/spend/ClientColorScheme';
import SpendModuleSettings from './views/spend/SpendModuleSettings';
import OptimizationFieldAliases from './views/optimization/FieldAliases';
import SetupSettings from './views/projects/SetupSettings'
import CategoryImporter from './views/spend/CategoryImporter';
import LogoManagement from './views/projects/LogoManagement';
import SSOAdmin from './views/sso_admin/SSOAdmin';

//sidebar
import MainSidebar from '../../headers/MainSidebar';

 

import MainHeader from '../../headers/MainHeader';
import { checkRole } from '../../security/SecurityUtilities';
import withAuth from '../../security/withAuth';
import withRole from '../../security/withRole';


import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
// utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//homemade utilities
import {zeroNull, extractToken} from "../../services/Utilities";
import { injectCustomerColorScheme } from '../../services/InjectedStyle';

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class Setup extends React.Component {
  mobxState = {};

	componentDidMount() {
    injectCustomerColorScheme().then(({styleInjector, clientColorScheme}) => {
      this.mobxState.styleInjector = styleInjector;
      this.mobxState.clientColorScheme = clientColorScheme;
    });
    checkRole({'model': 'builder_admin', 'permission':'a'}).then(res => {this.mobxState.canBuilderAdminA = res});
    checkRole({'model': 'builder_admin', 'permission':'w'}).then(res => {this.mobxState.canBuilderAdminW = res});
    checkRole({'model': 'global', 'permission': 'w'}).then(res => {this.mobxState.canGlobalW = res});
    checkRole({'model': 'project', 'permission': 'r'}).then(res => {this.mobxState.canProjectR = res});
    checkRole({'model': 'project', 'permission': 'w'}).then(res => {this.mobxState.canProjectW = res});
    checkRole({'model': 'setup_admin', 'permission': 'r'}).then(res => {this.mobxState.canSetupAdminR = res});
    checkRole({'model': 'setup_admin', 'permission': 'w'}).then(res => {this.mobxState.canSetupAdminW = res});
		//timeout user session in seconds
    timeoutLogout(60*60);
  }
	componentDidUpdate() {
		//timeout user session in seconds
		timeoutLogout(60*60);
	}

	render() {

    //this variable does all the routing and is passed into here from parent
    const match = this.props.match;
		// Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
		const urlToken = extractToken(window.location.pathname, '/', 3);
		let projectId = null;
		// take only numbers no strings, to prevent /new /edit etc.. being recognized as id
		if(urlToken){ projectId = urlToken.match(/^\d+$/) ? urlToken : null; };

		return (
      <div>
        <div id="main-page-header" className='main-page-header'>
          {/* HEADER ---------------------------------------------------------- */}
          <div id='top_line'></div>
          <nav className='navbar header-navbar navbar-fixed-top'>
            <div className='navbar-wrapper'>
              {/* Header and Logo ----------------------------------------------------------------- */}
              <div className='navbar-logo'>
                <button type='button' data-toggle="modal" data-target="#main-sidebar-dock" className='btn btn-default navbar-btn'>
                  <FontAwesomeIcon icon={['fas', 'bars']} color="#D3D4D6" transform="" style={{fontSize: '19px'}} />
                </button>
                <img alt="Simfoni Logo" className="simfoni-logo" src={CompanyLogoWithoutText} />
              </div>

              {/* Links in the center ------------------------------------------------------------- */}
              <div className='navbar-menus'>
                <ul className='nav navbar-nav'>
                  {(this.mobxState.canBuilderAdminW || this.mobxState.canSetupAdminW) && (
                    <li className="dropdown">
                      <a id="crossModuleDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Cross Module
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="crossModuleSubmenu">
                        {this.mobxState.canBuilderAdminW && (<li><Link to={`/setup/page_filters`}>Page Filters</Link></li>)}
                        {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/projects/logo_management`}>Logo Management</Link></li>)}
                        {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/spend/client_color_scheme`}>Color Scheme</Link></li>)}
                        {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/category_importer`}>Category Import</Link></li>)}
                      </ul>
                    </li>
                  )}
                  {(this.mobxState.canSetupAdminW) && (
                    <li className="dropdown">
                      <a id="projectDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Projects
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="projectSubmenu">
                        {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/projects/schema`}>Fields</Link></li>)}
                        {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/projects/field_aliases`}>Field Aliases</Link></li>)}
                        {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/projects/setup_settings`}>Settings</Link></li>)}
                      </ul>
                    </li>
                  )}
                  {(this.mobxState.canSetupAdminW || this.mobxState.canBuilderAdminW) && (
                    <li className="dropdown">
                      <a id="spendDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Spend
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="spendSubmenu">
                        {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/spend/field_aliases`}>Field Aliases</Link></li>)}
                        {this.mobxState.canBuilderAdminW && (<li><Link to={`/setup/spend/module_settings`}>Module Settings</Link></li>)}
                        {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/spend/default_filters`}>Default Filters</Link></li>)}
                      </ul>
                    </li>
                  )}
                  {(this.mobxState.canSetupAdminW) && (
                    <li className="dropdown">
                      <a id="optimizationDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Optimization
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="optimizationSubmenu">
                       {this.mobxState.canSetupAdminW && (<li><Link to={`/setup/optimization/field_aliases`}>Field Aliases</Link></li>)}
                      </ul>
                    </li>
                  )}
                  {(this.mobxState.canGlobalW) && (
                    <li className="dropdown">
                      <a id="usersDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Users
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="usersSubmenu">
                        {this.mobxState.canGlobalW && (<li><Link to={`/setup/users/user`}>+/- Users</Link></li>)}
                        {this.mobxState.canGlobalW && (<li><Link to={`/setup/users/user_role`}>User Roles</Link></li>)}
                      </ul>
                    </li>
                  )}
                  {(this.mobxState.canBuilderAdminA || this.mobxState.canGlobalW) && (
                    <li className="dropdown">
                      <a id="adminDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Admin
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="adminSubmenu">
                        {this.mobxState.canBuilderAdminA && (<li><Link to={`/setup/users/role`}>+/- Roles</Link></li>)}
                        {this.mobxState.canBuilderAdminA && (<li><Link to={`/setup/users/role_model`}>Role Access</Link></li>)}
                        {this.mobxState.canGlobalW && (<li><Link to={`/setup/sso_admin`}>SSO Admin</Link></li>)}
                      </ul>
                    </li>
                  )}
                </ul>
              </div>
              {/* Button for right hand side */}
              <div className="pull-right" style={{paddingTop: '5px'}}>
                <img src={this.mobxState.client_logo} style={{maxWidth: '200px'}}/>
              </div>
            </div>
          </nav>
        </div>
        {/* Sidebar ------------------------------------------------------- */}
        <MainSidebar />

        {/* BODY ---------------------------------------------------------- */}
        <div className="wrapper" style={{top: '152px'}}>
            <div id="content">
              <Switch>
                <Route path={`/setup/projects/user_settings`} component={withRole(UserSettings, {'model':'project', 'permission': 'r'})}/>
								<Route path={`/setup/projects/schema`} component={withRole(Schema, {'model':'setup_admin', 'permission':'w'})}/>
                <Route path={`/setup/projects/field_aliases`} component={withRole(ProjectFieldAliases, {'model': 'setup_admin', 'permission': 'w'})}/>
								<Route path={`/setup/projects/setup_settings`} component={withRole(SetupSettings, {'model': 'setup_admin', 'permission': 'w'})}/>
								<Route path={`/setup/projects/logo_management`} component={withRole(LogoManagement, {'model': 'setup_admin', 'permission': 'w'})} />
                <Route path={`/setup/spend/field_aliases`} component={withRole(SpendFieldAliases, {'model': 'setup_admin', 'permission': 'w'})}/>
                <Route path={`/setup/spend/default_filters`} component={withRole(DefaultFilters, {'model': 'setup_admin', 'permission': 'w'})}/>
                <Route path={`/setup/spend/module_settings`} component={withRole(SpendModuleSettings, {'model':'builder_admin', 'permission':'w'})}/>
                <Route path={`/setup/spend/client_color_scheme`} component={withRole(ClientColorScheme, {'model': 'setup_admin', 'permission': 'w'})}/>
                <Route path={`/setup/optimization/field_aliases`} component={withRole(OptimizationFieldAliases, {'model': 'setup_admin', 'permission': 'w'})}/>
								<Route path={`/setup/users/user`} component={withRole(User, {'model':'global', 'permission':'w'})}/>
								<Route path={`/setup/users/global_user`} component={withRole(GlobalUser, {'model':'builder_admin', 'permission':'a'})}/>
								<Route path={`/setup/users/user_role`} component={withRole(UserRole, {'model':'global', 'permission':'w'})}/>
								<Route path={`/setup/users/role_model`} component={withRole(RoleModel, {'model':'builder_admin', 'permission':'a'})}/>
								<Route path={`/setup/users/role`} component={withRole(Role, {'model':'builder_admin', 'permission':'a'})}/>
								<Route path={`/setup/page_filters`} component={withRole(PageFilters, {'model':'builder_admin', 'permission':'w'})}/>
                <Route path={`/setup/category_importer`} component={withRole(CategoryImporter, {'model': 'setup_admin', 'permission': 'w'})}/>
                <Route path={`/setup/sso_admin`} component={withRole(SSOAdmin, {'model': 'global', 'permission': 'w'})}/>
              </Switch>

              <div className="line"></div>

            </div>


        </div>

      </div>
		); //end return
	} // end render

} //end class
// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Setup, {
  mobxState: observable
});

export default observer(Setup);

