import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//icons
import merge from '../../../assets/images/icons/merge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//lodash
import { pull } from 'lodash';

//handles rendering form components and their validation
class ScopeSelection extends React.Component {

  mobxState = {
    scopeSelect: [], //options for select box
    scopeValueSelect: [], //options for select box
    showScopeValueSelect: false, //conditional render for select box
    scopeValueDefaultValue: null, //default selected value
    scopeValueSelectedValue: null,
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    ////////////////////
    //FORM LOAD
    //SCOPES:
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/scopes', {params: {} })
    .then((response) => {

      //if property passed to ignore itema overall scopes, otherwise show all scopes
      if(this.props.onlyCustomFieldScope) {
        //remove overall and items from results
        function isAllowed(checkObject) {
        	return checkObject.value != 'overall' && checkObject.value != 'item'
        }
        //filter out item and overall scopes to only keep the custom_fields
        let filtered = response.data.scopes.filter(isAllowed);
        console.log('FILTERED scopes', filtered);
        this.mobxState.scopeSelect = filtered;
      } else {
        this.mobxState.scopeSelect = response.data.scopes;
      };

    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  //handle selecting the scope in the dropdown and update scope_value dropdown in the state
  handleScopeSelection = (selectedScope) => {

    console.log('selectedScope ', selectedScope.value);
    //don't show dropdown for scope_value on overall scope
    if (selectedScope.value !== 'overall') {
      this.mobxState.showScopeValueSelect = true;
    } else {
      //hide dropdown
      this.props.resetSelectAll(); //resets Select All option when switching between dropdowns
      this.mobxState.showScopeValueSelect = false;
    }

    //update form state in parent component
    this.props.handleFormChange('scope', selectedScope);

    //NEED TO GIVE REACT SELECT DEFAULT VALUE
    //clear scope_value selected state in parent and here in child because switching scopes should clear selection for a new scopeValue list related to that scope
    this.mobxState.scopeValueSelectedValue = null;
    this.props.handleFormChange('scopeValue', {label:null, value:null});

    //get the scopeValue dropdown list by sending a request with the selected scope in the params
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/scope_values', {params: {attribute: selectedScope.value} })
    .then((response) => {
      this.mobxState.scopeValueSelect = response.data.scope_values;
      // console.log(response.data.scope_values);
    });
  }

  //handle local state and callback
  handleScopeValueSelection = (scopeName, selectedScopeValue) => {
    //change the state in the parent with callback
    this.props.resetSelectAll(); //resets Select All option when switching between dropdowns
    if(selectedScopeValue.value === '999888777'){
      let selectAllOption = { value: this.mobxState.scopeValueSelect.map(option => option.value),
        label: "Select All", meta: { selectAll: true }};
      console.log("Selecting All Values", selectAllOption)
      this.props.handleFormChange(scopeName, selectAllOption);
    //local state, in order to handle the value
    this.mobxState.scopeValueSelectedValue = selectAllOption;
    this.props.onSelectAll(selectAllOption);
    }
    this.props.handleFormChange(scopeName, selectedScopeValue);
    //local state, in order to handle the value
    this.mobxState.scopeValueSelectedValue = selectedScopeValue;
  }

  render(){

    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }

    return (
      <div>
        <div className={this.props.scopeCssClass}>
          <div className="row">
            <div className="col-lg-2">
              <div>
                <FontAwesomeIcon icon={['fad', 'bullseye-arrow']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{marginRight:'20px'}}/>
              </div>
            </div>
            <div className="col-lg-10">
              <b>Scope Type</b>
              <Select
                onChange={this.handleScopeSelection}
                options={this.mobxState.scopeSelect}
                closeMenuOnSelect={true}
                maxMenuHeight = {400}
                placeholder={'What to apply the constraint by'}
              />
            </div>
          </div>
        </div>

        <div className={this.props.scopeValueCssClass}>


          {this.mobxState.showScopeValueSelect &&

              <div>
                <div className={'pull-left'}>
                  <img src={merge} style={{transform: 'rotate(90deg)', marginTop: '25px'}} />
                </div>

                <div className={''}>
                  <b>Scope</b>
                  <Select
                    onChange={(e) => this.handleScopeValueSelection('scopeValue', e)}
                    options={this.mobxState.scopeValueSelect}
                    closeMenuOnSelect={true}
                    maxMenuHeight = {400}
                    placeholder={'Specify data value to apply constraint to'}
                    defaultValue={this.mobxState.scopeValueDefaultValue}
                    value={this.mobxState.scopeValueSelectedValue}
                  />
                </div>
              </div>

          }
        </div>
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ScopeSelection, {
	mobxState: observable
})

export default observer(ScopeSelection);

// export default ScopeSelection
