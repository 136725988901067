import React from 'react';
import ReactDOM from 'react-dom';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//slider
import { Slider } from 'antd';
import 'antd/dist/antd.css'; // for css

//handles rendering form components and their validation
class PercentSlider extends React.Component {

  mobxState = {
    percentSelect: [], //options for select box
  }

  //mount data through http requests
  componentDidMount() {
  }



  render(){

    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }

    return (
      <div>
        <b>Award Percent</b>
        <Slider
          defaultValue={this.props.defaultValue}
          tipFormatter={value => `${value}%`} //adds percent symbol to number on slider
          tooltipVisible={ (this.props.tooltipVisible === false)? false : true }
          min={0}
          max={100}
          onAfterChange={(e) => this.props.handleFormChange(this.props.formField, e)}
        />
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(PercentSlider, {
	mobxState: observable
})

export default observer(PercentSlider);

// export default PercentSlider
