// Imports
// Adapted from https://react-select.com/async
import React from 'react';

import { Select, Spin } from 'antd';

export default class AntdMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    // State declaration
    this.state = {
    };
  }
  handleChange = values => {
    // Select just the label and value properties when updating
    let mappedValues = values.map(x => ({ value: x.value, label: x.label }));
    this.props.onChange(mappedValues);
  };
  // https://codesandbox.io/s/4j168r7jw0?file=/index.js
  render = () => {
    let currentOptions = this.props.options || [];
    let currentValues = this.props.value || [];

    return (
      <Select
        labelInValue
        showArrow
        defaultActiveFirstOption={false}
        onBlur={this.handleBlur}
        listHeight={this.props.maxMenuHeight || 256}
        placeholder={this.props.placeholder}
        mode='multiple'
        allowClear={true}
        options={currentOptions}
        value={currentValues}
        style={{ width: '100%', ...(this.props.style || {}) }}
        onChange={this.handleChange}
        notFoundContent={this.state.fetching ? <Spin size='small' /> : undefined}
        dropdownClassName={'antd-filter-dropdown'}
        getPopupContainer={() => document.querySelector('#filter-lists')}
      />
    );
  };
}
