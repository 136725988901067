import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//icons
import merge from '../../../assets/images/icons/merge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { element } from 'prop-types';

//handles rendering form components and their validation
class FreightBidFieldSelection extends React.Component {

//taken from scope selection renamed to qualitative selection

  mobxState = {
   freightBidFieldSelection: [], //select options for dropdown
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }
  

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  
  fetchData = () => {
   
    ////////////////////
    //FORM LOAD
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/freight_match_constraints_bid_field', {params: {} })
    .then((response) => {
      this.mobxState.freightBidFieldSelection = response.data.bid_level  
    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()


  render(){


    return (
      <div>       
          <b>Bid Level</b>
          <Select
            onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
            options={this.mobxState.freightBidFieldSelection}
            closeMenuOnSelect={true}
            maxMenuHeight = {400}
            placeholder={'Select Bid Field'}
          />
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FreightBidFieldSelection, {
	mobxState: observable
})

export default observer(FreightBidFieldSelection);


