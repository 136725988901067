import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from "../../../../badges/Badge";
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns
import ProjectName from "../../shared/ProjectName";

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { zeroNull, extractToken, deleteIfEmpty } from '../../../../services/Utilities';
import {
  processingFunctions,
  formatFiltersForServer
} from '../../../../services/filter_helpers';

const _ = require('lodash'); //extra utilities

// import FontAwesome from 'react-fontawesome'
// import faStyles from 'font-awesome/css/font-awesome.css'

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class FreightBids extends React.Component {
  // Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
  projectId = extractToken(window.location.pathname, '/', 3);
  modelName = 'freight_bid';
		mobxState = {
			cardData: {
        freightBidCount: 0,
        freightBidSupplierCount: 0
			},
      customOptions: {
        freight_bracket_name: 'Freight Bracket Name',
        min_bracket: 'Min Bracket',
        max_bracket: 'Max Bracket'
      },
			tableData: {
        freight_bids: []
      },
      //Filter State
      selectedModels: {}, //multi select data model for http
      selectedOptions: {}, //multi select data model in filter value/label format
			dockOptions: {
				isVisible: false,
				size: 0.2
			}, //sidebar defaults
			pivotData: [],
			pivotState: [],
			pivotFilter: [],
      filterModelNames: [], //autobuilds filters and clear functions
      customFilterModelNames: [],
      filterOptions: {},
      customFilterOptions: {},
      customOptions: {},
      filtersForServer: {}, // Filters in normal form to use for the server
      customSchemaRowCount: null, //default amount of rows from 'custom_schemas' table
      isLoadingTableData: false, //fired off when show enteries is selected
      inDeletion: false, //toggles icon and "Deleting selected rows" caption
		};

		//mount data through http requests
		componentDidMount() {
      //get the grid grow count then call the data loader to load data into the view
      this.getCustomRowCount();
    }


		//////////////////HTTP/////////////////
  getCustomRowCount = () => {
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
      .then(response => {
        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
        this.fetchData()
      })
      .catch(console.log.bind(console)); //error catcher
  }
  setupFilters = async () => {
    const pageId = 401; // CHANGE THIS FOR OTHER PAGES
    const urls = {
      system: '/v1/system_filters',
      systemOptions: `/v1/projects/${this.projectId}/freight_bids/system_field_options`,
      custom: '/v1/custom_filters_formatted',
      customOptions: `/v1/projects/${this.projectId}/freight_bids/custom_field_options`,
      systemOptionsSearchUrl: undefined,
      customOptionsSearchUrl: undefined
    };
    const [systemFilterResp, customFilterResp] = await Promise.all([
      axios.get(urls.system, {
        params: processingFunctions.system.filters.formatParams({
          enabled: true,
          page_id: pageId,
          project_id: this.projectId
        })
      }),
      axios.get(urls.custom, {
        params: processingFunctions.custom.filters.formatParams({
          page_id: pageId,
          enabled: true,
          project_id: this.projectId
        })
      })
    ]);

    let systemFilters = processingFunctions.system.filters.formatResp(
      systemFilterResp
    );
    let customFilters = processingFunctions.custom.filters.formatResp(
      customFilterResp
    );

    // Handle System Filters First
    let systemFilterModelNames = [];
    let systemFilterOptions = {};
    let systemFilterCustomOptions = {};
    let systemFilterWidgetTypes = {};

    systemFilters
      .filter(filter => filter.enabled)
      .forEach(filter => {
        let { name, widget_type } = filter; // Name of filter in the table, usually this is the column name of the sequelize model as well
        systemFilterModelNames.push(name); // Construct Select
        systemFilterWidgetTypes[name] = widget_type;
      });

    // Get options (name-label format) for our selects in model-nested format
    // then convert it to name-label format

    systemFilterOptions = await axios
      .post(
        urls.systemOptions,
        processingFunctions.system.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          system_fields: systemFilterModelNames
        })
      )
      .then(processingFunctions.system.options.formatResp);

    // Then Handle Custom Filters
    let customFilterModelNames = [];
    let customFilterOptions = {};
    let customFilterCustomOptions = {};

    // Manipulate custom filters into a format where they can be handled
    // by the constructors for our filter panel
    console.log("INITIAL CUSTOM ", customFilters)
    customFilters.forEach(filter => {
      let [name, data] = _.toPairs(filter)[0]; // _.toPairs splits object into list of [key, value] pairs
      customFilterModelNames.push(name);
      customFilterCustomOptions[name] = data.field_alias; // Construct the filter
    });

    customFilterOptions = await axios
      .post(
        urls.customOptions,
        processingFunctions.custom.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          custom_fields: customFilterModelNames
        })
      )
      .then(processingFunctions.custom.options.formatResp);

    // ------------------------------------------------------------
    // Finally Setup The Filters
    // Use the current filter generation that gets triggered when filterModelNames change
    this.mobxState.filterModelNames = systemFilterModelNames;
    console.log('filterModelNames', customFilterModelNames);
    this.mobxState.customFilterModelNames = customFilterModelNames;

    this.mobxState.filterOptions = systemFilterOptions;
    this.mobxState.customFilterOptions = customFilterOptions;

    console.log("CUSTOM FILTERS ", this.mobxState.customFilterOptions)

    this.mobxState.customOptions = {
      ...this.mobxState.customOptions,
      ...systemFilterCustomOptions,
      ...customFilterCustomOptions
    };
    console.log(this.mobxState.customOptions)
    this.mobxState.optionSearchFunctions = processingFunctions.general.makeSearchFunctions({
     system_filters: systemFilters,
     custom_filters: customFilters,
     system_url: urls.systemOptionsSearchUrl,
     custom_url: urls.customOptionsSearchUrl,
     selected_filters: this.mobxState.filtersForServer,
     system_fields: systemFilterModelNames,
     custom_fields: customFilterModelNames
   });
  };

		//data loader
		fetchData = () => {
			//quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();

			//merge the filter model with the amount of rows selected
    this.setupFilters();

    //merge the filter model with the amount of rows selected
    let models_with_rows = {
      ...this.mobxState.filtersForServer,
      ...{ table_rows: table_rows }
    };

    console.log('MODELS WITH ROWS:', models_with_rows);

			//analytics data load
    axios
      .post('/v1/projects/' + this.projectId + '/freight_bids/analytics', models_with_rows)
			.then(response => {
        console.log("Fetching Card Data", response.data.freight_bid_analytics)
        this.mobxState.cardData = response.data.freight_bid_analytics;
        this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
      });

    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}), // Prefer existing values
      }
    });

    axios
      .get('/v1/projects/' + this.projectId + '/project_field_schemas', {
        params: { project_id: this.projectId }
      })
      .then(response => {
				//get map of field labels from API
        this.mobxState.customOptions = {
          ...this.mobxState.customOptions,
          ...(response.data || {})
        };

				//after ag grid is set up, get the rest of the data
				//table data load
        this.mobxState.isLoadingTableData = true //loading project rows
        axios
          .post('/v1/projects/' + this.projectId + '/freight_bids_index', models_with_rows)
				  .then(response => {

          this.mobxState.tableData = response.data;
          this.mobxState.isLoadingTableData = false;
          console.log(response.data)

          //set the column definitions for ag grid, look into buildColumnDefs class to see options
          this.mobxState.columnDefsFreightBids = gridColumns.buildColumnDefs({
              customOptions: this.mobxState.customOptions,
              columnArray: ['freight_bids'],
              customFieldSchema: this.mobxState.customOptions.project_field_schemas[0],
              dataType: 'freight_bid' //special tag to signify that this is project data
            });
          });

					//autofit the table columns (hits the child function), this runs after the columns were created
					this.autofitChildToColumnWidth();
				})
      .catch(console.log.bind(console)); //error catcher

			//custom pivot configurations load
			axios.get("/v1/resources/index_filter", {params: { "page_id": 401 } })
			.then((response) => {
        axios
          .get('/v1/resources/index_filter', { params: { page_id: 401, project_id: this.projectId  || null } })
          .then(response => {
    				this.mobxState.pivotFilter = response.data.resources;
    			});
      });
  }; //end fetchData()

  deleteRequest = toSendArr => {
    this.mobxState.inDeletion = true;
    console.log('IN DELETION');
    axios
      .post('/v1/projects/' + this.projectId + '/freight_bids/batch_destroy', { id_set: toSendArr })
      .then(response => {
        this.mobxState.inDeletion = false
				console.log(response);
				this.fetchData();
	    });
  };
		//////////////////END HTTP/////////////////

	  //handles dropdown and chart filters
		handleFilter = (filterParam, selectedFilter) => {
    // In the state, pick the model based on filter that is sent and turn it into the array of values of the filter.
    // This goes as params to http request
    // Update state with selected options, removing the property if none selected
    // Equivalent to this.mobxState[filterParam] = selectedFilter.map(a => a.value)
    // but handles nested assignment
			this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
      deleteIfEmpty(this.mobxState.selectedModels, filterParam);

			//also store the selections in the original form. This goes back to the filter as selected values
			this.mobxState.selectedOptions[filterParam] = selectedFilter;
        if (_.isEmpty(this.mobxState.selectedOptions[filterParam])) {
          delete this.mobxState.selectedOptions[selectedFilter]
    }
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);
    console.log("SELETED MODELS ", this.mobxState.selectedModels)
			//fetch called, it reads the filter params from state
			this.fetchData();
  };

  handleCustomFilter = (filterParam, selectedFilter) => {
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http reques
    this.mobxState.selectedModels['custom_fields'] = this.mobxState.selectedModels['custom_fields'] || {};
    this.mobxState.selectedModels['custom_fields'][filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields`);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions['custom_fields'] = this.mobxState.selectedOptions['custom_fields'] || {};
    this.mobxState.selectedOptions.custom_fields[filterParam] = selectedFilter;
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields`);
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);
    console.log("SHOW ",this.mobxState.selectedModels )

    //fetch called, it reads the filter params from state
    this.fetchData();
  };
	  //handles dropdown and chart filters
		handleClear = () => {
			//in the state, clear the model. This goes as params to http request
			this.mobxState.selectedModels = {};

    // Clear the filters we send to the server
    this.mobxState.filtersForServer = {};

			//also store the explicit cleared selections in the original form. This goes back to the filter as selected values
			//loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
			//from ["supplier", "item"] to {"supplier":[], "item":[]}
    let newSelectedOptions = {custom_fields: {}}
    this.mobxState.filterModelNames.forEach(name => {
      newSelectedOptions[name] = []
    });
    this.mobxState.customFilterModelNames.forEach(name => {
      newSelectedOptions.custom_fields[name] = []
    });
    this.mobxState.selectedOptions = newSelectedOptions;

			//fetch called, it reads the filter params from state
			this.fetchData();
  };

		//for sidebar dock to change sizes
		handleSizeChange = size => {
			this.mobxState.dockOptions.size = size;
  };

		//sidebar dock toggle
		toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions
      .isVisible;
  };

		render() {
			const hcHeight = '300px';

			return (
				<div>
					{/* Small Left Toggle */}
            <div onClick={this.toggleDock} className='sidebar_button filter_toggle'>
                <i className='glyphicon glyphicon-chevron-left'></i>
            </div>

            <div className="jumbotron jumbotron_full_width">
                <div className="jumbotron_full_width_container">
                    <div className="row">
                        <div className="col-sm-5">
                            <span className="fa-layers fa-fw fa-2x">
                                <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#009C9C" style={{marginTop: '4px'}} />
                                <FontAwesomeIcon icon={['fal', 'dolly']} color="white" transform="shrink-5" style={{marginTop: '4px'}} />
                            </span>
                            &nbsp;
                            <b className="dash_header">Freight Bids Overview</b>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-3"> </div>
                        <div className="col-sm-2 gps">
                            <FontAwesomeIcon icon={['fad', 'location-arrow']} color="#53C8C5" transform='grow-15 left-10' style={{}} />
                            <Link to={'/sourcing/projects/'+this.projectId+'/scenarios'} className='link_style'>
                                Next: Optimize Award Scenarios
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

					{/* BADGES */}
          <div className='row'>
            <div className="col-lg-12">
              <div className="badge-container">
                <Badge
                  badgeNumber={zeroNull(
                  this.mobxState.cardData.freightBidCount).toLocaleString('en-US')}
                  badgeName={'Freight Bids'}
                  faClass={'gavel'}
                />
                <Badge
                  badgeNumber={zeroNull(this.mobxState.cardData.freightBidSupplierCount).toLocaleString('en-US')}
                  badgeName={'Freight Bid Bidders'}
                  faClass={'briefcase'}
                />
              </div>
            </div>
          </div>
			<br/>

					{/* Select Filter allows you to select the pivot_settings config to display*/}
          <div className='row'>
            <div className='col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg'>
									<b>Saved Project Analysis Configurations</b>
								</div>
                <div className='panel-body'>
  								{/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
  									<Select
                      onChange={e => this.clickChild(e)}
  										options={this.mobxState.pivotFilter}
  										closeMenuOnSelect={true}
  										maxMenuHeight = {400}
  										placeholder={'Select From List of Saved Project Tables'}
  									/>
  							</div>
  						</div>
  					</div>
  				</div>
					{/* PROJECT GRID */}
          <div className='row'>
            <div className='col-lg-12'>

                    {/* AG Grid */}
                    <GridRenderer
                        gridHeight={'700px'}
                        columnDefs={this.mobxState.columnDefsFreightBids}
                        rowData={this.mobxState.tableData.freight_bids}
                        fetchData={this.fetchData}
                        handleClear={this.handleClear}
                        toggleDock={this.toggleDock}
                        pageId={401}
                        savedState={this.mobxState.pivotState}
                        isPivotMode={true}
                        gridTitle={'Bid Details'}
                        setClick={click => (this.clickChild = click)}
                        deleteRequest={this.deleteRequest}
                        idColumn={"id"}
                        projectIdNumber={this.projectId}

                        modelsToUpdateArr={[{url:'v1/projects/'+this.projectId+'/freight_bids/', idName:"id"}]}
                        //modelsToUpdateArr={false}
                        // enableSetResizeChild={true}
                        // setResize={click => this.autofitChild = click}
                        enableSetResizeChildColumns={true}
                        setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

                        singleClickEdit={true}
                        suppressClickEdit={false}
                        isSaveable={true}
                        isAwardable={false}
                        isDeletable={true}
                        useCustomSchemaRowCount={true}
                        isLoadingTableData={this.mobxState.isLoadingTableData}
                        inDeletion={this.mobxState.inDeletion}
                    />
  				</div>
          </div>
          {/* END CHART ROW */}

					<br />
					{/* Sidebar */}
					<DockRenderer
						position={'right'}
						size={this.mobxState.dockOptions.size}
						dimMode={'none'}
						isVisible={this.mobxState.dockOptions.isVisible}
						onVisibleChange={this.handleVisibleChange}
						onSizeChange={this.handleSizeChange}
						fluid={true}
						toggleDock={this.toggleDock}
						handleClear={this.handleClear}
            // Current Filters----------------------------------
  					value={this.mobxState.selectedOptions}
            // Select Names-------------------------------------
            filterModelNames={this.mobxState.filterModelNames}
            customFilterModelNames={this.mobxState.customFilterModelNames}
            // Widget Types-------------------------------------
            filterWidgetTypes={this.mobxState.filterWidgetTypes}
            customFilterWidgetTypes={this.mobxState.customFilterWidgetTypes}
            // Options------------------------------------------
            options={this.mobxState.filterOptions}
            customFilterOptions={this.mobxState.customFilterOptions}
            // Handlers-----------------------------------------
  					handleFilter={this.handleFilter}
            handleCustomFilter={this.handleCustomFilter}
            //optionSearchFunctions={this.mobxState.optionSearchFunctions}
            // Visual options-----------------------------------
  					customOptions={this.mobxState.customOptions}
            // Callbacks----------------------------------------
            closeMenuOnSelect={true}
            maxMenuHeight={400}
					/>
        </div>);
		}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FreightBids, {mobxState: observable});
export default observer(FreightBids);
