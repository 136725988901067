import React from 'react';
import $ from 'jquery';
import _ from 'lodash';
import FIFOQueue from './FIFOQueue';
import axios from 'axios';
import namedColors from './NamedColors';

export const humanizeString = s => {
  try {
    return s.replace(/_+/g, " ")
            .trim()
            .toLowerCase()
            .replace(/\b[a-z]/ig, w => w.toUpperCase());
  } catch (e) {
    console.error(`Could not humanize string: ${s}`)
    return s;
  }
}

//COLOR TESTS
// TODO: Actually exclude attempts at numbers like 010
// TODO: Clamp rgb values between 0-255
export const isValidColor = s => {
  return (
    isValidHexColor(s)
    || isValidHexColorWithTransparency(s)
    || isNamedColor(s)
    || isValidRGBColor(s)
  )
};
export const isValidRGBColor = s => {
  return /^rgb\(\s*\d{1,3}%?\s*,\s*\d{1,3}%?\s*,\s*\d{1,3}%?\s*\)$/.test(s);
}

export const isValidRGBAColor = s => {
  return /^rgba\(\s*(\d{1,3}%?)\s*,\s*(\d{1,3}%?)\s*,\s*(\d{1,3}%?)\s*,\s*(0.[0-9]+|0%?|[^0]\d{0,2}%)\s*\)$/.test(s);
}

export const isValidHexColor = s => {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(s);
}

export const isValidHexColorWithTransparency = s => {
  return /^#([A-Fa-f0-9]{8})$/.test(s);
}

export const isNamedColor = s => {
  return namedColors.includes(s);
}

// USAGE
export const memoize = f => {
  let memo = new Map();
  return (...args) => {
    let keys = [...memo.keys()];
    for (var i = 0; i < keys.length; i++) {
      // Case: Recieved before
      if (_.isEqual(keys[i], args)) {
        return memo.get(keys[i]);
      }
    }
    // Case: New args
    let value = f(...args);
    memo.set(args, value);
    return value;
  };
};

export const forgetfulMemoize = (f, n) => {
  let keys = new FIFOQueue(n);
  let vals = new FIFOQueue(n);
  return (...args) => {
    for (var i = 0; i < keys.items.length; i++) {
      if (_.isEqual(keys.items[i], args)){
        return vals.items[i];
      }
    }
    // Case: New args
    let value = f(...args);
    keys.push(args);
    vals.push(value);
    return value;
  };
};

//convert NaN to 0
export const zeroNull = param => {
  return param || 0; //isNaN(param) ? 0 : param
};

export function extractToken(tokenString, splitter, tokenNumber) {
  let tokenArray = tokenString.split(splitter);
  return tokenArray[tokenNumber];
}

export const complement = pred => (...args) => !pred(...args);

// keepKeysWhere :: obj => (k => v => bool) => obj
export const keepKeysWhere = (obj, pred) => {
  return _(obj)
    .toPairs()
    .filter(([k, v]) => pred(k, v))
    .fromPairs()
    .value();
};
// keepKeysWhere :: obj => (k => v => bool) => obj
export const keepKeysWhereNot = (obj, pred) => {
  return keepKeysWhere(obj, complement(pred));
};

// selectKeys :: obj => [paths] => obj
export const selectKeys = (obj, keylist) => {
  let newObj = {};
  keylist.map(path => _.set(newObj, path, _.get(obj, path)));
  return newObj;
};

// removeKeys :: obj => [paths] => obj
export const removeKeys = (obj, keylist) => {
  let newObj = _.cloneDeep(obj);
  keylist.map(path => _.unset(newObj, path));
  return newObj;
};
export const replaceKeys = (obj, lookup) => {
  let newObj = {};
  Object.keys(obj).forEach(key => {
    newObj[lookup[key] || key] = _.cloneDeep(obj[key]);
  });
  return newObj;
};

export const deleteIfEmpty = (obj, keyPath) => {
  if (_.isEmpty(_.get(obj, keyPath, {}))) {
    _.unset(obj, keyPath);
  }
  return obj;
};

// Like deleteIfEmpty, but makes deep copy to avoid mutation of object
// Deep copy is slower, but means we aren't introducing side effects
// Is called pure to indicate lack of side effects
// deleteIfEmptyPure :: object => object
export const deleteIfEmptyPure = (obj, keyPath) => {
  let newObject = _.cloneDeep(obj);
  if (_.isEmpty(newObject, keyPath)) {
    _.unset(newObject, keyPath);
  }
  return newObject;
};

export const newJsonFieldSchema = (newCustomFieldName, displayOrderNumber) => {
  return {
    [newCustomFieldName]: {
      schema: {
        type: 'string'
      },
      field_name: newCustomFieldName,
      field_alias: _.startCase(newCustomFieldName), //humanize custom field name
      display_order: displayOrderNumber,
      hidden: false,
      required: false
    }
  };
};

export const formatForTreeSelect = (options, accessorFunctions, includeIdInLabel = true) => {
  let sortTitlesAlphabetically = arr => {
    return arr.sort((a, b) => {
      return ('' + a.title).toLowerCase().localeCompare(('' + b.title).toLowerCase());
    });
  };
  // Recursion scheme:
  // - Recurse until we're either out of options (I.E. Whole hierarchy isn't used)
  //   or we're out of accessor functions (generally this will be if we max out accessing level 4 or 5)
  let inner = (options, accessorFunctions, depth = 0, maxDepth = 10) => {
    let getNextLabel = opt => accessorFunctions[1] && accessorFunctions[1](opt);
    let isClassified = label => label && !['', '--', undefined, null].includes(label);
    let grouped = _.groupBy(options, accessorFunctions[0]);
    let pairs = _.toPairs(grouped);
    let out = [];
    // Case: Exceeded Recursive Depth
    if (depth > maxDepth) {
      throw new Error('Max depth exceeded while formatting options for tree select');
    }
    // Case: We've bottomed out
    if (options.length === 0){
      return []
    };

    pairs.forEach(([levelTitle, options]) => {
      // Find a option corresponding to level if possible
      let levelOptionIX = options.findIndex(opt => !isClassified(getNextLabel(opt)));
      let levelOption = options[levelOptionIX];
      let children = options.filter((el, ix) => ix !== levelOptionIX);
      let childResults = inner(children, accessorFunctions.slice(1), depth + 1, maxDepth);
      if (typeof levelOption !== 'undefined'){
        // Case We Have Category To Use For Option
        let result = {
          title: (
            includeIdInLabel
            ? `${accessorFunctions[0](levelOption)} (${levelOption.id})`
            : `${accessorFunctions[0](levelOption)}`
          ),
          value: `${levelOption.id}`,
          children: childResults,
          selectable: true,
        };
        out.push(result)
      } else {
        // Case We Dont Have Category To Use For Option
        let result = {
          title: `${levelTitle} (Expand for options)`,
          value: `${levelTitle} (Expand for options)`,
          children: childResults,
          selectable: false,
        };
        out.push(result);
      }
    });
    return sortTitlesAlphabetically(out);
  };
  let retval = inner(options, accessorFunctions, 1, 10);
  return retval;
};

export const determineCategoryLabel = (category, includeIdInLabel=true) => {
  let consideredUndefined = v => [undefined, null, '', '--'].includes(v);
  let accessorFunctions = [
    r => (r.custom_fields && r.custom_fields.custom_field_1) || '',
    r => (r.custom_fields && r.custom_fields.custom_field_2) || '',
    r => (r.custom_fields && r.custom_fields.custom_field_3) || '',
    r => (r.custom_fields && r.custom_fields.custom_field_4) || '',
    r => (r.custom_fields && r.custom_fields.custom_field_5) || ''
  ];
  //prettier-ignore
  if (category){
    let mostPreciseLabel = (
      accessorFunctions
        .map(f => f(category))                   // Get labels from category
        .reverse()                               // Order options
        .filter(l => !consideredUndefined(l))[0] // First defined option
    );
    return (
      includeIdInLabel
      ? `${mostPreciseLabel} (${category.id})`
      : `${mostPreciseLabel}`
    )
  }
};

//function to load up jquery sidebar..
//this is not in the index.js because calling this function in componentDidMount kept double loading the function every time component was loaded
// export function setUpSidebar() {
//   // $('#sidebar, #content').toggleClass('active');
//   $( document ).ready(function() {
//       console.log( "ready!" );
//   });
//   $('#sidebarCollapse').on('click', function () {
//       console.log("SIDEBAR TOGGLE");
//       $('#sidebar, #content').toggleClass('active');
//       $('.collapse.in').toggleClass('in');
//       $('a[aria-expanded=true]').attr('aria-expanded', 'false');
//   });
// }
/* TESTS vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
   Remove these when better test harness in place */
let testMemoize = async () => {
  let count = 0;
  let f = x => {
    count++;
    return count;
  };
  let mf = memoize(f);
  let v11 = mf(1);
  let v21 = mf(2);
  let v12 = mf(1);
  return v11 === v12 && v11 !== v21;
};

let testForgetfulMemoize = async () => {
  let count = 0;
  let f = x => {
    count++;
    return count;
  };
  let mf = forgetfulMemoize(f, 2);
  let v11 = mf(1); // counter: 1, calls-to-f: 1
  let v12 = mf(1); // counter: 1, calls-to-f: 1
  let v21 = mf(2); // counter: 2, calls-to-f: 2
  let v31 = mf(3); // counter: 3, calls-to-f: 3
  let v13 = mf(1); // counter: 4, calls-to-f: 4 (1 no longer in queue)
  return (v11 === 1 && v12 === 1 && v13 === 4);
};

let test = async () => {
  let allPass = true;
  await testMemoize().then(val => {
    allPass = allPass && val;
  });
  await testForgetfulMemoize().then(val => {
    allPass = allPass && val;
  });
  alert(`Tests all pass? ${allPass}`);
};

