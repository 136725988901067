import React from "react"

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from "../HCGlobal";

import Highcharts from 'highcharts';
require('highcharts/modules/funnel.src')(Highcharts);


//basic barchart options.. not using react library. Highcharts already loaded in asset pipeline
class FunnelChart extends React.Component {

	//fires on initial mount
	componentDidMount() {
		setHighChartsGlobals();
		this.loadChart();
	}

	// //mount data ON CHANGE
	componentDidUpdate (prevProps, prevState) {
		if(prevProps.funnelData !== this.props.funnelData) {
			setHighChartsGlobals();
			this.loadChart();
		}//end if statement state check
	}//end component did update

	//load chart function with options and cart drawer
	//issue with separating options and chart drawer.. probably something to do with it being a js drawer.. and maybe an async issue
	loadChart = () => {
		console.log('Loading funnel chart id', this.props.renderInDivId);
		var options = {
			chart: {
				type: 'funnel'
			},
			subtitle: {
				text: this.props.funnelTitle
			},
			title: {
				text: null
			},
			plotOptions: {
				series: {
					dataLabels: {
						enabled: true,
						format: '<b>{point.name}</b> ({point.y:,.0f})',
						softConnector: true
					},
					center: ['40%', '50%'],
					neckWidth: '15%',
					neckHeight: '25%',
					width: '80%',
					cursor: 'pointer',
					point: {
						events: {
							click: (e) => {
								//console.log(e.point.name); console.log(e.point.category); console.log(e.point.y);
								//send back the filterParam assigned in props - this names the field being sent
								//send back the e.point.name BUT in react-select format, so the selector could read it right out of mobxState
								this.props.handleFilterFunction(this.props.filterParam, [{label: `${e.point.name}`, value: e.point.name}] );
							}
						}
					}
				}
			},
			series: [{
				allowPointSelect: true,
				data: this.props.funnelData
			}],
			legend: {
				enabled: false
			}
		}//end options
		//use javascript to render to id.. id name passed via props
		var hcFunnelData = Highcharts.chart(this.props.renderInDivId, options);
	}


	render() {
		return (
			<div id={this.props.renderInDivId} style={{height: this.props.height}}></div>
		);
	}
}

export default FunnelChart
