import React from "react";
import PropTypes from "prop-types";

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from "../HCGlobal";

import Highcharts from 'highcharts';
require('highcharts/highcharts-more.js')(Highcharts);

//basic barchart options.. not using react library. Highcharts already loaded in asset pipeline
class BubbleChart extends React.Component {

	//fires on initial mount
	componentDidMount() {
		setHighChartsGlobals();
		this.loadChart();
	}

	// //mount data ON CHANGE
	componentDidUpdate (prevProps, prevState) {
		if(prevProps.bubbleData !== this.props.bubbleData) {
			setHighChartsGlobals();
			this.loadChart();
		}//end if statement state check
	}//end component did update

	//load chart function with options and cart drawer
	//issue with separating options and chart drawer.. probably something to do with it being a js drawer.. and maybe an async issue
	loadChart = () => {
		console.log('Loading bubble chart id', this.props.renderInDivId);
		var options = {
			chart: {
				type: 'bubble',
				plotBorderWidth: 1,
				zoomType: 'xy'
			},
			subtitle: {
				text: this.props.bubbleTitle
			},
			title: {
				text: null
			},
			xAxis: {
				lineWidth: 0,
				minorGridLineWidth: 0,
				lineColor: 'transparent',
				minorTickLength: 0,
				tickLength: 0,
				gridLineWidth: 0,
				title: {
					text: this.props.xTitle
				},
				labels: {
					format: '{value}'
				},
				plotLines: [{
					color: 'black',
					dashStyle: 'dot',
					width: 2,
					value: 65,
					label: {
						rotation: 0,
						y: 15,
						style: {
							fontStyle: 'italic'
						},
					},
					zIndex: 3
				}]
			},
			yAxis: {
				gridLineWidth: 0,
				minorGridLineWidth: 0,
				startOnTick: false,
				endOnTick: false,
				title: {
					text: this.props.yTitle
				},
				labels: {
					format: '{value}'
				},
				maxPadding: 0.2,
				plotLines: [{
					color: 'black',
					dashStyle: 'dot',
					width: 2,
					value: 50,
					label: {
						align: 'right',
						style: {
							fontStyle: 'italic'
						},
						x: -10
					},
					zIndex: 3
				}]
			},
			tooltip: {
				useHTML: true,
				headerFormat: '<table>',
				pointFormat: '<tr><th colspan="2"><h3>{point.name}</h3></th></tr>' +
				'<tr><th>'+this.props.xTitle+'</th><td>{point.x}</td></tr>' +
				'<tr><th>'+this.props.yTitle+'</th><td>{point.y}</td></tr>' +
				'<tr><th>'+this.props.zTitle+'</th><td>{point.z}</td></tr>',
				footerFormat: '</table>',
				followPointer: true
			},
			//NEED TO TAKE OUT SERIES AND COPY ANGULARJS plot options?
			series: [{
				allowPointSelect: true,
				data: this.props.bubbleData
				//     data: [
				//     { x: 95, y: 95, z: 13.8, name: 'BE', country: 'Belgium' },
				//     { x: 86.5, y: 102.9, z: 14.7, name: 'DE', country: 'Germany' },
				// ]
			}],
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					cursor: 'pointer',
					point: {
						events: {
							click: (e) => {
								//console.log(e.point.name); console.log(e.point.category); console.log(e.point.y);
								//send back the filterParam assigned in props - this names the field being sent
								//send back the e.point.name BUT in react-select format, so the selector could read it right out of mobxState
								this.props.handleFilterFunction(this.props.filterParam, [{label: `${e.point.name}`, value: e.point.name}] );
							}
						}
					}
				}
			}
		}//end options
		//use javascript to render to id.. id name passed via props
		var hcBubbleData = Highcharts.chart(this.props.renderInDivId, options);
	} //end loadChart()

	render() {
		return (
			<div id={this.props.renderInDivId} style={{height: this.props.height}}></div>
		);
	}
}

export default BubbleChart
