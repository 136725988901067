import React from 'react';
import { render } from 'react-dom';
import XLSX from 'xlsx'; //excel and csv parsing into JSON object
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//csv parser & file saving helpers/shims
import papaparse from 'papaparse';
import { saveAs } from 'file-saver';

const _ = require('lodash'); //extra utilities

// Relevant helper docs:
// Class Syntax: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes
// SheetJS: https://github.com/sheetjs/sheetjs
// FileReader API: https://developer.mozilla.org/en-US/docs/Web/API/FileReader
// Working with files: https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications

// Workflow:
// const importer = new FileImporter();
// importer.addSchema({
//    item: {},
//    bids: {},
//   <modelOrTableBeingImported>: {
//     year: {
//       schema: {
//         type: 'number',
//         enum: null
//       },
//       field_name: 'year',
//       field_alias: 'year project performed in',
//       table_alias: 's2',
//       field_example: '1993',
//       display_order: 2
//     }
//   }
// });
// <Wizard Page 1: Select what type of import you're doing>
// importer.setModelNameForMapping(user_selection) // Wire this to your select
// importer.generateTemplate() // Use selected model name to generate template
// <Wizard Page 2: Import the file>
// importer.addFile(window.$("input[type='file']").files[0]);
// <Wizard Page 3: Setup mappings>
// importer.addMapping('year', 'year_of_sale') //
// importer.validateSchema() // Check based on first few rows
// importer.submit() // Send both file and mapping
// <Wizard Page 4: Show Results>

class FileImporter {
  mobxState = {
    fileValid: true,
    rawFile: {}, // File user has uploaded
    preview: [], // First several rows of data
    modelName: null, // Name of the model being mapped currently
    mapping: {} // Mapping of field_name from file to field_name of model {<csv_field_name>: <model_fieldname>}
  };
  // Schema Methods (Require Just Schema)
  addSchema = schema => (this.mobxState.schema = schema);
  getSchema = () => this.mobxState.schema;

  getModelForMapping = () => this.mobxState.modelName;
  setModelForMapping = modelName => (this.mobxState.modelName = modelName);
  // File Methods  (Require Just File)
  addFile = file => {
    this.mobxState.rawFile = file;
    this.mobxState.preview = null;
    this.updatePreview();
  };
  updatePreview = async () => {
    try {
      let fileType = this.mobxState.rawFile.type === 'text/csv' ? 'csv' : 'buffer';
      let fileOrStringToParse = this.mobxState.rawFile; // Either a File or a string
      // If it's excel, first turn the first 3 rows into a csv file
      if (fileType === 'buffer') {
        let buffer = await fileOrStringToParse.arrayBuffer();
        let sheetNames = XLSX.read(buffer, { type: 'buffer', bookSheets: true })['SheetNames']; // Parse just the sheetnames
        let workbook = XLSX.read(buffer, { type: 'buffer', sheets: [sheetNames[0]], sheetRows: 3 }); // Parse just first n sheetRows
        let sheet = workbook.Sheets[sheetNames[0]];
        fileOrStringToParse = XLSX.utils.sheet_to_csv(sheet);
      }
      // Parse the data
      papaparse.parse(fileOrStringToParse, {
        preview: 3, // Parse first 3 rows
        complete: (results, file) => {
          this.mobxState.preview = results;
        }
      });
    } catch (error) {
      this.mobxState.fileValid = false;
      throw error;
    }
  };
  getFileSize = () => {
    let inBytes = this.mobxState.rawFile.size;
    return inBytes;
  };
  // Template (Requires Schema & Desired Model)
  // Generate xlsx from the schema and provided examples
  getTemplate = objectType => {
    // Generate the data for the sheet
    let { schema, modelName } = this.mobxState;
    let keysToUse = Object.keys(schema[modelName]);
    keysToUse.sort((a, b) => {
      let aOrder = parseFloat(schema[modelName][a]['display_order'] || 'Infinity');
      let bOrder = parseFloat(schema[modelName][b]['display_order'] || 'Infinity');
      return aOrder - bOrder;
    });
    let aoaHeaders = keysToUse;
    let aoaBody = [];
    keysToUse.forEach((k, colIx) => {
      (schema[modelName][k]['examples'] || []).forEach((el, rowIx) => {
        // Initial blank array if needed and fill cell
        aoaBody[rowIx] = aoaBody[rowIx] || Array.apply(null, Array(aoaHeaders.length)); // Wierd invocation to make array iterable
        aoaBody[rowIx][colIx] = el;
      });
    });
    // Build and save the workbook
    const ws = XLSX.utils.aoa_to_sheet([aoaHeaders, ...aoaBody]);
    const wb = XLSX.utils.book_new();
    const ws_name = modelName;
    // wb.Props = {
    //   Title: 'Import Template',
    //   Subject: 'Example Import Sheet',
    //   Author: 'EC Sourcing',
    //   CreatedDate: new Date()
    // };
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    //create and downloading workbook
    XLSX.writeFile(wb, `import_template.xlsx`);
  };
  // Mapping (Require Schema & Desired Model & File)
  addMapping = (fieldToMapFrom, fieldToMapTo) => {
    this.mobxState.mapping[fieldToMapFrom] = fieldToMapFrom;
  };
  // Validation (Requires Schema & File/File Preview)
  validateSchema = () => {
    // Check the rows from the preview, and validate their types
    const [headers, ...examples] = this.mobxState.preview[0];
    let valid = true;
    Object.entries(this.mobxState.schema[this.mobxState.modelName]).forEach((field, definition) => {
      // Do checks for each row in preview data
    });
    return true;
  };
  // Get Submission Params (Requires Schema & Desired Model & File & Mapping)
  getSubmitParams = () => {
    return {
      file: this.mobxState.rawFile,
      model: this.mobxState.modelName,
      mapping: this.mobxState.mapping
    };
  };
  // Rendering ------------------------------------
  showState = () => {
    window.cheapGlobal = {};
    window.cheapGlobal.fileImporter = this;
    window.cheapGlobal.XLSX = XLSX;
    return (
      <div>
        {/* Calculate size */}
        <h3>size</h3>
        <p>{this.getFileSize()}</p>
        <h3>valid?</h3>
        <p>{JSON.stringify(this.mobxState.fileValid)}</p>
        <h3>preview</h3>
        <p>{JSON.stringify(this.mobxState.preview && this.mobxState.preview.data, null, 2)}</p>
      </div>
    );
  };
  render() {
    return <div></div>;
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FileImporter, {
  mobxState: observable
});

export default observer(FileImporter);
