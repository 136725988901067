import React from 'react';
import Select from 'react-select'; //select box for filter

//numeric input
import NumericInput from 'react-numeric-input';
//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class BidPriceInput extends React.Component {

  //mount data through http requests
  componentDidMount() {
  }

  render(){

    return(
      <div className="row">
        <div className='col-lg-2'>
          <div>
            <FontAwesomeIcon
              icon={['fad', 'tags']}
              mask={['fas', 'circle']}
              size='4x'
              transform='shrink-6'
              color='#4CABA9'
              style={{ marginTop: '5px' }}
            />
          </div>
        </div>
        <div className="col-lg-10">
          <b>{this.props.fieldAlias}</b>
          <NumericInput
            onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
            className="form-control"
            name="priceInput"
            min={0}
            placeholder={this.props.placeholder}
          />
        </div>
      </div>
    )//end return
  }//end render

}//end class


export default BidPriceInput;
