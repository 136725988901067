import React from "react"
import { render } from 'react-dom'

//other components built
import Badge from "../../../badges/Badge";
import GridColumn from "../../../services/GridColumn"; //builds ag-grid columns
import UrlSniffer from "../../../services/UrlSniffer"; //work with rails paths to get project token

//filters
import DockRenderer from "../../../filters/DockRenderer";
import FilterQuickAction from "../../../filters/FilterQuickAction"; //filter controls
import Select from 'react-select'; //select box for filter

//highcharts
import BarChart from "../../../charts/basics/BarChart";
import PieChart from "../../../charts/basics/PieChart";
import LineChart from "../../../charts/basics/LineChart";

//percentage donut
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from "../../../grids/GridRenderer";

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import {zeroNull} from "../../../services/Utilities";

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();


//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class ContractOverview extends React.Component {

	//variables watched with mobX
	customOptions = [];
	cardData = {
		"contractValue": 0,
		"contractCount": 0,
		"contractSupplierCount": 0,
		"supplierContractSum": [], //charts
		"on_contract_count": [], //charts
		"dbe_count": [], //charts
		"cat_1_top_sum": [], //charts
		"year_month_sum_line": [],
	};
	tableData = {
		"contracts": []
	}; //all the rows of data in json
	selectedModels = {
	}; //multi select data model for http
	selectedOptions = {
	}; //multi select data model in filter value/label format
	pivotData = [];
	pivotState = [];
	pivotFilter = [];
	filterModelNames = ['year', 'month', 'Con_Title', 'Supplier', 'Status_Name', 'Cat1', 'Cat2', 'Cat3', 'Cat4',  'Con_A01', 'Con_A02']; //autobuilds filters and clear functions
	dockOptions = {
		isVisible: false,
		size: 0.2
	} //sidebar defaults

	//mount data through http requests
	componentDidMount() {
		//call the data loader to load data into the view
		this.fetchData();
	}

	//////////////////HTTP/////////////////
	//data loader
	fetchData = () => {
		//quick workaround -- pull simple dropdown value from nested component instead of passing around state
		var table_rows =  window.$('#table_rows_dropdown').val();

		let models_with_rows = {...this.selectedModels, ...{"table_rows": table_rows}};

		//load data for cards and filters
		axios.get("/microservices/v1/analytics/contract_overview", { params: models_with_rows })
		.then(response => {
			this.cardData = response.data;
		});

		//set up custom options / field labels for table, then get data for table
		axios.get("/v1/ref_cmfields_labels")
		.then((response) => {
			console.log("LABELS RESPONSE: ", response.data);
			//get map of field labels from API
			this.customOptions = response.data;
			//include any custom field names not in db !!THIS is not uniform in db.. the PM modules have all the field labels, but contracts and Projects
			//don't have all the necessary labels in the same api call
			this.customOptions = {...this.customOptions, ...{"year": "Exp. Year", "month": "Exp. Month", "Cat1":"Category 1", "Cat2":"Category 2",
			"Cat3":"Category 3", "Cat4":"Category 4", "Con_Title":"Contract", "Supp_No":"Supplier #", "Supplier":"Supplier", "Status_Name":"Status", "Status_Desc":"Status Description",
			"con_value":"Contract Value", "Exp_Date":"Expires", "Effect_Date":"Effective Date", "LastMod_Date":"Last Modified", "Con_File":"Filename"}};
			//merge the filter model with the amount of rows selected

			//load data for ag grid
			axios.get("/microservices/v1/analytics/contract_overview_data", { params: models_with_rows })
			.then(response => {
				this.tableData = response.data;
				//console.log("cardData RESPONSE: ", response.data);
				//set the column definitions for ag grid, look into buildColumnDefs class to see options
				this.columnDefsContracts = gridColumns.buildColumnDefs({"customOptions": this.customOptions, "columnArray": ['contract_overview'], "projectType":0, "isScenario":0});
				//render the filter dropdown choices for specific page id
				axios.get("/v1/resources/index_filter", {params: {"page_id": 1} })
				.then((response) => {
					this.pivotFilter = response.data.resources;
				});
			});
		}) //end initial then
		.catch(console.log.bind(console)) //error catcher
	} // end fetchData

	deleteRequest = (toSendArr) => {
		console.log("IN DELETION");
    axios.post('/v1/cm_contracts1/batch_destroy', {"id_set": toSendArr})
    .then((response) => {
			console.log(response);
			this.fetchData();
    });
	}
	//////////////////END HTTP/////////////////

  //handles dropdown and chart filters
	handleFilter = (filterParam, selectedFilter) => {
		console.log('Handle Filter Callback: ');
		console.log('selectedFilter ', selectedFilter); console.log('filterParam ', filterParam);
		//in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
		this.selectedModels[filterParam] = selectedFilter.map(a => a.value);
		//also store the selections in the original form. This goes back to the filter as selected values
		this.selectedOptions[filterParam] = selectedFilter;
		//fetch called, it reads the filter params from state
		this.fetchData();
	}

    //handles dropdown and chart filters
	handleClear = () => {
		//in the state, clear the model. This goes as params to http request
		this.selectedModels = {};
		//also store the explicit cleared selections in the original form. This goes back to the filter as selected values
		//loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
		//from ["supplier", "item"] to {"supplier":[], "item":[]}
		const optionsClear = {};
		for (const key of this.filterModelNames) { optionsClear[key] = [];}
		this.selectedOptions = optionsClear;
		//fetch called, it reads the filter params from state
		this.fetchData();
	}

	//for sidebar dock to change sizes
	handleSizeChange = size => {
		this.dockOptions.size = size;
	}

	//sidebar dock toggle
	toggleDock = () => {
		this.dockOptions.isVisible = !this.dockOptions.isVisible;
	}


	render() {

		const hcHeight = '350px'

		return (

			<div>

				{/* Small Left Toggle */}
				<div onClick={this.toggleDock}  className="sidebar_button filter_toggle"><i className="glyphicon glyphicon-chevron-left"></i></div>

				<div className="jumbotron jumbotron_full_width">
					<div className="jumbotron_full_width_container">
						<b className="dash_header">Executive Contract Details</b>
					</div>
				</div>


				{/* BADGES */}
				<div className="row">
					<div className="col-lg-4">
						<Badge
							badgeNumber={zeroNull(this.cardData.contractCount).toLocaleString('en-US')}
							badgeName={'Contracts'}
							faClass={'briefcase'}
						/>
					</div>
					<div className="col-lg-4">
						<Badge
							badgeNumber={zeroNull(this.cardData.contractSupplierCount).toLocaleString('en-US')}
							badgeName={'Contracted Suppliers'}
							faClass={'square-list'}
						/>
					</div>
					<div className="col-lg-4">
						<Badge
							badgeNumber={zeroNull(this.cardData.contractValue).toLocaleString('en-US')}
							badgeName={'Contracted Spend'}
							faClass={'square-list'}
						/>
					</div>
				</div>

				<br />
				<br />

				{/* SUMMARY CHARTS */}
				<div className="row">
					<div className="col-lg-4">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg">
								<b>Contract Statuses </b>
								<span className="pull-right">
									<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
								</span>
							</div>
							<div className="panel-body">
								<div className="col-md-12">
									<PieChart
										renderInDivId={'supplier_pie'}
										handleFilterFunction={this.handleFilter}
										pieData={this.cardData.contractCategoryCount}
										pieTitle={'Status By Contract Count'}
										height={hcHeight}
										filterParam={'Status_Name'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-8">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg">
								<b>Contract Expiration</b>
								<span className="pull-right">
									<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
								</span>
							</div>
							<div className="panel-body">
								<div className="col-md-12">
									<LineChart
										renderInDivId={'line_contract_count'}
										handleFilterFunction={this.handleFilter}
										lineData={[this.cardData.contractExpirationCount]}
										lineTitle={'Expiring Contracts In The Nearest Four Years'}
										lineYTitle={'Contracts'}
										height={hcHeight}
										filterParam={'year_month'}
										lineType={'area'}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>{/* END CHART ROW */}

				<br/>
				{/* Select Filter allows you to select the pivot_settings config to display*/}
				<div className="row">
					<div className="col-sm-12">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg">
								<b>Saved Contract Analysis Configurations</b>
							</div>
							<div className="panel-body">
							{/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
								<Select
									onChange={(e) => this.clickChild(e)}
									options={this.pivotFilter}
									closeMenuOnSelect={true}
									maxMenuHeight = {400}
									placeholder={'Select From List of Saved Contract Tables'}
								/>
							</div>
						</div>
					</div>
				</div>

				{/* CONTRACT GRID */}
			    <div className="row">
						<div className="col-lg-12">
							{/* AG Grid */}
							<GridRenderer
								gridHeight={"650px"}
								columnDefs={this.columnDefsContracts}
								rowData={this.tableData.contracts}

								fetchData={this.fetchData}
								handleClear={this.handleClear}
								toggleDock={this.toggleDock}
								pageId={1}

								savedState={this.pivotState}
								isPivotMode={true}
								gridTitle={"Contract Details"}

								setClick={click => this.clickChild = click}

								deleteRequest={this.deleteRequest}
								idColumn={"Con_ID"}

								modelsToUpdateArr={[{url:"v1/cm_contracts1/", idName:"Con_ID"}]}
								//modelsToUpdateArr={false}

								singleClickEdit={true}
								suppressClickEdit={false}

								isSaveable={true}
								isAwardable={false}
								isDeletable={false}
							/>

						</div>
					</div>{/* END CHART ROW */}

				<br />
				{/* Sidebar */}
				<DockRenderer
					position={'right'}
					size={this.dockOptions.size}
					dimMode={'none'}
					isVisible={this.dockOptions.isVisible}
					onVisibleChange={this.handleVisibleChange}
					onSizeChange={this.handleSizeChange}
					fluid={true}

					toggleDock={this.toggleDock}
					handleClear={this.handleClear}

					value={this.selectedOptions}
					options={this.cardData}
					closeMenuOnSelect={true}
					maxMenuHeight = {400}
					handleFilter={this.handleFilter}
					customOptions={this.customOptions}
					filterModelNames={this.filterModelNames}
				/>

			</div>
		); //end return
	} // end render

} //end class

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ContractOverview, {
	dockOptions: observable,
	customOptions: observable,
	cardData: observable,
	selectedModels: observable,
	selectedOptions: observable,
	pivotData: observable,
	pivotFilter: observable,
	filterModelNames: observable
})

export default observer(ContractOverview);

// export default ContractOverview;
