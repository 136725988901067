import React from 'react';
import PropTypes from 'prop-types';

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from '../HCGlobal';

import Highcharts from 'highcharts';
import GridRenderer from '../../grids/GridRenderer';
import * as GridValueFormatters from '../../services/GridColumnFormatters';

//basic barchart options.. not using react library. Highcharts already loaded in asset pipeline
class TableChart extends React.Component {
  constructor(props) {
    super(props);

    let tmpColDefs; // Holds temporary column definitions
    let formatters = GridValueFormatters; // formatters for the purpose of evaling colDefs
    let filterOnField = filterName => {
      return event => {
        props.handleFilterFunction(filterName, [{ label: event.value, value: event.value }]);
      };
    };

    // Don't crash on poorly defined column defs.
    try {
      eval(`tmpColDefs = ${props.columnDefs}`);
    } catch (e) {
      console.error('Evaluation of columnDefs failed with following error during component construction.', e);
    }
    this.state = { evaluatedColumnDefs: tmpColDefs || [] };
  }

  //fires on initial mount
  componentDidMount() {
    setHighChartsGlobals();
    setTimeout(() => this.autofitChild(), 0); // Autofit the chart, but do so after render (at the back of async queue)
  }

  // Provide limited number of constructor functions to be used client side
  // We need the ability for columnDefs to insert filters
  // to do this, the columnDefs are eval'd.

  render() {
    return (
      <div id={this.props.renderInDivId} style={{ height: this.props.height }}>
        {/* AG Grid */}
        <GridRenderer
          gridHeight={this.props.height}
          columnDefs={this.state.evaluatedColumnDefs}
          rowData={this.props.rowData}
          fetchData={(...args) => {}}
          handleClear={this.props.handleClear}
          toggleDock={this.props.toggleDock}
          pageId={this.pageId}
          savedState={null}
          isPivotMode={true}
          gridTitle={this.props.tableTitle}
          setClick={click => (this.clickChild = click)}
          deleteRequest={this.deleteRequest}
          idColumn={'id'}
          modelsToUpdateArr={[{ url: '' }]}
          //modelsToUpdateArr={false}
          enableSetResizeChild={true}
          setResize={click => (this.autofitChild = click)}
          // enableSetResizeChildColumns={true}
          // setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

          singleClickEdit={true}
          suppressClickEdit={false}
          isSaveable={false}
          isAwardable={false}
          isDeletable={false}
          isTableRowSelectable={false}
          isLoadingTableData={this.props.isLoadingTableData}
        />
      </div>
    );
  }
}

export default TableChart;
