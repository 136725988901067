//http requests
import axios from 'axios';

export const asyncLoadCharts = async (mobxState, params) => {
  const url = '/v1/visualization_client_configs_index';
  const preload = () => axios.post(url, { ...params, suppress_chart_data: true });
  const loadFull = () => axios.post(url, { ...params, suppress_chart_data: false });
  const loadParticular = id => axios.post(url, { ...params, id: id, suppress_chart_data: false });
  // Load up our statuses
  preload().then(response => {
    mobxState.chartRenderData = response.data.visualizations.map(x => {
      return {
        ...x,
        loading: true, // Tag records as loading
        chart_data: [] // Set blank attribute since we're suppressing this
      };
    });

    Object.entries(response.data.visualizations).forEach(([ix, el]) => {
      loadParticular(el.id).then(response => {
        // Tag records as loaded
        mobxState.chartRenderData[ix] = response.data.visualizations[0] || mobxState.chartRenderData[ix];
        mobxState.chartRenderData[ix]['loading'] = false;
      });
    });
    return response;
  });
};

