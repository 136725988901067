import React, { useEffect, useState, Component } from 'react';
// import IdleTimer from "./IdleTimer";
import './App.scss';

//ui requirements
import $ from 'jquery'; //for table dropdown
import 'jquery/src/jquery'; //for react bootstrap
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import PropTypes from "prop-types"
import { render } from 'react-dom'

import Login from './Login';
import MainHeader from './components/headers/MainHeader';
import ReactNotification from 'react-notifications-component';

import withAuth from './components/security/withAuth';

import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

import Executive from './components/modules/executive/Executive';
import Sourcing from './components/modules/sourcing/Sourcing';
import Spend from './components/modules/spend/Spend';
import Setup from './components/modules/setup/Setup';
import Headquarters from './components/modules/headquarters/Headquarters';
import NotFound from './components/modules/headquarters/views/messages/NotFound';


import socket from './socketio';


class App extends Component {

  constructor(props) {
    super(props);
    this.addNotification = this.addNotification.bind(this);
    this.notificationDOMRef = React.createRef();
  }

  addNotification(success, title, message) {
    const type = success ? "success" : "danger";
    this.notificationDOMRef.current.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true }
    });
  }

  componentDidMount() {
    
    socket.on("message", (data) => {
      this.addNotification(data.success, data.title, data.message);
    });

  }

  render() {
    return (
      <div>
        <ReactNotification ref={this.notificationDOMRef} />
        <Router>
          <div>
            <Switch>
              <Route path="/notfound" component={withAuth(NotFound)} />
              <Route path="/headquarters" component={withAuth(Headquarters)} />
              <Route path="/executive" component={withAuth(Executive)} />
              <Route path="/sourcing" component={withAuth(Sourcing)} />
              <Route path="/spend" component={withAuth(Spend)} />
              <Route path="/setup" component={withAuth(Setup)} />
              <Route path="/" component={Login} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}
export default App;
