import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
const projectId = extractToken(window.location.pathname, '/', 3);
console.log('Project Id', projectId);

//handles rendering form components and their validation
class SupplierIdSelection extends React.Component {

  mobxState = {
    supplierSelect: [], //options for select box
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    ////////////////////
    //FORM LOAD
    //OPERATORS:
    axios.get('/v1/projects/'+projectId+'/project_suppliers/project_index_filter', {params: {} })
    .then((response) => {
      this.mobxState.supplierSelect = response.data.project_suppliers;
      // console.log('INSIDE COMPONENT');
      console.log('Supplier Select', this.mobxState.supplierSelect);
    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  render(){

    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }

    return (
      <div>
        <b>Bidder</b>
        <Select
          onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
          options={this.mobxState.supplierSelect}
          closeMenuOnSelect={true}
          maxMenuHeight = {400}
          placeholder={'Bidder to apply rule to'}
        />
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(SupplierIdSelection, {
	mobxState: observable
})

export default observer(SupplierIdSelection);

// export default SupplierIdSelection
