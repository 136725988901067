import React from 'react';

//http
import axios from 'axios';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//confirm modal
import { confirmAlert } from 'react-confirm-alert';

class CloneScenarioRenderer extends React.Component {
  project_id = this.props.data.project_id;
  scenario_id = this.props.data.id;

  mobxState = {
    newScenarioName: null,
    newScenarioId: null,
    scenarioCreated: false
  };

  handleClone = async () => {
    let paramsToSend = { clone_into_project_id: this.project_id, scenario_id: this.scenario_id };
    await axios.post(`/v1/projects/${this.project_id}/scenarios/clone_new`, paramsToSend)
    .then(result => {
      console.log('SCENARIO CLONED ', result);
      this.props.agGridReact.props.fetchData();
    });
  };

  render() {
    return (
      <div>
        <a className="link_style">
          <FontAwesomeIcon
            icon={['fal', 'copy']}
            size='2x'
            style={{ marginLeft: '15%', marginTop: '8px' }}
            onClick={this.handleClone}
            title={'Copy this scenario'}
          />
        </a>
      </div>
    );
  }
}

decorate(CloneScenarioRenderer, {
  mobxState: observable
});

export default observer(CloneScenarioRenderer);
