import React from 'react';
import { withRouter } from "react-router-dom";


//ag grid
import ImportJobsRenderer from '../../../../grids/ImportJobsRenderer';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//notifications
import Notification from '../../../../../components/notifications/StandardNotificationRenderer';


//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class ImportJobs extends React.Component {
  mobxState = {
    dockOptions: {
      isVisible: false,
      size: 0.2
    }
  };
  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.notificationRef = React.createRef();
  }


  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };

  render() {

    return (
      <div>

        <div className='jumbotron jumbotron_full_width'>
          <div className='jumbotron_full_width_container'>
            <span className="fa-layers fa-fw fa-2x">
              <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#009C9C" style={{ marginTop: '4px' }} />
              <FontAwesomeIcon icon={['fal', 'cloud-upload']} color="white" transform="shrink-5" style={{ marginTop: '3px' }} />
            </span>
            &nbsp;
            <b className='dash_header'>Import Jobs</b>
          </div>
        </div>

        {/* PROJECT GRID */}
        <div className="row">
          <div className="col-lg-12">
            {/* AG Grid */}
            <ImportJobsRenderer />
          </div>
        </div>{/* END CHART ROW */}
        <Notification ref={this.notificationRef} />

      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ImportJobs, {
  mobxState: observable
});

export default withRouter(observer(ImportJobs));
