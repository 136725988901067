import React from 'react';
import PropTypes from 'prop-types';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

import SpinLoader from '../../loaders/SpinLoader';
import AsyncBadge from '../../badges/AsyncBadge';

//filters
import FilterQuickAction from '../../filters/FilterQuickAction'; //filter controls

//utility
import { zeroNull, deleteIfEmpty } from '../../services/Utilities';

//Renders the custom charts based on API call
class CustomBadgeRenderer extends React.Component {
  mobxState = {
    customCharts: []
  };

  //!!!BUBBLE CHART NOT SET UP YET - UNTESTED
  render() {
    const customCharts = [];

    for (const [index, value] of this.props.chartRenderData.entries()) {
      if (value.chart_type === 'badge') {
        customCharts.push(
          <div key={'badge_' + index}>
            <AsyncBadge
              badgeNumber={zeroNull(value.chart_data && value.chart_data[0] && value.chart_data[0]['y']).toLocaleString(
                'en-US'
              )}
              badgeName={value.chart_title}
              faClass={value.header_style}
              loading={value.loading}
            />
          </div>
        );
      }
    }
    return (
      <div id='badge-section' className='badge-container' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        {customCharts}
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(CustomBadgeRenderer, {
  mobxState: observable
});

export default observer(CustomBadgeRenderer);
