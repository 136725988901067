import React from "react"
import { render } from 'react-dom'

//other components built
import Badge from "../../../badges/Badge"
import GridColumn from "../../../services/GridColumn" //builds ag-grid columns
import UrlSniffer from "../../../services/UrlSniffer" //work with rails paths to get project token

//filters
import DockRenderer from "../../../filters/DockRenderer"
import FilterQuickAction from "../../../filters/FilterQuickAction" //filter controls

//highcharts
import BarChart from "../../../charts/basics/BarChart"
import PieChart from "../../../charts/basics/PieChart"
import LineChart from "../../../charts/basics/LineChart"

//percentage donut
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from "../../../grids/GridRenderer"

//http requests
import axios from 'axios'

//state
import { decorate, observable, computed, action } from "mobx"
import { observer } from "mobx-react"

//utility
import {zeroNull} from "../../../services/Utilities"
// import FontAwesome from 'react-fontawesome'
// import faStyles from 'font-awesome/css/font-awesome.css'

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();


//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class Overview extends React.Component {

			customOptions = [];
			cardData = {
				"contractValue": 0,
				"rfpCount": 0,
				"rfiCount": 0,
				"projectCount": 0,
				"contractCount": 0,
				"rfiYesPercent": 0,
				"rfpYesPercent": 0,
				"auctionYesPercent": 0,
				"supplierContractSum": [], //charts
				"on_contract_count": [], //charts
				"dbe_count": [], //charts
				"cat_1_top_sum": [], //charts
				"year_month_sum_line": [],
			};
			tableData = {
				"contracts": [],
				"projects": []
			}
			selectedModels = {
			}; //multi select data model for http
			selectedOptions = {
			}; //multi select data model in filter value/label format
			dockOptions = {
				isVisible: false,
				size: 0.20
			}; //sidebar defaults
			pivotData = [];
			pivotState = [];
			pivotFilterContracts = [];
			pivotFilterProjects = [];
			filterModelNames = ['Cat_ID', 'Cat_Desc', 'Con_Title', 'Cat1', 'Cat2', 'Cat3', 'Cat4']; //autobuilds filters and clear functions

		//mount data through http requests
		componentDidMount() {
			//call the data loader to load data into the view
			this.fetchData();
		}

		//////////////////HTTP/////////////////
		//data loader
		fetchData = () => {

			var table_rows = 10000;

			//temp custom options holder since project fields are all different
			this.customOptions =
			{"ID":"Row ID", "Cat_ID":"Project ID", "Cat_Desc":"Project Desc", "DateCreated":"Created Date", "URL_Addr":"URL", "Ref_XDate":"Ref Date",
			"proj_desc":"Desc 2", "Annual_Period":"Year", "MostRecent_Period":"Most Recent Year", "RFI_Scoring":"RFI Scoring", "Pay_Term":"Pmt Term",
			"Shipment_qty":"Ship Qty", "RFI_End_Time":"RFI End", "RFP_End_Time":"RFP End", "Base_Currency":"Currency", "Mfg_Name":"Mfg", "volume":"Volume",
			"LastMod_Date":"Last Modified", "Proj_Type":"Type", "ProjGrp":"Group", "RoundNo":"Round",
			"MultiRound":"Multi Round", "Supp_No":"Supp No", "supplier":"Supplier",
			"year": "Exp. Year", "month": "Exp. Month", "Cat1":"Category 1", "Cat2":"Category 2",
			"Cat3":"Category 3", "Cat4":"Category 4", "Con_Title":"Contract", "Supp_No":"Supplier #", "Status_Name":"Status", "con_value":"Contract Value",
			"Exp_Date":"Expires", "Effect_Date":"Effective Date", "LastMod_Date":"Last Modified", "Con_File":"Filename"};
			//merge the filter model with the amount of rows selected
			let models_with_rows = {...this.selectedModels, ...{"table_rows": table_rows}};

			//set up the cardData
			axios.get("/microservices/v1/analytics/executive_overview", { params: models_with_rows })
			.then(response => {
				this.cardData = response.data;
			});

			//set up the tableData
			axios.get("/microservices/v1/analytics/executive_overview_data", { params: models_with_rows })
			.then(response => {
				// this.cardData.project_data = response.data.projects;
				// this.cardData.contract_data = response.data.contracts;
				this.tableData = response.data;

				//set the column definitions for ag grid, look into buildColumnDefs class to see options
				this.columnDefsProjects = gridColumns.buildColumnDefs({"customOptions": this.customOptions, "columnArray": ['executive_overview_projects'], "projectType":0, "isScenario":0})
				this.columnDefsContracts = gridColumns.buildColumnDefs({"customOptions": this.customOptions, "columnArray": ['executive_overview_contracts'], "projectType":0, "isScenario":0})
				// //render the filter dropdown choices for specific page id
				// axios.get("/v1/resources/index_filter", {params: {"page_id": 3} })
				// .then((response) => {
				// 	this.pivotFilterProjects = response.data.resources;
				// });
				// //render the filter dropdown choices for specific page id
				// axios.get("/v1/resources/index_filter", {params: {"page_id": 4} })
				// .then((response) => {
				// 	this.pivotFilterContracts = response.data.resources;
				// });

			});

		}

		// deleteRequest = (toSendArr) => {
		// 	//work with rails URL to get project token
		// 	let projectToken = urlSniffer.getProjectToken();

	 //        axios.post('/projects/' + projectToken + '/optimization_data/batch_destroy.json', {"id_set": toSendArr})
	 //        .then((response) => {
		// 		console.log(response);
		// 		this.fetchData();
	 //        });
		// }
		//////////////////END HTTP/////////////////

	    //handles dropdown and chart filters
		handleFilter = (filterParam, selectedFilter) => {
			console.log('Handle Filter Callback: ');
			console.log('selectedFilter ', selectedFilter); console.log('filterParam ', filterParam);
			//in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
			this.selectedModels[filterParam] = selectedFilter.map(a => a.value);
			//also store the selections in the original form. This goes back to the filter as selected values
			this.selectedOptions[filterParam] = selectedFilter;
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

	    //handles dropdown and chart filters
		handleClear = () => {
			//in the state, clear the model. This goes as params to http request
			this.selectedModels = {};
			//also store the explicit cleared selections in the original form. This goes back to the filter as selected values
			//loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
			//from ["supplier", "item"] to {"supplier":[], "item":[]}
			const optionsClear = {};
			for (const key of this.filterModelNames) { optionsClear[key] = [];}
			this.selectedOptions = optionsClear;
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

		//for sidebar dock to change sizes
		handleSizeChange = size => {
			this.dockOptions.size = size;
		}

		//sidebar dock toggle
		toggleDock = () => {
			this.dockOptions.isVisible = !this.dockOptions.isVisible
		}


		render() {

			function CustomContentProgressbar(props) {
				const { children, ...otherProps } = props;

				return (
					<div
						style={{
							position: 'relative',
							width: '100%',
							height: '100%',
						}}
					>
						<div style={{ position: 'absolute' }}>
							<CircularProgressbar
								{...otherProps}
								strokeWidth={5}
								background
								backgroundPadding={0}
								styles={{
									path: { stroke: `#2196F3`,  strokeLinecap: 'butt', transition: 'stroke-dashoffset 1s ease 0s', },
									text: { fill: 'black', fontSize: '14px' },
									background: {fill: 'white'}
								}}
							/>
						</div>
						<div
							style={{
								position: 'absolute',
								height: '100%',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{props.children}
						</div>
					</div>
				);
			}

			const hcHeight = '350px';

			return (

				<div>

					{/* Small Left Toggle */}
					<div onClick={this.toggleDock}  className="sidebar_button filter_toggle"><i className="glyphicon glyphicon-chevron-left"></i></div>

					<div className="jumbotron jumbotron_full_width">
						<div className="jumbotron_full_width_container">
							<b className="dash_header">Executive Overview</b>
							<br />
							<br />
							<div className="row">
								<div className="col-lg-6">
									<div className="panel panel-default">
										<div className="panel-body">
											<div className="col-sm-4">
												<center>
													<div style={{width: '127px', height: '127px'}}>
														<CustomContentProgressbar percentage={this.cardData.rfiYesPercent}>
															<div style={{ fontSize: '2em' }} ><strong>{this.cardData.rfiYesPercent} %</strong></div>
															<div>RFI</div>
														</CustomContentProgressbar>
													</div>
												</center>
											</div>
											<div className="col-sm-4">
												<center>
													<div style={{width: '127px', height: '127px'}}>
														<CustomContentProgressbar percentage={this.cardData.rfpYesPercent}>
															<div style={{ fontSize: '2em' }} ><strong>{this.cardData.rfpYesPercent} %</strong></div>
															<div>RFP</div>
														</CustomContentProgressbar>
													</div>
												</center>
											</div>
											<div className="col-sm-4">
												<center>
													<div style={{width: '127px', height: '127px'}}>
														<CustomContentProgressbar percentage={this.cardData.auctionYesPercent}>
															<div style={{ fontSize: '2em' }} ><strong>{this.cardData.auctionYesPercent} %</strong></div>
															<div>Auction</div>
														</CustomContentProgressbar>
													</div>
												</center>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-6">

									{/* BADGES */}
									<div className="row">
										<div className="col-lg-6">
											<Badge
												badgeNumber={zeroNull(this.cardData.projectCount).toLocaleString('en-US')} //.toLocaleString('en-US')
												badgeName={'Projects'}
												faClass={'check-circle'}
											/>
										</div>
										<div className="col-lg-6">
											<Badge
												badgeNumber={zeroNull(this.cardData.contractCount).toLocaleString('en-US')}
												badgeName={'Contracts'}
												faClass={'briefcase'}
											/>
										</div>
									</div>
									<br />
								  <div className="row">
										<div className="col-lg-6">
											<Badge
												badgeNumber={zeroNull(this.cardData.rfpCount).toLocaleString('en-US')}
												badgeName={'RFP Projects'}
												faClass={'square-list'}
											/>
										</div>
										<div className="col-lg-6">
											<Badge
												badgeNumber={zeroNull(this.cardData.contractValue).toLocaleString('en-US')}
												badgeName={'Contracted Spend'}
												faClass={'square-list'}
											/>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>

						{/* SUMMARY CHARTS */}
				    <div className="row">
          		<div className="col-lg-8">
								<div className="panel panel-default">
							  	<div className="panel-heading panel_bg">
							  		<b>Category Summary </b>
							  		<span className="pull-right">
							  			<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
								  	</span>
							  	</div>
							  	<div className="panel-body">
										<div className="col-md-12">
											<div className="col-md-6">
												<PieChart
													renderInDivId={'supplier_pie'}
													handleFilterFunction={this.handleFilter}
													pieData={this.cardData.contractCategoryCount}
													pieTitle={'Top 10 ' + this.customOptions.Cat1 + 's By Contract Count'}
													height={hcHeight}
													filterParam={'Cat1'}
												/>
											</div>
											<div className="col-md-6">
												<PieChart
													renderInDivId={'group_1_pie'}
													handleFilterFunction={this.handleFilter}
													pieData={this.cardData.projectCategoryCount}
													pieTitle={'Top 10 ' + this.customOptions.Cat1 + 's By Project Count'}
													height={hcHeight}
													filterParam={'Cat1'}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
          		<div className="col-lg-4">
								<div className="panel panel-default">
							  	<div className="panel-heading panel_bg">
							  		<b>Contract Value Summary</b>
							  		<span className="pull-right">
											<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
								  	</span>
							  	</div>
							  	<div className="panel-body">
										<div className="col-md-12">
											<BarChart
												renderInDivId={'contract_value_bar'}
												handleFilterFunction={this.handleFilter}
												barData={this.cardData.contractValueSum}
												barTitle={'Most Expensive Contracts'}
												height={hcHeight}
												filterParam={'Con_Title'}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>{/* END CHART ROW */}

						<br />

						<div className="row">
							<div className="col-lg-4">
								<div className="panel panel-default">
										<div className="panel-heading panel_bg">
											<b>Largest Projects</b>
											<span className="pull-right">
												<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
											</span>
										</div>
										<div className="panel-body">
										<div className="col-md-12">
											<BarChart
												renderInDivId={'project_item_bar'}
												handleFilterFunction={this.handleFilter}
												barData={this.cardData.projectItemCount}
												barTitle={'# of Items By Project'}
												height={'350px'}
												filterParam={'Cat_Desc'}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-8">
								{/* AG Grid */}
								<GridRenderer
									gridHeight={"330px"}
									columnDefs={this.columnDefsProjects}
									rowData={this.tableData.projects}

									fetchData={this.fetchData}
									handleClear={this.handleClear}
									toggleDock={this.toggleDock}
									pageId={3}

									savedState={this.pivotState}
									isPivotMode={true}
									gridTitle={"Project Details"}

									setClick={click => this.clickChild = click}

									deleteRequest={false}
									idColumn={"Cat_ID"}

									modelsToUpdateArr={[{url:"v1/ref_categories/", idName:"Cat_ID"}]}
									singleClickEdit={true}
									suppressClickEdit={false}

									isSaveable={false}
									isAwardable={false}
									isDeletable={false}
								/>
							</div>
						</div>{/* END CHART ROW */}

					<br />

					{/* CONTRACT CHARTS */}
				    <div className="row">
							<div className="col-lg-4">
								<div className="panel panel-default">
										<div className="panel-heading panel_bg">
											<b>Top {this.customOptions.supplier_name}s</b>
											<span className="pull-right">
											<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
											</span>
										</div>
										<div className="panel-body">
										<div className="col-md-12">
											<BarChart
												renderInDivId={'contract_supplier_bar'}
												handleFilterFunction={this.handleFilter}
												barData={this.cardData.supplierContractSum}
												barTitle={'Suppliers By Contract Value'}
												height={'350px'}
												filterParam={'Supp_No'}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-8">
								{/* AG Grid */}
								<GridRenderer
									gridHeight={"330px"}
									columnDefs={this.columnDefsContracts}
									rowData={this.tableData.contracts}

									fetchData={this.fetchData}
									handleClear={this.handleClear}
									toggleDock={this.toggleDock}
									pageId={4}

									savedState={this.pivotState}
									isPivotMode={true}
									gridTitle={"Contract Details"}

									setClick={click => this.clickChild = click}

									deleteRequest={false}
									idColumn={"Con_ID"}

									modelsToUpdateArr={[{url:"v1/cm_contracts1/", idName:"Con_ID"}]}
									singleClickEdit={true}
									suppressClickEdit={false}

									isSaveable={false}
									isAwardable={false}
									isDeletable={false}
								/>
							</div>
						</div>{/* END CHART ROW */}

					<br />
					{/* Sidebar */}
					<DockRenderer
						position={'right'}
						size={this.dockOptions.size}
						dimMode={'none'}
						isVisible={this.dockOptions.isVisible}
						onVisibleChange={this.handleVisibleChange}
						onSizeChange={this.handleSizeChange}
						fluid={true}

						toggleDock={this.toggleDock}
						handleClear={this.handleClear}

						value={this.selectedOptions}
						options={this.cardData}
						closeMenuOnSelect={true}
						maxMenuHeight = {400}
						handleFilter={this.handleFilter}
						customOptions={this.customOptions}
						filterModelNames={this.filterModelNames}
					/>

				</div>
			);
		}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Overview, {
	customOptions: observable,
	cardData: observable,
	tableData: observable, //all the data rows
	selectedModels: observable, //multi select data model for http
	selectedOptions: observable, //multi select data model in filter value/label format
	dockOptions: observable, //sidebar defaults
	pivotData: observable,
	pivotState: observable,
	pivotFilter: observable,
	filterModelNames: observable //autobuilds filters and clear functions
})

export default observer(Overview);
//
// export default Overview;
