//This form is used to import Excel and CSV files.
//This can be re-factored to submit to various tables in the database by changing props and a few values in the validation function

import React from 'react';

//state
import { decorate, observable, computed, action, toJS } from 'mobx';
import { observer } from 'mobx-react';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//progress bar and table from bootstrap
import { ProgressBar, Table } from 'react-bootstrap';

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

//excel and csv parsing into JSON object
import XLSX from 'xlsx';

//React Select
import Select from 'react-select';

//http requests
import axios from 'axios';

//antd
import { Input, Button } from 'antd';

//lodash
import _, { includes, flatten, flatMap, filter, indexOf, truncate, isUndefined, merge, upperFirst } from 'lodash';
//import { Column } from 'ag-grid-community'; //removed by Ian when doing QA, not sure why you would need this here, it's not used?
import { func, object, node } from 'prop-types';
import { concatSeries, eachLimit } from 'async';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { uriComponentInSingleQuotedAttr } from 'xss-filters';

//build template spreadsheet to download
import ImportHandler from '../FileImportation/ImportTemplate/ImportHandler'
import { covertAliasToField, convertToSystemAliases, getAliasList } from '../FileImportation/ImportTemplate/ImportAlias'

class SpreadSheetImport extends React.Component {

  componentDidMount() {
    console.log('PRE convertToAlias');
    this.convertToAlias();
  }
  //get custom_fields data
  componentDidUpdate() {
    //grab scope list for this project id if it is a scope
    if(this.props.isConstraint === true){
      this.getScopes();
      this.getSupplierIds();
      this.getFreightBrackets();
      this.getProjectSchema();
    }
    if(this.props.tableName === 'items'){
      this.getItemCustomFields();
    }
    console.log("PROPS ", this.props)
    console.log(this.mobxState.aliasedRequiredCols)
  }

  componentWillUnmount(){
    this.resetFormState();
  }
  /*required columns for this particular spreadsheet and table, is used for form validation and db submission,
   values will change depending on what table  the form submits to */
  requiredCols = this.props.requiredCols;

  //props

  //name of table to submit to
  //name of table for determining what methods to use and what endpoint to use
  tableName = this.props.tableName;

  //project ID
  project_id = parseInt(this.props.projectId);

  //create project_id to insert into rows
  project_id_obj = { project_id: this.project_id}; //project_id_obj is inserted on the backend for bids

  //holds scenrioId if importing a constraint
  scenarioId = this.props.scenarioId;

  //create scenario_id to put into rows
  scenario_id_obj = { scenario_id: this.scenarioId };

  //used to determine if scenario_id should be inserted (used on constraint import)
  isConstraint = this.props.isConstraint

  //cols that arent required and arent custom aliases
  optionalCols = this.props.optionalCols


  //shows steps on progress bar, caption changes based on current step
  progressCaption = 'STEP 1/3';

  //state
  mobxState = {
    importForm: {
      //controls progress bar and next steps
      isLoading: true,
      stepProgress: 33,
      step1: true,
      step2: false,
      step3: false,
      step4: false, //final step in import
      importValid: true, //used to make sure the required columns are in the form before moving on the next step
      missingValues: [] //if header is missing this will display in the table showing whats missing and needs to be added,
    },
    formData: {
      //data from inside the uploaded files
      formPresent: false, //keeps list from rendering before state has values
      parsedForm: null, //body of form separate from headers
      fileName: null, //name of file from fileList array
      selectedMissingValue: { label: null, value: null },
      sheetNames: null, //names of sheets to select if multiple sheets are in the file
      selectedSheetIndex: 0, //sheet selected from sheet default is zero, first form or only form
      workbook: null,
      selectedSheetName: null,
      selectedWorkSheet: null,
      sheetSelected: false, //boolean shows a sheet has been selected
      selectedSheet: null, //currently selected worksheet
      noRows: true, //used to see if table should render preview rows
      foundMissing: [], //header(s) that shouldn't be in the form
      isCSV: false, //if it is a CSV file skip sheet selection
      fileType: null, //type of file being imported
      selectOptions: [], //options for excel sheet select
      defaultSheet: null, //first sheet option by default
      isNull: false,
      formRows: [], //holds form data to make apis calls with
      previewBody: null,
      smallerForm: null,
      previewForm: [], //shows first 5 rows as preview before submit
      lowerRequired: null,
    },
    importedRequiredHeaders: null, //headers on form that match required
    nonRequiredHeaders: [],
    missingValueSelection: [], //holds missing values for select menu
    //data fetched from database for use to compare
    importHeaders: null, //header names from imported form
    unknownHeaders: [], //headers that are not valid
    //lists from db
    //holds data to get freight brackets to swap names with id's
    freightBracketData: {
        freight_bracket_list: null,
    },
    // holds name of scope field_name and field_alias
    scopeData:{
      scope_list: null
    },
    // project schema
    projectFieldSchema: {
    },
    //holds data to get supplier_id's from  the names
    supplierData:{
      supplier_list: null, //holds list of supplier names and ids to compare against
    },
    //holds freight bid custom_fields data to compare against
    freightBidData:{
      freight_custom_fields_list: null
    },
    //holds item_custom_fields data to compare against
    itemData: {
      item_custom_fields_list: null
    },
    fullForm: null, //completed form ready to be submitted
    submitStatus: true, //holds value of http response status code
    nodeForm: null, //this is the complete form that gets sent to the backend to be parsed and submitted for bids
    submitText: null, //holds error message to display from backend
    submissionError: false, //for displaying error message on bad form submission
    isParsing: false, //used for displaying feedback to user while large a large file is parsing
    unusedCols: [], //holds ignored values to add them to the dropdown list
    aliasedRequiredCols: null, //required columns converted to field_aliases
    aliasList: null, // list of aliases for table so they can be highlighted
  };
  //convert any header that is using a system_alias back into a system_field
  convertToAlias = async () => {
    const aliasTableName = this.props.tableName.slice(0,this.props.tableName.length -1); //remove letter "s" at the end
    console.log('PRE ALIAS LIST');
    this.mobxState.aliasList = await getAliasList(aliasTableName) //get list so aliases can be highlighted
    console.log('aliasList result', this.mobxState.aliasList);
    let aliasList = await convertToSystemAliases(aliasTableName,this.props.requiredCols, true)
    this.mobxState.aliasedRequiredCols = aliasList
  }
  //convert bytes to Mb for readability
  mbSize(input){
    let mb = Math.round((input / 1024)) + "Mb"
    return mb
  }

  //clear state of current form data and go back to Step 1
  resetFormState = () => {
    this.mobxState.importForm.stepProgress = 33;
    this.mobxState.importForm.step1 = true;
    this.mobxState.importForm.step2 = false;
    this.mobxState.importForm.step4 = false;
    this.mobxState.importForm.importValid = false;
    this.mobxState.importForm.missingValues = [];

    this.mobxState.formData.formPresent = false;
    this.mobxState.formData.parsedForm = null;
    this.mobxState.formData.fileName = null;
    this.mobxState.formData.selectedMissingValue = { label: null, value: null };
    this.mobxState.formData.sheetNames = null;
    this.mobxState.formData.selectedSheetIndex = null;
    this.mobxState.formData.workbook = null;
    this.mobxState.formData.selectedSheetName = null;
    this.mobxState.formData.selectedWorkSheet = null;
    this.mobxState.formData.sheetSelected = null;
    this.mobxState.formData.noRows = true;
    this.mobxState.formData.foundMissing = [];
    this.mobxState.formData.isCSV = false;
    this.mobxState.formData.fileType = null;
    this.mobxState.formData.selectOptions = [];
    this.mobxState.formData.defaultSheet = null;
    this.mobxState.formData.isNull = false;
    this.mobxState.formData.previewBody = null;
    this.mobxState.formData.previewForm = [];
    this.mobxState.formData.lowerRequired = [];

    this.mobxState.scopeData.scope_list = null;

    this.mobxState.importedRequiredHeaders = null;
    this.mobxState.nonRequiredHeaders = [];
    this.mobxState.missingValueSelection = [];


    this.mobxState.importHeaders = null;
    this.mobxState.unknownHeaders = [];
    this.mobxState.freightBracketData.freight_bracket_list = null;

    this.mobxState.itemData.item_custom_fields_list = null;
    this.mobxState.freightBidData.freight_custom_fields_list = null;
    this.mobxState.fullForm = null;
    this.mobxState.submitStatus = true;
    this.mobxState.nodeForm = null;
    this.mobxState.submitText = null;
    this.mobxState.submissionError = false;
    this.mobxState.isParsing = false;
    this.mobxState.unusedCols = [];

    this.mobxState.isEditing = false;
    this.progressCaption = 'Step 1/3';

    // this.mobxState.aliasedRequiredCols = null;

    // console.log("reset state ", this.mobxState);
  };

  //for going back to step 2
  partialFormReset = () => {
    this.mobxState.missingValueSelection = []; //if this isnt cleared irrelevent options can appear
    this.mobxState.unusedCols = []; //if this isnt cleared irrelevent options can appear
    this.mobxState.formData.isCSV = false;
    this.mobxState.formData.defaultSheet = false;
    this.mobxState.formData.isNull = false;
    this.mobxState.formData.selectedWorkSheet = null;
    this.mobxState.formData.selectedSheetName = null;
    this.mobxState.formData.sheetNames = null;
  };

  //keep files larger than 5mb from being submitted
  checkFileSize = e => {
    var files = e.target.files,
      file;
    if (!files || files.length === 0) return;
    file = files[0];
    if(_.round(file.size / (1024 * 1024), 2) >  5){
      console.log("File is too large")
      // alert("File is too large (5mb limit)")
      confirmAlert({
        message: 'File is too large (5mb limit)',
        buttons: [ { label: 'Ok', }]
      });
      this.resetFormState()
      e.target.value = null
      return
    } else {
      this.handleFileImport(e)
    }
  }


  //handles file input for Step 1 where the user imports a form
  //Uses XLSX libray to convert spreadsheet
  handleFileImport = e => {
    this.mobxState.isParsing = true;
    var files = e.target.files,
      file;
    if (!files || files.length === 0) return;
    file = files[0];
    console.log("FILE SIZE ", _.round(file.size / (1024 * 1024), 4), " Mb" )
    if (file.type === 'text/csv') {
      this.mobxState.importForm.isCSV = true;
      this.mobxState.importForm.fileType = file.type;
    } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.mobxState.importForm.isCSV = false;
      this.mobxState.importForm.fileType = file.type;
    } else {
      this.mobxState.importForm.isCSV = false;
      this.mobxState.importForm.fileType = file.type;
    }
    var fileReader = new FileReader();
     fileReader.onload = e => {
      this.mobxState.isParsing = true;

      var filename = file.name;
      // call 'xlsx' to read the file
      var binary = '';
      var bytes = new Uint8Array(e.target.result);
      var length = bytes.byteLength;
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      //access object created by XLSX
      var workbook = XLSX.read(binary, { type: 'binary', cellDates: true, cellStyles: true });
      // console.log(workbook)

      this.mobxState.formData.sheetNames = workbook.SheetNames;
      //create default sheet
      // create dropdown menu options
      Object.entries(workbook.SheetNames).map(([key, value]) => {
        // console.log(key, value);
        if (workbook.SheetNames.length === 1) {
          console.log('single sheet');
        }
        this.mobxState.formData.selectOptions.push({ value: key, label: value });
        // console.log(this.mobxState.formData.selectOptions[0])
      });
      let defaultValue = {
        value: this.mobxState.formData.selectOptions[0].value,
        label: this.mobxState.formData.selectOptions[0].label
      };
      this.mobxState.formData.defaultSheet = defaultValue;
      // console.log(this.mobxState.formData.defaultSheet);
      this.mobxState.formData.workbook = workbook;
      // console.log(this.mobxState.formData.selectOptions)
      this.mobxState.formData.sheetSelected = false;
      this.mobxState.formData.formPresent = true;
      let Sheets = workbook.Sheets;
      //get the state to move on to the next step
      this.mobxState.formData.fileName = file.name; //name of file to display
      this.mobxState.importForm.stepProgress = 66; //moves progress bar
      this.progressCaption = 'Step 2/3';
      this.mobxState.importForm.formDirections = false;
      this.mobxState.importForm.step1 = false;
      this.mobxState.importForm.step2 = true; //sets state to move to second step
    };
    fileReader.readAsArrayBuffer(file);
  };
  // XLSX Library  for consverting spreadsheets uses PascalCase typing
  parseForm = () => {
    let worksheet = this.mobxState.formData.selectedWorkSheet;
    // console.log(this.mobxState.formData.selectedWorkSheet);
    // console.log(worksheet)
    let parsedForm = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false, defval: '' });
    //take the parsed data and put it into state
    // console.log("Parsed Form",parsedForm);
    let importHeaders = parsedForm[0];
    this.mobxState.importHeaders = importHeaders;
    // this.mobxState.formData.parsedFormHeaders = parsedForm[0];
    this.mobxState.formData.parsedForm = parsedForm.slice(1);
    let previewForm =  this.mobxState.formData.parsedForm.slice(0,4); //generate sample of top rows for preview
    this.mobxState.formData.previewForm = previewForm
    console.log("Preview Form", previewForm)
    this.mobxState.formData.parsedForm !== null || undefined
      ? (this.mobxState.formData.noRows = false)
      : (this.mobxState.formData.noRows = true);
  };

  //only first five rows are parsed so user can preview and work on headers, form get processed on backend
  //parses bids and freight bids pages, majority of processing is done on the backend
  bidFormParse = async function bidFormParse(...cols) {
    console.time("PARSE");
    //convert aliases to system_fields
    console.log(Array.from(this.mobxState.aliasedRequiredCols))
    let required = [...this.requiredCols, Array.from(this.mobxState.aliasedRequiredCols)];
    let headers = Array.from(this.mobxState.importHeaders);
    let aliasTableName = this.props.tableName.slice(0,this.props.tableName.length -1) //remove "s"
    let unAliasedHeaders = await covertAliasToField(aliasTableName, headers)
    let aliasList = await convertToSystemAliases(aliasTableName, headers)
    console.log("LIST ", aliasList)
    let optionalCols = cols //taken from argument "...cols" which comes from the "optionalCols" props
    // console.log('optionalCol ', optionalCols);
    let lowerRequired = []; //lower case required columns sorted
    let nonRequired = []; // columns that are not required the required

    //add optional header that isn't a required column or a custom_field alias
    console.log(required);
    console.log(lowerRequired);

    //Case insensitive header check
   headers.forEach((value) => {
      for (let reqCol of required) {
        if (_.isEqual(value, reqCol)) {
          lowerRequired.push(_.lowerCase(value));
          // headers.pop(value)
        } else if (_.lowerCase(value) === reqCol) {
          lowerRequired.push(_.lowerCase(value));
        }
      }
    });
    // lowerRequired.push('item custom field', 'price')
    //get all other values that are not a custom field
    function getNonRequired(arr1, arr2) {
      let difference = _.difference(arr1, arr2);
      // console.log(difference);
      nonRequired = [...difference];
      return nonRequired.flat();
    }
    console.log(headers);
    console.log(required);
    console.log(lowerRequired);

    getNonRequired(headers, lowerRequired);

    let parsedFormBody = this.mobxState.formData.parsedForm;
    console.log("PPPPPP ", parsedFormBody)
    const round = /\bround\b/
    //add optional column if it's there and replace any single space(s) with an underscore(_)
    for(let header of headers) {
      let formattedHeader = _.lowerCase(header).replace(/ /g, '_'); //allows for case and space insensitive matching
      for(let i = 0; i < optionalCols.length; i++){
        // console.log("formatted ", optionalCols.length)
        // console.log(formattedHeader, optionalCols[i])
        // console.log(/round$/.test(formattedHeader), formattedHeader)
        if(formattedHeader === optionalCols[i]){
          // console.log("MATCH ",formattedHeader, optionalCols[i])
          required.push(formattedHeader);
          // console.log(required);
          // console.log(headers);
          _.pull(nonRequired, formattedHeader);
        } else if(formattedHeader.includes("baseline_price")) {
          required.push("baseline_price");
          _.pull(nonRequired, formattedHeader);
          console.log(required)
        } else if(formattedHeader.includes("quantity_supplied")) {
          required.push("quantity_supplied");
          _.pull(nonRequired, formattedHeader);
          console.log(required)
        }
        else if(formattedHeader.match(round)) {
          console.log(formattedHeader.match(round), formattedHeader)
          console.log("INCLUES ROUND ", formattedHeader)
          required.push(_.lowerCase(formattedHeader));
          _.pull(nonRequired, formattedHeader);
          console.log(required)
        }
      }
    }
    //swap headers that are correct but contain extra data e.g "Bid Round" -> "Round"
    // Object.entries(headers).map(([key, header]) => {
    //   let formattedHeader = _.lowerCase(header).replace(/ /g, '_'); //allows for case and space insensitive matching
    //   // console.log(key, formattedHeader)
    //   if(formattedHeader.includes("bid") && formattedHeader.includes("_round")){
    //     /^round$/.test(formattedHeader)
    //     ? console.log("second round check ", formattedHeader) :
    //     headers.splice(key, 1, "round")
    //   }
    // })
    // console.log(headers)
    // console.log(unAliasedHeaders, lowerRequired)
    this.mobxState.formData.lowerRequired = [...lowerRequired]


    // console.log("BODY!!!!!!!!!!!!!!! ", parsedFormBody)
    let previewForm = Array.from(this.mobxState.formData.previewForm)
    console.log(previewForm);
    let movedBody = []; // body where column values are moved to match moved header(s)
    let rowKeys = []; //number of rows in the form

    Object.keys(parsedFormBody).forEach(key => rowKeys.push(parseInt(key))); //create keys for rows

    let rowLength = parsedFormBody[0].length; //length of columns in the form body

    //create key/value pairs with headers and form values
    Object.values(previewForm).forEach((parsedValue) => {
      Object.entries(headers).map(([key, value]) => {
        let lowerCaseValue = _.lowerCase(value).replace(/ /g, '_');
        //  console.log(lowerCaseValue);
        if (this.props.systemFieldCols.includes(lowerCaseValue)) {
          //case insensitive check for required columns
          movedBody.push([lowerCaseValue, parsedValue[key]]);
        } else {
          movedBody.push([value, parsedValue[key]]);
        }
      });
    });
    this.mobxState.formData.formHeaders = headers;
    let lowerHeaders = headers.map(i => _.lowerCase(i).replace(/ /g, '_'))
    parsedFormBody.unshift(lowerHeaders)
    // console.log(parsedFormBody)

    //constructs a sample of the spreadsheets first 5 rows to preview and work on headers
    let previewBody = []; //form with key value pairs added to values
    let rowNum = -1; //indexes for rows
    for (let i = 0; i < movedBody.length; i += rowLength) {
      let eachRow = movedBody.slice(i, i + rowLength);
      rowNum++;
      // console.log(rows)
      // console.log(rowNum, Object.fromEntries(eachRow));
      previewBody.push(eachRow);
    }
    this.mobxState.nodeForm  = parsedFormBody //copy of full body to submit to backend
    console.log("NODEFORM~!!!!!!!!!!!! ", parsedFormBody)
  }
  //end of BidFormParse

  //for parsing items
  itemFormParse = async function itemFormParse(...cols) {
    //headerFix param is for prasing after being fixed
    console.time()
    console.log(Array.from(this.mobxState.aliasedRequiredCols))
    let required = [...this.requiredCols, ...Array.from(this.mobxState.aliasedRequiredCols)];
    let headers = Array.from(this.mobxState.importHeaders);
    let unAliasedHeaders = await covertAliasToField('item', headers)
    let optionalCols = cols //taken from argument "...cols" which comes from the "optionalCols" props
    console.log('optionals ', optionalCols );
    console.log("HHHH", required, headers)
    let lowerRequired = []; //lower case required columns sorted
    let nonRequired = []; // columns that are not required the required

    //Case insensitive header check
    Object.entries(headers).map(([key, value]) => {
      for (let reqCol of required) {
        // console.log(reqCol, key, value)
        if (_.isEqual(value, reqCol)) {
          lowerRequired.push(_.lowerCase(value));
          // headers.pop(value)
        } else if (_.lowerCase(value) === reqCol) {
          lowerRequired.push(_.lowerCase(value));
        }
      }
    });

    //get all other values that are not a custom field
    function getNonRequired(arr1, arr2) {
      let difference = arr1.filter(i => !arr2.includes(_.lowerCase(i)));
      console.log(difference)
      nonRequired.push(difference);
      return nonRequired
    }

    getNonRequired(headers, lowerRequired);

    for (let header of headers) {
      let formattedHeader = _.lowerCase(header).replace(/ /g, '_'); //allows for case and space insensitive matching
      if (formattedHeader === 'baseline_price') {
        lowerRequired.push(formattedHeader);
        console.log("FORMATTED",formattedHeader)
     }
      else if(formattedHeader === 'weight') {
       lowerRequired.push(formattedHeader);
       console.log("FORMATTED",formattedHeader)
      }
      else if (formattedHeader === 'ratio') {
        lowerRequired.push(formattedHeader);
        console.log("FORMATTED",formattedHeader)
      }else if (formattedHeader === 'baseline_supplier') {
        lowerRequired.push(formattedHeader);
        console.log("FORMATTED",formattedHeader)
      }
      else {
        console.log("No Optional")
      }
    }

    let newRequired = Object.values(required.concat('project_id', 'custom_fields', 'baseline_price', 'weight', 'ratio', 'baseline_supplier')); //new values that form will be checked for
    let parsedFormBody = Array.from(this.mobxState.formData.parsedForm);
    let movedBody = []; // body where column values are moved to match moved header(s)
    let rowKeys = []; //number of rows in the form

    Object.keys(parsedFormBody).forEach(key => rowKeys.push(parseInt(key))); //create keys for rows

    let req = Object.values(required); // required columns
    let rowLength = parsedFormBody[0].length; //length of columns in the form body

    //create key/value pairs with headers and form values
    Object.entries(parsedFormBody).map(([parsedKey, parsedValue]) => {
      Object.entries(headers).map(([key, value]) => {
        let lowerCaseValue = _.lowerCase(value);
        if (this.props.systemFieldCols.includes(lowerCaseValue)) {
          //case insensitive check for required columns
          movedBody.push([lowerCaseValue, parsedValue[key]]);
        } else {
          movedBody.push([value, parsedValue[key]]);
        }
      });
    });
    console.log(newRequired)
    // console.log("MOVED BODY ", movedBody)
    let fullBody = []; //form with key value pairs added to values
    let rowNum = -1; //indexes for rows
    for (let i = 0; i < movedBody.length; i += rowLength) {
      let eachRow = movedBody.slice(i, i + rowLength);
      rowNum++;
      fullBody.push(Object.fromEntries(eachRow));
    }
    // console.log(movedBody)
    let item_custom_fields_list = this.mobxState.itemData.item_custom_fields_list; //list of item_custom_fields
    //get list of custom_field aliases
    let dbFieldNames = [];
    Object.entries(item_custom_fields_list).forEach(value => {
        let key = value[0];
        let field_name = value[1].field_name;
        let field_alias = value[1].field_alias;
        dbFieldNames.push({ field_name, field_alias });

    });

    //add project_id object to each row
    for (let row of fullBody) {
      Object.assign(row, this.project_id_obj); //insert project_id object
    }

    let custom_headers_list = new Set();
    let finalBody = []; //final form body that will be submitted
    let rowCount = -1; //indexes of row

    //add custom_fields object to each row
    for (let row of fullBody) {
      //iterate rows
      let required = newRequired;
      let custom_field = {}; //each individual custom_field goes here
      Object.entries(row).map(([key, value]) => {
        //iterate individual row values
        value = value || null; // replace empty value with null
        // console.log(key, value)
        Object.entries(dbFieldNames).map(([cKey, cValue]) => {
          //cValue short for custom_value
          if (_.lowerCase(key) === _.lowerCase(cValue.field_alias)) {
            let fieldName = cValue.field_name;
            Object.assign(custom_field, { [fieldName]: value });
            custom_headers_list.add(cValue.field_alias);
          }
        });
      });
      rowCount++;
      let custom_fields = Object.assign({ custom_fields: custom_field }); // create custom_fields objects
      // console.log(custom_fields)
      let filtered = Object.keys(row) //Filter out keys that are now part of the custom_fields object
        .filter(key => required.includes(key))
        .reduce((obj, key) => {
          obj[key] = row[key];
          return obj;
        }, {});
      // console.log(filtered)
      Object.assign(filtered, custom_fields); //add custom_fields object to each row
      finalBody.push(filtered);
      }
      // console.log(finalBody);
      let custom_fields_headers = Array.from(custom_headers_list); //names of custom_fields headers for comparing
      let allValidHeaders = lowerRequired.concat(custom_fields_headers);
      let lowerValid = [];
      console.log("All Valid ", allValidHeaders)
      for (let header of allValidHeaders) {
        // lowerValid.unshift("Ignore")
        lowerValid.push(_.lowerCase(header));
      }
      //keeps uppercase valid headers from throwing errors
      let lowerHeaders = [];
      for (let header of headers) {
        lowerHeaders.push(_.lowerCase(header));
      }
      const difference = lowerHeaders.filter(x => !lowerValid.includes(x));

      console.log(_.lowerCase(difference));
      this.mobxState.unknownHeaders = Array.from(difference); //headers that are not valid
      console.log("Lower ", lowerValid)
      this.mobxState.formData.lowerRequired = [...lowerValid] //values on form that are required
      console.log(this.mobxState.formData.lowerRequired);

      this.mobxState.nonRequiredHeaders = nonRequired;
      this.mobxState.fullForm = finalBody;
      // console.log(this.mobxState.fullForm)

    console.timeEnd();

  }; //end of item parse

///for parsing constraints and freight brackets
basicFormParse = async function basicFormParse(...cols){
  console.time()
  let required = this.requiredCols;
  let headers = Array.from(this.mobxState.importHeaders);
  let optionalCols = cols //taken from argument "...cols" which comes from the "optionalCols" props
  console.log('headers ', headers);
  let lowerRequired = []; //lower case required columns sorted
  let nonRequired = []; // columns that are not required the required

  //Case insensitive header check
  Object.entries(headers).map(([key, value]) => {
    for (let reqCol of required) {
      // console.log(reqCol, key, value)
      if (_.isEqual(value, reqCol)) {
        lowerRequired.push(_.lowerCase(value));
        // headers.pop(value)
      } else if (_.lowerCase(value) === reqCol) {
        lowerRequired.push(_.lowerCase(value));
      }
    }
  });

  //get all other values that are not a custom field
  function getNonRequired(arr1, arr2) {
    let difference = arr1.filter(i => !arr2.includes(_.lowerCase(i)));
    console.log(difference)
    nonRequired.push(difference);
    return nonRequired
  }

  getNonRequired(headers, lowerRequired);

  let parsedFormBody = Array.from(this.mobxState.formData.parsedForm);
  console.log('parsedFormBody check', parsedFormBody);
  let movedBody = []; // body where column values are moved to match moved header(s)
  let rowKeys = []; //number of rows in the form

  Object.keys(parsedFormBody).forEach(key => rowKeys.push(parseInt(key))); //create keys for rows

  let req = Object.values(required); // required columns
  let rowLength = parsedFormBody[0].length; //length of columns in the form body

  console.log(parsedFormBody)
  //create key/value pairs with headers and form values
  Object.entries(parsedFormBody).map(([parsedKey, parsedValue]) => {
    Object.entries(headers).map(([key, value]) => {
      let lowerCaseValue = _.lowerCase(value).replace(/ /g, "_");
      if (this.props.systemFieldCols.includes(lowerCaseValue)) {
        //case insensitive check for required columns
        movedBody.push([lowerCaseValue, parsedValue[key]]);
      } else {
        movedBody.push([value, parsedValue[key]]);
      }
    });
  });

  let fullBody = []; //form with key value pairs added to values
  let rowNum = -1; //indexes for rows
  for (let i = 0; i < movedBody.length; i += rowLength) {
    let eachRow = movedBody.slice(i, i + rowLength);
    rowNum++;
      console.log(rowNum, Object.fromEntries(eachRow));
      fullBody.push(Object.fromEntries(eachRow));
  }

  //add project_id object and scenario_id objects to each row
  for (let row of fullBody) {
    if(this.tableName === "freight_brackets"){
      Object.assign(row, this.project_id_obj); //insert project_id object
    } else {
      Object.assign(row, this.project_id_obj); //insert project_id object
      Object.assign(row, this.scenario_id_obj); //insert scenario_id object
    }
  }

  //alter custom_field alias in excel to system name like "custom_field_3" instead of "Ship From" listed in Excel
  if(this.tableName === "qualitative_constraints" || this.tableName === "quantitative_constraints"){
    for (let qRow of fullBody) {
      qRow = await this.mapRowKeyNameToSchemaName(qRow, this.mobxState.projectFieldSchema.bid_level, 'custom_field_name', 'field_alias', 'field_name'); //params ()
    }
  }
  if(this.tableName === "freight_bracket_constraints"){
    for (let qRow of fullBody) {
      qRow = await this.mapRowKeyNameToSchemaName(qRow, this.mobxState.projectFieldSchema.freight_bid_level, 'custom_field_name', 'field_alias', 'field_name');
    }
  }

  //will replace supplier name with supplier_id
  if(this.isConstraint === true){
    //swap out supplier name with supplier_id
    let supplier_id = {} //this will replace the supplier name from the form and put the supplier_id in
    Object.entries(fullBody).map(([key, row]) => {
      Object.entries(this.mobxState.supplierData.supplier_list).map(([sKey, sValue]) => {
        let supplierName = sValue.label;
        let id = sValue.value;
        // console.log(sKey, supplierName, supplier_id)
        // row.supplier = row.supplier || null;
        if(_.lowerCase(row.supplier) === _.lowerCase(supplierName)){
          Object.assign(row, {supplier_id: id} ); //put supplier_id in the row
          delete row.supplier //get rid of supplier column thats been replaced by the id
        }
      });
      console.log(key, row)
    })
  } else {
    console.log("No supplier Id")
  }

  //swap out freight_bracket with freight_bracket_id
  if(this.tableName === "freight_bracket_constraints"){
    Object.entries(fullBody).map(([key, row]) => {
      //null out false nulls that come across as ""
      Object.entries(row).map(([rowKey, rowEntry]) => {
        if(rowEntry === ''){
          console.log('in null fix', rowKey, rowEntry);
          row[rowKey] = null;
        };
      });
      Object.entries(this.mobxState.freightBracketData.freight_bracket_list).map(([fKey, fValue]) => {
        let bracketName = fValue.label || null;
        let id = fValue.value || null;
        let freight_bracket = row.freight_bracket || null;
        // console.log(fKey, "ROW ",row.freight_bracket)
        if(_.lowerCase(freight_bracket) === _.lowerCase(bracketName)){
          Object.assign(row, {freight_bracket_id: id} ); //put freight_bracket_id in the row
          delete row.freight_bracket //get rid of supplier column thats been replaced by the id
        }
        // if(row.alternate_price === ''){row.alternate_price = null};
      });
      // console.log(key, row)
    })
  }

  let finalBody = [] //final body that gets submitted

  if(this.tableName !== 'freight_brackets' === true){
    console.log(fullBody)
    //replace scopes with their matching field_name
    Object.entries(fullBody).map(([key, row]) => {
      for(let scope of this.mobxState.scopeData.scope_list){
        let scope_field_alias = scope.label;
        let scope_field_name = scope.value;
        console.log(scope_field_alias, scope_field_name)
        if(_.lowerCase(row.scope) === _.lowerCase(scope_field_alias)){
          Object.assign(row, {scope: scope_field_name}) //put scope with field_name in the row
        }
      }
    })
  }

  //take newly created rows and put them into what will be the final form that get submitted
  for(let [key, row] of fullBody.entries()){
      finalBody.push(row)
  }

  let rowCount = -1; //indexes of row

  // console.log(finalBody.scope, finalBody.suppliers);
  let allValidHeaders = [...lowerRequired]
  let lowerValid = [];
      for (let header of allValidHeaders) {
      lowerValid.push(_.lowerCase(header));
    }
    //keeps uppercase valid headers from throwing errors
    let lowerHeaders = [];
    for (let header of headers) {
      lowerHeaders.push(_.lowerCase(header));
    }
    const difference = lowerHeaders.filter(x => !lowerValid.includes(x));

    console.log(_.lowerCase(difference));
    this.mobxState.unknownHeaders = Array.from(difference); //headers that are not valid
    this.mobxState.formData.lowerRequired = [...lowerRequired]; //values on form that are required
    console.log(lowerRequired);
    this.mobxState.nonRequiredHeaders = nonRequired;
    this.mobxState.fullForm = finalBody;
    console.log(this.mobxState.fullForm)

  console.timeEnd();
}

//HELPERS TO MUTATE///
//alter custom_field alias in excel to system name like "custom_field_3" instead of "Ship From" listed in Excel.. take in the row and schema to check aka freight_bid_level schema
mapRowKeyNameToSchemaName = (rowToMutate, schema, rowKeyToMutate, schemaValueMutateFrom, schemaValueMutateTo) => {
  //loop over schema entries
  Object.entries(schema).map(([schemaKey, schemaValue]) => {
    //if the rowToMutate has a key that is being checked
    if(rowToMutate[rowKeyToMutate] == schemaValue[schemaValueMutateFrom]){
      //actually mutate the row with the new value of the schema
      rowToMutate[rowKeyToMutate] = schemaValue[schemaValueMutateTo];
      //Object.assign(rowToMutate, {[rowKeyToMutate]: schemaValue['field_name']}); //other way to mutate
    };
  });
  return rowToMutate;
}

//HTTP//////////
//gets lists of scopes
getScopes = () => {
 axios.get(`/microservices/v1/projects/${this.project_id}/constraints/scopes`, {params: {}})
 .then((response) => {
   this.mobxState.scopeData.scope_list = response.data.scopes
   console.log(response)
 })
};

getProjectSchema = () => {
 axios.get(`/v1/projects/${this.project_id}/project_field_schemas`, {params: {}})
 .then((response) => {
   this.mobxState.projectFieldSchema = response.data.project_field_schemas[0];
 })
};

//Gets list of supplier names and matching ids
getSupplierIds = () => {
  axios.get(`/v1/projects/${this.project_id}/project_suppliers/project_index_filter`, {params: {} })
  .then((response) => {
    this.mobxState.supplierData.supplier_list = response.data.project_suppliers;
    // console.log('Suppliers: ', this.mobxState.supplierData.supplier_list);
  });
}

//Gets list of freight brackets to compare then swap freight_bracket name into id
getFreightBrackets = () => {
    axios.get(`/microservices/v1/projects/${this.project_id}/constraints/freight_match_constraints_freight_bracket_name`, {params: {}})
      .then((response) => {
          // console.log(response)
          this.mobxState.freightBracketData.freight_bracket_list = response.data.freight_bracket
      })
      .catch((error) => {console.log("Error Getting Freight Brackets ", error)})
}

getFreightBidCustomFields = () => {
  //Dynamic Form
  axios
    .get(`/microservices/v1/projects/${this.project_id}/freight/freight_bid_custom_form`, { params: {} })
    .then(response => {
      console.log('Freight Bid Custom Fields ', response.data.custom_fields_list);
      if(response.data.custom_fields_list !== null || undefined ){
        this.mobxState.freightBidData.freight_custom_fields_list = response.data.custom_fields_list
      } else
      this.mobxState.freightBidData.freight_custom_fields_list = "No custom fields"
    })
    .catch(error => {
      console.log(error);
    });
};

//This will be used to convert the column name to a corresponding custom_field_xx value
getItemCustomFields = () => {
  axios
    .get(`/microservices/v1/projects/${this.project_id}/items/item_custom_form`, { params: {} })
    .then(response => {
      this.mobxState.itemData.item_custom_fields_list = response.data.custom_fields_list;
      console.log(response.data.custom_fields_list)
      // console.log('Custom fields from DB ', this.mobxState.custom_fields_list);
    })
    .catch(error => {
      console.log(error);
    });
};

//will submit to different endpoints depending on the tableName prop
async handleSubmit(){
  if(this.tableName === 'bids'){
    this.mobxState.importForm.isLoading = true;
    ;await axios
      .post(`/v1/projects/${this.project_id}/bids/create_new_bid/batch_create`, {
        node_form: this.mobxState.nodeForm
      })
      .then(response => {
        console.log('RESPONSE', response.status, response);
        this.props.handleSheetSubmit();
        this.mobxState.importForm.isLoading = false;
      })
      .catch(error => {
        this.mobxState.submissionError = true;
        console.log(error.response.data.message);
        this.mobxState.submitText = error.response.data.message;
        this.mobxState.importForm.isLoading = false;
      })
  } else if(this.tableName === 'items'){
    this.mobxState.importForm.isLoading = true;
    try{
      axios
      .post(`/v1/projects/${this.project_id}/items/batch_create`, {
        import_form: this.mobxState.fullForm
      })
      .then(response => {
        console.log('RESPONSE', response.status, response);
        this.props.handleSheetSubmit();
        this.mobxState.importForm.isLoading = false;
        this.mobxState.importForm.stepProgress = 100;
        this.progressCaption = 'Form Submitted';
        this.mobxState.submitText = 'Form Submitted';
        // console.log('form submitted')
      })
      .catch(error => {
          this.mobxState.submissionError = true;
          // console.log(error.response.data.error.message);
          this.mobxState.submitText = error.response.data.error.message
          this.mobxState.importForm.isLoading = false;
      });
    }
    catch (err){
      console.log(err)
    }
  } else if(this.tableName === 'freight_bids'){
    ;await axios
      .post(`/v1/projects/${this.project_id}/freight_bids/batch_create`, {
        node_form: this.mobxState.nodeForm
      })
      .then(response => {
        console.log('RESPONSE', response.status, response);
        this.props.handleSheetSubmit();
        this.mobxState.importForm.isLoading = false;
      })
      .catch(error => {
        this.mobxState.submissionError = true;
        console.log(error.response.data.message);
        this.mobxState.submitText = error.response.data.message;
        this.mobxState.importForm.isLoading = false;
      })
  }
  else if(this.isConstraint === true){
    console.log('inconstraintsubmit');
    console.log('this.mobxState.fullForm', this.mobxState.fullForm);
    try{
      await axios
        .post(`/v1/projects/${this.project_id}/scenarios/${this.scenarioId}/${this.tableName}/batch_create`, {
          import_form: this.mobxState.fullForm
        })
        .then(response => {
          console.log('RESPONSE', response.status, response);
          this.props.handleSheetSubmit();
          this.mobxState.importForm.isLoading = false;
          this.mobxState.importForm.stepProgress = 100;
          this.progressCaption = 'Form Submitted';
          this.mobxState.submitText = 'Form Submitted';
           // console.log('form submitted')
        })
        .catch(error => {
          this.mobxState.submitStatus = false;
          console.log( error, error.response.data.error.message);
          this.mobxState.submitText = error.response.data.error.message;
          this.mobxState.importForm.isLoading = false;
        });
    }
    catch (err){
      console.log(err)
    }
  } else if(this.tableName === "freight_brackets"){
    axios
      .post(`/v1/projects/${this.project_id}/freight_brackets/batch_create`, {
        import_form: this.mobxState.fullForm
      })
      .then(response => {
        console.log('RESPONSE', response.status, response);
        this.props.handleSheetSubmit();
        this.mobxState.importForm.isLoading = false;
        this.mobxState.importForm.stepProgress = 100;
        this.progressCaption = 'Form Submitted';
        this.mobxState.submitText = 'Form Submitted';
      })
      .catch(error => {
        this.mobxState.submitStatus = false;
        console.log(error);
        // this.mobxState.submitText = error.response.data.error.message;
        this.mobxState.importForm.isLoading = false;
      });
  }
    else {
    console.log("ERROR SUBMITTING")
  }
}
/////end of HTTP//////
  //react-select form state handler
  handleFormChange = (field, value) => {
    console.log('handleFormChange with params:', field, value);
    this.mobxState.formData[field] = value;
  };


  //runs initial form check to see if required columns are in the form
  formCheck = async () => {
    console.log("REQ", this.mobxState.formData.lowerRequired)
    let requiredValues = this.requiredCols; //required values to compare headers
    let unAliased = await covertAliasToField(this.tableName.slice(0,this.tableName.length -1), ...Array.from(this.mobxState.aliasedRequiredCols)) //headers converted from aliases
    //allow system_field and \/or system alias_values to be used, any aliased header will be converted on back end
    let importedRequiredHeaders = Array.from(this.mobxState.formData.lowerRequired).concat(unAliased);
    let missingValues = []; //missing values pushed here in case there is more than one to be mapped to table
    let missingValue = requiredValues.filter(x => !importedRequiredHeaders.includes(x)); //value that is not in the import
    console.log(requiredValues, importedRequiredHeaders )
    this.mobxState.importForm.missingValues = missingValue;
    let missingValueSelection = []; //missing values sent here to become values in dropdown

    for (let val of missingValue) {
      missingValues.push(val) && missingValueSelection.push({ value: val, label: val });
    } //names of headers that are not in the in the form
    // for (let val of missingValue) {  missingValueSelection.push({value:val, label:val})} //names of headers that are not in the in the form
    this.mobxState.missingValueSelection = missingValueSelection;

    console.log(this.mobxState.importForm.importValid);
    this.mobxState.importForm.missingValues = missingValues;
    console.log('Missing Values ', missingValues);
    console.log('Unknown Values ', this.mobxState.unknownHeaders);
    //find unknown headers still present
    let foundUnknownHeaders = _.intersection(
      Object.values(this.mobxState.importHeaders),
      Object.values(this.mobxState.unknownHeaders)
    );
    this.mobxState.formData.foundMissing = foundUnknownHeaders;
    console.log('Found 1 ', foundUnknownHeaders);
    console.log(foundUnknownHeaders.length);
    //find any missing values not present
    //values put back in
    let reinsertedHeaders = _.intersection(
      Object.values(this.mobxState.importHeaders),
      Object.values(this.mobxState.importForm.missingValues)
    );
    this.mobxState.formData.foundMissing2 = reinsertedHeaders;
    console.log('Values put back in ', reinsertedHeaders);
    //name of value that has been put back in headers and remove from the missing values
    let fixedValue = _.intersection(this.mobxState.importForm.missingValues, this.mobxState.formData.foundMissing2);
    Object.entries(this.mobxState.importForm.missingValues).map(([key, value]) => {
      let stringVal = value.toString();
      for (let fixedVal of fixedValue) {
        // console.log(key)
        if(this.tableName === 'bids' || 'freight_bids' === true){
          if (stringVal === fixedVal) {
            _.pull(this.mobxState.importForm.missingValues, value);
            console.log("MISSING LIST ", Array.from(this.mobxState.missingValueSelection))
            this.mobxState.formData.formHeaders.splice(key, 1)
            // this.mobxState.missingValueSelection.splice(key, 1)
            this.mobxState.unknownHeaders.splice(key, 1)
          }
        } else if(this.tableName !== 'bids'){
          if(stringVal === fixedVal){
            // this.mobxState.importHeaders.splice(key, 1, this.mobxState.formData.selectedMissingValue.value);
            _.pull(this.mobxState.importForm.missingValues, value)
            this.mobxState.missingValueSelection.splice(key, 1)
            this.mobxState.unknownHeaders.splice(key, 1)
            console.log("FULL ", this.mobxState.fullForm)
          }
        }
      }
    });
    console.log("IMPORT FORM HEADERS", this.mobxState.importHeaders);
    console.log(this.mobxState.missingValueSelection, fixedValue);
    // console.log(this.mobxState.unknownHeaders);
    // console.log("FULL ", this.mobxState.fullForm)

    if (
      this.mobxState.formData.foundMissing < 1 &&
      this.mobxState.unknownHeaders.length === 0 &&
      this.mobxState.importForm.missingValues.length === 0 &&
      _.intersection(foundUnknownHeaders, this.mobxState.unknownHeaders).length === 0
    ) {
      this.mobxState.importForm.importValid = true;
      console.log(this.mobxState.importForm.importValid);
    } else {
      this.mobxState.importForm.importValid = false;
      console.log(this.mobxState.importForm.importValid);
    }
  };

  nullCheck = async function nullCheck() {
    let neededVals = ['!margins', '!ref', 'A1', 'A2']; //used to see if sheet has min values
    let selectedWorkSheet = this.mobxState.formData.selectedWorkSheet;
    if (_.intersection(neededVals, Object.getOwnPropertyNames(selectedWorkSheet)).length <= neededVals.length) {
      await this.parseForm();
      if(this.tableName === 'bids'){
        console.log("Parsing Bid Form");
        await this.bidFormParse(...this.optionalCols);
        await this.formCheck();
      } else if(this.tableName === 'items'){
        console.log("Parsing Item Form")
        await this.itemFormParse(...this.optionalCols);
        await this.formCheck();
      } else if(this.tableName === 'freight_bids'){
        console.log("Parsing Freight Bids")
        await this.bidFormParse();
        await this.formCheck();
      } else if(this.tableName === 'freight_brackets') {
        console.log(`Parsing ${this.tableName} Form`)
        await this.basicFormParse();
        await this.formCheck();
      } else if(this.isConstraint === true){
        console.log(`Parsing ${this.tableName} Form`)
        await this.basicFormParse(...this.optionalCols);
        await this.formCheck();
      // alert("This form is empty or incomplete, attempting to submit will cause errors")
      } else {
        console.log('Wont Work');
        this.mobxState.formData.isNull = true;
        this.mobxState.formData.sheetSelected = false;
    }
  }
}

  cancelImport = () => {
    confirmAlert({
      message: 'Cancel import and return to File Selection?.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.resetFormState();
            this.mobxState.importForm.stepProgress = 33;
            this.mobxState.isEditing = false;
            this.mobxState.importForm.step3 = false;
            this.mobxState.importForm.step1 = true;
          }
        },
        {
          label: 'No',
          onClick: () => console.log('Resume Import')
        }
      ]
    });
  };

  render() {
    return (
      <div className="panel-body">
        <div className="row">
          <div className="col-sm-12">
            <div className={'top_panel_info'}>Import data from an Excel or .CSV file</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span>
              <ProgressBar
                now={this.mobxState.importForm.stepProgress !== undefined ? this.mobxState.importForm.stepProgress : 33}
                label={`${this.progressCaption}`}
                variant={'info'}
                role="progressbar"
                className="active"
              />
            </span>
            <div className="import-buttons">
              {/* step 1 */}
              {this.mobxState.importForm.step1 && (
                <div className="col-lg-12" style={{textAlign:'left'}}>
                  <React.Fragment>
                    <span className="input-group excel-group" draggable="false">
                      <b>Optional: download template</b>
                        {/* Build Template SpreadSheet to download */}
                        <ImportHandler
                          tableName={this.tableName}
                          projectId={this.project_id}
                          nonCustomCols={this.props.systemFieldCols}
                          templateToBuild={this.props.templateToBuild}
                        />
                      <br />

                      <form className="input-form">
                        <b>
                          Select or drag an Excel or CSV file from you computer &nbsp;
                          {/*
                            <FontAwesomeIcon icon={['fad', 'file-excel']} size="2x" transform="shrink-2" color="green" />
                            <FontAwesomeIcon icon={['fad', 'file-csv']} size="2x" transform="shrink-2" color="green" />
                          */}
                        </b>
                        <div style={{textAlign:'left !important', marginTop: '15px'}} >
                          <input
                            className="excel-input"
                            type="file"
                            onChange={this.checkFileSize}
                            placeholder="Drag File Here"
                          />
                        </div>
                      </form>
                    </span>
                    {/* shows that a large form is parsing */}
                    {this.mobxState.isParsing &&
                      <React.Fragment>
                        <span  style={{textAlign:'center'}}>
                          <b>Parsing File For Our System</b>
                          &nbsp;
                          <span
                          className="submitting-icon glyphicon glyphicon-refresh"
                          style={{ color: 'green' }}
                          />
                        </span>
                      </React.Fragment>
                    }
                    </React.Fragment>
                </div>
              ) // end of col-lg-6
              }{' '}
              {/* end of step 1 */}
              {/* Step 2 */}
              {this.mobxState.importForm.step2 && (
                <span className="file-name excel-group">
                  <div style={{textAlign:'left !important'}}>
                    {/*this is where you confirm the file you want to import*/}
                    <b>Confirm file or select an excel sheet to import</b>
                    <br />
                    <span
                      className=" glyphicon glyphicon-ok"
                      style={{ color: 'green' }}
                    >
                      &nbsp;
                    </span>
                    {this.mobxState.formData.fileName} &nbsp;
                    <span className="trash-icon" data-toggle="tooltip" title="Discard and select a new file.">
                      <FontAwesomeIcon
                        icon={['fad', 'trash']}
                        size="1x"
                        transform="shrink-2"
                        color="slate"
                        onClick={this.resetFormState}
                      />
                      <br />
                      {/* <span className="selectedSheet">{!this.mobxState.sheetSelected ? this.mobxState.formData.defaultSheet.label :this.mobxState.formData.selectedSheetName}</span> */}
                    </span>
                    {/* selection options excel sheets */}
                    {this.mobxState.formData.formPresent && !this.mobxState.importForm.isCSV ? (
                      <div className="sheet-select">
                        <br />
                        <b>Sheet Selection</b>
                        <React.Fragment>
                          <Select
                            maxMenuHeight={400}
                            isSearchable={true}
                            closeMenuOnSelect={true}
                            options={this.mobxState.formData.selectOptions}
                            defaultValue={
                              !this.mobxState.sheetSelected
                                ?  {label: "Select Sheet", value: "Select Sheet"}
                                : {label: "Select Sheet", value: "Select Sheet"}
                            }
                            onChange={event => {
                              // this.getFields();
                              this.mobxState.sheetSelected = true;
                              this.mobxState.formData.selectedSheetIndex = event.value;
                              //Use PascalCase typing in objects
                              let workbook = this.mobxState.formData.workbook;
                              // console.log(workbook);
                              let selectedSheetName = workbook.SheetNames[event.value];
                              this.mobxState.formData.selectedSheetName = selectedSheetName;
                              console.log(selectedSheetName);
                              // this.mobxState.formData.displaySheetName = selectedSheetName;
                              let selectedWorkSheet = workbook.Sheets[selectedSheetName];
                              console.log(Object.values(selectedWorkSheet).length);
                              // console.log(selectedWorkSheet);
                              this.mobxState.formData.selectedWorkSheet = selectedWorkSheet;
                              //check if form is empty
                              let neededVals = ['!margins', '!ref', 'A1', 'A2']; //used to see if sheet has min values
                              if (
                                _.intersection(neededVals, Object.getOwnPropertyNames(selectedWorkSheet)).length >=
                                neededVals.length
                              ) {
                                this.mobxState.formData.sheetSelected = true;
                                this.mobxState.formData.isNull = false;
                                // console.table(Object.getOwnPropertyNames(selectedWorkSheet));
                                console.log('good form');
                              } else {
                                this.mobxState.formData.isNull = true;
                                this.mobxState.formData.sheetSelected = false;
                                // console.log(Object.getOwnPropertyNames(selectedWorkSheet));
                                console.log('bad form');
                              }
                            }}
                          />
                        </React.Fragment>
                        {/* selection for CSV files */}
                      </div>
                    ) : (
                      <React.Fragment>
                        {/* grab first sheet by default */}
                        {(() => {
                          // this.getFields();
                          // grabs first sheet
                          this.mobxState.formData.selectedSheetIndex = 0;
                          //Use PascalCase typing in objects
                          let workbook = this.mobxState.formData.workbook;
                          // console.log(workbook);
                          let selectedSheetName = workbook.SheetNames[0];
                          this.mobxState.formData.selectedSheetName = selectedSheetName;
                          console.log(selectedSheetName);
                          // this.mobxState.formData.displaySheetName = selectedSheetName;
                          const selectedWorkSheet = workbook.Sheets[selectedSheetName];
                          console.log(Object.values(selectedWorkSheet).length);
                          // console.log(selectedWorkSheet);
                          // Delete the Blank Rows from the worksheet
                          this.mobxState.formData.selectedWorkSheet = selectedWorkSheet;
                          Object.values(selectedWorkSheet).length <= 10
                            ? (this.mobxState.formData.isNull = true)
                            : (this.mobxState.formData.sheetSelected = true);
                        })()}
                      </React.Fragment>
                    )}
                    {/* Btn group to go forwards or back */}
                    <br />
                    <br />
                    <React.Fragment>
                      {this.mobxState.formData.isNull ? (
                        <span style={{ color: '#f44336' }}>
                          This sheet is empty or lacks the minimum required fields to be submitted.
                        </span>
                      ) : null}
                      {this.mobxState.formData.sheetSelected && (
                        <React.Fragment>
                          <span style={{ color: '#4CAF50' }}>
                            <b>Valid File &nbsp;</b>
                          </span>
                          <span className=" glyphicon glyphicon-ok" style={{ color: '#4CAF50' }}>
                            &nbsp;
                          </span>
                        </React.Fragment>
                      )}
                    </React.Fragment>

                    <footer className="import-footer">
                      <div className="btn-group step-btns" role="group">
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            style={{ marginRight: '.5em', borderRadius: '4px' }}
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              this.progressCaption = 'Step 1/3';
                              this.mobxState.importForm.step1 = true;
                            }}
                            disabled
                          >
                            <span className="glyphicon glyphicon-arrow-left" />
                            &nbsp;Prev
                          </button>
                        </div>
                        {/* Next Button */}
                        <div className=" btn-group" role="group">
                          <button
                            type="button"
                            style={{ borderRadius: '4px', backgroundColor: '#2196f3' }}
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              this.nullCheck();
                              this.parseForm();
                              this.progressCaption = 'Step 3/3';
                              this.mobxState.importForm.stepProgress = 99;
                              this.mobxState.importForm.step2 = false;
                              this.mobxState.importForm.step3 = true;
                              // this.mobxState.importForm.stepProgress += 33; //moves progress bar
                            }}
                            disabled={!this.mobxState.formData.sheetSelected}
                          >
                            Next&nbsp;
                            <span className="glyphicon glyphicon-arrow-right" />
                          </button>
                        </div>
                      </div>
                    </footer>
                  </div>
                </span>
              )}{' '}
              {/* end of step 2 */}
              {/* step 3 */}
              {/* step if the form is valid */}
              {this.mobxState.importForm.step3 && (
                <span className="file-name excel-group">
                  <div className="row" style={{ marginTop: '.1em' }}>
                    <div className="col-lg-12" style={{ padding: '1em' }}>
                      {/* form invalid info */}
                      <React.Fragment>
                        <div>
                          <span style={{ display: 'inline-block', marginBottom: '10px' }}>
                            {/* valid form */}
                            {this.mobxState.importForm.importValid && (
                              <React.Fragment>
                                <h4 style={{ color: '#43A047', display: 'inline' }}>File valid</h4> &nbsp;
                                <span className=" glyphicon glyphicon-ok" style={{ color: 'green' }} />
                                <br />
                                <span className={"ignore-paragraph"}>
                                  <p>If you would like to swap or ignore certain columns:</p>
                                    <ol>
                                      <li>Press on a header to enter editing mode.</li>
                                      <li>You can ignore or swap columns by pressing <code><span className="glyphicon glyphicon-transfer" style={{ color: '#79bbf8' }} /></code> </li>
                                      <li>The value of the column will be added to the dropdown menu to select from.</li>
                                      <li>If a value is not selected the word "Ignore" will appear.</li>
                                      {/*
                                        <li>Press the <code>Exit Editing Mode</code> button to return.</li>
                                        <li>Notes: Required Columns are <mark>Highlighted Yellow.</mark> </li>
                                        <li>Columns that say "Ignore" will not be submitted.</li>
                                      */}
                                    </ol>
                                  </span>
                                <b>{this.mobxState.formData.displaySheetName}</b>
                                &nbsp; &nbsp;
                              </React.Fragment>
                            )}
                            {/* invalid form */}
                            {!this.mobxState.importForm.importValid && (
                              <React.Fragment>
                                <h4 style={{ color: '#f44336', display: 'inline' }}>Invalid File</h4> &nbsp;
                                <span className=" glyphicon glyphicon-remove" style={{ color: '#f44336' }} />
                                <br />
                                <b>{this.mobxState.formData.displaySheetName}</b>
                                <br />
                                &nbsp; &nbsp;
                                <br />
                                <div className="missing-headers-container">
                                  <div className="required-headers-list">
                                    <b>Required Headers:</b>
                                    <br />
                                    {Object.entries(this.requiredCols).map(([key, value]) => {
                                      return (
                                        <React.Fragment key={key}>
                                          <h4 key={value}>{value}</h4>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                  <article style={{ fontSize: '90%' }}>
                                    Note: Your file <b style={{ textDecoration: 'underLine' }}>will not </b>

                                    submit properly without these fields.<mark>Required are Highlighted Yellow </mark>

                                    Make sure your headers have these values (the
                                    order does not matter). Headers are case sensitive, if a field is correct but
                                    showing as invalid you may have simply misspelled or mislabeled a column. (e.g...
                                    "Bank Of City" --&gt; "Bank of City",&nbsp; "one & two" --&gt; "one and two" etc..).{' '}
                                    <hr /> {/*If you have the required fields you may choose to ignore unknown fields and
                                    submit all valid ones. */}
                                    <br /> Press a header to enter editing mode and press
                                    <code>Exit Editing Mode.</code>
                                    <hr />
                                    <div className={"ignore-paragraph"}>
                                    <p>If you would like to swap or ignore certain columns:</p>
                                      <span className={"list-container"}>
                                        <ol>
                                          <li>Press on a header to enter editing mode.</li>
                                          <li>You can ignore or swap columns by pressing <code><span className="glyphicon glyphicon-transfer" style={{ color: '#79bbf8' }} /></code></li>
                                          <li>The value of the column will be added to the dropdown menu to select from.</li>
                                          <li>If a value is not selected the word "Ignore" will appear.</li>
                                          <li>Press the <code>Exit Editing Mode</code> button to return.</li>
                                          <li>Note: Required Columns are <mark>Highlighted Yellow.</mark></li>
                                          <li>Note: Try to replace any missing required header before swapping or ignoring a column</li>
                                          <li>Columns that say "Ignore" will not be submitted.</li>
                                        </ol>
                                      </span>
                                    </div>
                                  </article>
                                  {/* headers that are not in the form  */}
                                  <ul
                                    className="missing"
                                    style={{ textAlign: 'start' }}
                                    data-missing-list="missingList"
                                  >
                                    <b>Missing Headers:</b>
                                    {Object.entries(this.mobxState.importForm.missingValues).map(([key, value]) => {
                                      return (
                                        <li key={key} style={{ color: 'tomato' }}>
                                          {value}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  {/* invalid headers */}
                                  {this.mobxState.unknownHeaders.length > 0 &&
                                    <ul
                                      className="missing"
                                      style={{ textAlign: 'start' }}
                                      data-missing-list="missingList"
                                    >
                                      <b>Unknown Headers:</b>
                                      {Object.entries(this.mobxState.unknownHeaders).map(([key, value]) => {
                                        return (
                                          <li key={key} style={{ color: 'tomato' }}>
                                            {value}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  }
                                </div>
                              </React.Fragment>
                            )}
                          </span>{' '}
                          &nbsp;
                        </div>

                        {/* List of required headers from props */}
                        {this.mobxState.isEditing && (
                          <React.Fragment>
                            <button
                              type="button"
                              style={{ marginBottom: '10px', width: '11em' }}
                              className="btn btn-outline btn-primary btn-md"
                              onClick={() => {
                                // this.attributeCheck();
                                this.mobxState.isEditing = false;
                                console.log(this.mobxState.unknownHeaders);
                                this.formCheck();
                                // this.mobxState.importForm.importValid = true;
                              }}
                            >
                              Exit Editing Mode &nbsp;
                              <span className="glyphicon glyphicon-log-out" />
                            </button>
                            {/* <Button
                              type="button"
                              className="btn btn-warning"
                              style={{ marginLeft: '10px', marginBottom: '10px', width: '14em' }}
                              onClick={event => {
                                event.persist();
                                this.mobxState.unknownHeaders.length = 0;
                                console.log(this.mobxState.unknownHeaders);
                              }}
                            >
                              Ignore Unknown Headers
                            </Button> */}
                          </React.Fragment>
                        )}

                        <Table responsive striped bordered hover className="compare-tables header-edit-table">
                          {!this.mobxState.importForm.importValid && (
                            <React.Fragment>
                              <tbody>
                                <tr className="header-info">
                                  <td colSpan="3">Headers on your imported file: Click a header to edit</td>
                                </tr>
                              </tbody>
                            </React.Fragment>
                          )}
                          <tbody>
                            <tr
                              ref={'headerRow'}
                              className={`header-row ` +
                                  `${ Object.entries(this.mobxState.importHeaders).every(
                                  name => !this.mobxState.unknownHeaders.includes(name)
                                  ) ? 'valid-header' : 'invalid-header'}`
                              }
                            >
                              {Object.entries(this.mobxState.importHeaders).map(([key, value]) => {
                                let tdType = true;
                                let tdDisabled = false;
                                for (let wrong of this.mobxState.unknownHeaders) {
                                  // console.log(value, wrong);
                                  value === wrong ? (tdType = tdDisabled) : (tdType = tdType);
                                }

                                return this.mobxState.isEditing ? (

                                  <td key={key}
                                  data-required-cell={`${this.requiredCols.some(col => _.lowerCase(col) === _.lowerCase(value).replace(/_/g, " ")) ?
                                    `required-cell`:
                                    `${Object.values(this.mobxState.unknownHeaders).some(header => _.lowerCase(header) === _.lowerCase(value).replace(/_/g, " ")) ?
                                      'unknown-cell' : 'valid'}`}`
                                    }
                                  >
                                    <span className={"preview-headers"}>
                                      <Select
                                        options={this.mobxState.missingValueSelection.concat(this.mobxState.unusedCols)} //adds value of column where ignore was pressed
                                        closeMenuOnSelect={true}
                                        key={value}
                                        className={`${tdType ? 'right-header editor edit-text' : 'wrong-header editor edit-text'
                                          }`}
                                        ref={`${tdType ? { value } : { value }}`}
                                        placeholder={value}
                                        onChange={event => {
                                          console.log(event);
                                          this.handleFormChange('selectedMissingValue', event);
                                          console.log(this.mobxState.formData.selectedMissingValue.value);
                                          (() => {
                                            if(_.isEqual(this.props.tableName, 'bids') || _.isEqual(this.props.tableName, 'freight_bids')){
                                              console.log("BID FIX ")
                                              this.mobxState.nodeForm[0].splice(key, 1 ,this.mobxState.formData.selectedMissingValue.value);
                                              this.mobxState.importHeaders.splice(key, 1, this.mobxState.formData.selectedMissingValue.value);
                                              this.mobxState.importForm.missingValues.splice(key, 1);
                                              console.log("FORM ",this.mobxState.importHeaders,  this.mobxState.fullForm)
                                            }
                                            else if(_.isEqual(this.tableName, "items")) {
                                              console.log("ITEM FIX");
                                              this.handleFormChange('selectedMissingValue', event);
                                              console.log(this.mobxState.formData.selectedMissingValue.value);
                                              this.mobxState.importHeaders.splice(key, 1, this.mobxState.formData.selectedMissingValue.value);
                                              this.mobxState.importForm.missingValues.splice(key, 1);
                                              console.log("FIXED HEADERS ", this.mobxState.importHeaders)
                                              let fixedHeaders = [...this.mobxState.importHeaders]
                                              this.itemFormParse(fixedHeaders)
                                              // console.log("FULL FIXED ", this.mobxState.fullForm)
                                            }
                                            else {
                                                console.log("IN default splice")
                                               console.log("Missing ", this.mobxState.formData.selectedMissingValue.value)
                                               console.log("SPLICING ", this.mobxState.importHeaders)
                                               this.mobxState.importHeaders.splice(key, 1, this.mobxState.formData.selectedMissingValue.value);
                                               // this.mobxState.fullForm[0] = this.mobxState.importHeaders
                                               let fixedHeaders = [...this.mobxState.importHeaders]
                                               this.basicFormParse(fixedHeaders)
                                               console.log("FIXED HEADERS ", this.mobxState.importHeaders)
                                               console.log("FULL 1386 ", this.mobxState.fullForm)
                                            }
                                          })()
                                          this.mobxState.importForm.missingValues.splice(key, 1);
                                          let eventValue = this.mobxState.formData.selectedMissingValue.value;
                                          // _.pull(this.mobxState.missingValueSelection, eventValue);
                                          // _.pull(this.mobxState.importForm.missingValues, eventValue);
                                          _.pull(this.mobxState.unknownHeaders, _.lowerCase(value));
                                          this.formCheck();
                                        }
                                      }
                                      />
                                      {/* button to ignore column */}
                                      {/* will take value from column and add it to dropdown list so it can be swapped with another col*/}
                                      <button
                                        onClick={async (event) => {
                                          console.log(event, value, key)
                                          this.mobxState.unusedCols.push({value: value, label: value}) // add column as option for dropdown
                                          // this.mobxState.missingValueSelection.concat(this.mobxState.unusedCols) //add ignored column to dropdown list
                                          Object.entries(this.mobxState.unknownHeaders).map(([key, item]) => {
                                            console.log(key, item, value, indexOf(value))
                                            _.pull(this.mobxState.unknownHeaders, _.lowerCase(value))
                                            _.pull(this.mobxState.importForm.missingValues, _.lowerCase(value))
                                            this.mobxState.missingValueSelection.splice(key, 1);
                                            console.log(this.mobxState.missingValueSelection)
                                          })
                                          this.mobxState.importHeaders.splice(key, 1, "Ignore")
                                          if(this.tableName === 'bids'){
                                            this.mobxState.nodeForm[0].splice(key, 1, "Ignore")
                                          }
                                          console.log("NODEFORM ", this.mobxState.nodeForm)
                                        }}

                                        type="button"
                                        className="ignore-btn"
                                        title="Swap Or Ignore Column"
                                      ><span className="glyphicon glyphicon-transfer" style={{ color: '#79bbf8' }} />
                                      </button>
                                    </span>

                                    {/* <Button className="btn btn-warning"
                                      onClick={() =>{
                                        let newValue = prompt("Enter a new header name")
                                        let missingValues = this.mobxState.importForm.missingValues;
                                        let unknownValues = this.mobxState.unknownHeaders;
                                        for(let missing of missingValues){
                                          if(newValue === missing){
                                            console.log(`old value ${newValue} new value ${missing}`)
                                            missingValues.filter(function(arr){
                                              return arr !== newValue;
                                            })
                                          } else {
                                            console.log("value not valid", missingValues)
                                          }
                                        }
                                        for(let unknown of this.mobxState.unknownHeaders){
                                          if(newValue === unknown){
                                            console.log(`old value ${newValue} new value ${unknown}`)
                                            this.mobxState.unknownHeaders.filter(function(i){
                                              return i !== newValue;
                                            })
                                          } else {
                                            console.log("value not valid", unknownValues)
                                          }
                                        }
                                        console.log(this.mobxState.formData.foundMissing);
                                        this.mobxState.importHeaders.splice(key, 1, newValue);
                                        console.log(this.mobxState.importHeaders)
                                        console.log(this.mobxState.importForm.missingValues);
                                        console.log(this.mobxState.unknownHeaders);
                                        console.log(newValue);
                                        this.formCheck();
                                      }}><span className="glyphicon glyphicon-pencil"></span>
                                      </Button> */}
                                  </td>
                                ) : (
                                  <td
                                    key={key}
                                    ref={`${tdType ? 'right-header' : 'wrong-header'}`}
                                    data-required-cell={
                                      `${[
                                          ...this.requiredCols,
                                          ...Array.from(this.mobxState.aliasedRequiredCols),
                                        {/*  ...Array.from(this.mobxState.aliasList)*/}
                                        ].some(col => _.lowerCase(col)  === _.lowerCase(value).replace(/[^a-zA-Z0-9#]/g, " ")) ? `required-cell`: `${Object.entries(this.mobxState.importHeaders).every(name => this.mobxState.unknownHeaders.includes(name)) ? 'unknown-cell':'valid'}
                                      `}
                                    `}
                                    className={`${tdType ? 'right-header' : 'wrong-header'}`}
                                    onClick={() => (this.mobxState.isEditing = true)}
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                            {/* form data */}
                            {!this.mobxState.formData.noRows && (
                              <React.Fragment>
                                <tr>
                                  {Object.entries(this.mobxState.formData.previewForm[0]).map(([key, value]) => {
                                    return <td key={value + key}>{value}</td>;
                                  })}
                                </tr>
                                {this.mobxState.formData.previewForm[1] && (
                                  <tr>
                                    {Object.entries(this.mobxState.formData.previewForm[1]).map(([key, value]) => {
                                      return <td key={value + key}>{value}</td>;
                                    })}
                                  </tr>
                                )}
                                {this.mobxState.formData.previewForm[2] && (
                                  <tr>
                                    {Object.entries(this.mobxState.formData.previewForm[2]).map(([key, value]) => {
                                      return <td key={value + key}>{value}</td>;
                                    })}
                                  </tr>
                                )}
                                {this.mobxState.formData.previewForm[3] && (
                                  <tr>
                                    {Object.entries(this.mobxState.formData.previewForm[3]).map(([key, value]) => {
                                      return <td key={value + key}>{value}</td>;
                                    })}
                                  </tr>
                                )}
                                {this.mobxState.formData.previewForm[4] && (
                                  <tr>
                                    {Object.entries(this.mobxState.formData.previewForm[4]).map(([key, value]) => {
                                      return <td key={value + key}>{value}</td>;
                                    })}
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </Table>
                      </React.Fragment>
                    </div>
                  </div>

                  {/* Btn group to   go forwards or back or cancel */}
                  <br />
                  <footer>
                    <div className="btn-group step-btns" role="group" style={{}}>
                      <div className="btn-group" role="group">
                        {/* Cancel Button */}
                        <button
                          type="button"
                          style={{ borderRadius: '4px', marginRight: '1em' }}
                          title="Cancel import and go back to file selection."
                          onClick={() => {
                            this.cancelImport();
                          }}
                          className="btn btn-default btn-md"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          style={{ borderRadius: '4px', marginRight: '.5em' }}
                          className="btn btn-primary btn-md"
                          onClick={() => {
                            this.partialFormReset();
                            this.mobxState.importForm.step3 = false;
                            this.mobxState.importForm.step2 = true;
                            this.mobxState.importForm.stepProgress = 66;
                            this.mobxState.formData.sheetSelected = false;
                            this.progressCaption = 'Step 2/3';
                          }}
                        >
                          <span className="glyphicon glyphicon-arrow-left" />
                          &nbsp; Prev
                          {/* submit button */}
                        </button>
                        &nbsp;
                          <button
                          type="button"
                          style={{ borderRadius: '4px' }}
                          title="Select a sheet to continue"
                          className="btn btn-success import-submit-btn btn-md"
                          onClick={() => {
                            this.mobxState.importForm.isLoading = true;
                            // this.formCheck();
                            // console.log(this.mobxState.nonRequiredHeaders)
                            this.handleSubmit()
                            this.progressCaption = 'STEP 3/3';
                            this.mobxState.importForm.step3 = false;
                            this.mobxState.importForm.step4 = true;
                          }}
                          disabled={!this.mobxState.importForm.importValid}
                        >
                          Submit &nbsp;
                          <FontAwesomeIcon icon={['fal', 'file-download']} />
                        </button>
                      </div>
                    </div>
                  </footer>
                </span>
              )}
              {/* end of step 3 */}
              {/* step 4 */}
              {this.mobxState.importForm.step4 &&
                this.mobxState.submitStatus === true && (
                  <span className="file-name excel-group">
                    <footer>
                      {this.mobxState.importForm.isLoading &&
                        !this.mobxState.submissionError &&
                        <React.Fragment>
                          <b>Submitting File</b>
                          &nbsp;
                          <span
                          className="submitting-icon glyphicon glyphicon-refresh"
                          style={{ color: 'green' }}
                          />
                        </React.Fragment>
                      }
                      {!this.mobxState.importForm.isLoading &&
                        !this.mobxState.submissionError &&
                        <React.Fragment>
                          <b>File has been submitted</b>
                          &nbsp;
                          <span
                          className=" glyphicon glyphicon-ok"
                          style={{ color: 'green' }}
                          />
                        </React.Fragment>
                      }
                      {/* //error message */}
                      {this.mobxState.submissionError &&
                        <React.Fragment>
                          <p style={{fontSize: '105%'}}>{this.mobxState.submitText}</p>
                        </React.Fragment>
                      }
                      <br />
                      <button
                        onClick={this.resetFormState}
                        className="btn btn-default btn-md"
                        style={{ borderRadius: '4px' }}
                        type="button"
                        data-toggle="collapse"
                        data-target="#importForm"
                        aria-expanded="false"
                        aria-controls="importForm"
                      >
                        Close
                      </button>
                    </footer>
                  </span>
                )}
              {/* submission error display */}
              {this.mobxState.submitStatus !== true && (
                <span className="file-name excel-group">
                  <footer>
                    <b style={{ color: 'tomato' }}>Error Submitting File</b>
                    <p style={{ fontSize: '80%' }}>
                    {this.mobxState.submitText}{' '}
                    </p>
                    <button
                      onClick={() => {
                        this.resetFormState();
                        console.log(this.mobxState);
                      }}
                      className="btn btn-default btn-md"
                      style={{ borderRadius: '4px' }}
                      type="button"
                      data-toggle="collapse"
                      data-target="#importForm"
                      aria-expanded="false"
                      aria-controls="importForm"
                    >
                      Close
                    </button>
                  </footer>
                </span>
              )}
              {/* end of step 4 */}
              {/* <div className="col-lg-3" /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

decorate(SpreadSheetImport, {
  mobxState: observable
});

export default observer(SpreadSheetImport);

// export default SpreadSheetImport;
