import React, { Component } from 'react';
import { timeoutLogout } from '../../services/Timing';

// import Project Reports
import Dashboard from './projects/Dashboard';
import Details from './projects/Details';

 

// import Executive from './components/executive/Executive';
import ContractOverview from './dashboards/ContractOverview';
import Overview from './dashboards/Overview';
import ProcessOverview from './dashboards/ProcessOverview';
import ProjectOverview from './dashboards/ProjectOverview';
import TaskOverview from './dashboards/TaskOverview';
import UserOverview from './dashboards/UserOverview';

//sidebar
import MainSidebar from '../../headers/MainSidebar';

import MainHeader from '../../headers/MainHeader';
import withRole from '../../security/withRole';

import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//images
import CompanyLogoWithText from '../../../assets/images/simfoni_logo_1920x1080_transparent_background.png'
import CompanyLogoWithoutText from '../../../assets/images/simfoni_transparent_icon.png'

//security
import { checkRole } from '../../security/SecurityUtilities';
import { injectCustomerColorScheme } from '../../services/InjectedStyle';

// utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class Executive extends React.Component {
  mobxState = {};

  componentDidMount() {
    checkRole({ model: 'legacy_project_insight', permission: 'r' }).then(res => {
      this.mobxState.canLegacyProjectInsightR = res;
    });
    checkRole({ model: 'legacy_ticket_insight', permission: 'r' }).then(res => {
      this.mobxState.canLegacyTicketInsightR = res;
    });
    checkRole({ model: 'legacy_contract_insight', permission: 'r' }).then(res => {
      this.mobxState.canLegacyContractInsightR = res;
    });
    checkRole({ model: 'legacy_overview_insight', permission: 'r' }).then(res => {
      this.mobxState.canLegacyOverviewInsightR = res;
    });
    checkRole({ model: 'legacy_trello_insight', permission: 'r' }).then(res => {
      this.mobxState.canLegacyTrelloInsightR = res;
    });
    injectCustomerColorScheme().then(({ styleInjector, clientColorScheme }) => {
      this.mobxState.styleInjector = styleInjector;
      this.mobxState.clientColorScheme = clientColorScheme;
    });

    //timeout user session in seconds
    timeoutLogout(60 * 60);
  }

  componentDidUpdate() {
    //timeout user session in seconds
    timeoutLogout(60 * 60);
  }

  render() {
    //this variable does all the routing and is passed into here from parent
    const match = this.props.match;

    return (
      <div>
        <div id='main-page-header' className='main-page-header'>
          {/* HEADER ---------------------------------------------------------- */}
          <div id='top_line'></div>
          <nav className='navbar header-navbar navbar-fixed-top'>
            <div className='navbar-wrapper'>
              {/* Header and Logo ----------------------------------------------------------------- */}
              <div className='navbar-logo'>
                <button
                  type='button'
                  data-toggle='modal'
                  data-target='#main-sidebar-dock'
                  className='btn btn-default navbar-btn'
                >
                  <FontAwesomeIcon icon={['fas', 'bars']} color='#D3D4D6' transform='' style={{ fontSize: '19px' }} />
                </button>
                <img alt='Simfoni Logo' className='simfoni-logo' src={CompanyLogoWithoutText} />
              </div>

              {/* Links in the center ------------------------------------------------------------- */}
              <div className='navbar-menus'>
                <ul className='nav navbar-nav'>
                  {/* Project Insights */}
                  <li className="dropdown">
                    <a id="projectDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      Project Insights
                      <span className="caret"/>
                    </a>
                    <ul className="dropdown-menu" id="projectSubmenu">
                      <li><Link to={`${match.url}/Dashboard`}>Overview</Link></li>
                      <li><Link to={`${match.url}/Details`}>Bid Details</Link></li>
                    </ul>
                  </li>
                  {/* Executive Views */}
                  {/*<li className="dropdown">
                    <a id="projectDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      Executive Views
                      <span className="caret"/>
                    </a>
                    <ul className="dropdown-menu" id="projectSubmenu">
                      {this.mobxState.canLegacyOverviewInsightR &&
                        <li><Link to={`${match.url}/Overview`}>Overview</Link></li>
                      }
                      {this.mobxState.canLegacyProjectInsightR &&
                        <li><Link to={`${match.url}/ProjectOverview`}>Projects</Link></li>
                      }
                      {this.mobxState.canLegacyContractInsightR &&
                        <li><Link to={`${match.url}/ContractOverview`}>Contracts</Link></li>
                      }
                      {this.mobxState.canLegacyTicketInsightR &&
                        <li><Link to={`${match.url}/TaskOverview`}>Process</Link></li>
                      }
                    </ul>
                  </li>*/}
                  {/* Boards */}
                  {/*{this.mobxState.canLegacyTrelloInsightR &&
                  <li className="dropdown">
                    <a id="projectDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      Boards
                      <span className="caret"/>
                    </a>
                    <ul className="dropdown-menu" id="projectSubmenu">
                      <li><Link to={`${match.url}/ProcessOverview`}>Status</Link></li>
                      <li><Link to={`${match.url}/UserOverview`}>Users</Link></li>
                    </ul>
                  </li>
                  }*/}
                </ul>
              </div>
              {/* Button for right hand side */}
              <div className='pull-right' style={{ paddingTop: '5px' }}>
                <img src={this.mobxState.client_logo} style={{ maxWidth: '200px' }} />
              </div>
            </div>
          </nav>
        </div>
        {/* Sidebar ------------------------------------------------------- */}
        <MainSidebar />

        {/* BODY ---------------------------------------------------------- */}
        <div className='wrapper' style={{}}>
          <div id='content'>
            {/*<!-- Trigger the modal with a button -->*/}
            <Switch>
              <Route path={`${match.path}/Dashboard`} component={withRole(Dashboard, {'model':'project_overview_insight', 'permission':'r'})}/>
              <Route path={`${match.path}/Details`} component={withRole(Details, {'model':'project_detail_insight', 'permission':'r'})}/>
              <Route path={`${match.path}/Overview`} component={withRole(Overview, {'model':'legacy_overview_insight', 'permission':'r'})}/>
              <Route path={`${match.path}/ContractOverview`} component={withRole(ContractOverview, {'model':'legacy_contract_insight', 'permission':'r'})}/>
              <Route path={`${match.path}/ProcessOverview`} component={withRole(ProcessOverview, {'model':'legacy_ticket_insight', 'permission':'r'})}/>
              <Route path={`${match.path}/ProjectOverview`} component={withRole(ProjectOverview, {'model':'legacy_project_insight', 'permission':'r'})}/>
              <Route path={`${match.path}/TaskOverview`} component={withRole(TaskOverview, {'model':'legacy_trello_insight', 'permission':'w'})}/>
              <Route path={`${match.path}/UserOverview`} component={withRole(UserOverview, {'model':'legacy_trello_insight', 'permission':'w'})}/>
            </Switch>
            <div className='line'></div>
          </div>
        </div>
        {/*<MainHeader />*/}
      </div>
    ); //end return
  } // end render
} //end class

decorate(Executive, {
  mobxState: observable
});

export default observer(Executive);
