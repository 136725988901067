import React from 'react';
import ConfigDropdown from './ConfigDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//loops through filterModelNames property, and renders a filter for each, including onChange callback
//NOTE about 10% slower than manually rendering the filters
class ConfigQuickAction extends React.Component {
  render() {
    return (
      <span>
        <React.Fragment>
          <span
            onClick={this.props.handleConfig}
            className='filter_toggle'
            data-toggle='modal'
            data-target={`#visualizationConfigModal-${this.props.value.id}`}
          >
            <FontAwesomeIcon icon={['fal', 'cog']} color='#C6C9CF' transform='' style={{}} />
          </span>
          &nbsp; &nbsp;
        </React.Fragment>
        <ConfigDropdown
          value={this.props.value}
          handleClone={this.props.handleClone}
          handleChange={this.props.handleChange}
          handleSubmit={this.props.handleSubmit}
          hasChartEditPermission={this.props['hasChartEditPermission']}
          hasSuperUserPermission={this.props['hasSuperUserPermission']}
        />
      </span>
    );
  }
}

export default ConfigQuickAction;
