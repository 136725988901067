import React from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsStock from 'highcharts/highstock';

//set global options
export function setHighChartsGlobals(selection) {
  [Highcharts, HighchartsStock].forEach(module_ => {
    // Colors can are either the default set here, or a custom color scheme if one is set.
    // Color scheme is saved by InjectedStyle.js to localStorage when injectCustomerColorScheme is called.
    // Page to set colorscheme: `setup/spend/client_color_scheme`
    //prettier-ignore
    let colorsDefault = [
      '#003f63',
      '#004770',
      '#004f7c',
      '#1a6189',
      '#337296',
      '#4d84a3',
      '#6695b0',
      '#80a7be',
      '#99b9cb',
      '#b3cad8',
      '#ccdce5',
      '#e6edf2',
      '#004F7D',
      '#01AEAA',
      '#01A7A3',
      '#019D99',
      '#019490',
      '#00847F',
      '#B1FFFC',
      '#7EFFFA',
      '#4BFFF7',
      '#31FFF6',
      '#B3E5FC',
      '#03A9F4',
      '#2196F3',
      '#03A9F4',
      '#00BCD4',
      '#009688',
      '#9E9E9E',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#FFEB3B',
      '#FFC107',
      '#FF9800',
      '#FF5722',
      '#795548',
      '#607D8B',
      '#E91E63',
      '#9C27B0',
      '#673AB7',
      '#3F51B5'
    ];
    let colors = [];
    colors = (
      (localStorage['clientColorScheme']
      && JSON.parse(localStorage['clientColorScheme'])
      && JSON.parse(localStorage['clientColorScheme']).highcharts_globals
      && JSON.parse(localStorage['clientColorScheme']).highcharts_globals.value
      )
      || colorsDefault
    );

    module_.setOptions({
      chart: {
        // backgroundColor: {
        //     linearGradient: [0, 0, 500, 500],
        //     stops: [
        //         [0, 'rgb(255, 255, 255)'],
        //         [1, 'rgb(240, 240, 255)']
        //         ]
        // },
        // borderWidth: 2,
        // plotBackgroundColor: 'rgba(255, 255, 255, .9)',
        // plotShadow: true,
        // plotBorderWidth: 1
      },
      credits: {
        enabled: false
      },
      // colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#3655CB', //hc default
      //    '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1']
      // colors: ['#2196F3', '#03A9F4', '#00BCD4', '#009688', '#9E9E9E', '#4CAF50',
      //    '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#607D8B', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5'] //material
      //material blue hues
      colors: colors
    });
  });
}

// export default OptimizationData
