// Imports
// Adapted from https://react-select.com/async
import React from 'react';

//Dock sidebar library
import Dock from 'react-dock';

import { Select, Spin } from 'antd';
import _ from 'lodash';
import { processingFunctions, formatFiltersForServer } from '../services/filter_helpers';

const range = (start, stop, step) => {
  let out = [];
  if (start <= stop){
    for (var x = start; x < stop; x += step) {
      out.push(x);
    }
  } else {
    for (var x = start; x > stop; x += step) {
      out.push(x);
    }
  }
  return out;
}
const filterIndex = (arr, pred) => {
  let out = [];
  for (var i = 0; i < arr.length; i++) {
    if (pred(i)){
      out.push(arr[i]);
    }
  };
  return out;
}
const sort = (arr) => {
  if (arr.length === 0) {
    return []
  } else {
    let [pivot, ...rest] = arr;
    let lt = [];
    let gt = [];
    for (var i = 0; i < rest.length; i++) {
      let x = rest[i];
      if (x <= pivot){
        lt.push(x)
      } else {
        gt.push(x)
      }
    }
    return [...sort(lt), pivot, ...sort(gt)];
  }
}

// Options
// Actually show all the filters applied?
export default class AntdMultiSelect extends React.Component {
  groupSelectedModelsByModel = selectedFilters => {
    let filters = JSON.parse(JSON.stringify(selectedFilters));
    // Group them by model
    let out = {};
    Object.keys(filters).forEach(k => {
      let modelName = k.split('__')[0];
      out[modelName] = out[modelName] || {};
      out[modelName][k] = filters[k];
    });
    return out;
  };
  render = () => {
    let {custom_fields, ...filters} = JSON.parse(JSON.stringify(this.props.selectedModels));
    if (typeof custom_fields !== 'undefined'){
      filters = {...filters, ...custom_fields};
    }
    let filtersByModel = this.groupSelectedModelsByModel(filters);
    let contents = [];
    let modelsAlphabetically = Object.keys(filtersByModel).sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );

    modelsAlphabetically.forEach(modelName => {
      let modelAttributes = Object.keys(filtersByModel[modelName]);
      let attributeRows = [];
      modelAttributes.forEach(attrName => {
        let attrValues = filtersByModel[modelName][attrName];
        let attrReps = [];
        for (var i = 0; i < attrValues.length; i++) {
          let v = attrValues[i];
          let li = <li key={`${attrName}-filter-panel-li${i}`}>{JSON.stringify(v)}</li>
          console.debug("PUSHING", v)
          attrReps.push(li);
        }
        attributeRows.push(
          <li key={`${attrName}-filter-panel`}>
            {this.props.customOptions[attrName] || attrName}
            <ul>{attrReps}</ul>
          </li>
        );
      });
      contents.push(
        <div className='col-xs-3' key={`${modelName}-filter-panel`}>
          <div className='panel panel-brand'>
            <div className='panel-heading'>
              <h3 className='panel-title'>{_.startCase(_.toLower(modelName))}</h3>
            </div>
            <div className='panel-body'>
              <ul>{attributeRows}</ul>
            </div>
          </div>
        </div>
      );
    });
    // For model in models
    // DIV:Panel
    //   For attr in filters[model]
    //   DIV:Header
    //     For selected in filters[model][attr]
    //       DIV:Attr
    return (
      <div className='bottom_bar'>
        <Dock
          position={'bottom'}
          size={this.props.size}
          dimMode={'none'}
          isVisible={this.props.isVisible || false}
          onVisibleChange={this.props.handleVisibleChange}
          onSizeChange={this.props.onSizeChange}
          fluid={this.props.fluid}
          zIndex={1030}
          className={'MYDOCK'}
        >
          {/* Small Bottom Toggle */}
          <div onClick={this.props.handleToggleDock} className='bottom_bar_button filter_toggle'>
            <i className='glyphicon glyphicon-chevron-down'></i>
          </div>
          <div className='col-xs-12'>{contents}</div>
        </Dock>
      </div>
    );
  };
}
// OPTION 1
//<div style={{position: 'absolute', width: '100%'}} className='filter_toggle'>
//  <div onClick={this.props.handleToggleDock} style={{position: "relative", 'margin-left': '50%'}}>
//    <i className='glyphicon glyphicon-chevron-down'></i>
//  </div>
//</div>
// OPTION 2
//<div onClick={this.props.handleToggleDock} className='bottom_bar_button filter_toggle'>
//  <i className='glyphicon glyphicon-chevron-down'></i>
//</div>
