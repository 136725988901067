import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../../services/Utilities';
//numeric input
import NumericInput from 'react-numeric-input';
//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
const projectId = extractToken(window.location.pathname, '/', 3);

class NewItemRatioInput extends React.Component {

  mobxState = {
    itemRatioInputValue: [], //value from input field in case it's needed in state
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    //this.fetchData();
  }
  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    //Will load data from Threshold column of discount_constraints table
    ////////////////////
    //FORM LOAD
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  render(){

    return(
        <div className={this.props.scopeCssClass}>
            <div className="row">
                <div className="col-lg-2">
                    <FontAwesomeIcon icon={['fad', 'divide']}
                    mask={['fas', 'circle']} size='4x' 
                    transform="shrink-6" color="#4CABA9"
                    style={{marginRight:'0px'}}
                    />
                </div>
                <div className="col-lg-10">
                    {/* Numeric data type */}
                    <b>Item Ratio</b>
                    <NumericInput 
                    onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
                    className="form-control"
                    name="itemRatio"
                    min={0}
                    placeholder="Enter Item Ratio"
                    />
                </div>         
            </div>        
        </div>
    )
  }

}

decorate(NewItemRatioInput, {
    mobxState: observable
})

export default observer(NewItemRatioInput);
