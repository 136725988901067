'use strict';

import React from 'react';
import { ChromePicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// https://casesandberg.github.io/react-color/#create-parent

class ButtonExample extends React.Component {
  state = {
    displayColorPicker: false
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const popover = {
      position: 'absolute',
      zIndex: '20'
    };
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    };
    return (
      <React.Fragment>
        <button type='button' className='btn btn-default' onClick={this.handleClick} style={{ 'zIndex': 'inherit' }}>
          <FontAwesomeIcon icon={['fal', 'eye-dropper']} style={{ marginTop: '7px' }} />
          {this.state.displayColorPicker ? (
            <div style={popover}>
              <div style={cover} onClick={this.handleClose} />
              <ChromePicker {...this.props} />
            </div>
          ) : null}
        </button>
      </React.Fragment>
    );
  }
}

export default ButtonExample;
