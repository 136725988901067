import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { element } from 'prop-types';

//handles rendering form components and their validation
class itemIdSelect extends React.Component {

  mobxState = {
    itemIdSelection: [], //select options from response
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //data loader

  fetchData = () => {
    ////////////////////
    //FORM LOAD
    axios.get('/v1/projects/'+this.props.projectId+'/items/index_filter', {params: {} })
    .then((response) => {
      this.mobxState.itemIdSelection = response.data.items;
    });
    //END FORM LOAD
    //////////////////
  } //end fetchData()


  render(){
    return (
      <div className='row'>
        <div className='col-lg-2'>
          <div>
            <FontAwesomeIcon
              icon={['fad', 'list']}
              mask={['fas', 'circle']}
              size='4x'
              transform='shrink-6'
              color='#4CABA9'
              style={{ marginTop: '5px' }}
            />
          </div>
        </div>
        <div className='col-lg-10'>
          <b>{this.props.fieldAlias}</b>
          <Select
            onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
            options={this.mobxState.itemIdSelection}
            closeMenuOnSelect={true}
            maxMenuHeight={400}
            placeholder={this.props.placeholder}
          />
        </div>
      </div>
    )//end return
  }//end render

}//end class

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(itemIdSelect, {
	mobxState: observable
})

export default observer(itemIdSelect);
