import React, { Component } from 'react';

//images
// import CompanyLogoWithText from '../../assets/images/simfoni_logo_1920x1080_transparent_background.png';
import CompanyLogoJustText from '../../assets/images/simfoni_text_127x47.png';
import CompanyLogoWithText from '../../assets/images/simfoni_logo_107x60.png';
import CompanyLogoWithoutText from '../../assets/images/simfoni_transparent_icon.png';

import Dock from 'react-dock';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
import { checkRole } from '../security/SecurityUtilities';

/*
props:
  position:: "Left"
  dimMode :: Boolean
  isVisible :: Boolean
  searchBar :: React Component - A searchbar injected by the module
*/

class MainSidebar extends React.Component {
  mobxState = {
    urlData: '',
    terminalUrl: null,
    analyticsUrl: null,
    sourceforceUrl: null,
    esourcing_Url: null,
    buydeskUrl: null,
    contractsPath: '/conmgt/contracts1.asp',
    workflowPath: '/prsystem/tket1.asp',
    eSourcingPath: '/landing01.asp?login01=1',
    supplierPath: '/landing01.asp?login01=1&Go2RegProf=1'
  };

  //prettier-ignore
  componentDidMount() {
    checkRole({ model: 'project', permission: 'r' }).then(res => { this.mobxState.canProjectR = res; });
    checkRole({ model: 'transaction', permission: 'r' }).then(res => { this.mobxState.canTransactionR = res; });
    checkRole({ model: 'legacy_project', permission: 'r' }).then(res => { this.mobxState.canLegacyProjectR = res; });
    checkRole({ model: 'legacy_project_insight', permission: 'r' }).then(res => { this.mobxState.canLegacyProjectInsightR = res; });
    checkRole({ model: 'legacy_ticket', permission: 'r' }).then(res => { this.mobxState.canLegacyTicketR = res; });
    checkRole({ model: 'legacy_contract', permission: 'r' }).then(res => { this.mobxState.canLegacyContractR = res; });
    checkRole({ model: 'legacy_supplier', permission: 'r' }).then(res => { this.mobxState.canLegacySupplierR = res; });
    checkRole({ model: 'legacy_project_insight', permission: 'r' }).then(res => { this.mobxState.canLegacyProjectInsightR = res; });
    //load base url for links
    axios.get('/microservices/v1/domain/ec_url').then(response => {
      this.mobxState.urlData = response.data.sourcingUrlConfig;
      this.mobxState.terminalUrl = response.data.terminal_url;
      this.mobxState.analyticsUrl = response.data.analytics_url;
      this.mobxState.sourceforceUrl = response.data.sourceforce_url;
      this.mobxState.esourcingUrl = response.data.esourcing_url;
      this.mobxState.buydeskUrl = response.data.buydesk_url;
    });
  }
  componentWillUnmount(){
    // Remove bootstrap modal
    window.$('#main-sidebar-dock').modal('hide');
  }
  extractBaseUrl = (urlStr) => {
    return (new URL(urlStr)).origin;
  }

  render() {
    return (
      <React.Fragment>
        <div className="dockpanel sidepanel modal" id="main-sidebar-dock">
          <div id='main-sidebar'>
            <div className='sidebar-section sidebar-top' style={{}}>
              <div className='sidebar-close' data-toggle="modal" data-target="#main-sidebar-dock">
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                />
              </div>
              <div className='sidebar-logo'>
                <img alt='Simfoni Logo' className='' style={{}} src={CompanyLogoJustText} />
              </div>
            </div>
            <div className='sidebar-section sidebar-middle'>
              {this.props.searchBar && <div className='sidebar-search'>{this.props.searchBar}</div>}
              <ul className='nav'>
              {this.mobxState.terminalUrl && (
                <li>
                  <a href={this.mobxState.terminalUrl}  target="_blank" rel="noopener noreferrer">
                    <i className='sidebar-link-icon'>
                      <FontAwesomeIcon className='sidebar-icon' icon={['fal', 'tachometer']} />
                    </i>
                    Terminal
                  </a>
                </li>)}
                <li className='dropdown'>
                  <a href='#' className='nav-link dropdown-toggle' data-toggle='dropdown' aria-expanded='false'>
                    <i className='sidebar-link-icon'>
                      <FontAwesomeIcon className='sidebar-icon' icon={['fal', 'file-invoice-dollar']} />
                    </i>
                    eSourcing
                    <span className='caret'></span>
                  </a>
                  <ul className='dropdown-menu'>
                    {/*this.mobxState.canTransactionR && (
                      <li>
                        <Link to={`/spend/spend`} target="_blank" rel="noopener noreferrer">Spend Analysis</Link>
                      </li>
                    )*/}
                    {this.mobxState.esourcingUrl && this.mobxState.canLegacyProjectR && (
                      <li>
                        <a href={`${this.mobxState.esourcingUrl}`}  target="_blank" rel="noopener noreferrer">eSourcing</a>
                      </li>
                    )}
                    {this.mobxState.canProjectR && (
                      <li>
                        <Link to={`/sourcing/projects`} target="_blank" rel="noopener noreferrer">Optimization</Link>
                      </li>
                    )}
                    {/*this.mobxState.esourcingUrl && this.mobxState.canLegacyContractR && (
                      <li>
                        <a href={`${this.extractBaseUrl(this.mobxState.esourcingUrl) + this.mobxState.contractsPath}`} target="_blank" rel="noopener noreferrer">Contracts</a>
                      </li>
                    )*/}
                    {/*this.mobxState.esourcingUrl && this.mobxState.canLegacyTicketR && (
                      <li>
                        <a href={`${this.extractBaseUrl(this.mobxState.esourcingUrl) + this.mobxState.workflowPath}`} target="_blank" rel="noopener noreferrer">Workflow</a>
                      </li>
                    )*/}
                    {this.mobxState.canLegacyProjectInsightR && (
                      <li>
                        <Link to={`/executive/Dashboard`} target="_blank" rel="noopener noreferrer">Insights</Link>
                      </li>
                    )}
                    {/*this.mobxState.esourcingUrl && this.mobxState.canLegacySupplierR && (
                      <li>
                        <a href={`${this.extractBaseUrl(this.mobxState.esourcingUrl) + this.mobxState.supplierPath}`} target="_blank" rel="noopener noreferrer">Supplier</a>
                      </li>
                    )*/}
                  </ul>
                </li>
                {this.mobxState.analyticsUrl && (
                  <li>
                    <a href={`${this.mobxState.analyticsUrl}`}  target="_blank" rel="noopener noreferrer">
                      <i className='sidebar-link-icon'>
                        <FontAwesomeIcon className='sidebar-icon' icon={['fal', 'analytics']} />
                      </i>
                      Analytics
                    </a>
                  </li>
                )}
                {this.mobxState.buydeskUrl && (
                  <li>
                    <a href={`${this.mobxState.buydeskUrl}`} target="_blank" rel="noopener noreferrer">
                      <i className='sidebar-link-icon'>
                        <FontAwesomeIcon className='sidebar-icon' icon={['fal', 'shopping-cart']} />
                      </i>
                      Marketplace
                    </a>
                  </li>
                )}
                {this.mobxState.sourceforceUrl && (
                  <li>
                    <a href={`${this.mobxState.sourceforceUrl}`} target="_blank" rel="noopener noreferrer">
                      <i className='sidebar-link-icon'>
                        <FontAwesomeIcon className='sidebar-icon' icon={['fal', 'database']} />
                      </i>
                      Sourcing Pipeline
                    </a>
                  </li>
                )}
                {/*<li>*/}
                {/*<Link>*/}
                {/*<i className="sidebar-link-icon"><FontAwesomeIcon className="sidebar-icon" icon={['fal', 'plus']} /></i>*/}
                {/*Add new*/}
                {/*</Link>*/}
                {/*</li>*/}
              </ul>
            </div>
            <div className='sidebar-section sidebar-bottom'>
              <ul className='nav'>
                {this.mobxState.canProjectR && (
                  <li>
                    <Link to={`/setup/projects/user_settings`}>
                      <i className="sidebar-link-icon"><FontAwesomeIcon className="sidebar-icon" icon={['fal', 'user']} /></i>
                      User Settings
                    </Link>
                  </li>
                )}
                <li>
                  <Link to={`/setup/projects/schema`} target="_blank" rel="noopener noreferrer">
                    <i className='sidebar-link-icon'>
                      <FontAwesomeIcon className='sidebar-icon' icon={['fal', 'cog']} />
                    </i>
                    Settings
                  </Link>
                </li>
                <li>
                  <Link to={`/headquarters/modules`} target="_blank" rel="noopener noreferrer">
                    <i className='sidebar-link-icon'>
                      <FontAwesomeIcon className='sidebar-icon' icon={['fal', 'chess-rook']} />
                    </i>
                    Modules
                  </Link>
                </li>
                {/*<li>*/}
                {/*<Link>*/}
                {/*<i className="sidebar-link-icon"><FontAwesomeIcon className="sidebar-icon" icon={['fal', 'user']} /></i>*/}
                {/*User Profile*/}
                {/*</Link>*/}
                {/*</li>*/}
                <li>
                  <a href={`/logout`}>
                    <i className='sidebar-link-icon'>
                      <FontAwesomeIcon className='sidebar-icon' icon={['fal', 'sign-out-alt']} />
                    </i>
                    Log Out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(MainSidebar, {
  mobxState: observable
});

export default observer(MainSidebar);
