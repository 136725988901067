import React from 'react';
import { render } from 'react-dom';
import ReactDom from 'react-dom';

//http requests
import axios from 'axios';

//filters
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, deleteIfEmpty } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//icons
import optimizeIcon from '../../../../../assets/images/icons/o_ec_optimize.png';
import spendIcon from '../../../../../assets/images/icons/o_ec_spend.png';
import insightsIcon from '../../../../../assets/images/icons/o_ec_insights.png';
import eSourcingIcon from '../../../../../assets/images/icons/o_ec_esourcing.png';
import supplierIcon from '../../../../../assets/images/icons/o_ec_supplier.png';
import contractsIcon from '../../../../../assets/images/icons/o_ec_contracts.png';
import workflowIcon from '../../../../../assets/images/icons/o_ec_workflow.png';
import luprIcon from '../../../../../assets/images/icons/lupr_square.png';

//routes
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

//security
import {checkRole} from '../../../../security/SecurityUtilities';

const _ = require('lodash'); //extra utilities

class Modules extends React.Component {

  mobxState = {
    urlData: '',
    contractsPath: '/conmgt/contracts1.asp',
    workflowPath: '/prsystem/tket1.asp',
    eSourcingPath: '/landing01.asp?login01=1',
    supplierPath: '/landing01.asp?login01=1&Go2RegProf=1'
  };

  //mount data through http requests
  componentDidMount() {
    this.fetchData();
    //role checks
    checkRole({'model':'project', 'permission':'r'}).then(res => { this.mobxState.canProjectR = res });
    checkRole({'model':'transaction', 'permission':'r'}).then(res => { this.mobxState.canTransactionR = res });
    checkRole({'model':'legacy_project', 'permission':'r'}).then(res => { this.mobxState.canLegacyProjectR = res });
    checkRole({'model':'legacy_project_insight', 'permission':'r'}).then(res => { this.mobxState.canLegacyProjectInsightR = res });
    checkRole({'model':'legacy_ticket', 'permission':'r'}).then(res => { this.mobxState.canLegacyTicketR = res });
    checkRole({'model':'legacy_contract', 'permission':'r'}).then(res => { this.mobxState.canLegacyContractR = res });
    checkRole({'model':'legacy_supplier', 'permission':'r'}).then(res => { this.mobxState.canLegacySupplierR = res });
    checkRole({'model':'legacy_project_insight', 'permission':'r'}).then(res => { this.mobxState.canLegacyProjectInsightR = res });
    checkRole({'model':'lupr', 'permission':'r'}).then(res => { this.mobxState.canLuprR = res });
  };

  fetchData = () => {
    //load data for cards and filters
    axios.get('/microservices/v1/domain/ec_url').then(response => {
      this.mobxState.urlData = response.data.sourcingUrlConfig;
      console.log('URL ', response.data);
    });
  };

  render() {
    const hcHeight = '300px';
    const iconCss = {width:'130px',height:'130px'};

    return (
      <div>
				<div className="jumbotron jumbotron_full_width" style={{maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap'}}>
					<div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-12">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#009C9C" style={{marginTop: '4px'}} />
                  <FontAwesomeIcon icon={['fal', 'building']} color="white" transform="shrink-5" style={{marginTop: '3px'}} />
                </span>
                &nbsp;
                <b className="dash_header">Procurement Headquarters </b>
              </div>
            </div>
					</div>
				</div>


				{/* MAIN CONTAINERS */}
        <div className="row">
          {this.mobxState.canTransactionR &&
            <div className="col-lg-4">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Spend Analysis </b>
                  <span className="pull-right">

                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <Link to={`/spend/spend`}>
                        <img src={spendIcon} style={iconCss} />
                      </Link>
                    </div>
                    <div className="col-lg-8">
                      Spot new opportunities across spend for savings and synergy capture.
                      <br/>
                      <br/>
                      <Link to={`/spend/spend`}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']}  style={{margin: '5px 5px 0px 0px'}} />
                        Enter Spend Analysis
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.mobxState.canLegacyProjectR &&
            <div className="col-lg-4">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>eSourcing </b>
                  <span className="pull-right">

                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <a href={this.mobxState.urlData + this.mobxState.eSourcingPath}>
                        <img src={eSourcingIcon} style={iconCss} />
                      </a>
                    </div>
                    <div className="col-lg-8">
                      Perform more sourcing in less time, with less pain, while increasing savings.
                      <br/>
                      <br/>
                      <a href={this.mobxState.urlData + this.mobxState.eSourcingPath}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']}  style={{margin: '5px 5px 0px 0px'}} />
                        Enter eSourcing
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.mobxState.canProjectR &&
            <div className="col-lg-4">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Optimization </b>
                  <span className="pull-right">

                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <Link to={`/sourcing/projects`}>
                        <img src={optimizeIcon} style={iconCss} />
                      </Link>

                    </div>
                    <div className="col-lg-8">
                      Create award scenarios with more savings and less internal resistance.
                      <br/>
                      <br/>
                      <Link to={`/sourcing/projects`}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']}  style={{margin: '5px 5px 0px 0px'}} />
                        Enter Optimization
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>{/* END ROW */}

        <div className="row">
          {this.mobxState.canLegacyContractR &&
            <div className="col-lg-4">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Contracts </b>
                  <span className="pull-right">

                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <a href={this.mobxState.urlData + this.mobxState.contractsPath}>
                        <img src={contractsIcon}  style={iconCss} />
                      </a>
                    </div>
                    <div className="col-lg-8">
                      Manage all contracts, expiration dates, & renewals proactively in one place.
                      <br/>
                      <br/>
                      <a href={this.mobxState.urlData + this.mobxState.contractsPath}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']}  style={{margin: '5px 5px 0px 0px'}} />
                        Enter Contracts
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.mobxState.canLegacyTicketR &&
            <div className="col-lg-4">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Workflow </b>
                  <span className="pull-right">

                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <a href={this.mobxState.urlData + this.mobxState.workflowPath}>
                        <img src={workflowIcon}  style={iconCss} />
                      </a>
                    </div>
                    <div className="col-lg-8">
                      Organize procurement process management activity with stakeholders.
                      <br/>
                      <br/>
                      <a href={this.mobxState.urlData + this.mobxState.workflowPath}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']}  style={{margin: '5px 5px 0px 0px'}} />
                        Enter Workflow
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.mobxState.canLegacyProjectInsightR &&
            <div className="col-lg-4">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Insights </b>
                  <span className="pull-right">

                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <Link to={`/executive/Dashboard`}>
                        <img src={insightsIcon}  style={iconCss} />
                      </Link>
                    </div>
                    <div className="col-lg-8">
                      Drive greater visibility to insights & key metrics across the organization.
                      <br/>
                      <br/>
                      <Link to={`/executive/Dashboard`}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']}  style={{margin: '5px 5px 0px 0px'}} />
                        Enter Insights
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>{/* END ROW */}

        <div className="row">
          {this.mobxState.canLegacySupplierR &&
            <div className="col-lg-4">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Supplier </b>
                  <span className="pull-right">

                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <a href={this.mobxState.urlData + this.mobxState.supplierPath}>
                        <img src={supplierIcon}  style={iconCss} />
                      </a>
                    </div>
                    <div className="col-lg-8">
                      Store supplier data and manage relationships to optimize ROI.
                      <br/>
                      <br/>
                      <a href={this.mobxState.urlData + this.mobxState.supplierPath}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']}  style={{margin: '5px 5px 0px 0px'}} />
                        Enter Supplier
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {/*{this.mobxState.canLuprR &&
            <div className="col-lg-4">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>LUPR </b>
                  <span className="pull-right">

                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <a href={this.mobxState.urlData + this.mobxState.eSourcingPath}>
                        <img src={luprIcon}  style={iconCss} />
                      </a>
                    </div>
                    <div className="col-lg-8">
                      Manage strategic supplier relationships through LUPR
                      <br/>
                      <br/>
                      <a href={'https://www.lupr.com/'}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']}  style={{margin: '5px 5px 0px 0px'}} />
                        Enter LUPR
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }*/}
        </div>{/* END ROW */}
				{/* END MAIN CONTAINERS */}

        {/* No access msg - only show if ALL access checks evaluate to false, which get reversed here causing the check here to be TRUE and show message */}
        {!this.mobxState.canTransactionR && !this.mobxState.canLegacyProjectR && !this.mobxState.canProjectR && !this.mobxState.canLegacyContractR && !this.mobxState.canLegacyTicketR && !this.mobxState.canLegacyProjectInsightR && !this.mobxState.canLegacySupplierR && !this.mobxState.canLuprR &&
          <div className="row">
            <div className="col-lg-2">
            </div>
            <div className="col-lg-8">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>ECSG Alert </b>
                  <span className="pull-right">
                  </span>
                </div>
                <div className="panel-body">
                  <div className="alert alert-danger" role="alert">
                    You do not currently have permissions to access these modules. Please contact support if you would like to receive access.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
            </div>
          </div>
        }
        {/* END ROW */}
				{/* END no access msg */}


				<br />

      </div>
    );

  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Modules, {
  mobxState: observable
});

export default observer(Modules);

// export default Modules;
