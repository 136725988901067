import React from 'react';
import ReactDom from 'react-dom';
import { render } from 'react-dom';

import socket from '../../../../../socketio';

//other components built
import Badge from '../../../../badges/Badge';
import AsyncBadge from '../../../../badges/AsyncBadge'; // ASYNC CHARTS CHANGE
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token
import SpinLoader from '../../../../loaders/SpinLoader'; //loading icon for project import

//images and documents
import instructionsPDF from '../../../../../assets/documents/Simfoni Optimize - Overview.pdf';

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//highcharts
import CustomChartRenderer from '../../../../charts/custom/CovidChartRenderer';

//router
import { Redirect } from 'react-router';
//Router
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

//notifications
import Notification from '../../../../../components/notifications/StandardNotificationRenderer';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';


//utility
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { zeroNull, deleteIfEmpty } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';
import { asyncLoadCharts } from '../../../../services/ChartUtilities'; // ASYNC CHARTS CHANGE
const _ = require('lodash'); //extra utilities



// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

class ProjectOverview extends React.Component {

  mobxState = {
    supplier_batch_bid_query: true,
    selectedDropdownOption: null,
    selectedDropdownOptionLabel: null,
    cardData: {
      projectAnalytics: {
        project_count: 0,
        bid_count: 0,
        item_count: 0
      }
    },
    templateState: {
      newProjectName: null,
      newProjectId: null,
      originalProjectId: null,
      isLoading: false, //to indicate cloning process is underway
      hasBids: null,
      projectCreated: false,
    },
    chartRenderData: [],
    tableData: {
      projects: []
    },
    templateData: {
      projects: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    customFilterModelNames: [],
    filterOptions: {},
    customFilterOptions: {},
    customOptions: {
    },
    filtersForServer: {}, // Filters in normal form to use for the server
    projectLoading: false, //for showing loading icon when importing a project
    customSchemaRowCount: null, //default amount of rows from 'custom_schemas' table
    isLoadingTableData: false, //fired off when show enteries is selected
    inDeletion: false, //toggles icon and "Deleting selected rows" caption,
    eSourcingPath: '/landing01.asp?login01=1',
    urlData: '',
  };

  //mount data through http requests
  componentDidMount() {
    //get the grid grow count than call the data loader to load data into the view
    this.getCustomRowCount();
    //load base url for links
    this.notificationRef = React.createRef(); //another pattern for calling a child function, this time, this component is the parent
    axios.get('/microservices/v1/domain/ec_url').then(response => {
      this.mobxState.urlData = response.data.sourcingUrlConfig;
    });

    socket.on("message", (data) => {
      this.fetchData();
    });

  };

  getCustomRowCount = () => {
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
      .then(response => {
        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
        this.fetchData()
      })
      .catch(console.log.bind(console)); //error catcher
  }


  //////////////////HTTP/////////////////
  //helpers for system filters
  //helpers for custom filters
  //setup filters
  // TODO:
  // - Remove system filters from customOptions once we have api call to populate aliases in place
  setupFilters = async () => {
    const pageId = 8; // CHANGE THIS FOR OTHER PAGES
    const urls = {
      system: '/v1/system_filters',
      systemOptions: '/v1/projects/system_field_options',
      custom: '/v1/custom_filters_formatted',
      customOptions: '/v1/projects/custom_field_options',
      systemOptionsSearchUrl: undefined,
      customOptionsSearchUrl: undefined
    };
    const [systemFilterResp, customFilterResp] = await Promise.all([
      axios.get(urls.system, {
        params: processingFunctions.system.filters.formatParams({
          page_id: pageId,
          enabled: true
        })
      }),
      axios.get(urls.custom, {
        params: processingFunctions.custom.filters.formatParams({
          page_id: pageId,
          enabled: true
        })
      })
    ]);

    let systemFilters = processingFunctions.system.filters.formatResp(
      systemFilterResp
    );
    let customFilters = processingFunctions.custom.filters.formatResp(
      customFilterResp
    );

    // Handle System Filters First
    let systemFilterModelNames = [];
    let systemFilterOptions = {};
    let systemFilterCustomOptions = {};
    let systemFilterWidgetTypes = {};

    systemFilters
      .filter(filter => filter.enabled)
      .forEach(filter => {
        let { name, widget_type } = filter; // Name of filter in the table, usually this is the column name of the sequelize model as well
        systemFilterModelNames.push(name); // Construct Select
        systemFilterWidgetTypes[name] = widget_type;
      });

    // Get options (name-label format) for our selects in model-nested format
    // then convert it to name-label format
    systemFilterOptions = await axios
      .post(
        urls.systemOptions,
        processingFunctions.system.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          system_fields: systemFilterModelNames
        })
      )
      .then(processingFunctions.system.options.formatResp);

    // Then Handle Custom Filters
    let customFilterModelNames = [];
    let customFilterOptions = {};
    let customFilterCustomOptions = {};
    let customFilterWidgetTypes = {};

    // Manipulate custom filters into a format where they can be handled
    // by the constructors for our filter panel
    customFilters.forEach(filter => {
      let [name, data] = _.toPairs(filter)[0]; // _.toPairs splits object into list of [key, value] pairs
      customFilterModelNames.push(name);
      customFilterCustomOptions[name] = data.field_alias; // Construct the filter
      customFilterWidgetTypes[name] = data.widget_type;
    });

    customFilterOptions = await axios
      .post(
        urls.customOptions,
        processingFunctions.custom.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          custom_fields: customFilterModelNames
        })
      )
      .then(processingFunctions.custom.options.formatResp);

    // ------------------------------------------------------------
    // Finally Setup The Filters
    // Use the current filter generation that gets triggered when filterModelNames change
    this.mobxState.filterModelNames = systemFilterModelNames;
    this.mobxState.customFilterModelNames = customFilterModelNames;

    this.mobxState.filterOptions = systemFilterOptions;
    this.mobxState.customFilterOptions = customFilterOptions;

    this.mobxState.filterWidgetTypes = systemFilterWidgetTypes;
    this.mobxState.customFilterWidgetTypes = customFilterWidgetTypes;

    this.mobxState.customOptions = {
      ...this.mobxState.customOptions,
      ...systemFilterCustomOptions,
      ...customFilterCustomOptions
    };
    this.mobxState.optionSearchFunctions = processingFunctions.general.makeSearchFunctions({
      system_filters: systemFilters,
      custom_filters: customFilters,
      system_url: urls.systemOptionsSearchUrl,
      custom_url: urls.customOptionsSearchUrl,
      selected_filters: this.mobxState.filtersForServer,
      system_fields: systemFilterModelNames,
      custom_fields: customFilterModelNames
    });
  };

  //data loader
  fetchData = () => {

    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    //use row count from "custom_schemas" table or just use value in select menu depending on the value in the state.
    var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();
    this.setupFilters();

    //merge the filter model with the amount of rows selected
    let models_with_rows = {
      ...this.mobxState.filtersForServer,
      ...{ table_rows: table_rows }
    };
    console.log(models_with_rows)

    //set up custom options for components, then get data for components
    // axios.get("/v1/ref_cmfields_labels")

    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}), // Prefer existing values
      }
      this.mobxState.customSchemaRowCount = null; //reset count so dropdown value is used instead of value from "custom_schemas"
    });

    axios
      .get('/v1/custom_schemas', { params: { name: 'project' } }) //query the project custom field schema for all projects
      .then(response => {
        //get map of field labels from API
        this.mobxState.customSchema = response.data;
        console.log('this.mobxState.customSchema.custom_schemas[0]');
        console.log(this.mobxState.customSchema.custom_schemas[0]);

        //after ag grid is set up, get the rest of the data
        //table data load
        axios.post('/v1/project_analytics', models_with_rows).then(response => {
          this.mobxState.cardData.projectAnalytics = response.data.project_analytics;
          this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
        });

        //after ag grid is set up, get the rest of the data
        //table data load
        //for non template projects
        this.mobxState.isLoadingTableData = true; //loading project rows
        axios.post('/v1/projects_index', models_with_rows).then(async (response) => {
          console.log('projects_index response.data', response.data);
          this.mobxState.isLoadingTableData = false;
          this.mobxState.tableData = response.data;
          //set the column definitions for ag grid, look into buildColumnDefs class to see options
          this.mobxState.columnDefsProjects = gridColumns.buildColumnDefs({
            customOptions: this.mobxState.customOptions,
            columnArray: ['sourcing_projects'],
            customFieldSchema: this.mobxState.customSchema.custom_schemas[0],
            dataType: 'sourcing_project' //special tag to signify that this is project data
          });
          //call child function to autofit child columns
          this.autofitChildToColumnWidth();
        })

        //for fetching projects that are templates
        axios.post('/v1/projects_template_index', models_with_rows).then(async (response) => {
          console.log('projects_template response.data', response.data);

          this.mobxState.templateData = response.data;
          //set the column definitions for ag grid, look into buildColumnDefs class to see options
          this.mobxState.columnDefsTemplates = gridColumns.buildColumnDefs({
            customOptions: this.mobxState.customOptions,
            columnArray: ['sourcing_projects_templates'],
            customFieldSchema: this.mobxState.customSchema.custom_schemas[0],
            dataType: 'sourcing_project' //special tag to signify that this is project data
          });
          //call child function to autofit child columns
          // this.autofitChildToColumnWidth();
        });
      })
      .catch(console.log.bind(console)); //error catcher

    //load custom chart data for this page (page_id 8)
    // axios
    //   .post('/v1/visualization_client_configs_index', {
    //     page_id: 8,
    //     is_orm_query: true,
    //     selected_models: this.mobxState.filtersForServer
    //   })
    //   .then(response => {
    //     this.mobxState.chartRenderData = response.data.visualizations;
    //   });
    //ASYNC CHARTS CHANGE
    asyncLoadCharts(this.mobxState, {
      page_id: 8,
      selected_models: this.mobxState.filtersForServer
    });

    //custom pivot configurations load
    axios
      .get('/v1/resources/index_filter', { params: { page_id: 8 } })
      .then(response => {
        console.log("Index Response ", response)
        this.mobxState.pivotFilter = response.data.resources;
      })
      .catch(console.log.bind(console)); //error catcher

    //flex projects load
    axios
      .get('/v1/projects/flex_index_filter', { params: {} })
      .then(response => {
        console.log('Avail projects', response.data.projects);
        this.mobxState.flexProjectFilter = response.data.projects;
      })
      .catch(console.log.bind(console)); //error catcher

  }; //end fetchData() ///////////////



  deleteRequest = toSendArr => {
    this.mobxState.inDeletion = true; //toggle delete icon and message
    console.log('IN DELETION');
    console.log('toSendArr', toSendArr);
    axios
      .post('/v1/projects/batch_destroy', { id_set: toSendArr })
      .then(response => {
        this.mobxState.inDeletion = false
        console.log(response);
        this.fetchData();
      })
      .catch((error) => {
        console.log(error)
        this.mobxState.inDeletion = false
      })
  };
  //////////////////END HTTP/////////////////

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    // In the state, pick the model based on filter that is sent and turn it into the array of values of the filter.
    // This goes as params to http request
    // Update state with selected options, removing the property if none selected
    // Equivalent to this.mobxState[filterParam] = selectedFilter.map(a => a.value)
    // but handles nested assignment
    this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, filterParam);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    if (_.isEmpty(this.mobxState.selectedOptions[filterParam])) {
      delete this.mobxState.selectedOptions[selectedFilter]
    }
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  handleCustomFilter = (filterParam, selectedFilter) => {
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels['custom_fields'] = this.mobxState.selectedModels['custom_fields'] || {};
    this.mobxState.selectedModels['custom_fields'][filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields`);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions['custom_fields'] = this.mobxState.selectedOptions['custom_fields'] || {};
    this.mobxState.selectedOptions.custom_fields[filterParam] = selectedFilter;
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields`);
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);

    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  handleChartFilter = (filterParam, selectedFilter) => {
    // KLUDGE : If the filterParam begins with 'custom_fields.' then it should be treated as if
    // a custom_filter was selected
    if (filterParam.indexOf('custom_fields.') !== -1) {
      return this.handleCustomFilter(filterParam.replace(/custom_fields./, ''), selectedFilter);
    } else {
      return this.handleFilter(filterParam, selectedFilter);
    }
  };


  //handles dropdown and chart filters
  handleClear = () => {
    //in the state, clear the model. This goes as params to http request
    this.mobxState.selectedModels = {};

    // Clear the filters we send to the server
    this.mobxState.filtersForServer = {};

    //also store the explicit cleared selections in the original form. This goes back to the filter as selected values
    //loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
    //from ["supplier", "item"] to {"supplier":[], "item":[]}
    let newSelectedOptions = { custom_fields: {} }
    this.mobxState.filterModelNames.forEach(name => {
      newSelectedOptions[name] = []
    });
    this.mobxState.customFilterModelNames.forEach(name => {
      newSelectedOptions.custom_fields[name] = []
    });
    this.mobxState.selectedOptions = newSelectedOptions;

    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  goToImportJobs = () => {
    this.props.history.push('/sourcing/projects/ImportJobs');
  };

  //this handles the selected flex project to import from the dropdown
  handleFlexProjectFilter = () => {
    var value = this.mobxState.selectedDropdownOption;
    var label = this.mobxState.selectedDropdownOptionLabel;
    if (value) {
      console.log("Importing Project")
      this.mobxState.projectLoading = true;
      console.log(value.toString());
      let urlString = '/v1/projects/flex_import/' + value.toString().trim() + '?supplier_batch_bid_query=' + this.mobxState.supplier_batch_bid_query;
      //urlString = urlString + '/'
      //'/v1/projects/flex_import/' + value.toString() + '?supplier_batch_bid_query=' + this.mobxState.supplier_batch_bid_query
      if (label) {
        urlString = urlString + '&project_name=' + label.toString();
      }
      let socketId = localStorage.getItem('socketId');
      urlString = urlString + '&socketId=' + socketId;  //add socketId to the url
      axios
        .get(urlString, { timeout: 1000 * 7200 }) //fire off the importer with the selected project with a 120 min (7200 seconds) timeout if it's a large project
        .then(response => {
          console.log('flex_import response', response.data);
          //fetch called, it reloads the table
          // this.fetchData();
          this.mobxState.projectLoading = false; //stop displaying loading icon
          // this.gridColumnApi.setColumnState(response.data.config.agState); //table state
          // this.gridColumnApi.setPivotMode(response.data.config.isPivotMode); //pivot mode on/off
          // this.gridApi.setFilterModel(response.data.config.filterModel); //pivot filters
          this.notificationRef.current.addNotification({
            type: response.data.success ? 'info' : 'danger',
            title: 'Importing Project',
            message: response.data.message,
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
          });
          
          setTimeout(() => {
            this.goToImportJobs();
          }, 1000 * 3); //redirect to Imports page after 3 seconds

        })
        .catch((e) => {
          console.log.bind(console) //error catcher
          console.log(`Problem with flex_import - ${e}`)
          this.mobxState.projectLoading = false;
          this.notificationRef.current.addNotification({
            type: 'danger',
            title: 'Failed to import project',
            message: 'Project import failed. Please try again.'
          });
        })
    }
  };

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions
      .isVisible;
  };

  // Methods for select dropdown menu to go to corresponding bid
  handleProjectDropDownSelect = (e) => {
    const { history } = this.props;
    this.mobxState.selectedDropdownOption = e.value;
    let bidURL = e.value;
    //window.location.assign(`projects/${bidURL}/bids`); //moves too bids page
    history.push(`projects/${bidURL}/bids`); //moves to bids page and allows user to use the back button
  };

  //create template method
  handleTemplateCreate = async (projectId) => {
    //hit project clone endpoint
    await axios
      .post(`/v1/projects/create_template/${projectId}`)
      .then((result) => {
        console.log(result);
        this.mobxState.templateState.newProjectId = result.data.newProjectId;
        this.mobxState.templateState.projectCreated = true;
        this.mobxState.templateState.originalProjectId = result.data.originalId;
      })
      .catch((err) => {
        console.log(err);
      });
    //get new project_id
    if (this.mobxState.templateState.projectCreated === true) {
      if (this.mobxState.templateState.newProjectId !== null || undefined) {
        //newly created project_id to clone into
        let paramsToSend = { clone_into_project_id: this.mobxState.templateState.newProjectId };
        console.log(paramsToSend);
        //create scenarios
        await axios
          .post('/v1/projects/' + this.mobxState.templateState.originalProjectId + '/scenarios/clone_all', paramsToSend)
          .then((result) => {
            console.log('Project scenarios cloned with result', result);
          })
          .catch((err) => {
            console.log(err);
          });

        //create custom_fields in new row
        await axios
          .post(
            `/v1/projects/${this.mobxState.templateState.originalProjectId}/project_field_schemas/clone_new`,
            paramsToSend
          )
          .then((result) => {
            console.log('Cloning Custom Fields ', result);
          })
          .catch((err) => {
            console.log(err);
          });

        //create suppliers
        await axios
          .post(
            `/v1/projects/${this.mobxState.templateState.originalProjectId}/project_suppliers/clone_all`,
            paramsToSend
          )
          .then((result) => {
            console.log('Bidders Cloned', result);
          })
          .catch((err) => {
            console.log(err);
          });

        //create freight_brackets and freight_bids
        //will create freight_brackets and any freight_bids that belong if they exist
        await axios
          .post(`/v1/projects/${this.mobxState.templateState.originalProjectId}/freight_brackets/clone_all`, paramsToSend)
          .then((result) => {
            console.log('Freight Brackets/ Freight Bids ', result);
            this.mobxState.submitMessage = true;
          })
          .catch((error) => {
            console.log(error);
          });

        await this.itemAndBidSubmit(this.mobxState.templateState.newProjectId);

        await this.clearTemplateState();

        await this.fetchData();
      }
    }
  };

  //only submits items or submits items and bids depending on the project
  itemAndBidSubmit = async (newId) => {
    //newly created project_id to clone into
    let newParams = { clone_into_project_id: newId };
    if (this.mobxState.templateState.hasBids === false) {
      await axios
        .post(`/v1/projects/${this.mobxState.templateState.originalProjectId}/items/clone_all`, newParams)
        .then(result => {
          console.log('Items Cloned ', result);
          // this.props.agGridReact.props.fetchData();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      axios
        .post(`/v1/projects/${this.mobxState.templateState.originalProjectId}/bids/clone_all`, newParams)
        .then(result => {
          console.log('Bids Cloned ', result);
          // this.props.agGridReact.props.fetchData();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  //clears template data after submit to keep same data from being resubmitted
  clearTemplateState = () => {
    this.mobxState.templateState.newProjectName = null;
    this.mobxState.templateState.newProjectId = null;
    this.mobxState.templateState.OriginalProjectId = null;
    this.mobxState.templateState.hasBids = null;
  }

  //end of template creation methods

  render() {
    const hcHeight = '300px';

    const match = this.props.match;

    return (
      <div>

        {/* Small Left Toggle */}
        <div onClick={this.toggleDock} className="sidebar_button filter_toggle"><i className="glyphicon glyphicon-chevron-left"></i></div>

        <div className="jumbotron jumbotron_full_width" style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}>
          <div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-12">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-2" color="#009C9C" style={{ marginTop: '4px' }} />
                  <FontAwesomeIcon icon={['fal', 'folders']} color="white" transform="shrink-6" style={{ marginTop: '3px' }} />
                </span>
                &nbsp;
                <b className="dash_header">Project Overview </b>
              </div>
            </div>
          </div>
        </div>

        {/* BADGES */}
        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-12">
                <div className="badge-container">
                  <Badge
                    badgeNumber={zeroNull(this.mobxState.cardData.projectAnalytics.project_count).toLocaleString('en-US')}
                    badgeName={'Projects'}
                    faClass={'folder-open'}
                  />
                  <Badge
                    badgeNumber={zeroNull(this.mobxState.cardData.projectAnalytics.item_count).toLocaleString('en-US')}
                    badgeName={'Items'}
                    faClass={'archive'}
                  />
                  <Badge
                    badgeNumber={zeroNull(this.mobxState.cardData.projectAnalytics.bid_count).toLocaleString('en-US')}
                    badgeName={'Bids'}
                    faClass={'gavel'}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="button-demo-kludge">
              <button className="btn btn-org pull-right" onClick={() => window.open(instructionsPDF)}>
                <FontAwesomeIcon icon={['fal', 'info-circle']} style={{ fontSize: '20px', paddingTop: '5px', paddingRight: '5px' }} />
                Optimization Guide
              </button>
              <a className="btn btn-org pull-right" href={`${this.mobxState.urlData + this.mobxState.eSourcingPath}`} target="new">
                <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} style={{ fontSize: '20px', paddingTop: '5px', paddingRight: '5px' }} />
                &nbsp;Go to eSourcing
              </a>
              {/* <a className="btn btn-org pull-right" href="https://simfoni.spendautomation.com/login/">
                <FontAwesomeIcon icon={['fal', 'shopping-cart']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}}/>
                &nbsp;Buydesk
              </a>
              <a className="btn btn-org pull-right" href="/spend/spend">
                <FontAwesomeIcon icon={['fal', 'eye']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}}/>
                &nbsp;Spend
              </a> */}
            </div>
          </div>
        </div>
        <br />

        {/* SUMMARY CHARTS */}
        <div className="row">
          <CustomChartRenderer
            handleFilter={this.handleChartFilter}
            toggleDock={this.toggleDock}
            handleClear={this.handleClear}
            chartRenderData={this.mobxState.chartRenderData}
          />
        </div> {/* END ROW */}
        {/* END SUMMARY CHARTS */}

        <div className="row">
          <div className="col-lg-4">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg">
                <b>Quick Import</b>
                <span className="pull-right">
                  <a href={`${match.url}/AdvancedImport`}>
                    <span className="filter_toggle">
                      <FontAwesomeIcon icon={['fal', 'cog']} color='#C6C9CF' transform='' style={{}} />
                    </span>
                  </a>
                  &nbsp; &nbsp;
                  <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                </span>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-lg-8">
                    {!this.mobxState.projectLoading ?
                      <Select
                        onChange={(e) => {
                          this.mobxState.selectedDropdownOption = e.value
                          this.mobxState.selectedDropdownOptionLabel = e.label
                        }}
                        id="flexProjectFilter"
                        options={this.mobxState.flexProjectFilter}
                        closeMenuOnSelect={true}
                        maxMenuHeight={400}
                        placeholder={'Import project from EC eSourcing'}
                      /> :
                      <SpinLoader
                        size={'2x'}
                        hoverTitle={'Importing Project'}
                        textStyle={{ marginLeft: '0.3125em' }}
                      />}
                  </div>
                  <div className="col-lg-4">
                    <a onClick={this.handleFlexProjectFilter} className="btn btn-success btn-block" role="button">Import</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-13">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Project Actions</b>
                  <span className="pull-right">
                    <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                  </span>
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <a href={`${match.url}/new`} className="btn btn-success btn-block" role="button">Create New Project</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Select Filter allows you to select the pivot_settings config to display*/}
            <div className="col-lg-13">
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Saved Project Analysis Configurations</b>
                </div>
                <div className="panel-body">
                  {/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
                  <Select
                    onChange={(e) => this.clickChild(e)}
                    options={this.mobxState.pivotFilter}
                    closeMenuOnSelect={true}
                    maxMenuHeight={400}
                    placeholder={'Select From List of Saved Project Tables'}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*  "noSave" prop will disable or enable rendering the grid with a save modal*/}
          {/* only shows projects that are templates */}
          <div className="col-lg-8">
            {/* AG Grid */}
            <GridRenderer
              gridHeight={"270px"}
              columnDefs={this.mobxState.columnDefsTemplates}
              rowData={this.mobxState.templateData.projects}
              noSave={true}

              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={8}

              savedState={this.mobxState.pivotState}
              isPivotMode={false}
              gridTitle={"Project Templates"}

              setClick={click => this.clickChild = click}

              deleteRequest={this.deleteRequest}
              idColumn={"id"}

              modelsToUpdateArr={[{ url: 'v1/projects/', idName: "id" }]}
              //modelsToUpdateArr={false}
              //enable auto fitting of columns
              // enableSetResizeChild={true}
              // setResize={click => this.autofitChild = click}
              enableSetResizeChildColumns={true}
              setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

              singleClickEdit={true}
              suppressClickEdit={false}

              isSaveable={true}
              isAwardable={false}
              isDeletable={true}
              useCustomSchemaRowCount={true}
              isLoadingTableData={this.mobxState.isLoadingTableData}
              inDeletion={this.mobxState.inDeletion}
            />
          </div>
        </div>
        {/* END CHART ROW */}

        {/*  "noSave" prop will disable or enable rendering the grid with a save modal*/}
        {/* PROJECT GRID */}
        <div className="row">
          <div className="col-lg-12">
            {/* AG Grid */}
            <GridRenderer
              gridHeight={"650px"}
              columnDefs={this.mobxState.columnDefsProjects}
              rowData={this.mobxState.tableData.projects}
              noSave={false}

              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={8}

              savedState={this.mobxState.pivotState}
              isPivotMode={true}
              gridTitle={"Project Details"}
              canCreateTemplate={true}

              setClick={click => this.clickChild = click}

              deleteRequest={this.deleteRequest}
              idColumn={"id"}
              handleTemplateCreate={this.handleTemplateCreate}

              modelsToUpdateArr={[{ url: 'v1/projects/', idName: "id" }]}
              //modelsToUpdateArr={false}
              //enable auto fitting of columns
              // enableSetResizeChild={true}
              // setResize={click => this.autofitChild = click}
              enableSetResizeChildColumns={true}
              setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

              singleClickEdit={true}
              suppressClickEdit={false}

              isSaveable={true}
              isAwardable={false}
              isDeletable={true}
              isLoadingTableData={this.mobxState.isLoadingTableData}
              useCustomSchemaRowCount={true}
              inDeletion={this.mobxState.inDeletion}
            />
          </div>
        </div>{/* END CHART ROW */}

        <br />
        {/* Sidebar */}
        <DockRenderer
          position={'right'}
          size={this.mobxState.dockOptions.size}
          dimMode={'none'}
          isVisible={this.mobxState.dockOptions.isVisible}
          onVisibleChange={this.handleVisibleChange}
          onSizeChange={this.handleSizeChange}
          fluid={true}
          toggleDock={this.toggleDock}
          handleClear={this.handleClear}
          // Current Filters----------------------------------
          value={this.mobxState.selectedOptions}
          // Select Names-------------------------------------
          filterModelNames={this.mobxState.filterModelNames}
          customFilterModelNames={this.mobxState.customFilterModelNames}
          // Widget Types-------------------------------------
          filterWidgetTypes={this.mobxState.filterWidgetTypes}
          customFilterWidgetTypes={this.mobxState.customFilterWidgetTypes}
          // Options------------------------------------------
          options={this.mobxState.filterOptions}
          customFilterOptions={this.mobxState.customFilterOptions}
          // Handlers-----------------------------------------
          handleFilter={this.handleFilter}
          handleCustomFilter={this.handleCustomFilter}
          // Visual options-----------------------------------
          customOptions={this.mobxState.customOptions}
          // Callbacks----------------------------------------
          closeMenuOnSelect={true}
          maxMenuHeight={400}
        />
        {/* End Sidebar */}

        {/* Notification System */}
        <Notification ref={this.notificationRef} />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ProjectOverview, {
  mobxState: observable
});

export default observer(ProjectOverview);
