import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SpinLoader extends React.Component {

  //Example Component Used with optional Message and style props/////
  /*
    <SpinLoader
      textStyle={{color:'#42A5F5'}}
      message={"Loading Message"}
      size={'2x'}
    />
  */


  render(){
    return(
      <div className="spin-loader">
          <FontAwesomeIcon
            title={this.props.hoverTitle}
            color={'#42A5F5'}
            icon={['fad', 'spinner']}
            size={this.props.size}
            pulse />
          {/*Optional Loading Caption, uses ag-grid's css style for consistency */}
          {/*Will default to 14px, #4E5155 color text */}
          <p className="ag-cell-value" style={this.props.textStyle}>
            {this.props.hoverTitle}
          </p>
      </div>
    )
  }
}

export default SpinLoader;
