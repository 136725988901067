import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///!!! USED IN THE SETUP AND PROJECTS MODULES SO FAR, BE CAREFUL EDITING, MAKE SURE IT DOESN'T BREAK OTHER FORMS!!!
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

class AliasForm extends React.Component {
  render() {
    const formDisplay = [];
    const definitions = (this.props.customSchema && this.props.customSchema[this.props.schemaLevel]) || {};
    Object.entries(definitions).forEach(([modelName, modelFields]) => {
      if (this.props.modelsToEdit.includes(modelName) === true) {
        modelFields.forEach((fieldDef, ix) => {
          formDisplay.push(
            <tr key={`${modelName}-fields-${fieldDef.field_name}-${ix}`}>
              <td>{fieldDef.field_name}</td>
              <td>
                <Input
                  defaultValue={fieldDef.field_alias}
                  onChange={e => this.props.handleFormChange([this.props.schemaLevel, modelName, ix, 'field_alias'], e.target.value)} />
              </td>
              <td></td>
            </tr>
          );
        });
      }
    });
    return (
      <div>
        <form>
          <table className='table table-striped'>
            <tbody>
              <tr>
                {/* <th style={{width:'14%'}}><b>Field In System</b></th> */}
                <th>
                  <b>Field Name</b>
                </th>
                <th>
                  <b>Display Name</b>
                </th>
              </tr>
              {formDisplay}
            </tbody>
          </table>
        </form>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(AliasForm, {
  mobxState: observable
});

export default observer(AliasForm);

// export default ProjectOverview;
