import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../../services/Utilities';
//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { element } from 'prop-types';

//handles rendering form components and their validation
class FreightBracketIdSelect extends React.Component {

  mobxState = {
    FreightBracketIdSelect: [], //select options for dropdown
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  fetchData = () => {
    axios.get(`/v1/projects/${this.props.projectId}/freight_brackets/index_filter`, {params: {} })
    .then((response) => {
      this.mobxState.FreightBracketIdSelect = response.data.freight_brackets
    });
  } //end fetchData()
  //////////////////END HTTP/////////////////

  render(){
    return (
      <div className='row'>
        <div className='col-lg-2'>
          <div>
            <FontAwesomeIcon
              icon={['fad', 'truck-container']}
              mask={['fas', 'circle']}
              size='4x'
              transform='shrink-6'
              color='#4CABA9'
              style={{ marginTop: '5px' }}
            />
          </div>
        </div>
        <div className='col-lg-10'>
          <b>{this.props.fieldAlias}</b>
          <Select
            onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
            options={this.mobxState.FreightBracketIdSelect}
            closeMenuOnSelect={true}
            maxMenuHeight={400}
            placeholder={this.props.placeholder}
          />
        </div>
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FreightBracketIdSelect, {
	mobxState: observable
})

export default observer(FreightBracketIdSelect);
