//this goes in the Freight Brack Constraints Component in the middle row

import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//icons
import merge from '../../../assets/images/icons/merge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { element } from 'prop-types';

//handles rendering form components and their validation
class FreightBidSelection extends React.Component {

//taken from scope selection renamed to qulitative selection

  mobxState = {
    freightBidSelect: [], //options for select box
    freightBidValueSelect: [], //options for select box
    showFreightBidValueSelect: false, //conditional render for select box
    freightBidValueDefaultValue: null, //default selected value
    freightBidValueSelectedValue: null
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  fetchData = () => {
    ////////////////////
    //FORM LOAD
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/freight_bracket_constraints', {params: {} })
    .then((response) => {
      this.mobxState.freightBidSelect = response.data.freight_bid_levels;  
    });
    //END FORM LOAD
    //////////////////
  } //end fetchData()

  //handle selecting the qualitative in the dropdown and update qualitative_value dropdown in the state

  handleFreightBidSelection = (selectedFreightBid) => {

    console.log('selectedFreightBid', selectedFreightBid.value);
    //don't show dropdown for qualitative_value on overall qualitative
    if (selectedFreightBid.value != 'overall') {
      this.mobxState.showFreightBidValueSelect = true;
    } else {
      //hide dropdown
      this.mobxState.showFreightBidValueSelect = false;
    }

    //update form state in parent component
    this.props.handleFormChange('custom_field_name', selectedFreightBid);

    //NEED TO GIVE REACT SELECT DEFAULT VALUE
    //clear qualitative_value selected state in parent and here in child because switching qualitatives should clear selection for a new qualitativeValue list related to that qualitative
    this.mobxState.freightBidValueSelectedValue = null;
    this.props.handleFormChange('custom_field_value', {name:null});

    //get the Value dropdown list by sending a request with the selected freight bid value in the params
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/freight_bracket_constraint_values', {params: {attribute: selectedFreightBid.value} })
    .then((response) => {
      //console.log(response.data.qualitative_values)
      this.mobxState.freightBidValueSelect = response.data.list_values; //sorted values for dropdown from micro service
      //console.log(list_values)
    });
  }

  //handle local state and callback
  handleFreightBidValueSelection = (freightBidName, selectedFreightBidValue) => {
    //change the state in the parent with callback
    this.props.handleFormChange(freightBidName, selectedFreightBidValue);
    //local state, in order to handle the value
    this.mobxState.freightBidValueSelectedValue = selectedFreightBidValue;
  }

  render(){
    console.log(this.mobxState.freightBidSelect)
    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }
    
    return (
      <div>
        <div className={this.props.scopeCssClass}> {/* kept css styles unchanged to maintain consistency */}
          <div className="row">
            <div className="col-lg-2">
              <div>
                <FontAwesomeIcon icon={['fad', 'columns']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{marginRight:'20px'}}/>
              </div>
            </div>
            <div className="col-lg-10">
              {/* main form that is always rendered */}
              <b>Custom Field Selection</b>
              <Select
                onChange={this.handleFreightBidSelection}
                options={this.mobxState.freightBidSelect}
                closeMenuOnSelect={true}
                maxMenuHeight = {400}
                placeholder={'Select custom fields'}
              />
            </div>
          </div>
        </div>

        <div className={this.props.scopeValueCssClass}>

        {/* Dependent dropdown menu that appears after selection is made in the first menu */}
          {this.mobxState.showFreightBidValueSelect && 

              <div>
                <div className={'pull-left'}>
                  <img src={merge} style={{transform: 'rotate(90deg)', marginTop: '25px'}} />
                </div>

                <div className={''}>
                  <b>Custom Field Value</b>
                  <Select
                    onChange={(e) => this. handleFreightBidValueSelection('custom_field_value', e)}
                    options={this.mobxState.freightBidValueSelect}
                    closeMenuOnSelect={true}
                    filterOption={() => true}
                    maxMenuHeight = {400}
                    placeholder={'Specify data value to apply constraint to'}
                    defaultValue={this.mobxState.freightBidValueDefaultValue}
                    value={this.mobxState.freightBidValueSelectedValue}
                  />
                </div>
              </div>
          }
        </div>
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FreightBidSelection, {
	mobxState: observable
})

export default observer(FreightBidSelection);

// export default FreightBidSelection
