import React from "react";
import { render } from "react-dom";

//other components built
import Badge from "../../../../badges/Badge";
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns

//filters
import DockRenderer from "../../../../filters/DockRenderer";
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls
import Select from "react-select"; //select box for filter

//ag grid
import GridRenderer from "../../../../grids/GridRenderer";

//http requests
import axios from "axios";
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import { zeroNull, extractToken } from "../../../../services/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//router
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

//forms
import FreightBracketName from "../../../../forms/sourcing/FreightBracketName";
import FreightBracketMin from "../../../../forms/sourcing/FreightBracketMin";
import FreightBracketMax from "../../../../forms/sourcing/FreightBracketMax";

//file importation form
import SpreadSheetImport  from '../../../../forms/sourcing/FileImportation/SpreadSheetImport'

//react reveal for to reveal components and validations
import Bounce from "react-reveal/Fade";

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

class FreightBrackets extends React.Component {

  // Set the project url by splitting up the pathname by / and selecting the proper location of the project  Id in url
  projectId = extractToken(window.location.pathname, "/", 3);

  mobxState = {
    formState: {
      bracketAliasName: "", //alias name input
      freightBracketMin: null,
      freightBracketMax: null,
      formValid: false,
      showHelperIcons: false,
    },
    customOptions: {
    },
    cardData: {},
    tableData: {
      "freight_brackets": []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2,
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [
    ], //autobuilds filters and clear functions
    customSchemaRowCount: null, //default amount of rows from 'custom_schemas' table
    isLoadingTableData: false, //fired off when show enteries is selected
    inDeletion: false, //toggles icon and "Deleting selected rows" caption
  };

  //mount data through http requests
  componentDidMount() {
    //get the grid grow count then call the data loader to load data into the view
    this.getCustomRowCount();
  }

  //////////////////HTTP/////////////////
  getCustomRowCount = () => {
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
      .then(response => {
        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
        this.fetchData()
      })
      .catch(console.log.bind(console)); //error catcher
  }
  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();

    //merge the filter model with the amount of rows selected
    let models_with_rows = {...this.mobxState.selectedModels, ...{ "table_rows": table_rows }};
    // console.log("ROWS ", table_rows)

    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}), // Prefer existing values
      }
    });

    axios.get('/v1/projects/'+this.projectId+'/project_field_schemas', { params: { 'project_id': this.projectId } })
      .then((response) => {
        //get map of field labels from API
        this.mobxState.customOptions = {
          ...this.mobxState.customOptions,
          ...(response.data || {})
        };

        //after ag grid is set up, get the rest of the data
        //table data load
        this.mobxState.isLoadingTableData = true //loading project rows
        axios.get(`/v1/projects/${this.projectId}/freight_brackets`, {params: models_with_rows,})
          .then((response) => {
            console.log("data response:");
            console.log(response);

            this.mobxState.tableData = response.data;
            this.mobxState.isLoadingTableData = false;

            //set the column definitions for ag grid, look into buildColumnDefs class to see options
            this.mobxState.columnDefsFreightBrackets = gridColumns.buildColumnDefs({
              "customOptions": this.mobxState.customOptions,
              "columnArray": ['freight_brackets'],
              "customFieldSchema": this.mobxState.customOptions.project_field_schemas[0],
              "dataType": 'freight_bracket',
            });

            //autofit the table columns (hits the child function), this runs after the columns were created
            this.autofitChild();
          })
      })
      .catch(console.log.bind(console)); //error catcher

    //custom pivot configurations load
    axios.get("/v1/resources/index_filter", { params: { "page_id": 402 } })
      .then((response) => {
        this.mobxState.pivotFilter = response.data.resources;
        this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
      });
  }; //end fetchData()

  //FORM HANDLING FUNCTIONS
  //handle submission by sending the formState to server
  handleFormSubmit = (event) => {
    axios.post(`/v1/projects/${this.projectId}/freight_brackets`, {
        name: this.mobxState.formState.bracketAliasName,
        min_bracket: this.mobxState.formState.freightBracketMin,
        max_bracket: this.mobxState.formState.freightBracketMax,
      })
      .then((response) => {
        console.log(response);
        this.fetchData();
        this.mobxState.formState.showHelperIcons = false;
      });
  };

  //text input state handler
  handleTextInput = (event) => {
	console.log("handleFormChange with params:", event);
    let inputValue = event.target.value;
    this.mobxState.formState.bracketAliasName = inputValue;
  };

  handleFormChange = (field, value) => {
    console.log("handleFormChange with params:", field, value);
    this.mobxState.formState[field] = value;
    //call validationResult
    this.validateForm();
  }

  //reloads grid after sheet is submitted
  handleSheetSubmit = () => {
    console.log("Reloading Grid");
    this.fetchData();
};

  //react-select form state handler

  validateForm = () => {
    //checks all form fields have values except for the scope and scope value
    let formConditionsMet =
      this.mobxState.formState.bracketAliasName != null &&
      this.mobxState.formState.freightBracketMin != null &&
      this.mobxState.formState.freightBracketMax != null;

    // form conditions
    let formValidPath = formConditionsMet;

    console.log("formConditionsMet", formConditionsMet);
    console.log(this.mobxState.formState);

    //check that form is valid and make the state aware of that
    if (formValidPath) {
      this.mobxState.formState.formValid = true;
      this.mobxState.formState.showHelperIcons = true;
    } else {
      this.mobxState.formState.formValid = false;
      this.mobxState.formState.showHelperIcons = false;
    }
    console.log(this.mobxState.formState);
  };
  //END FORM HANDLING

  deleteRequest = (toSendArr) => {
    this.mobxState.inDeletion = true;
    console.log("IN DELETION");
    axios.post(`/v1/projects/${this.projectId}/freight_brackets/batch_destroy`, { "id_set": toSendArr })
      .then((response) => {
        this.mobxState.inDeletion = false
        console.log(response);
        this.fetchData();
      });
  }

  //////////////////END HTTP/////////////////

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    console.log("Handle Filter Callback: ");
    console.log("selectedFilter ", selectedFilter);
    console.log("filterParam ", filterParam);
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels[filterParam] = selectedFilter.map( (a) => a.value );
    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //handles dropdown and chart filters
  handleClear = () => {
    //in the state, clear the model. This goes as params to http request
    this.mobxState.selectedModels = {};
    //also store the explicit cleared selections in the original form. This goes back to the filter as selected values
    //loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
    //from ["supplier", "item"] to {"supplier":[], "item":[]}
    const optionsClear = {};
    for (const key of this.mobxState.filterModelNames) {
      optionsClear[key] = [];
    }
    this.mobxState.selectedOptions = optionsClear;
    //fetch called, it reads the filter params from state
    this.fetchData();
  }

  //for sidebar dock to change sizes
  handleSizeChange = (size) => {
    this.mobxState.dockOptions.size = size;
  }

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  }

  render() {
    const hcHeight = "300px";

    return <div>
        {/* Small Left Toggle
        <div onClick={this.toggleDock} className="sidebar_button filter_toggle">
          <i className="glyphicon glyphicon-chevron-left" />
        </div>
        */}

        <div className="jumbotron jumbotron_full_width">
          <div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-5">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform="grow-3"
                    color="#009C9C"
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fal', 'truck-container']}
                    color="white"
                    transform="shrink-5"
                    style={{ marginTop: '4px' }}
                  />
                </span>
                &nbsp;
                <b className="dash_header">Manage Freight Brackets</b>
              </div>
              <div className="col-sm-2" />
              <div className="col-sm-3" />
              <div className="col-sm-2 gps">
                <FontAwesomeIcon icon={['fad', 'location-arrow']} color="#53C8C5" transform="grow-15 left-10" />
                <Link to={`/sourcing/projects/${this.projectId}/scenarios`} className='link_style'>Next: Constraints Overview</Link>
              </div>
            </div>
          </div>
        </div>

        {/* BADGES */}

        {/* FORM */}
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg clearfix">
                <b>Create New Freight Bracket Alias</b>
                <span className="pull-right">
                  <Bounce left when={this.mobxState.formState.showHelperIcons}>
                    <span>
                      <FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size="2x" transform="down-3 grow-10" color="#53C8C5" style={{ marginRight: '15px' }} />
                    </span>
                  </Bounce>
                  <button type="button" disabled={!this.mobxState.formState.formValid} className="btn btn-success btn-md" onClick={this.handleFormSubmit}>
                    <FontAwesomeIcon icon={['fal', 'save']} />&nbsp; Save Alias
                  </button>
                  &nbsp;
                  {/*
                    <button type="button" className="btn btn-primary btn-md" onClick={this.handleFormSubmit}>
                    <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Bulk Add
                    </button>
                  */}

                  {/* //file importation button */}
                  <button
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Import constraints from an Excel SpreadSheet or CSV file."
                    className="btn btn-warning"
                    type="button"
                    data-toggle="collapse"
                    data-target="#importForm"
                    aria-expanded="false"
                    aria-controls="importForm">
                    <FontAwesomeIcon icon={['fal', 'file-import']} />&nbsp; Import Excel/CSV
                  </button>
                </span>
              </div>

              {/* collapsing form for importing data from external excel or csv file */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="collapse multi-collapse" id="importForm">
                    <div className="card card-body">
                      {/* holds import excel and csv import forms and buttons */}
                      <SpreadSheetImport
                        requiredCols={['name', 'min bracket', 'max bracket']}
                        tableName={'freight_brackets'}
                        handleSheetSubmit={this.handleSheetSubmit}
                        systemFieldCols={['name', 'min_bracket', 'max_bracket']}
                        projectId={this.projectId}
                        isConstraint={false}
                        scenarioId={this.scenarioId}
                        optionalCols={[]}
                         />
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-body">
                <form>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'top_panel_info'}>Enter the exact names of your freight bracket aliasas</div>
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-lg-4">
                      <b>Alias Name</b>
                      <input type="text" name="bracketAliasName" placeholder="Enter alias name" formfield={'bracketAliasName'} className="form-control" onChange={this.handleTextInput} />
                    </div>
                    <div className="col-lg-4">
                      <FreightBracketMin formField={'freightBracketMin'} handleFormChange={this.handleFormChange} />
                    </div>
                    {/* end col-lg-4*/}
                    <div className="col-lg-4">
                      <FreightBracketMax formField={'freightBracketMax'} handleFormChange={this.handleFormChange} />
                    </div>
                    {/* end col-lg-4*/}
                  </div>
                  {/* End row */}
                  <br />
                </form>
              </div>
            </div>
          </div>
          {/* END col-sm-12*/}
        </div>
        {/* END row*/}
        {/* END FORM*/}

        {/* PROJECT GRID */}
        <div className="row">
          <div className="col-lg-12">
            {/* AG Grid */}

            <GridRenderer gridHeight={'550px'}
              columnDefs={this.mobxState.columnDefsFreightBrackets}
              rowData={this.mobxState.tableData.freight_brackets}
              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={402}
              savedState={this.mobxState.pivotState}
              isPivotMode={true}
              gridTitle={'Freight Brackets'}
              setClick={click => (this.clickChild = click)}
              useCustomSchemaRowCount={true}
              isLoadingTableData={this.mobxState.isLoadingTableData}
              enableSetResizeChild={true}
              setResize={click => (this.autofitChild = click)}
              deleteRequest={this.deleteRequest}
              idColumn={'id'}
              modelsToUpdateArr={[{ url: `v1/projects/${this.projectId}/freight_brackets/`, idName: 'id' }]}
              singleClickEdit={true}
              suppressClickEdit={false}
              isSaveable={true}
              isAwardable={false}
              isDeletable={true}
              inDeletion={this.mobxState.inDeletion}
              />

          </div>
        </div>
        {/* END CHART ROW */}

        <br />
        {/* Sidebar */}
        <DockRenderer position={'right'} size={this.mobxState.dockOptions.size} dimMode={'none'} isVisible={this.mobxState.dockOptions.isVisible} onVisibleChange={this.handleVisibleChange} onSizeChange={this.handleSizeChange} fluid={true} toggleDock={this.toggleDock} handleClear={this.handleClear} value={this.mobxState.selectedOptions} options={this.mobxState.cardData} closeMenuOnSelect={true} maxMenuHeight={400} handleFilter={this.handleFilter} customOptions={this.mobxState.customOptions} filterModelNames={this.mobxState.filterModelNames} />
      </div>;
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FreightBrackets, {
  mobxState: observable,
});

export default observer(FreightBrackets);

//export default FreightBrackets;
