import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

class LaunchScenarioRenderer extends Component {
  // Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
  projectId = extractToken(window.location.pathname, '/', 3);
  mobxState = {
    scenarioStatusId: this.props.agGridReact.props.rowData[0].scenario_status_id
  };

  constructor(props) {
    super(props);
  }

  //mount data through http requests
  componentDidMount() {
    //console.log(this.props.agGridReact.props.rowData[0].scenario_status_id);
  }

  handlePlayClick = () => {
    //send the scenario to be evaluated
    axios.post('/v1/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/launch')
    .then(response => {
      console.log('Solver response');
      console.log(response);
      //call fetch data to renew screen with new status
      this.props.agGridReact.props.fetchData();
      console.log('Props', this.props.agGridReact.props.rowData[0].scenario_status_id);
      //this.mobxState.scenarioAutofill =  ['Lowest Price', '1 Supplier Per DC', '5% Discount']; //response.data.scenarios;
    })
  }

  render() {

    return (
      <span>
        {this.props.data.scenario_status_id != 2 &&
          <span className="fa-layers fa-fw fa-2x link_style">
            <FontAwesomeIcon icon={['fal', 'play-circle']}
            style={{marginTop: '6px'}}
            onClick={this.handlePlayClick} />
          </span>
        }
        {this.props.data.scenario_status_id == 2 &&
          <span className="fa-layers fa-fw fa-2x link_style">
            <FontAwesomeIcon icon={['fal', 'spinner']}
            spin
            style={{marginTop: '6px'}}
          />
          </span>
        }
      </span>
    );
  }
}

decorate(LaunchScenarioRenderer, {
	mobxState: observable
})

export default observer(LaunchScenarioRenderer);
