import React from 'react';
import Select from 'react-select'; //select box for filter

import { Input } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ItemCustomFieldInput extends React.Component {
  render(){
    return(
      <div className='row'>
        <div className='col-lg-2'>
          <div>
            <FontAwesomeIcon
              icon={['fad', 'warehouse']}
              mask={['fas', 'circle']}
              size='4x'
              transform='shrink-6'
              color='#4CABA9'
              style={{ marginTop: '5px' }}
            />
          </div>
        </div>
        <div className='col-lg-10'>
          <b>{this.props.fieldAlias}</b>
          <Input
            name='item custom field'
            placeholder={this.props.placeholder}
            className='form-control'
            onChange={(e) => this.props.handleFormChange(this.props.formField, e.target.value)}
          />
        </div>
      </div>
    )//end return
  }//end render

}//end class

export default ItemCustomFieldInput;
