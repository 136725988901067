import React, { Component } from 'react';

// PROPS
// idPrefix :: Roughly name of component (e.g. "spend"), should be unique
// suppress :: Whether to suppress rendering this element
// icon     :: Icon to show for element
// title    :: Title To Show For Element
// children :: Child components (Usually passed within tag, not as props)

class SidebarDropdown extends React.Component {
  static defaultProps = {
    shouldShow: true
  };
  render() {
    let dropdownId = `${this.props.idPrefix}Submenu`;
    let wrapItem = (el, ix) => <li key={`${dropdownId}-child-${ix}`}>{el}</li>;
    let childArray = React.Children.toArray(this.props.children); // Proper way to map props.children
    let wrappedChildren = childArray.map((el, ix) => wrapItem(el, ix));

    if (this.props.shouldShow) {
      return (
        <div className='arrow_dropdown'>
          <a href={'#' + dropdownId} data-toggle='collapse' aria-expanded='false'>
            {this.props.icon}
            {this.props.title}
          </a>
          <ul className='collapse list-unstyled' id={dropdownId}>
            <div>{wrappedChildren}</div>
          </ul>
        </div>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

export default SidebarDropdown;
