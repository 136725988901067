// Imports
// Adapted from https://react-select.com/async
import React from 'react';
import ReactDOM from 'react-dom';

import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import isEqual from 'lodash';

import axios from 'axios';
import { forgetfulMemoize } from '../services/Utilities';

const { Option } = Select;

// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex#6969486
// escapeRegExp :: str -> Regex
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
// makeSelectFilter :: str -> (str -> bool)
function makeSelectFilter(userInput) {
  let regex = new RegExp(escapeRegExp(userInput), 'g');
  return s => regex.test(s);
}

export default class AntdSearchedMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    // State declaration
    this.state = {
      searchTerm: '', // What value is in the search input?
      searchOptions: [], // What options are we showing (if not default)
      fetching: false // Are we currently fetching options?
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  // Delegate to optionSearch if passed down, otherwise provide empty
  // optionSearch :: value => Promise => [Options in react-select format]
  optionSearch = value => {
    if (typeof this.props.optionSearch === 'undefined') {
      return new Promise((resolve, reject) => {
        resolve([]);
      });
    } else {
      return this.props.optionSearch(value);
    }
  };

  handleSearch = debounce(value => {
    this.setState({ searchTerm: value, fetching: true });
    let promises = [];
    if (value !== '') {
      // Use Provided Search
      // And update options using resolved promise
      // console.log(`handleSearch(${JSON.stringify(value)})`);
      let promise = this.optionSearch(value);
      promise.then(resp => {
        // console.log('Received options for search', value, 'in value+label format');
        if (this._isMounted && this.state.searchTerm === value) {
          this.setState({ searchOptions: resp || [] });
        }
      });
      promises.push(promise);
    }
    Promise.all(promises).then(() => {
      this.setState({ fetching: false });
    });
  }, 500); // Only fire once no change occurs for 100 milliseconds
  handleBlur = () => {
    this.setState({ searchTerm: '' });
  };

  handleChange = values => {
    // Select just the label and value properties when updating
    let mappedValues = values.map(x => ({ value: x.value, label: x.label }));
    this.props.onChange(mappedValues);
  };

  render = () => {
    let currentOptions = this.state.searchTerm === '' ? this.props.options || [] : this.state.searchOptions;
    let currentValues = this.props.value || [];

    return (
      <Select
        labelInValue
        showArrow
        defaultActiveFirstOption={false}
        onBlur={this.handleBlur}
        listHeight={this.props.maxMenuHeight || 256}
        placeholder={this.props.placeholder}
        mode='multiple'
        allowClear={true}
        options={currentOptions}
        value={currentValues}
        style={{ width: '100%', ...(this.props.style || {}) }}
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={this.state.fetching ? <Spin size='small' /> : undefined}
        dropdownClassName={'antd-filter-dropdown'}
        getPopupContainer={(
          () => document.querySelector(this.props.popupContainerSelector || '#filter-lists')
        )}
      />
    );
  };
}
