import React from 'react';
import { render } from 'react-dom';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { zeroNull, extractToken, deleteIfEmpty, newJsonFieldSchema } from '../../../services/Utilities';
const _ = require('lodash'); //extra utilities

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class QuickLaunchIncumbentsForm extends React.Component {
  // Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
  projectId = extractToken(window.location.pathname, '/', 3);
  modelName = 'bid';
  mobxState = {
    formState: {  scenarioName: '' 
                  , scenarioID  : 0  
                  , constraintType: 'competition_constraint'  
                  , scope: 'overall'  
                  , scopeValue: null  
                  , awardOperator: '<='  
                  , base: 'quantity'  
                  , award: 0  
                  , maxSuppliers: 0
                  , minSuppliers: 1
                  , formValid: false  },
    addField: {   fieldLevel        : 'bid_level'  
                , fieldKey          : 'custom_field_'  
                , fieldName         : 'custom_field_'  
                , fieldAlias        : 'Incumbent'  
                , isIncumbentLable  : 'Yes'  
                , notIncumbentLable : 'No'  
                , customFieldNumber : 0  
                , threshold         : 80  
                , scenarioName      : 'Keep Incumbents autogenerated'  
                , fieldExists       : false  },
    projectFieldSchema: {
                  item_level            : {}
                , bid_level             : {}
                , freight_bid_level     : {}
                , product_decimal_spots : 2
                , freight_decimal_spots : 2 },
  };

	//mount data through http requests
	componentDidMount() {	} ; 

  //////////////////HTTP//////////////////
  buildRunIncumbentsScenario = (event) => {
      //
      // create scenario
      axios.post('/v1/projects/'+this.projectId+'/scenarios', {"name":'Keep '+this.mobxState.addField.threshold+'% Incumbents autogenerated'})
      .then((response) => {
        // console.log('QuickLaunchIncumbentsForm: buildRunIncumbentsScenario post scenario response : ',response);
        //
        // does the new scenario have an ID ?
        this.mobxState.formState.scenarioID = 0 ;
        if (response.data.id > 0) {this.mobxState.formState.scenarioID = response.data.id} ;
        //
        //send the scenario to be evaluated
        if (this.mobxState.formState.scenarioID > 0) {
          axios.post('/v1/projects/' + this.projectId + '/scenarios/' + this.mobxState.formState.scenarioID + '/qualitative_constraints', {
              scope: 'overall'
              , scope_value: null
              , award_operator: '>='
              , award: this.mobxState.addField.threshold 
              , base: 'cost'
              , custom_field_name : this.mobxState.addField.fieldName
              , custom_field_value: this.mobxState.addField.isIncumbentLable
            })
          .then((response) => { 
            // console.log('QuickLaunchIncumbentsForm: buildRunIncumbentsScenario post qualitative_constraints response : ', response);
            //
            //send the scenario to be evaluated
            axios.post('/v1/projects/'+this.projectId+'/scenarios/'+this.mobxState.formState.scenarioID+'/launch')
            .then(response => {
              // console.log('QuickLaunchIncumbentsForm: buildRunIncumbentsScenario : run scenario ',this.mobxState.formState.scenarioID);
              // console.log(response);
              window.location.pathname = '/sourcing/projects/'+this.projectId+'/scenarios' // <<<<<<<<<<<<
            }) // end then ... run scenario
          }) ; // end then ... qualitative_constraints
        } ; // end if ...scenarioID > 0
      }) // end then ... post scenario response
  }; // end buildRunIncumbentsScenario

  handleAddNamedField = () => { // add bid_level custom field 'incumbentYN', build, run a Keep Incumbent scenario
    let fieldLevel = this.mobxState.addField.fieldLevel ; // only bid_level has been tested here
    let fieldName = this.mobxState.addField.fieldName ;
    this.mobxState.addField.scenarioName = '' ; // re-initialize so it doesn't go sideways
    //
    //get all schema fields in array
    let schemaFields = this.mobxState.projectFieldSchema[fieldLevel]
      ? Object.keys(this.mobxState.projectFieldSchema[fieldLevel])
      : []; //make sure to null handle w empty array if schema doesn't exist
    let schemaFieldNumbers = [];
    let lastSchemaFieldNumber = 0 ;
    // 
    //loop thru custom fields
    schemaFields.forEach(element => {
      //push in the number only of the custom field
      schemaFieldNumbers.push(Math.trunc(extractToken(element, '_', 2)));
    });
    //
    //set up custom options for components, then get data for components
    axios
      .get('/v1/projects/' + this.projectId + '/project_field_schemas', { params: { project_id: this.projectId } })
      .then(response => { // console.log('QuickLaunchIncumbentsForm: handleAddNamedField: read/change/save projectFieldSchema to/from mobxState') ;
        //
        //add to customOptions
        this.mobxState.customOptions = { ...this.mobxState.customOptions, ...response.data };
        //
        //store the projectFieldSchema of the project 
        this.mobxState.projectFieldSchema = response.data.project_field_schemas[0];
        let bid_level_field_schema = response.data.project_field_schemas[0].bid_level ;
        // console.log('QuickLaunchIncumbentsForm: handleAddNamedField: this.mobxState.projectFieldSchema', this.mobxState.projectFieldSchema) ;
        //
        //get bid level schema fields in array
        schemaFields = bid_level_field_schema
          ? Object.keys(bid_level_field_schema)
          : []; //make sure to null handle w empty array if schema doesn't exist
        //
        //loop thru custom fields to extract field numbers 
        //and check whether the needed field alias is already there
        schemaFieldNumbers = [0]; // in case there are no bid_level fields, include 0
        let alias_found = false ;
        schemaFields.forEach(element => { 
          schemaFieldNumbers.push(Math.trunc(extractToken(element, '_', 2))); // extract field number, insert in schemaFieldNumbers
          //
          if (bid_level_field_schema[element].field_alias == this.mobxState.addField.fieldAlias) {
            alias_found = true ;
            this.mobxState.addField.fieldName = bid_level_field_schema[element].field_name ;
            // console.log('QuickLaunchIncumbentsForm: handleAddNamedField: this.mobxState.addField.fieldName', this.mobxState.addField.fieldName ) ;
          } ; // end if (bid_level_field_schema...)
        }); // end schemaFields.forEach(element => {
        // console.log('QuickLaunchIncumbentsForm: handleAddNamedField: alias_found', alias_found ) ;
        this.mobxState.addField.fieldExists = alias_found ;
        //
        // keep going if incumbent alias not found
        if (! this.mobxState.addField.fieldExists) {
          //
          //new field name, based on 'last' field number 
          lastSchemaFieldNumber = _.last(_.sortBy(schemaFieldNumbers)); // the highest number
          let newFieldNumber = lastSchemaFieldNumber + 1 ;
          this.mobxState.addField.customFieldNumber = newFieldNumber ;
          let newCustomFieldName = 'custom_field_'.concat(newFieldNumber);
          this.mobxState.addField.fieldName = newCustomFieldName ;
          // console.log('QuickLaunchIncumbentsForm: handleAddNamedField: newCustomFieldName = ',newCustomFieldName) ;
          //
          //add new field schema to the bid level field schema
          let newFieldSchema = newJsonFieldSchema(newCustomFieldName, newFieldNumber);
          newFieldSchema[newCustomFieldName].field_alias = this.mobxState.addField.fieldAlias ;
          bid_level_field_schema[newCustomFieldName] = newFieldSchema[newCustomFieldName] ;
          //
          //update mobxState with new bid level field
          this.mobxState.projectFieldSchema[fieldLevel] = bid_level_field_schema ;
          // console.log('QuickLaunchIncumbentsForm: handleAddNamedField: (B) this.mobxState.projectFieldSchema['+fieldLevel+'] = ',this.mobxState.projectFieldSchema[fieldLevel]) ;
          //
        } ; // end if not this.mobxState.addField.fieldExists
        //
      }) // end then(response => { '...read/change/save projectFieldSchema to/from mobxState'
      //
      .then( () => { // put projectFieldSchema
        //
        // keep going if incumbent alias not found
        if (! this.mobxState.addField.fieldExists) {
          // console.log('QuickLaunchIncumbentsForm: handleAddNamedField: this.mobxState.projectFieldSchema B = ',this.mobxState.projectFieldSchema) ;
          axios
            .put('/v1/projects/' + this.projectId + '/project_field_schemas/' + this.mobxState.projectFieldSchema.id, {
              [fieldLevel]: this.mobxState.projectFieldSchema[fieldLevel]
            }) //query the project custom field schema for all projects
            .then(response => { console.log(response); })
            .catch(console.log.bind(console)); //error catcher;
        } ; // end if not this.mobxState.addField.fieldExists
        //
      }) // end then(response => { // put projectFieldSchema
      //
      .then( () => { // populate column IncumbentFT
        // console.log('QuickLaunchIncumbentsForm: populateIncumbentYN: this.mobxState.projectFieldSchema ', this.mobxState.projectFieldSchema) ;
        this.populateIncumbentYN() ;
      }) // end populate column IncumbentFT
      
      .then( () => { // i) create scenario;  ii) add constraint;  iii) run it;  iv) go to scenario page
        this.buildRunIncumbentsScenario() ;
      }) // end i) create scenario...
      
      .catch(console.log.bind(console)) ; //error catcher
  } ; // end handleAddNamedField()

  // populate bids.custom_fields.incumbentYN with 'Yes'/'No' values (routes/v1/bids.js)
  populateIncumbentYN = (event) => { // console.log('QuickLaunchIncumbentsForm: populateIncumbentYN: started ') ;
    axios
      .put('/v1/projects/' + this.projectId + '/bids_incumbent_ids/' + this.mobxState.addField.fieldName) // get bid IDs and custom_fields for incumbent and non-incumbent bids
      .then(response => { // console.log('QuickLaunchIncumbentsForm: populateIncumbentYN: response.data.bid_ids[0] ', response.data.bid_ids[0]) ;
      }) // end then ...response.data.bids
      .catch(console.log.bind(console)) //error catcher
  }; // end populateIncumbentYN()

  //numeric parsed value : min incumbent cost %
  handleFormChange = (event) => {
    let inputValue = event.target.value;
    let parsedValue = 0 ;
    console.log('QuickLaunchIncumbentsForm: handleFormChange read: '
      , 'threshold=', this.mobxState.addField.threshold
      , '; inputValue=', inputValue, '; length=', inputValue.length
      , '; has digits=', /\d+/.test(inputValue)
      , '; digit after % =', /\%\d/.test(inputValue)); // are there any digits ? // what is the integer ?
    if (!/\%/.test(inputValue) & inputValue.length > 0 // if '%' (last character) was deleted, then delete character before last 
        & this.mobxState.addField.threshold != 80) { 
            inputValue = inputValue.substring(0, inputValue.length -1) } ;
    if (/\d+/.test(inputValue)) { parsedValue = parseInt(/\d+/.exec(inputValue.replace(/\%/g,''))[0]) } ;
    this.mobxState.addField.threshold = parsedValue ;
    this.mobxState.formState.formValid = (parsedValue >= 0 ) ;
    event.target.value = parsedValue.toString() + '%' ;
    //
    // do not accept inputs with no digits or zero
    if (parsedValue==0) { event.target.value = '%' ; this.mobxState.formState.formValid = false } ;
    //
    // assign scenario name in mobX
    this.mobxState.addField.threshold = parsedValue ; // event.target.value ; // 80 ; //
  }
  //END FORM HANDLING

  //////////////////END OF HTTP////////////

  //main rendering of component
  render() {
    return (
            <div className="panel panel-default">
              <div className="panel-heading panel_bg">
                <b>Quick-Start an Incumbent Costs Scenario</b>
              </div>
              <div className="panel-body">
                <div className="row">
                    <div className="col-sm-6">
                      <input name="scenarioName" type="text" placeholder="Enter min incumbents % cost" className="form-control" onChange={this.handleFormChange}/>
                    </div>
                    <div className="col-sm-6">
                      <button
                        type="submit"
                        disabled={!this.mobxState.formState.formValid}
                        className="btn btn-success btn-block"
                        onClick={this.handleAddNamedField}
                      >
                        <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Launch
                      </button>
                    </div>
                </div>

              </div>
            </div>
    );
  }
} //end of component



// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate( QuickLaunchIncumbentsForm, { mobxState: observable } )

export default observer( QuickLaunchIncumbentsForm ) ;
