import _ from 'lodash';
import { deleteIfEmpty, removeKeys, forgetfulMemoize } from './Utilities';
import axios from 'axios';

// flattenFilters :: apiResponse -> [{path: [<model1>, <model2>], filter: <filter>}]
// unflattenFilters :: [{name: <muxedName>, value: [<option 1>, <option 2>]}] =>
//                     [{<model 1>: {<model 2>: {<column_name>: [<option 1>, <option 2>]}}}]
// CURRENT =========
// getOptions :: {selectedModels: Current filters in {<name-of-filter>: [selectedOptions]},
//                system_fields: systemFilterModelNames // List of filters to get options for}
// NEW  ============
// getOptions :: {selectedModels: Current filters in {<name-of-filter>: [selectedOptions]},
//                system_fields: {<model 1>: {<model 2>: {<filter-col-1>: [<option 1>, <option 2>],
//                                                        <filter-col-2>: [<option 1>, <option 2>],
//                                            <model 3>: {<filter-col-3>: [<option 3>, <option 4>]}}}}

// Flatten and Mux Filters
// Convert filters from {<model>: <filter>} to {path: [<path-to-filter>], filter: <filter>}

// flatten :: nestedObject => [path+array]
export const flatten = nestedObject => {
  // NOTE: - While a iterative definition is usually preferable, I'm defining flattenFilters
  //         in a recursive format since this is essentially a tree problem. We need to walk
  //         down a nested object of model/table names to find an array of filters.
  //       - The path to a leaf (list of filters) will be prepended to whatever name is used for
  //         the filter within the context of a given page.
  //       - We need to limit recursive depth to keep this from blocking in edge cases
  let inner = (obj, path, depth = 0, maxDepth = 5) => {
    if (depth >= maxDepth) {
      throw new Error('Recursion Error: Max Depth Reached');
    }
    // Base Case: We've reached a leaf node (In our case, this will be the first array found)
    if (_.isArray(obj)) {
      return obj.map(filter => {
        return { path: path, filter: filter };
      });
    } else if (_.isObject(obj)) {
      // Recursive Case: We're on a branch node.
      // Descend down each branch collecting their filters
      // And return [...<branch1-results>, ...<branch2-results>, ...ect]
      let results = [];
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          results.push(
            inner(
              obj[prop], // We need results for property
              [...path, prop], // the way we got here + where we are
              depth + 1 // With incremented depth to prevent runaway recursion in edge cases (e.g. Cycles)
            )
          );
        }
      }
      return [].concat(...results);
    } else {
      // Default Case: Simple value such as 1, '', undefined, null, ect.
      return obj;
    }
  };
  return inner(nestedObject, []);
};

// unflatten :: [path+array] => nestedObject
export const unflatten = pathArrayList => {
  // debugger;
  if (!_.isArray(pathArrayList)) {
    return pathArrayList;
  } else {
    // Unflatten each object then merge resulting objects
    // See https://lodash.com/docs/4.17.15#mergeWith
    let mergeCustomizer = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    };
    let nestedObjects = pathArrayList.map(pathArrayHash => {
      let { path, filter } = pathArrayHash;
      let newObj = _.set({}, path, [filter]);
      return newObj;
    });
    // Merge objects from left to right, zipping objects & concatenating arrays
    return [{}, ...nestedObjects].reduce((acc, el) =>
      _.mergeWith(acc, el, mergeCustomizer)
    );
  }
};

// Special case for option names, just handle their marshaling separately
export const demarshalOptionNames = muxedOptionNameList => {
  let mergeCustomizer = (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  };
  let out = [];
  muxedOptionNameList.forEach(name => {
    let fullpath = name.split('__');
    let path = _.dropRight(fullpath, 1);
    let col = fullpath[fullpath.length - 1];
    out.push(_.set({}, path, [col]));
  });
  out = [{}, ...out].reduce((acc, el) => _.mergeWith(acc, el, mergeCustomizer));
  return out;
};

export const processingFunctions = {
  system: {
    filters: {
      // We don't need to format params here since we're just requesting filter
      formatParams: params => params,
      // Mux response (nested by model) into 'bid__name' format
      formatResp: resp => {
        let filtersNestedByModel = resp.data.system_filters;
        let pathFilterFormat = flatten(filtersNestedByModel);
        let filters = pathFilterFormat.map(({ path, filter }) => ({
          ...filter,
          ...{ name: [...path, filter.name].join('__') }
        }));
        return filters;
      }
    },
    options: {
      // Demux field names when requesting options
      // TODO: Smooth out formatting of parameters
      // selectedModels: List of filters to get options for
      // system_fields: Current filters in {<name-of-filter>: [selectedOptions]} format
      formatParams: options => {
        let { selected_filters, system_fields } = options;
         selected_filters = selected_filters || {};
        let demuxedSystemFields = demarshalOptionNames(system_fields);
        return {
          selected_models: selected_filters,
          system_fields: demuxedSystemFields
        };
      },
      formatResp: resp => {
        // {bid: {item: {name: [{name: "Bruce Wayne", label: "Batman"}]}}}
        let data = resp.data;
        // {path: ['bid', 'item', 'name'], filter: {name: "Bruce Wayne", label: "Batman"}}
        let dataFlat = flatten(data);
        let options = _(dataFlat)
          .groupBy(option => option['path'].join('__')) // Group by flattened name
          .mapValues(v => v.map(x => x.filter)) // Drop path field for each filter
          .mapValues(v => v.map(x => ({...x, ...{label: `${x.label}`}}))) // Enforce string type for label
          .value();
        return options;
      }
    }
  },
  custom: {
    filters: {
      // We don't need to format params here since we're just requesting filter
      formatParams: params => params,
      // Mux response (nested by model) into 'bid__name' format
      formatResp: resp => {
        let filtersNestedByModel = resp.data.custom_filters;
        let pathFilterFormat = flatten(filtersNestedByModel);
        let filters = pathFilterFormat.map(({ path, filter }) => {
          let [name, data] = _.toPairs(filter)[0]; // _.toPairs splits object into list of [key, value] pairs
          let prefixedFilter = {};
          prefixedFilter[[...path, name].join('__')] = data;
          return prefixedFilter;
        });
        return filters;
      }
    },
    options: {
      // Demux out of 'bid__name' format for call requesting options
      formatParams: options => {
        let { selected_filters, custom_fields } = options;
        selected_filters = selected_filters || {};
        // Break off field names
        let demuxedSystemFields = demarshalOptionNames(custom_fields);
        // Nest it under 'custom_fields'
        Object.keys(demuxedSystemFields).forEach(k => {
          demuxedSystemFields[k] = {'custom_fields': demuxedSystemFields[k]}
        });
        // demuxedSystemFields = replaceNonstandardJSONBFieldNames(demuxedSystemFields); // NOTE: We're skipping custom_field column renaming step
        return {
          selected_models: selected_filters,
          custom_fields: demuxedSystemFields
        };
      },
      // Mux response (nested by model) into 'bid__name' format
      formatResp: resp => {
        // {bid: {item: {name: [{name: "Bruce Wayne", label: "Batman"}]}}}
        let data = resp.data;
        // {path: ['bid', 'item', 'name'], filter: {name: "Bruce Wayne", label: "Batman"}}
        let dataFlat = flatten(data);
        return _(dataFlat)
          .groupBy(option => option['path'].join('__'))
          .mapValues(v => v.map(x => x.filter))
          .mapValues(v => v.map(x => ({...x, ...{label: `${x.label}`}}))) // Enforce string type for label
          .value();
      }
    }
  },
  general: {
    makeSearchFunctions: options => {
      // Take in the actual response for filters and custom filters
      let { selected_filters, system_fields, custom_fields } = options;
      selected_filters = selected_filters || {};

      let searchFunctions = {};
      system_fields.forEach(nestedSystemFieldName => {
        let demuxedSystemFields = demarshalOptionNames([nestedSystemFieldName]);
        let systemFilter = options.system_filters.filter(el => el.name === nestedSystemFieldName)[0];
        if (systemFilter.uses_db_for_search === false) {
          searchFunctions[nestedSystemFieldName] = undefined;
        } else {
          searchFunctions[nestedSystemFieldName] = forgetfulMemoize(
            searchTerm => {
            return axios
              .post(options.system_url, {
                search: searchTerm,
                system_fields: demuxedSystemFields,
                selected_filters: selected_filters
              })
              .then(resp => {
                return _.get(resp.data, nestedSystemFieldName.split('__'), []);
              });
          }, 10); // => Promise
        }
      });
      let customSearchFunctions = {};
      custom_fields.forEach(nestedCustomFieldName => {
        let demuxedCustomFields = demarshalOptionNames([nestedCustomFieldName]);
        let customFilterMatches = options.custom_filters.filter(el => typeof el[nestedCustomFieldName] !== 'undefined');
        if (_.get(customFilterMatches, [0, nestedCustomFieldName, 'uses_db_for_search'], false)) {
          // Nest it under 'custom_fields'
          Object.keys(demuxedCustomFields).forEach(k => {
            demuxedCustomFields[k] = { custom_fields: demuxedCustomFields[k] };
          });
          customSearchFunctions[nestedCustomFieldName] = forgetfulMemoize(searchTerm => {
            return axios
              .post(options.custom_url, {
                search: searchTerm,
                custom_fields: demuxedCustomFields,
                selected_filters: selected_filters
              })
              .then(resp => {
                return _.get(resp.data, nestedCustomFieldName.split('__'), []);
              });
          }, 10); // => Promise
        }
      });
      return { searchFunctions, customSearchFunctions };
      // For each nested name
      // create a search function that takes a string
      // requests the provided options endpoint resolve it
      // grab the options from that result.
    },
    // kinda one off, for spend rule reclassifications
    unsequelizeAndFlattenConditions: filtersForServer => {
      let unsequelizedFilters = unSequelizeFilters(filtersForServer);
      let flatFilters = _(flatten(unsequelizedFilters))
      .groupBy(option => option['path'].join('__'))
      .mapValues(v => v.map(v => v.filter)) // Drop path
      .value();
      return flatFilters;
    }
  }
};

export const demuxPart = part => {
  // Convert from {'project__name': ['value 1', 'value 2'], 'bid__amount': ['1', '2']}
  // to [{path: ['project', 'name'], filter: 'value 1'}
  //     {path: ['project', 'name'], filter: 'value 2'}
  //     {path: ['bid', 'amount'], filter: '1'}
  //     {path: ['bid', 'amount'], filter: '2'}]
  // strip off the primary model (the one we're working with) so system filters get passed as expected
  //    [{path: ['name'], filter: 'value 1'}
  //     {path: ['name'], filter: 'value 2'}
  //     {path: ['bid', 'amount'], filter: '1'}
  //     {path: ['bid', 'amount'], filter: '2'}]
  // And unflatten into a nested object to be used for parameters
  //     {name: ['value 1', 'value 2'], bid: {amount: ['1', '2']}}
  let pairs = _.toPairs(part);
  let pathFilterFormat = pairs.map(pair => {
    let [path, selectedOptions] = pair;
    path = path.split('__');
    return selectedOptions.map(value => ({ path: path, filter: value }));
  });
  // Flatten out lists (returns list)
  let filtersFlat = pathFilterFormat.reduce((acc, el) => acc.concat(el), []);
  // Unflatten filters
  let filtersNested = unflatten(filtersFlat);
  return filtersNested;
};

export const demux = (selectedModels) => {
  let normalFilterPart = _.cloneDeep(selectedModels);
  let customFilterPart = selectedModels['custom_fields'];
  _.unset(normalFilterPart, 'custom_fields');

  let demuxedFilters = {
    ...demuxPart(normalFilterPart),
    ...{ custom_fields: demuxPart(customFilterPart) }
  };
  deleteIfEmpty(demuxedFilters, 'custom_fields');
  return demuxedFilters;
};

export const normalize = (demuxedFilters) => {
  demuxedFilters = _.cloneDeep(demuxedFilters);
  // Get already normalized part + custom_fields part
  let customFieldPart = demuxedFilters['custom_fields'] || {};
  let normalPart = demuxedFilters;
  delete normalPart['custom_fields'];

  // Walk over models in demuxedFilters['custom_fields']
  // Merging each of it's fields under the key
  // demuxedFilters[<modelName>]['custom_fields'][<field>]
  for (let modelName in customFieldPart) {
    // Handle possible undefined values
    normalPart[modelName] = normalPart[modelName] || {};
    normalPart[modelName]['custom_fields'] = {};
    // Copy over the attributes
    for (let customFieldName in (customFieldPart[modelName])) {
      normalPart[modelName]['custom_fields'][customFieldName] = customFieldPart[modelName][customFieldName]
    }
  }
  return normalPart;
}

// Wrap any array belonging to custom_fields with {$in: <array>}
export const sequelizeFilters = (normalizedFilters) => {
  normalizedFilters = _.cloneDeep(normalizedFilters);
  let inner = (obj, path, depth, maxDepth) => {
    // Error if we go too deep
    if (depth > maxDepth) {
      throw new Error(`RECURSION ERROR: maxDepth of ${depth} has been exceeded`)
    };
    if (Array.isArray(obj)) {
      // Base Case: Array
      if (path.indexOf('custom_fields') !== -1) {
        return {$in: obj} // -- Sub Case: We're in custom_fields
      } else {
        return obj        // -- Sub Case: We're not in custom_fields
      }
      // Wrap the object with sequelize operator {$in: <theObject>}
    } else if (typeof obj === 'object' && obj !== null) {
      // Recursive Case: Nonnull object (Recurse on its values)
      let newObj = {};
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key] = inner(obj[key], [...path, key], depth + 1, maxDepth)
        }
      }
      return newObj;
    } else {
      // BASE CASE 2: obj is null or simple (ex. 3)
      return obj;
    }
  };
  return inner(normalizedFilters, [], 0, 10)
}

// Unnest custom fields
export const unSequelizeFilters = (normalizedFilters) => {
  normalizedFilters = _.cloneDeep(normalizedFilters);
  let inner = (obj, path, depth, maxDepth) => {
    // Error if we go too deep
    if (depth > maxDepth) {
      throw new Error(`RECURSION ERROR: maxDepth of ${depth} has been exceeded`)
    };
    // BASE CASE 1: DEALING WITH ARRAY
    if (Array.isArray(obj)) {
      return obj
    } else if (typeof obj === 'object' && obj !== null) {
      // Recursive Case: Nonnull object (Recurse on its values)
      let newObj = {};
      for (let key in obj) {
        // Case: Single key object "$in": []
        // Case: custom_fields
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (key === 'custom_fields'){
            newObj = {...newObj, ...inner(obj[key], [...path, key], depth + 1, maxDepth)}
          } else if (obj[key]['$in']){
            newObj[key] = inner(obj[key]['$in'], [...path], depth + 1, maxDepth);
          } else {
            newObj[key] = inner(obj[key], [...path, key], depth + 1, maxDepth);
          }
        }
      }
      return newObj;
    } else {
      // BASE CASE 2: obj is null or simple (ex. 3)
      return obj;
    }
  };
  let result = inner(normalizedFilters, [], 0, 10);
  return result;
}

// custom_fields will sometimes be named something else
// for example: In the items table, it's named custom_fields
// add any such field into the replacements variable in the function below
export const replaceNonstandardJSONBFieldNames = (sequelizedFilters) => {
  sequelizedFilters = _.cloneDeep(sequelizedFilters);
  let replacements = {'item': 'item_groups'};
  let replacementKeys = Object.keys(replacements);
  let inner = (obj, path, depth, maxDepth) => {
    // Error if we go too deep
    if (depth > maxDepth) {
      throw new Error(`RECURSION ERROR: maxDepth of ${depth} has been exceeded`)
    };
    if (Array.isArray(obj)) {
      return obj
    } else if (typeof obj === 'object' && obj !== null) {
      // Recursive Case: Transform if we have table with a replacement then recurse
      let newObj = {};
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          // Replace {<modelName>: {custom_fields: {...}}
          // with    {<modelName>: {replacements[modelName]: {...}}}
          // leaving other fields intact
          if (replacementKeys.indexOf(key) !== -1) {
            obj[key][replacements[key]] = obj[key].custom_fields;
            delete obj[key]['custom_fields'];
            deleteIfEmpty(obj, `${key}.${replacements[key]}`)
          }
          // Continue to modify, recursing on value
          newObj[key] = inner(obj[key], [...path, key], depth + 1, maxDepth)
        }
      }
      return newObj;
    } else {
      // BASE CASE 2: obj is null or simple (ex. 3)
      return obj;
    }
  };
  return inner(sequelizedFilters, [], 0, 10);
};

export const formatFiltersForServer = selectedModels => {
  let demuxedFilters = demux(selectedModels);
  let normalizedFilters = normalize(demuxedFilters);
  let sequelizedFilters = sequelizeFilters(normalizedFilters);
  // let finalFilters = replaceNonstandardJSONBFieldNames(sequelizedFilters);
  let finalFilters = sequelizedFilters;
  return finalFilters;
};

// Filter Injection ============================================================================
// Filters are persisted via localStorage whenever a filter is selected
// In order to add default filters, we have to inject them before components fetch the
// existing value. The following approach is taken:
// - Fetch default filters when loading a module
// - If we've previously injected default filters, and no new value exists for them
// - If we've previously injected default filters, and a new value exists for them, reinject
// - If we haven't previously injected default filters, inject them.
// Usage::
// - Import during component mount
// - Set a flag in module component to render page components *after* we've fetched default values
export const injectDefaultFiltersInModule = async () => {
  let resp = await axios.get('/v1/custom_schemas', { params: { name: 'default_date_filter_value' } }).catch(e => {
    console.error('Problem injecting results', e);
    throw e;
  });
  let defaultFilter = resp.data.custom_schemas[0];
  if (typeof defaultFilter !== 'undefined') {
    let previouslySet = JSON.parse(localStorage.getItem('previouslyInjectedFilters')) || false;
    let previouslySetVal = JSON.parse(localStorage.getItem('previouslyInjectedFiltersValue'));
    let valueHasChanged = JSON.stringify(previouslySetVal) !== JSON.stringify(defaultFilter.schema_definition);
    if (!previouslySet || valueHasChanged) {
      // Build local storage objects that need set
      let selectedModels = defaultFilter.schema_definition;
      let selectedOptions = {};
      Object.keys(defaultFilter.schema_definition).forEach(k => {
        selectedOptions[k] = defaultFilter.schema_definition[k].map(value => ({ value: value, label: `${value}` }));
      });
      let filtersForServer = formatFiltersForServer(selectedModels);
      // Set local storage objects that need set
      localStorage.setItem('selectedModels', JSON.stringify(selectedModels));
      localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
      localStorage.setItem('filtersForServer', JSON.stringify(filtersForServer));
      // Do some book keeping
      localStorage.setItem('previouslyInjectedFilters', 'true');
      localStorage.setItem('previouslyInjectedFiltersValue', JSON.stringify(selectedModels));
      return {
        injected: true,
        selectedModels: selectedModels,
        selectedOptions: selectedOptions,
        filtersForServer: filtersForServer,
        resp: resp
      };
    }
  }
};
