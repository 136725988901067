import React from "react";
import { render } from 'react-dom';

//other components built
import Badge from "../../../../badges/Badge";
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns

//filters
import DockRenderer from "../../../../filters/DockRenderer";
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls
import Select from 'react-select'; //select box for filter
import PlayButton from '../../shared/PlayButton'; //play button that executes scenario

//ag grid
import GridRenderer from "../../../../grids/GridRenderer";

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import {zeroNull, extractToken} from "../../../../services/Utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//imported forms
import ScopeSelection from '../../../../forms/sourcing/ScopeSelection';
import FreightBidSelection from '../../../../forms/sourcing/FreightBidSelection';
import SupplierIdSelection from '../../../../forms/sourcing/SupplierIdSelection';
import FreightBracketNameSelection from '../../../../forms/sourcing/FreightBracketNameSelection';
import AlternatePriceInput from '../../../../forms/sourcing/AlternatePriceInput';

//File Importation
import SpreadSheetImport  from '../../../../forms/sourcing/FileImportation/SpreadSheetImport'

//ant design checkbox form
import { Checkbox } from 'antd';

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class FreightBracketConstraints extends React.Component {
		// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
		projectId = extractToken(window.location.pathname, '/', 3);
		scenarioId = extractToken(window.location.pathname, '/', 5);
		mobxState = {
			formState: {
				scope: {label:null, value:null},
				scopeValue: {label:null, value:null},
				selectedSupplier: {label:null, value:null}, //optional field
        showHelperIcons: false,
        custom_field_name: {label: null, value: null}, //freight bid custom_options value first option dropdown
        custom_field_value: {label: null, value: null}, //freight bid custom_options value second option dropdown
        freight_bracket_name: {label:null, value:null, min: null, max: null, project_id:null},
        alternate_price_amount: null, //optional field
				allow_optimization: {checked:false},//optional field
				checkBoxValue: false, //optional field
        formValid: false,
				submitMessage: false
			},
			customOptions: {
      },
			cardData: {},
			tableData: {
				"freight_bracket_constraints": []
			},
			selectedModels: {
			}, //multi select data model for http
			selectedOptions: {
			}, //multi select data model in filter value/label format
			dockOptions: {
				isVisible: false,
				size: 0.2
			}, //sidebar defaults
			pivotData: [],
			pivotState: [],
			pivotFilter: [],
			filterModelNames: [], //autobuilds filters and clear functions
			submitMessage: false,//controls message that confirms constraint was saved
      currentMax: false, //shows max price from freight brackets once id is selected
      submitAllScopes: false, //changes submission if "Apply Constraint to All" is Selected
		  scopeNames: [], //holds names of scope_values
		  allScopesObj: [], //holds multiple objects to submit if Apply constraint to all is selected
			customSchemaRowCount: null, //default amount of rows from 'custom_schemas' table
			isLoadingTableData: false, //fired off when show enteries is selected
			inDeletion: false, //toggles icon and "Deleting selected rows" caption
		};

		//mount data through http requests
		componentDidMount() {
			//get the grid grow count then call the data loader to load data into the view
	    this.getCustomRowCount();
		}

		//////////////////HTTP/////////////////
		getCustomRowCount = () => {
	    axios
	      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
	      .then(response => {
	        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
	        this.fetchData()
	      })
	      .catch(console.log.bind(console)); //error catcher
	  }
		//data loader
		fetchData = () => {
			//quick workaround -- pull simple dropdown value from nested component instead of passing around state
			var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();

			//merge the filter model with the amount of rows selected
			let models_with_rows = {...this.mobxState.selectedModels, ...{"table_rows": table_rows}};
			// console.log("ROWS ", table_rows)
			//set up custom options for components, then get data for components
      // Merge System Aliases Into Custom Options (Keeping existing values)
      grabSystemAliases().then(systemAliases => {
        this.mobxState.customOptions = {
          ...systemAliases,
          ...(this.mobxState.customOptions || {}), // Prefer existing values
        }
      });

			axios.get('/v1/projects/'+this.projectId+'/project_field_schemas', { params: {'project_id': this.projectId} })
			.then((response) => {

        //get values and the corresponding labels from jsonb data in db
				let item_name = response.data.project_field_schemas[0].item_level //get names from scopes
				let bid_name = response.data.project_field_schemas[0].freight_bid_level //get names from custom_fields

        //scope type names (value and label)
				let scope_fields_data = [];
				Object.values(item_name).forEach(element => {
					scope_fields_data.push({value:element.field_name, label:element.field_alias })
				})
				//console.log(scope_fields_data)

				//custom fields names (value and label)
				let custom_fields_data = [];
				Object.values(bid_name).forEach(element => {
					custom_fields_data.push({value:element.field_name, label:element.field_alias })
        })

        //get map of field labels from API
				this.mobxState.customOptions = {
          ...this.mobxState.customOptions,
          ...(response.data || {})
        };

				//after ag grid is set up, get the rest of the data
				//table data load
				this.mobxState.isLoadingTableData = true //loading project rows
				axios.get('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/freight_bracket_constraints', { params: models_with_rows })
				.then(response => {
					console.log("data response:");
					console.log(response.data);
					this.mobxState.isLoadingTableData = false
					response.data.freight_bracket_constraints.forEach(function(i){
						//find and replace scope value with label
						for(let x of scope_fields_data){
							if(i.scope === x.value){
								i.scope = x.label
							}
						}
						//find and replace custom_field_name with field_alias
						for(let x of custom_fields_data){
							if(i.custom_field_name === x.value){
								i.custom_field_name = x.label
							}
						}
					})

          this.mobxState.tableData = response.data;


          console.log('field Aliases', this.mobxState.customOptions);
          //set the column definitions for ag grid, look into buildColumnDefs class to see options
          this.mobxState.columnDefsFreightBracketConstraints = gridColumns.buildColumnDefs({
            "customOptions": this.mobxState.customOptions,
            "columnArray": ['freight_bracket_constraints'],
            "customFieldSchema": this.mobxState.customOptions.project_field_schemas[0],
						"dataType": 'freight_bracket_constraint' //special tag to signify that this is freight_bracket_constraint data
          });
					//autofit the table columns (hits the child function), this runs after the columns were created
					this.autofitChild();
				})
			})
			.catch(console.log.bind(console)) //error catcher

			//custom pivot configurations load
			axios.get("/v1/resources/index_filter", {params: {"page_id": 404} })
			.then((response) => {
				this.mobxState.pivotFilter = response.data.resources;
				this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
			});

		} //end fetchData()

		//FORM HANDLING FUNCTIONS
		//handle submission by sending the formState to server
		handleFormSubmit = (event) => {

      if(this.mobxState.submitAllScopes === false){
        axios.post('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/freight_bracket_constraints', {
          scope: this.mobxState.formState.scope.value,
          scope_value: this.mobxState.formState.scopeValue.value,
          custom_field_name: this.mobxState.formState.custom_field_name.value,
          custom_field_value: this.mobxState.formState.custom_field_value.value,
          supplier_id: this.mobxState.formState.selectedSupplier.value, //optional field
          freight_bracket_id: this.mobxState.formState.freight_bracket_name.value,
          alternate_price: this.mobxState.formState.alternate_price_amount, //optional field
          allow_optimization: this.mobxState.formState.allow_optimization.checked, //optional field
			})
			.then(()=>{{this.mobxState.submitMessage = true}setTimeout(() => { this.mobxState.submitMessage = false }, 7000)}) //shows constraint saved message
			.then((response) => {
				console.log(this.mobxState.formState.allow_optimization.checked );
				this.fetchData();
				this.mobxState.formState.showHelperIcons = false;
			});
      } else {
        this.createFinalForm()
        console.log("ALL SCOPES OBJ ", this.mobxState.allScopesObj)
        for (let i = 0; i < this.mobxState.allScopesObj.length; i++){
          axios.post('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/freight_bracket_constraints', {
            scope: this.mobxState.allScopesObj[i].scope,
            scope_value: this.mobxState.allScopesObj[i].scope_value,
            custom_field_name: this.mobxState.formState.custom_field_name.value,
            custom_field_value: this.mobxState.formState.custom_field_value.value,
            supplier_id:  this.mobxState.allScopesObj[i].supplier_id, //optional field
            freight_bracket_id: this.mobxState.allScopesObj[i].freight_bracket_id,
            allow_optimization: this.mobxState.allScopesObj[i].allow_optimization, //optional field
            alternate_price: this.mobxState.allScopesObj[i].alternate_price //optional field
        })
        .then(()=>{{this.mobxState.submitMessage = true}setTimeout(() => { this.mobxState.submitMessage = false }, 7000)}) //shows constraint saved message
        if (this.mobxState.allScopesObj.length - 1 === i) {
          console.log("Constraints Submitted")
          { this.mobxState.submitMessage = true }
          setTimeout(() => { this.mobxState.submitMessage = false }, 7000)
          this.fetchData();
          this.mobxState.formState.showHelperIcons = false;
        }
        }
      }

		} //end of handle form submit

		//react-select form state handler
		handleFormChange = (field, value) => {
      console.log('handleFormChange with params:', field, value);
      console.log("ALL SCOPES ",this.mobxState.submitAllScopes)
			this.mobxState.formState[field] = value;
			//call validationResult
			this.validateForm();
			this.mobxState.currentMax = true;
    }

    // Assign values from form state to scope values
		handleAllScopes = (allScopes) => {
			this.mobxState.submitAllScopes = true;
			let scopeNames = [];

			//  console.log("SCOPES ",allScopes, formState)
			for (let scope of allScopes.value) {
				console.log(scope)
				if (scope === '999888777' || null) {

					continue;
				} else {
					scopeNames.push(scope)
				}
			}

			this.mobxState.scopeNames = [...scopeNames]
		}

		//form that gets submitted if Apply Constraint to all is selected
		createFinalForm = () => {
			let finalForms = [];
			let formState = cloneDeep(this.mobxState.formState) //remove proxy
			let { scopeValue, ...rest } = formState //remove scopeValue
			console.log("REST ", rest )
			let scopeNames = [...this.mobxState.scopeNames]
			for (let i = 0; i < scopeNames.length; i++) {
				console.log(scopeNames[i])
				finalForms.push({
					scope_value: scopeNames[i],
          scope: rest.scope.value,
          custom_field_name: rest.custom_field_name.value,
					custom_field_value: rest.custom_field_value.value,
          supplier_id: rest.selectedSupplier.value,
          freight_bracket_id: rest.freight_bracket_name.value,
          alternate_price: rest.alternate_price_amount, //optional field
          allow_optimization: rest.allow_optimization.checked, //optional field
				})
				this.mobxState.allScopesObj = [...finalForms] //form where each scope value has its own full form to submit
				// console.log(this.mobxState.allScopesObj)
			}
		}

		validateForm = () => {
			//checks all form fields have values except for the scope and scope value
			//supplier and alternate price are optional and not validated
			let formConditionsMet = this.mobxState.formState.freight_bracket_name.value !== null; //freight bracket id select

			//if custom_fields is not null check the custom values are not null
			let customFieldConditionsMet = false;
			(() => {
				if(this.mobxState.formState.custom_field_name.value !== null && this.mobxState.formState.custom_field_value.value == null ){
					return this.customFieldConditionsMet = false
				} else if(this.mobxState.formState.custom_field_name.value !== null && this.mobxState.formState.custom_field_value.value !== null ) {
					return customFieldConditionsMet = true
				} else if(this.mobxState.formState.custom_field_name.value === null && this.mobxState.formState.custom_field_value.value === null ){
					return customFieldConditionsMet = true
				} else {return}
			})();
			//scope and scope value exist
			let scopeConditionMet = this.mobxState.formState.scope.value !== null && this.mobxState.formState.scopeValue.value !== null;
			//scope is overall so scope value can be null
			let scopeConditionAltMet = this.mobxState.formState.scope.value == 'overall' && this.mobxState.formState.scopeValue.value == null;
			//paths to valid form of meeting scope and form conditions
			let formValidPathOne = formConditionsMet && scopeConditionMet && customFieldConditionsMet !== false;
			let formValidPathTwo = formConditionsMet && scopeConditionAltMet;

			console.log('scopeConditionMet', scopeConditionMet); console.log('scopeConditionAltMet', scopeConditionAltMet); console.log('formConditionsMet', formConditionsMet); console.log('formValidPathOne', formValidPathOne); console.log('formValidPathTwo', formValidPathTwo);

			//check that form is valid and make the state aware of that
			if(
				formValidPathOne || formValidPathTwo
			){
				this.mobxState.formState.formValid = true;
				this.mobxState.formState.showHelperIcons = true;
			}
			else {
				this.mobxState.formState.formValid = false;
				this.mobxState.formState.showHelperIcons = false;
			};
			console.log(this.mobxState.formState);
		}
		//END FORM HANDLING

		deleteRequest = (toSendArr) => {
			this.mobxState.inDeletion = true
			console.log("IN DELETION");
	    axios.post('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/freight_bracket_constraints/batch_destroy', {"id_set": toSendArr})
	    .then((response) => {
				this.mobxState.inDeletion = false
				console.log(response);
				this.fetchData();
	    });
		}
		//////////////////END HTTP/////////////////

	  //handles dropdown and chart filters
		handleFilter = (filterParam, selectedFilter) => {
			console.log('Handle Filter Callback: ');
			console.log('selectedFilter ', selectedFilter);
			console.log('filterParam ', filterParam);
			//in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
			this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
			//also store the selections in the original form. This goes back to the filter as selected values
			this.mobxState.selectedOptions[filterParam] = selectedFilter;
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

	  //handles dropdown and chart filters
		handleClear = () => {
			//in the state, clear the model. This goes as params to http request
			this.mobxState.selectedModels = {};
			//also store the explicit cleared selections in the original form. This goes back to the filter as selected values
			//loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
			//from ["supplier", "item"] to {"supplier":[], "item":[]}
			const optionsClear = {};
			for (const key of this.mobxState.filterModelNames) { optionsClear[key] = [];}
			this.mobxState.selectedOptions = optionsClear;
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

		//for sidebar dock to change sizes
		handleSizeChange = size => {
			this.mobxState.dockOptions.size = size;
        }

		//checkbox from handling
		//checking the box overrides max tier and uses the user input price
        handleCheckBox = (event) => {
			this.mobxState.formState.allow_optimization.checked = event.target.checked
            // console.log(`checked = ${event.target.checked}`)
			// console.log(this.mobxState.formState.allow_optimization)
		};


		//sidebar dock toggle
		toggleDock = () => {
			this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
    }
    //reloads grid after sheet is submitted
    handleSheetSubmit = () => {
      console.log("Reloading Grid");
      this.fetchData();
  };

  //resets submitAllScopes in state
  resetSelectAll = () =>{
    this.mobxState.submitAllScopes = false;
  }

		render() {

			const hcHeight = '300px';

			return (
        <div>
          {/* Small Left Toggle
          <div onClick={this.toggleDock} className='sidebar_button filter_toggle'>
            <i className='glyphicon glyphicon-chevron-left'></i>
          </div>
					 */}

          <div className='jumbotron jumbotron_full_width'>
            <div className='jumbotron_full_width_container'>
              <div className='row'>
                <div className='col-sm-5'>
                  <span className='fa-layers fa-fw fa-2x'>
                    <FontAwesomeIcon icon={['fas', 'circle']} color='#009C9C' style={{ marginTop: '4px' }} />
                    <span
                      className='fa-layers-text fa-inverse'
                      style={{ fontWeight: '900', fontSize: '14px', marginTop: '4px' }}
                    >
                      Fb
                    </span>
                  </span>
                  <b className='dash_header'>Manage Freight Bracket Constraints</b>
                </div>
                <div className='col-sm-2'></div>
                <div className='col-sm-3'></div>
                <div className='col-sm-2 gps'>
									<span style={{marginRight:'17px'}}><PlayButton /></span>
                  <FontAwesomeIcon
                    icon={['fad', 'location-arrow']}
                    color='#53C8C5'
                    transform='grow-15 left-10'
                    style={{}}
                  />
                  <Link
                    to={'/sourcing/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/ScenarioManager'}
										className='link_style'
                  >
                    Next: Constraints Overview
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* BADGES */}

          {/* FORM */}
          <div className='row'>
            <div className='col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg clearfix'>
                  <b style={{display: 'inline'}}>Constraint Creation </b>
                  <span className='pull-right' style={{display: 'inline'}}>>
                    {/* Constraint Saved Message */}
                    <Bounce left when={this.mobxState.submitMessage}>
                      <b className='text-success' style={{ marginRight: '1.5em', marginTop: '4px' }}>
                        Constraint Saved
                      </b>
                    </Bounce>
                    <Bounce left when={this.mobxState.formState.showHelperIcons}>
                      <span>
                        <FontAwesomeIcon
                          icon={['fad', 'long-arrow-alt-right']}
                          size='2x'
                          transform='down-3 grow-10'
                          color='#53C8C5'
                          style={{ marginRight: '15px' }}
                        />
                      </span>
                    </Bounce>
                    <button
                      type='button'
                      disabled={!this.mobxState.formState.formValid}
                      className='btn btn-success btn-md'
                      onClick={this.handleFormSubmit}
                    >
                      <FontAwesomeIcon icon={['fal', 'save']} />&nbsp; Save Constraint
                    </button>
                    &nbsp;
										{/*
											<button type='button' className='btn btn-primary btn-md' onClick={this.handleFormSubmit}>
											<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Bulk Add
											</button>
										*/}
                    <button
                      className='btn btn-primary'
                      type='button'
                      data-toggle='collapse'
                      data-target='#importForm'
                      aria-expanded='false'
                      aria-controls='importForm'
                    >
                      <FontAwesomeIcon icon={['fal', 'file-import']} />&nbsp; Import Excel/CSV
                    </button>
                  </span>
                </div>{' '}
                {/* end of buttons */}
                <div className='panel-body'>
                  {/* collapsing form for importing data from external excel or csv file */}
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='collapse multi-collapse' id='importForm'>
                        <div className='card card-body'>
                          {/* holds import excel and csv import forms and buttons */}
													<SpreadSheetImport
														requiredCols={['scope','scope value','custom field name','custom field value','supplier','alternate price', 'allow optimization', 'freight bracket']}
														tableName={"freight_bracket_constraints"} //name of DB that the form will submit to
														handleSheetSubmit={this.handleSheetSubmit}
														systemFieldCols={['scope', 'scope_value', 'custom_field_name', 'custom_field_value',
								               'supplier', 'freight_bracket', 'alternate_price', 'allow_optimization']}
														isConstraint={true}
														projectId={this.projectId}
														scenarioId={this.scenarioId}
														optionalCols={[]}
													/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className={'top_panel_info'}>Narrow down where, in the solution, to override the freight brackets</div>
                      </div>
                    </div>
                    <br />

                    <div className='row'>
                      <ScopeSelection
                        projectId={this.projectId}
                        handleFormChange={this.handleFormChange}
                        scopeCssClass={'col-lg-4'}
                        scopeValueCssClass={'col-lg-4'}
                        onSelectAll={this.handleAllScopes}
												resetSelectAll={this.resetSelectAll} //keeps select select all from applying between dropdown
                      />
                      <div className='col-lg-4'></div> {/* end col-lg-4*/}
                    </div>
                    {/* End row */}
                    <br />

                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className={'top_panel_info'}>Using a custom bid field, narrow down the forced freight bid to override the solution with</div>
                      </div>
                    </div>
                    <br />
                    <div className='row'>
                      {' '}
                      {/* Middle Row */}
                      <FreightBidSelection
                        //formField={'custom_field_name'}
                        projectId={this.projectId}
                        handleFormChange={this.handleFormChange}
                        scopeCssClass={'col-lg-4'} //kept styles from scope select to maintain consistency
                        scopeValueCssClass={'col-lg-4'} //kept styles from scope select to maintain consistency
                      />
                      <div className='col-lg-4'></div>
                    </div>
                    {/* End row */}
                    <br />

                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className={'top_panel_info'}>Optional: using a bidder, narrow down the forced freight bid to override the solution with</div>
                      </div>
                    </div>
                    <br />
                    <div className='row'>
                      <div className='col-lg-4'>
                        <div className='row'>
                          <div className='col-lg-2'>
                            <div>
                              <FontAwesomeIcon
                                icon={['fad', 'table']}
                                mask={['fas', 'circle']}
                                size='4x'
                                transform='shrink-5'
                                color='#4CABA9'
                                style={{ marginRight: '20px' }}
                              />
                            </div>
                          </div>
                          <div className='col-lg-10'>
                            <SupplierIdSelection
                              formField={'selectedSupplier'}
                              projectId={this.projectId}
                              handleFormChange={this.handleFormChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-8'>
                      </div>
                    </div>
										<br />
										<div className='row'>
											<div className='col-lg-12'>
												<div className={'top_panel_info'}>Specify pricing to override the freight bracket with</div>
											</div>
										</div>
										<br />
                    <div className='row'>
                      <div className='col-lg-4'>
                        <div className='row'>
                          <div className='col-lg-2'>
                            <div>
                              <FontAwesomeIcon
                                icon={['fad', 'check-circle']}
                                mask={['fas', 'circle']}
                                size='4x'
                                transform='shrink-5'
                                color='#4CABA9'
                                style={{ marginRight: '20px' }}
                              />
                            </div>
                          </div>
                          <div className='col-lg-10'>
														<FreightBracketNameSelection
															formField={'freight_bracket_name'}
															projectId={this.projectId}
															handleFormChange={this.handleFormChange}
														/>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4'>
												<AlternatePriceInput
													formField={'alternate_price_amount'}
													projectId={this.projectId}
													handleFormChange={this.handleFormChange}
												/>
                      </div>
											<div className='col-lg-4'>
												<Checkbox
													name='checkBoxSelection'
													onChange={this.handleCheckBox}
													checked={this.mobxState.formState.allow_optimization.checked}
													style={{marginTop: '20px' }}
												>
													Use optimized freight bid instead if it yields a better price (max tier)
												</Checkbox>
											</div>
                    </div>
                    <br />
                  </form>
                </div>
              </div>
            </div>
            {/* END col-sm-12*/}
          </div>
          {/* END row*/}
          {/* END FORM*/}

          {/* PROJECT GRID */}
          <div className='row'>
            <div className='col-lg-12'>
              {/* AG Grid */}
              <GridRenderer
                gridHeight={'550px'}
                columnDefs={this.mobxState.columnDefsFreightBracketConstraints}
                rowData={this.mobxState.tableData.freight_bracket_constraints}
                fetchData={this.fetchData}
                handleClear={this.handleClear}
                toggleDock={this.toggleDock}
                pageId={404}
                savedState={this.mobxState.pivotState}
                isPivotMode={true}
                gridTitle={'Constraint Details'}
                setClick={click => (this.clickChild = click)}
                enableSetResizeChild={true}
                setResize={click => (this.autofitChild = click)}
                // enableSetResizeChildColumns={true}
                // setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

                deleteRequest={this.deleteRequest}
                idColumn={'id'}
                modelsToUpdateArr={[
                  {
                    url:
                      'v1/projects/' +
                      this.projectId +
                      '/scenarios/' +
                      this.scenarioId +
                      '/freight_bracket_constraints/',
                    idName: 'id'
                  }
                ]}
                singleClickEdit={true}
                suppressClickEdit={false}
                isSaveable={true}
                isAwardable={false}
                isDeletable={true}
								useCustomSchemaRowCount={true}
								isLoadingTableData={this.mobxState.isLoadingTableData}
								inDeletion={this.mobxState.inDeletion}
              />
            </div>
          </div>
          {/* END CHART ROW */}

          <br />
          {/* Sidebar */}
          <DockRenderer
            position={'right'}
            size={this.mobxState.dockOptions.size}
            dimMode={'none'}
            isVisible={this.mobxState.dockOptions.isVisible}
            onVisibleChange={this.handleVisibleChange}
            onSizeChange={this.handleSizeChange}
            fluid={true}
            toggleDock={this.toggleDock}
            handleClear={this.handleClear}
            value={this.mobxState.selectedOptions}
            options={this.mobxState.cardData}
            closeMenuOnSelect={true}
            maxMenuHeight={400}
            handleFilter={this.handleFilter}
            customOptions={this.mobxState.customOptions}
            filterModelNames={this.mobxState.filterModelNames}
          />
        </div>
      );
		}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FreightBracketConstraints, {
	mobxState: observable
})

export default observer(FreightBracketConstraints);

// export default SupplierConstraints;
