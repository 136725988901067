import React, { Fragment } from 'react';
import { render } from 'react-dom';

//other components built
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns
import ProjectName from "../../shared/ProjectName";

//Form Components
import CreateNewFreightBidForm from '../../../../forms/sourcing/CreateNewFreightBid/CreateNewFreightBidForm';

//filters
import DockRenderer from '../../../../filters/DockRenderer';

// import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { zeroNull, extractToken, deleteIfEmpty } from '../../../../services/Utilities';
import {
  processingFunctions,
  formatFiltersForServer
} from '../../../../services/filter_helpers';

const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class CreateNewFreightBid extends React.Component {
  // Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
  projectId = extractToken(window.location.pathname, '/', 3);

  modelName = 'newFreightBid';
  mobxState = {
    tableData: {
      freight_bids: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    customFilterModelNames: [],
    filterOptions: {},
    customFilterOptions: {},
    customOptions: {},
    filtersForServer: {}, // Filters in normal form to use for the server
    customSchemaRowCount: null, //default amount of rows from 'custom_schemas' table
    isLoadingTableData: false, //fired off when show enteries is selected
    inDeletion: false, //toggles icon and "Deleting selected rows" caption
  };

  //mount data through http requests
  componentDidMount() {
    //get the grid grow count then call the data loader to load data into the view
    this.getCustomRowCount();
  }

  getCustomRowCount = () => {
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
      .then(response => {
        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
        this.fetchData()
      })
      .catch(console.log.bind(console)); //error catcher
  }

  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();
    //merge the filter model with the amount of rows selected
    let models_with_rows = {
      ...this.mobxState.filtersForServer,
      ...{ table_rows: table_rows }
    };

    //analytics data load
    axios.post('/v1/projects/' + this.projectId + '/freight_bids/analytics', models_with_rows).then(response => {
      this.mobxState.cardData = response.data.freight_bid_analytics;
      this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
    });

    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}), // Prefer existing values
      }
    });

    axios
      .get('/v1/projects/' + this.projectId + '/project_field_schemas', {
        params: { project_id: this.projectId }
      })
      .then(response => {
        //get map of field labels from API
        this.mobxState.customOptions = {
          ...this.mobxState.customOptions,
          ...(response.data || {})
        };

        //after ag grid is set up, get the rest of the data
        //table data load
        this.mobxState.isLoadingTableData = true //loading project rows
        axios
          .post('/v1/projects/' + this.projectId + '/freight_bids_index', models_with_rows)
          .then(response => {
            this.mobxState.tableData = response.data;
            this.mobxState.isLoadingTableData = false;
            //set the column definitions for ag grid, look into buildColumnDefs class to see options
            this.mobxState.columnDefsFreightBids = gridColumns.buildColumnDefs({
                customOptions: this.mobxState.customOptions,
                columnArray: ['freight_bids'],
                customFieldSchema: this.mobxState.customOptions.project_field_schemas[0],
                dataType: 'freight_bid' //special tag to signify that this is project data
            });
          });

        //autofit the table columns (hits the child function), this runs after the columns were created
        this.autofitChildToColumnWidth();
      })
      .catch(console.log.bind(console)); //error catcher

    //custom pivot configurations load
    axios.get('/v1/resources/index_filter', { params: { page_id: 410 } }).then(response => {
      axios.get('/v1/resources/index_filter', { params: { page_id: 410 } }).then(response => {
        this.mobxState.pivotFilter = response.data.resources;
      });
    });

  };//end fetchData()

  deleteRequest = toSendArr => {
    this.mobxState.inDeletion = true;
    console.log('IN DELETION');
    axios
      .post('/v1/projects/' + this.projectId + '/freight_bids/batch_destroy', { id_set: toSendArr })
      .then(response => {
        this.mobxState.inDeletion = false;
				console.log(response);
				this.fetchData();
	    });
  };
  //////////////////END HTTP/////////////////

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };

  render() {
    const hcHeight = '300px';

    return (
      <div>
        {/* Small Left Toggle
        <div onClick={this.toggleDock} className="sidebar_button filter_toggle">
          <i className="glyphicon glyphicon-chevron-left" />
        </div>
        */}

        <div className="jumbotron jumbotron_full_width">
          <div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-8">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform="grow-3"
                    color="#009C9C"
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fal', 'tags']}
                    color="white"
                    transform="shrink-5"
                    style={{ marginTop: '4px' }}
                  />
                </span>
                &nbsp;
                 {/* Uses ProjectName Module to display project name */}
                <b className="dash_header">
                  Create New Freight Bid For <ProjectName />{' '}
                </b>{' '}
              </div>
              <div className="col-sm-2" />
              <div className="col-sm-2 gps">
                <FontAwesomeIcon
                  icon={['fad', 'location-arrow']}
                  color="#53C8C5"
                  transform="grow-15 left-10"
                  style={{}}
                />
                <Link to={'/sourcing/projects/' + this.projectId + '/scenarios'} className='link_style'>Next: Optimize Award Scenarios</Link>
              </div>
            </div>
          </div>
        </div>

       {/* Create New Bid Form */}
        <div className="row">
          <div className="col-lg-12">
            {/* Contains the 4 inputs for creating freight_bids as well as the customs fields table/form (CreateNewFreightBidForm.jsx) */}
            <CreateNewFreightBidForm
              projectId={this.projectId}
              newFreightBidFetchData={this.fetchData}
            />
          </div>
        </div>

        {/* Select Filter allows you to select the pivot_settings config to display*/}
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg">
                <b>Saved Project Analysis Configurations</b>
              </div>
              <div className="panel-body">
                {/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
                <Select
                  onChange={e => this.clickChild(e)}
                  options={this.mobxState.pivotFilter}
                  closeMenuOnSelect={true}
                  maxMenuHeight={400}
                  placeholder={'Select From List of Saved Project Tables'}
                />
              </div>
            </div>
          </div>
        </div>
        {/* PROJECT GRID */}
        <div className="row">
          <div className="col-lg-12">
            {/* AG Grid */}
            <GridRenderer
              gridHeight={'650px'}
              columnDefs={this.mobxState.columnDefsFreightBids}
              rowData={this.mobxState.tableData.freight_bids}
              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={410}
              savedState={this.mobxState.pivotState}
              isPivotMode={true}
              gridTitle={'Bid Details'}
              setClick={click => (this.clickChild = click)}
              deleteRequest={this.deleteRequest}
              idColumn={'id'}
              modelsToUpdateArr={[{ url: 'v1/projects/' + this.projectId + '/freight_bids/', idName: 'id' }]}
              //modelsToUpdateArr={false}
              // enableSetResizeChild={true}
              // setResize={click => this.autofitChild = click}
              enableSetResizeChildColumns={true}
              setResizeToColumnWidth={click => (this.autofitChildToColumnWidth = click)}
              singleClickEdit={true}
              suppressClickEdit={false}
              isSaveable={true}
              isAwardable={false}
              isDeletable={true}
              useCustomSchemaRowCount={true}
              isLoadingTableData={this.mobxState.isLoadingTableData}
              inDeletion={this.mobxState.inDeletion}
            />
          </div>
        </div>
        {/* END CHART ROW */}

        <br />
        {/* Sidebar */}
        <DockRenderer
          position={'right'}
          size={this.mobxState.dockOptions.size}
          dimMode={'none'}
          isVisible={this.mobxState.dockOptions.isVisible}
          onVisibleChange={this.handleVisibleChange}
          onSizeChange={this.handleSizeChange}
          fluid={true}
          toggleDock={this.toggleDock}
          handleClear={this.handleClear}
          // Current Filters----------------------------------
          value={this.mobxState.selectedOptions}
          // Select Names-------------------------------------
          filterModelNames={this.mobxState.filterModelNames}
          customFilterModelNames={this.mobxState.customFilterModelNames}
          // Options------------------------------------------
          options={this.mobxState.filterOptions}
          customFilterOptions={this.mobxState.customFilterOptions}
          // Handlers-----------------------------------------
          handleFilter={this.handleFilter}
          handleCustomFilter={this.handleCustomFilter}
          // Visual options-----------------------------------
          customOptions={this.mobxState.customOptions}
          // Callbacks----------------------------------------
          closeMenuOnSelect={true}
          maxMenuHeight={400}
        />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(CreateNewFreightBid, {mobxState: observable});
export default observer(CreateNewFreightBid);
