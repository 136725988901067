import React from "react";
import { render } from 'react-dom';

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import {zeroNull, extractToken} from "../../../services/Utilities";

//FA
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
// const projectId = extractToken(window.location.pathname, '/', 3);
// const scenarioId = extractToken(window.location.pathname, '/', 5);

class PlayButton extends React.Component {
  projectId = extractToken(window.location.pathname, '/', 3);
  scenarioId = extractToken(window.location.pathname, '/', 5);
  mobxState = {
  };

  //mount data through http requests
  componentDidMount() {

  }

  //////////////////HTTP//////////////////
  handlePlayClick = () => {
    //send the scenario to be evaluated
    axios.post('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/launch')
    .then(response => {
      console.log('Solver response');
      console.log(response);
      window.location.pathname = '/sourcing/projects/'+this.projectId+'/scenarios'
    })
  }
  //////////////////END OF HTTP////////////

  //main rendering of component
  render() {
    return (
      <span className="fa-layers fa-fw fa-2x launch-btn" title="Launch scenario execution">
        <FontAwesomeIcon icon={['fad', 'play-circle']}
        style={{marginTop: '6px'}}
        onClick={this.handlePlayClick} />
      </span>
    );
  }
} //end of component



// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(PlayButton, {
	mobxState: observable
})

export default observer(PlayButton);
