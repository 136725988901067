import React from 'react';
import { render } from 'react-dom';
//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, Select as AntdSelect, TreeSelect } from 'antd';
import Select from 'react-select'; //select box for filter

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//security
import { checkRole } from '../../../../security/SecurityUtilities';

const _ = require('lodash'); //extra utilities

class EditProjectForm extends React.Component {
  mobxState = {
    projectStatus: [], //options for select box
    projectDepartment: [] //options for select box
  };

  componentWillMount() {
    this.roleChecks = {};
    this.hasFreightBidW = null;
    checkRole({ model: 'freight_bid', permission: 'w' }).then(res => {
      this.hasFreightBidW = res;
    });

    //get the project drop downs
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_dropdowns' } }) //query the project dropdowns
      .then(response => {
        //get map of field labels from API
        this.mobxState.projectStatus = response.data.custom_schemas[0].schema_definition.status.map(x => ({
          label: x,
          value: x
        }));
        this.mobxState.projectDepartment = response.data.custom_schemas[0].schema_definition.department.map(x => ({
          label: x,
          value: x
        }));
      })
      .catch(console.log.bind(console)); //error catcher
  }

  // Input building methods ==========================================================================================
  buildCustomField = (name, definition) => {
    const hasEnum =
      Object.prototype.hasOwnProperty.call(definition.schema, 'enum') && Array.isArray(definition.schema.enum);
    const inputType = definition.schema.type;
    if (hasEnum) {
      return this.makeEnumSelect(name, definition);
    } else {
      if (inputType === 'string') {
        return this.makeStringInput(name, definition);
      } else if (inputType === 'number') {
        return this.makeNumberInput(name, definition);
      } else {
        console.error('UNRECOGNIZED FIELD DEFINITION', name);
        return <div className='unknown-field-type'></div>;
      }
    }
  };
  makeEnumSelect = (name, definition) => {
    return (
      <div className='form-group'>
        <label htmlFor={`form-${name}-input`}>{definition.field_alias}</label>
        <AntdSelect
          id={`form-${name}-input`}
          options={definition.schema.enum.map(opt => ({ label: opt, value: opt }))}
          value={this.props.projectData['custom_fields'] && this.props.projectData['custom_fields'][name]}
          className='form-control'
          onChange={value => this.props.handleChange(['custom_fields', name], value)}
          dropdownClassName={'antd-filter-dropdown'}
          placeholder='Please Select One'
        />
      </div>
    );
  };
  makeStringInput = (name, definition) => {
    return (
      <div className='form-group'>
        <label htmlFor={`form-${name}-input`}>{definition.field_alias}</label>
        <AutoComplete
          id={`form-${name}-input`}
          options={[]}
          value={(this.props.projectData['custom_fields'] && this.props.projectData['custom_fields'][name]) || ''}
          className='form-control'
          onChange={value => this.props.handleChange(['custom_fields', name], value)}
          dropdownClassName={'antd-filter-dropdown'}
          placeholder='Please Enter Value'
        />
      </div>
    );
  };
  makeNumberInput = (name, definition) => {
    return (
      <div className='form-group'>
        <label htmlFor={`form-custom-${name}-input`}>{definition.field_alias}</label>
        <InputNumber
          id={`form-${name}-input`}
          value={(this.props.projectData['custom_fields'] && this.props.projectData['custom_fields'][name]) || ''}
          min={definition.schema.minimum}
          max={definition.schema.maximum}
          className='form-control'
          onChange={value => this.props.handleChange(['custom_fields', name], value)}
          placeholder='Please Enter Value'
          style={{ width: '100%' }}
        />
      </div>
    );
  };
  render() {
    // Format custom fields
    let customFieldInputs = [];
    for (const [name, schema] of Object.entries(this.props.schemaDefinition || {})) {
      customFieldInputs.push(
        <div key={name} className='col-sm-3'>
          {this.buildCustomField(name, schema)}
        </div>
      );
    }
    // Determine category to use
    // There's a significant lag, so an option is needed before options fully load.
    // Parent component guesses initial {value+label} option
    // and provide it as this.props.projectData.initial_category_option.
    // Use that as the tree select value if it matches this.props.projectData.category_id
    let categoryOptionToUse = null;
    let formOptionsLoaded = this.props.formOptions.category_id || false;
    let projectDataLoaded = this.props.projectData || false;
    let initialOptionLoaded = this.props.projectData.initial_category_option || false;
    let optionChanged =
      (initialOptionLoaded &&
        this.props.projectData.initial_category_option.value !== `${this.props.projectData.category_id}`) ||
      false;
    if (projectDataLoaded && initialOptionLoaded) {
      if (!optionChanged) {
        categoryOptionToUse = this.props.projectData.initial_category_option;
      } else {
        categoryOptionToUse = `${this.props.projectData.category_id}`;
      }
    }

    return (
      <div>
        <div className='row'>
          <div className={'col-xs-12'}>
            <div className={'top_panel_info'}>Basic Project Information</div>
          </div>
        </div>
        <div className='row'>
          <div className='responsive-grid'>
            {/* Name */}
            <div className='responsive-grid-column'>
              <div className='iconned-input'>
                <FontAwesomeIcon
                  icon={['fad', 'folder-open']}
                  mask={['fas', 'circle']}
                  size='4x'
                  transform='shrink-6'
                  color='#4CABA9'
                  style={{ marginRight: '15px' }}
                />
                <div className='form-group'>
                  <label htmlFor='form-name-input'>Name</label>
                  <AutoComplete
                    id='form-name-input'
                    options={[]}
                    value={this.props.projectData['name'] || ''}
                    className='form-control'
                    onChange={value => this.props.handleChange(['name'], value)}
                    dropdownClassName={'antd-filter-dropdown'}
                    placeholder='Please Enter Value'
                  />
                </div>
              </div>
            </div>

            <div className='responsive-grid-column'>
              <div className='form-group'>
                <label htmlFor='form-description-input'>Description</label>
                <AutoComplete
                  id='form-description-input'
                  options={[]}
                  value={this.props.projectData['description'] || ''}
                  className='form-control'
                  onChange={value => this.props.handleChange(['description'], value)}
                  dropdownClassName={'antd-filter-dropdown'}
                  placeholder='Please Enter Value'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          {/* Project Type */}
          <div className='responsive-grid'>
            <div className='no_top_pad_grid_column'>
              <div className='iconned-input'>
                <FontAwesomeIcon
                  icon={['fad', 'chalkboard-teacher']}
                  mask={['fas', 'circle']}
                  size='4x'
                  transform='shrink-6'
                  color='#4CABA9'
                  style={{ marginRight: '15px' }}
                />
                <div className='form-group'>
                  <label htmlFor='form-project_type-input'>Project Type</label>
                  <AntdSelect
                    id='form-project_type-input'
                    options={
                      this.hasFreightBidW
                        ? [
                            { label: 'Product', value: 'Product' },
                            { label: 'Freight', value: 'Freight' }
                          ]
                        : [{ label: 'Product', value: 'Product' }]
                    }
                    value={this.props.projectData['project_type'] || ''}
                    className='form-control'
                    onChange={value => this.props.handleChangeWithReload(['project_type'], value)}
                    dropdownClassName={'antd-filter-dropdown'}
                    placeholder='Please Enter Value'
                  />
                </div>
              </div>
            </div>

            {/* Status */}
            <div className='no_top_pad_grid_column'>
              <div className='form-group'>
                <label htmlFor='form-status-input'>Status</label>
                <AntdSelect
                  id='form-status-input'
                  options={this.mobxState.projectStatus}
                  value={this.props.projectData['status'] || ''}
                  className='form-control'
                  onChange={value => this.props.handleChange(['status'], value)}
                  dropdownClassName={'antd-filter-dropdown'}
                  placeholder='Select Project Status'
                />
              </div>
            </div>

            {/* Department */}
            <div className='no_top_pad_grid_column'>
              <div className='form-group'>
                <label htmlFor='form-department-input'>Department</label>
                <AntdSelect
                  id='form-department-input'
                  options={this.mobxState.projectDepartment}
                  value={this.props.projectData['department'] || ''}
                  className='form-control'
                  onChange={value => this.props.handleChange(['department'], value)}
                  dropdownClassName={'antd-filter-dropdown'}
                  placeholder='Select Project Department'
                />
              </div>
            </div>

            {/* Savings */}
            <div className='no_top_pad_grid_column'>
              <div className='form-group'>
                <label htmlFor='form-department-input'>Project Savings</label>
                <InputNumber
                  id='form-department-input'
                  value={this.props.projectData['savings'] || ''}
                  className='form-control'
                  onChange={value => this.props.handleChange(['savings'], value)}
                  placeholder='Enter Savings Value'
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
        </div>
        {/*Category Selection ---------------------------------------------------------------------*/}
        {this.props.projectCategorySettings && this.props.projectCategorySettings.enabled ? (
          <React.Fragment>
            <div className='row'>
              <div className={'col-xs-12'}>
                <div className={'top_panel_info'}>Project Category</div>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className={'col-xs-12'}>
                <TreeSelect
                  id='form-category_id-input'
                  style={{ width: '100%' }}
                  value={categoryOptionToUse}
                  defaultValue={
                    this.props.projectCategorySettings && this.props.projectCategorySettings.default_category_id
                  }
                  treeData={this.props.formOptions.category_id || []}
                  placeholder='Please Select Value'
                  treeDefaultExpandAll={false}
                  onChange={value => this.props.handleChange(['category_id'], value)}
                  className='form-control'
                  dropdownClassName={'antd-filter-dropdown'}
                  showSearch={true}
                  filterTreeNode={(input, node) => node.title.toLowerCase().includes(input.toLowerCase())}
                />
              </div>
            </div>
            <br />
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <div className='row'>
          <div className={'col-xs-12'}>
            <div className={'top_panel_info'}>Custom Project Information</div>
          </div>
        </div>
        <br />
        {/* Custom Fields */}
        <div className='row'>{customFieldInputs}</div>
        <br />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(EditProjectForm, {
  mobxState: observable
});

export default observer(EditProjectForm);

// export default ProjectOverview;
