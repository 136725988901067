import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class RunButtonRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span>
        {/*<Link to={`${window.location.pathname}/${this.props.data.id}/edit`}>*/}
          <span className='fa-layers fa-fw fa-2x link_style'>
            <FontAwesomeIcon icon={['fal', 'play']} style={{ marginTop: '6px' }} />
          </span>
        {/*</Link>*/}
      </span>
    );
  }
}
