// Imports
// Adapted from https://react-select.com/async
import React from 'react';

//state
import { decorate, observable, computed, action, trace } from 'mobx';
import { observer } from 'mobx-react';

import moment from 'moment';
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, Select, Spin, DatePicker } from 'antd';
const { RangePicker } = DatePicker;

// There's some mapping that needs to go on here
// For the sake of compatibility, I'm going to assume we're getting:
//   -- Options: [{value+label}]
//   -- Value:   [{value+label}]

class AntdRangeSelect extends React.Component {
  makeDateRange = (startMoment, endMoment) => {
    // Make sure dates inputs are sorted
    if (startMoment.isAfter(endMoment)) {
      [startMoment, endMoment] = [endMoment, startMoment];
    }
    // Duplicate arguments since moments are mutable
    startMoment = startMoment.clone();
    endMoment = endMoment.clone();
    // Add days till range is generated
    let out = [];
    let cur = startMoment;
    while (cur.isSameOrBefore(endMoment)) {
      out.push(cur);
      cur = cur.clone();
      cur.add(1, 'days');
    }
    return out;
  };

  render = () => {
    let currentOptions = this.props.options || []; // [label+value]
    let currentValues = this.props.value || []; // [label+value]
    let startDate = null;
    let endDate = null;
    if (currentValues.length === 0) {
    } else if (currentValues.length === 1) {
      startDate = moment.utc(('' + currentValues[0].value).slice(0, 10));
      endDate = moment.utc(('' + currentValues[0].value).slice(0, 10));
    } else {
      startDate = moment.utc(('' + currentValues[0].value).slice(0, 10));
      endDate = moment.utc(('' + currentValues[currentValues.length - 1].value).slice(0, 10));
    }
    return (
      <RangePicker
        style={{ width: '100%' }}
        value={[startDate, endDate]}
        getPopupContainer={() => document.querySelector('#filter-lists')}
        onChange={(moments, dateStrings) => {
          if (moments === null) {
            // CASE: Picker was cleared
            this.props.onChange([]);
          } else {
            let dateRange = this.makeDateRange(
              moment.utc(moments[0].toISOString().slice(0, 10) + 'T00:00:00Z'),
              moment.utc(moments[1].toISOString().slice(0, 10) + 'T00:00:00Z')
            );
            let newValues = dateRange.map(m => {
              return {
                label: m.toISOString(),
                value: m.toISOString()
              };
            });
            this.props.onChange(newValues);
          }
        }}
      />
    );
  };
}

// return (
//   <div className='custom-range-selector'>
//     <div className='custom-range-selector-parts'>
//       <div className='tooltip-part-container'>
//         <div className='tooltip-buffer'>
//           <div className='tooltip-tray'>
//             {tooltips}
//           </div>
//         </div>
//       </div>
//       <div className='antd-part-container'>
//         <Slider
//           min={0}
//           max={numChoices - 1}
//           marks={marks}
//           range={true}
//           tooltipVisible={false}
//           tipFormatter={ix => (currentOptions[ix] && currentOptions[ix].label) || ''}
//           onChange={this.handleVisibleChange}
//           onAfterChange={this.handleActualChange}
//           defaultValue={this.state.actuallySelectedIndices}
//           value={this.state.visuallySelectedIndices}
//         />
//       </div>
//     </div>
//   </div>
// );

decorate(AntdRangeSelect, {
  state: observable,
  handleVisibleChange: action,
  handleActualChange: action
});
export default observer(AntdRangeSelect);
