import React, { Component } from 'react';

//http requests
import axios from 'axios';

import socket from '../../socketio';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

import SpinLoader from '../loaders/SpinLoader';
import Notification from '../../components/notifications/StandardNotificationRenderer';
import { ErrorModal } from '../modules/sourcing/views/Modals/ErrorModal';


class ImportJobsRenderer extends Component {

    mobxState = {
        columnDefs: [
            {
                field: "project",
                headerName: "Project Imported",
                minWidth: 300,
                cellRenderer: (params) => {
                    if (!params.value.id) {
                        // return value of project_name if project object is null
                        return params.data.project_name;
                    }
                    return `<a class="link_style" href="/sourcing/projects/${params.value.id}">${params.value.name}</a>`;
                },
            },
            {
                headerName: "Status",
                field: "is_success",
                cellRendererFramework: (params) => {
                    let icon = <SpinLoader
                        size={'1x'}
                        textStyle={{ marginLeft: '0.3125em' }} />;
                    if (params.data.status === 'completed') {
                        icon = <FontAwesomeIcon icon='check' transform='' style={{
                            color: '#00b300'
                        }} />
                    } else if (params.data.status === 'failed') {
                        icon = <FontAwesomeIcon icon='times' transform='' style={{
                            color: '#ff0000'
                        }} />
                    }
                    return <div>{icon}</div>
                }

            },
            {
                headerName: "Import Start",
                field: "created_at",
                cellRenderer: (params) => {
                    const created_at = new Date(params.value);
                    return created_at.toLocaleString();
                },
            },
            {
                headerName: "Duration",
                field: "duration",
                cellRenderer: (params) => {
                    const created_at = new Date(params.data.created_at);
                    const updated_at = new Date(params.data.updated_at);
                    const now = new Date();
                    var duration = Math.round((now - created_at) / 1000);
                    if (updated_at > created_at) {
                        var duration = Math.round((updated_at - created_at) / 1000);
                    }

                    // return duration wiht seconds or minutes
                    if (duration < 60) {
                        return `${duration} seconds`;
                    } else {
                        return `${Math.round(duration / 60)} minutes`;
                    }
                },
            },
            {
                headerName: "Error Details",
                field: "message",
                cellRendererFramework: (params) => {
                    return params.value ? 
                    <span className='error-details-link' onClick={() => this.handleOpenPopover(params.value)}>Click here for details</span>
                    : '-';
                }
            },
            {
                headerName: "Retry",
                field: "retry",
                cellRendererFramework: (params) => {
                    const now = new Date();
                    const created_at = new Date(params.data.created_at);

                    // return retry icon if job is not completed and created_at is more than 5 minutes
                    if (params.data.status === 'failed') {
                        return (
                            <div>
                                <a onClick={this.handleFlexProjectFilter.bind(this, params.data)}>
                                    <FontAwesomeIcon icon={['fal', 'redo']} transform=''/>
                                </a>
                            </div>
                        )

                    } else {
                        return <div>
                            <FontAwesomeIcon icon={['fal', 'redo']} transform='' style={{ color: 'grey' }} />
                        </div>
                    }
                }
            },
            {
                headerName: "Delete",
                field: "delete",
                cellRendererFramework: (params) => {
                    const now = new Date();
                    const created_at = new Date(params.data.created_at);
                    return (
                        <div>
                            <a onClick={this.deleteJobsItem.bind(this, params.data)}>
                                <FontAwesomeIcon icon={['fal', 'trash-alt']} transform='' style={{
                                    color: 'grey'
                                }} />
                            </a>
                        </div>
                    )
                }
            }

        ],
        rowData: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            isPopoverOpen: false,
            error_message: "",
          };
    }

    handleOpenPopover = (message) => {
        this.setState({ isPopoverOpen: true, error_message: message });
      };
    
      handleClosePopover = () => {
        this.setState({ isPopoverOpen: false, error_message: ""});
      };

    componentDidMount() {
        this.notificationRef = React.createRef();
        socket.on("message", (data) => {
            this.fetchData();
        });
        this.fetchData();

    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    deleteJobsItem = (data) => {
        console.log('deleteJobsItem', data);
        axios.delete(`/v1/jobs/${data.id}`).then(async (response) => {
            console.log(response);
            this.addNotification(response.data.success, response.data.title, response.data.message)
            console.log('deleteJobsItem response.data', response.data);
            this.fetchData();
        }).catch((error) => {
            console.log('error', error);

        });
    }

    addNotification(success, title, message) {
        console.log(success, title, message);
        const type = success ? "success" : "danger";
        this.notificationRef.current.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: { duration: 2000 },
            dismissable: { click: true }
        });
    }

    //this handles the selected flex project to import from the dropdown
    handleFlexProjectFilter = (data) => {
        var value = data.flex_id;
        var label = data.project_name;
        if (value) {
            console.log("Importing Project")
            this.mobxState.projectLoading = true;
            console.log(value.toString());
            let urlString = '/v1/projects/flex_import/' + value.toString().trim() + '?supplier_batch_bid_query=' + data.supplier_batch_bid_query;
            //urlString = urlString + '/'
            //'/v1/projects/flex_import/' + value.toString() + '?supplier_batch_bid_query=' + this.mobxState.supplier_batch_bid_query
            if (label) {
                urlString = urlString + '&project_name=' + label.toString();
            }
            axios
                .get(urlString, { timeout: 1000 * 7200 }) //fire off the importer with the selected project with a 120 min (7200 seconds) timeout if it's a large project
                .then(response => {
                    console.log(response);
                    this.addNotification(response.data.success, response.data.title, response.data.message)
                    // reload the grid
                    this.fetchData();
                })
                .catch((e) => {
                    console.log.bind(console) //error catcher
                    this.addNotification(false, 'Failed', 'Project did not imported.')
                })
        }
    };

    //fetch data from server
    fetchData = (limit, offset) => {
        limit = limit || 200;
        offset = offset || 0;
        axios.get(`/v1/jobs?limit=${limit}&offset=${offset}`).then(async (response) => {
            this.mobxState.rowData = response.data;

        }).catch((error) => {
            console.log('error', error);
        });
    };


    render() {
        const { isPopoverOpen, error_message } = this.state;
        return (
            <div
                className="ag-theme-material"
                style={{
                    height: '700px',
                    width: '100%'
                }}
            >
                <AgGridReact
                    columnDefs={this.mobxState.columnDefs}
                    onGridReady={this.onGridReady.bind(this)}
                    rowData={this.mobxState.rowData}>
                </AgGridReact>
                <Notification ref={this.notificationRef} />
                <ErrorModal isOpen={isPopoverOpen} onClose={this.handleClosePopover} 
                modalContent={error_message}/>
            </div>
        );
    }
}

decorate(ImportJobsRenderer, {
    mobxState: observable
});

export default observer(ImportJobsRenderer);