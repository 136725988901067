import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from '../../../../badges/Badge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token
import AliasForm from '../../../../forms/AliasForm'; //the form renderer for custom fields

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken, newJsonFieldSchema } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

class SpendFieldAliases extends React.Component {
  projectId = extractToken(window.location.pathname, '/', 3);
  mobxState = {
    customSchema: {
      schema_definition: {}
    },
    formState: {
      formValid: true
    }
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////

  //data loader
  fetchData = () => {
    //set up custom options for components, then get data for components
    axios
      .get('/v1/custom_schemas', { params: { name: 'system_aliases' } }) //query the project custom field schema for all projects
      .then(response => {
        //get map of field labels from API
        this.mobxState.customSchema = response.data && response.data.custom_schemas && response.data.custom_schemas[0];
        //include any custom field names not in db
        this.mobxState.customOptions = {
          ...this.mobxState.customOptions,
          ...{}
        };
      })
      .catch(console.log.bind(console)); //error catcher
  }; //end fetchData() ///////////////

  handleAddField = fieldLevel => {};

  handleDeleteField = (fieldLevel, fieldName) => {};

  //handles change in the projectFieldSchma by looking up the path of the property given in param with lodash and setting the property to the fieldValue
  handleFormChange = (propertyPath, fieldValue) => {
    _.set(this.mobxState.customSchema, propertyPath, fieldValue);
    axios
      .put('/v1/custom_schemas/' + this.mobxState.customSchema.id, {
        schema_definition: this.mobxState.customSchema['schema_definition']
      })
      .then(response => {
        console.log(response);
      })
      .catch(console.log.bind(console));
  };

  render() {
    return (
      <div>
        <div
          className='jumbotron jumbotron_full_width'
          style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
        >
          <div className='jumbotron_full_width_container'>
            <div className='row'>
              <div className='col-sm-5'>
                <span className='fa-layers fa-fw fa-2x'>
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform='grow-3'
                    color='#53C8C5'
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fas', 'folders']}
                    color='white'
                    transform='shrink-5'
                    style={{ marginTop: '3px' }}
                  />
                </span>
                &nbsp;
                <b className='dash_header'>Edit Field Aliases For Spend Models</b>
              </div>
              <div className='col-sm-2'></div>
              <div className='col-sm-2'></div>
              <div className='col-sm-3 gps'>
                <FontAwesomeIcon
                  icon={['fad', 'location-arrow']}
                  color='#53C8C5'
                  transform='grow-15 left-10'
                  style={{}}
                />
              </div>
            </div>
          </div>
        </div>

        {/* PROJECT FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Project Field Aliases</b>
                <span className='pull-right'></span>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className={'top_panel_info'}>
                      System fields for projects. These fields describe or provide info about projects in the system.
                    </div>
                  </div>
                </div>
                <br />

                <AliasForm
                  handleFormChange={this.handleFormChange}
                  customSchema={this.mobxState.customSchema}
                  schemaLevel={'schema_definition'}
                  modelsToEdit={['project']}
                />
              </div>
            </div>
          </div>
        </div>
        {/* ITEM FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Item Field Aliases</b>
                <span className='pull-right'></span>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className={'top_panel_info'}>
                      System fields for items. These fields describe or provide info about items in the system.
                    </div>
                  </div>
                </div>
                <br />

                <AliasForm
                  handleFormChange={this.handleFormChange}
                  customSchema={this.mobxState.customSchema}
                  schemaLevel={'schema_definition'}
                  modelsToEdit={['item']}
                />
              </div>
            </div>
          </div>
        </div>
        {/* BID FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Bid Field Aliases</b>
                <span className='pull-right'></span>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className={'top_panel_info'}>
                      System fields for bids. These fields describe or provide info about bids in the system.
                    </div>
                  </div>
                </div>
                <br />

                <AliasForm
                  handleFormChange={this.handleFormChange}
                  customSchema={this.mobxState.customSchema}
                  schemaLevel={'schema_definition'}
                  modelsToEdit={['bid']}
                />
              </div>
            </div>
          </div>
        </div>
        {/* FREIGHT_BID FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Freight Bid Field Aliases</b>
                <span className='pull-right'></span>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className={'top_panel_info'}>
                      System fields for freight bids. These fields describe or provide info about freight bids in the system.
                    </div>
                  </div>
                </div>
                <br />

                <AliasForm
                  handleFormChange={this.handleFormChange}
                  customSchema={this.mobxState.customSchema}
                  schemaLevel={'schema_definition'}
                  modelsToEdit={['freight_bid']}
                />
              </div>
            </div>
          </div>
        </div>
        {/* SUPPLIER FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Supplier Field Aliases</b>
                <span className='pull-right'></span>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className={'top_panel_info'}>
                      System fields for suppliers. These fields describe or provide info about suppliers in the system.
                    </div>
                  </div>
                </div>
                <br />

                <AliasForm
                  handleFormChange={this.handleFormChange}
                  customSchema={this.mobxState.customSchema}
                  schemaLevel={'schema_definition'}
                  modelsToEdit={['supplier']}
                />
              </div>
            </div>
          </div>
        </div>

        {/* END row*/}
        {/* END ITEM FORM*/}
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(SpendFieldAliases, {
  mobxState: observable
});

export default observer(SpendFieldAliases);

// export default ProjectOverview;
