//user image upload for custom logo (replaces "ECSouring" logo in top header)

import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const _ = require('lodash'); //extra utilities

class LogoManagement extends React.Component {


  mobxState = {
    imageData: {
      image: null, //holds blob data of image to send to server
      validImage: false, //toggles submit button
      previewImage: null,
      imageType: null
    },
  }

  runImageChecks = async (e) => {
    var files = e.target.files,
      file;
    console.log("FILES ",files[0] )
    if (!files || files.length === 0) return;
    file = files[0];

    await this.checkImage(e, file) //checks dimensions ,file type and file size

  }
  //clear input form and state
  clearInputAndState() {
    this.inputFormRef.reset();
    this.mobxState.imageData.validImage = false;
    this.mobxState.imageData.image = null;
    this.mobxState.imageData.previewImage = null;
  }

  //check uploaded image properties
  checkImage = (e, image) => {
    let inputValue = e.target.value;
    //size check
    //keep files larger than 1mb from being submitted
    if(_.round(image.size / (1024 * 1024), 2) >  1){
      console.log("File is too large", e.target.value)
      confirmAlert({
        message: 'File is too large (1mb limit)',
        buttons: [ { label: 'Ok', }]
      });
      this.clearInputAndState();
      return
    }
    //File type check
    console.log("TYPE ", image)
    if(image.type === "image/png") {
      console.log("Valid PNG", image.type)
    } else if(image.type === "image/jpeg"){
      console.log("Valid Jpg ", image.type)
    } else {
      console.log("Invalid Image Type ", image.type)
      confirmAlert({
        message: "Incorret File Type. Image must be .png or .jpg ",
        buttons: [ { label: 'Ok', }]
      });
      this.clearInputAndState();
      return
    }
    //final check for image dimensions then put image data into state
    const fr = new FileReader;
    fr.onload = function() { // file is loaded
        let img = new Image;

        img.onload = () => {
            let height = img.height;
            let width = img.width;
            if(height !== 30 || width !== 200) {
              console.log(height, width)
              console.log("Incorrect image dimensions")
              confirmAlert({
                message: `Incorret image dimensions. Dimensions must be: (Width: 200px, Height: 30px) `,
                buttons: [ { label: 'Ok', }]
              });
              this.clearInputAndState()
              console.log(inputValue)
            } else {
              console.log("valid dimensions")
            }
        }
        let base64Image = fr.result.split(',')[1];
        this.mobxState.imageData.image = base64Image
        this.mobxState.imageData.imageType = fr.result
        this.mobxState.imageData.previewImage = fr.result;

        img.src = fr.result; // is the data URL because called with readAsDataURL
    }.bind(this)

    fr.readAsDataURL(image);

    this.mobxState.imageData.validImage = true;
  }

  handleSubmit() {
    console.log("HANDLE IMAGE SUBMIT ")
    axios
      .put(`/v1/custom_schemas/client_logo/upload`,
        {'logo': this.mobxState.imageData.image})
      .then((res) => {
        console.log(res)
        window.location.reload(); //refresh to show new logo
      })
      .catch((err) => {
        alert("There was an error submitting your image.")
        console.log(err)
      })
  }


  render() {

    return(
      <div>

        <div className="jumbotron jumbotron_full_width" style={{maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap'}}>
          <div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-5">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#53C8C5" style={{marginTop: '4px'}} />
                  <FontAwesomeIcon icon={['fas', 'file-image']} color="white" transform="shrink-5" style={{marginTop: '3px'}} />
                </span>
                &nbsp;
                <b className="dash_header">Logo Management</b>
              </div>
              <div className="col-sm-2"></div>
              <div className="col-sm-2"></div>

            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg clearfix">
                <b>Upload new logo</b>
              </div>
              <div className="panel-body">
                <div className="col-lg-12">
                  <React.Fragment>
                    <span className="input-group image-group" draggable="false">
                      <span className='require-list'>
                        <ul>
                          <b>Image Requirements:</b>
                          <li>Maximum File Size is 1mb</li>
                          <li>Image must be either .png or .jpg</li>
                          <li>Dimensions must be W: 200px H: 30px</li>
                        </ul>
                      </span>

                      <form className="image-input-form" ref={(el) => this.inputFormRef = el}>
                        <label htmlFor="file-input">
                          Select or Drag Image File
                          <FontAwesomeIcon icon={['fad', 'file-import']} size="1x" transform="shrink-2" color="grey" />
                        </label>
                        <input
                          className="image-input"
                          type="file"
                          onChange={this.runImageChecks}
                          placeholder="Drag File Here"
                        />
                      </form>
                      {/* Show Submit and cancel Buttons and image preview */}


                      {this.mobxState.imageData.validImage &&

                        <footer className="import-footer" style={{ marginTop: '1em'}}>
                          <div className="image-preview">
                            <img src={this.mobxState.imageData.previewImage} />
                          </div>
                          <div className="btn-group step-btns" role="group">
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                style={{ marginRight: '.5em', borderRadius: '4px' }}
                                className="btn btn-secondary btn-md"
                                onClick={() => {
                                  this.clearInputAndState();
                                }}
                              >
                                &nbsp;Cancel
                              </button>
                            </div>
                            {/* Submit Button */}
                            <div className=" btn-group" role="group">
                              <button
                                type="button"
                                style={{ borderRadius: '4px', backgroundColor: '#2196f3' }}
                                className="btn btn-success import-submit-btn btn-md"
                                onClick={() => {
                                  this.handleSubmit()
                                }}
                              >
                                Submit&nbsp;
                              </button>
                            </div>
                          </div>
                        </footer>
                      }
                    </span>

                    </React.Fragment>
                </div>
              </div>
            </div>
          </div>{/* END col-sm-12*/}
        </div>{/* END row*/}
      </div>
    )

  }
}

decorate(LogoManagement, {
  mobxState: observable
});

export default observer(LogoManagement)
