import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utility
import { zeroNull, extractToken } from '../../services/Utilities';
//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url

export default class DeleteButtonRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span>
        {/*<Link to={`${window.location.pathname}/${this.props.data.id}/edit`}>*/}
          <span className='fa-layers fa-fw fa-2x link_style'>
            <FontAwesomeIcon icon={['fal', 'trash']} style={{ marginTop: '6px' }} />
          </span>
        {/*</Link>*/}
      </span>
    );
  }
}
