import React from 'react';
import { render } from 'react-dom';

//other components built
import AsyncBadge from '../../../../badges/AsyncBadge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//forms

//highcharts
import CustomChartRenderer from '../../../../charts/custom/CovidChartRenderer';

//percentage donut
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, deleteIfEmpty, formatForTreeSelect, isValidColor } from '../../../../services/Utilities';
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';
import { asyncLoadCharts } from '../../../../services/ChartUtilities';

// Override client globals for this one page
const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

class SamlConfigPage extends React.Component {
  pageId = 218;
  mobxState = {
    cardData: {},
    chartRenderData: [],
    tableData: {
      spend_reclassification_rules: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    customFilterModelNames: [],
    filterOptions: {},
    customFilterOptions: {},
    customOptions: {},
    filtersForServer: {}, // Filters in normal form to use for the server
    // Form specific stuff
    formState: {}
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //helpers for system filters
  //helpers for custom filters
  //setup filters
  setupFilters = async () => {};
  //data loader
  fetchData = () => {
    this.setupFilters();

    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}) // Prefer existing values
      };

      // Grab the existing values for the form
      axios.get('/microservices/v1/domain/ec_url').then(resp => {
        this.mobxState.formState = resp.data;
      });
    });
  }; //end fetchData() ///////////////
  handleChange = (path, value) => {
    _.set(this.mobxState.formState, path, value);
    console.log(`state: ${JSON.stringify(this.mobxState.formState, null, 2)}`);
  };
  validateState = () => true;
  handleSubmit = () => {
    if (this.validateState()) {
      axios.put(`/microservices/v1/domain/ec_url`, this.mobxState.formState).then(resp => {
        alert('Saved Successfully');
      });
    }
  };

  //////////////////END HTTP/////////////////
  render() {
    return (
      <div>
        <div
          className='jumbotron jumbotron_full_width'
          style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
        >
          <div className='jumbotron_full_width_container'>
            <div className='row'>
              <div className='col-sm-12'>
                <span className='fa-layers fa-fw fa-2x'>
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform='grow-3'
                    color='#53C8C5'
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fas', 'palette']}
                    color='white'
                    transform='shrink-5'
                    style={{ marginTop: '3px' }}
                  />
                </span>
                &nbsp;
                <b className='dash_header'>SSO Admin</b>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>SAML Config</b>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  {/* SSO Redirect */}
                  <div className='col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor='postlogin_redirect_path-input'>Postlogin Redirect Path</label>
                      <input
                        id='postlogin_redirect_path-input'
                        type='text'
                        name='postlogin_redirect_path'
                        className='form-control'
                        value={this.mobxState.formState.postlogin_redirect_path || ''}
                        onChange={e => this.handleChange(['postlogin_redirect_path'], e.target.value)}
                      />
                    </div>
                  </div>
                  {/* Name Id Attribute */}
                  <div className='col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor='name_id_attribute-input'>User Email Attribute</label>
                      <input
                        id='name_id_attribute-input'
                        type='text'
                        name='name_id_attribute'
                        className='form-control'
                        value={this.mobxState.formState.name_id_attribute || ''}
                        onChange={e => this.handleChange(['name_id_attribute'], e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>Sidebar Modules</b>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  {/* Terminal Link */}
                  <div className='col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor='terminal-url-input'>Terminal URL</label>
                      <input
                        id='terminal-url-input'
                        type='text'
                        name='terminal_url'
                        className='form-control'
                        value={this.mobxState.formState.terminal_url || ''}
                        onChange={e => this.handleChange(['terminal_url'], e.target.value)}
                      />
                    </div>
                  </div>
                  {/* Analytics Link  */}
                  <div className='col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor='analytics-url-input'>Analytics URL</label>
                      <input
                        id='analytics-url-input'
                        type='text'
                        name='analytics_url'
                        className='form-control'
                        value={this.mobxState.formState.analytics_url || ''}
                        onChange={e => this.handleChange(['analytics_url'], e.target.value)}
                      />
                    </div>
                  </div>
                  {/* SourceForce Link  */}
                  <div className='col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor='sourceforce-url-input'>Sourceforce URL</label>
                      <input
                        id='sourceforce-url-input'
                        type='text'
                        name='sourceforce_url'
                        className='form-control'
                        value={this.mobxState.formState.sourceforce_url || ''}
                        onChange={e => this.handleChange(['sourceforce_url'], e.target.value)}
                      />
                    </div>
                  </div>
                  {/* Esourcing Link  */}
                  <div className='col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor='esourcing-url-input'>Esourcing URL</label>
                      <input
                        id='esourcing-url-input'
                        type='text'
                        name='esourcing_url'
                        className='form-control'
                        value={this.mobxState.formState.esourcing_url || ''}
                        onChange={e => this.handleChange(['esourcing_url'], e.target.value)}
                      />
                    </div>
                  </div>
                  {/* BuyDesk Link  */}
                  <div className='col-xs-12'>
                    <div className='form-group'>
                      <label htmlFor='buydesk-url-input'>Buydesk URL</label>
                      <input
                        id='buydesk-url-input'
                        type='text'
                        name='buydesk_url'
                        className='form-control'
                        value={this.mobxState.formState.buydesk_url || ''}
                        onChange={e => this.handleChange(['buydesk_url'], e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-body'>
                <div className='row'>
                  <div className="col-xs-12">
                    <button type='button' className='btn btn-primary btn-block' onClick={this.handleSubmit}>
                      <FontAwesomeIcon icon={['fas', 'plus']} />
                      &nbsp; Publish Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(SamlConfigPage, {
  mobxState: observable
});

export default observer(SamlConfigPage);

// export default SamlConfigPage ;
