import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';


// PROPS
// shouldShow :: Whether to render link
class SidebarLink extends React.Component {
  static defaultProps = {
    shouldShow: true
  };
  render() {
    let {shouldShow, ...linkProps} = this.props;
    if (shouldShow) {
      return (
        <Link {...linkProps}>
          {this.props.children}
        </Link>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

export default SidebarLink;
