import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
// does not update properly
// const projectIdParam = extractToken(window.location.pathname, '/', 3);

export default class ParamsRenderer extends Component {
  constructor(props) {
    super(props);

  }

  render() {

    return (
      <span>
        {/* CompetitionConstraints */}
        {this.props.data.competition_constraint_count>=1 &&
          <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/CompetitionConstraints'}>
            <span className="fa-layers fa-fw fa-2x link_style" title="Competition Constraints">
              <FontAwesomeIcon icon={['fal', 'circle']} style={{marginTop: '6px'}}  />
              <span className="fa-layers-text" style={{fontWeight:'900', fontSize:'14px', marginTop: '7px'}}>Co</span>
              <span className="fa-layers-counter fa-2x" style={{background:'#004F7D', marginTop:'-2px'}}>
                {this.props.data.competition_constraint_count}
              </span>
            </span>
          </Link>
        }

        {/* BidderConstraints */}
        {this.props.data.supplier_constraint_count>=1 &&
          <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/SupplierConstraints'} >
            <span className="fa-layers fa-fw fa-2x link_style" title="Bidder Constraints">
              <FontAwesomeIcon icon={['fal', 'circle']} style={{marginTop: '6px'}} />
              <span className="fa-layers-text" style={{fontWeight:'900', fontSize:'14px', marginTop: '7px'}}>Bi</span>
              <span className="fa-layers-counter fa-2x" style={{background:'#004F7D', marginTop:'-2px'}}>
                {this.props.data.supplier_constraint_count}
              </span>
            </span>
          </Link>
        }

        {/* QualitativeConstraints */}
        {this.props.data.qualitative_constraint_count>=1 &&
          <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/QualitativeConstraints'} >
            <span className="fa-layers fa-fw fa-2x link_style" title="Qualitative Constraints">
              <FontAwesomeIcon icon={['fal', 'circle']} style={{marginTop: '6px'}} />
              <span className="fa-layers-text" style={{fontWeight:'900', fontSize:'14px', marginTop: '7px'}}>Ql</span>
              <span className="fa-layers-counter fa-2x" style={{background:'#004F7D', marginTop:'-2px'}}>
                {this.props.data.qualitative_constraint_count}
              </span>
            </span>
          </Link>
        }

        {/* QuantitativeConstraints */}
        {this.props.data.quantitative_constraint_count>=1 &&
          <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/QuantitativeConstraints'} >
            <span className="fa-layers fa-fw fa-2x link_style" title="Quantitative Constraints">
              <FontAwesomeIcon icon={['fal', 'circle']} style={{marginTop: '6px'}} />
              <span className="fa-layers-text" style={{fontWeight:'900', fontSize:'14px', marginTop: '7px'}}>Qn</span>
              <span className="fa-layers-counter fa-2x" style={{background:'#004F7D', marginTop:'-2px'}}>
                {this.props.data.quantitative_constraint_count}
              </span>
            </span>
          </Link>
        }

        {/* DiscountConstraints */}
        {this.props.data.discount_constraint_count>=1 &&
          <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/DiscountConstraints'} >
            <span className="fa-layers fa-fw fa-2x link_style" title="Discount Constraints">
              <FontAwesomeIcon icon={['fal', 'circle']} style={{marginTop: '6px'}} />
              <span className="fa-layers-text" style={{fontWeight:'900', fontSize:'14px', marginTop: '7px'}}>Ds</span>
              <span className="fa-layers-counter fa-2x" style={{background:'#004F7D', marginTop:'-2px'}}>
                {this.props.data.discount_constraint_count}
              </span>
            </span>
          </Link>
        }

        {/* SubsetConstraints */}
        {this.props.data.subset_constraint_count>=1 &&
          <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/SubsetConstraints'} >
            <span className="fa-layers fa-fw fa-2x link_style" title="Subset Constraints">
              <FontAwesomeIcon icon={['fal', 'circle']} style={{marginTop: '6px'}} />
              <span className="fa-layers-text" style={{fontWeight:'900', fontSize:'14px', marginTop: '7px'}}>Ss</span>
              <span className="fa-layers-counter fa-2x" style={{background:'#004F7D', marginTop:'-2px'}}>
                {this.props.data.subset_constraint_count}
              </span>
            </span>
          </Link>
        }

        {/* FreightMatchConstraints */}
        {this.props.data.freight_match_constraint_count>=1 &&
          <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/FreightMatchConstraints'} >
            <span className="fa-layers fa-fw fa-2x link_style" title="Freight Match Constraints">
              <FontAwesomeIcon icon={['fal', 'circle']} style={{marginTop: '6px'}} />
              <span className="fa-layers-text" style={{fontWeight:'900', fontSize:'14px', marginTop: '7px'}}>Fm</span>
              <span className="fa-layers-counter fa-2x" style={{background:'#004F7D', marginTop:'-2px'}}>
                {this.props.data.freight_match_constraint_count}
              </span>
            </span>
          </Link>
        }

        {/* FreightBracketConstraints */}
        {this.props.data.freight_bracket_constraint_count>=1 &&
          <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/FreightBracketConstraints'} >
            <span className="fa-layers fa-fw fa-2x link_style" title="Freight Bracket Constraints">
              <FontAwesomeIcon icon={['fal', 'circle']} style={{marginTop: '6px'}} />
              <span className="fa-layers-text" style={{fontWeight:'900', fontSize:'14px', marginTop: '7px'}}>Fb</span>
              <span className="fa-layers-counter fa-2x" style={{background:'#004F7D', marginTop:'-2px'}}>
                {this.props.data.freight_bracket_constraint_count}
              </span>
            </span>
          </Link>
        }

      </span>
    );
  }
};
