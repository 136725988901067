import React from 'react';
import { render } from 'react-dom';

//other components built
import AsyncBadge from '../../../../badges/AsyncBadge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter
import FilterCountIndicator from '../../../../filters/FilterCountIndicator';
import FilterTray from '../../../../filters/FilterTray';

//highcharts
import CustomBadgeRenderer from '../../../../badges/custom/CustomBadgeRenderer';
import CustomChartRenderer from '../../../../charts/custom/CovidChartRenderer';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, deleteIfEmpty } from '../../../../services/Utilities';
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Wizard from '../../../../services/Wizard';

import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';
import { asyncLoadCharts } from '../../../../services/ChartUtilities';
import { humanizeString } from '../../../../services/Utilities';
import FileImporter from './FileImporter';

const _ = require('lodash'); //extra utilities

// <Wizard Page 1: Select what type of import you're doing>
// importer.setModelNameForMapping(user_selection) // Wire this to your select
// importer.generateTemplate() // Use selected model name to generate template
// <Wizard Page 2: Import the file>
// importer.addFile(window.$("input[type='file']").files[0]);
// <Wizard Page 3: Setup mappings>
// importer.addMapping('year', 'year_of_sale') //
// importer.validateSchema() // Check based on first few rows
// importer.submit() // Send both file and mapping
// <Wizard Page 4: Show Results>

class Step1 extends React.Component {
  render() {
    window.step1 = this;
    console.log(this.props.importer && this.props.importer.mobxState.modelName);
    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-heading panel_bg clearfix'>
            <b>Step {this.props.stepNumber}: Please select what you want to import</b>
          </div>
          <div className='panel-body'>
            <div className='col-xs-12'>
              <Select
                onChange={e => this.props.importer.setModelForMapping(e.value)}
                options={Object.keys(this.props.schema).map(k => ({ value: k, label: humanizeString(k) }))}
                value={
                  this.props.importer &&
                  this.props.importer.mobxState.modelName && {
                    value: this.props.importer.mobxState.modelName,
                    label: humanizeString(this.props.importer.mobxState.modelName)
                  }
                }
                closeMenuOnSelect={true}
                maxMenuHeight={400}
                placeholder={'Select From List of Saved Project Tables'}
              />
              Selected: {`${this.props.importer && this.props.importer.mobxState.modelName}`}
            </div>
          </div>
        </div>
        <div className='panel-footer'>
          <div className=''>
            {this.props.canStepBack && (
              <button type='button' class='btn btn-default' onClick={this.props.stepBack}>
                Previous Step
              </button>
            )}
            {this.props.canStepForward && (
              <button type='button' class='btn btn-default' onClick={this.props.stepForward}>
                Next Step
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
class Step2 extends React.Component {
  render() {
    window.step2 = this;
    return (
      <div>
        <div className='panel-heading'>
          <b>Step {this.props.stepNumber}: Please select what you want to import</b>
        </div>
        <div className='panel-body'>
          <input
            className='excel-input'
            type='file'
            onChange={e => this.handleFileSelect(e.target.files[0])}
            placeholder='Drag File Here'
          />
        </div>
        <div className='panel-footer'>
          <div className=''>
            {this.props.canStepBack && (
              <button type='button' class='btn btn-default' onClick={this.props.stepBack}>
                Previous Step
              </button>
            )}
            {this.props.canStepForward && (
              <button type='button' class='btn btn-default' onClick={this.props.stepForward}>
                Next Step
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
class Step3 extends React.Component {
  render() {
    window.step3 = this;
    return (
      <div>
        <div className='panel-heading'>
          <b>Step {this.props.stepNumber} </b>
        </div>
        <div className='panel-body'>
          <input
            className='excel-input'
            type='file'
            onChange={e => this.handleFileSelect(e.target.files[0])}
            placeholder='Drag File Here'
          />
        </div>
        <div className='panel-footer'>
          <div className=''>
            {this.props.canStepBack && (
              <button type='button' class='btn btn-default' onClick={this.props.stepBack}>
                Previous Step
              </button>
            )}
            {this.props.canStepForward && (
              <button type='button' class='btn btn-default' onClick={this.props.stepForward}>
                Next Step
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class CategoryImportForm extends React.Component {
  mobxState = {
    importer: null
  };
  componentDidMount() {
    this.mobxState.importer = new FileImporter();
    this.mobxState.importer.addSchema(this.props.schema); // Todo: Move this to a network call
  }
  render() {
    // This is a little hacky, since the importer doesn't itself change, we need
    // to pass it's internal state as a separate object so that changes are recognized
    return (
      <div className='panel panel-default'>
        <div className='step-progress'>
          <Wizard
            steps={[
              <Step1
                importer={this.mobxState.importer}
                importerState={{ ...((this.mobxState.importer && this.mobxState.importer.mobxState) || {}) }}
                {...this.props}
              />,
              <Step2
                importer={this.mobxState.importer}
                importerState={{ ...((this.mobxState.importer && this.mobxState.importer.mobxState) || {}) }}
                {...this.props}
              />,
              <Step3
                importer={this.mobxState.importer}
                importerState={{ ...((this.mobxState.importer && this.mobxState.importer.mobxState) || {}) }}
                {...this.props}
              />
            ]}
          />
        </div>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(CategoryImportForm, {
  mobxState: observable
});

export default observer(CategoryImportForm);
