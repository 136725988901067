import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///!!! USED IN THE SETUP AND PROJECTS MODULES SO FAR, BE CAREFUL EDITING, MAKE SURE IT DOESN'T BREAK OTHER FORMS!!!
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

class UserSettingsForm extends React.Component {

  render() {
    const formDisplay = []
    let i = 0; // console.log('UserSettingsForm userSettingsSet', typeof(this.props.projectFieldSchema) == 'object', JSON.stringify(this.props.projectFieldSchema)) ;
    if (typeof(this.props.projectFieldSchema) == 'object'){
      Object.entries(this.props.projectFieldSchema).map(([key, value]) => { 
        let id = value.id ; 
        if (typeof(id) == 'number') { // console.log('UserSettingsForm  value', JSON.stringify(value)) ;
          formDisplay.push(
            <tr key={`user-settings-form-row-${key}`} data-index={`${key}`}>
              {/*
              <td>
                <b style={{fontSize: '14px', display: 'flex', alignItems: 'center', height: '40px'}}>Item {_.startCase(value.field_name)} </b>
              </td>
              */}
              <td>
                <Select
                  type={'string'}
                  onChange={(option) => {this.props.handleFormChangeOptions([key, "feature_desc"], option)}}
                  options={[
                    {label:'Least Cost', value: 'Least Cost'},
                    {label:'Limit Bidders', value: 'Limit Bidders'},
                    {label:'Incumbents %', value: 'Incumbents %'}
                  ]}
                  placeholder={value.feature_desc}
                  value={{label: `${value.feature_desc}`, value: `${value.feature_desc}`}}
                />
              </td>
              <td>
                <Input
                  value={value.numeric_value}
                  placeholder={value.numeric_value || 'Numeric value for the feature'}
                  className="form-control"
                  onChange={(e) => {this.props.handleFormChangeNumeric([key, "numeric_value"], e, e.target.value || 0)}}
                />
              </td>
              <td>
                <Select
                  type={'string'}
                  onChange={(option) => {this.props.handleFormChangeOptions( [key, "is_active"], option ) } }
                  options={[{label:'true', value: true}, {label:'false', value: false}]}
                  value={
                    [true, "true"].includes(this.props.projectFieldSchema[key].is_active)
                    ? {label:'true', value: true}
                    : {label:'false', value: false}
                  }
                />
              </td>
              <td>
                <Select
                  type={'number'}
                  onChange={(option) => {this.props.handleFormChangeOptions( [key, "display_order"], option ) } }
                  options={[
                    {label: "1", value: "1"},
                    {label: "2", value: "2"},
                    {label: "3", value: "3"}
                  ]}
                  placeholder={value.display_order}
                  value={{label: `${value.display_order}`, value: `${value.display_order}`}}
                />
              </td>
              <td>
                <div>
                  <span>
                    <a data-toggle="collapse" href={'#modal_custom_field_22_schema_definition'} onClick={() => this.props.handleDeleteField(i, value.id)}>
                      <span className="glyphicon glyphicon-remove-circle modal_glyphi text-danger" aria-hidden="true" style={{marginTop: '10px'}}></span>
                      <span> Remove </span>
                    </a>
                  </span>
                </div>
              </td>
            </tr>
          );
          i++;
        } // end if id is a number
      })
    }

    return (
      <div>

        <form>
          <table className="table table-striped">
            <thead>   
              <tr>
                {/* <th style={{width:'14%'}}><b>Field In System</b></th> */}
                <th><b>Feature</b></th>
                <th><b>Numeric Value</b></th>
                <th><b>Is Active</b></th>
                <th><b>Display Order</b></th>
                <th style={{width:'10%'}}><b>Deletion</b></th>
              </tr>
            </thead>
            <tbody>
              {formDisplay}
            </tbody>
          </table>
        </form>

      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(UserSettingsForm, {
  mobxState: observable
});

export default observer(UserSettingsForm);

// export default ProjectOverview;
