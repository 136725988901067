import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Badge extends React.Component {
  render() {
    return (
      <div className='card legacy-badge'>
        <div className='card_container'>
          <div className='card_icon'>
            <div className='icon_container'>
              <div className='icon_outline'>
                <FontAwesomeIcon
                  className=''
                  icon={['fal', this.props.faClass]}
                  style={{ color: 'white', fontSize: '30px' }} />
              </div>
            </div>
          </div>
          <div className='card_details'>
            <div className='card_top'>{this.props.badgeNumber}</div>
            <div className='card_bottom'>{this.props.badgeName}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Badge;
