import React from "react";
import { render } from 'react-dom';

//other components built
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns

//filters
import DockRenderer from "../../../../filters/DockRenderer";
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls
import Select from 'react-select'; //select box for filter
import PlayButton from '../../shared/PlayButton'; //play button that executes scenario

//forms
import { AutoComplete, Input, Checkbox, InputNumber } from 'antd';

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//security
import {checkRole} from '../../../../security/SecurityUtilities';

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import {zeroNull, extractToken} from "../../../../services/Utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class ScenarioManager extends React.Component {
		// Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
		projectId = extractToken(window.location.pathname, '/', 3);
		scenarioId = extractToken(window.location.pathname, '/', 5);
		mobxState = {
			formState: {scenarioName: ''},
			activeFormState: {name: ''},
			customOptions: {},
			cardData: {
				scenario: {
				}
			},
			tableData: {
			},
			errorPanel: { //holds data related to error panel
				scenarioContainsErrors: false, //bool to toogle the panel
				hasNullOrZeroCustom: false, //toggles list of custom_fields with null or zero
				fullCustomList: null, //complate list or custom_fields fields returned
				//ITEM ERROR INFO
				hasInvalidItemQuantity: false, //toggles list of null or 0 quantity items
				nullQuantityList: [], //holds list of item names and quantities that are null or 0
				//BID AND ITEM ERROR INFO
				hasInvalidBidPrice: false, //toggles list of bids with invalid prices
				invalidPriceOnly: false, //used to toggle display for errors when only bid price is selected
				invalidBidPriceList: {}, //holds list of bids with 0 or null prices
				groupedPrices: {}, // //holds list of bids with 0 or null prices from the price only check (grouped by round)
				itemBidErrors: false, //if custom field is null show list of names that have the corresponding bids
				itemBidErrorList: null, //holds list of items that have bids where current custom_field is null
			},
			formState: { //fetchData fetchets this object with scenario settings
				name: null,
				optimizer_field: 'price',
	      freight_match: 'true'
			},
			optimizerSelectValue: {},
			projectFieldSchema: {
				bidLevel: {}
			},
			formSelect: {
				optimizerFieldSelect: [{'label':'Bid Price','value':'price'}], //start off with the basic price, http calls appends to this array
				roundSelect: [{'label':'1','value':'1'}],
				confidenceSelect: [{'label':'.1','value':'.1'},{'label':'.01','value':'.01'},{'label':'.001','value':'.001'},{'label':'.0001','value':'.0001'},{'label':'.00001','value':'.00001'}],
				matchSelect: [{'label': 'Use same bidder for item and freight','value': true}, {'label':'Do not attempt matching item and freight bidders','value': false}]
			},
			scenarioAutofill: [],
			selectedModels: {
			}, //multi select data model for http
			selectedOptions: {
			}, //multi select data model in filter value/label format
			dockOptions: {
				isVisible: false,
				size: 0.2
			}, //sidebar defaults
			pivotData: [],
			pivotState: [],
			pivotFilter: [],
			filterModelNames: [], //autobuilds filters and clear functions
			isFreight: false, //if project type is not "Freight" freight related data will be hidden
		};

		//mount data through http requests
		componentDidMount() {
			//call the data loader to load data into the view
			this.fetchData();
		}

		//////////////////HTTP/////////////////
		//data loader
		fetchData = () => {

      // Merge System Aliases Into Custom Options (Keeping existing values)
      grabSystemAliases().then(systemAliases => {
        this.mobxState.customOptions = {
          ...systemAliases,
          ...(this.mobxState.customOptions || {}), // Prefer existing values
        }
      });

			//scenario form data load
			axios.get('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId)
			.then(response => {
				this.mobxState.formState = response.data;
				console.log('formState', this.mobxState.formState);

				//clear select options otherwise select options will double every time fetchData() is called
				this.mobxState.formSelect.optimizerFieldSelect = [{'label':'Bid Price','value':'price'}];

				//scenario form data load for optimizer field
				axios.get('/v1/projects/'+this.projectId+'/project_field_schemas')
				.then(response => {
					//filter response to only numeric bid_level fields
					this.mobxState.projectFieldSchema.bidLevel = Object.fromEntries(Object.entries(response.data.project_field_schemas[0].bid_level).filter(([key, value]) => value.schema.type == 'number'));
					console.log('bidLevel', this.mobxState.projectFieldSchema.bidLevel);

					//loop through bidLevel and create a label, value array for actual react select dropdown
					for (const [key, value] of Object.entries(this.mobxState.projectFieldSchema.bidLevel)) {
						this.mobxState.formSelect.optimizerFieldSelect = this.mobxState.formSelect.optimizerFieldSelect.concat({
							'label': value.field_alias,
							'value': value.field_name
						});
					};
					console.log('optimizerFieldSelect', this.mobxState.formSelect.optimizerFieldSelect);
					//putting the optimizer field into a label, value format
					//filter selections to the one that is selected in the scenario in the db
					let filteredField = this.mobxState.formSelect.optimizerFieldSelect.filter(option => option.value == this.mobxState.formState.optimizer_field);
					console.log('filteredField', filteredField);
					this.mobxState.optimizerSelectValue = {label: filteredField[0].label, value: this.mobxState.formState.optimizer_field};
					console.log('optimizerSelectValue', this.mobxState.optimizerSelectValue);
					this.mobxState.freightMatchSelectValue = this.mobxState.formSelect.matchSelect.filter(option => option.value == this.mobxState.formState.freight_match);
					this.checkOptimizedField(); //run check on selected field
					console.log('freightMatchSelectValue', this.mobxState.freightMatchSelectValue);
				})
			})

			//get project type
			axios.get(`/v1/projects/${this.projectId}`)
			.then(response => {
				// console.log('PROJECT >>>>>>', response.data.project_type);
				response.data.project_type !== "Freight" ? this.mobxState.isFreight = false : this.mobxState.isFreight = true;
			})

			//scenarios load to get the scenario counts
			axios.get('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId)
			.then(response => {
				this.mobxState.cardData.scenario = response.data;
				console.log('scenario', response.data);
			})

			//scenario form data load for round
			axios.get('/v1/projects/'+this.projectId+'/bids/round_filter')
			.then(response => {
				this.mobxState.formSelect.roundSelect = response.data.rounds;
				console.log('roundSelect', this.mobxState.roundSelect);
			})

			//scenario auto populate form options
			axios.get('/v1/projects/scenarios/index_all_names')
			.then(response => {
				this.mobxState.scenarioAutofill = response.data.scenarios;
			})

		} //end fetchData()

		//OPTIMIZATION FIELD CHECK
		checkOptimizedField = async () => {
		  let selectedField = this.mobxState.optimizerSelectValue; //custom field selected in the dropdown
		  let fieldAlias = this.mobxState.optimizerSelectValue.label; //custom_field field alias
		  let fieldName = this.mobxState.optimizerSelectValue.value; //custom field field name
		  console.log('FIELD CHECK ON ', fieldAlias, fieldName);
			//keep panel from showing up when switching selections
			this.mobxState.errorPanel.scenarioContainsErrors = false;
		  //run price check if the select value is price
		  if (_.toLower(fieldName) === 'price' || fieldAlias === 'Bid Price') {
		    await axios.get(`/v1/projects/${this.projectId}/scenarios/optimizer_check/${fieldName}`)
				.then(async response => {
		      //values are from the "price" column in the bids table
		      //clear old results from state
		      this.mobxState.errorPanel.hasInvalidItemQuantity = false;
		      this.mobxState.errorPanel.hasInvalidBidPrice = false;
		      this.mobxState.errorPanel.hasNullOrZeroCustom = false;
		      this.mobxState.errorPanel.fullCustomList = null;
					this.mobxState.errorPanel.itemBidErrorList = null;
					this.mobxState.errorPanel.nullQuantityList = [];
					this.mobxState.errorPanel.invalidBidPriceList = {}
		       console.log('GROUP LIST, ', response);
		      if (_.isEmpty(response.data.priceList.pricePromise) === false) {
		        this.mobxState.errorPanel.invalidPriceOnly = true;
		        this.mobxState.errorPanel.scenarioContainsErrors = true;
		        //group prices by round
		        let groupedPrices = await response.data.priceList.pricePromise.reduce((groups, bid) => {
		          const group = groups[bid.round] || [];
		          group.push(bid);
		          groups[bid.round] = group;
		          return groups;
		        }, {});
		        this.mobxState.errorPanel.groupedPrices = groupedPrices;
		      }
		    });
		  } else {
		    //run price check on the selected custom field alias selected
		    axios
		      .get(`/v1/projects/${this.projectId}/scenarios/optimizer_check/${fieldName}`)
		      .then(async response => {
						console.log("RES ", response)
		        // console.log(
		        //   _.isEmpty(response.data.results.fullCustom),
		        //   !response.data.results.sortedZeroPrice.length,
		        //   !response.data.results.sortedZeroQuantity.length
		        // );
		        //toggle lists and error panel if response has errors
		        if (
		          _.isEmpty(response.data.results.fullCustom) === false ||
		          !response.data.results.sortedZeroPrice.length === false ||
		          !response.data.results.sortedZeroQuantity.length === false ||
							!response.data.results.itemList.length === false
		        ) {
		          this.mobxState.errorPanel.scenarioContainsErrors = true;
		          //clear bid price state
		          this.mobxState.errorPanel.invalidPriceOnly = false;
		          this.mobxState.errorPanel.groupedPrices = {};
		          //populate state
		          this.mobxState.errorPanel.fullCustomList = await response.data.results.fullCustom;
		          this.mobxState.errorPanel.invalidBidPriceList = await response.data.results.sortedZeroPrice;
		          this.mobxState.errorPanel.nullQuantityList = await response.data.results.sortedZeroQuantity;
							this.mobxState.errorPanel.itemBidErrorList = await response.data.results.itemList;
		        }
		      })
		      .catch(error => {
		        console.log(error);
		      });
		  }
		};

		//FORM HANDLING FUNCTIONS
		//processes the form call by sending a put request with the input that was edited by the user
		//specifically for label,value format with dropdown. fetchData call at end updates the mobxState and creates a specific format for selected value in this.mobxState.optimizerSelectValue
		handleFormChangeAndState = (fieldName, selection) => {
			console.log('handleFormChange() with:', fieldName, selection);
			let paramsToSend = {[fieldName]: selection}; //eval fieldName to the name passed in and put in selection into object
			console.log('paramsToSend', paramsToSend);
			axios.put('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId, paramsToSend) //fire off update request with the correct params
			.then((response) => {
				console.log(response);
				this.fetchData();
			});
			this.mobxState.formState[fieldName] = selection;
			console.log(fieldName, ' state changed to ', this.mobxState.formState[fieldName]);
		}

		//DEBOUNCE the submit, so form doesn't freeze up if too many requests are sent
		handleDebouncedChange = (fieldName, selection) => {
			console.log('changing', fieldName, selection);
			this.mobxState.formState[fieldName] = selection;
	    this.handleSubmit(fieldName, selection);
	  };

	  handleSubmit = _.debounce(async (fieldName, selection) => {
			let paramsToSend = {[fieldName]: selection}; //eval fieldName to the name passed in and put in selection into object
	    // PATCH and redirect to https://test.localhost/sourcing/projects/:project_id/bids
	    axios
	      .put('/v1/projects/'+this.projectId+'/scenarios/'+this.scenarioId, paramsToSend)
	      .then(result => {
	        console.log('PUT UPDATED WITH:', paramsToSend);
	      })
	      .catch(e => {
	        // TODO: Work out error handling
	        alert('Something went wrong. Please check your inputs and try again.');
	      });
	  });

		//END FORM HANDLING

		//////////////////END HTTP/////////////////

	  //handles dropdown and chart filters
		handleFilter = (filterParam, selectedFilter) => {
			console.log('Handle Filter Callback: ');
			console.log('selectedFilter ', selectedFilter);
			console.log('filterParam ', filterParam);
			//in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
			this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
			//also store the selections in the original form. This goes back to the filter as selected values
			this.mobxState.selectedOptions[filterParam] = selectedFilter;
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

	  //handles dropdown and chart filters
		handleClear = () => {
			//in the state, clear the model. This goes as params to http request
			this.mobxState.selectedModels = {};
			//also store the explicit cleared selections in the original form. This goes back to the filter as selected values
			//loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
			//from ["supplier", "item"] to {"supplier":[], "item":[]}
			const optionsClear = {};
			for (const key of this.mobxState.filterModelNames) { optionsClear[key] = [];}
			this.mobxState.selectedOptions = optionsClear;
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

		//for sidebar dock to change sizes
		handleSizeChange = size => {
			this.mobxState.dockOptions.size = size;
		}

		//sidebar dock toggle
		toggleDock = () => {
			this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible
		}

		render() {

			const hcHeight = '300px';
			//need to add form validation
			const validate = value => {
	      return !value || value.length < 3 ? 'Must be at least three characters' : null;
	    };

			const canCompetitionConstraintR = checkRole({'model':'competition_constraint', 'permission':'r'});
			const canSupplierConstraintR = checkRole({'model':'supplier_constraint', 'permission':'r'});
			const canQualitativeConstraintR = checkRole({'model':'qualitative_constraint', 'permission':'r'});
			const canQuantitativeConstraintR = checkRole({'model':'quantitative_constraint', 'permission':'r'});
			const canDiscountConstraintR = checkRole({'model':'discount_constraint', 'permission':'r'});

			const canFreightBracketConstraintR = checkRole({'model':'freight_bracket_constraint', 'permission':'r'});
			const canFreightMatchConstraintR = checkRole({'model':'freight_match_constraint', 'permission':'r'});
			const canFreightConstraint = canFreightBracketConstraintR || canFreightMatchConstraintR;

			return (

				<div>

					<div className="jumbotron jumbotron_full_width">
						<div className="jumbotron_full_width_container">
							<div className="row">
								<div className="col-sm-5">
									<span className="fa-layers fa-fw fa-2x">
										<FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#009C9C" style={{marginTop: '4px'}} />
										<FontAwesomeIcon icon={['fal', 'cog']} color="white" transform="shrink-2" style={{marginTop: '4px'}} />
									</span>
									&nbsp;
									<b className="dash_header">{this.mobxState.formState.name} scenario</b>

								</div>
								<div className="col-sm-1">
								</div>
								<div className="col-sm-1">
								</div>
								<div className="col-sm-5 gps">
									<span style={{marginRight:'17px'}}><PlayButton /></span>
									<FontAwesomeIcon icon={['fad', 'location-arrow']} color="#53C8C5" transform='grow-15 left-10' style={{}} />
									<Link to={'/sourcing/projects/'+this.projectId+'/scenarios'} className='link_style'>
										Next: Evaluate Optimization Scenario
									</Link>
								</div>
							</div>

						</div>
					</div>

					{/* FORM */}
					<div className="row">
						<div className="col-sm-12">
							<div className="panel panel-default">
								<div className="panel-body">
									<form>
										<div className="row">
											<div className="col-sm-4">
												<b>Name</b>
												<AutoComplete
													value={this.mobxState.formState.name}
												  dataSource={this.mobxState.scenarioAutofill}
													placeholder={this.mobxState.formState.name || 'Enter scenario name'}
													className="form-control"
													maxMenuHeight = {400}
													onChange={(e) => this.handleDebouncedChange('name', e)}
												/>
											</div>
											<div className="col-sm-8">
												<b>Description</b>
												<Input
													placeholder={this.mobxState.formState.description}
													value={this.mobxState.formState.description}
													className="form-control"
													onChange={(e) => this.handleDebouncedChange('description', e.target.value)}
												/>
											</div>
										</div> {/* END ROW */}
										<br />
										<div className="row">
											<div className="col-sm-4">
												<b>Optimize on field</b>
												<Select
													onChange={(e) => {
														this.handleFormChangeAndState('optimizer_field', e.value)
													}}
													options={this.mobxState.formSelect.optimizerFieldSelect}
													value = {this.mobxState.optimizerSelectValue}
													closeMenuOnSelect={true}
													maxMenuHeight = {400}
													//placeholder={this.mobxState.formState.optimizer_field.label}
												/>
											</div>
											<div className="col-sm-4">
												<b>Round</b>
												<Select
													onChange={(e) => this.handleDebouncedChange('round', e.value)}
													options={this.mobxState.formSelect.roundSelect}
													value={{label: this.mobxState.formState.round, value:this.mobxState.formState.round}}
													closeMenuOnSelect={true}
													maxMenuHeight = {400}
													placeholder={'Select Round'}
												/>
											</div>
											<div className="col-sm-4">
												<b>Confidence Interval</b>
												<Select
													onChange={(e) => this.handleDebouncedChange('confidence_interval', e.value)}
													options={this.mobxState.formSelect.confidenceSelect}
													value={{label: this.mobxState.formState.confidence_interval, value:this.mobxState.formState.confidence_interval}}
													closeMenuOnSelect={true}
													maxMenuHeight = {400}
													placeholder={'Select Confidence Level'}
												/>
											</div>
										</div> {/* END ROW */}
										{!this.mobxState.isFreight &&
											<div>
												<br />
												<div className="row">
													<div className="col-lg-3" style={{ }}>
														<Checkbox
														  name='approximateSolution'
														  onChange={(e) => this.handleDebouncedChange('approximate_solution', e.target.checked)}
														  checked={this.mobxState.formState.approximate_solution}
														>
														  Estimate solution if infeasible
														</Checkbox>
													</div>
														<div className="col-lg-5" style={{}}>
															<Checkbox
															  name='integerSolve'
															  onChange={(e) => this.handleDebouncedChange('integer_solve', e.target.checked)}
															  checked={this.mobxState.formState.integer_solve}
															>
															  Higher accuracy, increased solve time
															</Checkbox>
														</div>
													<div className="col-sm-4">

													</div>
												</div>
											</div>
									  }


										{canFreightConstraint && this.mobxState.isFreight &&
											<div>
												<br />
												<div className='row'>
													<div className='col-sm-12'>
														<div className={'top_panel_info'}>Advanced freight settings </div>
													</div>
												</div>
												<br />
												<div className="row">
													<div className="col-sm-3">
														<div>
															<b>Shipments</b>
														</div>
														<div>
															<InputNumber
																style={{ width: "100%", paddingTop: "3px" }}
																min={1}
																//defaultValue={1}
																onChange={(e) => this.handleDebouncedChange('shipments', e)}
																value={this.mobxState.formState.shipments}
															/>
														</div>
													</div>
													<div className="col-sm-3">
														<b>Match Freight Bidders</b>
														<Select
															onChange={(e) => this.handleFormChangeAndState('freight_match', e.value)}
															options={this.mobxState.formSelect.matchSelect}
															value={this.mobxState.freightMatchSelectValue}
															closeMenuOnSelect={true}
															maxMenuHeight = {400}
															placeholder={'Select Matching'}
														/>
													</div>
													<div className="col-lg-3" style={{ marginTop: '20px'}}>
														<Checkbox
														  name='includeFreight'
														  onChange={(e) => this.handleDebouncedChange('include_freight', e.target.checked)}
														  checked={this.mobxState.formState.include_freight}
														>
														  Include Freight Analysis
														</Checkbox>
													</div>
													<div className="col-sm-3">
													</div>
												</div>
											</div>
										}

									</form>

								</div>
							</div>
						</div>
						{/* END FORM*/}
					</div> {/* END ROW*/}

						{/* WARN PANEL*/}
						{this.mobxState.errorPanel.scenarioContainsErrors &&
							<div>
								<div className="row">
									<div className="col-lg-12">
										<div className="panel panel-default">
												<div className="panel-heading panel_bg">
													<b>Optimization Data Issues</b>
											</div>
											<div className="panel-body scenario-error-panel">
												<div className="row">
													{_.isEmpty(this.mobxState.errorPanel.itemBidErrorList) === false ?
														<div className="col-lg-12">
															<div className="panel-heading  panel_bg bg-danger text-danger">
																<b className="text-danger">The following items contain bids where {this.mobxState.optimizerSelectValue.label} is null</b>
															</div>

															<ol className="scenario-error-list" style={{listStyle: "none",marginLeft: ".4em"}}>
																{Object.entries(this.mobxState.errorPanel.itemBidErrorList).map(([key, value]) => {
																	return(
																		<React.Fragment>
																			{
																				parseInt(key) + 1 === this.mobxState.errorPanel.itemBidErrorList.length ?
																				<li key={key}>{value}</li>
																				:
																				<li key={key}>{value}&nbsp;|</li>
																			}
																		</React.Fragment>
																	)
																})}
															</ol>
														</div> :
														<div></div>
													}
													{/* ZERO and or NULL bid prices */}
													{this.mobxState.errorPanel.invalidPriceOnly === true ?
														<div className="col-lg-12">
															<div className="panel-heading  panel_bg bg-danger text-danger">
																<b className="text-danger">The following bids have null or 0 for the price, please add a price to these bids before running a scenario.</b>
															</div>
															{/*Group Results By Round */}
															{
																Object.entries(this.mobxState.errorPanel.groupedPrices).map(([key, value]) => {
																	console.log("K", key, value)
																	return(
																		<div className="bid-error-list" key={key}>
																				<b className="error-list-header">Round {key}:</b>
																				{
																					value.map((subValue, i) => {
																						return(
																							<React.Fragment>
																								<ul style={{listStyle: "none"}}>
																									<li>Bid Id: {subValue['bid_id']}, &nbsp;</li>
																									<li>Bid Item Name: {subValue['item.name']}, &nbsp;</li>
																									<li>Bidder Name: {subValue['supplier.name']}, &nbsp;</li>
																									{
																										i + 1 === value.length ?
																										<li>Bid Price: {subValue['bid_price'] == 0 ? subValue['bid_price'] : "No Price"} &nbsp;</li>:
																										<li>Bid Price: {subValue['bid_price'] === 0 ? subValue['bid_price'] : "No Price"} &nbsp; |</li>
																									}
																								</ul>
																							</React.Fragment>
																						)
																					})
																				}
																		</div>
																	)
																})
															}
														</div> :
														<div></div>
													}
													{/* WARN THAT SHOWS COMPLETE LIST OF CUSTOM FIELDS */}
													{this.mobxState.errorPanel.fullCustomList !== null ?
														<div className="col-lg-12">
															<div className="panel-heading  panel_bg bg-danger text-danger">
																<b className="text-danger">{this.mobxState.optimizerSelectValue.label} custom field contains null or zero values. </b>
															</div>
															{
																Object.entries(this.mobxState.errorPanel.fullCustomList).map(([key, value]) => {
																	return(
																		<div className="bid-error-list" key={key}>
																				<b className="error-list-header">Round {key}:</b>
																				{
																					value.map((subValue, i) => {
																						return(
																							<React.Fragment>
																								<ul key={key} style={{listStyle: "none"}}>
																									<li>Bid Id: {subValue['bid_id']}, &nbsp;</li>
																									<li>Bid Item Name: {subValue['item.name']}, &nbsp;</li>
																									<li>Bidder Name: {subValue['supplier.name']} &nbsp;</li>
																									{
																										i + 1 === value.length ?
																										<li>{this.mobxState.optimizerSelectValue.label}: {subValue['bid_level_custom'] || "No Value"}&nbsp;</li>
																										:
																										<li>{this.mobxState.optimizerSelectValue.label}: {subValue['bid_level_custom'] || "No Value"}&nbsp;|</li>
																									}
																								</ul>
																							</React.Fragment>
																						)
																					})
																				}
																		</div>
																	)
																})
																}
														</div> :
														<div></div>
													}
													{/*END of fullCustomList*/}
													{/* Bids with zero price*/}
													{this.mobxState.errorPanel.invalidBidPriceList.length > 0 ?
														<div className="col-lg-12">
																<div className="panel-heading panel_bg bg-danger text-danger">
																	<b className="text-danger">The following bids have null or 0 for the price, please add a price to these bids before running a scenario.</b>
																</div>
														{
													  Object.entries(this.mobxState.errorPanel.invalidBidPriceList).map(([key, value]) => {
													    let index = parseInt(key) + 1;
													    return (
													      <div className='bid-error-list' key={key}>
													        <b className='error-list-header'>Round {index}:</b>
													        {Object.entries(value).map(([key, value]) => {
													          return (
													            <React.Fragment>
													              {value.map((subValue, i) => {
													                return (
													                  <ul>
													                    <li>Bid Id: {subValue['bid_id']}, &nbsp;</li>
													                    <li>Bid Item Name: {subValue['item.name']}, &nbsp;</li>
																							<li>Bidder Name: {subValue['supplier.name']}, &nbsp;</li>
													                    {i + 1 === value.length ? (
																								<li>Bid Price: {subValue['bid_price'] === 0 ? subValue['bid_price'] : "No Price"}&nbsp;</li>
													                    ) : (
													                      <li>Bid Price: {subValue['bid_price'] === 0 ? subValue['bid_price'] : "No Price"}&nbsp; |</li>
													                    )}

													                  </ul>
													                );
													              })}
													            </React.Fragment>
													          );
													        })}
													      </div>
													    );
													  })
													}
														</div> :
														<div></div>
													}
													{/*Null or zero quantity*/}
													{this.mobxState.errorPanel.nullQuantityList.length > 0  ?
														<div className="col-lg-12">
																<div className="panel-heading panel_bg bg-danger text-danger">
																	<b className="text-danger">The following bids are for items with 0 quantity.</b>
																</div>
														{
															Object.entries(this.mobxState.errorPanel.nullQuantityList).map(([key, value]) => {
																let index = parseInt(key) + 1
																return(
																	<div className="bid-error-list" key={key}>
																			<b className="error-list-header">Round {index}:</b>
																			{
																				Object.entries(value).map(([key, value]) => {

																					return(
																						<React.Fragment>
																							{
																								value.map((subValue, i) => {
																										return(
																											<ul>
																												<li>Bid Id: {subValue['bid_id']} &nbsp;</li>
																												{
																													i + 1 === value.length ?
																													<li>Item Name: {subValue['item.name']} &nbsp;</li>:
																													<li>Item Name: {subValue['item.name']} &nbsp; |</li>
																												}
																											</ul>
																											)
																										})
																							}
																						</React.Fragment>
																					)
																				})
																			}
																	</div>
																)
															})
														}
														</div> :
														<div></div>
													}

												</div> {/*end of row*/}
											</div>
										</div>
									</div>
								</div>
							</div>
						}

					{/* CONSTRAINTS */}
					<div className="row">
	          <div className="col-lg-12">
	            <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Constraint Summary</b>
                </div> {/*end panel heading */}

                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table table-striped" cellSpacing="0" width="100%">
                      <thead>
                        <tr>
                          <th style={{width:'2%'}}></th>
													<th style={{width:'18%'}}>Type</th>
                          <th style={{width:'10%'}}># Created</th>
                          <th style={{width:'70%'}}>Description</th>
                        </tr>
                      </thead>

                      <tbody>
												{/*Competition constraints */}
												{canCompetitionConstraintR &&
	                        <tr>
	                          <td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/CompetitionConstraints'} className='link_style'>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Co</span>
																</span>
															</Link>
														</td>
														<td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/CompetitionConstraints'} className='link_style'>
																Competition
															</Link>
														</td>
	                          <td>{this.mobxState.cardData.scenario.competition_constraint_count}</td>
	                          <td>
															Specify the number of bidders competing for an award
													  </td>
	                        </tr>
												}
												{/* Bidder Constraints */}
												{canSupplierConstraintR &&
													<tr>
	                          <td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/SupplierConstraints'} className='link_style'>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
																<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Bi</span>
															</span>
															</Link>
	                          </td>
													  <td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/SupplierConstraints'} className='link_style'>
																Bidder
															</Link>
													  </td>
	                          <td>{this.mobxState.cardData.scenario.supplier_constraint_count}</td>
	                          <td>
															Assign award volume to specific bidders
														</td>
	                        </tr>
												}
												{/* Qualitative Constraints */}
												{canQualitativeConstraintR &&
													<tr>
	                          <td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/QualitativeConstraints'} className='link_style'>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Ql</span>
																</span>
															</Link>
	                          </td>
														<td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/QualitativeConstraints'} className='link_style'>
																Qualitative
															</Link>
														</td>
														<td>{this.mobxState.cardData.scenario.qualitative_constraint_count}</td>

														{/* Quantitative Constraints */}
														<td>
															Use custom fields of the bid to assign awards
														</td>
	                        </tr>
												}
												{canQuantitativeConstraintR &&
													<tr>
	                          <td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/QuantitativeConstraints'} className='link_style'>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Qn</span>
																</span>
															</Link>
	                          </td>
														<td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/QuantitativeConstraints'} className='link_style'>
																Quantitative
															</Link>
														</td>
	                          <td>{this.mobxState.cardData.scenario.quantitative_constraint_count}</td>
	                          <td>
															Use custom numeric fields of the bid to assign awards
														</td>
	                        </tr>
												}
												{canDiscountConstraintR &&
													<tr>
	                          <td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/DiscountConstraints'} className='link_style'>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Ds</span>
																</span>
															</Link>
	                          </td>
														<td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/DiscountConstraints'} className='link_style'>
																Discount
															</Link>
														</td>
							  						<td>{this.mobxState.cardData.scenario.discount_constraint_count}</td>
	                          <td>
															Assign tiered bidder discounts
														</td>
	                        </tr>
												}
												{canCompetitionConstraintR &&
													<tr>
														<td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/SubsetConstraints'} className='link_style'>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Ss</span>
																</span>
															</Link>
														</td>
														<td>
															<Link to={'/sourcing/projects/'+this.projectId+'/scenarios/'+this.scenarioId+'/SubsetConstraints'} className='link_style'>
																Subset
															</Link>
														</td>
														<td>{this.mobxState.cardData.scenario.subset_constraint_count}</td>
														<td>
															Optimize a custom subset of data
														</td>
													</tr>
												}
												{/*

													<span className="fa-layers fa-fw fa-2x">
														<FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
														<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Co</span>
													</span>

													<Bounce left when={false}>
														<span>
															<i className="glyphicon glyphicon-question-sign"></i>
															<FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{marginRight:'15px'}}/>
															You can specify a rule that determines the total bidder base for this scenario. Example: at most two bidders should be used for this scenario. Or, only two bidders should be used on a specific item.
														</span>
													</Bounce>
                        <tr>
                          <td>Bidder
                            <span className="pull-right"><a href="#" data-toggle="tooltip" data-placement="bottom" title="This rule helps allocate a certain award percent to specifc bidders. Example: Bidder A should get at least 30% of the award. Or, Bidder B should be awarded no more than 40% of the overall business."><i className="glyphicon glyphicon-question-sign"></i></a></span>
                          </td>
                          <td><%= link_to 'Manage', project_optimization_run_supplier_constraints_path(@project.id) %></td>
                          <td><%= @optimization_run.supplier_constraints.count %></td>
                          <td>Assign award volume to specific bidders</td>
                        </tr>
                        <tr>
                          <td>Qualitative
                           <span className="pull-right"><a href="#" data-toggle="tooltip" data-placement="bottom" title="You can use custom fields to define awards. Your data needs to have qualitative data in order to use this rule. Example: if your data is tagged with a risk rating for each bid, you could specify that 80% of the award should go to low risk bids. Other good uses of this rule are: incumbency, preferred vendors, minority certifications, preferred plants."><i className="glyphicon glyphicon-question-sign"></i></a></span>
                          </td>
                          <td><%= link_to 'Manage', project_optimization_run_qualitative_constraints_path(@project.id) %></td>
                          <td><%= @optimization_run.qualitative_constraints.count %></td>
                          <td>Use custom descriptive attributes of the bid to assign awards</td>
                        </tr>
                        <tr>
                          <td>Quantitative
                           <span className="pull-right"><a href="#" data-toggle="tooltip" data-placement="bottom" title="You can use custom numeric fields to define awards. Your bids need to have numeric ratings in order to use this rule. Example: if your data is tagged with a risk rating for each bid, you could specify that 80% of the award should go to bids with a risk rating of at most 3. Other good uses of this rule are: lead time, bidder compliance scores, fuel surcharge."><i className="glyphicon glyphicon-question-sign"></i></a></span>
                          </td>
                          <td><%= link_to 'Manage', project_optimization_run_quantitative_constraints_path(@project.id) %></td>
                          <td><%= @optimization_run.quantitative_constraints.count %></td>
                          <td>Use custom numeric attributes of the bid to assign awards</td>
                        </tr>
                        <tr>
                          <td>Discount
                           <span className="pull-right"><a href="#" data-toggle="tooltip" data-placement="bottom" title="If your bidders provided discounts you can enter them here. This is a very useful tool for negotiations as well. You can use a tiered discount based on how much a bidder is awarded, or a simple discount. Example: bidder A has agreed to provide a 10% discount if they are awarded 1 Million dollars of business."><i className="glyphicon glyphicon-question-sign"></i></a></span>
                          </td>
                          <td><%= link_to 'Manage', project_optimization_run_discount_constraints_path(@project.id) %></td>
                          <td><%= @optimization_run.discount_constraints.count %></td>
                          <td>Assign bidder discounts</td>
                        </tr>
                        <tr>
                          <td>Capacity Modifier
                           <span className="pull-right"><a href="#" data-toggle="tooltip" data-placement="bottom" title="This rule can help estimate awards and costs based on different time frames. If your pricing and data is quoted monthly, you can create a rule to estimate what the scenario would cost on yearly basis by assigning a modifier. Example: the data has weekly quantities so I will increase the quantity and bidder capacity factors by 52 to get a yearly estimate for this sceario."><i className="glyphicon glyphicon-question-sign"></i></a></span>
                          </td>
                          <td><%= link_to 'Manage', route_capacity_modifier_project_optimization_run_capacity_modifiers_path(@project.id) %></td>
                          <td><%= @optimization_run.capacity_modifiers.count %></td>
                          <td>Use a modifier to account for capacity constraints for a given time frame</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div> {/*end table responsive */}
                </div> {/*end panel body */}
	            </div> {/*end panel */}
	          </div> {/*end lg 12 */}
	        </div> {/*end row */}

					<br />

					{/* FREIGHT CONSTRAINTS */}


					{canFreightConstraint && this.mobxState.isFreight &&
						<div className="row">
		          <div className="col-lg-12">
		            <div className="panel panel-default">
	                <div className="panel-heading panel_bg">
	                  <b>Freight Specific Constraint Summary</b>
	                </div> {/*end panel heading */}

	                <div className="panel-body">
	                  <div className="table-responsive">
											<table className='table table-striped' cellSpacing='0' width='100%'>
											  <thead>
											    <tr>
											      <th style={{ width: '2%' }}></th>
											      <th style={{ width: '18%' }}>Type</th>
											      <th style={{ width: '10%' }}># Created</th>
											      <th style={{ width: '70%' }}>Description</th>
											    </tr>
											  </thead>

											  <tbody>
											    {canFreightMatchConstraintR && (
											      <tr>
											        <td>
											          <Link
											            to={'/sourcing/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/FreightMatchConstraints'}
																	className='link_style'
											          >
											            <span className='fa-layers fa-fw fa-2x'>
											              <FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
											              <span className='fa-layers-text fa-inverse' style={{ fontWeight: '900', fontSize: '14px' }}>
											                Fm
											              </span>
											            </span>
											          </Link>
											        </td>
											        <td>
											          <Link
											            to={'/sourcing/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/FreightMatchConstraints'}
																	className='link_style'
											          >
											            Freight Match
											          </Link>
											        </td>
											        <td>{this.mobxState.cardData.scenario.freight_match_constraint_count}</td>
											        <td>Assign Freight Match Constraints</td>
											      </tr>
											    )}
											    {canFreightBracketConstraintR && (
											      <tr>
											        <td>
											          <Link
											            to={'/sourcing/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/FreightBracketConstraints'}
																	className='link_style'
											          >
											            <span className='fa-layers fa-fw fa-2x'>
											              <FontAwesomeIcon icon={['fas', 'circle']} style={{}} />
											              <span className='fa-layers-text fa-inverse' style={{ fontWeight: '900', fontSize: '14px' }}>
											                Fb
											              </span>
											            </span>
											          </Link>
											        </td>
											        <td>
											          <Link
											            to={'/sourcing/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/FreightBracketConstraints'}
																	className='link_style'
											          >
											            Freight Bracket
											          </Link>
											        </td>
											        <td>{this.mobxState.cardData.scenario.freight_bracket_constraint_count}</td>
											        <td>Assign Freight Bracket Constraints</td>
											      </tr>
											    )}
											  </tbody>
											</table>
	                  </div>
	                </div>
		            </div>
		          </div>
		        </div>
					}

				</div>
			);
		}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ScenarioManager, {
	mobxState: observable
})

export default observer(ScenarioManager);

// export default ScenarioManager;
