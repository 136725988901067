import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';



//handles rendring form components and their validation
class ConstraintAwardSelection extends React.Component {

  mobxState = {
    operatorSelect: [], //options for select box
    baseSelect: [], //options for select box
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    ////////////////////
    //FORM LOAD
    //OPERATORS:
    axios.get('/microservices/v1/operators', {params: {} })
    .then((response) => {
      this.mobxState.operatorSelect = response.data.operators;
      // console.log('INSIDE COMPONENT');
      console.log(this.mobxState.operatorSelect);
    });

    //BASE of award
    axios.get('/microservices/v1/bases', {params: {} })
    .then((response) => {
      this.mobxState.baseSelect = response.data.bases;
      // console.log('INSIDE COMPONENT');
      console.log(this.mobxState.baseSelect);
    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  render(){

    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }
    

    return (
      <div>

        <div className={this.props.operatorCssClass}>
          <div className="row">       
            <div className="col-lg-12">         
              <Select
                onChange={(e) => this.props.handleFormChange('operator', e)}
                options={this.mobxState.operatorSelect}
                closeMenuOnSelect={true}
                maxMenuHeight = {400}
                placeholder={'How you would like to award'}
              />
            </div>           
          </div>        
        </div>
        <div className={this.props.awardCssClass}>
        </div>
        <div className={this.props.baseCssClass}>
          <Select
            onChange={(e) => this.props.handleFormChange('base', e)}
            defaultValue={{value: 'cost', label: 'cost'}}
            options={this.mobxState.baseSelect}
            closeMenuOnSelect={true}
            maxMenuHeight = {400}
            placeholder={'How you would like to award'}
          />
        </div>
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ConstraintAwardSelection, {
	mobxState: observable
})

export default observer(ConstraintAwardSelection);

// export default ConstraintAwardSelection
