import React from 'react';
import Select from 'react-select';
import WindowedSelect from './WindowedSelect';
import AntdMultiSelect from './AntdMultiSelect';
import AntdSearchedMultiSelect from './AntdSearchedMultiSelect';
import AntdRangeSelect from './AntdRangeSelect';
import AntdDateRangePicker from './AntdDateRangePicker';
import AntdDateYearMonthPicker from './AntdDateYearMonthRangePicker';
import AntdDateYearPicker from './AntdDateYearRangePicker';

/*
NOTES:

2021-09-22: The filters have gotten to the point where they're becoming a leaky abstraction.
I've got the ranged slider implemented for the system filters. A custom version will likely need to be created.
For now I'm just trying to make the code roughly symmetric between the two.

2021-10-04: Logic for deciding what filter is as follows:

- If widget type is provided, via props, then instantiate the appropriate widget
- If widget type is not provided, then it's going to be a multiselect widget
  - If a search function is provided, then it will be a searched multiselect
  - If a search function is not provided then we assume all options are passed
*/

//loops through filterModelNames property, and renders a filter for each, including onChange callback
//NOTE about 10% slower than manually rendering the filters
class FilterRender extends React.Component {
  buildSystemFilters = () => {
    return this.props.filterModelNames.map((name, ix, arr) => {
      let searchFunction =
        this.props.optionSearchFunctions &&
        this.props.optionSearchFunctions.searchFunctions &&
        this.props.optionSearchFunctions.searchFunctions[name];
      let widgetType = this.props.filterWidgetTypes && this.props.filterWidgetTypes[name];
      if (widgetType === 'range_slider') {
        return (
          <div key={'system-filter-component-' + ix}>
            <br />
            <div className='row' key={'system-filter-row-' + ix}>
              <div className='col-lg-12' key={'system-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdRangeSelect
                  name={name}
                  optionSearch={searchFunction}
                  value={this.props.value[name] || []}
                  onChange={e => this.props.handleFilter([name], e)}
                  options={this.props.options && this.props.options[name]}
                  isMulti
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  maxMenuHeight={this.props.maxMenuHeight}
                  placeholder={'Select Option(s)'}
                  key={'system-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else if (widgetType === 'date_picker') {
        return (
          <div key={'system-filter-component-' + ix}>
            <br />
            <div className='row' key={'system-filter-row-' + ix}>
              <div className='col-lg-12' key={'system-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdDateRangePicker
                  name={name}
                  value={this.props.value[name] || []}
                  onChange={e => this.props.handleFilter([name], e)}
                  options={this.props.options && this.props.options[name]}
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  placeholder={'Select Option(s)'}
                  key={'system-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else if (widgetType === 'year_picker') {
        return (
          <div key={'system-filter-component-' + ix}>
            <br />
            <div className='row' key={'system-filter-row-' + ix}>
              <div className='col-lg-12' key={'system-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdDateYearPicker
                  name={name}
                  value={this.props.value[name] || []}
                  onChange={e => this.props.handleFilter([name], e)}
                  options={this.props.options && this.props.options[name]}
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  placeholder={'Select Option(s)'}
                  key={'system-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else if (widgetType === 'year_month_picker') {
        return (
          <div key={'system-filter-component-' + ix}>
            <br />
            <div className='row' key={'system-filter-row-' + ix}>
              <div className='col-lg-12' key={'system-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdDateYearMonthPicker
                  name={name}
                  value={this.props.value[name] || []}
                  onChange={e => this.props.handleFilter([name], e)}
                  options={this.props.options && this.props.options[name]}
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  placeholder={'Select Option(s)'}
                  key={'system-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else if (typeof searchFunction === 'undefined') {
        return (
          <div key={'system-filter-component-' + ix}>
            <br />
            <div className='row' key={'system-filter-row-' + ix}>
              <div className='col-lg-12' key={'system-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdMultiSelect
                  value={this.props.value[name]}
                  onChange={e => this.props.handleFilter([name], e)}
                  options={this.props.options[name]}
                  isMulti
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  maxMenuHeight={this.props.maxMenuHeight}
                  placeholder={'Select Option(s)'}
                  key={'system-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div key={'system-filter-component-' + ix}>
            <br />
            <div className='row' key={'system-filter-row-' + ix}>
              <div className='col-lg-12' key={'system-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdSearchedMultiSelect
                  name={name}
                  optionSearch={searchFunction}
                  value={this.props.value[name] || []}
                  onChange={e => this.props.handleFilter([name], e)}
                  options={(this.props.options && this.props.options[name]) || []}
                  isMulti
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  maxMenuHeight={this.props.maxMenuHeight}
                  placeholder={'Select Option(s)'}
                  key={'system-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      }
    });
  };

  buildCustomFilters = () => {
    return this.props.customFilterModelNames.map((name, ix, arr) => {
      let searchFunction =
        this.props.optionSearchFunctions &&
        this.props.optionSearchFunctions.customSearchFunctions &&
        this.props.optionSearchFunctions.customSearchFunctions[name];
      let widgetType = this.props.customFilterWidgetTypes && this.props.customFilterWidgetTypes[name];
      if (widgetType === 'range_slider') {
        return (
          <div key={'custom-filter-component-' + ix}>
            <br />
            <div className='row' key={'custom-filter-row-' + ix}>
              <div className='col-lg-12' key={'custom-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdRangeSelect
                  name={name}
                  optionSearch={searchFunction}
                  value={(this.props.value['custom_fields'] && this.props.value['custom_fields'][name]) || []}
                  onChange={e => this.props.handleCustomFilter([name], e)}
                  options={(this.props.customFilterOptions && this.props.customFilterOptions[name]) || []}
                  isMulti
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  maxMenuHeight={this.props.maxMenuHeight}
                  placeholder={'Select Option(s)'}
                  key={'custom-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else if (widgetType === 'date_picker') {
        return (
          <div key={'custom-filter-component-' + ix}>
            <br />
            <div className='row' key={'custom-filter-row-' + ix}>
              <div className='col-lg-12' key={'custom-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdDateRangePicker
                  name={name}
                  value={(this.props.value['custom_fields'] && this.props.value['custom_fields'][name]) || []}
                  onChange={e => this.props.handleCustomFilter([name], e)}
                  options={(this.props.customFilterOptions && this.props.customFilterOptions[name]) || []}
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  placeholder={'Select Option(s)'}
                  key={'custom-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else if (widgetType === 'year_picker') {
        return (
          <div key={'custom-filter-component-' + ix}>
            <br />
            <div className='row' key={'custom-filter-row-' + ix}>
              <div className='col-lg-12' key={'custom-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdDateYearPicker
                  name={name}
                  value={(this.props.value['custom_fields'] && this.props.value['custom_fields'][name]) || []}
                  onChange={e => this.props.handleCustomFilter([name], e)}
                  options={(this.props.customFilterOptions && this.props.customFilterOptions[name]) || []}
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  placeholder={'Select Option(s)'}
                  key={'custom-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else if (widgetType === 'year_month_picker') {
        return (
          <div key={'custom-filter-component-' + ix}>
            <br />
            <div className='row' key={'custom-filter-row-' + ix}>
              <div className='col-lg-12' key={'custom-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdDateYearMonthPicker
                  name={name}
                  value={(this.props.value['custom_fields'] && this.props.value['custom_fields'][name]) || []}
                  onChange={e => this.props.handleCustomFilter([name], e)}
                  options={(this.props.customFilterOptions && this.props.customFilterOptions[name]) || []}
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  placeholder={'Select Option(s)'}
                  key={'custom-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else if (typeof searchFunction === 'undefined') {
        return (
          <div key={'custom-filter-component-' + ix}>
            <br />
            <div className='row' key={'custom-filter-row-' + ix}>
              <div className='col-lg-12' key={'custom-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdMultiSelect
                  value={(this.props.value['custom_fields'] && this.props.value['custom_fields'][name]) || []}
                  onChange={e => this.props.handleCustomFilter([name], e)}
                  options={this.props.customFilterOptions[name]}
                  isMulti
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  maxMenuHeight={this.props.maxMenuHeight}
                  placeholder={'Select Option(s)'}
                  key={'custom-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div key={'custom-filter-component-' + ix}>
            <br />
            <div className='row' key={'custom-filter-row-' + ix}>
              <div className='col-lg-12' key={'custom-filter-col-' + ix}>
                <div>{this.props.customOptions[name]}</div>
                <AntdSearchedMultiSelect
                  optionSearch={searchFunction}
                  value={(this.props.value['custom_fields'] && this.props.value['custom_fields'][name]) || []}
                  onChange={e => this.props.handleCustomFilter([name], e)}
                  options={(this.props.customFilterOptions && this.props.customFilterOptions[name]) || []}
                  isMulti
                  closeMenuOnSelect={this.props.closeMenuOnSelect}
                  maxMenuHeight={this.props.maxMenuHeight}
                  placeholder={'Select Option(s)'}
                  key={'custom-filter-select-' + ix}
                />
              </div>
            </div>
          </div>
        );
      }
    });
  };
  render() {
    let thingsNeedingLogged = [
      this.props.optionSearchFunctions,
      Object.keys(this.props.customOptions || {}).length,
      Object.keys(this.props.optionSearchFunctions || {}).length,
      Object.keys(this.props.filterModelNames || {}).length,
      Object.keys(this.props.value || {}).length,
      Object.keys(this.props.options || {}).length,
      Object.keys(this.props.customOptions || {}).length,
      Object.keys(this.props.customFilterModelNames || {}).length
    ];
    let systemFilters = this.buildSystemFilters();
    let customFilters = this.buildCustomFilters();
    return (
      <div id='filter-lists'>
        <div className='systemFilterGroup'>{systemFilters}</div>
        <div className='customFilterGroup'>{customFilters}</div>
      </div>
    );
  }
}

export default FilterRender;
