import React from "react"
import { render } from 'react-dom'

//other components built
import Badge from "../../../badges/Badge"
import GridColumn from "../../../services/GridColumn" //builds ag-grid columns
import UrlSniffer from "../../../services/UrlSniffer" //work with rails paths to get project token

//filters
import DockRenderer from "../../../filters/DockRenderer"
import FilterQuickAction from "../../../filters/FilterQuickAction" //filter controls
import Select from 'react-select' //select box for filter

//highcharts
import BarChart from "../../../charts/basics/BarChart"
import PieChart from "../../../charts/basics/PieChart"
import LineChart from "../../../charts/basics/LineChart"
import FunnelChart from "../../../charts/basics/FunnelChart"

//percentage donut
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from "../../../grids/GridRenderer"

//http requests
import axios from 'axios'

//state
import { decorate, observable, computed, action } from "mobx"
import { observer } from "mobx-react"

//utility
import {zeroNull} from "../../../services/Utilities"
// import FontAwesome from 'react-fontawesome'
// import faStyles from 'font-awesome/css/font-awesome.css'

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();


//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class ContractOverview extends React.Component {

		customOptions = [];
		cardData = {
			"contractValue": 0,
			"contractCount": 0,
			"contractSupplierCount": 0,
			"supplierContractSum": [], //charts
			"on_contract_count": [], //charts
			"dbe_count": [], //charts
			"cat_1_top_sum": [], //charts
			"year_month_sum_line": [],
		};
		tableData = {
			"tickets":[]
		}; //all the data rows
		selectedModels = {
		}; //multi select data model for http
		selectedOptions = {
		}; //multi select data model in filter value/label format
		dockOptions = {
			isVisible: false,
			size: 0.20
		}; //sidebar defaults
		pivotData = [];
		pivotState = [];
		pivotFilter = [];
		filterModelNames = ['Request_Title', 'year', 'month', 'Released_User', 'Status_ID', 'Supplier']; //autobuilds filters and clear functions

		//mount data through http requests
		componentDidMount() {
			//call the data loader to load data into the view
			this.fetchData();
		}

		//////////////////HTTP/////////////////
		//data loader
		fetchData = () => {
			//quick workaround -- pull simple dropdown value from nested component instead of passing around state
			var table_rows = window.$('#table_rows_dropdown').val();

			//merge the filter model with the amount of rows selected
			let models_with_rows = {...this.selectedModels, ...{"table_rows": table_rows}};

			//after ag grid is set up, get the rest of the data
			axios.get("/microservices/v1/analytics/task_overview", { params: models_with_rows })
			.then(response => {
				this.cardData = response.data;
			});

			//set up custom options for components, then get data for components
			axios.get("/v1/ref_prfields_labels")
			.then((response) => {
				console.log("LABELS RESPONSE: ", response.data);
				//get map of field labels from API
				this.customOptions = response.data;
				//include any custom field names not in db
				this.customOptions = {...this.customOptions, ...{"year": "Created Year", "month": "Created Month",
				"Status_Name":"Status", "released_user_name":"Assigned User", "duration_days":"Duration Days", "Supp_No":"Supplier #", "Supplier":"Supplier", "Supp_Rep":"Supplier Rep.",
				"Original_Due_Date":"Original Due Date", "Due_Date":"Due Date", "Revised_Due_Date":"Revised Due Date",
				"Repeat_Issue":"Repeat Issue", "Created_User":"Created User", "Created_Date":"Created Date", "Released_User":"Assigned User", "Released_Date":"Released Date",
				"Accepted_Date":"Accepted Date", "Accepted_User":"Accepted User", "LastMod_User":"Last Modified User", "LastMod_Date":"Last Modified Date", "For_Review_User":"Review User",
				"Alert_Days":"Alert Days", "Defined_Alert":"Defined Alert", "Interval_Days":"Interval Days", "created_time":"Created Time"}};

				//after ag grid is set up, get the rest of the data
				axios.get("/microservices/v1/analytics/task_overview_data", { params: models_with_rows })
				.then(response => {
					this.tableData = response.data;
					console.log(this.tableData);
					//set the column definitions for ag grid, look into buildColumnDefs class to see options
					this.columnDefsTickets = gridColumns.buildColumnDefs({"customOptions": this.customOptions, "columnArray": ['task_overview'], "projectType":0, "isScenario":0});
					//render the filter dropdown choices for specific page id
					axios.get("/v1/resources/index_filter", {params: {"page_id": 2} })
					.then((response) => {
						this.pivotFilter = response.data.resources;
					});
				})
			})
			.catch(console.log.bind(console)) //error catcher
		}

		deleteRequest = (toSendArr) => {
			console.log("IN DELETION");
	    axios.post('/v1/cm_contracts1/batch_destroy', {"id_set": toSendArr})
	    .then((response) => {
				console.log(response);
				this.fetchData();
	    });
		}
		//////////////////END HTTP/////////////////

	  //handles dropdown and chart filters
		handleFilter = (filterParam, selectedFilter) => {
			console.log('Handle Filter Callback: ');
			console.log('selectedFilter ', selectedFilter); console.log('filterParam ', filterParam);
			//in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
			this.selectedModels[filterParam] = selectedFilter.map(a => a.value);
			//also store the selections in the original form. This goes back to the filter as selected values
			this.selectedOptions[filterParam] = selectedFilter;
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

	    //handles dropdown and chart filters
		handleClear = () => {
			//in the state, clear the model. This goes as params to http request
			this.selectedModels = {};
			//also store the explicit cleared selections in the original form. This goes back to the filter as selected values
			//loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
			//from ["supplier", "item"] to {"supplier":[], "item":[]}
			const optionsClear = {};
			for (const key of this.filterModelNames) { optionsClear[key] = [];}
			this.selectedOptions = optionsClear;

			//add the template filter to clear as well.. because Temp_ID is not in the filterModelNames because it is a separate filter in a separate place from the rest of the filters
			this.selectedOptions['Temp_ID'] = [];

			//fetch called, it reads the filter params from state
			this.fetchData();
		}

		//for sidebar dock to change sizes
		handleSizeChange = size => {
			this.dockOptions.size = size;
		}

		//sidebar dock toggle
		toggleDock = () => {
			this.dockOptions.isVisible = !this.dockOptions.isVisible
		}

		render() {

			const hcHeight = '370px';

			return (

				<div>

					{/* Small Left Toggle */}
					<div onClick={this.toggleDock}  className="sidebar_button filter_toggle"><i className="glyphicon glyphicon-chevron-left"></i></div>

					<div className="jumbotron jumbotron_full_width">
						<div className="jumbotron_full_width_container">
							<b className="dash_header">Task Details</b>
						</div>
					</div>

          <div className="row">
            <div className="col-lg-9">

							{/* BADGES */}
							<div className="row">
								<div className="col-lg-4">
									<Badge
										badgeNumber={zeroNull(this.cardData.ticketCount).toLocaleString('en-US')}
										badgeName={'Tickets'}
										faClass={'tag'}
									/>
								</div>
								<div className="col-lg-4">
									<Badge
										badgeNumber={zeroNull(this.cardData.lateTicketCount).toLocaleString('en-US')}
										badgeName={'Late Tickets'}
										faClass={'asterisk'}
									/>
								</div>
								<div className="col-lg-4">
									<Badge
										badgeNumber={zeroNull(this.cardData.avgTicketDayDuration).toLocaleString('en-US')+" Days"}
										badgeName={'Average Ticket Duration'}
										faClass={'clock'}
									/>
								</div>
							</div>{/* END BADGES ROW */}

							<br />
							<br />

							{/* SUMMARY CHARTS ROW */}
							<div className="row">
								<div className="col-lg-4">
									<div className="panel panel-default">
										<div className="panel-heading panel_bg">
											<b>Ticket Count</b>
											<span className="pull-right">
												<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
											</span>
										</div>
										<div className="panel-body">
											<div className="col-lg-12">
												<BarChart
													renderInDivId={'count_bar'}
													handleFilterFunction={this.handleFilter}
													barData={this.cardData.ticketCountChart}
													barTitle={'Tickets'}
													height={hcHeight}
													filterParam={'faux_user_name'}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="panel panel-default">
										<div className="panel-heading panel_bg">
											<b>Late Ticket Count</b>
											<span className="pull-right">
												<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
											</span>
										</div>
										<div className="panel-body">
											<div className="col-lg-12">
											<BarChart
													renderInDivId={'count_late_bar'}
													handleFilterFunction={this.handleFilter}
													barData={this.cardData.lateTicketCountChart}
													barTitle={'Late Tickets'}
													height={hcHeight}
													filterParam={'faux_user_name'}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="panel panel-default">
										<div className="panel-heading panel_bg">
											<b>Ticket Duration</b>
											<span className="pull-right">
												<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
											</span>
										</div>
										<div className="panel-body">
											<div className="col-lg-12">
												<BarChart
													renderInDivId={'duration_bar'}
													handleFilterFunction={this.handleFilter}
													barData={this.cardData.ticketDurationChart}
													barTitle={'Ticket Duration In Days'}
													height={hcHeight}
													filterParam={'faux_user_name'}
												/>
											</div>
										</div>
									</div>
								</div>

							</div> {/* END SUMMARY CHARTS ROW */}
						</div> {/* END COL LG 9, BADGES AND CHARTS */}

						<div className="col-lg-3">
							<div className="panel panel-default">
								<div className="panel-heading panel_bg">
									<b>Ticket Status</b>
									<span className="pull-right">
										<FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
									</span>
								</div>
								<div className="panel-body">
									<div className="col-lg-12">
										<FunnelChart
											renderInDivId={'status_funnel'}
											handleFilterFunction={this.handleFilter}
											funnelData={this.cardData.statusChart}
											funnelTitle={'Tickets By Status'}
											height={'478px'}
											filterParam={'Status_Name'}
										/>
									</div>
								</div>
							</div>
						</div>{/* END funnel cols */}

          </div>{/* END Badges and CHARTs container ROW */}

					{/* Additional dropdowns */}
					<div className="row">
						{/* Select Filter allows you to select the template*/}
						<div className="col-sm-6">
							<div className="panel panel-default">
								<div className="panel-heading panel_bg">
									<b>Process Management Ticket Template</b>
								</div>
								<div className="panel-body">
									<Select
										value = {this.selectedOptions['Temp_ID']}
										onChange={(e) => this.handleFilter(['Temp_ID'], e)}
										options={this.cardData.Temp_ID}
										isMulti
										closeMenuOnSelect={true}
										maxMenuHeight = {400}
										placeholder={'Select From List of Created Process Management Templates'}
									/>
								</div>
							</div>
						</div>{/* End col-sm-6*/}
						{/* Select Filter allows you to select the pivot_settings config to display*/}
						<div className="col-sm-6">
							<div className="panel panel-default">
								<div className="panel-heading panel_bg">
									<b>Saved Process Management Analysis Configurations</b>
								</div>
								<div className="panel-body">
								{/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
									<Select
										onChange={(e) => this.clickChild(e)}
										options={this.pivotFilter}
										closeMenuOnSelect={true}
										maxMenuHeight = {400}
										placeholder={'Select From List of Saved Process Management Table Views'}
									/>
								</div>
							</div>
						</div>{/* End col-sm-6*/}

					</div>{/* End Row*/}

					{/* TICKET GRID */}
				    <div className="row">
							<div className="col-lg-12">
								{/* AG Grid */}
								<GridRenderer
									gridHeight={"650px"}
									columnDefs={this.columnDefsTickets}
									rowData={this.tableData.tickets}

									fetchData={this.fetchData}
									handleClear={this.handleClear}
									toggleDock={this.toggleDock}
									// pivotLocationID={1}
									pageId={2}

									savedState={this.pivotState}
									isPivotMode={true}
									gridTitle={"Task Details"}

									setClick={click => this.clickChild = click}

									deleteRequest={this.deleteRequest}
									idColumn={"Tket_ID"}

									modelsToUpdateArr={[{url:"v1/pr_tble1/", idName:"Tket_ID"}]}
									//modelsToUpdateArr={false}

									singleClickEdit={true}
									suppressClickEdit={false}

									isSaveable={true}
									isAwardable={false}
									isDeletable={true}
								/>

							</div>
						</div>{/* END CHART ROW */}

					<br />
					{/* Sidebar */}
					<DockRenderer
						position={'right'}
						size={this.dockOptions.size}
						dimMode={'none'}
						isVisible={this.dockOptions.isVisible}
						onVisibleChange={this.handleVisibleChange}
						onSizeChange={this.handleSizeChange}
						fluid={true}

						toggleDock={this.toggleDock}
						handleClear={this.handleClear}

						value={this.selectedOptions}
						options={this.cardData}
						closeMenuOnSelect={true}
						maxMenuHeight = {400}
						handleFilter={this.handleFilter}
						customOptions={this.customOptions}
						filterModelNames={this.filterModelNames}
					/>

				</div>
			);
		}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ContractOverview, {
	customOptions: observable,
	cardData: observable,
	tableData: observable, //all the data rows
	selectedModels: observable, //multi select data model for http
	selectedOptions: observable, //multi select data model in filter value/label format
	dockOptions: observable, //sidebar defaults
	pivotData: observable,
	pivotState: observable,
	pivotFilter: observable,
	filterModelNames: observable //autobuilds filters and clear functions
})

export default observer(ContractOverview);

// export default ContractOverview;
