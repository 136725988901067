import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from '../../../badges/AsyncBadge';
import GridColumn from '../../../services/GridColumn'; //builds ag-grid columns

//filters
import DockRenderer from '../../../filters/DockRenderer';
import FilterQuickAction from '../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//highcharts
import CustomChartRenderer from '../../../charts/custom/CovidChartRenderer';

//ag grid
import GridRenderer from '../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { grabSystemAliases } from  '../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { zeroNull, extractToken, deleteIfEmpty } from '../../../services/Utilities';

// Add to imports
import { processingFunctions, formatFiltersForServer } from '../../../services/filter_helpers';
import { asyncLoadCharts } from '../../../services/ChartUtilities';
const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class Details extends React.Component {
  // Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
  modelName = 'project_bid';
  mobxState = {
    cardData: {
      projectCount: 0,
      bidCount: 0,
      itemCount: 0,
      supplierCount: 0
    },
    chartRenderData: [],
    tableData: {
      projects: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    customFilterModelNames: [],
    filterOptions: {},
    customFilterOptions: {},
    customOptions: {
    },
    filtersForServer: {}, // Filters in normal form to use for the server
    customSchemaRowCount: null, //default amount of rows from 'custom_schemas' table
    isLoadingTableData: false, //fired off when show enteries is selected
    inDeletion: false, //toggles icon and "Deleting selected rows" caption
  };

	//mount data through http requests
	componentDidMount() {
    //get the grid grow count then call the data loader to load data into the view
    this.getCustomRowCount();
	}

	//////////////////HTTP/////////////////
  getCustomRowCount = () => {
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
      .then(response => {
        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
        this.fetchData()
      })
      .catch(console.log.bind(console)); //error catcher
  }

  setupFilters = async () => {
    const pageId = 601; // CHANGE THIS FOR OTHER PAGES
    const urls = {
      system: '/v1/system_filters',
      systemOptions: '/v1/projects/system_field_options',
      custom: '/v1/custom_filters_formatted',
      customOptions: '/v1/projects/custom_field_options',
      systemOptionsSearchUrl: undefined,
      customOptionsSearchUrl: undefined
    };
    const [systemFilterResp, customFilterResp] = await Promise.all([
      axios.get(urls.system, {
        params: processingFunctions.system.filters.formatParams({
          enabled: true,
          page_id: pageId,
        })
      }),
      axios.get(urls.custom, {
        params: processingFunctions.custom.filters.formatParams({
          page_id: pageId,
          enabled: true,
        })
      })
    ]);

    let systemFilters = processingFunctions.system.filters.formatResp(systemFilterResp);
    let customFilters = processingFunctions.custom.filters.formatResp(customFilterResp);

    // Handle System Filters First
    let systemFilterModelNames = [];
    let systemFilterOptions = {};
    let systemFilterCustomOptions = {};
    let systemFilterWidgetTypes = {};

    systemFilters
      .filter(filter => filter.enabled)
      .forEach(filter => {
        let { name, widget_type } = filter; // Name of filter in the table, usually this is the column name of the sequelize model as well
        systemFilterModelNames.push(name); // Construct Select
        systemFilterWidgetTypes[name] = widget_type;
      });

    // Get options (name-label format) for our selects in model-nested format
    // then convert it to name-label format
    systemFilterOptions = await axios
      .post(
        urls.systemOptions,
        processingFunctions.system.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          system_fields: systemFilterModelNames
        })
      )
      .then(processingFunctions.system.options.formatResp);

    // Then Handle Custom Filters
    let customFilterModelNames = [];
    let customFilterOptions = {};
    let customFilterCustomOptions = {};
    let customFilterWidgetTypes = {};

    // Manipulate custom filters into a format where they can be handled
    // by the constructors for our filter panel
    customFilters.forEach(filter => {
      let [name, data] = _.toPairs(filter)[0]; // _.toPairs splits object into list of [key, value] pairs
      customFilterModelNames.push(name);
      customFilterCustomOptions[name] = data.field_alias; // Construct the filter
      customFilterWidgetTypes[name] = data.widget_type;
    });

    customFilterOptions = await axios
      .post(
        urls.customOptions,
        processingFunctions.custom.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          custom_fields: customFilterModelNames
        })
      )
      .then(processingFunctions.custom.options.formatResp);

    // ------------------------------------------------------------
    // Finally Setup The Filters
    // Use the current filter generation that gets triggered when filterModelNames change
    this.mobxState.filterModelNames = systemFilterModelNames;
    this.mobxState.customFilterModelNames = customFilterModelNames;

    this.mobxState.filterOptions = systemFilterOptions;
    this.mobxState.customFilterOptions = customFilterOptions;

    this.mobxState.filterWidgetTypes = systemFilterWidgetTypes;
    this.mobxState.customFilterWidgetTypes = customFilterWidgetTypes;

    this.mobxState.customOptions = {
      ...this.mobxState.customOptions,
      ...systemFilterCustomOptions,
      ...customFilterCustomOptions
    };
    this.mobxState.optionSearchFunctions = processingFunctions.general.makeSearchFunctions({
      system_filters: systemFilters,
      custom_filters: customFilters,
      system_url: urls.systemOptionsSearchUrl,
      custom_url: urls.customOptionsSearchUrl,
      selected_filters: this.mobxState.filtersForServer,
      system_fields: systemFilterModelNames,
      custom_fields: customFilterModelNames
    });
  };

  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();

    //merge the filter model with the amount of rows selected
    this.setupFilters();

    //merge the filter model with the amount of rows selected
    let models_with_rows = {
      ...this.mobxState.filtersForServer,
      ...{ table_rows: table_rows }
    };

    //analytics data load
    axios.post('/v1/projects/project_bids_analytics', models_with_rows).then(response => {
      console.log('/v1/projects/project_bids_analytics: ', response.data.project_analytics);
      this.mobxState.cardData = response.data.project_analytics;
      this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
    });

    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}), // Prefer existing values
      }
    });

    axios
      .get('/v1/custom_schemas', { params: { name: 'project' } }) //query the project custom field schema for all projects
      .then((response) => {
        //get map of field labels from API
        this.mobxState.customSchema = response.data;

        //after ag grid is set up, get the rest of the data
        //table data load
        axios.post('/v1/projects/project_bids_analytics', models_with_rows).then((response) => {
          this.mobxState.cardData = response.data.project_analytics;
          this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
        });

        //after ag grid is set up, get the rest of the data
        //table data load
        //for non template projects
        this.mobxState.isLoadingTableData = true; //loading project rows

        axios.post('/v1/projects/project_bids', models_with_rows).then(async (response) => {
          this.mobxState.tableData = response.data;
          this.mobxState.isLoadingTableData = false;

          //set the column definitions for ag grid, look into buildColumnDefs class to see options
          this.mobxState.columnDefsBids = gridColumns.buildColumnDefs({
            customOptions: this.mobxState.customOptions,
            columnArray: ['project_bids'],
            customFieldSchema: this.mobxState.customSchema.custom_schemas[0],
            dataType: 'project_bid', //special tag to signify that this is project data
          });

          //call child function to autofit child columns
          this.autofitChildToColumnWidth();
        });
      })
      .catch(console.log.bind(console)); //error catcher

    //load custom chart data for this page (updates mobxState.chartRenderData)
    console.log('this.mobxState.filtersForServer', this.mobxState.filtersForServer);
    asyncLoadCharts(this.mobxState, {
        page_id: 601,
        selected_models: this.mobxState.filtersForServer
    });

    //custom pivot configurations load
    axios.get('/v1/resources/index_filter', { params: { page_id: 601, project_id: null } }).then(response => {
      this.mobxState.pivotFilter = response.data.resources;
    });
  }; //end fetchData()

	//////////////////END HTTP/////////////////

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    // In the state, pick the model based on filter that is sent and turn it into the array of values of the filter.
    // This goes as params to http request
    // Update state with selected options, removing the property if none selected
    // Equivalent to this.mobxState[filterParam] = selectedFilter.map(a => a.value)
    // but handles nested assignment
    this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, filterParam);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    if (_.isEmpty(this.mobxState.selectedOptions[filterParam])) {
      delete this.mobxState.selectedOptions[selectedFilter];
    }
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);

    // Update stored filters
    localStorage.setItem('selectedOptions', JSON.stringify(this.mobxState.selectedOptions));
    localStorage.setItem('filtersForServer', JSON.stringify(this.mobxState.filtersForServer));
    localStorage.setItem('selectedModels', JSON.stringify(this.mobxState.selectedModels));
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  handleCustomFilter = (filterParam, selectedFilter) => {
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels['custom_fields'] = this.mobxState.selectedModels['custom_fields'] || {};
    this.mobxState.selectedModels['custom_fields'][filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields`);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions['custom_fields'] = this.mobxState.selectedOptions['custom_fields'] || {};
    this.mobxState.selectedOptions.custom_fields[filterParam] = selectedFilter;
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields`);
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);
    // Update stored filters
    localStorage.setItem('selectedOptions', JSON.stringify(this.mobxState.selectedOptions));
    localStorage.setItem('filtersForServer', JSON.stringify(this.mobxState.filtersForServer));
    localStorage.setItem('selectedModels', JSON.stringify(this.mobxState.selectedModels));

    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  handleChartFilter = (filterParam, selectedFilter) => {
    // KLUDGE : If the filterParam begins with 'custom_fields.' then it should be treated as if
    // a custom_filter was selected
    if (filterParam.indexOf('custom_fields.') !== -1) {
      return this.handleCustomFilter(filterParam.replace(/custom_fields./, ''), selectedFilter);
    } else {
      return this.handleFilter(filterParam, selectedFilter);
    }
  };

  //handles dropdown and chart filters
  handleClear = () => {
    //in the state, clear the model. This goes as params to http request
    this.mobxState.selectedModels = {};

    // Clear the filters we send to the server
    this.mobxState.filtersForServer = {};

    //also store the explicit cleared selections in the original form. This goes back to the filter as selected values
    //loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
    //from ["supplier", "item"] to {"supplier":[], "item":[]}
    let newSelectedOptions = { custom_fields: {} };
    this.mobxState.filterModelNames.forEach(name => {
      newSelectedOptions[name] = [];
    });
    this.mobxState.customFilterModelNames.forEach(name => {
      newSelectedOptions.custom_fields[name] = [];
    });
    this.mobxState.selectedOptions = newSelectedOptions;
    localStorage.setItem('selectedOptions',  '{}');
    localStorage.setItem('filtersForServer', '{}');
    localStorage.setItem('selectedModels',  '{}');

    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };


	render() {

		return (
			<div>
				{/* Small Left Toggle */}
        <div onClick={this.toggleDock} className='sidebar_button filter_toggle'>
          <i className='glyphicon glyphicon-chevron-left'></i>
        </div>

				<div className="jumbotron jumbotron_full_width">
					<div className="jumbotron_full_width_container">
						<div className="row">
							<div className="col-sm-7">
								<span className="fa-layers fa-fw fa-2x">
									<FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#53C8C5" style={{marginTop: '4px'}} />
									<FontAwesomeIcon icon={['fas', 'tags']} color="white" transform="shrink-5" style={{marginTop: '4px'}} />
								</span>
								&nbsp;
								<b className="dash_header">Project Reports - Details</b>
							</div>
							<div className="col-sm-1">
							</div>
							<div className="col-sm-4 gps">
							</div>
						</div>
					</div>
				</div>

				{/* BADGES */}
        <div className="row">
          <div className="col-lg-12">
            <div className="badge-container">
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.projectCount
              ).toLocaleString('en-US')}
                badgeName={'Projects'}
                faClass={'folder-open'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.itemCount
              ).toLocaleString('en-US')}
                badgeName={'Items'}
                faClass={'list'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.supplierCount
              ).toLocaleString('en-US')}
                badgeName={'Bidders'}
                faClass={'briefcase'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.bidCount
              ).toLocaleString('en-US')}
                badgeName={'Bids'}
                faClass={'list'}
              />
            </div>
          </div>
        </div>
        <br/>

        {/* SUMMARY CHARTS */}
        <div className="row">
          <CustomChartRenderer
            handleFilter={this.handleChartFilter}
            toggleDock={this.toggleDock}
            handleClear={this.handleClear}
            chartRenderData={this.mobxState.chartRenderData}
          />
        </div>

				{/* Select Filter allows you to select the pivot_settings config to display*/}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
								<b>Saved Project Reports Configurations</b>
							</div>
              <div className='panel-body'>
								{/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
								<Select
                  onChange={e => this.clickChild(e)}
									options={this.mobxState.pivotFilter}
									closeMenuOnSelect={true}
									maxMenuHeight = {400}
									placeholder={'Select From List of Saved Project Tables'}
								/>
							</div>
						</div>
					</div>
				</div>

				{/* PROJECT GRID */}
        <div className='row'>
          <div className='col-lg-12'>
						{/* AG Grid */}
						<GridRenderer
              gridHeight={'700px'}
							columnDefs={this.mobxState.columnDefsBids}
							rowData={this.mobxState.tableData.projects}
							fetchData={this.fetchData}
							handleClear={this.handleClear}
							toggleDock={this.toggleDock}
							pageId={601}
							savedState={this.mobxState.pivotState}
							isPivotMode={true}
              gridTitle={'Project Bid Details'}
              setClick={click => (this.clickChild = click)}
							idColumn={"id"}
              projectIdNumber={this.projectId}

							modelsToUpdateArr={[{url:'v1/projects/project_bids/', idName:"id"}]}
							enableSetResizeChildColumns={true}
							setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

							singleClickEdit={true}
							suppressClickEdit={false}
							isSaveable={true}
							isAwardable={false}
							isDeletable={true}
              useCustomSchemaRowCount={true}
              isTableRowSelectable={true}
              isLoadingTableData={this.mobxState.isLoadingTableData}
              inDeletion={this.mobxState.inDeletion}
						/>
					</div>
        </div>

				{/* Sidebar */}
				<DockRenderer
					position={'right'}
					size={this.mobxState.dockOptions.size}
					dimMode={'none'}
					isVisible={this.mobxState.dockOptions.isVisible}
					onVisibleChange={this.handleVisibleChange}
					onSizeChange={this.handleSizeChange}
					fluid={true}
					toggleDock={this.toggleDock}
					handleClear={this.handleClear}
          // Current Filters----------------------------------
					value={this.mobxState.selectedOptions}
          // Select Names-------------------------------------
          filterModelNames={this.mobxState.filterModelNames}
          customFilterModelNames={this.mobxState.customFilterModelNames}
          // Widget Types-------------------------------------
          filterWidgetTypes={this.mobxState.filterWidgetTypes}
          customFilterWidgetTypes={this.mobxState.customFilterWidgetTypes}
          // Options------------------------------------------
          options={this.mobxState.filterOptions}
          customFilterOptions={this.mobxState.customFilterOptions}
          // Handlers-----------------------------------------
					handleFilter={this.handleFilter}
          handleCustomFilter={this.handleCustomFilter}
          // Visual options-----------------------------------
					customOptions={this.mobxState.customOptions}
          // Callbacks----------------------------------------
          closeMenuOnSelect={true}
          maxMenuHeight={400}
				/>
      </div>);
	}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Details, {mobxState: observable});
export default observer(Details);
