import React from 'react';
import { render } from 'react-dom';

//other components built
import AsyncBadge from '../../../../badges/AsyncBadge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//forms
import DefaultFiltersForm from './DefaultFiltersForm';

//highcharts
import CustomChartRenderer from '../../../../charts/custom/CovidChartRenderer';

//percentage donut
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, deleteIfEmpty, formatForTreeSelect } from '../../../../services/Utilities';
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';
import { asyncLoadCharts } from '../../../../services/ChartUtilities';
const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

// DEVELOPER NOTES:
// - The easiest way to get searchable filter options is just
//   to use the existing setupFilters().
// - Make sure not to do anything to load existing filter options?

class DefaultFiltersPage extends React.Component {
  pageId = 212;
  mobxState = {
    cardData: {
      spendCount: 0,
      supplierCount: 0,
      contractCount: 0
    },
    chartRenderData: [],
    tableData: {
      spend_reclassification_rules: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    customFilterModelNames: [],
    filterOptions: {},
    customFilterOptions: {},
    customOptions: {
    },
    filtersForServer: {}, // Filters in normal form to use for the server
    defaultFormState: {}, // Form for new or editing reclassification rule
    // Form specific stuff
    formModelFields: ['date__year', 'date__year_month'],
    formCustomModelFields: [],
    formModelOptions: {},
    formOptionSearchFunctions: { customSearchFunctions: {}, searchFunctions: {} },
    editId: null,
    runAllResults: null
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //helpers for system filters
  //helpers for custom filters
  //setup filters
  setupFilters = async () => {
    const urls = {
      system: '/v1/system_filters',
      systemOptions: '/v1/transactions/system_field_options',
      custom: '/v1/custom_filters_formatted',
      customOptions: '/v1/transactions/custom_field_options',
      systemOptionsSearchUrl: `/v1/transactions/system_field_options`,
      customOptionsSearchUrl: `/v1/transactions/custom_field_options`
    };
    const [systemFilterResp, customFilterResp] = await Promise.all([
      axios.get(urls.system, {
        params: processingFunctions.system.filters.formatParams({
          enabled: true,
          page_id: this.pageId
        })
      }),
      axios.get(urls.custom, {
        params: processingFunctions.custom.filters.formatParams({
          page_id: this.pageId,
          enabled: true
        })
      })
    ]);

    let systemFilters = processingFunctions.system.filters.formatResp(systemFilterResp);
    let customFilters = processingFunctions.custom.filters.formatResp(customFilterResp);

    // Handle System Filters First
    let systemFilterModelNames = [];
    let systemFilterOptions = {};
    let systemFilterCustomOptions = {};
    let systemFilterWidgetTypes = {};

    systemFilters
      .filter(filter => filter.enabled)
      .forEach(filter => {
        let { name, widget_type } = filter; // Name of filter in the table, usually this is the column name of the sequelize model as well
        systemFilterModelNames.push(name); // Construct Select
        systemFilterWidgetTypes[name] = widget_type;
      });

    // Get options (name-label format) for our selects in model-nested format
    // then convert it to name-label format
    systemFilterOptions = await axios
      .post(
        urls.systemOptions,
        processingFunctions.system.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          system_fields: systemFilterModelNames
        })
      )
      .then(processingFunctions.system.options.formatResp);

    // Then Handle Custom Filters
    let customFilterModelNames = [];
    let customFilterOptions = {};
    let customFilterCustomOptions = {};
    let customFilterWidgetTypes = {};

    // Manipulate custom filters into a format where they can be handled
    // by the constructors for our filter panel
    customFilters.forEach(filter => {
      let [name, data] = _.toPairs(filter)[0]; // _.toPairs splits object into list of [key, value] pairs
      customFilterModelNames.push(name);
      customFilterCustomOptions[name] = data.field_alias; // Construct the filter
      customFilterWidgetTypes[name] = data.widget_type;
    });

    customFilterOptions = await axios
      .post(
        urls.customOptions,
        processingFunctions.custom.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          custom_fields: customFilterModelNames
        })
      )
      .then(processingFunctions.custom.options.formatResp);

    // ------------------------------------------------------------
    // Finally Setup The Filters
    // Use the current filter generation that gets triggered when filterModelNames change
    this.mobxState.filterModelNames = systemFilterModelNames;
    this.mobxState.customFilterModelNames = customFilterModelNames;

    this.mobxState.filterOptions = systemFilterOptions;
    this.mobxState.customFilterOptions = customFilterOptions;

    this.mobxState.filterWidgetTypes = systemFilterWidgetTypes;
    this.mobxState.customFilterWidgetTypes = customFilterWidgetTypes;

    this.mobxState.customOptions = {
      ...this.mobxState.customOptions,
      ...systemFilterCustomOptions,
      ...customFilterCustomOptions
    };
    this.mobxState.optionSearchFunctions = processingFunctions.general.makeSearchFunctions({
      system_filters: systemFilters,
      custom_filters: customFilters,
      system_url: urls.systemOptionsSearchUrl,
      custom_url: urls.customOptionsSearchUrl,
      selected_filters: this.mobxState.filtersForServer,
      system_fields: systemFilterModelNames,
      custom_fields: customFilterModelNames
    });

    // Fill in fields for our form
    this.mobxState.formModelFields = systemFilterModelNames;
    this.mobxState.formCustomModelFields = customFilterModelNames;
    this.mobxState.formModelOptions = { ...systemFilterOptions, ...customFilterOptions };
    this.mobxState.formOptionSearchFunctions = this.mobxState.optionSearchFunctions;
  };
  //data loader
  fetchData = () => {
    this.setupFilters();

    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}) // Prefer existing values
      };

      // Grab the existing values for the form
      axios.get('/v1/custom_schemas', {params: {name: 'default_date_filter_value'}}).then(resp => {
        let customSchema = resp.data.custom_schemas[0];
        this.mobxState.editId = customSchema.id;
        let schemaDefinition = customSchema.schema_definition;
        let labelize = v => ({label: `${v}`, value: v});
        // Build Form Values In label+value format
        let defaultFormState = {};
        Object.keys(schemaDefinition).forEach(k => {
          defaultFormState[k] = (schemaDefinition[k] || []).map(x => labelize(x));
        });
        this.mobxState.defaultFormState = defaultFormState;
      });
    });
  }; //end fetchData() ///////////////
  //////////////////END HTTP/////////////////
  render() {
    const hcHeight = '300px';

    return (
      <div>
        <div
          className='jumbotron jumbotron_full_width'
          style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
        >
          <div className='jumbotron_full_width_container'>
            <div className='row'>
              <div className='col-sm-12'>
                <span className='fa-layers fa-fw fa-2x'>
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform='grow-3'
                    color='#53C8C5'
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fas', 'calendar-week']}
                    color='white'
                    transform='shrink-5'
                    style={{ marginTop: '3px' }}
                  />
                </span>
                &nbsp;
                <b className='dash_header'>Default Spend Filters</b>
              </div>
            </div>
          </div>
        </div>
        {/* BADGES */}
        {/* TODO: ADD BADGES*/}
        <br />
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>Edit Default Filters</b>
              </div>
              <div className='panel-body'>
                <DefaultFiltersForm
                  schemaDefinition={{}}
                  editId={this.mobxState.editId}
                  defaultFormState={this.mobxState.defaultFormState}
                  customOptions={this.mobxState.customOptions || {}}
                  modelFields={this.mobxState.formModelFields}
                  modelOptions={this.mobxState.formModelOptions}
                  searchFunctions={this.mobxState.formOptionSearchFunctions}
                  customModelFields={this.mobxState.formCustomModelFields}
                  customModelOptions={this.mobxState.formModelOptions}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(DefaultFiltersPage, {
  mobxState: observable
});

export default observer(DefaultFiltersPage);

// export default DefaultFiltersPage ;
