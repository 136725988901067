import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utility
import { zeroNull, extractToken } from '../../services/Utilities';
//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Simple Cell Renderer Example: https://www.ag-grid.com/angular-grid/group-cell-renderer/#example-group-cellrenderer

// Inner render meant for passing to AutoGroupColumn deps like so:
// TODO: Add example;
//  <GridRenderer
//    gridHeight={'650px'}
//    columnDefs={this.mobxState.columnDefsSpendProjectCrosstabByCategory}
//    autoGroupColumnDef={{minWidth: 300, headerName: 'Category',
//       cellRendererParams: {
//        innerRenderer: (...args) => {
//          this.timesEntered = this.timesEntered + 1;
//          let node = args[0].node;
//          let field = node.field;
//          let group = node.group; // Whether node is part of row group
//          let isTL  = node.level == 0;
//          let originalColDef = node.gridApi.getColumnDef(field);
//
//          if (this.timesEntered <= 2){
//            let foo = [...args];
//            debugger;
//          };
//         }
//       }
//     }}
const passthrowRowGroupInnerRenderer = (args) => {
  let node = args.node;
  let {field, group, level, gridApi} = node;
  let data = node.data;
  let originalColDef = gridApi.getColumnDef(field);
  // Lift the following to the rowgroup:
  // - onCellClicked to the rowgroup
  // -
  if (originalColDef.cellRenderer) {
    return (
      <div className={`row-group-passthrough ${originalColDef.cellClass || ""}`}>
        {originalColDef.cellRenderer(args)}
      </div>
    )
  } else {
    return (
      <div
        className={`row-group-passthrough ${originalColDef.cellClass || ""}`}
        onClick={() => (originalColDef.onCellClicked && originalColDef.onCellClicked({target: '', value: args.value}, args))}>
        {args.value}
      </div>
    );
  }
}

const debuggedRenderer = n => {
  let timesEntered = n;
  return (args) => {
    timesEntered = timesEntered - 1;
    if (timesEntered > 0) {
      debugger;
    }
    return passthrowRowGroupInnerRenderer(args);
  }
}

export default debuggedRenderer(0);

