import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utility
import { zeroNull, extractToken } from '../../services/Utilities';
//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

export default class extends Component {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }
  checkedHandler(e) {
    let checked = e.target.checked;
    let colId = this.props.column.colId;
    this.props.node.setDataValue(colId, checked);
  }
  render() {
    return <input type='checkbox' onClick={this.checkedHandler} checked={this.props.value} />;
  }
}
