import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../../services/Utilities';

import { text } from '@fortawesome/fontawesome-svg-core';

import { Input } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
const projectId = extractToken(window.location.pathname, '/', 3);

class NewItemNameInput extends React.Component {

  mobxState = {
    newItemNameInputValue: [], //value from input field in case it's needed in state
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    //this.fetchData();
  }
  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    //Will load data from Threshold column of discount_constraints table
    ////////////////////
    //FORM LOAD
    //END FORM LOAD
    //////////////////

  } //end fetchData()


  render(){

    return(
     
            
          <div className={this.props.scopeCssClass}>
            <div className="row">
                <div className="col-lg-2">
                    <div>
                        <FontAwesomeIcon icon={['fad', 'hand-point-up']} 
                        mask={['fas', 'circle']} size='4x' 
                        transform="shrink-6" color="#4CABA9"
                        style={{marginRight:'0px'}}/>
                    </div>
                </div>
                <div className="col-lg-10">
                    <b>Item Name</b>
                    <Input name="item name" 
                    placeholder="Enter an Item name" 
                    className="form-control"
                    onChange={(e) => this.props.handleFormChange(this.props.formField, e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
  }

}

decorate( NewItemNameInput, {
    mobxState: observable
})

export default observer(NewItemNameInput);
