import React from 'react';
import { Form, Text } from 'informed';

//handles rendring form components and their validation
class Checklist extends React.Component {

  //displays checklist for KanBan.
  //takes in array of object of task info
  //Progress bar is calculated based on completed tasks/all tasks  
  render(){

  console.log("modal log tasks:::", this.props.tasks);
  const completedTasks = this.props.tasks.filter(x => x.Date_Completed).length;
  const completedPercent = Math.round(completedTasks / this.props.tasks.length * 100) || 0; //for the progress bar, also 0s out if Nan

  return (
      <div>
        
        <span className="glyphicon glyphicon-check modal_glyphi" aria-hidden="true"></span><span className="modal_section_title"> Task Progress:</span>
        <br />
        <br />
        <div className="progress">
          <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar" aria-valuenow={completedPercent} aria-valuemin="0" aria-valuemax="100" style={{width: completedPercent+'%'}}>
            {completedPercent}%
          </div>
        </div>
        <br />
        <span className="glyphicon glyphicon-tasks modal_glyphi" aria-hidden="true"></span><span className="modal_section_title"> Tasks and Completion:</span>  
        <br />
        <ul className="no_list_style">
        { this.props.tasks.map(function(d, idx){
          if(d.Date_Completed) {
            return (<li key={idx}><span className="glyphicon glyphicon-check text_success" aria-hidden="true"></span>  {d.Task_Name}<span className="light_date"> - {d.Date_Completed}</span></li>)
          } else {
            return (<li key={idx}><span className="glyphicon glyphicon-unchecked" aria-hidden="true"></span> {d.Task_Name}</li>)
          }
        }) 
        }
        </ul>
      </div>
    )
  }

}

export default Checklist
