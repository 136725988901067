import React, { Component } from 'react';

class Sidebar extends React.Component {
  render() {
    let wrapItem = (el, ix) => <li key={`sidebar-child-${ix}`}>{el}</li>;
    let childArray = React.Children.toArray(this.props.children); // Proper way to map props.children
    let wrappedChildren = childArray.map((el, ix) => wrapItem(el, ix));

    return (
      <nav id='sidebar' className="navbar navbar-default">
        <div className='sidebar_line collaplibles'></div>
        <ul className='list-unstyled components'>{wrappedChildren}</ul>
        <div className='sidebar_line collaplibles'></div>
      </nav>
    );
  }
}

export default Sidebar;
