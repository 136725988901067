import React, { Component } from 'react';
import {timeoutLogout} from '../../services/Timing';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//images
import CompanyLogoWithText from '../../../assets/images/simfoni_logo_1920x1080_transparent_background.png'
import CompanyLogoWithoutText from '../../../assets/images/simfoni_transparent_icon.png'

//sidebar
import MainSidebar from '../../headers/MainSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

 

//sidebar
import Sidebar from './Sidebar';
import SidebarDropdown from './SidebarDropdown';
import SidebarLink from './SidebarLink';

// Dashboard
import Dashboard from './views/dashboard/Dashboard';
import CategorySummary from './views/category_summary/CategorySummary';
import TransactionDetails from './views/transaction_details/TransactionDetails';
import CategoryBreakdown from './views/category_breakdown/CategoryBreakdown';
import SpendRiskProfileOverview from './views/spend_risk_profiles/SpendRiskProfileOverview';
import SavingsOpportunitiesOverview from './views/savings_opportunities/SavingsOpportunitiesOverview';
import ProjectCrosstab from './views/project_crosstab/ProjectCrosstab';
import OutlierAnalyticsOverview from './views/outlier_analytics/OutlierAnalyticsOverview';
import DatasetAnalysis from './views/dataset_analysis/DatasetAnalysis';
import CustomAnalysisOverview from './views/custom_analysis/CustomAnalysisOverview';
import SpendReclassificationRuleOverview from './views/spend_reclassification_rules/SpendReclassificationRuleOverview';
import SupplierOverview from './views/supplier_overview/SupplierOverview';

import MainHeader from '../../headers/MainHeader';
import withAuth from '../../security/withAuth';

//security
import { checkRole } from '../../security/SecurityUtilities';
import withRole from '../../security/withRole';

import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

//homemade utilities
import { zeroNull, extractToken } from '../../services/Utilities';

//filter helpers
import { injectDefaultFiltersInModule } from '../../services/filter_helpers';
import { injectCustomerColorScheme } from '../../services/InjectedStyle'

const _ = require('lodash'); //extra utilities

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class Spend extends React.Component {
  mobxState = {
    pages: [],      // List of pages
    pageLookup: {}, // Page id->page lookup
    shouldDisplay: {},
    finishedInjectingFilters: false,
    client_logo: null,
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
    timeoutLogout(60*60);
  }

	//timeout user session in seconds
	componentDidUpdate() {
		timeoutLogout(60*60);
	}

  fetchData = () => {
    Promise.all([
        axios.get('/v1/pages'),
        injectDefaultFiltersInModule(),
        injectCustomerColorScheme()
        ]).then(([pageResp, injectedFilters, injectedGlobals]) => {
      this.mobxState.pages = pageResp.data.pages;
      this.mobxState.pageLookup = pageResp.data.pages.map(p => ({ [p.id]: p })).reduce((a, b) => ({ ...a, ...b }));
      this.mobxState.finishedInjectingFilters = true;
      console.info('FINISHED INJECTING FILTERS');
    });
    //grab custom logo from db
    axios.get(`/v1/custom_schemas/client_logo/retrieve`)
    .then(async (res) => {
      let pic = res.data.pic
      let picture = "data:image/png;base64," + pic
      this.mobxState.client_logo = picture
    })
    .catch((error) => {
      console.log("Error Fetching Logo ", error)
    })
  };
  shouldDisplayPageInSidebar = pageId => {
    let p = this.mobxState.pages.filter(x => x.id === pageId)[0];
    if (p && p.enabled) {
      return true;
    } else {
      return false;
    }
  };
  shouldDisplaySidebarTab = pageIds => {
    return pageIds.map(id => this.shouldDisplayPageInSidebar(id)).some(x => x === true);
  };
  getDisplayName = (pageId, defaultValue) => {
    let page = this.mobxState.pageLookup[pageId];
    if (page) {
      if (!(/^\s*$/.test(page.custom_name))) {
        return page.custom_name;
      }
    }
    return defaultValue;
  }

  render() {
    //this variable does all the routing and is passed into here from parent
    const match = this.props.match;
    const canTransactionW = checkRole({ model: 'transaction', permission: 'w' });
    return (
      <div>
        <div id='main-page-header' className='main-page-header'>
          {/* HEADER ---------------------------------------------------------- */}
          <div id='top_line'></div>
          <nav className='navbar header-navbar navbar-fixed-top'>
            <div className='navbar-wrapper'>
              {/* Header and Logo ----------------------------------------------------------------- */}
              <div className='navbar-logo'>
                <button type='button' data-toggle="modal" data-target="#main-sidebar-dock" className='btn btn-default navbar-btn'>
                  <FontAwesomeIcon icon={['fas', 'bars']} color="#D3D4D6" transform="" style={{fontSize: '19px'}} />
                </button>
                <img alt="Simfoni Logo" className="simfoni-logo" src={CompanyLogoWithoutText} />
              </div>

              {/* Links in the center ------------------------------------------------------------- */}
              <div className='navbar-menus'>
                 <ul className='nav navbar-nav'>
                   {/* Spend */}
                   {this.shouldDisplaySidebarTab([201, 202, 203, 204]) && (
                      <li className="dropdown">
                        <a id="spendDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Spend
                          <span className="caret"></span>
                        </a>
                        <ul className='dropdown-menu' id='spendSubmenu'>
                         {this.shouldDisplayPageInSidebar(201) && (
                           <li><Link to={`${match.url}/spend`}>{this.getDisplayName(201, 'Overview')}</Link></li>
                         )}
                         {this.shouldDisplayPageInSidebar(202) && (
                           <li><Link to={`${match.url}/category_summary`}>{this.getDisplayName(202, 'Category Summary')}</Link></li>
                         )}
                         {this.shouldDisplayPageInSidebar(203) && (
                           <li><Link to={`${match.url}/category_breakdown`}>{this.getDisplayName(203, 'Category Breakdown')}</Link></li>
                         )}
                         {this.shouldDisplayPageInSidebar(204) && (
                           <li><Link to={`${match.url}/transaction_details`}>{this.getDisplayName(204, 'Transaction Details')}</Link></li>
                         )}
                        </ul>
                      </li>
                     )
                  }
                 {/* Risk Profiles */}
                {this.shouldDisplaySidebarTab([205]) && (
                     <li className="dropdown">
                       <a id="riskProfilesDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                         Risk Profiles
                         <span className="caret"></span>
                       </a>
                       <ul className='dropdown-menu' id='riskProfilesSubmenu'>
                         {this.shouldDisplayPageInSidebar(205) && (
                           <li><Link to={`${match.url}/risk_profiles`}>{this.getDisplayName(205, 'Overview')}</Link></li>
                         )}
                       </ul>
                     </li>
                    )
                 }
                 {/* Savings Opportunities */}
                 {this.shouldDisplaySidebarTab([207]) && (
                      <li className="dropdown">
                        <a id="savingsOpportunitiesDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Savings Opportunity
                          <span className="caret"></span>
                        </a>
                        <ul className='dropdown-menu' id='savingsOpportunitiesSubmenu'>
                         {this.shouldDisplayPageInSidebar(207) && (
                           <li><Link to={`${match.url}/savings_opportunities`}>{this.getDisplayName(207, 'Overview')}</Link></li>
                         )}
                         {this.shouldDisplayPageInSidebar(214) && (
                           <li><Link to={`${match.url}/project_crosstab`}>{this.getDisplayName(214, 'Cross Sourcing')}</Link></li>
                         )}
                        </ul>
                      </li>
                     )
                  }
                 {/* Outlier Analytics */}
                 {this.shouldDisplaySidebarTab([208]) && (
                      <li className="dropdown">
                        <a id="outlierAnalyticsDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Outlier Analytics
                          <span className="caret"></span>
                        </a>
                        <ul className='dropdown-menu' id='outlierAnalyticsSubmenu'>
                         {this.shouldDisplayPageInSidebar(208) && (
                           <li><Link to={`${match.url}/outlier_analytics`}>{this.getDisplayName(208, 'Overview')}</Link></li>
                         )}
                        </ul>
                      </li>
                     )
                  }
                 {/* Dataset Analysis */}
                 {this.shouldDisplaySidebarTab([210]) && (
                      <li className="dropdown">
                        <a id="datasetAnalyticsDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Dataset Analysis
                          <span className="caret"></span>
                        </a>
                        <ul className='dropdown-menu' id='datasetAnalyticsSubmenu'>
                         {this.shouldDisplayPageInSidebar(210) && (
                           <li><Link to={`${match.url}/dataset_analysis`}>{this.getDisplayName(210, 'Overview')}</Link></li>
                         )}
                        </ul>
                      </li>
                     )
                  }
                 {/* Reclass Rules */}
                 {this.shouldDisplaySidebarTab([209]) && (
                      <li className="dropdown">
                        <a id="reclassRulesDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Reclass Rules
                          <span className="caret"></span>
                        </a>
                        <ul className='dropdown-menu' id='reclassRulesSubmenu'>
                         {this.shouldDisplayPageInSidebar(209) && (
                           <li><Link to={`${match.url}/spend_reclassification_rules`}>{this.getDisplayName(209, 'Overview')}</Link></li>
                         )}
                         {this.shouldDisplayPageInSidebar(213) && (
                           <li><Link to={`${match.url}/suppliers`}>{this.getDisplayName(213, 'Bidders')}</Link></li>
                         )}
                        </ul>
                      </li>
                     )
                  }
                 {/* Custom Reports */}
                 </ul>
              </div>
              {/* Button for right hand side */}
              <div className="pull-right" style={{paddingTop: '5px'}}>
                <img src={this.mobxState.client_logo} style={{maxWidth: '200px'}}/>
              </div>
            </div>
          </nav>
        </div>
        {/* Sidebar ------------------------------------------------------- */}
        <MainSidebar />

        {/* BODY ---------------------------------------------------------- */}

        <div className='wrapper' style={{}}>
          <div id='content'>
             {this.mobxState.finishedInjectingFilters ? (
                <Switch>
                  <Route path={`${match.path}/spend`} component={withRole(Dashboard, { model: 'transaction', permission: 'r' })} />
                  <Route path={`${match.path}/category_summary`} component={withRole(CategorySummary, { model: 'transaction', permission: 'r' })} />
                  <Route path={`${match.path}/category_breakdown`} component={withRole(CategoryBreakdown, { model: 'transaction', permission: 'r' })} />
                  <Route path={`${match.path}/transaction_details`} component={withRole(TransactionDetails, { model: 'transaction', permission: 'r' })} />
                  <Route path={`${match.path}/risk_profiles`} component={withRole(SpendRiskProfileOverview, { model: 'spend_risk_profile', permission: 'r' })} />
                  <Route path={`${match.path}/savings_opportunities`} component={withRole(SavingsOpportunitiesOverview, { model: 'spend_savings_opportunity', permission: 'r' })} />
                  <Route path={`${match.path}/project_crosstab`} component={withRole(ProjectCrosstab, { model: 'spend_project_crosstab', permission: 'r' })} />
                  <Route path={`${match.path}/outlier_analytics`} component={withRole(OutlierAnalyticsOverview, { model: 'spend_outlier', permission: 'r' })} />
                  <Route path={`${match.path}/dataset_analysis`} component={withRole(DatasetAnalysis, { model: 'spend_dataset_analysis', permission: 'r' })} />
                  <Route path={`${match.path}/spend_reclassification_rules`} component={withRole(SpendReclassificationRuleOverview, { model: 'spend_reclassification_rule', permission: 'w' })} />
                  <Route path={`${match.path}/custom_analysis`} component={withRole(CustomAnalysisOverview, { model: 'spend_custom_01', permission: 'r' })} />
                  <Route path={`${match.path}/suppliers`} component={withRole(SupplierOverview, { model: 'spend_supplier_overview', permission: 'w' })} />
                </Switch>
               ) : (
                 <React.Fragment />
               )}
            <div className='line'></div>
          </div>
        </div>
      </div>
      
    ); //end return
  } // end render
} //end class
// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Spend, {
  mobxState: observable
});

export default observer(Spend);
