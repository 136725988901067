import React from "react"
import ReactDom from 'react-dom';

//notifications
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

//state
import { decorate, observable, computed, action } from "mobx"
import { observer } from "mobx-react"

class StandardNotificationRenderer extends React.Component {

  //mount data
  componentDidMount() {
    this.notificationDOMRef = React.createRef();
  }

  addNotification = (configObject) => {
    this.notificationDOMRef.current.addNotification({
      title: configObject.title,
      message: configObject.message,
      type: configObject.type,
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 30000 },
      dismissable: { click: true }
    });
  }

  render() {

    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <ReactNotification ref={this.notificationDOMRef} />
          </div>
        </div>
      </div>
    )

  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
// decorate(StandardNotificationRenderer, {
// 	mobxState: observable
// })
//
// export default observer(StandardNotificationRenderer);
export default StandardNotificationRenderer;
