import React from 'react';
import Checklist from './Checklist';
//!!!!!!!!!!!!
//import InlineEdit from 'react-edit-inline2'; //NOTE THIS IS A FORKED MODULE AS NEW REACT BROKE IT.. NEED TO SEE IN THE FUTURE IF BREAKING ISSUE HAS BEEN RESOLVED
import { RIEToggle, RIEInput, RIETextArea, RIENumber, RIETags, RIESelect } from '@attently/riek'
//time formatting
import Moment from 'react-moment';
import 'moment-timezone';
import DateTimePicker from 'react-datetime-picker';

//handles rendring modals for Kanban popups
class KanbanModal extends React.Component {

  customValidateTitle(text) {
    return (text.length > 2 && text.length < 201);
  }

  deleteCard = () => {
    console.log("INSIDE deleteCard");
    this.props.handleTicketDelete(this.props.modalCard.Tket_ID);
  }

  render(){

    console.log("ID:::", this.props.modalId);
    console.log("CARD:::", this.props.modalCard)
		//delete ticket thru api

    return (
      <div>
        {/* Modal */}
        <div className="modal fade" id={this.props.modalId} role="dialog">
          <div className="modal-dialog">

            {/* Modal Content */}
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" id="hideModalButton" className="close" data-dismiss="modal">&times;</button>
                <span className="glyphicon glyphicon-tag modal_glyphi" aria-hidden="true"></span>
                <b><span className="modal_title">&nbsp;
                <RIEInput
                  //issue with value prop.. if it's blank and another one is blank, it gets confused with the values between modals
                  //solution is to just never have blanks and populate the value with the Ticket#:
                  value={this.props.modalCard.Request_Title || 'Ticket #' + this.props.modalCard.Tket_ID }
                  change={this.props.handleModalTitleChange}
                  propName={'title'}
                  // defaultValue='Click to add ticket title'
                  // validate={_.isString}
                />

                </span></b>
              </div>
              <div className="modal-body">
                <Checklist tasks={this.props.tasks} />
                <br />

                <span className="glyphicon glyphicon-calendar modal_glyphi" aria-hidden="true"></span><span className="modal_section_title"> Important Dates:</span>
                <br />
                <table className="padding_table_cols">
                  <tbody>
                    {this.props.modalCard.Accepted_Date && <tr><td>Accepted Date: </td><td><Moment format={"MM/DD/YYYY"} className="light_date">{this.props.modalCard.Accepted_Date}</Moment></td></tr>}
                    {this.props.modalCard.Answered_Date && <tr><td>Answered Date: </td><td><Moment format={"MM/DD/YYYY"} className="light_date">{this.props.modalCard.Answered_Date}</Moment></td></tr>}
                    {this.props.modalCard.Created_Date && <tr><td>Created Date: </td><td><Moment format={"MM/DD/YYYY"} className="light_date">{this.props.modalCard.Created_Date}</Moment></td></tr>}
                    {this.props.modalCard.Released_Date && <tr><td>Released Date: </td><td><Moment format={"MM/DD/YYYY"} className="light_date">{this.props.modalCard.Released_Date}</Moment></td></tr>}
                    {this.props.modalCard.Rejected_Date && <tr><td>Rejected Date: </td><td><Moment format={"MM/DD/YYYY"} className="light_date">{this.props.modalCard.Rejected_Date}</Moment></td></tr>}
                    {this.props.modalCard.Due_Date && <tr><td>Due Date: </td><td><Moment format={"MM/DD/YYYY"} className="light_date">{this.props.modalCard.Due_Date}</Moment></td></tr>}
                    {this.props.modalCard.Revised_Due_Date && <tr><td>Revised Due Date: </td><td><Moment format={"MM/DD/YYYY"} className="light_date">{this.props.modalCard.Revised_Due_Date}</Moment></td></tr>}
                  </tbody>
                </table>
                <br />
                <span className="glyphicon glyphicon-cog modal_glyphi" aria-hidden="true"></span><span className="modal_section_title"> Actions:</span>
                <br />
                <a data-toggle="collapse" href="#modalDelete">Delete Ticket</a>

                <div id="modalDelete" className="panel-collapse collapse">
                  <div>
                    <br />
                    Are you sure you want to permanently delete this ticket?
                    &nbsp;&nbsp;
                    <a data-toggle="collapse" href="#modalDelete" onClick={this.deleteCard}>Yes</a>
                    &nbsp;&nbsp;&nbsp;
                    <a data-toggle="collapse" href="#modalDelete">No </a>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div> {/* End Modal */}
      </div>
    )
  }

}

export default KanbanModal


// <span>
//   <button type="button" className="btn btn-success btn-md" data-toggle="modal" data-target="#exampleModalLong">
//     open
//   </button>

//   <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
//     <div className="modal-dialog" role="document">
//       <div className="modal-content">
//         <div className="modal-header">
//           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//             <span aria-hidden="true">&times;</span>
//           </button>
//           <h4 className="modal-title" id="exampleModalLongTitle">Modal Title</h4>
//         </div>
//         <div className="modal-body">

//           optional
//           <hr>
//           <div>

//             <span className="colored_step">1</span>
//             <span className="btn btn-info btn-md btn-file">
//               Choose File
//             </span>
//             <br>
//             <br>Select file to upload from your computer
//             <hr>

//             <span className="colored_step">2</span>
//             <span>text</span>
//             <br>
//             <br>
//             Enter sheet name if it is different from the template
//             <hr>

//             <div>
//               <span className="colored_step">3</span>
//               button
//               <br>
//               <br>
//               Upload data into your project
//             </div>

//             <br>

//           </div>
//         </div>


//       </div>
//     </div>
//   </div>
// </span>
