import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, Select as AntdSelect, TreeSelect } from 'antd';
import AntdMultiSelect from '../../../../filters/AntdMultiSelect';
import AntdSearchedMultiSelect from '../../../../filters/AntdSearchedMultiSelect';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';

//security
import { checkRole } from '../../../../security/SecurityUtilities';

const _ = require('lodash'); //extra utilities

class EditRuleForm extends React.Component {
  initialState = {
    name: '',
    description: '',
    category_id: '',
    priority: 0,
    id: this.props.editId,
    updatedAt: `${Date.now()}`,
    createdAt: `${Date.now()}`,
    updated_at: `${Date.now()}`,
    created_at: `${Date.now()}`,
    priority_dirty: false
  };
  initialMetadata = { metadata__errors: [] };
  // Helper method to split up:
  // - keys that will be in conditions
  // - keys that will be toplevel values
  // - keys that are metadata
  helperBreakupState = state => {
    // Filter out values that won't be submitted (prefixed by metadata__)
    let isCustomField = fieldName => /custom_field_\d+$/.test(fieldName);
    let initialStateKeys = Object.keys(this.initialState);
    let metadataKeys = Object.keys(state).filter(key => key.startsWith('metadata__'));
    let fieldKeys = initialStateKeys.filter(key => !metadataKeys.includes(key));
    let conditionKeysCombined = Object.keys(state).filter(k => ![...initialStateKeys, ...metadataKeys].includes(k));
    let conditionKeys = conditionKeysCombined.filter(k => !(isCustomField(k)));
    let customFieldConditionKeys = conditionKeysCombined.filter(k => isCustomField(k));
    return {
      fieldKeys: fieldKeys,
      conditionKeys: conditionKeys,
      customFieldConditionKeys: customFieldConditionKeys,
      conditionKeysCombined: conditionKeysCombined,
      metadataKeys: metadataKeys
    };
  };


  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      ...this.initialMetadata,
      ...this.props.defaultFormState
    };
  }
  componentWillMount() {}
  componentWillReceiveProps(nextProps) {
    // We need to set default values so they're in custom_fields if missing
    let filledInState = { ...nextProps.defaultFormState };
    for (const [key, value] of Object.entries(nextProps.schemaDefinition)) {
      let keyToCheck = 'custom_fields__' + key;
      if (!Object.prototype.hasOwnProperty.call(this.state, keyToCheck)) {
        filledInState[keyToCheck] = null;
      }
    }
    this.setState(filledInState);
  }

  // Input building methods ==========================================================================================
  buildCustomField = (name, definition) => {
    const hasEnum =
      Object.prototype.hasOwnProperty.call(definition.schema, 'enum') && Array.isArray(definition.schema.enum);
    const inputType = definition.schema.type;
    if (hasEnum) {
      return this.makeEnumSelect(name, definition);
    } else {
      if (inputType === 'string') {
        return this.makeStringInput(name, definition);
      } else if (inputType === 'number') {
        return this.makeNumberInput(name, definition);
      } else {
        console.error('UNRECOGNIZED FIELD DEFINITION', name);
        return <div class='unknown-field-type'></div>;
      }
    }
  };
  makeEnumSelect = (name, definition) => {
    return (
      <div className='form-group'>
        <label htmlFor={`form-${name}-input`}>{definition.field_alias}</label>
        <AntdSelect
          id={`form-${name}-input`}
          options={definition.schema.enum.map(opt => ({ label: opt, value: opt }))}
          defaultValue={null}
          className='form-control'
          onChange={value => this.handleChange(name, value)}
          dropdownClassName={'antd-filter-dropdown'}
          placeholder='Please Select One'
        />
      </div>
    );
  };
  makeStringInput = (name, definition) => {
    return (
      <div className='form-group'>
        <label htmlFor={`form-${name}-input`}>{definition.field_alias}</label>
        <AutoComplete
          id={`form-${name}-input`}
          options={[]}
          defaultValue=''
          className='form-control'
          onChange={value => this.handleChange(name, value)}
          dropdownClassName={'antd-filter-dropdown'}
          placeholder='Please Enter Value'
        />
      </div>
    );
  };
  makeNumberInput = (name, definition) => {
    return (
      <div className='form-group'>
        <label htmlFor={`form-custom-${name}-input`}>{definition.field_alias}</label>
        <InputNumber
          id={`form-${name}-input`}
          defaultValue=''
          min={definition.schema.minimum}
          max={definition.schema.maximum}
          className='form-control'
          onChange={value => this.handleChange(name, value)}
          placeholder='Please Enter Value'
          style={{ width: '100%' }}
        />
      </div>
    );
  };
  // Handler methods        ==========================================================================================
  // Change --------------------------------------------------------------
  validateState = (name, value) => {
    if (name === 'priority') {
      return /^\d+$/.test(`${value}`);
    } else {
      return true;
    }
  };

  handleChange = (name, value) => {
    // Calculate new priority and metadata updates
    // This will be the number of conditions set, if the user hasn't explicitly set the priority
    // Flags
    let dealingWithPriority = name === 'priority';
    let unsettingValue = (Array.isArray(value) && value.length === 0) || value === null;
    let explicitlySetPriority = this.state.priority_dirty;

    // Determine number of condition fields set after setting current field
    let nextExpectedState = Object.assign({ ...this.state }, { [name]: value });
    let { conditionKeysCombined } = this.helperBreakupState(nextExpectedState);
    let conditionsSet = _.uniq(
      ['name', 'description', 'priority_dirty', 'category_id'].includes(name)
        ? conditionKeysCombined
        : [name, ...conditionKeysCombined]
    ).filter(k => !_.isEmpty(nextExpectedState[k]));
    let conditionsCount = conditionsSet.length;

    let newPriorityAndMetadata = {};
    if (dealingWithPriority) {
      newPriorityAndMetadata = {
        priority: unsettingValue ? conditionsCount : value,
        priority_dirty: unsettingValue ? false : true
      };
    } else {
      newPriorityAndMetadata = {
        priority: explicitlySetPriority ? this.state.priority : conditionsCount
      };
    }
    // Update the state
    if (this.validateState(name, value)) {
      console.info('SETTING STATE:', { ...{ [name]: value }, ...newPriorityAndMetadata });
      this.setState({ ...{ [name]: value }, ...newPriorityAndMetadata });
    }
  };
  // Submission --------------------------------------------------------------
  validateSubmit = state => {
    let { fieldKeys, conditionKeys, metadataKeys, customFieldConditionKeys } = this.helperBreakupState(state);
    let errors = [];
    if (/^\s*$/.test(`${state.name}`)) {
      errors.push({ name: 'Please provide a name for your classification rule' });
    }
    if ([...conditionKeys, ...customFieldConditionKeys].length === 0) {
      errors.push({ conditions: 'Please provide at least one condition for your classification rule' });
    }
    if (!/^\d+$/.test(`${state.category_id}`)) {
      errors.push({ category_id: 'Please provide a category for your classification rule' });
    }
    if (errors.length === 0) {
      return true;
    } else {
      let errorMessages = Object.values(Object.assign({}, ...errors)).map(v => `- ${v}`);
      let missingMessage = 'Form Submission Error:\n' + errorMessages.join('\n');
      console.error(missingMessage);
      alert(missingMessage);
      this.setState({ metadata__errors: Object.assign({}, ...errors) });
      return false;
    }
  };
  stateToParams = state => {
    let { fieldKeys, conditionKeys, metadataKeys, customFieldConditionKeys } = this.helperBreakupState(state);
    // Unflatten conditions
    let paramsForPut = {};
    fieldKeys.forEach(k => _.set(paramsForPut, k.split('__'), state[k]));
    // Convert from {nested: [label+value]} to {model: {fieldName: [value]}}
    paramsForPut['conditions'] = Object.fromEntries(conditionKeys.map(k => [k, this.state[k].map(v => v.value)]));
    paramsForPut['conditions']['custom_fields'] = Object.fromEntries(customFieldConditionKeys.map(k => [k, this.state[k].map(v => v.value)]));
    paramsForPut['conditions'] = formatFiltersForServer(paramsForPut['conditions']);
    return paramsForPut;
  };
  handleSubmit = async () => {
    // POST and reload page
    let paramsForPut = this.stateToParams(this.state);
    if (this.validateSubmit(paramsForPut)) {
      axios
        .put(`/v1/spend_reclassification_rules/${this.props.ruleId}`, paramsForPut)
        .then(result => {
          window.location.reload();
        })
        .catch(e => {
          // TODO: Work out error handling
          alert('Something went wrong. Please check your inputs and try again.');
        });
    }
    console.log('RECIEVED', paramsForPut);
  };
  render() {
    // Format custom fields
    let conditionTables = [];
    let combinedFields = [...this.props.modelFields, ...this.props.customModelFields];
    let isCustomField = fieldName => /custom_field_\d+$/.test(fieldName);

    Object.entries(_.groupBy(combinedFields, x => x.split('__')[0])).forEach(([tableName, tableFields]) => {
      let tableColumns = [];
      tableFields.forEach(fieldName => {
        tableColumns.push(
          <div className='responsive-grid-column' key={`condition-select-${tableName}-${fieldName}`}>
            <div className='form-group'>
              <label htmlFor='form-description-input'>{this.props.customOptions[fieldName] || fieldName}</label>
              <AntdSearchedMultiSelect
                name={fieldName}
                optionSearch={
                  isCustomField(fieldName)
                    ? this.props.searchFunctions.customSearchFunctions[fieldName]
                    : this.props.searchFunctions.searchFunctions[fieldName]
                }
                value={this.state[fieldName] || []}
                onChange={value => this.handleChange(fieldName, value)}
                options={this.props.modelOptions[fieldName] || []}
                closeMenuOnSelect={this.props.closeMenuOnSelect}
                maxMenuHeight={this.props.maxMenuHeight}
                placeholder='Please Select Value(s)'
                key={'system-filter-select-' + fieldName}
                popupContainerSelector={'body'}
              />
            </div>
          </div>
        );
      });
      conditionTables.push(
        <div className='col-xs-12' key={`condition-select-${tableName}`}>
          <div className='row'>
            <div className='responsive-grid-4'>{tableColumns}</div>
          </div>
        </div>
      );
    });
    return (
      <div>
        <div className='row'>
          <div className={'col-xs-12'}>
            <div className={'top_panel_info'}>Provide your basic rule settings</div>
          </div>
        </div>
        {/* Name */}
        <div className='row'>
          <div className='responsive-grid'>
            {/* Name */}
            <div className='responsive-grid-column iconned-input'>
              <FontAwesomeIcon
                icon={['fad', 'folder-open']}
                mask={['fas', 'circle']}
                size='4x'
                transform='shrink-6'
                color='#4CABA9'
                style={{ marginRight: '15px' }}
              />
              <div className='form-group'>
                <label htmlFor='form-name-input'>Name</label>
                <AutoComplete
                  id='form-name-input'
                  options={[]}
                  defaultValue={this.state.name || ''}
                  className='form-control'
                  onChange={value => this.handleChange('name', value)}
                  dropdownClassName={'antd-filter-dropdown'}
                  placeholder='Please Enter Value'
                />
              </div>
            </div>
            {/* Description */}
            <div className='responsive-grid-column'>
              <div className='iconned-input'>
                <FontAwesomeIcon
                  icon={['fad', 'chalkboard-teacher']}
                  mask={['fas', 'circle']}
                  size='4x'
                  transform='shrink-6'
                  color='#4CABA9'
                  style={{ marginRight: '15px' }}
                />
                <div className='form-group'>
                  <label htmlFor='form-description-input'>Description</label>
                  <AutoComplete
                    id='form-description-input'
                    options={[]}
                    defaultValue={this.state.description || ''}
                    className='form-control'
                    onChange={value => this.handleChange('description', value)}
                    dropdownClassName={'antd-filter-dropdown'}
                    placeholder='Please Enter Value'
                  />
                </div>
              </div>
            </div>
            {/* Priority */}
            <div className='responsive-grid-column'>
              <div className='iconned-input'>
                <FontAwesomeIcon
                  icon={['fas', 'chevron-up']}
                  mask={['fas', 'circle']}
                  size='4x'
                  transform='shrink-6'
                  color='#4CABA9'
                  style={{ marginRight: '15px' }}
                />
                <div className='form-group'>
                  <label htmlFor='form-priority-input'>Rule Priority</label>
                  <InputNumber
                    id='form-priority-input'
                    options={[]}
                    defaultValue={this.state.priority || ''}
                    value={this.state.priority}
                    className='form-control'
                    style={{ width: '100%' }}
                    onChange={value => this.handleChange('priority', value)}
                    placeholder='Please Enter Value'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Conditions */}
        <div className='row'>
          <div className={'col-xs-12'}>
            <div className={'top_panel_info'}>When the following conditions are met:</div>
          </div>
        </div>
        <div className='row'>{conditionTables}</div>
        {/* Category ID */}
        <div className='row'>
          <div className={'col-xs-12'}>
            <div className={'top_panel_info'}>Reassign the following transaction fields:</div>
          </div>
        </div>
        <div className='row'>
          <div className='responsive-grid'>
            <div className='responsive-grid-column'>
              <div className='iconned-input'>
                <FontAwesomeIcon
                  icon={['fad', 'pencil']}
                  mask={['fas', 'circle']}
                  size='4x'
                  transform='shrink-6'
                  color='#4CABA9'
                  style={{ marginRight: '15px' }}
                />
                <div className='form-group'>
                  <label htmlFor='form-description-input'>Category</label>
                  <TreeSelect
                    id='form-category_id-input'
                    style={{ width: '100%' }}
                    value={`${this.state.category_id}`}
                    treeData={this.props.formOptions.category_id || []}
                    placeholder='Please Select Value'
                    treeDefaultExpandAll={false}
                    onChange={value => this.handleChange('category_id', value)}
                    className='form-control'
                    dropdownClassName={'antd-filter-dropdown'}
                    showSearch={true}
                    filterTreeNode={(input, node) => node.title.toLowerCase().includes(input.toLowerCase())}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <button type='button' className='btn btn-primary btn-block' onClick={this.handleSubmit}>
          <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Save
        </button>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(EditRuleForm, {
  mobxState: observable
});

export default observer(EditRuleForm);

// export default ProjectOverview;
