//handles input for alternate price on the freight_bracket_constraints component

import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
import NumericInput from 'react-numeric-input';
// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
const projectId = extractToken(window.location.pathname, '/', 3);

class AlternatePriceInput extends React.Component {

  mobxState = {
    alternatePrice: [], //value from input field in case it's needed in state
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    //this.fetchData();
  }
  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    //Will load data from Threshold column of discount_constraints table
    ////////////////////
    //FORM LOAD
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  render(){

    return(
        <div>
            <b>Optional: Enter different price from bid in bracket</b>
            <NumericInput
            onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
            className="form-control"
            name="discountPercentage"
            min={0}
            step={1}
            placeholder="Enter amount"
            />
        </div>
    )
  }

}

decorate(AlternatePriceInput, {
    mobxState: observable
})

export default observer(AlternatePriceInput);
