import React from 'react';
import SpinLoader from '../loaders/SpinLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Props
// faClass: Font Awesome Icon for badge (e.g. "pencil")
//              https://fontawesome.com/v5/search
// badgeNumber: Number shown for badge (e.g. "1,000,000")
// badgeName: Text shown for badge (e.g. "Items")
// loading: Boolean to indicate loading status
class AsyncBadge extends React.Component {
  render() {
    return (
      <div className='card'>
        <div className='card_container'>
          <div className='card_icon'>
            <div className='icon_container'>
              <div className='icon_outline'>
                <FontAwesomeIcon
                  className=''
                  icon={['fal', this.props.faClass]}
                  style={{ color: 'white', fontSize: '30px' }}
                />
              </div>
            </div>
          </div>
          <div className='card_details'>
            <div className='card_top'>
              {this.props.loading ? <SpinLoader style={{ color: '#42A5F5' }} size={'1x'} /> : this.props.badgeNumber}
            </div>
            <div className='card_bottom'>{this.props.badgeName}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AsyncBadge;
