import React, { Component } from 'react';
import { timeoutLogout } from '../../services/Timing';

//project views
import Projects from './views/projects/Projects';
import NewProject from './views/projects/New';
import ProjectEdit from './views/projects/Edit';
import Templates from './views/projects/Templates';
import AdvancedImport from './views/projects/AdvancedImport';
import ImportJobs from './views/projects/ImportJobs';
 

//item views
import Items from './views/items/Items';
import NewItem from './views/items/NewItem';

//bid views
import Bids from './views/bids/Bids';
import CreateNewBid from './views/bids/CreateNewBid';

import ProjectSuppliers from './views/project_suppliers/ProjectSuppliers';
import Scenarios from './views/scenarios/Scenarios';

import PageFilters from '../setup/views/page_filters/PageFilters';

//images
import CompanyLogoWithText from '../../../assets/images/simfoni_logo_1920x1080_transparent_background.png'
import CompanyLogoWithoutText from '../../../assets/images/simfoni_transparent_icon.png'

//views for different constraints
import ScenarioManager from './views/scenarios/ScenarioManager';
import CompetitionConstraints from './views/competition_constraints/CompetitionConstraints';
import SupplierConstraints from './views/supplier_constraints/SupplierConstraints';
import QualitativeConstraints from './views/qualitative_constraints/QualitativeConstraints';
import DiscountConstraints from './views/discount_constraints/DiscountConstraints.jsx';
import SubsetConstraints from './views/subset_constraints/SubsetConstraints';
import FreightBrackets from './views/freight_brackets/FreightBrackets';
import FreightBids from './views/freight_bids/FreightBids';
import OptimizationResults from './views/optimization_results/OptimizationResults';
import OptimizationRanks from './views/optimization_results/OptimizationRanks';
import ScenarioComparisons from './views/optimization_results/ScenarioComparisons';

import QuantitativeConstraints from './views/quantitative_constraints/QuantitativeConstraints';
import FreightMatchConstraints from './views/freight_match_constraints/FreightMatchConstraints';
import FreightBracketConstraints from './views/freight_bracket_constraints/FreightBracketConstraints';

import CreateNewFreightBid from './views/freight_bids/CreateNewFreightBid';

import MainSidebar from '../../headers/MainSidebar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { components, DropdownIndicatorProps } from 'react-select';

//security
import { checkRole } from '../../security/SecurityUtilities';
import withRole from '../../security/withRole';

import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

//homemade utilities
import { zeroNull, extractToken } from '../../services/Utilities';
import { injectCustomerColorScheme } from '../../services/InjectedStyle';

import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

// Dropdown indicator for the sidebar (needed to provide a different icon to react select)
// See Laura's answer on
// https://stackoverflow.com/questions/54586184/react-select-changing-drop-down-indicator-icon-to-font-awesome-icon-is-not-worki#54598963
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={['fal', 'search']} />
    </components.DropdownIndicator>
  );
};

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class Sourcing extends React.Component {
  mobxState = {
    isFreight: null,
    client_logo: null
  };

  //initial check for project type (Freight or Product)
  componentDidMount() {
    this.findType();
    injectCustomerColorScheme().then(({ styleInjector, clientColorScheme }) => {
      this.mobxState.styleInjector = styleInjector;
      this.mobxState.clientColorScheme = clientColorScheme;
    });
    //grab custom logo from db
    axios
      .get(`/v1/custom_schemas/client_logo/retrieve`)
      .then(async res => {
        let pic = res.data.pic;
        let picture = 'data:image/png;base64,' + pic;
        this.mobxState.client_logo = picture;
      })
      .catch(error => {
        console.log('Error Fetching Logo ', error);
      });
    timeoutLogout(60 * 60);
    //load select drop-down menu options for sidebar
    axios
      .get('/v1/projects/index_filter', { params: {} })
      .then(response => {
        this.mobxState.projectDropDownSelectOptions = response.data.projects;
      })
      .catch(console.log.bind(console)); //error
  }

  // Methods for select dropdown menu to go to corresponding bid
  handleProjectDropDownSelect = e => {
    const { history } = this.props;
    let projectId = e.value;
    this.mobxState.selectedDropdownOption = projectId;
    history.push(`/sourcing/projects/${projectId}/bids`); //moves to bids page and allows user to use the back button
  };

  //run check to determine current project type
  componentDidUpdate(prevProps, prevState) {
    this.findType();
    timeoutLogout(60 * 60);
  }

  findType = async () => {
    await axios.get(`/v1/projects/${extractToken(window.location.pathname, '/', 3)}`).then(response => {
      let projectType = response.data.project_type;
      if (projectType === 'Freight') {
        console.log('FREIGHT PROJECT');
        this.mobxState.isFreight = true;
      } else {
        console.log('NOT FREIGHT');
        this.mobxState.isFreight = false;
      }
    });
  };

  render() {
    //this variable does all the routing and is passed into here from parent
    const match = this.props.match;
    // Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
    const projectId = extractToken(window.location.pathname, '/', 3);

    const canScenarioR = checkRole({ model: 'scenario', permission: 'r' });

    return (
      <div>
        <div id='main-page-header' className='main-page-header'>
          {/* HEADER ---------------------------------------------------------- */}
          <div id='top_line'></div>
          <nav className='navbar header-navbar navbar-fixed-top'>
            <div className='navbar-wrapper'>
              {/* Header and Logo ----------------------------------------------------------------- */}
              <div className='navbar-logo'>
                <button type='button' data-toggle="modal" data-target="#main-sidebar-dock" className='btn btn-default navbar-btn'>
                  <FontAwesomeIcon icon={['fas', 'bars']} color="#D3D4D6" transform="" style={{fontSize: '19px'}} />
                </button>
                <img alt="Simfoni Logo" className="simfoni-logo" src={CompanyLogoWithoutText} />
              </div>

              {/* Links in the center ------------------------------------------------------------- */}
              <div className='navbar-menus'>
                <ul className='nav navbar-nav'>
                  {/* Projects Tab */}
                  <li className="dropdown">
                    <a id="projectsDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      Projects
                      <span className="caret"></span>
                    </a>
                    <ul className='dropdown-menu' id='projectsSubmenu'>
                      <li><Link to={`${match.url}/projects`}>Overview</Link></li>
                      <li><Link to={`${match.url}/projects/new`}>New</Link></li>
                      <li><Link to={`${match.url}/projects/AdvancedImport`}>Advanced Import</Link></li>
                      <li><Link to={`${match.url}/projects/ImportJobs`}>Import Jobs</Link></li>
                      {projectId && /^\d+$/.test(`${projectId}`) && (
                        <li><Link to={`${match.url}/projects/${projectId}/edit`}>Edit</Link></li>
                      )}
                      {projectId && /^\d+$/.test(`${projectId}`) && (
                        <li><Link to={`${match.url}/projects/${projectId}/Templates`}>Templates</Link></li>
                      )}
                    </ul>
                  </li>
                  {/* conditional menu display to show only if projectId exists in the url */}
                  {projectId && /^\d+$/.test(`${projectId}`) && (
                    <React.Fragment>
                      {/* 1 ---------------------------------------------*/}
                      <li className="dropdown">
                        <a id="suppliersSubmenu" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Bidders
                          <span className="caret"></span>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="suppliersSubmenu">
                          <li><Link to={`${match.url}/projects/${projectId}/ProjectSuppliers`}>Overview</Link></li>
                        </ul>
                      </li>
                      {/* 2 ---------------------------------------------*/}
                      <li className="dropdown">
                        <a id="itemsSubmenu" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Items
                          <span className="caret"></span>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="itemsSubmenu">
                          <li><Link to={`${match.url}/projects/${projectId}/items/newItem`}>New</Link></li>
                          <li><Link to={`${match.url}/projects/${projectId}/items`}>Overview</Link></li>
                        </ul>
                      </li>
                      {/* 3 ---------------------------------------------*/}
                      <li className="dropdown">
                        <a id="bidsSubmenu" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Bids
                          <span className="caret"></span>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="bidsSubmenu">
                          <li><Link to={`${match.url}/projects/${projectId}/createNewBid`}>New</Link></li>
                          <li><Link to={`${match.url}/projects/${projectId}/bids`}>Overview</Link></li>
                        </ul>
                      </li>
                      {/* conditional render if project type is freight for freight sidebar links */}
                      {
                        this.mobxState.isFreight &&
                        <React.Fragment>
                          {/* 4 ---------------------------------------------*/}
                          <li className="dropdown">
                            <a id="freightSubmenu" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                              Freight
                              <span className="caret"></span>
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="freightSubmenu">
                              <li><Link to={`${match.url}/projects/${projectId}/FreightBrackets`}>Brackets</Link></li>
                              <li><Link to={`${match.url}/projects/${projectId}/createNewFreightBid`}>New</Link></li>
                              <li><Link to={`${match.url}/projects/${projectId}/FreightBids`}>Overview</Link></li>
                            </ul>
                          </li>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  )}
                  {/* end conditional menu display */}
                  {projectId && /^\d+$/.test(`${projectId}`) && canScenarioR && (
                    <React.Fragment>
                      <li className="dropdown">
                        <a id="freightSubmenu" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                          Optimization
                          <span className="caret"></span>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="freightSubmenu">
                          <li><Link to={`${match.url}/projects/${projectId}/scenarios`}>Overview</Link></li>
                          <li><Link to={`${match.url}/projects/${projectId}/scenarios/ScenarioComparisons`}>Scenario Comparison</Link></li>
                        </ul>
                      </li>
                    </React.Fragment>
                  )}{' '}
                  {/* end conditional menu display */}
                </ul>
              </div>
              {/* Button for right hand side */}
              <div className="pull-right" style={{paddingTop: '5px'}}>
                <img src={this.mobxState.client_logo} style={{maxWidth: '200px'}}/>
              </div>
            </div>
          </nav>
        </div>
        {/* Sidebar ------------------------------------------------------- */}
        <MainSidebar
          searchBar={
            <Select
              className='text-left'
              options={this.mobxState.projectDropDownSelectOptions}
              closeMenuOnSelect={true}
              maxMenuHeight={400}
              placeholder={'Search Projects'}
              label={'Search Projects'}
              isSearchable={true}
              onChange={e => this.handleProjectDropDownSelect(e)}
              components={{ DropdownIndicator }}
            />
          }
        />

        {/* BODY ---------------------------------------------------------- */}
        <div className='wrapper' style={{}}>
          <div id='content'>
            {/*<!-- Trigger the modal with a button -->*/}
            <Switch>
              <Route
                path={`${match.path}/projects/:projectId/Edit`}
                component={withRole(ProjectEdit, { model: 'project', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Templates`}
                component={withRole(Templates, { model: 'project', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Bids`}
                component={withRole(Bids, { model: 'bid', permission: 'r' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/CreateNewBid`}
                component={withRole(CreateNewBid, { model: 'bid', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/FreightBids`}
                component={withRole(FreightBids, { model: 'freight_bid', permission: 'r' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/FreightBrackets`}
                component={withRole(FreightBrackets, { model: 'freight_bid', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/CreateNewFreightBid`}
                component={withRole(CreateNewFreightBid, { model: 'freight_bid', permission: 'w' })}
              />
              {/* <Route path={`${match.path}/Items/New`} component={withRole(NewItem)}/> */}
              <Route
                path={`${match.path}/projects/:projectId/Items/NewItem`}
                component={withRole(NewItem, { model: 'item', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Items`}
                component={withRole(Items, { model: 'item', permission: 'r' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/ProjectSuppliers`}
                component={withRole(ProjectSuppliers, { model: 'project_supplier', permission: 'r' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/OptimizationResults`}
                component={withRole(OptimizationResults, { model: 'optimization_result', permission: 'r' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/OptimizationRanks`}
                component={withRole(OptimizationRanks, { model: 'optimization_result', permission: 'r' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/ScenarioComparisons`}
                component={withRole(ScenarioComparisons, { model: 'optimization_result', permission: 'r' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/ScenarioManager`}
                component={withRole(ScenarioManager, { model: 'scenario', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/SupplierConstraints`}
                component={withRole(SupplierConstraints, { model: 'supplier_constraint', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/CompetitionConstraints`}
                component={withRole(CompetitionConstraints, { model: 'competition_constraint', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/QualitativeConstraints`}
                component={withRole(QualitativeConstraints, { model: 'qualitative_constraint', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/DiscountConstraints`}
                component={withRole(DiscountConstraints, { model: 'discount_constraint', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/QuantitativeConstraints`}
                component={withRole(QuantitativeConstraints, { model: 'quantitative_constraint', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/SubsetConstraints`}
                component={withRole(SubsetConstraints, { model: 'competition_constraint', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/FreightMatchConstraints`}
                component={withRole(FreightMatchConstraints, { model: 'freight_match_constraint', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios/:scenarioId/FreightBracketConstraints`}
                component={withRole(FreightBracketConstraints, {
                  model: 'freight_bracket_constraint',
                  permission: 'w'
                })}
              />
              <Route
                path={`${match.path}/projects/:projectId/Scenarios`}
                component={withRole(Scenarios, { model: 'scenario', permission: 'r' })}
              />
              <Route
                path={`${match.path}/projects/new`}
                component={withRole(NewProject, { model: 'project', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/AdvancedImport`}
                component={withRole(AdvancedImport, { model: 'project', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects/ImportJobs`}
                component={withRole(ImportJobs, { model: 'project', permission: 'w' })}
              />
              <Route
                path={`${match.path}/projects`}
                component={withRole(Projects, { model: 'project', permission: 'r' })}
              />
            </Switch>
            <div className='line'></div>
          </div>
        </div>
        {/*<MainHeader />*/}
      </div>
    ); //end return
  } // end render
} //end class

decorate(Sourcing, { mobxState: observable });
export default observer(Sourcing);
