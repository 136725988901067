import React from 'react';
// import Logo from '../../assets/images/ec_intel_logo.png';
import Logo from '../../assets/images/ecsg_logo.png';
import $ from 'jquery'; //for table dropdown AND sidebar
import 'jquery/src/jquery'; //for react bootstrap
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//security
import {checkRole} from '../security/SecurityUtilities';

class MainHeader extends React.Component {

  mobxState = {
    urlData: '',
    contractsPath: '/conmgt/contracts1.asp',
    workflowPath: '/prsystem/tket1.asp',
    eSourcingPath: '/landing01.asp?login01=1',
    client_logo: null,
  };

  //mount the jquery sidebar logic
  componentDidMount() {
    this.handleSidebarToggle();
    // $("#sidebar").mCustomScrollbar({
    //     theme: "minimal"
    // });
    //call the data loader to load data into the view
    this.fetchData();

    //role checks
    checkRole({'model':'global', 'permission':'w'}).then(res => { this.mobxState.canGlobalW = res });
    checkRole({'model':'project', 'permission':'r'}).then(res => { this.mobxState.canProjectR = res });
    checkRole({'model':'transaction', 'permission':'r'}).then(res => { this.mobxState.canTransactionR = res });
    checkRole({'model':'legacy_project', 'permission':'r'}).then(res => { this.mobxState.canLegacyProjectR = res });
    checkRole({'model':'legacy_ticket', 'permission':'r'}).then(res => { this.mobxState.canLegacyTicketR = res });
    checkRole({'model':'legacy_contract', 'permission':'r'}).then(res => { this.mobxState.canLegacyContractR = res });
    checkRole({'model':'legacy_project_insight', 'permission':'r'}).then(res => { this.mobxState.canLegacyProjectInsightR = res });
  }

  //for sidebar dock to change sizes
  handleSidebarToggle = () => {
    $('#sidebar, #content').toggleClass('active'); //toggle active class
    // $('.collapse.in').toggleClass('in'); //don't close menu options
    // $('a[aria-expanded=true]').attr('aria-expanded', 'false'); //not sure what this does but came with template
  };

  fetchData = () => {
    //load data for cards and filters
    axios.get('/microservices/v1/domain/ec_url').then(response => {
      this.mobxState.urlData = response.data.sourcingUrlConfig;
      console.log('URL ', response.data);
    });

    //grab custom logo from db
    axios.get(`/v1/custom_schemas/client_logo/retrieve`)
    .then(async (res) => {
      let pic = res.data.pic
      let picture = "data:image/png;base64," + pic
      this.mobxState.client_logo = picture
    })
    .catch((error) => {
      console.log("Error Fetching Logo ", error)
    })
  };

  render() {
    return (
      <div>
        <div id='top_line'></div>

        <nav
          className='navbar navbar-default'>
          <div className='container-fluid'>
            <div className='navbar-header'>
              <button
                type='button'
                className='navbar-toggle collapsed btn btn-default navbar-btn'
                data-toggle='collapse'
                data-target='#navbar'
                aria-expanded='false'
                aria-controls='navbar'
              >
                <i className='glyphicon glyphicon-menu-hamburger header_font'></i>
              </button>
              <div className='navbar-header'>
                <button
                  type='button'
                  onClick={this.handleSidebarToggle}
                  className='btn btn-default navbar-btn'
                >
                  <i
                    className='glyphicon glyphicon-menu-hamburger header_font'
                    id='header_hamburger'
                  ></i>
                </button>
                  <img src={this.mobxState.client_logo}/>
              </div>
            </div>

            <div id='navbar' className='navbar-collapse collapse'>
              <ul className='nav navbar-nav'></ul>

              <ul className='nav navbar-nav navbar-center'></ul>

              <ul className='nav navbar-nav navbar-right'>
                <li className='dropdown'>
                  <a
                    href='#'
                    className='dropdown-toggle navbar_icons header_font'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span
                      className='glyphicon glyphicon-th'
                      aria-hidden='true'
                    ></span>
                  </a>
                  <ul className='dropdown-menu'>
                    <li className='dropdown-header'>Sourcing</li>
                    <li role='separator' className='divider'></li>
                    <li>
                      <a href={this.mobxState.urlData + this.mobxState.eSourcingPath}>EC eSourcing</a>
                    </li>
                    <li role='separator' className='divider'></li>
                    <li>
                      <a href='/sourcing/projects'>EC Optimize</a>
                    </li>
                    <li role='separator' className='divider'></li>
                    <li className='dropdown-header'>Reporting</li>
                    <li role='separator' className='divider'></li>
                    <li>
                      <a href='/spend/spend'>EC Spend</a>
                    </li>
                    <li role='separator' className='divider'></li>
                    <li>
                      <a href='/executive/ProjectOverview'>EC Insights</a>
                    </li>
                    <li role='separator' className='divider'></li>
                    <li className='dropdown-header'>Legal</li>
                    <li role='separator' className='divider'></li>
                    <li>
                      <a href={this.mobxState.urlData + this.mobxState.contractsPath}>EC Contracts</a>
                    </li>
                    <li role='separator' className='divider'></li>
                    <li className='dropdown-header'>Process Management</li>
                    <li role='separator' className='divider'></li>
                    <li>
                      <a href={this.mobxState.urlData + this.mobxState.workflowPath}>EC Workflow</a>
                    </li>
                    <li role='separator' className='divider'></li>
                  </ul>
                </li>
                {this.mobxState.canGlobalW &&
                  <li>
                    <a href='/setup/projects/schema'>
                      <span
                        className='glyphicon glyphicon-ok-sign navbar_icons header_font'
                        aria-hidden='true'
                      ></span>
                    </a>
                  </li>
                }
                <li>
                  <a href='/headquarters/modules'>
                    <span
                      className='glyphicon glyphicon-tower navbar_icons header_font'
                      aria-hidden='true'
                    ></span>
                  </a>
                </li>
                <li className='dropdown'>
                  <a
                    href='#'
                    className='dropdown-toggle navbar_icons header_font'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span className='glyphicon glyphicon-user header_font'></span>
                  </a>
                  <ul className='dropdown-menu'>
                    <li className='dropdown-header'>
                      <span
                        className='glyphicon glyphicon-log-out'
                        aria-hidden='true'
                        style={{ fontSize: '14px' }}
                      >
                        {' '}
                        <a href='/logout'>logout</a>
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

decorate(MainHeader, {
  mobxState: observable
});

export default observer(MainHeader);
