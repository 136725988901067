import React from 'react';
import { render } from 'react-dom';
import { useHistory, withRouter } from "react-router-dom";

//other components built
import Badge from '../../../../badges/Badge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token
import SpinLoader from '../../../../loaders/SpinLoader'; //loading icon for project import

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter
//forms
import { AutoComplete, Input, Checkbox, InputNumber } from 'antd';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { zeroNull } from '../../../../services/Utilities';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Bounce from 'react-reveal/Fade';

//notifications
import Notification from '../../../../../components/notifications/StandardNotificationRenderer';

import ScopeSelection from '../../../../forms/sourcing/ScopeSelection'
import ConstraintAwardSelection from '../../../../forms/sourcing/ConstraintAwardSelection';
import ConstraintOperatorSelection from '../../../../forms/sourcing/ConstraintOperatorSelection';
import SupplierIdSelection from '../../../../forms/sourcing/SupplierIdSelection';
import ConstraintBaseSelection from '../../../../forms/sourcing/ConstraintBaseSelection';
import PercentSlider from '../../../../forms/sourcing/PercentSlider';

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class AdvancedImport extends React.Component {
  mobxState = {
    //for helpers
    formValid: false,
    projectLoading: false,
    submitMessage: false,
    createdProject: 0,
    //form objects
    flexProjectFilter: [],
    flexSelectedProject: '',
    flexSelectedField: '',
    flexSelectedQuantitySuppliedField: '',
    flexProjectFields: [],
    flexAllProjectFields: [],
    include_supplier_tags: true,
    supplier_batch_bid_query: true,
    // include_custom_bid_fields: true,
    //sidebar defaults
    dockOptions: {
      isVisible: false,
      size: 0.2
    },
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.notificationRef = React.createRef();
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    //flex projects load
    axios
      .get('/v1/projects/flex_index_filter', { params: {} })
      .then(response => {
        console.log('Avail projects', response.data.projects);
        this.mobxState.flexProjectFilter = response.data.projects;
      })
      .catch(console.log.bind(console)); //error catcher

  };
  //////////////////END HTTP/////////////////

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };

  handleImport = () => {
    this.mobxState.formValid = false;
    this.mobxState.projectLoading = true;

    console.log('Selected project string', this.mobxState.flexSelectedProject);
    let urlString = '/v1/projects/flex_import/' + this.mobxState.flexSelectedProject + '?main_bid_field=' + this.mobxState.flexSelectedField;
    urlString = urlString + '&quantity_supplied_field=' + this.mobxState.flexQuantitySuppliedField;
    urlString = urlString + '&include_supplier_tags=' + this.mobxState.include_supplier_tags + '&supplier_batch_bid_query=' + this.mobxState.supplier_batch_bid_query; //+ '&include_custom_bid_fields=' + this.mobxState.include_custom_bid_fields;
      if (this.mobxState.flexSelectedProjectLabel) {
        urlString = urlString + '&project_name=' + this.mobxState.flexSelectedProjectLabel;
      }
    console.log('urlString', urlString);
    axios
      .get(urlString, { timeout: 1000 * 4200 }) //fire off the importer with the selected project with a 70 min (4200 seconds) timeout if it's a large project
      .then(response => {
        const messageData = response?.data;
        this.notificationRef.current.addNotification({
          type: messageData.success ? 'info' : 'danger',
          title: messageData.title,
          message: messageData.message,
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
        }); 

        setTimeout(() => {
          this.props.history.push('/sourcing/projects/ImportJobs');
        }, 1000 * 3); //redirect to Imports page after 3 seconds

        // this.mobxState.createdProject = response.data.project_id;
        // this.mobxState.submitMessage = true;

        this.mobxState.projectLoading = false; //stop displaying loading icon
      })
      .catch((e) => {
        console.log('error', e);
        console.log.bind(console) //error catcher
        this.notificationRef.current.addNotification({
          type: 'danger',
          title: 'Failed to import project',
          message: 'Project import failed. Please try again.'
        });
        this.mobxState.projectLoading = false;
      })
  }

  handleFlexFieldSelect = e => {
    this.mobxState.submitMessage = false;
    console.log(e.value);
    if (e.value) {
      this.mobxState.flexSelectedField = e.value.toString();
      this.mobxState.formValid = true;
    }
  };

  handleFlexQuantitySuppliedFieldSelect = e => {
    this.mobxState.submitMessage = false;
    console.log(e.value);
    if (e.value) {
      this.mobxState.flexQuantitySuppliedField = e.value.toString();
      this.mobxState.formValid = true;
    }
  };



  handleFlexProjectSelect = e => {
    this.mobxState.submitMessage = false;
    this.mobxState.formValid = false;
    console.log(e.value);
    if (e.value) {
      this.mobxState.flexSelectedProject = e.value.toString().trim();
      console.log('selected project', this.mobxState.flexSelectedProject);
      axios
        .get('/v1/projects/flex_schema/' + e.value.toString(), { params: {} })
        .then(response => {
          console.log('Avail fields', response.data.fields);
          this.mobxState.flexProjectFields = response.data.fields;
        })
        .catch(console.log.bind(console)); //error catcher

      this.mobxState.flexSelectedProject = e.value.toString().trim();
      console.log('selected project', this.mobxState.flexSelectedProject);
      axios
        .get('/v1/projects/flex_schema_quantity_supplied/' + e.value.toString(), { params: {} })
        .then(response => {
          console.log('Avail fields', response.data.fields);
          this.mobxState.flexAllProjectFields = response.data.fields;
        })
        .catch(console.log.bind(console)); //error catcher
    }
    if (e.label) {
      this.mobxState.flexSelectedProjectLabel = e.label.toString().trim();
    }
  };

  //DEBOUNCE the submit, so form doesn't freeze up if too many requests are sent
  handleFormChangeAndState = (fieldName, selection) => {
    console.log('changing', fieldName, selection);
    this.mobxState[fieldName] = selection;
  };

  render() {
    const hcHeight = '300px';

    return (
      <div>

        <div className='jumbotron jumbotron_full_width'>
          <div className='jumbotron_full_width_container'>
            <span className="fa-layers fa-fw fa-2x">
              <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#009C9C" style={{ marginTop: '4px' }} />
              <FontAwesomeIcon icon={['fal', 'cloud-upload']} color="white" transform="shrink-5" style={{ marginTop: '3px' }} />
            </span>
            &nbsp;
            <b className='dash_header'>Advanced eSourcing Project Import</b>
          </div>
        </div>

        {/* FORM */}
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg clearfix">
                <b>Importation Options </b>
                <span className="pull-right">
                  {/* Constraint Saved Message */}
                  <Bounce left when={this.mobxState.projectLoading}>
                    <b className="text-info" style={{ marginRight: '1.5em', marginTop: '4px' }}>
                      Import in Process
                    </b>
                  </Bounce>
                  <Bounce left when={this.mobxState.submitMessage}>
                    <b className="text-success" style={{ marginRight: '1.5em', marginTop: '4px' }}>
                      {this.mobxState.submitMessage &&
                        <a href={'/sourcing/projects/' + this.mobxState.createdProject + '/bids/'}>View Imported Project</a>
                      }
                    </b>
                  </Bounce>
                  <Bounce left when={this.mobxState.formValid}>
                    <span>
                      <FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{ marginRight: '15px' }} />
                    </span>
                  </Bounce>
                  <button type="button" disabled={!this.mobxState.formValid} className="btn btn-success btn-md" onClick={this.handleImport}>
                    <FontAwesomeIcon icon={['fal', 'save']} />&nbsp; Import Project
                  </button>
                </span>
              </div>

              <div className='panel-body'>
                <form>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'top_panel_info'}>Select project to import from the EC eSourcing module</div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-2">
                          <div>
                            <FontAwesomeIcon icon={['fad', 'folders']} mask={['fas', 'circle']} size='4x' transform="shrink-7" color="#4CABA9" style={{ marginRight: '20px', textAlign: 'center' }} />
                          </div>
                        </div>
                        <div className="col-lg-10">
                          <b>eSourcing Project</b>
                          {!this.mobxState.projectLoading ?
                            <Select
                              onChange={(e) => this.handleFlexProjectSelect(e)}
                              options={this.mobxState.flexProjectFilter}
                              closeMenuOnSelect={true}
                              maxMenuHeight={400}
                              placeholder={'Import project from FlexRFP'}
                            /> :
                            <div>
                              <SpinLoader
                                size={'2x'}
                                hoverTitle={'Importing Project'}
                                textStyle={{ marginLeft: '0.3125em' }}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div> {/* end col-lg-6*/}
                    <div className="col-lg-6">
                    </div> {/* end col-lg-6*/}
                  </div>{/* End row */}

                  <br />
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'top_panel_info'}>Select the main bid field from the project. The selection will be used in all project charts and reports.</div>
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-2">
                          <div>
                            <FontAwesomeIcon icon={['fad', 'columns']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{ marginRight: '20px', textAlign: 'center' }} />
                          </div>
                        </div>
                        <div className="col-lg-10">
                          <b>Project Bid Field</b>
                          {!this.mobxState.projectLoading ?
                            <Select
                              onChange={(e) => this.handleFlexFieldSelect(e)}
                              options={this.mobxState.flexProjectFields}
                              closeMenuOnSelect={true}
                              maxMenuHeight={400}
                              placeholder={'Main project field field to use'}
                            /> :
                            <div>
                              <SpinLoader
                                size={'2x'}
                                hoverTitle={'Using Selected Bid Field as Price'}
                                textStyle={{ marginLeft: '0.3125em' }}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div> {/* end col-lg-6*/}
                    <div className="col-lg-6">
                    </div> {/* end col-lg-6*/}
                  </div>{/* End row */}

                  <br />
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'top_panel_info'}>Select the quantity supplied field from the project.</div>
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-2">
                          <div>
                            <FontAwesomeIcon icon={['fad', 'columns']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{ marginRight: '20px', textAlign: 'center' }} />
                          </div>
                        </div>
                        <div className="col-lg-10">
                          <b>Project Quantity Supplied Field</b>
                          {!this.mobxState.projectLoading ?
                            <Select
                              onChange={(e) => this.handleFlexQuantitySuppliedFieldSelect(e)}
                              options={this.mobxState.flexAllProjectFields}
                              closeMenuOnSelect={true}
                              maxMenuHeight={400}
                              placeholder={'Quantity Supplied field to use'}
                            /> :
                            <div>
                              <SpinLoader
                                size={'2x'}
                                hoverTitle={'Using Selected Field as Quantity Supplied'}
                                textStyle={{ marginLeft: '0.3125em' }}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div> {/* end col-lg-6*/}
                    <div className="col-lg-6">
                    </div> {/* end col-lg-6*/}
                  </div>{/* End row */}


                  <br />
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'top_panel_info'}>Optional: Import faster by excluding data from the import. </div>
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-2">
                          <div>
                            <FontAwesomeIcon icon={['far', 'ballot-check']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{ marginRight: '20px', textAlign: 'center' }} />
                          </div>
                        </div>
                        <div className="col-lg-10" style={{ marginTop: '20px' }}>
                          {!this.mobxState.projectLoading &&
                            <Checkbox
                              name='includeSupplierTags'
                              onChange={(e) => this.handleFormChangeAndState('include_supplier_tags', e.target.checked)}
                              checked={this.mobxState.include_supplier_tags}
                            >
                              Include Bidder Tags
                            </Checkbox>
                          }
                        </div>
                      </div>
                    </div> {/* end col-lg-6*/}
                    <div className="col-lg-6">
                    </div> {/* end col-lg-6*/}
                  </div>{/* End row */}

                  <br />
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'top_panel_info'}>Optional - advanced: Batch the import by running a separate query for each bidder. </div>
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-2">
                          <div>
                            <FontAwesomeIcon icon={['far', 'page-break']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{ marginRight: '20px', textAlign: 'center' }} />
                          </div>
                        </div>
                        <div className="col-lg-10" style={{ marginTop: '20px' }}>
                          {!this.mobxState.projectLoading &&
                            <Checkbox
                              name='includeSupplierTags'
                              onChange={(e) => this.handleFormChangeAndState('supplier_batch_bid_query', e.target.checked)}
                              checked={this.mobxState.supplier_batch_bid_query}
                            >
                              Batch queries per bidder
                            </Checkbox>
                          }
                        </div>
                      </div>
                    </div> {/* end col-lg-6*/}
                    <div className="col-lg-6">
                    </div> {/* end col-lg-6*/}
                  </div>{/* End row */}

                </form>{/* END FORM*/}
              </div>{/* END panel body*/}

              <br />
            </div>{/* END panel panel-default*/}
          </div>{/* END col-sm-12*/}
        </div>{/* END row*/}

        <Notification ref={this.notificationRef} />

      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(AdvancedImport, {
  mobxState: observable
});

export default withRouter(observer(AdvancedImport));

// export default ProjectOverview;
