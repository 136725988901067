import React from "react";
import { render } from 'react-dom';

//other components built
import Badge from "../../../../badges/Badge";
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns

//filters
import DockRenderer from "../../../../filters/DockRenderer";
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls
import Select from 'react-select'; //select box for filter
import PlayButton from '../../shared/PlayButton'; //play button that executes scenario

//ag grid
import GridRenderer from "../../../../grids/GridRenderer";

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import {zeroNull, extractToken} from "../../../../services/Utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//selection forms
import FreightBidFieldSelection from '../../../../forms/sourcing/FreightBidFieldSelection';
import FreightFieldSelection from '../../../../forms/sourcing/FreightFieldSelection';

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class FreightMatchConstraints extends React.Component {
  // Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
  projectId = extractToken(window.location.pathname, '/', 3);
  scenarioId = extractToken(window.location.pathname, '/', 5);
  mobxState = {
    formState: {
      bid_field: { label: null, value: null },
      freight_field: { label: null, value: null },
      formValid: false,
      showHelperIcons: false
    },
    customOptions: {
    },
    cardData: {},
    tableData: {
      "freight_match_constraints": []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    submitMessage: false, //controls message that confirms constraint was saved
    customSchemaRowCount: null, //default amount of rows from 'custom_schemas' tablev
    isLoadingTableData: false, //fired off when show enteries is selected
    inDeletion: false, //toggles icon and "Deleting selected rows" caption
  };

  //mount data through http requests
  componentDidMount() {
    //get the grid grow count then call the data loader to load data into the view
    this.getCustomRowCount();
  }

  //////////////////HTTP/////////////////
  getCustomRowCount = () => {
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
      .then(response => {
        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
        this.fetchData()
      })
      .catch(console.log.bind(console)); //error catcher
  }
  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();

    //merge the filter model with the amount of rows selected
    let models_with_rows = { ...this.mobxState.selectedModels, ...{ "table_rows": table_rows } };
    // console.log("ROWS ", table_rows)
    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}), // Prefer existing values
      }
    });
    axios
      .get('/v1/projects/'+this.projectId+'/project_field_schemas', { params: { 'project_id' : this.projectId } })
      .then(response => {

        let bid_name = response.data.project_field_schemas[0].bid_level //get names from custom_fields in bid_level col
        let freight_name = response.data.project_field_schemas[0].freight_bid_level //get names from custom_fields in freight_bid_level


        //custom fields names (value and label)
        let custom_fields_data = [];
        Object.values(bid_name).forEach(element => {
          custom_fields_data.push({value:element.field_name, label:element.field_alias })
        })
        //console.log(custom_fields_data)

        //freight fields names (value and label)
        let freight_fields_data = [];
        Object.values(freight_name).forEach(element => {
          freight_fields_data.push({value:element.field_name, label:element.field_alias })
        })

        //get map of field labels from API
        this.mobxState.customOptions = {
          ...this.mobxState.customOptions,
          ...(response.data || {})
        };

        //after ag grid is set up, get the rest of the data
        //table data load
        this.mobxState.isLoadingTableData = true //loading project rows
        axios.get(`/v1/projects/${this.projectId}/scenarios/${this.scenarioId}/freight_match_constraints`, { params: models_with_rows })
          .then(response => {
            this.mobxState.isLoadingTableData = false
            // console.log('data response:');
            //console.log(response);
            response.data.freight_match_constraints.forEach(function(i){
              //find and replace custom_field_name with field_alias
              for(let x of custom_fields_data){
                if(i.bid_field == x.value){
                  i.bid_field = x.label
                }
              }
              for(let x of freight_fields_data){
                if(i.freight_field == x.value){
                  i.freight_field = x.label
                }
              }
            })

            this.mobxState.tableData = response.data;
            console.log(this.mobxState.tableData)

            //set the column definitions for ag grid, look into buildColumnDefs class to see options
            this.mobxState.columnDefsFreightMatchConstraints = gridColumns.buildColumnDefs({
              "customOptions": this.mobxState.customOptions,
              "columnArray": ['freight_match_constraints'],
              "customFieldSchema": this.mobxState.customOptions.project_field_schemas[0],
                "dataType": 'freight_match_constraint' //special tag to signify that this is freight_match_constraint data
            });
            //autofit the table columns (hits the child function), this runs after the columns were created
            this.autofitChild();
          });
      })
      .catch(console.log.bind(console)); //error catcher

    //custom pivot configurations load
    axios.get('/v1/resources/index_filter', { params: { "page_id": 403 } }).then(response => {
      this.mobxState.pivotFilter = response.data.resources;
      this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
    });
  }; //end fetchData()

  //FORM HANDLING FUNCTIONS
  //handle submission by sending the formState to server
  handleFormSubmit = event => {
    axios.post('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/freight_match_constraints', {
        bid_field: this.mobxState.formState.bid_field.value,
        freight_field: this.mobxState.formState.freight_field.value
      })
      .then(() => { {this.mobxState.submitMessage = true;}setTimeout(() => {this.mobxState.submitMessage = false;}, 7000);}) //shows constraint saved message
      .then(response => {
        this.fetchData();
        this.mobxState.formState.showHelperIcons = false;
      });
  };

  //react-select form state handler
  handleFormChange = (field, value) => {
    console.log('handleFormChange with params:', field, value);
    this.mobxState.formState[field] = value;
    //call validationResult
    this.validateForm();
  };

  validateForm = () => {
    //checks all form fields have values except for the scope and scope value
    let formConditionsMet = this.mobxState.formState.bid_field.value !== null
    && this.mobxState.formState.freight_field.value !== null;
    //scope and scope value exist

    //paths to valid form of meeting scope and form conditions
    let formValidPathOne = formConditionsMet;

    //check that form is valid and make the state aware of that
    if (formValidPathOne) {
      this.mobxState.formState.formValid = true;
      this.mobxState.formState.showHelperIcons = true;
    } else {
      this.mobxState.formState.formValid = false;
      this.mobxState.formState.showHelperIcons = false;
    }
    console.log(this.mobxState.formState);
  };
  //END FORM HANDLING

  deleteRequest = toSendArr => {
    this.mobxState.inDeletion = true
    console.log('IN DELETION');
    axios.post(`/v1/projects/${this.projectId}/scenarios/${this.scenarioId}/freight_match_constraints/batch_destroy`, { "id_set": toSendArr }  )
      .then(response => {
        this.mobxState.inDeletion = false
        console.log(response);
        this.fetchData();
      });
  };
  //////////////////END HTTP/////////////////

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    console.log('Handle Filter Callback: ');
    console.log('selectedFilter ', selectedFilter);
    console.log('filterParam ', filterParam);
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //handles dropdown and chart filters
  handleClear = () => {
    //in the state, clear the model. This goes as params to http request
    this.mobxState.selectedModels = {};
    //also store the explicit cleared selections in the original form. This goes back to the filter as selected values
    //loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
    //from ["supplier", "item"] to {"supplier":[], "item":[]}
    const optionsClear = {};
    for (const key of this.mobxState.filterModelNames) {
      optionsClear[key] = [];
    }
    this.mobxState.selectedOptions = optionsClear;
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };

  render() {
    const hcHeight = '300px';

    return (
      <div>
        {/* Small Left Toggle
        <div onClick={this.toggleDock} className="sidebar_button filter_toggle">
          <i className="glyphicon glyphicon-chevron-left" />
        </div>
        */}

        <div className="jumbotron jumbotron_full_width">
          <div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-5">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon icon={['fas', 'circle']} color="#009C9C" style={{ marginTop: '4px' }} />
                  <span
                    className="fa-layers-text fa-inverse"
                    style={{ fontWeight: '900', fontSize: '14px', marginTop: '4px' }}
                  >
                    Fm
                  </span>
                </span>
                <b className="dash_header">Manage Freight Match Constraints</b>
              </div>
              <div className="col-sm-2" />
              <div className="col-sm-3" />
              <div className="col-sm-2 gps">
                <span style={{marginRight:'17px'}}><PlayButton /></span>
                <FontAwesomeIcon
                  icon={['fad', 'location-arrow']}
                  color="#53C8C5"
                  transform="grow-15 left-10"
                  style={{}}
                />
                <Link
                  to={'/sourcing/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/ScenarioManager'}
                  className='link_style'
                >
                  Next: Constraints Overview
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* BADGES */}

        {/* FORM */}
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg clearfix">
                <b>Create New Freight Match Constraint</b>
                <span className="pull-right">
                  {/* Constraint Saved Message */}
                  <Bounce left when={this.mobxState.submitMessage}>
                    <b className="text-success" style={{ marginRight: '1.5em', marginTop: '4px' }}>
                      Constraint Saved
                    </b>
                  </Bounce>
                  <Bounce left when={this.mobxState.formState.showHelperIcons}>
                    <span>
                      <FontAwesomeIcon
                        icon={['fad', 'long-arrow-alt-right']}
                        size="2x"
                        transform="down-3 grow-10"
                        color="#53C8C5"
                        style={{ marginRight: '15px' }}
                      />
                    </span>
                  </Bounce>
                  <button
                    type="button"
                    disabled={!this.mobxState.formState.formValid}
                    className="btn btn-success btn-md"
                    onClick={this.handleFormSubmit}
                  >
                    <FontAwesomeIcon icon={['fal', 'save']} />&nbsp; Save Constraint
                  </button>
                  &nbsp;
                  {/*
                    <button type="button" className="btn btn-primary btn-md" onClick={this.handleFormSubmit}>
                    <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Bulk Add
                    </button>
                  */}
                </span>
              </div>
              <div className="panel-body">
                <form>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'top_panel_info'}>Select fields where you would like to ensure a match between freight and item bids</div>
                    </div>
                  </div>
                  <br />

                  <div className="row">
                        <div className="col-lg-6">
                            <span className="freightIcon" style={{marginRight:'26px', float: 'left'}} >
                              <FontAwesomeIcon icon={['fad', 'columns']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" />
                            </span>
                            <FreightBidFieldSelection
                            projectId={this.projectId}
                            formField={'bid_field'}
                            handleFormChange={this.handleFormChange}
                            />
                        </div>

                        <div className="col-lg-6">
                            <FreightFieldSelection
                            projectId={this.projectId}
                            formField={'freight_field'}
                            handleFormChange={this.handleFormChange}
                            />
                        </div>
                      {/* <div className="col-lg-1"></div>  */}

                  </div>
                  {/* End row */}
                  <br />
                </form>
              </div>
            </div>
          </div>
          {/* END col-sm-12*/}
        </div>
        {/* END row*/}
        {/* END FORM*/}

        {/* PROJECT GRID */}
        <div className="row">
          <div className="col-lg-12">
            {/* AG Grid */}
            <GridRenderer
              gridHeight={'550px'}
              columnDefs={this.mobxState.columnDefsFreightMatchConstraints}
              rowData={this.mobxState.tableData.freight_match_constraints}
              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={403}
              savedState={this.mobxState.pivotState}
              isPivotMode={true}
              gridTitle={'Constraint Details'}
              setClick={click => (this.clickChild = click)}
              enableSetResizeChild={true}
              setResize={click => (this.autofitChild = click)}
              // enableSetResizeChildColumns={true}
              // setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

              deleteRequest={this.deleteRequest}
              idColumn={"id"}
              modelsToUpdateArr={[{url:`v1/projects/${this.projectId}/scenarios/${this.scenarioId}/freight_match_constraints/`, idName: "id"}]}


              singleClickEdit={true}
              suppressClickEdit={false}
              isSaveable={true}
              isAwardable={false}
              isDeletable={true}
              useCustomSchemaRowCount={true}
              isLoadingTableData={this.mobxState.isLoadingTableData}
              inDeletion={this.mobxState.inDeletion}
            />
          </div>
        </div>
        {/* END CHART ROW */}

        <br />
        {/* Sidebar */}
        <DockRenderer
          position={'right'}
          size={this.mobxState.dockOptions.size}
          dimMode={'none'}
          isVisible={this.mobxState.dockOptions.isVisible}
          onVisibleChange={this.handleVisibleChange}
          onSizeChange={this.handleSizeChange}
          fluid={true}
          toggleDock={this.toggleDock}
          handleClear={this.handleClear}
          value={this.mobxState.selectedOptions}
          options={this.mobxState.cardData}
          closeMenuOnSelect={true}
          maxMenuHeight={400}
          handleFilter={this.handleFilter}
          customOptions={this.mobxState.customOptions}
          filterModelNames={this.mobxState.filterModelNames}
        />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(FreightMatchConstraints, {
	mobxState: observable
})

export default observer(FreightMatchConstraints);
