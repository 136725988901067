import React from 'react';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//filter toggles
import FilterQuickAction from '../filters/FilterQuickAction'; //filter controls

//http requests
import axios from 'axios';
import UrlSniffer from '../services/UrlSniffer'; //work with rails paths to get project token

//forms
import { Form, Text } from 'informed';
import SaveGridModal from '../forms/SaveGridModal';
import TableRows from '../forms/TableRows';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//notifications
import Notification from '../notifications/StandardNotificationRenderer';

//icons
import SpinLoader from '../loaders/SpinLoader';

//csv parser & file saving helpers/shims
import papaparse from 'papaparse';
import { saveAs } from 'file-saver';

//renderers
import ParamsRenderer from './renderers/ParamsRenderer.jsx';
import ScenarioSettingsRenderer from './renderers/ScenarioSettingsRenderer.jsx';
import LaunchScenarioRenderer from './renderers/LaunchScenarioRenderer.jsx';
import CloneProjectRenderer from './renderers/CloneProjectRenderer.jsx';
import ProjectFromTemplateRenderer from './renderers/ProjectFromTemplateRenderer.jsx';
import CloneScenarioRenderer from './renderers/CloneScenarioRenderer';

import RunButtonRenderer from './renderers/RunButtonRenderer.jsx';
import EditButtonRenderer from './renderers/EditButtonRenderer.jsx';
import DeleteButtonRenderer from './renderers/DeleteButtonRenderer.jsx';

import PassthroughRowGroupInnerRenderer from './renderers/PassthroughRowGroupInnerRenderer';
import CheckboxCellRenderer from './renderers/CheckboxCellRenderer.jsx';

//confirm modal
import { confirmAlert } from 'react-confirm-alert';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
//ag grid license
import { LicenseManager } from 'ag-grid-enterprise';
//in new version probably: import {LicenseManager} from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(
  'CompanyName=EC SOURCING GROUP,LicensedApplication=Bidmode,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-008729,ExpiryDate=2_July_2021_[v2]_MTYyNTE4MDQwMDAwMA==fc77508ee141cbb98e125e64615ef029'
);


let tableMessage = null;

// Making Instance of class > it's object now
const urlSniffer = new UrlSniffer();

class GridRenderer extends React.Component {
  static defaultProps = {
    isTableRowSelectable: true
  };

  mobxGrid = {
    icons: {
      sortAscending: '<i class="glyphicon glyphicon-sort-by-attributes"/>',
      sortDescending: '<i class="glyphicon glyphicon-sort-by-attributes-alt"/>'
      // checkboxChecked: '<i class="glyphicon glyphicon-check checkbox_style checked_icon"/> ',
      // checkboxUnchecked: '<i class="glyphicon glyphicon-unchecked checkbox_style"/> '
    },
    panelShow: false,
    isCloning: false, //toggles "Cloning Project" caption and SpinLoader Icon
    currentResourceView: null, //holds data about what current view is being displayed (name, id etc), and
    isSavedView: false, //if page is a saved pivot view this will toggle to show pivot data and related icons
    inDeletion: false // for dispalying SpinLoader icon while deleting selection in grid
  };

  componentDidMount() {
    console.log('Mounting GridRenderer', this.props);
    //this makes the handleRestoreFromParent available to use in the parent
    this.props.setClick(this.handleRestoreFromParent); //a pattern for calling the child function from this components parent
    //if the child setting is enabled by parent.. use the setResize to enable a pattern for calling this child fxn from parent
    if (this.props.enableSetResizeChild == true) {
      this.props.setResize(this.sizeToFit);
    }
    if (this.props.enableSetResizeChildColumns == true) {
      this.props.setResizeToColumnWidth(this.autoSizeAll);
    }
    this.notificationRef = React.createRef(); //another pattern for calling a child function, this time, this component is the parent
  }

  //jumps to row
  jumpToRow(value) {
    var value = document.getElementById('row').value;
    var index = Number(value);
    if (typeof index === 'number' && !isNaN(index)) {
      this.gridApi.ensureIndexVisible(index);
    }
  }

  //this handles table restore from db config when the parent component calls onChange={(e) => this.clickChild(e)}
  //note the setClick is passed/set in componentDidMount
  //receive param from selection
  handleRestoreFromParent = e => {
    console.log(e.value);

    axios
      .get('/v1/resources/' + e.value.toString()) //show the pivot config of the id that is passed from filter
      .then(response => {
        console.log(response.data);
        this.gridColumnApi.setColumnState(response.data.config.agState); //table state
        this.gridColumnApi.setPivotMode(response.data.config.isPivotMode); //pivot mode on/off
        this.gridApi.setFilterModel(response.data.config.filterModel); //pivot filters
        this.mobxGrid.currentResourceView = response.data; //holds data to display
        this.mobxGrid.isSavedView = true; //toggles saved view data and icons
      })
      .catch(console.log.bind(console)); //error catcher

    console.log(this.mobxGrid.currentResourceView);
  };

  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };

  autoSizeAll = () => {
    var allColumnIds = [];
    let columns = this.gridColumnApi.getAllColumns() || [];
    columns.forEach(function(column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  };

  handleExport = () => {
    var params = { fileName: 'ecsg_export' };
    //this.gridApi.exportDataAsExcel(params); //issues on 10k
    this.gridApi.exportDataAsCsv(params);
  };

  handleExportFlat = () => {
    let pivots = this.gridColumnApi.getPivotColumns();
    let groups = this.gridColumnApi.getRowGroupColumns();
    let headerColumnCount = 1 + pivots.length;
    let groupHeaders = groups.map(col => this.gridColumnApi.getDisplayNameForColumn(col));
    let pivotHeaders = pivots.map(col => this.gridColumnApi.getDisplayNameForColumn(col));

    // Get table as csv text
    let isLeaf = row => (groups.length === 0 ? true : row.node.leafGroup);
    let csvText = this.gridApi.getDataAsCsv({
      columnGroups: true,
      suppressAggAtRootLevel: true,
      shouldRowBeSkipped: row => !isLeaf(row),
      processRowGroupCallback: params => {
        let iterations = 0;
        let label = '';
        let node = params.node;
        while (node && node.parent && iterations < 11) {
          iterations++;
          label = '𓀼' + node.key + label;
          node = node.parent;
        }
        return label;
      }
    });
    // Parse the table
    let parsedData = papaparse.parse(csvText);
    if (parsedData.errors.length > 0) {
      console.error(parsedData.errors);
    }
    let headerRows = parsedData.data.slice(0, headerColumnCount);
    let bodyRows = parsedData.data.slice(headerColumnCount);

    // Process the headers
    // If we're row grouped, create a column for each row group on the lowest header
    // If we're pivotted add pivot label on rows above the main header row
    let colsToDrop = groups.length === 0 ? 0 : 1;
    let colsToAdd = Math.max(groups.length, pivots.length === 0 ? 0 : 1);
    for (var i = 0; i < headerRows.length; i++) {
      let isLastRow = i === headerRows.length - 1;
      let keepHeaders = headerRows[i].slice(colsToDrop);
      headerRows[i] = [...Array(colsToAdd).fill(''), ...keepHeaders];
      // Fill in pivot labels if present
      if (!isLastRow) {
        headerRows[i][colsToAdd - 1] = pivotHeaders[i];
      } else {
        // Fill in group headers on last row if present
        headerRows[i] = [...groupHeaders, ...headerRows[i].slice(groupHeaders.length)];
      }
    }

    // Process the body rows
    for (let i = 0; i < bodyRows.length; i++) {
      if (groups.length > 0) {
        // If we're row grouped, just split first col, don't worry if we're pivoted
        let [gc, ...rcs] = bodyRows[i];
        let gccs = gc.split('𓀼').slice(1);
        bodyRows[i] = [...gccs, ...rcs];
      } else if (pivots.length > 0) {
        // Special case of pivot and no row groups, add a filler cell
        bodyRows[i] = ['', ...bodyRows[i]];
      } else {
        // Neither grouped, nor pivoted, pass it through
        bodyRows[i] = bodyRows[i];
      }
    }

    // Export to csv
    let output = papaparse.unparse([...headerRows, ...bodyRows], { quotes: true });
    let blob = new Blob([output], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'ecsg_export.csv');
  };

  handleSelectedExport = () => {
    var params = { fileName: 'ecsg_export', onlySelected: true };
    // this.gridApi.exportDataAsExcel(params); //issues on 10k
    this.gridApi.exportDataAsCsv(params);
  };

  handlePivotMode = () => {
    this.gridColumnApi.setPivotMode(true);
    this.gridApi.showToolPanel(true);
    // this.gridColumnApi.setPivotColumns(["item_group_1"]);
    // this.gridColumnApi.setRowGroupColumns(["supplier"]);
  };

  handleFlatMode = () => {
    this.gridColumnApi.setPivotMode(false);
    this.gridApi.showToolPanel(false);
  };

  handleToolPanel = () => {
    this.mobxGrid.panelShow = !this.mobxGrid.panelShow;
    this.gridApi.showToolPanel(this.mobxGrid.panelShow);
  };

  //resets table to starting from scratch
  resetState = () => {
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.setPivotMode(false);
    this.gridApi.showToolPanel(false);
    console.log('Table state reset');
  };

  //confirm modal for deleting the currently displayed table view
  handleResourceConfirm = () => {
    confirmAlert({
      message: `Delete Currently Displayed Grid View?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            console.log('Deleting Resource');
            await this.handleResourceDelete();
            window.location.reload();
          }
        },
        {
          label: 'No',
          onClick: () => console.log('Cancel Deleting Resource')
        }
      ]
    });
  };

  //hit DELETE resources/:id endpoint
  handleResourceDelete = () => {
    let resourceId = this.mobxGrid.currentResourceView.id;
    console.log(resourceId);
    axios
      .delete(`/v1/resources/${resourceId}`)
      .then(result => {
        console.log('Resource Deleted ', result);
      })
      .catch(error => {
        console.log(error);
      });
  };

  //form submission to save chart config
  handleSubmit = event => {
    //use form api to get the form state

    let formState = this.formApi.getState();
    console.log('EVENT ', event);
    console.log('PROPS ', this.props);
    //AG Grid Config and Pivot State
    let toSendConfig = {
      agState: this.gridColumnApi.getColumnState(),
      isPivotMode: this.gridColumnApi.isPivotMode(),
      filterModel: this.gridApi.getFilterModel()
    };
    //use setFilterModel(model) to reset the filter
    console.log(formState);
    console.log('In saving', toSendConfig);
    console.log('Save to page:', this.props.pageId);
    //create the config in rails and log the response
    console.log('MODAL DATA', this.props, formState, event);
    axios
      .post('/v1/resources', {
        name: formState.values.name,
        description: formState.values.description,
        config: toSendConfig,
        page_id: this.props.pageId,
        project_id: formState.values.projectId || null
      })
      .then(response => {
        console.log(response);
        document.getElementById('hideModalButton').click(); //closes bootstrap modal
        this.notificationRef.current.addNotification({
          type: 'success',
          title: 'Table view saved',
          message: 'Your new view will be available in the saved analysis dropdown after reload'
        });
      })
      .catch(error => {
        document.getElementById('hideModalButton').click(); //closes bootstrap modal
        this.notificationRef.current.addNotification({
          type: 'danger',
          title: 'Failed to save',
          message: error.message
        });
        console.log(error);
      });
  };

  handleDelete = () => {
    //ag rows
    const selectedNodes = this.gridApi.getSelectedNodes();

    //Array to be populated with IDs for batch delete
    let toSendArr = [];
    selectedNodes.forEach(element => {
      toSendArr.push(element.data[this.props.idColumn]);
    });

    console.log('HANDLE DELETE');
    console.log(toSendArr);

    //call deletion function from props
    this.props.deleteRequest(toSendArr);

    alert('Deleting the selected data and all of its references');
  };

  handleAward = () => {
    //ag rows
    const selectedNodes = this.gridApi.getSelectedNodes();

    //Array to be populated with IDs for batch delete
    let toSendArr = [];
    selectedNodes.forEach(element => {
      toSendArr.push(element.data[this.props.idColumn]);
    });

    //call deletion function from props
    this.props.awardRequest(toSendArr);
    // alert('BidMode is awarding the selected data in FlexRFP');
  };

  handleTemplateCreate = async () => {
    const selectedRow = this.gridApi.getSelectedRows();
    let projectId = selectedRow[0].id;

    this.props.handleTemplateCreate(projectId);
  };

  //form api to get value in submit
  setFormApi = formApi => {
    console.log(this.props.identifier);
    this.formApi = formApi;
  };

  //handles update of values by user in ag grid
  handleGridChange = params => {
    //check for existence of models to update in the parent definition
    if (this.props.modelsToUpdateArr != null) {
      console.log('In Change. Params to change:');
      // console.log(`Curenntly dispalying: ${params.api.getDisplayedRowCount()} rows`)
      console.log(params.data);
      //save data row to mutate later
      let data_row = params.data;
      let { custom_field_name, scope, ...amended_row } = data_row; //takes custom_field_name out of PUT request to keep name in DB from changing
      // console.log(amended_row);
      //cycle through models to update
      this.props.modelsToUpdateArr.forEach(model => {
        console.log('Sending data row', amended_row);
        console.log('Update URL');
        console.log(model.url + data_row[model.idName]);
        //cycle through array of paths and use each element object as a path and id name in field in the data row to send in the new data row
        axios
          .put('/' + model.url + data_row[model.idName], amended_row)
          .then(response => {
            console.log(response.data);
            //not logging saving, gets annoying for user, they can see the saved results anyway
            this.notificationRef.current.addNotification({
              type: 'success',
              title: 'Data row saved',
              message: 'ID#: ' + data_row[model.idName] + ' is saved'
            });
          })
          .catch(error => {
            this.notificationRef.current.addNotification({
              type: 'danger',
              title: 'Failed to save',
              message: 'ID#: ' + data_row[model.idName] + ' ' + error.message
            });
            console.log(error);
          });
      });
    }
    this.gridApi.stopEditing();
    console.log('Edits processed, stopping edit');
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    console.log('Grid ready', params.api.getDisplayedRowCount());
  };

  //used to toggle "Project Cloning" and spin loader icon
  onProjectClone = toggle => {
    toggle === true ? (this.mobxGrid.isCloning = true) : (this.mobxGrid.isCloning = false);
  };

  render() {
    const FormContent = ({ formState }) => (
      <SaveGridModal formState={formState} projectIdNumber={this.props.projectIdNumber} isChecked={true} />
    );

    const gridHeight = this.props.gridHeight || '700px';

    return (
      <div>
        <div className='panel panel-default'>
          <div className='panel-heading panel_bg'>
            <b>{this.props.gridTitle} </b>
          </div>
          <div className='panel-body'>
            {/* Conditionally Render "Show N Thousand Entry Selection */}
            {this.props.isTableRowSelectable ? (
              <React.Fragment>
                {' '}
                Show{' '}
                <TableRows
                  fetchData={this.props.fetchData}
                  useCustomSchemaRowCount={this.props.useCustomSchemaRowCount}
                />{' '}
                entries
                {/* Conditionally Render SpinLoader Icon while new rows are loading */}
                {this.props.isLoadingTableData && (
                  <div style={{ display: 'inline-block', marginLeft: '5px', marginTop: '2px' }}>
                    <SpinLoader
                      style={{ color: '#42A5F5' }}
                      size={'2x'}
                      title={'Loading Rows'}
                      hoverTitle={'Loading Details'}
                      textStyle={{ marginLeft: '0.3125em' }}
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* Conditional static row count element (This is kinda hacky, but works around need to insert custom element to fill whitespace) */}
                {this.props.customTableRowCountIndicator
                  ? this.props.customTableRowCountIndicator
                  : <React.Fragment></React.Fragment>}
                {/* Conditionally Render SpinLoader Icon while new rows are loading */}
                {this.props.isLoadingTableData && (
                  <div style={{ display: 'inline-block', marginLeft: '5px', marginTop: '2px' }}>
                    <SpinLoader
                      style={{ color: '#42A5F5' }}
                      size={'2x'}
                      title={'Loading Rows'}
                      hoverTitle={'Loading Details'}
                      textStyle={{ marginLeft: '0.3125em' }}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            {/*Conditionally Render "Project Cloning" caption and Spinloader icon */}
            {this.mobxGrid.isCloning && (
              <div className={'cloning-loader'}>
                <SpinLoader style={{ color: '#42A5F5' }} size={'2x'} title={'Cloning Project'} />
                <b>Cloning Project</b>
              </div>
            )}
            {/*Conditionally Render "Deleting Selection" caption and Spinloader icon */}
            {this.props.inDeletion && (
              <div className={'cloning-loader'}>
                <SpinLoader
                  style={{ color: '#42A5F5' }}
                  size={'2x'}
                  title={'Deleting selected rows'}
                  hoverTitle={'Deleting selected rows'}
                  textStyle={{ marginLeft: '0.3125em', marginTop: '0.3125em' }}
                />
              </div>
            )}
            {/* Conditionally Render Name of Current Saved View being displayed */}
            {this.mobxGrid.isSavedView && (
              <div className='grid-saved-view-title'>
                <b>
                  {this.mobxGrid.currentResourceView.name === '' || null
                    ? 'Error Displaying Name'
                    : `${this.mobxGrid.currentResourceView.name}`}
                </b>
              </div>
            )}
            &nbsp;
            <span className='text-success success_text'>{tableMessage}</span>
            <span className='pull-right'>
              {/* Toggle Remove Icon for Confirm Deletion for currently displayed resource (Saved Project Grid View) */}
              {this.mobxGrid.isSavedView && (
                <span className='delete-resource-icon'>
                  <span
                    title='Delete Saved View'
                    className='glyphicon glyphicon-remove'
                    onClick={this.handleResourceConfirm}
                  ></span>
                  &nbsp;
                </span>
              )}
              {/* Expand dropdown */}
              <span className='dropdown'>
                <a href='#' className='dropdown-toggle filter_toggle' data-toggle='dropdown'>
                  <FontAwesomeIcon icon={['fal', 'bars']} color='#C6C9CF' transform='' style={{}} />
                </a>
                <ul className='dropdown-menu dropdown-menu-right' role='menu'>
                  <li className='dropdown-header'>Arrange Data</li>
                  <li className='dropdown-header grid_dropdown' onClick={this.handlePivotMode}>
                    <span>Pivot Table</span>
                  </li>
                  <li className='dropdown-header grid_dropdown' onClick={this.handleFlatMode}>
                    <span>Flat Table</span>
                  </li>
                  <li role='separator' className='divider'></li>
                  <li className='dropdown-header'>Columns</li>
                  <li className='dropdown-header grid_dropdown' onClick={this.handleToolPanel}>
                    <span>Select Columns</span>
                  </li>
                  <li className='dropdown-header grid_dropdown' onClick={this.sizeToFit}>
                    <span>Fit All Columns in Window</span>
                  </li>
                  <li className='dropdown-header grid_dropdown' onClick={this.autoSizeAll}>
                    <span>Fit Columns to Content</span>
                  </li>
                  <li role='separator' className='divider'></li>
                  <li className='dropdown-header'>Exports</li>
                  <li className='dropdown-header grid_dropdown' onClick={this.handleExport}>
                    <span>Export Data</span>
                  </li>
                  <li className='dropdown-header grid_dropdown' onClick={this.handleExportFlat}>
                    <span>Export Flat Data</span>
                  </li>
                  {this.props.isSaveable && (
                    <span>
                      <li role='separator' className='divider'></li>
                      <li className='dropdown-header'>Saving</li>
                      <li className='dropdown-header grid_dropdown' data-toggle='modal' data-target='#formModal'>
                        <span>Save Table</span>
                      </li>
                    </span>
                  )}
                  <li className='dropdown-header grid_dropdown' onClick={this.resetState}>
                    <span>Reset Defaults</span>
                  </li>
                  {this.props.isDeletable && (
                    <span>
                      <li role='separator' className='divider'></li>
                      <li className='dropdown-header'>Deletion</li>
                      <li className='dropdown-header grid_dropdown' onClick={this.handleDelete}>
                        <span>Delete Selected</span>
                      </li>
                    </span>
                  )}
                  {this.props.canCreateTemplate && (
                    <span>
                      <li role='separator' className='divider'></li>
                      <li className='dropdown-header'>Templates</li>
                      <li className='dropdown-header grid_dropdown' onClick={this.handleTemplateCreate}>
                        <span>Make Template</span>
                      </li>
                    </span>
                  )}
                  {this.props.isAwardable && (
                    <span>
                      <li role='separator' className='divider'></li>
                      <li className='dropdown-header'>Award Management</li>
                      <li className='dropdown-header grid_dropdown' onClick={this.handleAward}>
                        <span>Award Selected</span>
                      </li>
                    </span>
                  )}
                </ul>
              </span>
              {/* Toggle saving modal */}
              {this.props.isSaveable && (
                <span>
                  &nbsp; &nbsp;
                  <span data-toggle='modal' data-target='#formModal' className='filter_toggle'>
                    <FontAwesomeIcon icon={['fal', 'save']} color='#C6C9CF' transform='' style={{}} />
                  </span>
                </span>
              )}
              &nbsp; &nbsp;
              {/* Toggle filters in dock */}
              <FilterQuickAction handleClear={this.props.handleClear} toggleDock={this.props.toggleDock} />
            </span>
            {/* Modal */}
            {!this.props.noSave && (
              <div className='modal fade' id='formModal' role='dialog'>
                <div className='modal-dialog'>
                  {/* Modal Content */}
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <button type='button' id='hideModalButton' className='close' data-dismiss='modal'>
                        &times;
                      </button>
                      <h4 className='modal-title'>Saving {this.props.gridTitle} Table</h4>
                    </div>
                    <div className='modal-body'>
                      {/* Actual form is displayed here */}
                      {/* Calls form with constant in above in render with form values*/}
                      <Form
                        component={FormContent}
                        id='form-state-form'
                        getApi={this.setFormApi}
                        onSubmit={this.handleSubmit}
                      />
                    </div>
                  </div>{' '}
                  {/* End Modal */}
                  {/* AG Grid */}
                  <Notification ref={this.notificationRef} />
                  <div className='ag-theme-material' style={{ height: gridHeight }}>
                    <AgGridReact
                      enableColResize
                      onGridReady={this.onGridReady.bind(this)}
                      enableSorting={true}
                      enableFilter={true}
                      rowDragManaged={true}
                      onRowDataChanged={this.onRowDataChanged}
                      animateRows={true}
                      icons={this.mobxGrid.icons}
                      groupDefaultExpanded={
                        typeof this.props.groupDefaultExpanded !== 'undefined' ? this.props.groupDefaultExpanded : -1
                      }
                      pivotMode={this.props.pivotMode}

                      overlayLoadingTemplate={
                        this.props.loadingMessage
                        ? `<span class="ag-overlay-loading-center">${this.props.loadingMessage}</span>`
                        : '<span class="ag-overlay-loading-center">Loading...</span>'
                      }

                      enableColResize={true}
                      columnDefs={this.props.columnDefs}
                      autoGroupColumnDef={this.props.autoGroupColumnDef}
                      rowData={this.props.rowData}
                      getDisplayedRowCount={this.props.getDisplayedRowCount}
                      rowSelection='multiple'
                      singleClickEdit={false}
                      suppressClickEdit={this.props.suppressClickEdit}
                      rowMultiSelectWithClick={true}
                      enableRangeSelection={true}
                      enterMovesDown={true}
                      enterMovesDownAfterEdit={true}
                      onCellValueChanged={this.handleGridChange}
                      fetchData={this.props.fetchData}
                      onProjectClone={this.onProjectClone}
                      frameworkComponents={{
                        paramsRenderer: ParamsRenderer,
                        scenarioSettingsRenderer: ScenarioSettingsRenderer,
                        launchScenarioRenderer: LaunchScenarioRenderer,
                        cloneProjectRenderer: CloneProjectRenderer,
                        projectFromTemplateRenderer: ProjectFromTemplateRenderer,
                        runButtonRenderer: RunButtonRenderer,
                        editButtonRenderer: EditButtonRenderer,
                        deleteButtonRenderer: DeleteButtonRenderer,
                        cloneScenarioRenderer: CloneScenarioRenderer,
                        passthroughRowGroupInnerRenderer: PassthroughRowGroupInnerRenderer,
                        checkboxRenderer: CheckboxCellRenderer
                      }}
                      sideBar={{

                        toolPanels: [
                          {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                            toolPanelParams: {
                              suppressPivots: this.props.suppressPivots || false,
                              suppressPivotMode: this.props.suppressPivotMode || false
                            }
                          }
                          // {
                          //     id: 'filters',
                          //     labelDefault: 'Filters',
                          //     labelKey: 'filters',
                          //     iconKey: 'filter',
                          //     toolPanel: 'agFiltersToolPanel',
                          // }
                        ],
                        position: 'right',
                        hiddenByDefault: false,
                        defaultToolPanel: ''
                      }}
                      defaultExportParams={{ sheetName: 'ECSG Export' }}
                      useCustomSchemaRowCount={this.props.useCustomSchemaRowCount}
                      customSchemaRowCount={this.props.customSchemaRowCount}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            )}{' '}
            {/* NOTIFICATION */}
            <Notification ref={this.notificationRef} />
            {/* AG Grid */}
            <div className='ag-theme-material' style={{ height: gridHeight, paddingTop: '12px'}}>
              <AgGridReact
                enableColResize
                onGridReady={this.onGridReady.bind(this)}
                enableSorting={true}
                enableFilter={true}
                rowDragManaged={true}
                onRowDataChanged={this.onRowDataChanged}
                animateRows={true}
                icons={this.mobxGrid.icons}
                groupDefaultExpanded={
                  typeof this.props.groupDefaultExpanded !== 'undefined' ? this.props.groupDefaultExpanded : -1
                }
                pivotMode={this.props.pivotMode}
                overlayLoadingTemplate={
                  this.props.loadingMessage
                    ? `<span class="ag-overlay-loading-center">${this.props.loadingMessage}</span>`
                    : '<span class="ag-overlay-loading-center">Loading...</span>'
                }
                enableColResize={true}
                columnDefs={this.props.columnDefs}
                autoGroupColumnDef={this.props.autoGroupColumnDef}
                rowData={this.props.rowData}
                getDisplayedRowCount={this.props.getDisplayedRowCount}
                rowSelection='multiple'
                singleClickEdit={false}
                suppressClickEdit={this.props.suppressClickEdit}
                rowMultiSelectWithClick={true}
                enableRangeSelection={true}
                enterMovesDown={true}
                enterMovesDownAfterEdit={true}
                onCellValueChanged={this.handleGridChange}
                fetchData={this.props.fetchData}
                onProjectClone={this.onProjectClone}
                frameworkComponents={{
                  paramsRenderer: ParamsRenderer,
                  scenarioSettingsRenderer: ScenarioSettingsRenderer,
                  launchScenarioRenderer: LaunchScenarioRenderer,
                  cloneProjectRenderer: CloneProjectRenderer,
                  projectFromTemplateRenderer: ProjectFromTemplateRenderer,
                  runButtonRenderer: RunButtonRenderer,
                  editButtonRenderer: EditButtonRenderer,
                  deleteButtonRenderer: DeleteButtonRenderer,
                  cloneScenarioRenderer: CloneScenarioRenderer,
                  passthroughRowGroupInnerRenderer: PassthroughRowGroupInnerRenderer,
                  checkboxRenderer: CheckboxCellRenderer
                }}
                sideBar={{
                  toolPanels: [
                    {
                      id: 'columns',
                      labelDefault: 'Columns',
                      labelKey: 'columns',
                      iconKey: 'columns',
                      toolPanel: 'agColumnsToolPanel',
                      toolPanelParams: {
                        suppressPivots: this.props.suppressPivots || false,
                        suppressPivotMode: this.props.suppressPivotMode || false
                      }
                    }
                    // {
                    //     id: 'filters',
                    //     labelDefault: 'Filters',
                    //     labelKey: 'filters',
                    //     iconKey: 'filter',
                    //     toolPanel: 'agFiltersToolPanel',
                    // }
                  ],
                  position: 'right',
                  hiddenByDefault: false,
                  defaultToolPanel: ''
                }}
                defaultExportParams={{ sheetName: 'ECSG Export' }}
                useCustomSchemaRowCount={this.props.useCustomSchemaRowCount}
                customSchemaRowCount={this.props.customSchemaRowCount}
                isSaveable={!this.props.noSave}
              ></AgGridReact>
            </div>
            <br />
          </div>{' '}
        </div>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(GridRenderer, {
  mobxGrid: observable
});

export default observer(GridRenderer);
