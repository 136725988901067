import React from 'react';
import { render } from 'react-dom';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { zeroNull, extractToken, deleteIfEmpty, newJsonFieldSchema } from '../../../services/Utilities';
const _ = require('lodash'); //extra utilities

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class QuickLaunchButtons extends React.Component {
  // Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
  projectId = extractToken(window.location.pathname, '/', 3);
  modelName = 'bid';
  mobxState = {
    formState: {  scenarioName: ''
                  , scenarioID  : 0
                  , constraintType: 'competition_constraint'
                  , scope: 'overall'
                  , scopeValue: null
                  , awardOperator: '<='
                  , base: 'quantity'
                  , award: null
                  , maxSuppliers: 0
                  , minSuppliers: 1
                  , formValid: false  },
    addField: {   fieldLevel        : 'bid_level'
                , fieldKey          : 'custom_field_'
                , fieldName         : 'custom_field_'
                , fieldAlias        : 'Incumbent'
                , isIncumbentLable  : 'Yes'
                , notIncumbentLable : 'No'
                , customFieldNumber : 0
                , threshold         : 80
                , scenarioName      : 'Keep Incumbents autogenerated'
                , fieldExists       : false  },
    projectFieldSchema: {
                  item_level            : {}
                , bid_level             : {}
                , freight_bid_level     : {}
                , product_decimal_spots : 2
                , freight_decimal_spots : 2 },
    dynamicSettings: { // #J49#
                  max_active_buttons    : 3
                , settings_records      : []  // [{id: ,user_id: ,feature_id: ...},{}...]
                , button1               : {id:0, user_id:1, feature_id:0, feature_desc:'', parent_feature:'', text_value:'', numeric_value:0, is_active:false, display_order:0}
                , button2               : {id:0, user_id:1, feature_id:0, feature_desc:'', parent_feature:'', text_value:'', numeric_value:0, is_active:false, display_order:0}
                , button3               : {id:0, user_id:1, feature_id:0, feature_desc:'', parent_feature:'', text_value:'', numeric_value:0, is_active:false, display_order:0}
                },
  };

  componentDidMount() {
    axios // VD 072022 added lines 62-66 and 83-84 to display default buttons
      .put('/v1/user_settings/set_user_defaults_if_missing', { cap: 3 }) // VD 072022
      .then(response => {
        console.log('set_user_defaults_if_missing response :', response); // VD 072022
        axios
          .get('/v1/user_settings')
          .then(response => {
            this.mobxState.dynamicSettings.settings_records = response.data.user_settings;
            // console.log('QuickLaunchButtons: /v1/user_settings response.data.user_settings : ', response.data.user_settings);
            // console.log('QuickLaunchButtons: /v1/user_settings mobxState settings_records : ', JSON.stringify(this.mobxState.dynamicSettings.settings_records));
            let button1 = {};
            let button2 = {};
            let button3 = {};
            response.data.user_settings.forEach(record => {
              if ((record.display_order === 1) & ((button1 === {}) || (record.is_active === true))) {
                button1 = record;
              } // ; this.mobxState.dynamicSettings.button1 = record ; console.log('this.mobxState.dynamicSettings.button1',JSON.stringify(this.mobxState.dynamicSettings.button1))} ;
              if ((record.display_order === 2) & ((button2 === {}) || (record.is_active === true))) {
                button2 = record;
              }
              if ((record.display_order === 3) & ((button3 === {}) || (record.is_active === true))) {
                button3 = record;
              }
            });
            this.mobxState.dynamicSettings.button1 = button1;
            console.log('this.mobxState.dynamicSettings.button1', JSON.stringify(this.mobxState.dynamicSettings.button1));
            this.mobxState.dynamicSettings.button2 = button2;
            console.log('this.mobxState.dynamicSettings.button2', JSON.stringify(this.mobxState.dynamicSettings.button2));
            this.mobxState.dynamicSettings.button3 = button3;
            console.log('this.mobxState.dynamicSettings.button3', JSON.stringify(this.mobxState.dynamicSettings.button3));
          })
          .catch(console.log.bind(console)); //error catcher;
      })
      .catch(console.log.bind(console)); //error catcher // VD 072022
  } // end componentDidMount

  //////////////////HTTP//////////////////
  handleClickLeastCostScenario = (event) => {
    event.preventDefault();
      //
      // create scenario
      this.mobxState.formState.scenarioName = 'Least Cost autogenerated' ;
      this.mobxState.formState.maxSuppliers = 0 ; // default, not used in this function
      axios.post('/v1/projects/'+this.projectId+'/scenarios', {"name":this.mobxState.formState.scenarioName})
      .then((response) => {
        console.log('QuickLaunchButtons: handleClickLeastCostScenario response : ',response);
        //
        // does the new scenario have an ID ?
        this.mobxState.formState.scenarioID = 0 ;
        if (response.data.id > 0) {this.mobxState.formState.scenarioID = response.data.id} ;
        //
        //send the scenario to be evaluated
        if (this.mobxState.formState.scenarioID > 0) {
          axios.post('/v1/projects/'+this.projectId+'/scenarios/'+this.mobxState.formState.scenarioID+'/launch')
          .then(response => {
            console.log('QuickLaunchButtons: handleClickLeastCostScenario: Solver response');
            console.log(response);
            window.location.pathname = '/sourcing/projects/'+this.projectId+'/scenarios'
          })
        } ; // end if ...scenarioID > 0
      })
  };

  handleClickConstrainNumberOfSuppliers = (event) => {
      //
      // create scenario
      axios.post('/v1/projects/'+this.projectId+'/scenarios', {"name":this.mobxState.formState.scenarioName})
      .then((response) => {
        console.log('QuickLaunchButtons: handleClickConstrainNumberOfSuppliers response : ',response);
        //
        // does the new scenario have an ID ?
        this.mobxState.formState.scenarioID = 0 ;
        if (response.data.id > 0) {this.mobxState.formState.scenarioID = response.data.id} ;
        //
        //send the scenario to be evaluated
        if (this.mobxState.formState.scenarioID > 0) {
          axios.post('/v1/projects/' + this.projectId + '/scenarios/' + this.mobxState.formState.scenarioID + '/competition_constraints', {
              scope: this.mobxState.formState.scope,
              scope_value: this.mobxState.formState.scopeValue,
              award_operator: this.mobxState.formState.awardOperator,
              suppliers: this.mobxState.formState.maxSuppliers
            })
          .then((response)=>{
            //
            //send the scenario to be evaluated
            axios.post('/v1/projects/'+this.projectId+'/scenarios/'+this.mobxState.formState.scenarioID+'/launch')
            .then(response => {
              console.log('QuickLaunchButtons: handleClickConstrainNumberOfSuppliers : Solver response');
              console.log(response);
              window.location.pathname = '/sourcing/projects/'+this.projectId+'/scenarios'
            })
          }) ;
        } ; // end if ...scenarioID > 0
      })
  };

  handleClickUpToNSuPpliersButton1 = (event) => {
    event.preventDefault() ;
    this.mobxState.formState.maxSuppliers = this.mobxState.dynamicSettings.button1.numeric_value ;
    this.mobxState.formState.scenarioName = 'Up to '+JSON.stringify(this.mobxState.formState.maxSuppliers)+' suppliers autogenerated' ;
    this.handleClickConstrainNumberOfSuppliers() ; // run the whole enchillada
  };

  handleClickUpToNSuPpliersButton2 = (event) => {
    event.preventDefault() ;
    this.mobxState.formState.maxSuppliers = this.mobxState.dynamicSettings.button2.numeric_value ;
    this.mobxState.formState.scenarioName = 'Up to '+JSON.stringify(this.mobxState.formState.maxSuppliers)+' suppliers autogenerated' ;
    this.handleClickConstrainNumberOfSuppliers() ; // run the whole enchillada
  };

  handleClickUpToNSuPpliersButton3 = (event) => {
    event.preventDefault() ;
    this.mobxState.formState.maxSuppliers = this.mobxState.dynamicSettings.button3.numeric_value ;
    this.mobxState.formState.scenarioName = 'Up to '+JSON.stringify(this.mobxState.formState.maxSuppliers)+' suppliers autogenerated' ;
    this.handleClickConstrainNumberOfSuppliers() ; // run the whole enchillada
  };

  handleClickUpToNSuPpliers = (event) => {
    event.preventDefault() ;
    // this.mobxState.formState.maxSuppliers = 2 ; // set max # of suppliers
    this.mobxState.formState.scenarioName = 'Up to '+JSON.stringify(this.mobxState.formState.maxSuppliers)+' suppliers autogenerated' ;
    this.handleClickConstrainNumberOfSuppliers() ; // run the whole enchillada
  };

  handleClickUpToOneSupplier = (event) => {
    event.preventDefault() ;
    this.mobxState.formState.maxSuppliers = 1 ; // set max # of suppliers
    this.mobxState.formState.scenarioName = 'Up to one supplier autogenerated' ;
    this.handleClickConstrainNumberOfSuppliers() ; // run the whole enchillada
  };

  buildRunIncumbentsScenario = (event) => {
      //
      // create scenario
      axios.post('/v1/projects/'+this.projectId+'/scenarios', {"name":'Keep '+this.mobxState.addField.threshold+'% Incumbents autogenerated'})
      .then((response) => {
        console.log('QuickLaunchButtons: buildRunIncumbentsScenario post scenario response : ',response);
        //
        // does the new scenario have an ID ?
        this.mobxState.formState.scenarioID = 0 ;
        if (response.data.id > 0) {this.mobxState.formState.scenarioID = response.data.id} ;
        //
        //send the scenario to be evaluated
        if (this.mobxState.formState.scenarioID > 0) {
          axios.post('/v1/projects/' + this.projectId + '/scenarios/' + this.mobxState.formState.scenarioID + '/qualitative_constraints', {
              scope: 'overall'
              , scope_value: null
              , award_operator: '>='
              , award: this.mobxState.addField.threshold
              , base: 'cost'
              , custom_field_name : this.mobxState.addField.fieldName
              , custom_field_value: this.mobxState.addField.isIncumbentLable
            })
          .then((response) => {
            console.log('QuickLaunchButtons: buildRunIncumbentsScenario post qualitative_constraints response : ', response);
            //
            //send the scenario to be evaluated
            axios.post('/v1/projects/'+this.projectId+'/scenarios/'+this.mobxState.formState.scenarioID+'/launch')
            .then(response => {
              console.log('QuickLaunchButtons: buildRunIncumbentsScenario : run scenario ',this.mobxState.formState.scenarioID);
              console.log(response);
              window.location.pathname = '/sourcing/projects/'+this.projectId+'/scenarios'
            }) // end then ... run scenario
          }) ; // end then ... qualitative_constraints
        } ; // end if ...scenarioID > 0
      }) // end then ... post scenario response
  }; // end buildRunIncumbentsScenario

  handleAddNamedField = () => { // add bid_level custom field 'incumbentYN', build, run a Keep Incumbent scenario
    let fieldLevel = this.mobxState.addField.fieldLevel // only bid_level has been tested here
    let fieldName = this.mobxState.addField.fieldName
    //
    //get all schema fields in array
    let schemaFields = this.mobxState.projectFieldSchema[fieldLevel]
      ? Object.keys(this.mobxState.projectFieldSchema[fieldLevel])
      : []; //make sure to null handle w empty array if schema doesn't exist
    let schemaFieldNumbers = [];
    let lastSchemaFieldNumber = 0 ;
    //
    //loop thru custom fields
    schemaFields.forEach(element => {
      //push in the number only of the custom field
      schemaFieldNumbers.push(Math.trunc(extractToken(element, '_', 2)));
    });
    //
    //set up custom options for components, then get data for components
    axios
      .get('/v1/projects/' + this.projectId + '/project_field_schemas', { params: { project_id: this.projectId } })
      .then(response => { console.log('QuickLaunchButtons: handleAddNamedField: read/change/save projectFieldSchema to/from mobxState') ;
        //
        //add to customOptions
        this.mobxState.customOptions = { ...this.mobxState.customOptions, ...response.data };
        //
        //store the projectFieldSchema of the project
        this.mobxState.projectFieldSchema = response.data.project_field_schemas[0];
        let bid_level_field_schema = response.data.project_field_schemas[0].bid_level ;
        console.log('QuickLaunchButtons: handleAddNamedField: this.mobxState.projectFieldSchema', this.mobxState.projectFieldSchema) ;
        //
        //get bid level schema fields in array
        schemaFields = bid_level_field_schema
          ? Object.keys(bid_level_field_schema)
          : []; //make sure to null handle w empty array if schema doesn't exist
        //
        //loop thru custom fields to extract field numbers
        //and check whether the needed field alias is already there
        schemaFieldNumbers = [0]; // in case there are no bid_level fields, include 0
        let alias_found = false ;
        schemaFields.forEach(element => {
          schemaFieldNumbers.push(Math.trunc(extractToken(element, '_', 2))); // extract field number, insert in schemaFieldNumbers
          //
          if (bid_level_field_schema[element].field_alias == this.mobxState.addField.fieldAlias) {
            alias_found = true ;
            this.mobxState.addField.fieldName = bid_level_field_schema[element].field_name ;
            console.log('QuickLaunchButtons: handleAddNamedField: this.mobxState.addField.fieldName', this.mobxState.addField.fieldName ) ;
          } ; // end if (bid_level_field_schema...)
        }); // end schemaFields.forEach(element => {
        console.log('QuickLaunchButtons: handleAddNamedField: alias_found', alias_found ) ;
        this.mobxState.addField.fieldExists = alias_found ;
        //
        // keep going if incumbent alias not found
        if (! this.mobxState.addField.fieldExists) {
          //
          //new field name, based on 'last' field number
          lastSchemaFieldNumber = _.last(_.sortBy(schemaFieldNumbers)); // the highest number
          let newFieldNumber = lastSchemaFieldNumber + 1 ;
          this.mobxState.addField.customFieldNumber = newFieldNumber ;
          let newCustomFieldName = 'custom_field_'.concat(newFieldNumber);
          this.mobxState.addField.fieldName = newCustomFieldName ;
          console.log('QuickLaunchButtons: handleAddNamedField: newCustomFieldName = ',newCustomFieldName) ;
          //
          //add new field schema to the bid level field schema
          let newFieldSchema = newJsonFieldSchema(newCustomFieldName, newFieldNumber);
          newFieldSchema[newCustomFieldName].field_alias = this.mobxState.addField.fieldAlias ;
          bid_level_field_schema[newCustomFieldName] = newFieldSchema[newCustomFieldName] ;
          //
          //update mobxState with new bid level field
          this.mobxState.projectFieldSchema[fieldLevel] = bid_level_field_schema ;
          console.log('QuickLaunchButtons: handleAddNamedField: (B) this.mobxState.projectFieldSchema['+fieldLevel+'] = ',this.mobxState.projectFieldSchema[fieldLevel]) ;
          //
        } ; // end if not this.mobxState.addField.fieldExists
        //
      }) // end then(response => { '...read/change/save projectFieldSchema to/from mobxState'
      //
      .then( () => { // put projectFieldSchema
        //
        // keep going if incumbent alias not found
        if (! this.mobxState.addField.fieldExists) {
          console.log('QuickLaunchButtons: handleAddNamedField: this.mobxState.projectFieldSchema B = ',this.mobxState.projectFieldSchema) ;
          axios
            .put('/v1/projects/' + this.projectId + '/project_field_schemas/' + this.mobxState.projectFieldSchema.id, {
              [fieldLevel]: this.mobxState.projectFieldSchema[fieldLevel]
            }) //query the project custom field schema for all projects
            .then(response => { console.log(response); })
            .catch(console.log.bind(console)); //error catcher;
        } ; // end if not this.mobxState.addField.fieldExists
        //
      }) // end then(response => { // put projectFieldSchema
      //
      .then( () => { // populate column IncumbentFT
        console.log('QuickLaunchButtons: populateIncumbentYN: this.mobxState.projectFieldSchema ', this.mobxState.projectFieldSchema) ;
        this.populateIncumbentYN() ;
      }) // end populate column IncumbentFT

      .then( () => { // i) create scenario;  ii) add constraint;  iii) run it;  iv) go to scenario page
        this.buildRunIncumbentsScenario() ;
      }) // end i) create scenario...

      .catch(console.log.bind(console)) ; //error catcher
  } ; // end handleAddNamedField()

  handleAddNamedFieldButton1 = (event) => {
    event.preventDefault() ;
    this.mobxState.addField.threshold = this.mobxState.dynamicSettings.button1.numeric_value ;
    this.handleAddNamedField() ; // run the whole enchillada
  };

  handleAddNamedFieldButton2 = (event) => {
    event.preventDefault() ;
    this.mobxState.addField.threshold = this.mobxState.dynamicSettings.button2.numeric_value ;
    this.handleAddNamedField() ; // run the whole enchillada
  };

  handleAddNamedFieldButton3 = (event) => {
    event.preventDefault() ;
    this.mobxState.addField.threshold = this.mobxState.dynamicSettings.button3.numeric_value ;
    this.handleAddNamedField() ; // run the whole enchillada
  };

  // populate bids.custom_fields.incumbentYN with 'Yes'/'No' values (routes/v1/bids.js)
  populateIncumbentYN = (event) => { console.log('QuickLaunchButtons: populateIncumbentYN: started ') ;
    axios
      .put('/v1/projects/' + this.projectId + '/bids_incumbent_ids/' + this.mobxState.addField.fieldName) // get bid IDs and custom_fields for incumbent and non-incumbent bids
      .then(response => { console.log('QuickLaunchButtons: populateIncumbentYN: response.data.bid_ids[0] ', response.data.bid_ids[0]) ;
      }) // end then ...response.data.bids
      .catch(console.log.bind(console)) //error catcher
  }; // end populateIncumbentYN()

  // define button based on button_state record from user_settings
  defineQuickLaunchButton(button_settings) {
    let button ;
    //
    switch (button_settings.feature_id ) {
      case 1 :
        button = <a className="btn btn-org pull-right" title=" Run a Least Cost scenario"
             onClick={this.handleClickLeastCostScenario} >
            <FontAwesomeIcon icon={['fal', 'play-circle']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}} /> &nbsp;Least Cost </a> ;
        break ;
      case 2 :
        switch (button_settings.display_order) {
          case 1 :
            button = <a className="btn btn-org pull-right" title=" Run Limited Number of Suppliers scenario"
                 onClick={this.handleClickUpToNSuPpliersButton1} >
                <FontAwesomeIcon icon={['fal', 'play-circle']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}} />
                &nbsp;Up To {button_settings.numeric_value} Suppliers </a> ;
            break ;
          case 2 :
            button = <a className="btn btn-org pull-right" title=" Run Limited Number of Suppliers scenario"
                 onClick={this.handleClickUpToNSuPpliersButton2} >
                <FontAwesomeIcon icon={['fal', 'play-circle']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}} />
                &nbsp;Up To {button_settings.numeric_value} Suppliers </a> ;
            break ;
          case 3 :
            button = <a className="btn btn-org pull-right" title=" Run Limited Number of Suppliers scenario"
                 onClick={this.handleClickUpToNSuPpliersButton3} >
                <FontAwesomeIcon icon={['fal', 'play-circle']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}} />
                &nbsp;Up To {button_settings.numeric_value} Suppliers </a> ;
            break ;
          default : button = <a/> ;
        } ; // end switch by display_order
        break ;
      case 3 :
        switch (button_settings.display_order) {
          case 1 :
            button = <a className="btn btn-org pull-right" title=" Keep % Incumbents above Given Level"
                 onClick={this.handleAddNamedFieldButton1} >
                <FontAwesomeIcon icon={['fal', 'play-circle']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}} />
                &nbsp;Incumbents > {button_settings.numeric_value}% </a> ;
            break ;
          case 2 :
            button = <a className="btn btn-org pull-right" title=" Keep % Incumbents above Given Level"
                 onClick={this.handleAddNamedFieldButton2} >
                <FontAwesomeIcon icon={['fal', 'play-circle']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}} />
                &nbsp;Incumbents > {button_settings.numeric_value}% </a> ;
            break ;
          case 3 :
            button = <a className="btn btn-org pull-right" title=" Keep % Incumbents above Given Level"
                 onClick={this.handleAddNamedFieldButton3} >
                <FontAwesomeIcon icon={['fal', 'play-circle']} style={{fontSize: '20px', paddingTop: '5px', paddingRight: '5px'}} />
                &nbsp;Incumbents > {button_settings.numeric_value}% </a> ;
            break ;
          default : button = <a/> ;
        } ; // end switch by display_order
        break ;
      default : button = <a/> ;
    } ; // end switch button.feature_id
    return button
  } ; // end function defineQuickLaunchButton

  //////////////////END OF HTTP////////////

  //main rendering of component
  render() {
    let button1 = this.defineQuickLaunchButton(this.mobxState.dynamicSettings.button1) ;
    let button2 = this.defineQuickLaunchButton(this.mobxState.dynamicSettings.button2) ;
    let button3 = this.defineQuickLaunchButton(this.mobxState.dynamicSettings.button3) ;

    return (
      <div className="button-demo-kludge">
        {button1}
        {button2}
        {button3}
        {/*End button for quick scenario generator*/}
      </div>
    );
  }
} //end of component



// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate( QuickLaunchButtons, { mobxState: observable } )

export default observer( QuickLaunchButtons ) ;
