import React from 'react';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//helper functions
import  { BuildItemTemplate, FormBuild, GetItemCustomFields, GetBidCustomFields, GetFreightBidCustomFields, BuildBidTemplate, BuildBaseTemplate, BuildFreightBidTemplate } from './BuildTemplate';
import * as aliasHelper from './ImportAlias';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//this component disseminates the data that will go into building the import spreadsheet template and creates an Excel (.xlsx) spreadsheet form the data

class ImportHandler extends React.Component {

    mobxState = {
        bidCustomFieldsList: null, //bid_level custom fields
        freightBidFieldsList: null, //freight_bid_level custom fields
        itemCustomFieldsList: null, //item_level custom fields
        nonCustomCols: null, //non custom fields columns passed by props
    };

    projectId = this.props.projectId;

    tableName = this.props.tableName;

    templateToBuild = this.props.templateToBuild; //keeps irrelevant headers out of form

    componentDidMount(){
        this.getNoneCustom();
        this.getItemCustomFields();
        this.getBidCustomFields();
        this.getFreightBidCustomFields();
    }

    ///for testing remove when commiting final version

    ////////////////
    componentWillUnmount(){
        this.mobxState.itemCustomFieldsList = null;
        this.mobxState.bidCustomFieldsList = null;
        this.mobxState.freightBidCustomFieldsList = null;
        this.mobxState.nonCustomFieldsList = null;
    }

    handleFormBuild = async () => {
        if (this.templateToBuild === "bids") { //bid import
            let aliasedHeaders = await aliasHelper.convertToSystemAliases("bid", this.mobxState.nonCustomCols)
            let formHeaders = BuildBidTemplate(aliasedHeaders, this.mobxState.itemCustomFieldsList, this.mobxState.bidCustomFieldsList)
            console.log(formHeaders)
            FormBuild(formHeaders)
        } else if (this.templateToBuild === "items") { //item import
            let aliasedHeaders = await aliasHelper.convertToSystemAliases("item", this.mobxState.nonCustomCols)
            console.log(aliasedHeaders)
            let formHeaders = BuildItemTemplate(aliasedHeaders, this.mobxState.itemCustomFieldsList)
            console.log(formHeaders)
            FormBuild(formHeaders)
        } else if (this.templateToBuild === "freight_bids") { //freight bid import
            let aliasedHeaders = await aliasHelper.convertToSystemAliases("freight_bid", this.mobxState.nonCustomCols)
            let formHeaders = BuildFreightBidTemplate(aliasedHeaders, this.mobxState.freightBidCustomFieldsList)
            console.log(formHeaders)
            FormBuild(formHeaders)
        }
        else { //generic import using only props
          console.log('this.mobxState.nonCustomCols', this.mobxState.nonCustomCols);
            let formHeaders = BuildBaseTemplate(this.mobxState.nonCustomCols)
            console.log('formHeaders', formHeaders)
            FormBuild(formHeaders)
        }
    }

    getNoneCustom(){
        let nonCustomCols = [];
        for(let val of this.props.nonCustomCols){
            nonCustomCols.push({field_alias: val})
        }
        this.mobxState.nonCustomCols = nonCustomCols;
        console.log('this.props.nonCustomCols', this.props.nonCustomCols);
        console.log('nonCustomCols1', nonCustomCols);
        //console.log(Array.from(this.mobxState.nonCustomCols))
    };

    //fetch custom_fields

    //item_level
    async getItemCustomFields(){
        let itemCustomFields = await GetItemCustomFields(this.projectId, this.tableName)
        this.mobxState.itemCustomFieldsList = itemCustomFields;
        // this.customCols = this.customFields;
        console.log(this.mobxState)
    }
    //bid_level
    async getBidCustomFields(){
        let bidCustomFields = await GetBidCustomFields(this.projectId, this.tableName)
        this.mobxState.bidCustomFieldsList = bidCustomFields;
        // this.customCols = this.customFields;
        console.log(this.mobxState)
    }
    //freight_bid_level
    async getFreightBidCustomFields(){
        let freightBidCustomFields = await GetFreightBidCustomFields(this.projectId, this.tableName)
        this.mobxState.freightBidCustomFieldsList = freightBidCustomFields;
        // this.customCols = this.customFields;
        console.log(this.mobxState)
    }

    render(){
      return (
        <div>
          <button
            className="btn btn-primary download-btn"
            type="button"
            onClick={this.handleFormBuild}>
            <FontAwesomeIcon icon={['fal', 'file-download']} /> Download Excel Sheet Template
          </button>
        </div>
      )
    }

}

decorate(ImportHandler, {
    mobxState: observable
})

export default observer(ImportHandler)
