import React from 'react';
import $ from 'jquery';
import _ from 'lodash';
//http requests
import axios from 'axios'

//check roleToCheck in this format: {'model':'project', 'permission':'r'}
export const checkRole = (roleToCheck) => {
  //console.log("In Role Check", roleToCheck);

  return axios.post('/security/check_role', {'role': roleToCheck})
  .then(response => {
    //check that the authentication returns http status 200 and the body contains the statusConfirmation === 1 just in case
    if (response.status === 200 && response.data.statusConfirmation === 1) {
      //console.log("Successful check auth", response);
      return true
    } else {
      console.log("No check auth", response);
      return false
    }
  })
  .catch(err => {
    console.log("Error returned from checkRole");
    console.error(err);
    return false
  });
};
