import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from '../../../../badges/Badge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken, newJsonFieldSchema } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

class RoleModel extends React.Component {
  projectId = extractToken(window.location.pathname, '/', 3);
  mobxState = {
    formState: {
      user_id: null,
      role_id: null
    },
    formValid: false,
    showHelperIcons: false,
    submitMessage: false,
    tableData: [],
    roleSelect: [],
    permissionSelect: [
      {label:'Write', value: 'w'},
      {label:'Read', value: 'r'}
    ],
    userSelect: []
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  };

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = window.$('#table_rows_dropdown').val();

    //include any custom field names not in db
    this.mobxState.customOptions = {
        project__name: 'Project Name'
    };

    //role select
    axios
      .get('/v1/roles/index_filter')
      .then( response => {
        console.log('roleSelect', response.data.roles);
        this.mobxState.roleSelect = response.data.roles;
      })

    //after ag grid is set up, get the rest of the data
    //table data load
    axios
      .get('/v1/role_models_summary', table_rows)
      .then(response => {

        this.mobxState.tableData = response.data;
        console.log('role_models_summary', response.data);

        //set the column definitions for ag grid, look into buildColumnDefs class to see options
        this.mobxState.columnDefsUsers = gridColumns.buildColumnDefs({
          "customOptions": this.mobxState.customOptions,
          "columnArray": ['role_models'],
          "customFieldSchema": {},
          "dataType": 'role_model' //special tag to signify that this is project data
        });

        //autofit the table columns (hits the child function), this runs after the columns were created
        this.autofitChild();
      })
  }; //end fetchData() ///////////////

  handleFormSubmit = () => {
    console.log('Submitting Form');
    let formState = this.mobxState.formState;
    console.log(formState);
    axios
      .post('/v1/role_models/', formState)
      .then(response => {
        console.log(response);
        console.log('Response Status', response.status);
        this.mobxState.submitMessage = true;
        this.fetchData();
        this.mobxState.formValid = false;
        this.mobxState.showHelperIcons = false;
      }).catch(console.log.bind(console)) //error catcher
  }

  //react-select form state handler
  handleFormChange = (field, event) => {
    console.log(event.value);
    this.mobxState.formState[field] = event.value;
    console.log('formState', this.mobxState.formState);
    //call validationResult
    this.validateForm();
  }

  //text input form state handler
  handleInputChange = (field, event) => {
    console.log(event.target.value);
    this.mobxState.formState[field] = event.target.value;
    console.log('formState', this.mobxState.formState);
    //call validationResult
    this.validateForm();
  }

  deleteRequest = (toSendArr) => {
    console.log("IN DELETION");
    axios.post('/v1/role_models/batch_destroy', {"id_set": toSendArr})
    .then((response) => {
      console.log(response);
      this.fetchData();
    });
  }

  validateForm = () => {
    //just check that all form variables have a value
    if(
      this.mobxState.formState.permission != null
      &&
      this.mobxState.formState.role_id != null
      &&
      this.mobxState.formState.model != null
    ){
      console.log('Form validated');
      this.mobxState.formValid = true;
      this.mobxState.showHelperIcons = true;
    } else{
      console.log('Form failed valitation');
      this.mobxState.formValid = false;
      this.mobxState.showHelperIcons = false;
    };
  }
  //////////////////END HTTP/////////////////

  render() {

    return (
      <div>

				<div className="jumbotron jumbotron_full_width" style={{maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap'}}>
					<div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-5">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#53C8C5" style={{marginTop: '4px'}} />
                  <FontAwesomeIcon icon={['fas', 'user-circle']} color="white" transform="shrink-5" style={{marginTop: '3px'}} />
                </span>
                &nbsp;
                <b className="dash_header">Manage Role Access</b>
              </div>
			        <div className="col-sm-2"></div>
			        <div className="col-sm-2"></div>
			        <div className="col-sm-3 gps">
			          <FontAwesomeIcon
			            icon={["fad", "location-arrow"]}
			            color="#53C8C5"
			            transform="grow-15 left-10"
			            style={{}}
			          />
			          <Link to={"/sourcing/projects/"}>
			            Next: Manage Projects
			          </Link>
			        </div>
            </div>
					</div>
				</div>

				{/* PROJECT FORM */}
				<div className="row">
					<div className="col-sm-12">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg clearfix">
									<b>Role Creation</b>
									<span className="pull-right">

										<Bounce left when={this.mobxState.submitMessage}>
											<b className="text-success" style={{marginRight: '1.5em',marginTop: '4px'}}>
											  Custom Role Created
											</b>
							  		</Bounce>
										<Bounce left when={this.mobxState.showHelperIcons}>
											<span>
												<FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{marginRight:'15px'}}/>
											</span>
										</Bounce>

										<button type="button" disabled={!this.mobxState.formValid} className="btn btn-primary btn-md" onClick={this.handleFormSubmit}>
											<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Add Role
										</button>
									</span>
							</div>
							<div className="panel-body">

                <form>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'top_panel_info'}>Select a data model, permission to add to a specific role. More than one model can be assigned to any role. Expressive permissions!! You have to add both an 'r' and a 'w' permission to have full access to all model pages. Just 'w' does not inherit 'r' permission. </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-sm-4">
                      <b>Role</b>
                      <Select
                        onChange={(e) => this.handleFormChange('role_id', e)}
                        options={this.mobxState.roleSelect}
                        closeMenuOnSelect={true}
                        maxMenuHeight = {400}
                        placeholder={'Select Role'}
                      />
                    </div>
                    <div className="col-sm-4">
                      <b>Data Model</b>
                      <Input
                        name="model"
                        placeholder="Data model for role"
                        className="form-control"
                        onChange={(e) => this.handleInputChange('model', e)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <b>Read or Write Permission</b>
                      <Select
                        onChange={(e) => this.handleFormChange('permission', e)}
                        options={this.mobxState.permissionSelect}
                        closeMenuOnSelect={true}
                        maxMenuHeight = {400}
                        placeholder={'Select r/w permission'}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-sm-12">
                      <b>Role Query (optional)</b>
                      <Input
                        name="role_query"
                        placeholder="Where clause for query"
                        className="form-control"
                        onChange={(e) => this.handleInputChange('role_query', e)}
                      />
                    </div>
                  </div>

                </form>

							</div>
						</div>
					</div>{/* END col-sm-12*/}
				</div>{/* END row*/}
				{/* END ITEM FORM*/}

        {/* PROJECT GRID */}
        <div className="row">
          <div className="col-lg-12">
            {/* AG Grid */}
            <GridRenderer
              gridHeight={"650px"}
              columnDefs={this.mobxState.columnDefsUsers}
              rowData={this.mobxState.tableData.role_models}

              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={9}

              savedState={this.mobxState.pivotState}
              isPivotMode={true}
              gridTitle={"User Roles"}

              setClick={click => this.clickChild = click}

              deleteRequest={this.deleteRequest}
              idColumn={"id"}

              modelsToUpdateArr={[{url:'v1/role_models/', idName:'id'}]}
              //modelsToUpdateArr={false}
              enableSetResizeChild={true}
              setResize={click => this.autofitChild = click}
              // enableSetResizeChildColumns={true}
              // setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

              singleClickEdit={true}
              suppressClickEdit={false}

              isSaveable={false}
              isAwardable={false}
              isDeletable={true}
            />

          </div>
        </div>{/* END GRID ROW */}

      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(RoleModel, {
  mobxState: observable
});

export default observer(RoleModel);

// export default ProjectOverview;
