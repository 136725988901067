import React from "react"

//HTTP FACTORY instead of Redux
class UrlSniffer {

	// Higher order function to call on instantiated class
	getProjectToken() {

        //work with rails URL
        let stateSniffer = window.location.pathname; //takes the rails path
        let pathTokens = stateSniffer.split(/[\s/]+/); //splits by /
        let projectToken = pathTokens[2]; //takes 3rd token, which should be id of project in rails path
				//console.log(projectToken);

		//return data of the call
		return projectToken;
	}
}

export default UrlSniffer
