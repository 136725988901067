import React from "react"
import PropTypes from "prop-types"
import { render } from 'react-dom'

// import Logo from './assets/images/ec_intel_logo.png';

export default class Login extends React.Component {
  render() {
    //var loginUrl = window.location.href+'/saml/login/';
    return (
      <div>
        <div className="login-wrap">
        	<div className="login-html">
            {/* <img src={Logo} /> */}
            <br />
            <br />
            <br />
        		<label className="tab"><a href="/saml/login" style={{'color':'white'}}><i className="glyphicon glyphicon-lock"></i> EC System Single Sign On</a></label>
            <br />
            {/*
        		    <label className="tab"><a href="/saml/login" style={{'color':'white'}}><i className="glyphicon glyphicon-lock"></i> ADFS Windows Single Sign On</a></label>
            */}
        	</div>
        </div>
      </div>
    );
  }
}
