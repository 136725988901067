import React from 'react';
import { render } from 'react-dom';
import ReactDom from 'react-dom';

//http requests
import axios from 'axios';

//filters
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, deleteIfEmpty } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//icons
import optimizeIcon from '../../../../../assets/images/icons/ec_optimize.png';
import spendIcon from '../../../../../assets/images/icons/ec_spend.png';
import insightsIcon from '../../../../../assets/images/icons/ec_insights.png';
import eSourcingIcon from '../../../../../assets/images/icons/ec_esourcing.png';
import contractsIcon from '../../../../../assets/images/icons/ec_contracts.png';
import workflowIcon from '../../../../../assets/images/icons/ec_workflow.png';

//routes
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

//security
import {checkRole} from '../../../../security/SecurityUtilities';

const _ = require('lodash'); //extra utilities

class NoAccess extends React.Component {

  //mount data through http requests
  componentDidMount() {
  };

  render() {

    return (
      <div>
				<div className="jumbotron jumbotron_full_width" style={{maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap'}}>
					<div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-12" style={{marginTop: '7px'}}>
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#3655CC" style={{marginTop: '4px'}} />
                  <FontAwesomeIcon icon={['fas', 'do-not-enter']} color="white" transform="shrink-5" style={{marginTop: '3px'}} />
                </span>
                &nbsp;
                <b className="dash_header">Page Not Found </b>
              </div>
            </div>
					</div>
				</div>

				<br />

				{/* MAIN CONTAINERS */}
        <div className="row">
          <div className="col-lg-2">
          </div>
          <div className="col-lg-8">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg">
                <b>ECSG Alert </b>
                <span className="pull-right">
                </span>
              </div>
              <div className="panel-body">
                <div class="alert alert-danger" role="alert">
                  This page cannot be found in our application. Please contact support if you feel that you should be able to reach this page.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
          </div>
        </div>{/* END ROW */}
				{/* END MAIN CONTAINERS */}

				<br />

      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(NoAccess, {
  mobxState: observable
});

export default observer(NoAccess);

// export default NoAccess;
