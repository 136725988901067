import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';

//handles rendring form components and their validation
class ConstraintBaseSelection extends React.Component {

  mobxState = {
    baseSelect: [], //options for select box
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    ////////////////////
    //FORM LOAD
    //OPERATORS:
    axios.get('/microservices/v1/bases', {params: {} })
    .then((response) => {
      this.mobxState.baseSelect = response.data.bases;
      // console.log('INSIDE COMPONENT');
      console.log('bases', this.mobxState.baseSelect);
    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  render(){

    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }

    return (
      <div>
        <b>Base</b>
        <Select
          onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
          options={this.mobxState.baseSelect}
          closeMenuOnSelect={true}
          maxMenuHeight = {400}
          placeholder={'Unit of measure to apply rule by'}
        />
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ConstraintBaseSelection, {
	mobxState: observable
})

export default observer(ConstraintBaseSelection);

// export default ConstraintBaseSelection
