import React from 'react';
import { render } from 'react-dom';
import ReactDom from 'react-dom';

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { zeroNull, formatForTreeSelect, determineCategoryLabel } from '../../../../services/Utilities';
import _ from 'lodash';

//forms
import NewProjectForm from './NewProjectForm';

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class New extends React.Component {
  pageId = null;
  mobxState = {
    customOptions: {},
    formState: {},
    formOptions: {},
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    filterModelNames: [] //autobuilds filters and clear functions
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}) // Prefer existing values
      };
    });

    // Determine if client categorizes their projects
    // And if so what the categories are and what the options for them are
    Promise.all([
      axios.get('/v1/custom_schemas', { params: { name: 'project_category_settings' } }),
      axios.get('/v1/custom_schemas', { params: { name: 'category_selector_settings' } })
    ]).then(([projectCategorySettingsResp, categorySelectorSettingsResp]) => {
      this.mobxState.projectCategorySettings =
        projectCategorySettingsResp.data &&
        projectCategorySettingsResp.data.custom_schemas &&
        projectCategorySettingsResp.data.custom_schemas[0] &&
        projectCategorySettingsResp.data.custom_schemas[0]['schema_definition'];
      this.mobxState.categorySelectorSettings =
        categorySelectorSettingsResp.data &&
        categorySelectorSettingsResp.data.custom_schemas &&
        categorySelectorSettingsResp.data.custom_schemas[0] &&
        categorySelectorSettingsResp.data.custom_schemas[0]['schema_definition'];
      if (this.mobxState.projectCategorySettings) {
        let { enabled, default_category_id } = this.mobxState.projectCategorySettings;
        if (default_category_id) {
          axios
            .post('/v1/categories_index', {
              category: { id: this.mobxState.projectCategorySettings.default_category_id }
            })
            .then(resp => {
              this.mobxState.defaultCategoryOption = {
                value: `${this.mobxState.projectCategorySettings.default_category_id}`,
                label: determineCategoryLabel(
                  resp.data.categories[0],
                  this.mobxState.categorySelectorSettings.show_id_in_label
                )
              };
            });
        }

        //grab categories for category selection
        axios.post('/v1/categories_index', {}).then(response => {
          let tmpResult = formatForTreeSelect(
            response.data.categories,
            [
              r => (r.custom_fields && r.custom_fields.custom_field_1) || '',
              r => (r.custom_fields && r.custom_fields.custom_field_2) || '',
              r => (r.custom_fields && r.custom_fields.custom_field_3) || '',
              r => (r.custom_fields && r.custom_fields.custom_field_4) || '',
              r => (r.custom_fields && r.custom_fields.custom_field_5) || ''
            ],
            this.mobxState.categorySelectorSettings.show_id_in_label
          );
          this.mobxState.formOptions = this.mobxState.formOptions || {};
          this.mobxState.formOptions['category_id'] = tmpResult;
        });
      }
    });

    axios
      .get('/v1/custom_schemas', { params: { name: 'project' } }) //query the project custom field schema for all projects
      .then(response => {
        //get map of field labels from API
        this.mobxState.customSchema = response.data;
      });
  };
  //////////////////END HTTP/////////////////

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };

  render() {
    const hcHeight = '300px';

    return (
      <div>
        <div className='jumbotron jumbotron_full_width'>
          <div className='jumbotron_full_width_container'>
            <b className='dash_header'>New Project</b>
          </div>
        </div>

        <div className='row'>
          {' '}
          <div className='col-lg-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>New Project Options</b>
              </div>
              <div className='panel-body'>
                <NewProjectForm
                  schemaDefinition={
                    (this.mobxState.customSchema &&
                      this.mobxState.customSchema.custom_schemas &&
                      this.mobxState.customSchema.custom_schemas[0] &&
                      this.mobxState.customSchema.custom_schemas[0].schema_definition) ||
                    {}
                  }
                  projectCategorySettings={this.mobxState.projectCategorySettings}
                  initialCategoryOption={this.mobxState.defaultCategoryOption}
                  formOptions={this.mobxState.formOptions}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(New, {
  mobxState: observable
});

export default observer(New);

// export default ProjectOverview;
