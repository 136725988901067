import React from 'react';
import { Form, Text, Checkbox } from 'informed';

//ant design checkbox form
// import { Checkbox } from 'antd';

//handles rendring form components and their validation
class SaveGridModal extends React.Component {


  isChecked = true //bool to control checkBox

  handleCheckBox = (e) => {
    this.isChecked = !this.isChecked
    if(this.isChecked === false){
      this.props.formState.values.projectId = null
      // console.log("Do not Include Project Id")
      // console.log("PROPS", this.props)
    }
  }

  componentDidUpdate(){
    if(this.isChecked === true){
      this.props.formState.values.projectId = this.props.projectIdNumber
      // console.log("PROPS", this.props)
    }
  }


  render(){

    const validate = value => {
      return !value || value.length < 3 ? 'Must be at least three characters' : null;
    }

    return (
      <div className="save_grid_modal">
        <div className="form-group">
          <label htmlFor="name">Table Name: <span className="text-danger">{this.props.formState.errors.name}</span> </label>
          <Text field="name" id="name" validate={validate} validateOnChange validateOnBlur className="form-control"/>
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <Text field="description" id="description" className="form-control"/>
        </div>
        <div className="form-group">
          {/* Will only display if grid on page contains a "project_id" */}
          { /* Checked box will send project_id with the data, default is to send */}
          {this.props.projectIdNumber !== undefined &&
            <div className="save_grid_modal">
              <input
                style={{ transform: "scale(1.2)"}}
                type="checkbox"
                name='checkBoxSelection'
                onChange={this.handleCheckBox}
                id="checkBox"
                field="projectId"
                defaultChecked={this.isChecked}
              />
              <b>Save To This Project Only</b>
            </div>
          }
        </div>

        <hr />
        <button type="submit" className="btn btn-primary btn-md btn-block">
          Save
        </button>
        <br />
      </div>
    )
  }

}

export default SaveGridModal
