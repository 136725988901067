import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from '../../../../badges/Badge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token
import ModuleSettingsForm from '../../../../forms/ModuleSettingsForm'; //the form renderer for custom fields

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken, newJsonFieldSchema } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

class ModuleSettings extends React.Component {
  projectId = extractToken(window.location.pathname, '/', 3);
  mobxState = {
    pages: [],
    formState: {
      formValid: true
    }
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////

  //data loader
  fetchData = () => {
    //set up custom options for components, then get data for components
    axios
      .get('/v1/pages') //query the project custom field schema for all projects
      .then(response => {
        //get map of field labels from API
        let pages =
          response.data &&
          response.data.pages;
        this.mobxState.pages = pages;
      })
      .catch(console.log.bind(console)); //error catcher
  }; //end fetchData() ///////////////

  handleFormChange = (propertyPath, fieldValue) => {
    _.set(this.mobxState.pages, propertyPath, fieldValue);
    let pageDisplayIdx = propertyPath[0];
    let pageId = this.mobxState.pages[pageDisplayIdx].id;
    let field = propertyPath[1];
    axios
      .put('/v1/pages/' + pageId, {
        [field]: fieldValue
      })
      .then(response => {
        console.log(response);
      })
      .catch(console.log.bind(console));
  };

  render() {
    return (
      <div>
        <div
          className='jumbotron jumbotron_full_width'
          style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
        >
          <div className='jumbotron_full_width_container'>
            <div className='row'>
              <div className='col-sm-5'>
                <span className='fa-layers fa-fw fa-2x'>
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform='grow-3'
                    color='#53C8C5'
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fas', 'folders']}
                    color='white'
                    transform='shrink-5'
                    style={{ marginTop: '3px' }}
                  />
                </span>
                &nbsp;
                <b className='dash_header'>Edit Module Settings For Spend</b>
              </div>
              <div className='col-sm-2'></div>
              <div className='col-sm-2'></div>
              <div className='col-sm-3 gps'>
                <FontAwesomeIcon
                  icon={['fad', 'location-arrow']}
                  color='#53C8C5'
                  transform='grow-15 left-10'
                  style={{}}
                />
              </div>
            </div>
          </div>
        </div>

        {/* TRANSACTION FORM */}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg clearfix'>
                <b>Spend Pages</b>
                <span className='pull-right'></span>
              </div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className={'top_panel_info'}>
                      Pages for the spend module. Enabled determines whether page is enabled in the sidebar. Custom Name determines what text is displayed.
                    </div>
                  </div>
                </div>
                <br />

                <ModuleSettingsForm
                  handleFormChange={this.handleFormChange}
                  pages={this.mobxState.pages}
                  pagesToEdit={[201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ModuleSettings, {
  mobxState: observable
});

export default observer(ModuleSettings);

// export default ProjectOverview;
