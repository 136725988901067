import React, { Component } from 'react';
import {timeoutLogout} from '../../services/Timing';

//project views
import Modules from './views/modules/Modules';
import NoAccess from './views/messages/NoAccess';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//images
import CompanyLogoWithText from '../../../assets/images/simfoni_logo_1920x1080_transparent_background.png'
import CompanyLogoWithoutText from '../../../assets/images/simfoni_transparent_icon.png'

//sidebar
import MainSidebar from '../../headers/MainSidebar';

 

import MainHeader from '../../headers/MainHeader';
import withAuth from '../../security/withAuth';
import withRole from '../../security/withRole';

import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';

//components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//homemade utilities
import {zeroNull, extractToken} from "../../services/Utilities";
import {checkRole} from "../../security/SecurityUtilities";
import { injectCustomerColorScheme } from '../../services/InjectedStyle';

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class Headquarters extends React.Component {

	mobxState = {
		// canProjectR: false,
		// canTransactionR: false,
		// canLegacyContractR: false,
		// canLegacyProjectR: false,
		// canLegacyTicketR: false,
  };

	//NEED TO CHECK BODY OF RESPONSE FOR 23 STATUS CHECK
	componentDidMount() {
		//role checks
		checkRole({'model':'project', 'permission':'r'}).then(res => { this.mobxState.canProjectR = res });
		checkRole({'model':'transaction', 'permission':'r'}).then(res => { this.mobxState.canTransactionR = res });
		checkRole({'model':'legacy_project', 'permission':'r'}).then(res => { this.mobxState.canLegacyProjectR = res });
		checkRole({'model':'legacy_ticket', 'permission':'r'}).then(res => { this.mobxState.canLegacyTicketR = res });
		checkRole({'model':'legacy_contract', 'permission':'r'}).then(res => { this.mobxState.canLegacyContractR = res });
		checkRole({'model':'legacy_project_insight', 'permission':'r'}).then(res => { this.mobxState.canLegacyProjectInsightR = res });
		checkRole({'model':'legacy_ticket_insight', 'permission':'r'}).then(res => { this.mobxState.canLegacyTicketInsightR = res });
		checkRole({'model':'legacy_contract_insight', 'permission':'r'}).then(res => { this.mobxState.canLegacyContractInsightR = res });
		checkRole({'model':'legacy_overview_insight', 'permission':'r'}).then(res => { this.mobxState.canLegacyOverviewInsightR = res });
		checkRole({'model':'legacy_trello_insight', 'permission':'r'}).then(res => { this.mobxState.canLegacyTrelloInsightR = res });
    injectCustomerColorScheme().then(({styleInjector, clientColorScheme}) => {
      this.mobxState.styleInjector = styleInjector;
      this.mobxState.clientColorScheme = clientColorScheme;
    });
		//timeout user session in seconds
		timeoutLogout(60*60);
	}

	componentDidUpdate() {
		//timeout user session in seconds
		timeoutLogout(60*60);
	}

	render() {

    //this variable does all the routing and is passed into here from parent
    const match = this.props.match;

		return (
      <div>
        <div id='main-page-header' className='main-page-header'>
          {/* HEADER ---------------------------------------------------------- */}
          <div id='top_line'></div>
          <nav className='navbar header-navbar navbar-fixed-top'>
            <div className='navbar-wrapper'>
              {/* Header and Logo ----------------------------------------------------------------- */}
              <div className='navbar-logo'>
                <button type='button' data-toggle="modal" data-target="#main-sidebar-dock" className='btn btn-default navbar-btn'>
                  <FontAwesomeIcon icon={['fas', 'bars']} color="#D3D4D6" transform="" style={{fontSize: '19px'}} />
                </button>
                <img alt="Simfoni Logo" className="simfoni-logo" src={CompanyLogoWithoutText} />
              </div>

              {/* Links in the center ------------------------------------------------------------- */}
              <div className='navbar-menus'>
                <ul className='nav navbar-nav'>
                  {/*{this.mobxState.canProjectR && (
                    <li className="dropdown">
                      <a id="optimizationDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Optimization
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="optimizationSubmenu">
                        <li><Link to={`/sourcing/projects`}>Projects</Link></li>
                      </ul>
                    </li>
                  )}*/}
                  {/*{ (this.mobxState.canLegacyProjectInsightR || this.mobxState.canLegacyContractInsightR || this.mobxState.canLegacyTicketInsightR) && (
                    <li className="dropdown">
                      <a id="insightsDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Insights
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="insightsSubmenu">
                        {this.mobxState.canLegacyOverviewInsightR && <li><Link to={`/executive/Overview`}>Overview</Link></li>}
                        {this.mobxState.canLegacyProjectInsightR && <li><Link to={`/executive/ProjectOverview`}>Projects</Link></li>}
                        {this.mobxState.canLegacyContractInsightR && <li><Link to={`/executive/ContractOverview`}>Contracts</Link></li>}
                        {this.mobxState.canLegacyTicketInsightR && <li><Link to={`/executive/TaskOverview`}>Workflow</Link></li>}
                      </ul>
                    </li>
                  )}*/}
                  {/*{this.mobxState.canTransactionR && (
                    <li className="dropdown">
                      <a id="spendDropdown" href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        Spend
                        <span className="caret"/>
                      </a>
                      <ul className="dropdown-menu" id="spendSubmenu">
                        <li><Link to={`/spend/spend`}>Overview</Link></li>
                      </ul>
                    </li>
                  )}*/}
                  {' '}
                  {/* end conditional menu display */}
                </ul>
              </div>
            {/* Button for right hand side */}
            <div className="pull-right" style={{paddingTop: '5px'}}>
              <img src={this.mobxState.client_logo} style={{maxWidth: '200px'}}/>
            </div>
          </div>
        </nav>
      </div>
      {/* Sidebar ------------------------------------------------------- */}
      <MainSidebar />

      {/* BODY ---------------------------------------------------------- */}
        <div className="wrapper" style={{top: '152px'}}>
            <div id="content">
              <Switch>
								<Route path={`${match.path}/modules`} component={withAuth(Modules)}/>
								<Route path={`${match.path}/messages/no_access`} component={withAuth(NoAccess)}/>
              </Switch>
              <div className="line"></div>

            </div>


        </div>

      </div>
		); //end return
	} // end render

} //end class

decorate(Headquarters, {
  mobxState: observable
});

export default observer(Headquarters);
