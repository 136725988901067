import React from "react"
import PropTypes from "prop-types"

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from "../HCGlobal"

import Highcharts from 'highcharts';

//basic barchart options.. not using react library. Highcharts already loaded in asset pipeline
class PieChart extends React.Component {

	// //mount data ON CHANGE
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.pieData !== this.props.pieData) {
			setHighChartsGlobals();
			var options = {

				chart: {
					type: 'pie'
				},
				subtitle: {
					text: this.props.pieTitle
				},
				title: {
					text: null
				},
				series: [{
					data: this.props.pieData,
					size: '80%',
					innerSize: '60%'
				}],
				plotOptions: {
				  pie: {
		      	cursor: 'pointer',
		      	showInLegend: true,
		      	dataLabels: {
		         	enabled: false,
		         	format: '<b>{point.name}</b>: {point.percentage:.1f} %'
		     		}
					},
			  	series: {
		      	cursor: 'pointer',
		      	point: {
			        events: {
								click: (e) => {
									//send back the filterParam assigned in props - this names the field being sent
									//send back the e.point.name BUT in react-select format, so the selector could read it right out of mobxState
									this.props.handleFilterFunction(this.props.filterParam, [{label: `${e.point.name}`, value: e.point.name}] );
		            }
			        }
		     		}
			  	}
				},
				legend: {
				  enabled: true,
				  layout: 'vertical',
				  align: 'right',
				  width: 200,
				  verticalAlign: 'middle',
				  useHTML: true,
				  labelFormatter: function() {
				  	return '<div style="width:200px"><div style="width:48%; float:left">' + this.name.substring(0,10) + '</div><div style="width:48%; float:right"> ' + this.y + '</div></div>';
					}
	     	}
		 	} //end options

			//use javascript to render to id.. id name passed via props
			var hcPie = Highcharts.chart(this.props.renderInDivId, options);
		} //end if

	} //end componentDidUpdate

	render() {
		return (
			<div id={this.props.renderInDivId} style={{height: this.props.height}}></div>
		);
	}
}//end component

export default PieChart
