import isEqual from 'lodash';
/*
FIFOQueue: Items come off queue in the order they are inserted.
PARAMETERS:
    n :: int - Number of items to allow in the queue (use -1 for unlimited length)
USAGE:
    let queue = new FIFOQueue(3)
    queue.push('a'); // queue.items => ['a']
    queue.push('b'); // queue.items => ['a', 'b']
    queue.push('c'); // queue.items => ['a', 'b', 'c']
    queue.push('d'); // queue.items => ['b', 'c', 'd']
*/
export default class FIFOQueue {
  constructor(n) {
    this.n = n;
    this.items = [];
  }
  peekLeft = () => this.items[0];
  peekRight = () => this.items[this.items.length - 1];
  // [a,b,c] => [b,c,a]
  rotateLeft = n => {
    for (let i = 0; i < n; i++) {
      this.items = [...this.items.slice(1), this.items[0]];
    }
  };
  // [a,b,c] => [c,a,b]
  rotateRight = n => {
    for (let i = 0; i < n; i++) {
      this.items = [this.items[this.items.length - 1], ...this.items.slice(0, this.items.length - 1)];
    }
  };
  push = item => {
    if (this.items.length === this.n) {
      this.items = [...this.items.slice(1), item];
    } else {
      this.items = [...this.items, item];
    }
  };
  pop = () => {
    let first = this.items[0];
    this.items = this.items.slice(1);
    return first;
  };
  popLeft = () => {
    let first = this.items[0];
    this.items = this.items.slice(1);
    return first;
  };
  popRight = () => {
    let last = this.items[this.items.length - 1];
    this.items = this.items.slice(0, this.items.length - 1);
    return last;
  };
  extend = (arr) => {
    arr.forEach(el => {
      this.push(el);
    });
  };
  indexOf = obj => {
    for (let i = 0; i < this.items.length; i++) {
      if (isEqual(obj, this.items[i])) {
        return i;
      }
    }
    return -1;
  };
}

function test() {
  let assert = (x, val) => {
    if (!isEqual(x, val)) {
      throw Error(`ASSERTION ERROR=====
      expected: ${val}
      got: ${x}`);
    }
    return true;
  };

  let queue = new FIFOQueue(3);
  ['a', 'b', 'c'].map(x => queue.push(x));
  assert(queue.items, ['a', 'b', 'c']);
  queue.rotateLeft(1);
  assert(queue.items, ['b', 'c', 'a']);
  assert(queue.peekLeft(), 'b');
  assert(queue.peekRight(), 'a');

  queue = new FIFOQueue(3);
  ['a', 'b', 'c'].map(x => queue.push(x));
  assert(queue.items, ['a', 'b', 'c']);
  queue.rotateRight(1);
  assert(queue.items, ['c', 'a', 'b']);

  queue = new FIFOQueue(3);
  ['a', 'b', 'c'].map(x => queue.push(x));
  assert(queue.items, ['a', 'b', 'c']);
  queue.rotateRight(2);
  assert(queue.items, ['b', 'c', 'a']);

  queue = new FIFOQueue(3);
  ['a', 'b', 'c'].map(x => queue.push(x));
  assert(queue.peekLeft(), 'a');
  assert(queue.pop(), 'a');

  assert(queue.items, ['b', 'c']);
  assert(queue.popRight(), 'c');
  assert(queue.items, ['b']);

  queue = new FIFOQueue(3);
  ['a', 'b', 'c'].map(x => queue.push(x));
  assert(queue.indexOf('b'), 1);
  assert(queue.indexOf('c'), 2);
  assert(queue.indexOf('d'), -1);
}
