import React from 'react';


//http requests
import axios from 'axios';


import SpinLoader from "../loaders/SpinLoader";

//renders table row selector
class TableRows extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        rowCountLabel: "1 Thousand", // label for displaying row count from db
        value: "1000", //default value
      };
    }

    //list to get labels based on values
    selectOptions = [{
        value: 1000,
        label: '1 Thousand'
      },
      {
        value: 10000,
        label: '10 Thousand'
      },
      {
        value: 50000,
        label: '50 Thousand'
      },
      {
        value: 100000,
        label: '100 Thousand'
      },
    ]

    //if "useCustomSchemaRowCount" prop is passed down and true use the default value from custom_schemas table
    componentDidMount() {
      console.log(this.props)
      if (this.props.useCustomSchemaRowCount === true) {
        axios
          .get('/v1/custom_schemas', {
            params: {
              name: 'project_grid_rows'
            }
          }) //query the project custom field schema for all projects
          .then(response => {
            //dbValue is default number of rows from "custom_ schemas" table (wrapped in quotes to use as the select value)
            let dbValue = JSON.stringify(response.data.custom_schemas[0].schema_value)
            this.setState({
              value: dbValue,
            })
            this.updateCurrentDefault(dbValue)
          })
          .catch(console.log.bind(console)) //error catcher
      }
    }

    //for showing what the current row count in the db is in the select menu
    updateCurrentDefault = (val) => {
      let defaultValue = this.selectOptions.find(option => option.value === parseInt(val))
      this.setState({
        rowCountLabel: defaultValue.label
      })
    }

    //changes state values for row count and label
    handleSelect = (event) => {
      let val = parseInt(event.target.value)
      this.setState({
        value: val
      })
      this.updateCurrentDefault(val);
      this.props.fetchData();
      // console.log(val, "-----> ", this.state);
    }

    render(){
      return (
        <React.Fragment>
          <span>
            <select value={`${this.state.value}`} id="table_rows_dropdown" className="simple_dropdown" onChange={this.handleSelect} >
              <option disabled hidden>{`${this.state.rowCountLabel}`}</option>
              <option value="1000">1 Thousand</option>
              <option value="10000">10 Thousand</option>
              <option value="50000">50 Thousand</option>
              <option value="100000">100 Thousand</option>
            </select>
          </span>
        </React.Fragment>
      )
    }

}

export default TableRows
