import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//icons
import merge from '../../../assets/images/icons/merge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumericInput from 'react-numeric-input';

//handles rendring form components and their validation
class QuantitativeConstraintSelection extends React.Component {

//taken from scope selection renamed to qulitative selection

  mobxState = {
    quantitativeSelect: [], //options for select box
    quantitativeValueSelect: [], //options for select box
    showQuantitativeValueSelect: false, //conditional render for select box
    quantitativeValueDefaultValue: null, //default selected value
    quantitativeValueSelectedValue: null
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }


  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  
  fetchData = () => {
   
    ////////////////////
    //FORM LOAD
    //QUANTITATIVES:
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/quantitative_constraints', {params: {} })
    .then((response) => {
      console.log(response.data.sorted_number_fields)
      this.mobxState.quantitativeSelect = response.data.sorted_number_fields; //sorted list sent by line:301 in microservices/constraints.js
      
    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  //handle selecting the quantitative in the dropdown and update quantitative _value dropdown in the state


  handleQuantitativeSelection = (selectedQuantitative) => {

    console.log('selectedQuantitative ', selectedQuantitative.value);
    //don't show dropdown for quantitative  on overall quantitative 
    if (selectedQuantitative.value != 'overall') {
      this.mobxState.showQuantitativeValueSelect = true;
    } else {
      //hide dropdown
      this.mobxState.showQuantitativeValueSelect = false;
    }

    //update form state in parent component
    this.props.handleFormChange('custom_field_name', selectedQuantitative);

  }


  render(){

    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }

    return (
      <div>
        <div className={this.props.scopeCssClass}> {/* kept css styles unchanged to maintain consistency */}
          <div className="row">
            <div className="col-lg-2">
              <div>
                <FontAwesomeIcon icon={['fad', 'list-ol']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{marginRight:'20px'}}/>
              </div>
            </div>
            <div className="col-lg-10">
              {/* main form that is always rendered */}
              <b>Custom Fields</b>
              <Select
                onChange={this.handleQuantitativeSelection}
                options={this.mobxState.quantitativeSelect}
                closeMenuOnSelect={true}
                maxMenuHeight = {400}
                placeholder={'Select custom fields'}
              />
            </div>
          </div>
        </div>


      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(QuantitativeConstraintSelection, {
	mobxState: observable
})

export default observer(QuantitativeConstraintSelection);

// export default QuantitativeConstraintSelection
