import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from '../../../../badges/Badge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token
import UserSettingsForm from '../../../../forms/UserSettingsForm'; //the form renderer for custom fields

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken, newJsonFieldSchema } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

class UserSettings extends React.Component {
  projectId = extractToken(window.location.pathname, '/', 3);
  mobxState = {
    userSettings: { // #J49# column names/types, in case we need it
      id:0, user_id:0, feature_id:0, feature_desc:'', parent_feature:'', text_value:'', numeric_value:0, is_active:false, display_order:0, created_at:'', updated_at:''
    },
    userSettingHeaders: { // headers for the Quick-Launch Buttons table
      id: 'Row ID',user_id:'User ID', feature_id:'Feature ID', feature_desc:'Feature', parent_feature:'Category', text_value:'Note', numeric_value:'Numeric Value',is_active:'Active?',created_at:'Created at',updated_at:'Updated at'
    },
    userSettingsSet: [] , // for user's settings, all of them
    projectFieldSchema: { schema_definition: {} },
    formState: { formValid: true },
  };

  //mount data through http requests
  componentDidMount() { this.fetchData(); }; //call the data loader to load data into the view

  //////////////////HTTP/////////////////

  //data loader
  fetchData = () => {
    // cap_active_buttons if needed
    axios
      .put('/v1/user_settings/cap_active_buttons', {"cap":3}) 
      .then(response => {
        console.log('cap_active_buttons recordIDs :', response) ;
        // set_user_defaults_if_missing
        axios
          .put('/v1/user_settings/set_user_defaults_if_missing', {"cap":3}) 
          .then(response => {
            console.log('set_user_defaults_if_missing response :', response) ;
            // 
            //query user settings table
            axios
              .get('/v1/user_settings/quick_launch_buttons') 
              .then(response => { // console.log('user_settings read 1', response.data.user_settings);
                //get map of field labels from API
                this.mobxState.userSettingsSet = response.data.user_settings; // console.log('this.mobxState.userSettingsSet read 1', JSON.stringify(this.mobxState.userSettingsSet)) ;
                //include any custom field names not in db
                this.mobxState.userSettingsSet = {
                  ...this.mobxState.userSettingsSet,
                  ...{id: 'Row ID',user_id:'User ID', feature_id:'Feature ID', feature_desc:'Feature', text_value:'Note', numeric_value:'Numeric Value',is_active:'Active?',created_at:'Created at',updated_at:'Updated at'
                     }
                };
              })
              .catch(console.log.bind(console)); //error catcher
          }) // end set_user_defaults_if_missing ... then( )
          .catch(console.log.bind(console)) //error catcher
      }) // end cap active buttons ... then( )
      .catch(console.log.bind(console)) //error catcher
  }; //end fetchData() ///////////////

  handleAddField = fieldLevel => {
    console.log('Add setting for editing: ', fieldLevel);
    let defautNewRecord = { feature_id:1  , feature_desc:'Least Cost' , parent_feature:fieldLevel
                          , text_value:'' , numeric_value:0           , is_active:false           , display_order:1};
    axios
      .put('/v1/user_settings/add_record', defautNewRecord) // add record to DB
      .then(response => { this.fetchData() })               // display with changes
      .catch(console.log.bind(console))                     // error catcher;
  } ; // end handleAddField

  handleDeleteField = (fieldLevel, record_id) => {
    console.log('Deleting: ', fieldLevel, record_id, this.mobxState.projectFieldSchema);

    let ndx ; let key_found = false ;
    Object.keys(this.mobxState.userSettingsSet).forEach((ky) => {
      if (this.mobxState.userSettingsSet[ky].id === record_id) {ndx = ky; key_found = true}// ; console.log('userSettingsSet key found ', ky)}
    });
    //SAVING SCHEMA CHANGES in db
    axios
      .delete('/v1/user_settings/' + record_id) // delete user setting record
      .then(response => {
        console.log(response);
        delete this.mobxState.userSettingsSet[ndx];
      }).catch(console.log.bind(console)) //error catcher;
  } ; // end handleDeleteField

  //handles change in the projectFieldSchma by looking up the path of the property given in param with lodash and setting the property to the fieldValue
  handleFormChangeOptions = (propertyPath, option) => {
    let userSettingId = this.mobxState.userSettingsSet[propertyPath[0]].id;
    let body = {} ;
    body[propertyPath[propertyPath.length - 1]] = option.label ;

    _.set(this.mobxState.userSettingsSet, propertyPath, option.value);
    console.log('set userSettingsSet field to ', propertyPath[propertyPath.length - 1], option.value) ;

    axios
      .put(`/v1/user_settings/${userSettingId}`, body )
      .then(response => { console.log(response); })
      .catch(console.log.bind(console)) //error catcher;
  } ; // end handleFormChangeOptions

  //handles changes by running a PUT route
  handleFormChangeNumeric = (propertyPath, event, fieldValue) => {
    // first, make sure the argument is integer
    let parsedValue = 0 ;
    if (/\d+/.test(fieldValue)) { parsedValue = parseInt(/\d+/.exec(fieldValue)[0]) };

    // then, apply change
    this.handleFormChangeField(propertyPath, event, fieldValue) ; 
  } ; // end handleFormChangeNumeric

  //handles changes by running a PUT route
  handleFormChangeField = (propertyPath, event, fieldValue) => {
    console.log('handleFormChangeField fieldValue', fieldValue);
    let userSettingId = this.mobxState.userSettingsSet[propertyPath[0]].id;
    let body = {} ;
    body[propertyPath[propertyPath.length - 1]] = fieldValue ;
    _.set(this.mobxState.userSettingsSet, propertyPath, fieldValue);

    console.log('handleFormChangeField for axios: propertyPath = ', propertyPath, '; body = ', body);
    axios
      .put(`/v1/user_settings/${userSettingId}`, body )
      .then(response => {
        console.log('handleFormChangeField axios response', response);
      }).catch(console.log.bind(console)) //error catcher;
  } ; // end handleFormChangeField

  //////////////////END HTTP/////////////////

  render() {

    return (
      <div>

				<div className="jumbotron jumbotron_full_width" style={{maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap'}}>
					<div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-5">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#53C8C5" style={{marginTop: '4px'}} />
                  <FontAwesomeIcon icon={['fas', 'folders']} color="white" transform="shrink-5" style={{marginTop: '3px'}} />
                </span>
                &nbsp;
                <b className="dash_header">Edit User Settings</b>
              </div>
			        <div className="col-sm-2"></div>
			        <div className="col-sm-2"></div>
			        <div className="col-sm-3 gps">
			          <FontAwesomeIcon
			            icon={["fad", "location-arrow"]}
			            color="#53C8C5"
			            transform="grow-15 left-10"
			            style={{}}
			          />
			          <Link to={"/sourcing/projects/"}>
			            Next: Manage Projects
			          </Link>
			        </div>
            </div>
					</div>
				</div>

				{/* PROJECT FORM */}
				<div className="row">
					<div className="col-sm-12">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg clearfix">
									<b>Quick-Launch Buttons</b>

									<span className="pull-right">
										<button type="button" disabled={!this.mobxState.formState.formValid} className="btn btn-primary btn-md" onClick={() => this.handleAddField('Quick-Launch Buttons')}>
											<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Add Button Setting
										</button>
									</span>
							</div>
							<div className="panel-body">

								<div className="row">
									<div className="col-sm-12">
										<div className={'top_panel_info'}>Adjust quick-launch buttons settings for optimization scenarios</div>
									</div>
								</div>
								<br />

                {this.mobxState.userSettingsSet &&
                  <UserSettingsForm
                    handleFormChangeOptions={this.handleFormChangeOptions}
                    handleFormChangeField={this.handleFormChangeField}
                    handleFormChangeNumeric={this.handleFormChangeNumeric}
                    handleDeleteField={this.handleDeleteField}
                    projectFieldSchema={this.mobxState.userSettingsSet}
                    schemaLevel={'schema_definition'}
                  />
                }
							</div>
						</div>
					</div>{/* END col-sm-12*/}
				</div>{/* END row*/}

        {/* PLACEHOLDER for other than quick-launch buttons parent_feature(s) */}

      </div>
    );
  }
}

decorate(UserSettings, { // when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
  mobxState: observable
});

export default observer(UserSettings);
