import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, Select as AntdSelect, TreeSelect } from 'antd';
import AntdMultiSelect from '../../../../filters/AntdMultiSelect';
import AntdSearchedMultiSelect from '../../../../filters/AntdSearchedMultiSelect';
import { SketchPicker } from 'react-color';
import ColorPicker from '../../../../services/ColorPicker';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { humanizeString } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';

//security
import { checkRole } from '../../../../security/SecurityUtilities';

const _ = require('lodash'); //extra utilities

class DefaultFiltersForm extends React.Component {
  mobxState = {};
  renderArrayField = (name, value) => {
    return (
      <div className='col-xs-12' key={`color-field-${name}`}>
        <div className='form-group'>
          <label htmlFor={`form-{name}-input`}>{humanizeString(name)}</label>
          <div className='input-group'>
            <input
              id={`form-{name}-input`}
              type='text'
              name=''
              className='form-control'
              value={this.props.schemaDefinition[name].value}
              onChange={e => this.props.handleChange([name, 'value'], e.target.value.split(/\s*,\s*/g))}
              required='required'
              pattern='^(#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}),)*([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'
            />
            <div className='input-group-btn' style={{ zIndex: 'inherit' }}>
              {/* Reset Button */}
              <button type='button' className='btn btn-default' onClick={() => this.props.handleReset(name)}>
                <FontAwesomeIcon icon={['fal', 'backspace']} style={{ marginTop: '7px' }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderColorField = (name, value) => {
    return (
      <div className='col-xs-3' key={`color-field-${name}`}>
        <div className='form-group'>
          <label htmlFor={`form-{name}-input`}>{humanizeString(name)}</label>
          <div className='input-group'>
            <input
              id={`form-{name}-input`}
              type='text'
              name=''
              className='form-control'
              value={this.props.schemaDefinition[name].value}
              onChange={e => this.props.handleChange([name, 'value'], e.target.value)}
              required='required'
              pattern='^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$'
              title=''
            />
            <div className='input-group-btn' style={{ zIndex: 'inherit' }}>
              {/* Color Picker Button */}
              <ColorPicker
                color={this.props.schemaDefinition[name].value}
                onChange={e => {
                  this.props.handleChange([name, 'value'], e.hex);
                }}
              />
              {/* Reset Button */}
              <button type='button' className='btn btn-default' onClick={() => this.props.handleReset(name)}>
                <FontAwesomeIcon icon={['fal', 'backspace']} style={{ marginTop: '7px' }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  resetToDefaultValue = (name, value) => {
    throw new Error('NOT YET IMPLEMENTED ERROR');
  };
  render() {
    // Format custom fields
    let fields = [];
    let sortFn = (a, b) => {
      let aVal = a && a[1] && a[1].display_order;
      let bVal = b && b[1] && b[1].display_order;
      if (aVal === bVal) {
        return 0;
      } else if (aVal < bVal) {
        return -1;
      } else {
        return 1;
      }
    };
    Object.entries(this.props.schemaDefinition)
      .sort(sortFn)
      .forEach(([k, v]) => {
        if (v.value && Array.isArray(v.value)) {
          fields.push(this.renderArrayField(k, v));
        } else {
          fields.push(this.renderColorField(k, v));
        }
      });
    return (
      <div>
        <div className='row'>{fields}</div>
        <br />
        <button type='button' className='btn btn-primary btn-block' onClick={this.props.handleSubmit}>
        <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Publish Changes
        </button>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(DefaultFiltersForm, {
  mobxState: observable
});

export default observer(DefaultFiltersForm);

// export default ProjectOverview;
