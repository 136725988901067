import React from 'react';

//forms
import {
  AutoComplete,
  TextArea,
  Input,
  InputNumber,
  Checkbox,
  Slider,
  Row,
  Col,
  Select as AntdSelect,
  TreeSelect
} from 'antd';
import AntdMultiSelect from '../../filters/AntdMultiSelect';
import AntdSearchedMultiSelect from '../../filters/AntdSearchedMultiSelect';

//loops through filterModelNames property, and renders a filter for each, including onChange callback
//NOTE about 10% slower than manually rendering the filters
class ConfigDropdown extends React.Component {
  handleSubmit = () => {};

  makeStringInput = (name, definition) => {
    return (
      <div className='form-group col-xs-6' key={`form-${this.props.value.id}-${name}-input`}>
        <label htmlFor={`form-${this.props.value.id}-${name}-input`}>{definition.field_alias}</label>
        <AutoComplete
          id={`form-${this.props.value.id}-${name}-input`}
          options={[]}
          defaultValue={this.props.value[name] || ''}
          className='form-control'
          onChange={value => this.props.handleChange(name, value)}
          dropdownClassName={'antd-filter-dropdown'}
          placeholder={definition.placeholder || 'Please Enter Value'}
        />
      </div>
    );
  };
  makeTextAreaInput = (name, definition) => {
    // WTF is Input.TextArea the like one antd api that sends me an Event rather than value?!?
    return (
      <div className='form-group col-xs-12' key={`form-${this.props.value.id}-${name}-input`}>
        <label htmlFor={`form-${this.props.value.id}-${name}-input`}>{definition.field_alias}</label>
        <Input.TextArea
          id={`form-${this.props.value.id}-${name}-input`}
          defaultValue={this.props.value[name] || ''}
          onChange={event => this.props.handleChange(name, event.target.value)}
          placeholder={definition.placeholder || 'Please Enter Value'}
        />
      </div>
    );
  };
  makeNumberInput = (name, definition) => {
    return (
      <div className='form-group col-xs-6' key={`form-${this.props.value.id}-${name}-input`}>
        <label htmlFor={`form-custom-${name}-input`}>{definition.field_alias}</label>
        <InputNumber
          id={`form-${this.props.value.id}-${name}-input`}
          defaultValue={this.props.value[name] || ''}
          min={definition && definition.schema && definition.schema.minimum}
          max={definition && definition.schema && definition.schema.maximum}
          className='form-control'
          onChange={value => this.props.handleChange(name, value)}
          placeholder={definition.placeholder || 'Please Enter Value'}
          style={{ width: '100%' }}
        />
      </div>
    );
  };
  makeBooleanInput = (name, definition) => {
    return (
      <div className='form-group col-xs-6' key={`form-${this.props.value.id}-${name}-input`}>
        <label htmlFor={`form-${this.props.value.id}-${name}-input`}>{definition.field_alias}</label>
        <AntdSelect
          id={`form-${this.props.value.id}-${name}-input`}
          options={[
            { label: 'null', value: null },
            { label: 'true', value: true },
            { label: 'false', value: false }
          ]}
          defaultValue={this.props.value[name]}
          className='form-control'
          onChange={value => this.props.handleChange(name, value)}
          dropdownClassName={'antd-filter-dropdown'}
          placeholder={definition.placeholder || 'Please Enter Value'}
        />
      </div>
    );
  };
  render() {
    let formInputs = [
      /*Id Stuff*/
      ...(this.props.hasSuperUserPermission
        ? [
            //this.makeNumberInput('id', { field_name: 'id', field_alias: 'Id' }),
            this.makeNumberInput('page_id', { field_name: 'page_id', field_alias: 'Page Id' }),
            this.makeStringInput('filter_param', { field_name: 'Filter Param', field_alias: 'Filter Param' })
          ]
        : []),
      /*Visual*/
      this.makeStringInput('name', {
        field_name: 'name',
        field_alias: 'Name',
        placeholder: 'Please enter visualization name'
      }),
      this.makeStringInput('x_title', {
        field_name: 'x_title',
        field_alias: 'X Title',
        placeholder: 'Please enter axis label'
      }),
      this.makeStringInput('y_title', {
        field_name: 'y_title',
        field_alias: 'Y Title',
        placeholder: 'Please enter axis label'
      }),
      this.makeStringInput('z_title', {
        field_name: 'z_title',
        field_alias: 'Z Title',
        placeholder: 'Please enter axis label'
      }),
      this.makeStringInput('chart_title', {
        field_name: 'chart_title',
        field_alias: 'Chart Title',
        placeholder: 'Please enter chart title'
      }),
      this.makeNumberInput('column_size', {
        field_name: 'column_size',
        field_alias: 'Bootstrap Column Width',
        placeholder: 'Please enter visualization width (Integer between 1 and 12)'
      }),
      this.makeNumberInput('show_order', {
        field_name: 'show_order',
        field_alias: 'Show Order',
        placeholder: 'Please enter relative priority for chart placement'
      }),
      this.makeNumberInput('height', {
        field_name: 'height',
        field_alias: 'Height',
        placeholder: 'Enter height (in number of pixels)'
      }),
      this.makeStringInput('header_style', {
        field_name: 'header_style',
        field_alias: 'Header Style',
        placeholder: 'Enter css classname'
      }),
      this.makeStringInput('fill_color', {
        field_name: 'fill_color',
        field_alias: 'Fill Color',
        placeholder: 'Enter hex color'
      }),
      this.makeStringInput('line_type', {
        field_name: 'line_type',
        field_alias: 'Line Type',
        placeholder: 'Enter type of line (Used for line charts)'
      }),
      this.makeBooleanInput('enable_legend', { field_name: 'enable_legend', field_alias: 'Enable Legend' }),
      this.makeNumberInput('columns_shown', {
        field_name: 'columns_shown',
        field_alias: 'Columns Shown',
        placeholder: 'Number of columns to show (for scrolled bar charts)'
      }),
      this.makeBooleanInput('wrap_label', {
        field_name: 'wrap_label',
        field_alias: 'Wrap Label',
        placeholder: 'Set to true to wrap labels'
      }),
      /* SuperUser Only */
      ...(this.props.hasSuperUserPermission
        ? [
            this.makeStringInput('chart_type', { field_name: 'chart_type', field_alias: 'Chart Type' }),
            this.makeBooleanInput('is_enabled', { field_name: 'is_enabled', field_alias: 'Is Enabled' }),
            this.makeBooleanInput('is_orm_query', { field_name: 'is_orm_query', field_alias: 'Is ORM Query' }),
            this.makeStringInput('query_handler', { field_name: 'query_handler', field_alias: 'Query Handler' }),
            this.makeTextAreaInput('query', { field_name: 'query', field_alias: 'Query' }),
            this.makeTextAreaInput('column_definitions', {
              field_name: 'column_definitions',
              field_alias: 'Column Definitions'
            })
          ]
        : [])
    ];

    return (
      <div className='modal fade' id={`visualizationConfigModal-${this.props.value.id}`} role='dialog'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>
                Customize Chart
                {/* Buttons ------------------------------------------------------- */}
                <span className='modal-header-button-section'>
                  {/* Close button */}
                  <span className='modal-header-button'>
                    <i className='glyphicon glyphicon-remove pull-right' data-dismiss='modal' />
                  </span>
                  &nbsp; &nbsp;
                  {/* Duplicate button */}
                  <span className='modal-header-button'>
                    <i className='glyphicon glyphicon-duplicate pull-right' onClick={this.props.handleClone} />
                  </span>
                  &nbsp; &nbsp;
                </span>
              </h4>
            </div>
            <div className='modal-body'>
              <div className='row'>{formInputs}</div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-default' data-dismiss='modal'>
                Close
              </button>
              <button type='button' className='btn btn-default' data-dismiss='modal' onClick={this.props.handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfigDropdown;
