
//spreadsheet generator
import XLSX from 'xlsx';

//filesaver for downloading sheet
import { saveAs }from 'file-saver';

//http requests
import axios from 'axios';

//template build with custom_fields
export const BuildBaseTemplate = (nonCustomFields)  => {
    let arr1 = Array.from(nonCustomFields);
    if(arr1 !== null || undefined){
        console.log('nonCustomFields param if true', nonCustomFields);
        let headerData = [];
        for(let field of Object.values(arr1)){
            headerData.push([field.field_alias])
        }
        return [headerData]
    }
    //if no custom_fields are present
    else {
        console.log('nonCustomFields param if false', nonCustomFields);
        let combined = [...arr1]
        let headerData = [];
        for(let field of Object.values(combined)){
            headerData.push([field.field_alias])
        }
        console.log('headerData', headerData);
        return [headerData]
    }


};

//for item import
export const BuildItemTemplate = (nonCustomFields, itemCustomFields = {})  => {
    let arr1 = Array.from(nonCustomFields);
    if(arr1 !== null || undefined){
        let arr2 = Array.from(itemCustomFields)
        let combined = [...arr1, ...arr2]
        let headerData = [];
        for(let field of Object.values(combined)){
            headerData.push([field.field_alias])
        }
        return [headerData]
    }
    //if no custom_fields are present
    else {
        let combined = [...arr1]
        let headerData = [];
        for(let field of Object.values(combined)){
            headerData.push([field.field_alias])
        }
        return [headerData]
    }

};
//for bid import
export const BuildBidTemplate = (nonCustomFields, itemCustomFields = {}, bidCustomFields = {} )  => {
    let arr1 = Array.from(nonCustomFields);
    if(arr1 !== null || undefined){
        let arr2 = Array.from(itemCustomFields)
        let arr3 = Array.from(bidCustomFields)
        let combined = [...arr1, ...arr2, ...arr3]
        let headerData = [];
        for(let field of Object.values(combined)){
            headerData.push([field.field_alias])
        }
        return [headerData]
    }
    //if no custom_fields are present
    else {
        let combined = [...arr1]
        let headerData = [];
        for(let field of Object.values(combined)){
            headerData.push([field.field_alias])
        }
        return [headerData]
    }

};
//for freight_bid import
export const BuildFreightBidTemplate = (nonCustomFields, freightBidCustomField = {} )  => {
    let arr1 = Array.from(nonCustomFields);
    if(arr1 !== null || undefined){
        let arr2 = Array.from(freightBidCustomField )
        let combined = [...arr1, ...arr2]
        let headerData = [];
        for(let field of Object.values(combined)){
            headerData.push([field.field_alias])
        }
        return [headerData]
    }
    //if no custom_fields are present
    else {
        let combined = [...arr1]
        let headerData = [];
        for(let field of Object.values(combined)){
            headerData.push([field.field_alias])
        }
        return [headerData]
    }

};

export const FormBuild = (headers) => {
    console.log(headers)
    var wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "import_data",
        Subject: "item_import_template"
    };
    wb.SheetNames.push("import_form");

    var ws_data = headers;
    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["import_form"] = ws;

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    //convert binary to octet
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }
    function saveSheet() {
        console.log("Downloading Sheet");
        saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'import_form.xlsx');
    }
    saveSheet()
}

//item_level custom_fields
export const GetItemCustomFields = (projectId, tableName) => {
    return axios
        .get(`/microservices/v1/projects/${projectId}/items/item_custom_form`, { params: {} })
        .then(response => {
            console.log(response.data.custom_fields_list)
            if(response.data.custom_fields_list !== null || undefined){
                let itemCustomFields = [];
                Object.entries( response.data.custom_fields_list).map(([key, value]) => {
                    itemCustomFields.push({field_alias: value.field_alias})
                })
                console.log(itemCustomFields)
                return itemCustomFields
            } else {
                let itemCustomFields = {};
                return itemCustomFields
            }

        })
        .catch(error => {
        console.log(error);
        });
}

//bid_level custom fields
export const GetBidCustomFields = (projectId, tableName) => {
    return axios
        .get(`/microservices/v1/projects/${projectId}/bids/bid_custom_form`, { params: {} })
        .then(response => {
            console.log(response.data.custom_fields_list)
            if(response.data.custom_fields_list !== null || undefined){
                let bidCustomFields = [];
                Object.entries( response.data.custom_fields_list).map(([key, value]) => {
                    bidCustomFields.push({field_alias: value.field_alias})
                })
                console.log(bidCustomFields)
                return bidCustomFields
            } else {
                let bidCustomFields = {};
                return bidCustomFields
            }

        })
        .catch(error => {
        console.log(error);
        });
}



//constraint template


export const BuildConstraintTemplate = (nonCustomFields) => {
    let arr1 = Array.from(nonCustomFields);
        let combined = [...arr1]
        let headerData = [];
        for(let field of Object.values(combined)){
            headerData.push([field.field_alias])
        }
        return [headerData]

}

//freight_bid_level custom fields
export const GetFreightBidCustomFields = (projectId, tableName) => {
    return axios
        .get(`/microservices/v1/projects/${projectId}/freight/freight_bid_custom_form`, { params: {} })
        .then(response => {
            console.log(response.data.custom_fields_list)
            if(response.data.custom_fields_list !== null || undefined){
                let freightBidCustomFields = [];
                Object.entries( response.data.custom_fields_list).map(([key, value]) => {
                    freightBidCustomFields.push({field_alias: value.field_alias})
                })
                console.log(freightBidCustomFields)
                return freightBidCustomFields
            } else {
                let bidCustomFields = {};
                return bidCustomFields
            }

        })
        .catch(error => {
        console.log(error);
        });

}
