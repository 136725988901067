import React from "react"
import PropTypes from "prop-types"

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from "../HCGlobal"

import Highcharts from 'highcharts';

//basic barchart options.. not using react library. Highcharts already loaded in asset pipeline
class LineChart extends React.Component {

	//fires on initial mount
	componentDidMount() {
		setHighChartsGlobals();
		this.loadChart();
	}

	// //mount data ON CHANGE
	componentDidUpdate (prevProps, prevState) {
		if(prevProps.lineData[0] !== this.props.lineData[0]) {
			setHighChartsGlobals();
			this.loadChart();
		}//end if statement state check
	}//end component did update

	//load chart function with options and cart drawer
	//issue with separating options and chart drawer.. probably something to do with it being a js drawer.. and maybe an async issue
	loadChart = () => {
 		let seriesObj = []
 		this.props.lineData.forEach(function (data_obj, index) {
			console.log(index);
 			seriesObj.push({allowPointSelect:true, data: data_obj, color: '#42A5F5'});
 		});

		//in SQL in order to use 2 series.. select distinct dates from all series, then dense rank them to get the x value to put into the series.
		//run an sql query for every data series, then feed them into the lineData Object
		//example data series with 2 series..
		// seriesObj = [
		// 	{
		// 		// name: "series a",
		// 		allowPointSelect: true,
		// 		//example data
		// 		data: [{"name":"2018-01","y":490037843.0,"x":201801},{"name":"2018-02","y":708813420.0,"x":201802}]
		// 	},
		// 	{
		// 		// name: "b ser",
		// 		allowPointSelect: true,
		// 		//example data
		// 		data: [{"name":"2018-01","y":590037843.0,"x":201801},{"name":"2019-01","y":208813420.0,"x":201901}]
		// 		, color: '#42A5F5'
		// 	}
		// ]

		var options = {
      chart: {
				//can send in 'line' or 'area' (filled in bg)
				type: this.props.lineType
      },
      subtitle: {
				text: this.props.lineTitle
      },
      title: {
				text: null
      },
			xAxis: {
				type: "category",
				lineWidth: 0,
				minorGridLineWidth: 0,
				lineColor: 'transparent',
				minorTickLength: 0,
				tickLength: 0
			},
			yAxis: {
				gridLineWidth: 0,
				minorGridLineWidth: 0,
				title:{
					text: this.props.lineYTitle
				}
			},
			series: seriesObj,
			legend: {
				enabled: this.props.enableSeries || false
			},
			plotOptions: {
				series: {
					cursor: 'pointer',
					point: {
						events: {
							click: (e) => {
								//console.log(e.point.name); console.log(e.point.category); console.log(e.point.y);
								//send back the filterParam assigned in props - this names the field being sent
								//send back the e.point.name BUT in react-select format, so the selector could read it right out of mobxState
								console.log(e.point.name);
								this.props.handleFilterFunction(this.props.filterParam, [{label: `${e.point.name}`, value: e.point.name}] );
							}
						}
					}
				}
			}
		}//end options
		//use javascript to render to id.. id name passed via props
		var hcLineData = Highcharts.chart(this.props.renderInDivId, options);
	}//end loadChart

	render() {
		return (
			<div id={this.props.renderInDivId} style={{height: this.props.height}}></div>
		);
	}
}

export default LineChart
