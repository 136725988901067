import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from "../../../../badges/Badge";
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns
import ProjectName from "../../shared/ProjectName";

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//highcharts
import CustomChartRenderer from '../../../../charts/custom/CovidChartRenderer';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { zeroNull, extractToken, deleteIfEmpty } from '../../../../services/Utilities';
import {
  processingFunctions,
  formatFiltersForServer
} from '../../../../services/filter_helpers';

const _ = require('lodash'); //extra utilities


// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class OptimizationRanks extends React.Component {
  // Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
  projectId = extractToken(window.location.pathname, '/', 3);
  scenarioId = extractToken(window.location.pathname, '/', 5);
  modelName = 'bid';
  mobxState = {
    cardData: {
      itemCount: 0,
      bidCount: 0,
      supplierCount: 0,
      scenarioNetAmount: 0,
      scenarioBidCount: 0,
      scenarioItemCount: 0,
      scenarioNetAmount: 0,
      scenarioNetSavings: 0,
      scenarioNetBaselinePrice: 0
    },
    scenarioInfo: '',
    tableData: {
      bids: []
    },
    chartRenderData: [],
    discounts: [],
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    renderingHold: true,
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    //filters
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    filterModelNames: [], //system filters shown and that options are requested for
    customFilterModelNames: [], //custom filters shown and that options are requested for
    filterOptions: {},  // Holds options for system filters
    customFilterOptions: {}, // Holds options for custom filters
    customOptions: {
      // supplier: 'Supplier',
      // bid__price: 'Price',
      // bid__item_id: 'Item ID',
      // bid__supplier_id: 'Supplier ID',
      // bid__quantity_supplied: 'Quantity Supplied',
      // item__name: 'Item Name',
      // supplier__name: 'Supplier',
      // round: 'Round',
      // price: 'Price'
    }, // Holds aliases used for filter labels
    filtersForServer: {}, // Filters in normalized form to use communicating with the server
    customSchemaRowCount: null, //default amount of rows from 'custom_schemas' table
    isLoadingTableData: false, //fired off when show enteries is selected
    scenarioSelectOptions: [], //scenario dropdown
    selectedScenario: null,
  };


	//mount data through http requests
	componentDidMount() {
		// //call the data loader to load data into the view
		// this.fetchData();
    //get the grid grow count then call the data loader to load data into the view
    this.getCustomRowCount();
	}

	//////////////////HTTP/////////////////
  getCustomRowCount = () => {
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
      .then(response => {
        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
        this.fetchData()
      })
      .catch(console.log.bind(console)); //error catcher
  }

  setupFilters = async () => {
    const pageId = 13; // CHANGE THIS FOR OTHER PAGES
    const urls = {
      system: '/v1/system_filters',
      systemOptions: `/v1/projects/${this.projectId}/scenarios/${this.scenarioId}/optimization_results/system_field_options`,
      custom: '/v1/custom_filters_formatted',
      customOptions: `/v1/projects/${this.projectId}/scenarios/${this.scenarioId}/optimization_results/custom_field_options`,
      systemOptionsSearchUrl: `/v1/projects/${this.projectId}/scenarios/${this.scenarioId}/optimization_results/system_field_options`,
      customOptionsSearchUrl: `/v1/projects/${this.projectId}/scenarios/${this.scenarioId}/optimization_results/custom_field_options`,
    };
    const [systemFilterResp, customFilterResp] = await Promise.all([
      axios.get(urls.system, {
        params: processingFunctions.system.filters.formatParams({
          enabled: true,
          page_id: pageId,
          project_id: this.projectId
        })
      }),
      axios.get(urls.custom, {
        params: processingFunctions.custom.filters.formatParams({
          page_id: pageId,
          enabled: true,
          project_id: this.projectId
        })
      })
    ]);

    let systemFilters = processingFunctions.system.filters.formatResp(
      systemFilterResp
    );
    let customFilters = processingFunctions.custom.filters.formatResp(
      customFilterResp
    );

    // Handle System Filters First
    let systemFilterModelNames = [];
    let systemFilterOptions = {};
    let systemFilterCustomOptions = {};
    let systemFilterWidgetTypes = {};

    systemFilters
      .filter(filter => filter.enabled)
      .forEach(filter => {
        let { name, widget_type } = filter; // Name of filter in the table, usually this is the column name of the sequelize model as well
        systemFilterModelNames.push(name); // Construct Select
        systemFilterWidgetTypes[name] = widget_type;
      });

    // Get options (name-label format) for our selects in model-nested format
    // then convert it to name-label format
    systemFilterOptions = await axios
      .post(
        urls.systemOptions,
        processingFunctions.system.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          system_fields: systemFilterModelNames
        })
      )
      .then(processingFunctions.system.options.formatResp);

    // Then Handle Custom Filters
    let customFilterModelNames = [];
    let customFilterOptions = {};
    let customFilterCustomOptions = {};
    let customFilterWidgetTypes = {};

    // Manipulate custom filters into a format where they can be handled
    // by the constructors for our filter panel
    customFilters.forEach(filter => {
      let [name, data] = _.toPairs(filter)[0]; // _.toPairs splits object into list of [key, value] pairs
      customFilterModelNames.push(name);
      customFilterCustomOptions[name] = data.field_alias; // Construct the filter
      customFilterWidgetTypes[name] = data.widget_type;
    });

    customFilterOptions = await axios
      .post(
        urls.customOptions,
        processingFunctions.custom.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          custom_fields: customFilterModelNames
        })
      )
      .then(processingFunctions.custom.options.formatResp);

    // ------------------------------------------------------------
    // Finally Setup The Filters
    // Use the current filter generation that gets triggered when filterModelNames change
    this.mobxState.filterModelNames = systemFilterModelNames;
    this.mobxState.customFilterModelNames = customFilterModelNames;

    this.mobxState.filterOptions = systemFilterOptions;
    this.mobxState.customFilterOptions = customFilterOptions;

    this.mobxState.filterWidgetTypes = systemFilterWidgetTypes;
    this.mobxState.customFilterWidgetTypes = customFilterWidgetTypes;

    this.mobxState.customOptions = {
      ...this.mobxState.customOptions,
      ...systemFilterCustomOptions,
      ...customFilterCustomOptions
    };
    this.mobxState.optionSearchFunctions = processingFunctions.general.makeSearchFunctions({
      system_filters: systemFilters,
      custom_filters: customFilters,
      system_url: urls.systemOptionsSearchUrl,
      custom_url: urls.customOptionsSearchUrl,
      selected_filters: this.mobxState.filtersForServer,
      system_fields: systemFilterModelNames,
      custom_fields: customFilterModelNames
    });
  };

  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();

    //merge the filter model with the amount of rows selected
    this.setupFilters();

    //merge the filter model with the amount of rows selected
    let models_with_rows = {
      ...this.mobxState.filtersForServer,
      ...{ table_rows: table_rows }
    };

    console.log('MODELS WITH ROWS:', models_with_rows);

    //analytics data load
    axios.post('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/optimization_results/analytics', models_with_rows).then(response => {
      this.mobxState.cardData = response.data.optimization_result_analytics;
      console.log('ANALYTICS', this.mobxState.cardData);
    });

    //scenario info load
    axios.get('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId).then(response => {
      this.mobxState.scenarioInfo = response.data;
    });

    //scenario dropdown load
    //scenario input box autofill suggestions
    axios.get('/v1/projects/'+this.projectId+'/scenarios/index_filter_completed')
    .then(response => {
      this.mobxState.scenarioSelectOptions = response.data.scenarios;
    })

    //load custom chart data for this page (page_id 13)
    axios
      .post('/v1/visualization_client_configs_index', {
        page_id: 15,
        is_orm_query: true,
        selected_models: this.mobxState.filtersForServer,
        req_params: {scenario_id: this.scenarioId, project_id: this.projectId}
      })
      .then(response => {
        this.mobxState.chartRenderData = response.data.visualizations;
      });

    //set up custom options for components, then get data for components
    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}), // Prefer existing values
      }
    });

    axios
      .get('/v1/projects/' + this.projectId + '/project_field_schemas', {
        params: { project_id: this.projectId }
      })
      .then(response => {
        //get map of field labels from API
        this.mobxState.customOptions = {
          ...this.mobxState.customOptions,
          ...(response.data || {})
        };

        //after ag grid is set up, get the rest of the data
        this.mobxState.isLoadingTableData = true //loading project rows
        //table data load
        axios.post('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/optimization_results/optimization_ranks_index', models_with_rows).then(response => {
          this.mobxState.tableData = response.data;
          this.mobxState.isLoadingTableData = false;  //done loading project rows
          //null out the customSchemaRowCount
          this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
          //set the column definitions for ag grid, look into buildColumnDefs class to see options
          this.mobxState.columnDefsOptimizationRanks = gridColumns.buildColumnDefs({
            customOptions: this.mobxState.customOptions,
            columnArray: ['optimization_ranks'],
            customFieldSchema: this.mobxState.customOptions.project_field_schemas[0],
            dataType: 'optimization_rank' //special tag to signify that this is project data
          });
        });

        //autofit the table columns (hits the child function), this runs after the columns were created
        //this.autofitChildToColumnWidth();
      })
      .catch(console.log.bind(console)); //error catcher

    //custom pivot configurations load
    axios.get('/v1/resources/index_filter', { params: { page_id: 15, project_id: this.projectId || null } }).then(response => {
      this.mobxState.pivotFilter = response.data.resources;
    });

    //discounts load
    axios.get('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/optimization_discounts').then(response => {
      console.log(response.data.optimization_discounts);
      this.mobxState.discounts = response.data.optimization_discounts;
      console.log('Discounts', this.mobxState.discounts);
      //set the column definitions for ag grid, look into buildColumnDefs class to see options
      this.mobxState.columnDefsOptimizationDiscounts = gridColumns.buildColumnDefs({
        customOptions: this.mobxState.customOptions,
        columnArray: ['optimization_discounts'],
        customFieldSchema: {},
        dataType: 'optimization_discount' //special tag to signify that this is project data
      });
      //autofit the table columns (hits the child function), this runs after the columns were created
      //only run if array not empty because the discount grid is hidden if empty
      //if (this.mobxState.discounts.length > 0) {this.autofitChild()}; //has issues because if no table to perform on, then it craps out
    });

  }; //end fetchData()

  deleteRequest = toSendArr => {
    console.log('IN DELETION');
    axios
      .post('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/optimization_results/batch_destroy', { id_set: toSendArr })
      .then(response => {
				console.log(response);
				this.fetchData();
	    });
  };
		//////////////////END HTTP/////////////////

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    // In the state, pick the model based on filter that is sent and turn it into the array of values of the filter.
    // This goes as params to http request
    // Update state with selected options, removing the property if none selected
    // Equivalent to this.mobxState[filterParam] = selectedFilter.map(a => a.value)
    // but handles nested assignment
    this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, filterParam);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    if (_.isEmpty(this.mobxState.selectedOptions[filterParam])) {
      delete this.mobxState.selectedOptions[selectedFilter];
    }
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);

    // Update stored filters
    localStorage.setItem('selectedOptions', JSON.stringify(this.mobxState.selectedOptions));
    localStorage.setItem('filtersForServer', JSON.stringify(this.mobxState.filtersForServer));
    localStorage.setItem('selectedModels', JSON.stringify(this.mobxState.selectedModels));
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  handleChartFilter = (filterParam, selectedFilter) => {
    // KLUDGE : If the filterParam begins with 'custom_fields.' then it should be treated as if
    // a custom_filter was selected
    if (filterParam.indexOf('custom_fields.') !== -1) {
      return this.handleCustomFilter(filterParam.replace(/custom_fields./, ''), selectedFilter);
    } else {
      return this.handleFilter(filterParam, selectedFilter);
    }
  };

  handleCustomFilter = (filterParam, selectedFilter) => {
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels['custom_fields'] = this.mobxState.selectedModels['custom_fields'] || {};
    this.mobxState.selectedModels['custom_fields'][filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields`);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions['custom_fields'] = this.mobxState.selectedOptions['custom_fields'] || {};
    this.mobxState.selectedOptions.custom_fields[filterParam] = selectedFilter;
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields`);
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);
    // Update stored filters
    localStorage.setItem('selectedOptions', JSON.stringify(this.mobxState.selectedOptions));
    localStorage.setItem('filtersForServer', JSON.stringify(this.mobxState.filtersForServer));
    localStorage.setItem('selectedModels', JSON.stringify(this.mobxState.selectedModels));

    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //handles dropdown and chart filters
  handleClear = () => {
    //in the state, clear the model. This goes as params to http request
    this.mobxState.selectedModels = {};

    // Clear the filters we send to the server
    this.mobxState.filtersForServer = {};

    //also store the explicit cleared selections in the original form. This goes back to the filter as selected values
    //loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
    //from ["supplier", "item"] to {"supplier":[], "item":[]}
    let newSelectedOptions = { custom_fields: {} };
    this.mobxState.filterModelNames.forEach(name => {
      newSelectedOptions[name] = [];
    });
    this.mobxState.customFilterModelNames.forEach(name => {
      newSelectedOptions.custom_fields[name] = [];
    });
    this.mobxState.selectedOptions = newSelectedOptions;
    localStorage.setItem('selectedOptions',  '{}');
    localStorage.setItem('filtersForServer', '{}');
    localStorage.setItem('selectedModels',  '{}');

    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  // Take selected scenario and send user there
  handleScenarioSelect = (e) => {
    const { history } = this.props;
    this.mobxState.selectedScenario = e.value;
    history.push('/sourcing/projects/' + this.projectId + '/scenarios/' + e.value + '/OptimizationRanks'); //moves to bids
  };

  handleGoToScenarios = () => {
    const { history } = this.props;
    history.push('/sourcing/projects/' + this.projectId + '/scenarios'); //moves to bids
  }

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };


  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };


	render() {
		const hcHeight = '300px';
    const scenarioButton =  <span style={{ display: 'inline-block' }}>
                          <button type="button" className="btn btn-primary btn-md" style={{minHeight:'36px', marginTop:'-2px'}}
                            onClick={this.handleGoToScenarios}>
                            <span className="glyphicon glyphicon-play-circle"></span> Optimize More Scenarios
                          </button>
                        </span>;

		return (
			<div>
				{/* Small Left Toggle */}
        <div onClick={this.toggleDock} className='sidebar_button filter_toggle'>
          <i className='glyphicon glyphicon-chevron-left'></i>
        </div>

				<div className="jumbotron jumbotron_full_width">
					<div className="jumbotron_full_width_container">
						<div className="row">
							<div className="col-sm-5">
								<span className="fa-layers fa-fw fa-2x">
									<FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#009C9C" style={{marginTop: '4px'}} />
									<FontAwesomeIcon icon={['fal', 'tags']} color="white" transform="shrink-5" style={{marginTop: '4px'}} />
								</span>
								&nbsp;
								<b className="dash_header">{this.mobxState.scenarioInfo.name} bid rank report </b> {/* Uses ProjectName Module to display project name */}
							</div>
							<div className="col-sm-1">
							</div>
              <div className="col-sm-1">
              </div>

              {this.mobxState.scenarioSelectOptions.length > 1 &&
  							<div className="col-sm-5" style={{textAlign: 'right'}}>
  							  <FontAwesomeIcon icon={['fad', 'location-arrow']} color="#53C8C5" transform='grow-15 left-10' style={{}} />
                  &nbsp;
                  <span style={{ display: 'inline-block', width: '300px' }}>
                    <Select
                      onChange={(e) => this.handleScenarioSelect(e)}
                      options={this.mobxState.scenarioSelectOptions}
                      closeMenuOnSelect={true}
                      maxMenuHeight = {400}
                      placeholder={'View another rank report'}
                      className='text-left'
                    />
                  </span>
                  &nbsp;
                  {scenarioButton}
                </div>
              }
              {this.mobxState.scenarioSelectOptions.length < 2 &&
                <div className="col-sm-5 gps">
                  <FontAwesomeIcon icon={['fad', 'location-arrow']} color="#53C8C5" transform='grow-15 left-10' style={{}} />
                  &nbsp;
                  {scenarioButton}
                </div>
              }

						</div>
					</div>
				</div>

				{/* BADGES */}
        <div className="row">
          <div className="col-lg-12">
            <div className="badge-container">
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.scenarioNetAmount
              ).toLocaleString('en-US')}
                badgeName={'Scenario Amount'}
                faClass={'check-circle'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.scenarioNetBaselinePrice
              ).toLocaleString('en-US')}
                badgeName={'Baseline Cost'}
                faClass={'check-circle'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.scenarioNetSavings
              ).toLocaleString('en-US')}
                badgeName={'Scenario Savings'}
                faClass={'check-circle'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.totalItemCount
              ).toLocaleString('en-US')}
                badgeName={'Items in Project'}
                faClass={'list'}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="badge-container">
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.scenarioSupplierCount
              ).toLocaleString('en-US')}
                badgeName={'Scenario Bidders'}
                faClass={'briefcase'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.totalSupplierCount
              ).toLocaleString('en-US')}
                badgeName={'Baseline Bidders'}
                faClass={'briefcase'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.scenarioBidCount
              ).toLocaleString('en-US')}
                badgeName={'Bids in Scenario'}
                faClass={'list'}
              />
              <Badge
              badgeNumber={zeroNull(
                this.mobxState.cardData.scenarioItemCount
              ).toLocaleString('en-US')}
                badgeName={'Items in Scenario'}
                faClass={'list'}
              />
            </div>
          </div>
        </div>


        {/* SUMMARY CHARTS */}

        <div className="row">
          <CustomChartRenderer
            handleFilter={this.handleChartFilter}
            toggleDock={this.toggleDock}
            handleClear={this.handleClear}
            chartRenderData={this.mobxState.chartRenderData}
          />
        </div>

        {/* END ROW */}
        {/* END SUMMARY CHARTS */}

        {/* DISCOUNT GRID */}
        { this.mobxState.discounts.length > 0 &&
          <div className='row'>
            <br/>
            <div className='col-lg-12'>
              {/* AG Grid */}
              <GridRenderer
                gridHeight={'150px'}
                columnDefs={this.mobxState.columnDefsOptimizationDiscounts}
                rowData={this.mobxState.discounts}
                // fetchData={this.fetchData}
                // handleClear={this.handleClear}
                toggleDock={this.toggleDock}
                pageId={15}
                // savedState={this.mobxState.pivotState}
                isPivotMode={false}
                gridTitle={'Applied Discounts'}
                setClick={click => (this.clickChild = click)}
                // deleteRequest={this.deleteRequest}
                idColumn={"id"}

                // modelsToUpdateArr={[{url:'v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/optimization_discounts/', idName:"id"}]}
                //modelsToUpdateArr={false}
                enableSetResizeChild={true}
                setResize={click => this.autofitChild = click}
                // enableSetResizeChildColumns={true}
                // setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

                singleClickEdit={false}
                suppressClickEdit={false}
                isSaveable={false}
                isAwardable={false}
                isDeletable={false}
              />
            </div>
          </div>
        }
        {/* END DISCOUNT GRID ROW */}


				<br/>
				{/* Select Filter allows you to select the pivot_settings config to display*/}
        <div className='row'>
          <div className='col-sm-12'>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
								<b>Saved Project Analysis Configurations</b>
							</div>
              <div className='panel-body'>
								{/* NEED TO GIVE THIS PROPER DATA AND DEFAULT VALUES*/}
									<Select
                    onChange={e => this.clickChild(e)}
										options={this.mobxState.pivotFilter}
										closeMenuOnSelect={true}
										maxMenuHeight = {400}
										placeholder={'Select From List of Saved Project Tables'}
									/>
							</div>
						</div>
					</div>
				</div>
				{/* RESULT GRID */}
        <div className='row'>
          <div className='col-lg-12'>
						{/* AG Grid */}
						<GridRenderer
              gridHeight={'700px'}
							columnDefs={this.mobxState.columnDefsOptimizationRanks}
							rowData={this.mobxState.tableData.optimization_results}
							fetchData={this.fetchData}
							handleClear={this.handleClear}
							toggleDock={this.toggleDock}
							pageId={15}
							savedState={this.mobxState.pivotState}
							isPivotMode={true}
              gridTitle={'Scenario Bid Price Rankings'}
              setClick={click => (this.clickChild = click)}
							deleteRequest={this.deleteRequest}
							idColumn={"id"}

							modelsToUpdateArr={[{url:'v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/optimization_results/', idName:"id"}]}
							//modelsToUpdateArr={false}
							// enableSetResizeChild={true}
							// setResize={click => this.autofitChild = click}
							enableSetResizeChildColumns={true}
							setResizeToColumnWidth={click => this.autofitChildToColumnWidth = click}

							singleClickEdit={true}
							suppressClickEdit={false}
							isSaveable={true}
              projectIdNumber={this.projectId}
							isAwardable={false}
							isDeletable={true}
              useCustomSchemaRowCount={true}
              isLoadingTableData={this.mobxState.isLoadingTableData}
						/>
					</div>
        </div>
        {/* END CHART ROW */}

				<br />
        {/* Sidebar */}
        <DockRenderer
          position={'right'}
          size={this.mobxState.dockOptions.size}
          dimMode={'none'}
          isVisible={this.mobxState.dockOptions.isVisible}
          onVisibleChange={this.handleVisibleChange}
          onSizeChange={this.handleSizeChange}
          fluid={true}
          toggleDock={this.toggleDock}
          handleClear={this.handleClear}
          // Current Filters----------------------------------
          value={this.mobxState.selectedOptions}
          // Select Names-------------------------------------
          filterModelNames={this.mobxState.filterModelNames}
          customFilterModelNames={this.mobxState.customFilterModelNames}
          // Widget Types-------------------------------------
          filterWidgetTypes={this.mobxState.filterWidgetTypes}
          customFilterWidgetTypes={this.mobxState.customFilterWidgetTypes}
          // Options------------------------------------------
          options={this.mobxState.filterOptions}
          customFilterOptions={this.mobxState.customFilterOptions}
          // Handlers-----------------------------------------
          handleFilter={this.handleFilter}
          handleCustomFilter={this.handleCustomFilter}
          // Search Functions --------------------------------
          optionSearchFunctions={this.mobxState.optionSearchFunctions}
          // Visual options-----------------------------------
          customOptions={this.mobxState.customOptions}
          // Callbacks----------------------------------------
          closeMenuOnSelect={true}
          maxMenuHeight={400}
        />
      </div>
    ); //end return
	}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(OptimizationRanks, {mobxState: observable});
export default observer(OptimizationRanks);
