import React from 'react';
import moment from 'moment';
//homemade utilities
import {zeroNull, extractToken} from './Utilities';
import {dateFormatter,
        jsonFormatter,
        twoDecimalPaddedFormatter,
        numberParser,
        customDecimalFormatter,
        decimalFormatter,
        threeDecimalFormatter,
        roundFormatter,
        percentFormatter,
        percentDifferenceFormatter,
        ratingFormatter,
        colorScaleFormatter,
        percentThreeDecimalFormatter,
        calculateColorAlongGradient,
        percentAlongScale,
        numericComparator,
        weightedAverageFunctionFactory,
        percentWidth,
        conditionalCellStyler,
        checkboxCellRenderer
      } from './GridColumnFormatters';

//HTTP FACTORY instead of Redux
class GridColumn {

	// Higher order function to call on instantiated class
	//Note the date format here is changed to fit the mssql server, and edits
	buildColumnDefs( optionsObject ) {


		//optionsObject.customOptions, optionsObject.columnArray, optionsObject.projectType = 2, optionsObject.isScenario = 0, optionsObject.timeSettings = {"timeFormat":"","dateFormat":"YYYY-MM-DD","hoursFromDbTime":0, "timeZone":""}
		//builder function
		function columnLogic( optionsObject ) {
			//optionsObject.customOptions, optionsObject.columnArray, optionsObject.projectType = 2, optionsObject.isScenario = 0, optionsObject.timeSettings = {"timeFormat":"","dateFormat":"YYYY-MM-DD","hoursFromDbTime":0, "timeZone":""}

			//set defaults
			optionsObject.isScenario = optionsObject.hasOwnProperty('isScenario') ? optionsObject.isScenario : 0;
			optionsObject.projectType = optionsObject.hasOwnProperty('projectType') ? optionsObject.projectType : 2;
			optionsObject.timeSettings = optionsObject.hasOwnProperty('timeSettings') ? optionsObject.timeSettings : {"timeFormat":"","dateFormat":"YYYY-MM-DD","hoursFromDbTime":0, "timeZone":""};
			optionsObject.customFieldSchema = optionsObject.hasOwnProperty('customFieldSchema') ? optionsObject.customFieldSchema : {};
      optionsObject.categoryNum = optionsObject.categoryNum ? optionsObject.categoryNum : 2;

      let project_overview = [
				{field: 'ID', headerName:optionsObject.customOptions.ID, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter},
				{field: 'sourcing_id', headerName:optionsObject.customOptions.Cat_ID, enableRowGroup: true, enablePivot: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, hide: true},
				{field: 'proj_desc', headerName:optionsObject.customOptions.proj_desc, hide: true, enableRowGroup: true, enablePivot: true,
						editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'Cat_Desc', headerName:optionsObject.customOptions.Cat_Desc, enableRowGroup: true, enablePivot: true,
						editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'DateCreated', headerName:optionsObject.customOptions.DateCreated, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
				{field: 'URL_Addr', headerName:optionsObject.customOptions.URL_Addr, hide: true, enableRowGroup: true, enablePivot: true},
				{field: 'Ref_XDate', headerName:optionsObject.customOptions.Ref_XDate, hide: true, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
				{field: 'Annual_Period', headerName:optionsObject.customOptions.Annual_Period, hide: true, enableRowGroup: true, enablePivot: true,
						editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'MostRecent_Period', headerName:optionsObject.customOptions.MostRecent_Period, hide: true, enableRowGroup: true, enablePivot: true,
						editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'RFI_Scoring', headerName:optionsObject.customOptions.RFI_Scoring, hide: true, enableRowGroup: true, enablePivot: true},
				{field: 'Pay_Term', headerName:optionsObject.customOptions.Pay_Term, hide: true, enableRowGroup: true, enablePivot: true,
						editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'Shipment_qty', headerName:optionsObject.customOptions.Con_Title, hide: true, aggFunc: "sum", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter,
						editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'RFI_End_Time', headerName:optionsObject.customOptions.RFI_End_Time, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
				{field: 'RFP_End_Time', headerName:optionsObject.customOptions.RFP_End_Time, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
				{field: 'Base_Currency', headerName:optionsObject.customOptions.Base_Currency, hide: true, enableRowGroup: true, enablePivot: true,
						editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'Mfg_Name', headerName:optionsObject.customOptions.Mfg_Name, hide: true, enableRowGroup: true, enablePivot: true,
						editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'volume', headerName:optionsObject.customOptions.volume, hide: true, aggFunc: "sum", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter,
						editable: true, stopEditingWhenGridLosesFocus: true},
				// {field: 'Cat1', headerName:optionsObject.customOptions.Cat1, enableRowGroup: true, enablePivot: true,
				// 		editable: true, stopEditingWhenGridLosesFocus: true},
				// {field: 'Cat2', headerName:optionsObject.customOptions.Cat2, enableRowGroup: true, enablePivot: true,
				// 		editable: true, stopEditingWhenGridLosesFocus: true},
				// {field: 'Cat3', headerName:optionsObject.customOptions.Cat3, enableRowGroup: true, enablePivot: true,
				// 		editable: true, stopEditingWhenGridLosesFocus: true, hide: true},
				// {field: 'Cat4', headerName:optionsObject.customOptions.Cat4, enableRowGroup: true, enablePivot: true,
				// 		editable: true, stopEditingWhenGridLosesFocus: true, hide: true},
				{field: 'LastMod_Date', headerName:optionsObject.customOptions.LastMod_Date, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
				{field: 'Proj_Type', headerName:optionsObject.customOptions.Proj_Type, hide: true, enableRowGroup: true, enablePivot: true},
				{field: 'ProjGrp', headerName:optionsObject.customOptions.ProjGrp, hide: true, enableRowGroup: true, enablePivot: true},
				{field: 'RoundNo', headerName:optionsObject.customOptions.RoundNo, hide: true, enableRowGroup: true, enablePivot: true},
				{field: 'MultiRound', headerName:optionsObject.customOptions.MultiRound, hide: true, enableRowGroup: true, enablePivot: true}
      ]

      //ADD CATEGORIES BASED ON THE AMOUNT OF CATS GIVEN
      if (optionsObject.categoryNum == 1){
        project_overview.push(
          {field: 'Cat1', headerName:optionsObject.customOptions.Cat1, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true}
        )
      }
      else if (optionsObject.categoryNum == 2){
        project_overview.push(
          {field: 'Cat1', headerName:optionsObject.customOptions.Cat1, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true},
  				{field: 'Cat2', headerName:optionsObject.customOptions.Cat2, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true}
        )
      }
      else if (optionsObject.categoryNum == 3){
        project_overview.push(
          {field: 'Cat1', headerName:optionsObject.customOptions.Cat1, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true},
  				{field: 'Cat2', headerName:optionsObject.customOptions.Cat2, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true},
  				{field: 'Cat3', headerName:optionsObject.customOptions.Cat3, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true}
        )
      }
      else {
        project_overview.push(
          {field: 'Cat1', headerName:optionsObject.customOptions.Cat1, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true},
  				{field: 'Cat2', headerName:optionsObject.customOptions.Cat2, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true},
  				{field: 'Cat3', headerName:optionsObject.customOptions.Cat3, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true},
  				{field: 'Cat4', headerName:optionsObject.customOptions.Cat4, enableRowGroup: true, enablePivot: true,
  						editable: true, stopEditingWhenGridLosesFocus: true}
        )
      }

      //add custom_fields if exist in schema
			//had to alter the logic a little from item level because data comes over differently from the /custom_schemas api than from /project_field_schemas api points
			//this is looking for a special property 'project_data'
			if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'project_overview' ) {
				//the level here is schema_definition, since this is the db field containing the schema.. like item_level is the field for items in /project_field_schemas api
				let sourcingCustomFields = displayCustomFields({propertyLevel: 'schema_definition', nestedFieldName: ''}, optionsObject, false, true, false);
				project_overview = project_overview.concat(sourcingCustomFields);
			};

      let executive_overview_projects = [
          {field: 'ID', headerName:optionsObject.customOptions.ID, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter},
          {field: 'Cat_ID', headerName:optionsObject.customOptions.Cat_ID, enableRowGroup: true, enablePivot: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
          {field: 'proj_desc', headerName:optionsObject.customOptions.proj_desc, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat_Desc', headerName:optionsObject.customOptions.Cat_Desc, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'DateCreated', headerName:optionsObject.customOptions.DateCreated, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'URL_Addr', headerName:optionsObject.customOptions.URL_Addr, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Ref_XDate', headerName:optionsObject.customOptions.Ref_XDate, hide: true, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'Annual_Period', headerName:optionsObject.customOptions.Annual_Period, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'MostRecent_Period', headerName:optionsObject.customOptions.MostRecent_Period, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'RFI_Scoring', headerName:optionsObject.customOptions.RFI_Scoring, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Pay_Term', headerName:optionsObject.customOptions.Pay_Term, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Shipment_qty', headerName:optionsObject.customOptions.Con_Title, hide: true, aggFunc: "sum", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'RFI_End_Time', headerName:optionsObject.customOptions.RFI_End_Time, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'RFP_End_Time', headerName:optionsObject.customOptions.RFP_End_Time, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Base_Currency', headerName:optionsObject.customOptions.Base_Currency, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Mfg_Name', headerName:optionsObject.customOptions.Mfg_Name, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'volume', headerName:optionsObject.customOptions.volume, hide: true, aggFunc: "sum", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat1', headerName:optionsObject.customOptions.Cat1, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat2', headerName:optionsObject.customOptions.Cat2, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat3', headerName:optionsObject.customOptions.Cat3, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat4', headerName:optionsObject.customOptions.Cat4, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'LastMod_Date', headerName:optionsObject.customOptions.LastMod_Date, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'Proj_Type', headerName:optionsObject.customOptions.Proj_Type, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'ProjGrp', headerName:optionsObject.customOptions.ProjGrp, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'RoundNo', headerName:optionsObject.customOptions.RoundNo, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'MultiRound', headerName:optionsObject.customOptions.MultiRound, hide: true, enableRowGroup: true, enablePivot: true}
      ]

      let executive_overview_contracts = [
          {field: 'Con_Title', headerName:optionsObject.customOptions.Con_Title, enableRowGroup: true, enablePivot: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'con_value', headerName:optionsObject.customOptions.con_value, aggFunc: "sum", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Exp_Date', headerName:optionsObject.customOptions.Exp_Date, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true, valueFormatter: dateFormatter},
          {field: 'Cat1', headerName:optionsObject.customOptions.Cat1, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat2', headerName:optionsObject.customOptions.Cat2, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat3', headerName:optionsObject.customOptions.Cat3, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat4', headerName:optionsObject.customOptions.Cat4, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Supp_No', headerName:optionsObject.customOptions.Supp_No, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Con_File', headerName:optionsObject.customOptions.Con_File, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat_ID', headerName:optionsObject.customOptions.Cat_ID, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Effect_Date', headerName:optionsObject.customOptions.Effect_Date, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true, valueFormatter: dateFormatter},
          {field: 'LastMod_Date', headerName:optionsObject.customOptions.LastMod_Date, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'Con_ID', headerName:optionsObject.customOptions.Con_ID, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter},
      ]

      let contract_overview = [
          {field: 'Con_Title', headerName:optionsObject.customOptions.Con_Title, enableRowGroup: true, enablePivot: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'con_value', headerName:optionsObject.customOptions.con_value, aggFunc: "sum", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: decimalFormatter,
              editable: true, stopEditingWhenGridLosesFocus:true},
					{field: 'Supplier', headerName:optionsObject.customOptions.Supplier, enableRowGroup: true, enablePivot: true},
          {field: 'Exp_Date', headerName:optionsObject.customOptions.Exp_Date, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true, valueFormatter: dateFormatter},
          {field: 'Status_Name', headerName:optionsObject.customOptions.Status_Name, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Status_Desc', headerName:optionsObject.customOptions.Status_Desc, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat1', headerName:optionsObject.customOptions.Cat1, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat2', headerName:optionsObject.customOptions.Cat2, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat3', headerName:optionsObject.customOptions.Cat3, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Cat4', headerName:optionsObject.customOptions.Cat4, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Supp_No', headerName:optionsObject.customOptions.Supp_No, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Con_File', headerName:optionsObject.customOptions.Con_File, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus:true},
          {field: 'Cat_ID', headerName:optionsObject.customOptions.Cat_ID, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Effect_Date', headerName:optionsObject.customOptions.Effect_Date, hide: true, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true, valueFormatter: dateFormatter},
          {field: 'LastMod_Date', headerName:optionsObject.customOptions.LastMod_Date, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'Con_ID', headerName:optionsObject.customOptions.Con_ID, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc", valueFormatter: roundFormatter},
      ]

      //initial box check
      //rounding.. number formatters
      let task_overview = [
          {field: 'Request_Title', headerName:optionsObject.customOptions.Request_Title, enableRowGroup: true, enablePivot: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_ID', headerName:optionsObject.customOptions.Tket_ID, aggFunc: "count", enableValue: true, type: "numericColumn", enableRowGroup: true, enablePivot: true},
          {field: 'Status_Name', headerName:optionsObject.customOptions.Status_Name, enableRowGroup: true, enablePivot: true},
          {field: 'released_user_name', headerName:optionsObject.customOptions.released_user_name, enableRowGroup: true, enablePivot: true},
          {field: 'duration_days', headerName:optionsObject.customOptions.duration_days, aggFunc: "avg", enableValue: true, type: "numericColumn", valueFormatter: decimalFormatter},
          {field: 'Temp_ID', headerName:optionsObject.customOptions.Temp_ID, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", enableRowGroup: true, enablePivot: true},
					{field: 'Supplier', headerName:optionsObject.customOptions.Supplier, enableRowGroup: true, enablePivot: true},
					{field: 'Supp_No', headerName:optionsObject.customOptions.Sup_No, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Supp_Rep', headerName:optionsObject.customOptions.Supp_Rep, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Original_Due_Date', headerName:optionsObject.customOptions.Original_Due_Date, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'Due_Date', headerName:optionsObject.customOptions.Due_Date, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true, valueFormatter: dateFormatter},
          {field: 'Revised_Due_Date', headerName:optionsObject.customOptions.Revised_Due_Date, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true, valueFormatter: dateFormatter},
          {field: 'Status_ID', headerName:optionsObject.customOptions.Status_ID, hide: true, enableRowGroup: true, enablePivot: true,},
                              //editable: true, stopEditingWhenGridLosesFocus: true},
          // {field: 'Loc_ID', headerName:optionsObject.customOptions.Loc_ID, hide: true, enableRowGroup: true, enablePivot: true},
          // {field: 'Reason_Code', headerName:optionsObject.customOptions.Reason_Code, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Repeat_Issue', headerName:optionsObject.customOptions.Repeat_Issue, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Created_User', headerName:optionsObject.customOptions.Created_User, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Created_Date', headerName:optionsObject.customOptions.Created_Date, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'Released_User', headerName:optionsObject.customOptions.Released_User, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Released_Date', headerName:optionsObject.customOptions.Released_Date, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true, valueFormatter: dateFormatter},
          {field: 'Answered_User', headerName:optionsObject.customOptions.Answered_User, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Answered_Date', headerName:optionsObject.customOptions.Answered_Date, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'Rejected_User', headerName:optionsObject.customOptions.Rejected_User, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Rejected_Date', headerName:optionsObject.customOptions.Rejected_Date, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'Accepted_User', headerName:optionsObject.customOptions.Accepted_User, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Accepted_Date', headerName:optionsObject.customOptions.Accepted_Date, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true, valueFormatter: dateFormatter},
          {field: 'LastMod_User', headerName:optionsObject.customOptions.LastMod_User, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'LastMod_Date', headerName:optionsObject.customOptions.LastMod_Date, hide: true, enableRowGroup: true, enablePivot: true, valueFormatter: dateFormatter},
          {field: 'UnSaved', headerName:optionsObject.customOptions.UnSaved, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'For_Review_User', headerName:optionsObject.customOptions.For_Review_User, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'For_Review', headerName:optionsObject.customOptions.For_Review, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Org_ID', headerName:optionsObject.customOptions.Org_ID, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'CatLevel_ID', headerName:optionsObject.customOptions.CatLevel_ID, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Tket_Prefix', headerName:optionsObject.customOptions.Tket_Prefix, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Alert_Days', headerName:optionsObject.customOptions.Alert_Days, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Defined_Alert', headerName:optionsObject.customOptions.Defined_Alert, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Interval_Days', headerName:optionsObject.customOptions.Interval_Days, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'created_time', headerName:optionsObject.customOptions.created_time, hide: true, enableRowGroup: true, enablePivot: true},
          {field: 'Tket_A01', headerName:optionsObject.customOptions.Tket_A01, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A02', headerName:optionsObject.customOptions.Tket_A02, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A03', headerName:optionsObject.customOptions.Tket_A03, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A04', headerName:optionsObject.customOptions.Tket_A04, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A05', headerName:optionsObject.customOptions.Tket_A05, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A06', headerName:optionsObject.customOptions.Tket_A06, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A07', headerName:optionsObject.customOptions.Tket_A07, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A08', headerName:optionsObject.customOptions.Tket_A08, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A09', headerName:optionsObject.customOptions.Tket_A09, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A10', headerName:optionsObject.customOptions.Tket_A10, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A11', headerName:optionsObject.customOptions.Tket_A11, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A12', headerName:optionsObject.customOptions.Tket_A12, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A13', headerName:optionsObject.customOptions.Tket_A13, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A14', headerName:optionsObject.customOptions.Tket_A14, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A15', headerName:optionsObject.customOptions.Tket_A15, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A16', headerName:optionsObject.customOptions.Tket_A16, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A17', headerName:optionsObject.customOptions.Tket_A17, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A18', headerName:optionsObject.customOptions.Tket_A18, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A19', headerName:optionsObject.customOptions.Tket_A19, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A20', headerName:optionsObject.customOptions.Tket_A20, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A21', headerName:optionsObject.customOptions.Tket_A21, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A22', headerName:optionsObject.customOptions.Tket_A22, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A23', headerName:optionsObject.customOptions.Tket_A23, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A24', headerName:optionsObject.customOptions.Tket_A24, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A25', headerName:optionsObject.customOptions.Tket_A25, hide: true, enableRowGroup: true, enablePivot: true,
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A26', headerName:optionsObject.customOptions.Tket_A26, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A27', headerName:optionsObject.customOptions.Tket_A27, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A28', headerName:optionsObject.customOptions.Tket_A28, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A29', headerName:optionsObject.customOptions.Tket_A29, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A30', headerName:optionsObject.customOptions.Tket_A30, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A31', headerName:optionsObject.customOptions.Tket_A31, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A32', headerName:optionsObject.customOptions.Tket_A32, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A33', headerName:optionsObject.customOptions.Tket_A33, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A34', headerName:optionsObject.customOptions.Tket_A34, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A35', headerName:optionsObject.customOptions.Tket_A35, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A36', headerName:optionsObject.customOptions.Tket_A36, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A37', headerName:optionsObject.customOptions.Tket_A37, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A38', headerName:optionsObject.customOptions.Tket_A38, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A39', headerName:optionsObject.customOptions.Tket_A39, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A40', headerName:optionsObject.customOptions.Tket_A40, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A41', headerName:optionsObject.customOptions.Tket_A41, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A42', headerName:optionsObject.customOptions.Tket_A42, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A43', headerName:optionsObject.customOptions.Tket_A43, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A44', headerName:optionsObject.customOptions.Tket_A44, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A45', headerName:optionsObject.customOptions.Tket_A45, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A46', headerName:optionsObject.customOptions.Tket_A46, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A47', headerName:optionsObject.customOptions.Tket_A47, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A48', headerName:optionsObject.customOptions.Tket_A48, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A49', headerName:optionsObject.customOptions.Tket_A49, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A50', headerName:optionsObject.customOptions.Tket_A50, hide: true, enableRowGroup: true, enablePivot: true, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N01', enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N01, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N02', enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N02, enableValue: true, type: "numericColumn",
                               editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N03', enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N03, enableValue: true, type: "numericColumn",
                               editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N04', enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N04, enableValue: true, type: "numericColumn",
                               editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N05', enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N05, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N06', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N06, enableValue: true, type: "numericColumn",
                               editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N07', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N07, enableValue: true, type: "numericColumn",
                               editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N08', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N08, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N09', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N09, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N10', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N010, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N11', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N11, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N12', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N12, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N13', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N13, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N14', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N14, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N15', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N15, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N16', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N16, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N17', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N17, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N18', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N18, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N19', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N19, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N20', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N20, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N21', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N21, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N22', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N22, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N23', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N23, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N24', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N24, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N25', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N25, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N26', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N26, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N27', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N27, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N28', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N28, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N29', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N29, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N30', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N30, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N31', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N31, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N32', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N32, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N33', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N33, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N34', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N34, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N35', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N35, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N36', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N36, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N37', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N37, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N38', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N38, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N39', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N39, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N40', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N40, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N41', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N41, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N42', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N42, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N43', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N43, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N44', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N44, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N45', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N45, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N46', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N46, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N47', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N47, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N48', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N48, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N49', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N49, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N50', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N50, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D01', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D01, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D02', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D02, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D03', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D03, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D04', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D04, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D05', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D05, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D06', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D06, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D07', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D07, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D08', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D08, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D09', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D09, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D10', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D10, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D11', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D11, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D12', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D12, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D13', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D13, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D14', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D14, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D15', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D15, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D16', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D16, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D17', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D17, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D18', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D18, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D19', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D19, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D20', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D20, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D21', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D21, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D22', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D22, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D23', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D23, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D24', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D24, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D25', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D25, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D26', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D26, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D27', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D27, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D28', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D28, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D29', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D29, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D30', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D30, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D31', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D31, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D32', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D32, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D33', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D33, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D34', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D34, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D35', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D35, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D36', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D36, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D37', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D37, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D38', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D38, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D39', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D39, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D40', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D40, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D41', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D41, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D42', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D42, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D43', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D43, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D44', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D44, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D45', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D45, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D46', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D46, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D47', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D47, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D48', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D48, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D49', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D49, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D50', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D50, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A51', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A51, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A52', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A52, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A53', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A53, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A54', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A54, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A55', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A55, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A56', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A56, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A57', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A57, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A58', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A58, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A59', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A59, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_A60', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_A60, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N51', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N51, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N52', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N52, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N53', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N53, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N54', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N54, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N55', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N55, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N56', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N56, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N57', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N57, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N58', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N58, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N59', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N59, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_N60', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_N60, enableValue: true, type: "numericColumn",
                              editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D51', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D51, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D52', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D52, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D53', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D53, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D54', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D54, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D55', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D55, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D56', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D56, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D57', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D57, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D58', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D58, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D59', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D59, editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'Tket_D60', hide: true, enableRowGroup: true, enablePivot: true, headerName:optionsObject.customOptions.Tket_D60, editable: true, stopEditingWhenGridLosesFocus: true},
      ]

			let sourcing_projects = [
          {field: 'id', headerName:optionsObject.customOptions.project__id, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc"},
          {field: 'name', headerName:optionsObject.customOptions.project__name, enableRowGroup: true, enablePivot: true,
            headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
            editable: true, stopEditingWhenGridLosesFocus: true,  cellRenderer: function(params) {
							if('data' in params && 'id' in params.data) {
								return '<a href="/sourcing/projects/' + params.data.id + '/bids" target="_blank" class="link_style">'+ params.value +'</a>'}
							else {
								return params.value
							}
				  }},
          //action button fires button to clone project (imported from cloneProjectRenderer)
          {field: 'actions', headerName:optionsObject.customOptions.actions, enableRowGroup: true, enablePivot: true,
            headerCheckboxSelection: false, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: false,
            editable: false, stopEditingWhenGridLosesFocus: true, cellRenderer: 'cloneProjectRenderer',
				  },
					{field: 'description', headerName: optionsObject.customOptions.project__description, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
					{field: 'status', headerName: optionsObject.customOptions.project__status, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
					{field: 'department', headerName: optionsObject.customOptions.project__department, enableRowGroup: true, enablePivot: true,
	            editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'project_type', headerName: optionsObject.customOptions.project__project_type, enableRowGroup: true, enablePivot: true,
              editable: false, stopEditingWhenGridLosesFocus: true},
          {
            field: 'incomplete_project',
            headerName: 'Exclude from Reports',
            enableRowGroup: true,
            enablePivot: false,
            editable: true,
            stopEditingWhenGridLosesFocus: true,
            cellRenderer: 'checkboxRenderer'
          },
          {field: 'created_at', headerName: optionsObject.customOptions.project__created_at, enableRowGroup: true, enablePivot: true,
							valueFormatter: dateFormatter},
					{field: 'updated_at', headerName: optionsObject.customOptions.project__updated_at, hide: true, enableRowGroup: true, enablePivot: true,
							valueFormatter: dateFormatter}
      ]

      console.log('options', optionsObject);
      //add custom_fields if exist in schema
      //had to alter the logic a little from item level because data comes over differently from the /custom_schemas api than from /project_field_schemas api points
      //this is looking for a special property 'project_data'
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'sourcing_project' ) {
        //the level here is schema_definition, since this is the db field containing the schema.. like item_level is the field for items in /project_field_schemas api
        let sourcingCustomFields = displayCustomFields({propertyLevel: 'schema_definition', nestedFieldName: 'project__custom_fields'}, optionsObject, true, false, false);
        sourcing_projects = sourcing_projects.concat(sourcingCustomFields);
      };

      // same as sourcing_projects expect its for the templates grid on the projects page
      let sourcing_projects_templates = [
          {field: 'id', headerName:optionsObject.customOptions.project__id, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc"},
          {field: 'name', headerName:optionsObject.customOptions.project__name, enableRowGroup: true, enablePivot: true, minWidth: 450,
            headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
            editable: true, stopEditingWhenGridLosesFocus: true,  cellRenderer: function(params) {
							if('data' in params && 'id' in params.data) {
								return '<a href="/sourcing/projects/' + params.data.id + '/bids" target="_blank" class="link_style">'+ params.value +'</a>'}
							else {
								return params.value
							}
				  }},
          //action button fires button to clone project (imported from cloneProjectRenderer)
          {field: 'actions', headerName:optionsObject.customOptions.actions, enableRowGroup: true, enablePivot: true,
            headerCheckboxSelection: false, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: false,
            editable: false, stopEditingWhenGridLosesFocus: true, cellRenderer: 'cloneProjectRenderer',
				  },
          //for creating a new project from template
          {field: 'project', headerName:optionsObject.customOptions.actions, enableRowGroup: true, enablePivot: true,
            headerCheckboxSelection: false, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: false,
            editable: false, stopEditingWhenGridLosesFocus: true, cellRenderer: 'projectFromTemplateRenderer',
				  },
					{field: 'description', headerName: optionsObject.customOptions.project__description, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
					{field: 'status', headerName: optionsObject.customOptions.project__status, enableRowGroup: true, enablePivot: true,
              editable: true, stopEditingWhenGridLosesFocus: true},
					{field: 'department', headerName: optionsObject.customOptions.project__department, enableRowGroup: true, enablePivot: true,
	            editable: true, stopEditingWhenGridLosesFocus: true},
          {field: 'project_type', headerName: optionsObject.customOptions.project__project_type, enableRowGroup: true, enablePivot: true,
              editable: false, stopEditingWhenGridLosesFocus: true},
          {field: 'created_at', headerName: optionsObject.customOptions.project__created_at, enableRowGroup: true, enablePivot: true,
							valueFormatter: dateFormatter},
					{field: 'updated_at', headerName: optionsObject.customOptions.project__updated_at, hide: true, enableRowGroup: true, enablePivot: true,
							valueFormatter: dateFormatter}
      ]

			let sourcing_items = [
        {field: 'id', headerName: optionsObject.customOptions.item__id, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc"},
        {field: 'name', headerName: optionsObject.customOptions.item__name, enableRowGroup: true, enablePivot: true,
          headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
          editable: true, stopEditingWhenGridLosesFocus: true},
				{field: 'quantity', type: "numericColumn",  headerName: optionsObject.customOptions.item__quantity,
					editable: true, stopEditingWhenGridLosesFocus:true, valueFormatter: decimalFormatter},
        {field: 'baseline_supplier', headerName: optionsObject.customOptions.item__baseline_supplier, enableRowGroup: true, enablePivot: true,
          editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'baseline_price', type: "numericColumn",  headerName: optionsObject.customOptions.item__baseline_price,
          editable: true, enableValue: true, enablePivot: true, stopEditingWhenGridLosesFocus:true,
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots)
        },
        {
          field: 'baseline_net_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_net_price,
          enableValue: true,
          type: 'numericColumn',
          hide: false,
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {field: 'weight', type: "numericColumn", hide: true, headerName: optionsObject.customOptions.item__weight,
          editable: true, stopEditingWhenGridLosesFocus:true, enableValue: true, enableRowGroup: true, enablePivot: true},
        {field: 'ratio', type: "numericColumn", hide: true, headerName: optionsObject.customOptions.item__ratio,
          editable: true, stopEditingWhenGridLosesFocus:true, enableValue: true, enableRowGroup: true, enablePivot: true},
        {field: 'created_at', headerName: optionsObject.customOptions.item__created_at, enableRowGroup: true, enablePivot: true,
					valueFormatter: dateFormatter},
				{field: 'updated_at', headerName: optionsObject.customOptions.item__updated_at, hide: true, enableRowGroup: true, enablePivot: true,
					valueFormatter: dateFormatter},
				 	//valueGetter: 'rowData.item_groups'}
      ]
			//add item_groups if exist in schema
			if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'sourcing_item') {
				let sourcingItemGroups = displayCustomFields({propertyLevel: 'item_level', nestedFieldName: 'item__custom_fields'}, optionsObject, true, false, false);
				sourcing_items = sourcing_items.concat(sourcingItemGroups);
			};

      let sourcing_bids = [
        {
          field: 'id',
          headerName: optionsObject.customOptions.bid__id,
          hide: true,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc'
        },
        {
          field: 'item_name',
          headerName: optionsObject.customOptions.item__name,
          enableRowGroup: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'supplier_name',
          hide: false,
          headerName: optionsObject.customOptions.supplier__name,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'supplier_id',
          hide: true,
          headerName: optionsObject.customOptions.supplier__id,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__price,
          enableValue: true,
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'net_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__net_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'baseline_supplier',
          headerName: optionsObject.customOptions.item__baseline_supplier,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'baseline_net_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_net_price,
          enableValue: true,
          type: 'numericColumn',
          hide: true,
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'savings',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__savings,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: false,
          hide: true,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'net_savings',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__net_savings,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'item_quantity',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__quantity,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'quantity_supplied',
          hide: true,
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__quantity_supplied,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'round',
          headerName: optionsObject.customOptions.bid__round,
          hide: false,
          type: 'numericColumn',
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true
        },
        {
          field: 'weight',
          type: 'numericColumn',
          hide: true,
          headerName: optionsObject.customOptions.item__weight,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'ratio',
          type: 'numericColumn',
          hide: true,
          headerName: optionsObject.customOptions.item__ratio,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'created_at',
          headerName: optionsObject.customOptions.bid__created_at,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        },
        {
          field: 'updated_at',
          headerName: optionsObject.customOptions.bid__updated_at,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        },
        {
          field: 'custom_fields',
          hide: true,
          headerName: 'Custom Data',
          enableRowGroup: false,
          enablePivot: false,
          pivot: false
        }
      ];

			//add item_groups if exist in schema
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'sourcing_bid') {
        let sourcingItemCustomFields = displayCustomFields(
          { propertyLevel: 'item_level', nestedFieldName: 'item__custom_fields' },
          optionsObject,
          false,
          false,
          false
        );
        sourcing_bids = sourcing_bids.concat(sourcingItemCustomFields);
        let sourcingBidCustomFields = displayCustomFields(
          { propertyLevel: 'bid_level', nestedFieldName: 'bid__custom_fields' },
          optionsObject,
          true,
          false,
          false
        );
        sourcing_bids = sourcing_bids.concat(sourcingBidCustomFields);
      }

      ////Project Bids////
      let project_bids = [
        {
          field: 'id',
          headerName: optionsObject.customOptions.project__id,
          hide: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'name',
          headerName: optionsObject.customOptions.project__name,
          sortable: true,
          editable: false,
          enableRowGroup: true,
          enablePivot: true,
          stopEditingWhenGridLosesFocus: true,
          cellRenderer: function(params) {
            if ('data' in params && 'id' in params.data) {
              return '<a href="/sourcing/projects/' + params.data.id + '/bids" target="_blank" class="link_style">' + params.value + '</a>'
            } else {
              return params.value
            }
          }
        },
        {
          field: 'description',
          headerName: optionsObject.customOptions.project__description,
          hide: true,
          editable: false,
          enableRowGroup: true,
          enablePivot: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'department',
          headerName: optionsObject.customOptions.project__department,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'status',
          headerName: optionsObject.customOptions.project__status,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'project_type',
          headerName: optionsObject.customOptions.project__project_type,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'created_at',
          headerName: optionsObject.customOptions.project__created_at,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
        {
          field: 'item_id',
          headerName: optionsObject.customOptions.item__id,
          hide: true,
          enableRowGroup: false,
          enablePivot: false,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'item_name',
          headerName: optionsObject.customOptions.item__name,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'item_quantity',
          headerName: optionsObject.customOptions.item__quantity,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(0),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'ratio',
          headerName: optionsObject.customOptions.item__ratio,
          hide: true,
          enableRowGroup: false,
          enablePivot: false,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(2),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'weight',
          headerName: optionsObject.customOptions.item__weight,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(0),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_supplier_name',
          headerName: optionsObject.customOptions.item__baseline_supplier,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_price',
          headerName: optionsObject.customOptions.item__baseline_price,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(2),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_net_price',
          headerName: optionsObject.customOptions.item__baseline_net_price,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(2),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'bid_id',
          headerName: optionsObject.customOptions.bid__id,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'bid_supplier_id',
          headerName: optionsObject.customOptions.supplier__id,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'bid_supplier_name',
          headerName: optionsObject.customOptions.supplier__name,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'round',
          headerName: optionsObject.customOptions.bid__round,
          hide: false,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'quantity_supplied',
          headerName: optionsObject.customOptions.bid__quantity_supplied,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(0),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'price',
          headerName: optionsObject.customOptions.bid__price,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(2),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_price',
          headerName: optionsObject.customOptions.bid__net_price,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(2),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'savings',
          headerName: optionsObject.customOptions.bid__savings,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(0),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_savings',
          headerName: optionsObject.customOptions.bid__net_savings,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          editable: false,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(0),
          stopEditingWhenGridLosesFocus: true
        }
      ];

      //add custom_fields if exist in schema
      //had to alter the logic a little from item level because data comes over differently from the /custom_schemas api than from /project_field_schemas api points
      //this is looking for a special property 'project_data'
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'project_bid' ) {
        //the level here is schema_definition, since this is the db field containing the schema.. like item_level is the field for items in /project_field_schemas api
        let projectBidsCustomFields = displayCustomFields({propertyLevel: 'schema_definition', nestedFieldName: 'project_custom_fields'}, optionsObject, true, false, false);
        project_bids = project_bids.concat(projectBidsCustomFields);
      };

      // {
      //   field: 'name',
      //   headerName: optionsObject.customOptions.project__name,
      //   enableRowGroup: true,
      //   enablePivot: true,
      //   cellRenderer: function (params) {
      //     if ('data' in params && 'id' in params.data) {
      //       return (
      //         '<a href="/sourcing/projects/' +
      //         params.data.id +
      //         '/bids" target="_blank" class="link_style">' +
      //         params.value +
      //         '</a>'
      //       );
      //     } else {
      //       return params.value;
      //     }
      //   },
      // },
      let dashboard_projects = [
        {
          field: 'id',
          headerName: optionsObject.customOptions.project__id,
          hide: true,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc',
        },
        {
          field: 'name',
          headerName: optionsObject.customOptions.project__name,
          enableRowGroup: true,
          enablePivot: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          cellRenderer: function(params) {
            if ('data' in params && 'id' in params.data) {
              return '<a href="/sourcing/projects/' + params.data.id + '/bids" target="_blank" class="link_style">' + params.value + '</a>'
            } else {
              return params.value
            }
          }
        },
        {
          field: 'description',
          headerName: optionsObject.customOptions.project__description,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'status',
          headerName: optionsObject.customOptions.project__status,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'department',
          headerName: optionsObject.customOptions.project__department,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'project_type',
          headerName: optionsObject.customOptions.project__project_type,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'created_at',
          headerName: optionsObject.customOptions.project__created_at,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
        {
          field: 'savings',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.project__savings,
          enableValue: true,
          editable: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(0),
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'updated_at',
          headerName: optionsObject.customOptions.project__updated_at,
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
      ];

      //add custom_fields if exist in schema
      //had to alter the logic a little from item level because data comes over differently from the /custom_schemas api than from /project_field_schemas api points
      //this is looking for a special property 'project_data'
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'dashboard_project' ) {
        //the level here is schema_definition, since this is the db field containing the schema.. like item_level is the field for items in /project_field_schemas api
        let dashboardCustomFields = displayCustomFields({propertyLevel: 'schema_definition', nestedFieldName: 'project_custom_fields'}, optionsObject, true, false, false);
        dashboard_projects = dashboard_projects.concat(dashboardCustomFields);
      };

      ////Optimization Results////
      let optimization_results = [
        {
          field: 'id',
          headerName: optionsObject.customOptions.optimization_result__id,
          hide: true,
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc'
        },
        {
          field: 'item_id',
          headerName: optionsObject.customOptions.item__id,
          hide: true,
          enableValue: true,
          type: 'numericColumn',
        },
        {
          field: 'item_name',
          headerName: optionsObject.customOptions.item__name,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'supplier_name',
          hide: false,
          headerName: optionsObject.customOptions.supplier__name,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'supplier_id',
          hide: true,
          headerName: optionsObject.customOptions.supplier__id,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__award,
          enableValue: true,
          aggFunc: 'sum',
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_savings',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__net_savings,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'freight_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__freight_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'raw_freight_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__raw_freight_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'landed_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__landed_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_supplier',
          headerName: optionsObject.customOptions.item__baseline_supplier,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_total',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_net_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'item_award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__item_award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'freight_award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__freight_award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'weight',
          type: 'numericColumn',
          hide: true,
          headerName: optionsObject.customOptions.item__weight,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'ratio',
          type: 'numericColumn',
          hide: true,
          headerName: optionsObject.customOptions.item__ratio,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'discount',
          hide: 'true',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__discount,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'savings',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__savings,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'min_bracket',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__min_bracket,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          type: 'numericColumn',
          valueFormatter: roundFormatter,
          hide: true
        },
        {
          field: 'max_bracket',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__max_bracket,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          type: 'numericColumn',
          valueFormatter: roundFormatter,
          hide: true
        },
        {
          field: 'round',
          headerName: optionsObject.customOptions.bid__round,
          hide: true,
          type: 'numericColumn',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'bid_id',
          headerName: optionsObject.customOptions.bid__id,
          hide: true,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
        },
        {
          field: 'quantity_awarded',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__quantity_awarded,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'quantity_supplied',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__quantity_supplied,
          hide: true,
          //headerName: optionsObject.customOptions.quantity,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'item_quantity',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__quantity,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          hide: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'created_at',
          headerName: 'Optimization Created At',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        },
        {
          field: 'updated_at',
          headerName: 'Optimization Updated At',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        }
      ];

      //add item_groups if exist in schema
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'optimization_result') {
        console.log('INSIDE CUSTOMS');
        let sourcingItemCustomFields = displayCustomFields(
          { propertyLevel: 'item_level', nestedFieldName: 'item__custom_fields' },
          optionsObject,
          false,
          false,
          false
        );
        optimization_results = optimization_results.concat(sourcingItemCustomFields);
        let sourcingBidCustomFields = displayCustomFields(
          { propertyLevel: 'bid_level', nestedFieldName: 'bid__custom_fields' },
          optionsObject,
          false,
          false,
          false
        );
        optimization_results = optimization_results.concat(sourcingBidCustomFields);
        let sourcingFreightBidCustomFields = displayCustomFields(
          { propertyLevel: 'freight_bid_level', nestedFieldName: 'freight_bid__custom_fields' },
          optionsObject,
          false,
          false,
          false
        );
        optimization_results = optimization_results.concat(sourcingFreightBidCustomFields);
        console.log('FIELD LIST', optimization_results);
      }



      ////Optimization Results////
      let optimization_ranks = [
        {
          field: 'id',
          headerName: optionsObject.customOptions.optimization_result__id,
          hide: true,
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc'
        },
        {
          field: 'item_id',
          headerName: optionsObject.customOptions.item__id,
          hide: true,
          enableValue: true,
          type: 'numericColumn',
        },
        {
          field: 'item_name',
          headerName: optionsObject.customOptions.item__name,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'quantity_awarded',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__quantity_awarded,
          editable: true,
          hide: true,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'supplier_name',
          hide: false,
          headerName: optionsObject.customOptions.supplier__name,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'supplier_id',
          hide: true,
          headerName: optionsObject.customOptions.supplier__id,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__award,
          enableValue: true,
          aggFunc: 'sum',
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'freight_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__freight_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'raw_freight_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__raw_freight_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'landed_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__landed_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'item_award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__item_award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'freight_award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__freight_award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'weight',
          type: 'numericColumn',
          hide: true,
          headerName: optionsObject.customOptions.item__weight,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'ratio',
          type: 'numericColumn',
          hide: true,
          headerName: optionsObject.customOptions.item__ratio,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true
        },
        {
          field: 'discount',
          hide: 'true',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__discount,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          editable: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'supplier_name_1',
          hide: false,
          headerName: 'Best '+optionsObject.customOptions.supplier__name,
          enableRowGroup: true,
          enablePivot: true,
          pivot: true,
        },
        {
          field: 'price_1',
          type: 'numericColumn',
          headerName: 'Best '+optionsObject.customOptions.bid__price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'savings_1',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__price + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_savings_1',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__award + ' Δ',
          cellStyle: params => {
                if (params.value > 1000000) {
                    //mark police cells as red
                    return {backgroundColor: '#00C853'};
                }
                else if (params.value > 100000) {
                    //mark police cells as red
                    return {backgroundColor: '#00E676'};
                }
                else if (params.value > 10000) {
                    //mark police cells as red
                    return {backgroundColor: '#69F0AE'};
                }
                else if (params.value > 0) {
                    //mark police cells as red
                    return {backgroundColor: '#B9F6CA'};
                }
                return null;
            },
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'award_1',
          type: 'numericColumn',
          headerName: 'Best '+optionsObject.customOptions.optimization_result__award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'supplier_name_2',
          hide: false,
          headerName: '2nd Best '+optionsObject.customOptions.supplier__name,
          enableRowGroup: true,
          enablePivot: true,
          pivot: true,
        },
        {
          field: 'price_2',
          type: 'numericColumn',
          headerName: '2nd Best '+optionsObject.customOptions.bid__price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'savings_2',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__price + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_savings_2',
          type: 'numericColumn',
          headerName: '2nd '+optionsObject.customOptions.optimization_result__award + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'award_2',
          type: 'numericColumn',
          headerName: '2nd Best '+optionsObject.customOptions.optimization_result__award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'supplier_name_3',
          hide: false,
          headerName: '3rd Best '+optionsObject.customOptions.supplier__name,
          enableRowGroup: true,
          enablePivot: true,
          pivot: true,
        },
        {
          field: 'price_3',
          type: 'numericColumn',
          headerName: '3rd Best '+optionsObject.customOptions.bid__price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'savings_3',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__price + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_savings_3',
          type: 'numericColumn',
          headerName: '3rd '+optionsObject.customOptions.optimization_result__award + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'award_3',
          type: 'numericColumn',
          headerName: '3rd Best '+optionsObject.customOptions.optimization_result__award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'supplier_name_4',
          hide: true,
          headerName: '4th Best '+optionsObject.customOptions.supplier__name,
          enableRowGroup: true,
          enablePivot: true,
          pivot: true,
        },
        {
          field: 'price_4',
          type: 'numericColumn',
          headerName: '4th Best '+optionsObject.customOptions.bid__price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'savings_4',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__price + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_savings_4',
          type: 'numericColumn',
          headerName: '4th '+optionsObject.customOptions.optimization_result__award + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'award_4',
          type: 'numericColumn',
          headerName: '4th Best '+optionsObject.customOptions.optimization_result__award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'supplier_name_5',
          hide: true,
          headerName: '5th Best '+optionsObject.customOptions.supplier__name,
          enableRowGroup: true,
          enablePivot: true,
          pivot: true,
        },
        {
          field: 'price_5',
          type: 'numericColumn',
          headerName: '5th Best '+optionsObject.customOptions.bid__price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'savings_5',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__price + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_savings_5',
          type: 'numericColumn',
          headerName: '5th '+optionsObject.customOptions.optimization_result__award + ' Δ',
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'award_5',
          type: 'numericColumn',
          headerName: '5th Best '+optionsObject.customOptions.optimization_result__award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_supplier',
          headerName: optionsObject.customOptions.item__baseline_supplier,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_total',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_net_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'savings',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__savings,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'net_savings',
          type: 'numericColumn',
          hide: false,
          headerName: optionsObject.customOptions.bid__net_savings,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'min_bracket',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__min_bracket,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          type: 'numericColumn',
          valueFormatter: roundFormatter,
          hide: true
        },
        {
          field: 'max_bracket',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__max_bracket,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          type: 'numericColumn',
          valueFormatter: roundFormatter,
          hide: true
        },
        {
          field: 'round',
          headerName: optionsObject.customOptions.bid__round,
          hide: true,
          type: 'numericColumn',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'bid_id',
          headerName: optionsObject.customOptions.bid__id,
          hide: true,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
        },
        {
          field: 'quantity_supplied',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__quantity_supplied,
          hide: true,
          //headerName: optionsObject.customOptions.quantity,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'item_quantity',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__quantity,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          hide: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'created_at',
          headerName: 'Optimization Created At',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        },
        {
          field: 'updated_at',
          headerName: 'Optimization Updated At',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        },
      ];
      //add item_groups if exist in schema
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'optimization_rank') {
        console.log('INSIDE CUSTOMS');
        let sourcingItemCustomFields = displayCustomFields(
          { propertyLevel: 'item_level', nestedFieldName: 'item__custom_fields' },
          optionsObject,
          false,
          false,
          true
        );
        optimization_ranks = optimization_ranks.concat(sourcingItemCustomFields);
        let sourcingBidCustomFields = displayCustomFields(
          { propertyLevel: 'bid_level', nestedFieldName: 'bid__custom_fields' },
          optionsObject,
          false,
          false,
          true
        );
        optimization_ranks = optimization_ranks.concat(sourcingBidCustomFields);
        let sourcingFreightBidCustomFields = displayCustomFields(
          { propertyLevel: 'freight_bid_level', nestedFieldName: 'freight_bid__custom_fields' },
          optionsObject,
          false,
          false,
          true
        );
        optimization_ranks = optimization_ranks.concat(sourcingFreightBidCustomFields);
        console.log('FIELD LIST', optimization_ranks);
      }


      ////Optimization Results////
      let scenario_comparisons = [
        {
          field: 'scenario_name',
          headerName: 'Scenario',
          enableRowGroup: true,
          enablePivot: true,
          rowGroup: true,
          //pivot: true,
        },
        {
          field: 'id',
          headerName: optionsObject.customOptions.optimization_result__id,
          hide: true,
          //aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc'
        },
        {
          field: 'item_id',
          headerName: optionsObject.customOptions.item__id,
          hide: true,
          //aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
        },
        {
          field: 'item_name',
          headerName: optionsObject.customOptions.item__name,
          enableRowGroup: true,
          enablePivot: true,
        },
        {
          field: 'supplier_name',
          hide: false,
          headerName: optionsObject.customOptions.supplier__name,
          enableRowGroup: true,
          enablePivot: true,
          pivot: true,
        },
        {
          field: 'supplier_id',
          hide: true,
          headerName: optionsObject.customOptions.supplier__id,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'baseline_total',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_net_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__award,
          enableValue: true,
          aggFunc: 'sum',
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__price,
          enableValue: true,
          //aggFunc: 'avg',
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'net_savings',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__net_savings,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'baseline_supplier',
          headerName: optionsObject.customOptions.item__baseline_supplier,
          enableRowGroup: true,
          enablePivot: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'baseline_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__baseline_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'savings',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.bid__savings,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true,
          valueParser: numberParser
        },
        {
          field: 'freight_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__freight_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'raw_freight_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__raw_freight_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'landed_price',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__landed_price,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'item_award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__item_award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'freight_award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__freight_award,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'min_bracket',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__min_bracket,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          type: 'numericColumn',
          valueFormatter: roundFormatter,
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'max_bracket',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__max_bracket,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          type: 'numericColumn',
          valueFormatter: roundFormatter,
          hide: true,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'round',
          headerName: optionsObject.customOptions.bid__round,
          hide: true,
          type: 'numericColumn',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'discount',
          hide: 'true',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__discount,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.product_decimal_spots),
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'bid_id',
          headerName: optionsObject.customOptions.bid__id,
          hide: true,
          //aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
        },
        {
          field: 'quantity_awarded',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.optimization_result__quantity_awarded,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'quantity_supplied',
          type: 'numericColumn',
          hide: true,
          headerName: optionsObject.customOptions.bid__quantity_supplied,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'item_quantity',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.item__quantity,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableValue: true,
          type: 'numericColumn',
          valueFormatter: decimalFormatter
        },
        {
          field: 'created_at',
          headerName: 'Optimization Created At',
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        },
        {
          field: 'updated_at',
          headerName: 'Optimization Updated At',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        }
      ];

      //add item_groups if exist in schema
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'scenario_comparison') {
        let sourcingItemCustomFields = displayCustomFields(
          { propertyLevel: 'item_level', nestedFieldName: 'item__custom_fields' },
          optionsObject,
          false,
          false,
          false
        );
        scenario_comparisons = scenario_comparisons.concat(sourcingItemCustomFields);
        let sourcingBidCustomFields = displayCustomFields(
          { propertyLevel: 'bid_level', nestedFieldName: 'bid__custom_fields' },
          optionsObject,
          false,
          false,
          false
        );
        scenario_comparisons = scenario_comparisons.concat(sourcingBidCustomFields);
        let sourcingFreightBidCustomFields = displayCustomFields(
          { propertyLevel: 'freight_bid_level', nestedFieldName: 'freight_bid__custom_fields' },
          optionsObject,
          false,
          false,
          false
        );
        scenario_comparisons = scenario_comparisons.concat(sourcingFreightBidCustomFields);
      }

			/////Freight Bids//////
			let freight_bids = [
			  {
			    field: 'id',
			    headerName: optionsObject.customOptions.freight_bid__id,
			    hide: true,
			    aggFunc: 'count',
			    enableValue: true,
			    type: 'numericColumn',
			    sort: 'desc'
			  },
			  {
			    field: 'supplier_name',
			    hide: false,
			    headerName: optionsObject.customOptions.supplier__name,
          headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
			    editable: false,
			    stopEditingWhenGridLosesFocus: true,
					enableRowGroup: true,
					enablePivot: true
			  },
        {
          field: 'item_custom_field',
          hide: false,
          headerName: optionsObject.customOptions.freight_bid__item_custom_field,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true
        },
			  {
			    field: 'supplier_id',
			    hide: true,
			    headerName: optionsObject.customOptions.supplier__id,
			    editable: false,
			    stopEditingWhenGridLosesFocus: true,
					enableRowGroup: true,
					enablePivot: true,
          enableValue: true,
			  },
			  {
			    field: 'freight_bracket_id',
          hide: true,
			    type: 'numericColumn',
			    headerName: optionsObject.customOptions.freight_bid__freight_bracket_id,
			    enableValue: false,
			    type: 'numericColumn',
			    editable: false,
			    stopEditingWhenGridLosesFocus: true,
					enableRowGroup: true,
					enablePivot: true
			  },
			  {
			    field: 'freight_bracket_name',
			    hide: true,
			    headerName: optionsObject.customOptions.freight_bracket_name,
			    editable: false,
			    stopEditingWhenGridLosesFocus: true,
					enableRowGroup: true,
					enablePivot: true
			  },
			  {
			    field: 'min_bracket',
			    type: 'numericColumn',
			    headerName: 'Min Bracket',//optionsObject.customOptions.min_bracket,
			    enableValue: true,
			    type: 'numericColumn',
			    valueFormatter: roundFormatter,
			    editable: false,
			    stopEditingWhenGridLosesFocus: true
			  },
			  {
			    field: 'max_bracket',
			    type: 'numericColumn',
			    headerName: 'Max Bracket',//optionsObject.customOptions.max_bracket,
			    enableValue: true,
			    type: 'numericColumn',
			    valueFormatter: roundFormatter,
			    editable: false,
			    stopEditingWhenGridLosesFocus: true
			  },
			  {
			    field: 'project_id',
			    type: 'numericColumn',
			    headerName: optionsObject.customOptions.project__id,
			    enableValue: true,
			    type: 'numericColumn',
			    editable: false,
			    stopEditingWhenGridLosesFocus: true,
					enableRowGroup: true,
					enablePivot: true
			  },
			  {
			    field: 'price',
			    type: 'numericColumn',
			    headerName: optionsObject.customOptions.freight_bid__price,
			    enableValue: true,
			    type: 'numericColumn',
			    valueFormatter: customDecimalFormatter(optionsObject.customFieldSchema.freight_decimal_spots),
			    editable: true,
			    stopEditingWhenGridLosesFocus: true
			  },
        {
          field: 'round',
          headerName: optionsObject.customOptions.bid__round,
          hide: false,
          type: 'numericColumn',
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
			  {
			    field: 'created_at',
			    headerName: optionsObject.customOptions.freight_bid__created_at,
			    enableRowGroup: true,
			    enablePivot: true,
			    valueFormatter: dateFormatter,
					enableRowGroup: true,
					enablePivot: true
			  },
			  {
			    field: 'updated_at',
			    headerName: optionsObject.customOptions.freight_bid__updated_at,
			    hide: true,
			    enableRowGroup: true,
			    enablePivot: true,
			    valueFormatter: dateFormatter,
					enableRowGroup: true,
					enablePivot: true
			  },
        {
          field: 'custom_fields',
          hide: true,
          headerName: 'Custom Data',
          enableRowGroup: false,
          enablePivot: false,
          pivot: false,
        },
			];

      //add item_groups if exist in schema
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'freight_bid') {
          console.log();
          let freightCustomFields = displayCustomFields({propertyLevel: 'freight_bid_level', nestedFieldName: 'custom_fields'}, optionsObject, true, false);
          freight_bids = freight_bids.concat(freightCustomFields);
          // let freightItemGroups = displayCustomFields({propertyLevel: 'item_level', nestedFieldName: 'custom_fields'}, optionsObject);
          // freight_items = sourcing_items.concat(freightItemGroups);
      };

      let sourcing_project_suppliers = [
        {
          field: 'id',
          headerName: 'Invite ID',
          hide: true,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc',
        },
        {
          field: 'supplier_name',
          hide: false,
          headerName: optionsObject.customOptions.supplier__name,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'supplier_id',
          hide: true,
          headerName: optionsObject.customOptions.supplier__id,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'project_id',
          hide: true,
          headerName: optionsObject.customOptions.project__id,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'created_at',
          headerName: 'Invited On',
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
        {
          field: 'updated_at',
          headerName: 'Invite Updated On',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
      ];


      let users = [
        {field: 'id', headerName: 'Id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        {field: 'email', headerName: 'Email', enableRowGroup: true, enablePivot: true,
          headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
          editable: true, stopEditingWhenGridLosesFocus: true },
        {field: 'first_name', headerName: 'First Name', enableRowGroup: true, enablePivot: true,
          editable: true, stopEditingWhenGridLosesFocus: true },
        {field: 'last_name', headerName: 'Last Name', enableRowGroup: true, enablePivot: true,
          editable: true, stopEditingWhenGridLosesFocus: true },
        {field: 'created_at', headerName: 'Created At', enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter},
        {field: 'updated_at', headerName: 'Updated At', hide: true, enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter}
      ];

      let global_users = [
        {field: 'id', headerName: 'Id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        {field: 'email', headerName: 'Email', enableRowGroup: true, enablePivot: true,
          headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
          editable: true, stopEditingWhenGridLosesFocus: true },
        {field: 'first_name', headerName: 'First Name', enableRowGroup: true, enablePivot: true,
          editable: true, stopEditingWhenGridLosesFocus: true },
        {field: 'last_name', headerName: 'Last Name', enableRowGroup: true, enablePivot: true,
          editable: true, stopEditingWhenGridLosesFocus: true },
        {field: 'permission', headerName: 'Permission', enableRowGroup: true, enablePivot: true,
          editable: true, stopEditingWhenGridLosesFocus: true },
        {field: 'created_at', headerName: 'Created At', enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter},
        {field: 'updated_at', headerName: 'Updated At', hide: true, enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter}
      ];

      let user_roles = [
        {field: 'id', headerName: 'Id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        {field: 'user_id', headerName: 'User Id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        {field: 'role_id', headerName: 'Role Id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        // {field: 'user_id', headerName: 'User Id', enableRowGroup: true, enablePivot: true,
        //   headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
        //   editable: true, stopEditingWhenGridLosesFocus: true },
        {field: 'role_name', headerName: 'Role', enableRowGroup: true, enablePivot: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
        {field: 'email', headerName: 'email', enableRowGroup: true, enablePivot: true},
        {field: 'first_name', headerName: 'First Name', enableRowGroup: true, enablePivot: true},
        {field: 'last_name', headerName: 'Last Name', enableRowGroup: true, enablePivot: true},
        {field: 'created_at', headerName: 'Created At', enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter},
        {field: 'updated_at', headerName: 'Updated At', enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter}
      ];

      let role_models = [
        {field: 'id', headerName: 'Id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        {field: 'role_id', headerName: 'Role Id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        {field: 'role_name', headerName: 'Role', enableRowGroup: true, enablePivot: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, sort: "asc"},
        {field: 'model', headerName: 'model', enableRowGroup: true, enablePivot: true},
        {field: 'permission', headerName: 'Permission', enableRowGroup: true, enablePivot: true},
        {field: 'role_query', headerName: 'Query Where Clause', enableRowGroup: true, enablePivot: true},
        {field: 'created_at', headerName: 'Created At', enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter},
        {field: 'updated_at', headerName: 'Updated At', enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter}
      ];

      let roles = [
        {field: 'id', headerName: 'Id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        {field: 'name', headerName: 'Role Name', enableRowGroup: true, enablePivot: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, sort: "asc"},
        {field: 'description', headerName: 'Description', enableRowGroup: true, enablePivot: true},
      ];

      let optimization_discounts = [
        {field: 'id', headerName: 'id', hide: true, aggFunc: "count", enableValue: true, type: "numericColumn",},
        {field: 'supplier_name', headerName: 'Supplier', enableRowGroup: true, enablePivot: true, sort: "asc"},
        {field: 'optimization_discount_amount', headerName: 'Amount', aggFunc: "sum", enableValue: true, type: "numericColumn", valueFormatter: decimalFormatter},
        {field: 'optimization_discount_discount_constraint_id', headerName: 'Constraint', enableRowGroup: true, enablePivot: true,
            cellRenderer: function(params) {
						let linkPartOne = '<a href="/sourcing/projects/' +params.data.project_id+ '/scenarios/' +params.data.scenario_id+ '/DiscountConstraints" class="link_style">';
						let linkPartTwo = 'View in constraints';
						let linkPartThree = '</a>';
						let stringToReturn = linkPartOne + linkPartTwo + linkPartThree;
						return stringToReturn
				}},

        {field: 'optimization_discount_discount_constraint_id', headerName: 'Constraint ID', enableRowGroup: true, enablePivot: true},
        {field: 'discount_constraint_created_at', headerName: 'Created At', enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter},
        {field: 'discount_constraint_updated_at', headerName: 'Updated At', enableRowGroup: true, enablePivot: true,
            valueFormatter: dateFormatter}
      ];

      let smallScreenHide = true;
      //concat rules based on window query
      if('windowDimensions' in optionsObject && 'width' in optionsObject.windowDimensions && optionsObject.windowDimensions.width > 1550) {
        smallScreenHide = false;
      }
			let sourcing_scenarios = [
				{field: 'name', headerName: optionsObject.customOptions.scenario__name, enableRowGroup: true, enablePivot: true,
					headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, minWidth: 350,
					editable: true, stopEditingWhenGridLosesFocus: true,  cellRenderer: function(params) {
            let stringToReturn = '';
            if (params.data.scenario_status_id == 3 || params.data.scenario_status_id == 5) {
  						let linkPartOne = '<a href="/sourcing/projects/' +params.data.project_id+ '/scenarios/' +params.data.id+ '/OptimizationResults" class="link_style">';
  						let linkPartTwo = params.value ? params.value : '';
  						let linkPartThree = '</a>';
  						stringToReturn = linkPartOne + linkPartTwo + linkPartThree;
            } else {
              stringToReturn = params.value ? params.value : ''
            }
						return stringToReturn
				}},
        {field: 'description', hide: true, headerName: 'Description', enableRowGroup: true, enablePivot: true},
        {field: 'status_name', hide: true, headerName: 'Scenario Status', enableRowGroup: true, enablePivot: true},
        {field: 'status_description', headerName: 'Status', enableRowGroup: true, enablePivot: true, minWidth: 250, cellRenderer: function(params) {
          let infeasibilityMessage = '';
          //infeasibility exists
          if(params.data.scenario_status_id == 4 || params.data.scenario_status_id == 4) {
            //check for 'undefined' string in optimization return, if it's undefined just give a message, no link
            if('undefined' in params.data.optimizer_info.infeasible_constraints){
              infeasibilityMessage = '. Check data, many infeasible triggers'
            }
            else {
              infeasibilityMessage = '. Check:';
              let infeasibleConstraintsObject = params.data.optimizer_info.infeasible_constraints;
              console.log('Infeasible Constraints');
              console.log(infeasibleConstraintsObject);
              //outer loop, go over constraint types of infeasibleConstraintsObject
              for (let [constraintType, constraintTypeObjectValue] of Object.entries(infeasibleConstraintsObject)) {
                let linkPartOne = '<a href="/sourcing/projects/' +params.data.project_id+ '/scenarios/' +params.data.id+ '/'+constraintType.toLowerCase()+'constraints" class="link_style">';
                let linkPartTwo = constraintType.toLowerCase();
                let linkPartThree = '</a>';
                infeasibilityMessage += ' '+ linkPartOne + linkPartTwo + linkPartThree;
              }
            }
          }
          let description = params.data.status_description;
          let stringToReturn = description + infeasibilityMessage;
          return stringToReturn
        } },
        {field: 'Run', cellRenderer: 'launchScenarioRenderer', width: 100 },
        {field: 'id', headerName: optionsObject.customOptions.scenario__id, hide: true, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc"},
				{field: 'Settings', cellRenderer: 'scenarioSettingsRenderer', width: 125 },
        {field: 'rules', cellRenderer: 'paramsRenderer', hide: smallScreenHide, minWidth: 100 },
				{field: 'comment', hide: true, headerName: optionsObject.customOptions.scenario__comment, enableRowGroup: true, enablePivot: true,
					editable: true, stopEditingWhenGridLosesFocus:true},
				{field: 'created_at', headerName: optionsObject.customOptions.scenario__created_at, enableRowGroup: true, enablePivot: true,
					valueFormatter: dateFormatter, width: 110, hide: true},
				{field: 'updated_at', headerName: optionsObject.customOptions.scenario__updated_at, hide: true, enableRowGroup: true, enablePivot: true,
					valueFormatter: dateFormatter},
        {field: 'supplier_count', type: 'numericColumn', headerName: 'Bidders', enableValue: true, aggFunc: 'sum', type: 'numericColumn', editable: false, stopEditingWhenGridLosesFocus: true, width: 125, hide: smallScreenHide },
        {field: 'net_amount', type: 'numericColumn', headerName: 'Spend', enableValue: true, aggFunc: 'sum', type: 'numericColumn', valueFormatter: roundFormatter, editable: false, stopEditingWhenGridLosesFocus: true, minWidth: 110 },
        {field: 'actions', headerName: 'Copy', enableRowGroup: true, enablePivot: true,
          headerCheckboxSelection: false, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: false,
          editable: false, stopEditingWhenGridLosesFocus: true, cellRenderer: 'cloneScenarioRenderer', width: 100
        },
      ];

			// {field: 'id', headerName:"Actions", cellRender: function (params){return 'Value is <b>'+params.value+'</b>'} }

			let competition_constraints = [
				{field: 'scope', hide: false, headerName:optionsObject.customOptions.scope,
					editable: false, stopEditingWhenGridLosesFocus:true,
					headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
				{field: 'scope_value', hide: false, headerName:'Scope Value',
					editable: false, stopEditingWhenGridLosesFocus:true},
				{field: 'award_operator', hide: false, headerName:'Award Operator', width: 140,
          editable: true, stopEditingWhenGridLosesFocus:true,
            //this allows you to select the opposite of the current operator (i.e... '<=' becomes '>=' vice versa)
            cellEditor: "agRichSelectCellEditor",
                cellEditorParams: function(params) {
                    let selectedAward = params.data.award_operator
                    if (selectedAward === '<=') {
                        return {
                            values: ['>=']
                        };
                    } else {
                        return {
                            values: ['<=']
                        };
                    }
                }
				},
				{field: 'suppliers', hide: false, headerName: 'Bidders', type: "numericColumn",
					editable: true, stopEditingWhenGridLosesFocus:true},
        {field: 'created_at', headerName:'Created At', enableRowGroup: true, enablePivot: true,
					valueFormatter: dateFormatter},
				{field: 'updated_at', headerName:'Updated At', hide: true, enableRowGroup: true, enablePivot: true,
					valueFormatter: dateFormatter},
        {field: 'infeasibility', hide: false, headerName: 'Infeasibility',
          editable: false, stopEditingWhenGridLosesFocus:true,
          cellStyle: (e) => conditionalCellStyler(e, 'params.data.infeasibility==true', {backgroundColor: '#DD4145'})
        },
				{field: 'id', headerName:optionsObject.customOptions.id, hide: false, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc",
					 width: 140},

      ];

  		let subset_constraints = [
  			{field: 'scope', hide: false, headerName:optionsObject.customOptions.scope,
  				editable: false, stopEditingWhenGridLosesFocus:true,
  				headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
  			{field: 'scope_value', hide: false, headerName:'Scope Value',
  				editable: false, stopEditingWhenGridLosesFocus:true},
        {field: 'created_at', headerName:'Created At', enableRowGroup: true, enablePivot: true,
  				valueFormatter: dateFormatter},
  			{field: 'updated_at', headerName:'Updated At', hide: true, enableRowGroup: true, enablePivot: true,
  				valueFormatter: dateFormatter},
  			{field: 'id', headerName:optionsObject.customOptions.id, hide: false, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc",
  				 width: 140},
      ];

      let supplier_constraints = [
        {
          field: 'scope',
          hide: false,
          headerName: optionsObject.customOptions.scope,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
        },
        {
          field: 'scope_value',
          hide: false,
          headerName: 'Scope Value',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'award_operator',
          hide: false,
          headerName: 'Award Operator',
          width: 140,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          //this allows you to select the opposite of the current operator (i.e... '<=' becomes '>=' vice versa)
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: function (params) {
            let selectedAward = params.data.award_operator;
            if (selectedAward === '<=') {
              return {
                values: ['>='],
              };
            } else {
              return {
                values: ['<='],
              };
            }
          },
        },
        {
          field: 'supplier_id',
          hide: true,
          headerName: optionsObject.customOptions.supplier__id,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
        },
        {
          field: 'supplier_name',
          hide: false,
          headerName: optionsObject.customOptions.supplier__name,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'award',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.award,
          enableValue: true,
          type: 'numericColumn',
          editable: true,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'base',
          hide: false,
          headerName: optionsObject.customOptions.base,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'created_at',
          headerName: 'Created At',
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
        {
          field: 'updated_at',
          headerName: 'Updated At',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
        {
          field: 'infeasibility',
          hide: false,
          headerName: 'Infeasibility',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          cellStyle: (e) => conditionalCellStyler(e, 'params.data.infeasibility==true', { backgroundColor: '#DD4145' }),
        },
        {
          field: 'id',
          headerName: optionsObject.customOptions.id,
          hide: false,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc',
          width: 140,
        },
      ];

        ////////Qualitative Constraints////////////
			let qualitative_constraints = [
				{field: 'scope', hide: false, headerName:optionsObject.customOptions.scope,
					editable: false, stopEditingWhenGridLosesFocus:true,
					headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
				{field: 'scope_value', hide: false, headerName:'Scope Value',
                    editable: false, stopEditingWhenGridLosesFocus:true},
        {field: 'custom_field_name',hide: false, headerName:'Custom Field',
            editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'custom_field_value', hide: false, headerName:'Field Value',
            editable: false, stopEditingWhenGridLosesFocus: true},
				{field: 'award_operator', hide: false, headerName:'Award Operator', width: 140,
					editable: true, stopEditingWhenGridLosesFocus:true,
				//this allows you to select the opposite of the current operator (i.e... '<=' becomes '>=' vice versa)
                cellEditor: "agRichSelectCellEditor",
                    cellEditorParams: function(params) {
                        let selectedAward = params.data.award_operator
                        if (selectedAward === '<=') {
                            return {
                                values: ['>=']
                            };
                        } else {
                            return {
                                values: ['<=']
                            };
                        }
                    }
                },////custom_field_value and custom_field_name
				{field: 'award', type: "numericColumn", headerName:optionsObject.customOptions.award, enableValue: true, type: "numericColumn",
					editable: true, stopEditingWhenGridLosesFocus:true},
				{field: 'base', hide: false, headerName:optionsObject.customOptions.base,
					editable: false, stopEditingWhenGridLosesFocus:true},
        {field: 'created_at', headerName:'Created At', enableRowGroup: true, enablePivot: true,
					valueFormatter: dateFormatter},
				{field: 'updated_at', headerName:'Updated At', hide: true, enableRowGroup: true, enablePivot: true,
					valueFormatter: dateFormatter},
        {field: 'infeasibility', hide: false, headerName: 'Infeasibility',
          editable: false, stopEditingWhenGridLosesFocus:true,
          cellStyle: (e) => conditionalCellStyler(e, 'params.data.infeasibility==true', {backgroundColor: '#DD4145'})
        },
				{field: 'id', headerName:optionsObject.customOptions.id, hide: false, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc",
					 width: 140},
      ];

      ///////////Discount Constraints/////////////
      let discount_constraints = [
        {
          field: 'scope',
          hide: false,
          headerName: optionsObject.customOptions.scope,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true
        },
        {
          field: 'scope_value',
          hide: false,
          headerName: 'Scope Value',
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
			    field: 'supplier_id',
			    hide: true,
			    headerName: optionsObject.customOptions.supplier__id,
			    editable: false,
			    stopEditingWhenGridLosesFocus: true,
					enableRowGroup: true,
					enablePivot: true,
          enableValue: true,
			  },
        {
          field: 'supplier_name',
          hide: false,
          headerName: optionsObject.customOptions.supplier__name,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'award_operator',
          hide: false,
          headerName: 'Award Operator',
          width: 140,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          //this allows you to select the opposite of the current operator (i.e... '<=' becomes '>=' vice versa)
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: function(params) {
            let selectedAward = params.data.award_operator;
            if (selectedAward === '<=') {
              return {
                values: ['>=']
              };
            } else {
              return {
                values: ['<=']
              };
            }
          }
        },
        {
          field: 'threshold',
          type: 'numericColumn',
          headerName: 'Threshold',
          enableValue: true,
          type: 'numericColumn', //int
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'threshold_base',
          hide: false,
          headerName: 'Threshold Base',
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'discount',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.discount,
          enableValue: true,
          type: 'numericColumn', //int
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'base',
          headerName: optionsObject.customOptions.discount,
          editable: false,
          stopEditingWhenGridLosesFocus: true
        },
        {
          field: 'created_at',
          headerName: 'Created At',
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        },
        {
          field: 'updated_at',
          headerName: 'Updated At',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter
        },
        {
          field: 'infeasibility',
          hide: false,
          headerName: 'Infeasibility',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          cellStyle: e => conditionalCellStyler(e, 'params.data.infeasibility==true', { backgroundColor: '#DD4145' })
        },
        {
          field: 'id',
          headerName: optionsObject.customOptions.id,
          hide: false,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc',
          width: 140
        }
      ];


      ////////Freight Brackets//////////////
      let freight_brackets = [
        {
          field: 'name',
          hide: false,
          headerName: optionsObject.customOptions.alias,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          enableRowGroup: true,
          enablePivot: true,
        },
        {
          field: 'min_bracket',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.min_bracket,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          type: 'numericColumn',
          valueFormatter: roundFormatter,
        },
        {
          field: 'max_bracket',
          type: 'numericColumn',
          headerName: optionsObject.customOptions.max_bracket,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          editable: true,
          stopEditingWhenGridLosesFocus: true,
          type: 'numericColumn',
          valueFormatter: roundFormatter,
        },
        {
          field: 'id',
          headerName: optionsObject.customOptions.id,
          hide: false,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc',
          width: 140
        }
      ];


        //////Freight Match Constraints//////////
        let freight_match_constraints = [
            {field: 'id', headerName:optionsObject.customOptions.id, hide: false, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true,
            width: 140},
            {field: 'bid_field',hide: false, headerName:optionsObject.customOptions.bid_field,
            editable: false, stopEditingWhenGridLosesFocus: true},
            {field: 'freight_field', hide: false, headerName:optionsObject.customOptions.freight_field,
            editable: false, stopEditingWhenGridLosesFocus: true},
            {field: 'infeasibility', hide: false, headerName: 'Infeasibility',
              editable: false, stopEditingWhenGridLosesFocus:true,
              cellStyle: (e) => conditionalCellStyler(e, 'params.data.infeasibility==true', {backgroundColor: '#DD4145'})
            },
        ];

        ////////Quantitative Constraints////////////
        let quantitative_constraints = [
            {field: 'scope', hide: false, headerName:optionsObject.customOptions.scope,
                editable: false, stopEditingWhenGridLosesFocus:true,
                headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
            {field: 'scope_value', hide: false, headerName:'Scope Value',
                editable: false, stopEditingWhenGridLosesFocus:true},
            {field: 'custom_field_name',hide: false, headerName:'Custom Field',
                editable: false, stopEditingWhenGridLosesFocus: true},
            {field: 'constraint_operator', hide: false, headerName:'Constraint Operator', width: 140,
                editable: false, stopEditingWhenGridLosesFocus:true},
            {field: 'custom_field_value', hide: false, headerName:'Field Value',
                editable: false, stopEditingWhenGridLosesFocus: true},
            {field: 'award_operator', hide: false, headerName:'Award Operator', width: 140,
                editable: true, stopEditingWhenGridLosesFocus:true,
                //this allows you to select the opposite of the current operator (i.e... '<=' becomes '>=' vice versa)
            cellEditor: "agRichSelectCellEditor",
                    cellEditorParams: function(params) {
                        let selectedAward = params.data.award_operator
                        if (selectedAward === '<=') {
                            return {
                                values: ['>=']
                            };
                        } else {
                            return {
                                values: ['<=']
                            };
                        }
                    }
            },////custom_field_value and custom_field_name
            {field: 'award', type: "numericColumn", headerName:optionsObject.customOptions.award, enableValue: true, type: "numericColumn",
                editable: true, stopEditingWhenGridLosesFocus:true},
            {field: 'base', hide: false, headerName:optionsObject.customOptions.base,
                editable: false, stopEditingWhenGridLosesFocus:true},
            {field: 'created_at', headerName:'Created At', enableRowGroup: true, enablePivot: true,
                valueFormatter: dateFormatter},
            {field: 'updated_at', headerName:'Updated At', hide: true, enableRowGroup: true, enablePivot: true,
                valueFormatter: dateFormatter},
            {field: 'infeasibility', hide: false, headerName: 'Infeasibility',
             editable: false, stopEditingWhenGridLosesFocus:true,
             cellStyle: (e) => conditionalCellStyler(e, 'params.data.infeasibility==true', {backgroundColor: '#DD4145'})
            },
            {field: 'id', headerName:optionsObject.customOptions.id, hide: false, aggFunc: "count", enableValue: true, type: "numericColumn", sort: "desc",
                 width: 140},
  ];


      let spend_transactions = [
        { field: 'id', hide: true, headerName: optionsObject.customOptions['transaction__id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: false, aggFunc: null, enableValue: false, enableRowGroup: false, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'supplier_id', hide: true, headerName: optionsObject.customOptions['transaction__supplier_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'category_id', hide: true, headerName: optionsObject.customOptions['transaction__category_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'item_description', hide: false, headerName: optionsObject.customOptions['transaction__item_description'], type: null, sort: null, valueFormatter: null, width: 140, maxWidth: 140 * 5, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'item_id', hide: true, headerName: optionsObject.customOptions['transaction__item_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'quantity', hide: false, headerName: optionsObject.customOptions['transaction__quantity'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: 'sum', enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'price', hide: false, headerName: optionsObject.customOptions['transaction__price'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: twoDecimalPaddedFormatter, width: 140, enablePivot: true, aggFunc: 'sum', enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'currency', hide: false, headerName: optionsObject.customOptions['transaction__currency'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'on_contract', hide: false, headerName: optionsObject.customOptions['transaction__on_contract'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'contract_id', hide: true, headerName: optionsObject.customOptions['transaction__contract_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'location_id', hide: true, headerName: optionsObject.customOptions['transaction__location_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'date_id', hide: true, headerName: optionsObject.customOptions['transaction__date_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'project_id', hide: true, headerName: optionsObject.customOptions['transaction__project_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'department', hide: false, headerName: optionsObject.customOptions['transaction__department'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'cost_center', hide: false, headerName: optionsObject.customOptions['transaction__cost_center'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'file_source', hide: false, headerName: optionsObject.customOptions['transaction__file_source'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'file_source_filename', hide: false, headerName: optionsObject.customOptions['transaction__file_source_filename'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true },
        { field: 'date.date', hide: false, headerName: optionsObject.customOptions['date__date'], type: 'date', sort: null, valueFormatter: dateFormatter, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'date.month', hide: true, headerName: optionsObject.customOptions['date__month'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'date.quarter', hide: true, headerName: optionsObject.customOptions['date__quarter'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'date.year', hide: true, headerName: optionsObject.customOptions['date__year'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'date.year_month', hide: true, headerName: optionsObject.customOptions['date__year_month'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'date.year_quarter', hide: true, headerName: optionsObject.customOptions['date__year_quarter'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'date.month_name', hide: true, headerName: optionsObject.customOptions['date__month_name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'date.created_at', hide: true, headerName: optionsObject.customOptions['date__created_at'], type: 'date', sort: null, valueFormatter: dateFormatter, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'date.updated_at', hide: true, headerName: optionsObject.customOptions['date__updated_at'], type: 'date', sort: null, valueFormatter: dateFormatter, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'category.id', hide: true, headerName: optionsObject.customOptions['category__id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: "category.custom_fields.custom_field_1", hide: false, headerName: 'Level 1 Category', type: null, sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        { field: "category.custom_fields.custom_field_2", hide: false, headerName: 'Level 2 Category', type: null, sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        { field: "category.custom_fields.custom_field_3", hide: false, headerName: 'Level 3 Category', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        { field: "category.custom_fields.custom_field_4", hide: false, headerName: 'Level 4 Category', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        { field: "category.custom_fields.custom_field_5", hide: true, headerName: 'Level 5 Category', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        { field: 'location.country_iso2_code', hide: true, headerName: optionsObject.customOptions['location__country_iso2_code'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'location.country_iso3_code', hide: false, headerName: optionsObject.customOptions['location__country_iso3_code'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'location.state_code', hide: false, headerName: optionsObject.customOptions['location__state_code'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'location.country_name', hide: true, headerName: optionsObject.customOptions['location__country_name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'location.state_name', hide: true, headerName: optionsObject.customOptions['location__state_name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'location.region_name', hide: true, headerName: optionsObject.customOptions['location__region_name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'location.subregion_name', hide: true, headerName: optionsObject.customOptions['location__subregion_name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'location.created_at', hide: true, headerName: optionsObject.customOptions['location__created_at'], type: 'date', sort: null, valueFormatter: dateFormatter, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'location.updated_at', hide: true, headerName: optionsObject.customOptions['location__updated_at'], type: 'date', sort: null, valueFormatter: dateFormatter, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'supplier.name', hide: false, headerName: optionsObject.customOptions['supplier__name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'supplier.description', hide: false, headerName: optionsObject.customOptions['supplier__description'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'supplier.created_by_user_id', hide: true, headerName: optionsObject.customOptions['supplier__created_by_user_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: false, editable: false, stopEditingWhenGridLosesFocus: true },
        // {field: "supplier.custom_fields", hide: true, headerName: optionsObject.customOptions['supplier__custom_fields'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: true, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        { field: 'supplier.group', hide: false, headerName: optionsObject.customOptions['supplier__group'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'supplier.norm', hide: true, headerName: optionsObject.customOptions['supplier__norm'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'supplier.foreign_data', hide: true, headerName: optionsObject.customOptions['supplier__foreign_data'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'supplier.created_at', hide: true, headerName: optionsObject.customOptions['supplier__created_at'], type: 'date', sort: null, valueFormatter: dateFormatter, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true },
        { field: 'supplier.updated_at', hide: true, headerName: optionsObject.customOptions['supplier__updated_at'], type: 'date', sort: null, valueFormatter: dateFormatter, width: 140, enablePivot: true, aggFunc: null, enableValue: false, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true }
      ];


      ///////Freight Bracket Constraints////////
      let freight_bracket_constraints = [
        {
          field: 'id',
          headerName: optionsObject.customOptions.id,
          hide: false,
          aggFunc: 'count',
          enableValue: true,
          type: 'numericColumn',
          sort: 'desc',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          width: 140,
        },
        {
          field: 'scope',
          hide: false,
          headerName: optionsObject.customOptions.scope,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'scope_value',
          hide: false,
          headerName: 'Scope Value',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'custom_field_name',
          hide: false,
          headerName: 'Custom Field',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'custom_field_value',
          hide: false,
          headerName: 'Field Value',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
			    field: 'supplier_id',
			    hide: true,
			    headerName: optionsObject.customOptions.supplier__id,
			    editable: false,
			    stopEditingWhenGridLosesFocus: true,
					enableRowGroup: true,
					enablePivot: true,
          enableValue: true,
			  },
        {
          field: 'supplier_name',
          hide: false,
          headerName: optionsObject.customOptions.supplier__name,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'freight_bracket_id',
          hide: true,
          headerName: optionsObject.customOptions.freight_bid__freight_bracket_id,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'freight_bracket_name',
          hide: false,
          headerName: optionsObject.customOptions.freight_bracket__name,
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'alternate_price',
          type: 'numericColumn',
          headerName: 'Alternate Price',
          enableValue: true,
          type: 'numericColumn',
          editable: true,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'allow_optimization',
          hide: false,
          headerName: 'Allow Optimization',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
        },
        {
          field: 'infeasibility',
          hide: false,
          headerName: 'Infeasibility',
          editable: false,
          stopEditingWhenGridLosesFocus: true,
          cellStyle: (e) => conditionalCellStyler(e, 'params.data.infeasibility==true', { backgroundColor: '#DD4145' }),
        },
        {
          field: 'created_at',
          headerName: 'Created At',
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
        {
          field: 'updated_at',
          headerName: 'Updated At',
          hide: true,
          enableRowGroup: true,
          enablePivot: true,
          valueFormatter: dateFormatter,
        },
      ];



      let spend_category_breakdown = [
        {headerName: "Category Code", field: "category_id", hide: false, headerName: optionsObject.customOptions['grouped_transactions__category_id'], type: null, sort: "desc", valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {field: "custom_field_1", hide: false, headerName: 'Level 1 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_1_handler'](event)}},
        {field: "custom_field_2", hide: false, headerName: 'Level 2 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_2_handler'](event)}},
        {field: "custom_field_3", hide: false, headerName: 'Level 3 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_3_handler'](event)}},
        {field: "custom_field_4", hide: true, headerName: 'Level 4 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_4_handler'](event)}},
        {field: "custom_field_5", hide: true, headerName: 'Level 5 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_5_handler'](event)}},
        {field: "group", hide: false, headerName: optionsObject.customOptions['grouped_transactions__group'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        // {field: "transaction_average", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_average'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {field: "transaction_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', comparator: numericComparator, sort: "desc", valueFormatter: null, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {field: "transaction_total", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_total'], type: 'numericColumn', comparator: numericComparator, sort: "desc", valueFormatter: twoDecimalPaddedFormatter, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {...{headerName: optionsObject.customOptions['grouped_transactions__transaction_avg'], hide: false, colId: 'transactionAvg', type: 'numericColumn', comparator: numericComparator, valueFormatter: twoDecimalPaddedFormatter},
         ...(weightedAverageFunctionFactory('transaction_count', 'transaction_total'))}
      ];

      let spend_category_breakdown_by_category = [
        {headerName: "Category Code", field: "category_id", hide: true, headerName: optionsObject.customOptions['grouped_transactions__category_id'], type: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true},
        {field: "custom_field_1", hide: false, headerName: 'Level 1 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_1_handler'](event)}},
        {field: "custom_field_2", hide: false, headerName: 'Level 2 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_2_handler'](event)}},
        {field: "custom_field_3", hide: false, headerName: 'Level 3 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_3_handler'](event)}},
        {field: "custom_field_4", hide: true, headerName: 'Level 4 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_4_handler'](event)}},
        {field: "custom_field_5", hide: true, headerName: 'Level 5 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_5_handler'](event)}},
        // {field: "transaction_average", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_average'], type: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true},
        {field: "transaction_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: null, width: 140, aggFunc: 'sum'},
        {field: "transaction_total", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_total'], type: 'numericColumn', sort: 'desc', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter, width: 140, aggFunc: 'sum'},
        {...{headerName: optionsObject.customOptions['grouped_transactions__transaction_avg'], hide: false, colId: 'transactionAvg', type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter},
         ...(weightedAverageFunctionFactory('transaction_count', 'transaction_total'))}
      ];

      let spend_category_breakdown_by_supplier_group = [
        {field: "group", hide: false, headerName: 'Supplier Group', width: 240, enablePivot: true, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['supplier_group_handler'](event)}},
        {field: "name", hide: true, headerName: 'Supplier Name', width: 140, enablePivot: true, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['supplier_name_handler'](event)}},
        {field: "transaction_count", headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', comparator: numericComparator, enableValue: true, width: 140, enablePivot: true, aggFunc: 'sum'},
        {field: "transaction_total", headerName: optionsObject.customOptions['grouped_transactions__transaction_total'], sort: 'desc', type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter, width: 140, aggFunc: 'sum'},
        {...{headerName: optionsObject.customOptions['grouped_transactions__transaction_avg'], colId: 'transactionAvg', type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter},
         ...(weightedAverageFunctionFactory('transaction_count', 'transaction_total'))}
      ];

      let spend_projects_crosstab_by_category = [
        {headerName: "Category Code", field: "category_id", hide: true, headerName: optionsObject.customOptions['grouped_transactions__category_id'], type: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true},
        {field: "custom_field_1", hide: false, headerName: 'Level 1 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_1_handler'](event)}},
        {field: "custom_field_2", hide: false, headerName: 'Level 2 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_2_handler'](event)}},
        {field: "custom_field_3", hide: false, headerName: 'Level 3 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_3_handler'](event)}},
        {field: "custom_field_4", hide: true, headerName: 'Level 4 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_4_handler'](event)}},
        {field: "custom_field_5", hide: true, headerName: 'Level 5 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_5_handler'](event)}},
        {field: "transaction_total", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_total'], type: 'numericColumn', sort: 'desc', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter, width: 140, aggFunc: 'sum'},
        {field: "transaction_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: null, width: 140, aggFunc: 'sum'},
        {...{headerName: optionsObject.customOptions['grouped_transactions__transaction_avg'], hide: false, colId: 'transactionAvg', type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter},
         ...(weightedAverageFunctionFactory('transaction_count', 'transaction_total'))},
        // {field: "project_count", hide: false, headerName: "Project Count", type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: null, width: 140, aggFunc: 'sum'},
        {field: "project_savings", hide: false, headerName: "Project Savings", type: 'numericColumn', sort: null, comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter, width: 140, aggFunc: 'sum'},
        {field: "project_count", hide: false, headerName: "Project Count", type: 'numericColumn', sort: null, comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter, width: 140, aggFunc: 'sum'},
      ];

      let spend_projects_crosstab_by_supplier_group = [
        {field: "group", hide: false, headerName: 'Supplier Group', width: 240, enablePivot: true, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['supplier_group_handler'](event)}},
        {field: "name", hide: true, headerName: 'Supplier Name', width: 140, enablePivot: true, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['supplier_name_handler'](event)}},
        {field: "transaction_count", headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', comparator: numericComparator, enableValue: true, width: 140, enablePivot: true, aggFunc: 'sum'},
        {field: "transaction_total", headerName: optionsObject.customOptions['grouped_transactions__transaction_total'], sort: 'desc', type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter, width: 140, aggFunc: 'sum'},
        {...{headerName: optionsObject.customOptions['grouped_transactions__transaction_avg'], colId: 'transactionAvg', type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter},
         ...(weightedAverageFunctionFactory('transaction_count', 'transaction_total'))},
        {field: "project_name", hide: true, headerName: 'Project Name', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: false, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "project_id", hide: true, headerName: 'Project Id', width: 140, enablePivot: true, aggFunc: 'count', enableRowGroup: true, rowGroup: false, editable: false, stopEditingWhenGridLosesFocus: true},
      ];

      let spend_projects_crosstab_by_project_savings = [
        // {headerName: "Category Code", field: "category_id", hide: true, headerName: optionsObject.customOptions['grouped_transactions__category_id'], type: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true},
        // {field: "custom_field_1", hide: false, headerName: 'Level 1 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
        //  cellClass: "navigating-filter-cell a",
        //  onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_1_handler'](event)}},
        // {field: "custom_field_2", hide: false, headerName: 'Level 2 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
        //  cellClass: "navigating-filter-cell a",
        //  onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_2_handler'](event)}},
        // {field: "custom_field_3", hide: false, headerName: 'Level 3 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
        //  cellClass: "navigating-filter-cell a",
        //  onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_3_handler'](event)}},
        // {field: "custom_field_4", hide: true, headerName: 'Level 4 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
        //  cellClass: "navigating-filter-cell a",
        //  onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_4_handler'](event)}},
        // {field: "custom_field_5", hide: true, headerName: 'Level 5 Category', width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
        //  cellClass: "navigating-filter-cell a",
        //  onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['custom_field_5_handler'](event)}},
        // { field: "project_id", hide: true, headerName: 'Project Id', width: 140, enablePivot: true, enableRowGroup: false, rowGroup: false, editable: false, stopEditingWhenGridLosesFocus: true},
        { field: "project_savings", headerName: "Project Savings", sort: 'desc', type: 'numericColumn', comparator: numericComparator, enableValue: true, valueFormatter: twoDecimalPaddedFormatter, width: 140, aggFunc: 'sum'},
        { field: "project_name", hide: false, headerName: 'Project Name', width: 140, enablePivot: true,
          enableRowGroup: true, rowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
          cellClass: "",
          valueGetter: params => {
            if (!params.node.group){
              return {
                toString: () => `${params.data.project_id}|${params.data.project_name}`
              }
            }
          },
          cellRenderer: (params) => {
            try {
              let parts = params.value.split("|");
              let id = parts[0];
              let name = "" + parts.slice(1).join("|");
              let link = "/sourcing/projects/" + id + "/bids";
              if (name === 'null'){
                return (<span>No Project Assigned</span>)
              } else {
                return (<span><a href={link} target="_blank" class="link_style">{name}</a></span>);
              }
            } catch (err) {
              return params.value
            }
          }},
      ];

      let spend_risk_profiles_overview = [
        {field: "group", hide: false, headerName: optionsObject.customOptions['grouped_transactions__group'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        // {field: "transaction_average", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_average'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {field: "transaction_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {...{headerName: optionsObject.customOptions['grouped_transactions__transaction_avg'], hide: false, colId: 'transactionAvg', type: 'numericColumn', comparator: numericComparator, valueFormatter: twoDecimalPaddedFormatter},
         ...(weightedAverageFunctionFactory('transaction_count', 'transaction_total'))},
        {field: "transaction_total", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_total'], type: 'numericColumn', comparator: numericComparator, sort: "desc", valueFormatter: twoDecimalPaddedFormatter, width: 140, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true}
      ];

      let savings_opportunities_overview = [
        {field: "field_code", hide: false, headerName: optionsObject.customOptions['savings_opportunity__field_code'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: false, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "custom_field_1", hide: false, headerName: 'Level 1 Category', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: false, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "custom_field_2", hide: false, headerName: 'Level 2 Category', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: false, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "custom_field_3", hide: false, headerName: 'Level 3 Category', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: false, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "savings_potential_percent", hide: false, headerName: optionsObject.customOptions['savings_opportunity__savings_potential_percent'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: percentFormatter, width: 140, enablePivot: false, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "sourcing_event_ease_score", hide: false, headerName: optionsObject.customOptions['savings_opportunity__sourcing_event_ease_score'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: ratingFormatter(5), width: 140, enablePivot: false, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "e_auction_ease_score", hide: false, headerName: optionsObject.customOptions['savings_opportunity__e_auction_ease_score'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: ratingFormatter(5), width: 140, enablePivot: false, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "supplier_availability_score", hide: false, headerName: optionsObject.customOptions['savings_opportunity__supplier_availability_score'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: ratingFormatter(5), width: 140, enablePivot: false, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "current_spending", hide: false, headerName: optionsObject.customOptions['grouped_transactions__current_spending'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter, width: 140, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "saving_potential", hide: false, headerName: optionsObject.customOptions['grouped_transactions__saving_potential'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter, width: 140, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
      ];

      let outlier_analysis_overview = [
        {field: "name", hide: false, headerName: optionsObject.customOptions['supplier__name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['supplier_name_handler'](event)}},
        {field: "item_description", hide: false, headerName: optionsObject.customOptions['transaction__item_description'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell a",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['item_description_handler'](event)}},
        {field: "supplier_average", hide: false, headerName: optionsObject.customOptions['grouped_transactions__supplier_average'], type: 'numericColumn', sort: null, valueFormatter: roundFormatter, width: 140, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "industry_average", hide: false, headerName: optionsObject.customOptions['grouped_transactions__industry_average'], type: 'numericColumn', sort: null, valueFormatter: roundFormatter, width: 140, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "percent_of_average", hide: false, headerName: optionsObject.customOptions['grouped_transactions__percent_of_average'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: percentDifferenceFormatter, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
          cellStyle: colorScaleFormatter(optionsObject['metadata'] &&
                                         optionsObject['metadata']['percent_of_average'] &&
                                         optionsObject['metadata']['percent_of_average']['min'],
                                         optionsObject['metadata'] &&
                                         optionsObject['metadata']['percent_of_average'] &&
                                         optionsObject['metadata']['percent_of_average']['max'],
                                         {red: 255, green: 255, blue: 255},
                                         {red: 240, green: 128, blue: 128})
        }
      ];

      let spend_reclassification_rules = [
        {field: 'id', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'name', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'description', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__description'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'priority', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__priority'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'conditions', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__conditions'], type: null, sort: null, valueFormatter: jsonFormatter, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'category_id', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__category_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'created_at', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__created_at'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'updated_at', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__updated_at'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
       {headerName: "Run", cellRenderer: 'runButtonRenderer', type: null, sort: null, valueFormatter: null, width: 90, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
        cellClass: "navigating-filter-cell",
        onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['run_button_handler'](event)}},
        {headerName: "Edit", cellRenderer: 'editButtonRenderer', type: null, sort: null, valueFormatter: null, width: 90, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['edit_button_handler'](event)}},
        {headerName: "Delete", cellRenderer: 'deleteButtonRenderer', type: null, sort: null, valueFormatter: null, width: 90, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true,
         cellClass: "navigating-filter-cell",
         onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['delete_button_handler'](event)}}
      ];

      let spend_reclassifiction_rules_run_all_results = [
        // copy and paste of the spend_reclassification_rules def vvvvvvvvvvvvvvvvvvvvvvvvv
        {field: 'status', hide: false, headerName: 'Status'},
        {field: 'rule.id', hide: false, headerName: 'Rule Id', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'rule.name', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__name'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'rule.description', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__description'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'rule.priority', hide: false, headerName: optionsObject.customOptions['spend_reclassification_rule__priority'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'rule.conditions', hide: true, headerName: optionsObject.customOptions['spend_reclassification_rule__conditions'], type: null, sort: null, valueFormatter: jsonFormatter, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'rule.category_id', hide: true, headerName: optionsObject.customOptions['spend_reclassification_rule__category_id'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'rule.created_at', hide: true, headerName: optionsObject.customOptions['spend_reclassification_rule__created_at'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'rule.updated_at', hide: true, headerName: optionsObject.customOptions['spend_reclassification_rule__updated_at'], type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        // copy and paste of the spend_reclassification_rules def ^^^^^^^^^^^^^^^^^^^^^^^^^^
        {field: 'result.step_applied', hide: false, headerName: 'Step Applied', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: 'result.update_count', hide: false, headerName: 'Records Updated', type: null, sort: null, valueFormatter: null, width: 140, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
      ];

      let spend_suppliers = [
        {field: "id", hide: true, headerName: optionsObject.customOptions['supplier__id'], type: 'numericColumn', sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "name", hide: false, headerName: optionsObject.customOptions['supplier__name'], type: null, sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {field: "norm", hide: false, headerName: optionsObject.customOptions['supplier__norm'], type: null, sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {field: "group", hide: false, headerName: optionsObject.customOptions['supplier__group'], type: null, sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {field: "description", hide: true, headerName: optionsObject.customOptions['supplier__description'], type: null, sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: true, stopEditingWhenGridLosesFocus: true},
        {field: "total_transaction_spend", hide: false, headerName: "Total Spend", type: 'numericColumn', value: true, editable: false,  sort: null, valueFormatter: roundFormatter, width: 400, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, stopEditingWhenGridLosesFocus: true},
        {field: "transaction_count", hide: false, headerName: "Transaction Count", type: 'numericColumn', value: true, editable: false,  sort: null, valueFormatter: roundFormatter, width: 400, enablePivot: true, aggFunc: 'sum', enableRowGroup: true, stopEditingWhenGridLosesFocus: true},
        {field: "created_at", hide: false, headerName: optionsObject.customOptions['supplier__created_at'], type: null, sort: null, valueFormatter: dateFormatter, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "updated_at", hide: false, headerName: optionsObject.customOptions['supplier__updated_at'], type: null, sort: null, valueFormatter: dateFormatter, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "custom_fields", hide: true, headerName: optionsObject.customOptions['supplier__custom_fields'], type: null, sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "foreign_data", hide: true, headerName: optionsObject.customOptions['supplier__foreign_data'], type: null, sort: null, valueFormatter: null, width: 400, enablePivot: true, aggFunc: null, enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
      ]

      // let spend_dataset_analysis_categorization_breakdown = [
      //   {field: "level", hide: false, headerName: optionsObject.customOptions['grouped_transactions__level'], type: null, sort: null, valueFormatter: params => (optionsObject.customOptions['category__' + params.value] || params.value), width: 160},
      //   {field: "percent_classified_to", hide: false, headerName: optionsObject.customOptions['grouped_transactions__percent_classified_to'], type: null, sort: null, valueFormatter: threeDecimalFormatter, width: 134},
      //   {field: "total_spend", hide: false, headerName: optionsObject.customOptions['grouped_transactions__total_spend'], type: 'numericColumn', sort: null, valueFormatter: null, width: 149},
      //   {field: "transaction_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', sort: null, valueFormatter: null, width: 153},
      //   {field: "supplier_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__supplier_count'], type: 'numericColumn', width: 139},
      // ]
      let spend_dataset_analysis_categorization_breakdown = [
        {field: "level", hide: false, headerName: optionsObject.customOptions['grouped_transactions__level'], type: null, sort: null, valueFormatter: params => (optionsObject.customOptions['category__' + params.value] || params.value)},
        {field: "percent_classified_to", hide: false, headerName: optionsObject.customOptions['grouped_transactions__percent_classified_to'], type: 'rightAligned', sort: null, valueFormatter: percentThreeDecimalFormatter},
        {field: "total_spend", hide: false, headerName: optionsObject.customOptions['grouped_transactions__total_spend'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "transaction_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "supplier_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__supplier_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
      ]
      let spend_dataset_analysis_multi_cat_item_descriptions = [
        {field: "item_description", hide: false, headerName: optionsObject.customOptions['transactions__item_description'], type: null, sort: null, valueFormatter: null,
          cellClass: "navigating-filter-cell",
          onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['item_description_handler'](event)}},
        {field: "supplier_count", hide: true, headerName: optionsObject.customOptions['grouped_transactions__supplier_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "item_count", hide: true, headerName: optionsObject.customOptions['grouped_transactions__item_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "category_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__category_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "location_count", hide: true, headerName: optionsObject.customOptions['grouped_transactions__location_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "country_count", hide: true, headerName: optionsObject.customOptions['grouped_transactions__country_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter}
      ]

      let spend_dataset_analysis_multi_cat_suppliers = [
        {field: "supplier_id", hide: true, headerName: optionsObject.customOptions["transaction__supplier_id"], type: 'numericColumn'},
        {field: "supplier__name", hide: false, headerName: optionsObject.customOptions['supplier__name'],
           cellClass: "navigating-filter-cell",
           onCellClicked: (event) => {optionsObject['metadata'] && optionsObject['metadata']['supplier_name_handler'](event)}},
        {field: "supplier_count", hide: true, headerName: optionsObject.customOptions['grouped_transactions__supplier_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "item_count", hide: true, headerName: optionsObject.customOptions['grouped_transactions__item_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "category_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__category_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "location_count", hide: true, headerName: optionsObject.customOptions['grouped_transactions__location_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "country_count", hide: true, headerName: optionsObject.customOptions['grouped_transactions__country_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter}
      ];

      let spend_dataset_analysis_file_summary = [
        {field: "file_name", hide: false, headerName: optionsObject.customOptions["grouped_transactions__file_name"]},
        {field: "total_spend", hide: false, headerName: 'Dollars', type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter, width: 140, enablePivot: false, aggFunc: 'sum', enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "transaction_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__transaction_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter, width: 140, enablePivot: false, aggFunc: 'sum', enableRowGroup: true, editable: false, stopEditingWhenGridLosesFocus: true},
        {field: "supplier_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__supplier_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter},
        {field: "percent_classified_to", hide: false, headerName: optionsObject.customOptions['grouped_transactions__percent_classified_to'], comparator: numericComparator, sort: null, valueFormatter: percentThreeDecimalFormatter},
        {field: "country_count", hide: false, headerName: optionsObject.customOptions['grouped_transactions__country_count'], type: 'numericColumn', comparator: numericComparator, sort: null, valueFormatter: roundFormatter}
      ];

      // Unflatten custom fields for dataset analysis page
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'spend_category' ) {
        let spendCustomFields = displayCustomFields({propertyLevel: 'schema_definition', nestedFieldName: 'category.custom_fields'}, optionsObject, false, false);
        spend_dataset_analysis_multi_cat_item_descriptions = spend_dataset_analysis_multi_cat_item_descriptions.concat(spendCustomFields);
        spend_dataset_analysis_multi_cat_suppliers = spend_dataset_analysis_multi_cat_suppliers.concat(spendCustomFields);
      };
      if (optionsObject.hasOwnProperty('dataType') && optionsObject.dataType == 'spend_transaction' ) {
        let spendCustomFields = displayCustomFields({propertyLevel: 'schema_definition', nestedFieldName: 'custom_fields'}, optionsObject, false);
        spend_transactions = spend_transactions.concat(spendCustomFields)
      };

			// iterate through schema for custom columns
			function displayCustomFields(functionProperties, optionsObjectParam, isEditable, isLegacy = false, isHide = false) { //ie params: {propertyLevel: 'item_level', nestedFieldName: 'item_groups'}
				let fieldList = [];

				//check for existence of a customFieldSchema before operating with it
				if(optionsObjectParam.customFieldSchema && optionsObjectParam.customFieldSchema[functionProperties.propertyLevel]){
					//loop thru every item_level item group and display.. find the correct schema element from the passed in param
					Object.keys(optionsObjectParam.customFieldSchema[functionProperties.propertyLevel]).forEach(schemaElement => {
            let fieldName = '';
            //check if fieldName is made for legacy mssql setup or regular
            if(isLegacy){
              //field for mssql
              //get the field name in item_groups.item_group_3 format to drill into json from db
              fieldName = optionsObjectParam.customFieldSchema[functionProperties.propertyLevel][schemaElement]['flex_field_name'];
            } else {
              //regular, non mssql field
              //get the field name in item_groups.item_group_3 format to drill into json from db
              fieldName = functionProperties.nestedFieldName + '.' + schemaElement;
            }

						//get column type from jsonSchema
						let columnType = optionsObjectParam.customFieldSchema[functionProperties.propertyLevel][schemaElement]['schema']['type'];
						//convert jsonSchema type to AG grid type
						let agColumnType = columnType.replace('string', 'stringColumn').replace('numeric', 'numericColumn').replace('number', 'numericColumn').replace('date', 'dateColumn');
            let fieldToAdd = {field: fieldName, headerName: optionsObjectParam.customFieldSchema[functionProperties.propertyLevel][schemaElement]['field_alias'],
            							hide: isHide, enableRowGroup: true, enablePivot: true, enableValue: true, type: agColumnType, editable: isEditable, stopEditingWhenGridLosesFocus: isEditable};
            //console.log('field', fieldToAdd);
            if(agColumnType == 'numericColumn') {
              fieldToAdd = {...fieldToAdd, ...{valueFormatter: customDecimalFormatter(optionsObjectParam.customFieldSchema.product_decimal_spots)}};
            };
						//add column to array
						fieldList.push(fieldToAdd);
					});//end forEach loop
					console.log('custom field list', fieldList);
				};// end existence check if
				return fieldList;
			}


      let columnDefs = []
      //loop thru every element in strings given and turn them into variables of column definitions
			optionsObject.columnArray.forEach(function(element) {
			    columnDefs = columnDefs.concat(eval(element))
			});

  		//return data after promise completes
  		return columnDefs
  	}

		//return data of the call
		return columnLogic(optionsObject);
		//optionsObject.customOptions, optionsObject.columnArray, optionsObject.projectType, optionsObject.isScenario
	}
}

export default GridColumn
