import React from 'react';
import PropTypes from 'prop-types';

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from '../HCGlobal';

import Highcharts from 'highcharts';

const unflattenChartData = (data) => {
  // Get points grouped by their outer name
  let outputHash = {};
  for (var i = 0; i < data.length; i++) {
    let {outer_name, ...point} = data[i];
    if (!(Object.keys(outputHash).includes(outer_name))){
      outputHash[outer_name] = [];
    }
    outputHash[outer_name].push(point);
  }
  // Manipulate it into the format
  // [{name: "...", data: [{name: <pointName>, y: <pointVal>}]},
  //  {name: "...", data: [{name: <pointName>, y: <pointVal>}]}]
  let outputList = [];
  Object.entries(outputHash).forEach((nameDataPair) => {
    let [name, data] = nameDataPair;
    outputList.push({name: name, data: data});
  });
  return outputList;
};

//basic barchart options.. not using react library. Highcharts already loaded in asset pipeline
class MultiLineChart extends React.Component {
  //fires on initial mount
  componentDidMount() {
    setHighChartsGlobals();
    this.loadChart();
  }

  // //mount data ON CHANGE
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lineData[0] !== this.props.lineData[0]) {
      setHighChartsGlobals();
      this.loadChart();
    } //end if statement state check
  } //end component did update

  //load chart function with options and cart drawer
  //issue with separating options and chart drawer.. probably something to do with it being a js drawer.. and maybe an async issue
  loadChart = () => {
    // let seriesObj = [];
    // if (~Array.isArray(this.props.lineData)){
    //   // CASE: No Data (prop undefined or null or non-array)
    //   console.warning("MultiLineChart expects an array for property lineData, recieved", this.props.lineData);
    // } else if (this.props.lineData.length === 0) {
    //   // CASE: Empty Series (don't know if we have no data, or no series)
    //   console.warning("No Data Recieved For MultiLineChart");
    // } else if (~Array.isArray(this.props.lineData[0])) {
    //   // CASE: Series of data (individual points)
    //   this.props.lineData.forEach(function (dataPoint, ix) {
    //     // console.log(index);
    //     seriesObj.push({allowPointSelect: true, data: dataPoint});
    //   });
    // } else {
    //   // CASE: Series of series (multiple lines)
    //   this.props.lineData.forEach(function (dataSeries, ix, arr) {
    //     el.forEach()
    //     seriesObj.push({allowPointSelect: true, data: seriesData})
    //   })
    // }
    let seriesObj = [];


    (unflattenChartData(this.props.lineData) || []).forEach(function(data_obj, index) {
      if ('data' in data_obj) {
        seriesObj.push({...{allowPointSelect: true},
                        ...data_obj})
      } else {
        console.log(index);
        seriesObj.push({ allowPointSelect: true, data: data_obj});
      }
    });

    //in SQL in order to use 2 series.. select distinct dates from all series, then dense rank them to get the x value to put into the series.
    //run an sql query for every data series, then feed them into the lineData Object
    //example data series with 2 series..
    // seriesObj = [
    //  {
    //    // name: "series a",
    //    allowPointSelect: true,
    //    //example data
    //    data: [{"name":"2018-01","y":490037843.0,"x":201801},{"name":"2018-02","y":708813420.0,"x":201802}]
    //  },
    //  {
    //    // name: "b ser",
    //    allowPointSelect: true,
    //    //example data
    //    data: [{"name":"2018-01","y":590037843.0,"x":201801},{"name":"2019-01","y":208813420.0,"x":201901}]
    //    , color: '#42A5F5'
    //  }
    // ]

    var options = {
      chart: {
        //can send in 'line' or 'area' (filled in bg)
        type: this.props.lineType
      },
      colors: ['#1565C0', '#29B6F6', '#26C6DA', '#26A69A', '#5C6BC0',
      '#7E57C2', '#AB47BC', '#EC407A', '#FF7043', '#8D6E63', '#03A9F4',
      '#ef5350', '#03A9F4', '#00BCD4', '#009688', '#9E9E9E', '#4CAF50',
      '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722',
      '#795548', '#607D8B', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5'],
      subtitle: {
        text: this.props.lineTitle
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'category',
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: this.props.lineYTitle
        }
      },
      series: seriesObj,
      //OVERWRITING HOW THE REST OF THE CHARTS ARE MADE:
      legend: {
        enabled: true //this.props.enableSeries || false
      },

      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: e => {
                //console.log(e.point.name); console.log(e.point.category); console.log(e.point.y);
                //send back the filterParam assigned in props - this names the field being sent
                //send back the e.point.name BUT in react-select format, so the selector could read it right out of mobxState
                console.log(e.point.name);
                this.props.handleFilterFunction(this.props.filterParam, [{label: `${e.point.name}`, value: e.point.name }]);
              }
            }
          }
        }
      }
    }; //end options
    //use javascript to render to id.. id name passed via props
    var hcLineData = Highcharts.chart(this.props.renderInDivId, options);
  }; //end loadChart

  render() {
    return <div id={this.props.renderInDivId} style={{ height: this.props.height }}></div>;
  }
}

export default MultiLineChart;
