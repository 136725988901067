
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
//http requests
import axios from 'axios'

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      //initial state..
      this.state = {
        loading: true, //render will not show anything on load
        redirect: false, //redirect is false, this will get changed if not authenticated
        userId: null,
        subdomain: null,
      };
    }
    //NEED TO CHECK BODY OF RESPONSE FOR 23 STATUS CHECK
    componentDidMount() {
      axios.get('/security/check_token')
        .then(response => {
          //check that the authentication returns http status 200 and the body contains the statusConfirmation === 1 just in case
          if (response.status === 200 && response.data.statusConfirmation === 1) {
            console.log("Successful auth");
            this.setState({ loading: false, redirect: false, user: response.data.userId, subdomain: response.data.subdomain });

          } else {
            console.log("No auth");
            this.setState({ loading: false, redirect: true });
          }
        })
        .catch(err => {
          console.error(err);
          console.log("in err");
          this.setState({ loading: false, redirect: true });
        });


    }
    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return null; //return null while loading
      }
      if (redirect) {
        return <Redirect to="/" />;
        //window.location.replace('/eclogin');
      }
      //not loading and not redirecting conditions checked, only in that case render
      if (!redirect && !loading) {
        return (
          <React.Fragment>
            <ComponentToProtect {...this.props} />
          </React.Fragment>
        );
      } else {
        return null; //catch all return null
      };
    }
  }
}
