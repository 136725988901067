import React from "react";
import { render } from 'react-dom';

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import {zeroNull, extractToken} from "../../../services/Utilities";

// Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
//const projectId = extractToken(window.location.pathname, '/', 3);


class Name extends React.Component{
    projectId = extractToken(window.location.pathname, '/', 3);
    mobxState = {
        projectName: null
    };

    //mount data through http requests
		componentDidMount() {
            this.fetchData(); //fetch data to get project name
        };

 //////////////////HTTP//////////////////
    fetchData = () => {
    axios
      .get(`/v1/projects/${this.projectId}/`)
      .then(response => {
        this.mobxState.projectName = response.data.name;
      })
      .catch(console.log.bind(console)); //error catcher
    }; //end of fetchdata()

//////////////////END OF HTTP////////////

//main rendering of component
    render(){
        return(
            <React.Fragment>
                {this.mobxState.projectName}
            </React.Fragment>
        )
    };
};//end of component


// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Name, {
	mobxState: observable
})

export default observer(Name);
