import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
import NumericInput from 'react-numeric-input';
// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
const projectId = extractToken(window.location.pathname, '/', 3);

class DiscountPercentage extends React.Component {

  mobxState = {
    discountPercentageAmount: [], //value from input field in case it's needed in state
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    //this.fetchData();
  }
  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    //Will load data from Threshold column of discount_constraints table
    ////////////////////
    //FORM LOAD
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  //adds percent sign after input number
  myFormat(num) {
      return num + "%";
  };

  render(){

    return(
        <div>
            <b>Discount in Percent</b>
            <NumericInput 
            onChange={(e) => this.props.handleFormChange(this.props.formField, e)}
            className="form-control"
            name="discountPercentage"
            min={0}
            max={100}
            step={1}
            placeholder="Enter amount (1-100)"
            format={this.myFormat}
            />
        </div>
    )
  }

}

decorate(DiscountPercentage, {
    mobxState: observable
})

export default observer(DiscountPercentage);