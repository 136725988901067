import React from 'react';
//Loops and renders filters
import FilterRender from '../filters/FilterRender';
//Dock sidebar library
import Dock from 'react-dock';

// DockRender sidebar dock
// PROPERTIES
// ----------
// PROP DRILLED PROPERTIES
// -----------------------
// value
// options: Options for selects
// closeMenuOnSelect [Boolean] - Whether to close sidebar on select
// maxMenuHeight
// handleFilter [Function] - Callback for system filters
// handleCustomFilter [Function] - Callback for customFilters
//
// customOptions
// filterModelNames

class DockRenderer extends React.Component {
  static defaultProps = {
    // Current Filters----------------------------------
    value: {},
    // Select Names-------------------------------------
    filterModelNames: [],
    customFilterModelNames: [],
    // Options------------------------------------------
    options: [],
    customFilterOptions: [],
    // Widget Types ------------------------------------
    filterWidgetTypes: {},
    customFilterWidgetTypes: {},
    // Handlers-----------------------------------------
    handleFilter: [],
    handleCustomFilter: [],
    // Visual options-----------------------------------
    customOptions: [],
    // Callbacks----------------------------------------
    closeMenuOnSelect: [],
    maxMenuHeight: []
  };
  render() {
    return (
      <div>
        <Dock
          position={this.props.position}
          size={this.props.size}
          dimMode={this.props.dimMode}
          isVisible={this.props.isVisible}
          onVisibleChange={this.props.handleVisibleChange}
          onSizeChange={this.props.onSizeChange}
          fluid={this.props.fluid}
          zIndex={1030}
        >
          {/*Do not set zIndex >= 1050*/}
          <div id='top_line'></div>

          <div className='col-lg-12' style={{ paddingTop: '3px' }}>
            <div onClick={this.props.toggleDock} className='filter_toggle'>
              {' '}
              <h5>
                <i className='glyphicon glyphicon-chevron-right pull-right'></i>
              </h5>
            </div>
            <h4>
              <b>Filters</b>
            </h4>
            <hr />
          </div>

          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-12'>
                <button onClick={this.props.handleClear} className='btn btn-block btn-clear'>
                  <span className='glyphicon glyphicon-fast-backward'></span>
                  &nbsp; <b>Clear Selections</b>
                </button>
              </div>
            </div>
            <FilterRender
              // Current Filters----------------------------------
              value={this.props.value }
              // Select Names-------------------------------------
              filterModelNames={this.props.filterModelNames}
              customFilterModelNames={this.props.customFilterModelNames}
              // Widget Types-------------------------------------
              filterWidgetTypes={this.props.filterWidgetTypes}
              customFilterWidgetTypes={this.props.customFilterWidgetTypes}
              // Options------------------------------------------
              options={this.props.options}
              customFilterOptions={this.props.customFilterOptions}
              // Handlers-----------------------------------------
              handleFilter={this.props.handleFilter}
              handleCustomFilter={this.props.handleCustomFilter}
              // Visual options-----------------------------------
              customOptions={this.props.customOptions}
              // Callbacks----------------------------------------
              closeMenuOnSelect={this.props.closeMenuOnSelect}
              maxMenuHeight={this.props.maxMenuHeight}
              // Search Function ---------------------------------
              optionSearchFunctions={this.props.optionSearchFunctions}
            />
          </div>
        </Dock>
      </div>
    );
  }
}

export default DockRenderer;
