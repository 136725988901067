// Imports
// Adapted from https://react-select.com/async
import React from 'react';

//state
import { decorate, observable, computed, action, trace } from 'mobx';
import { observer } from 'mobx-react';

import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, Select, Spin } from 'antd';

// There's some mapping that needs to go on here
// For the sake of compatibility, I'm going to assume we're getting:
//   -- Options: [{value+label}]
//   -- Value:   [{value+label}]

// Options coming in are mapped into their index (0-N)
// We're maintaining
//  - mapping
//  - marks
//  - Min selected
//  - Max selected

// getSelectedIndices :: [{value+label}] => [{value+label}] => [Int]
// Get list of indexes selected by comparing to lists to see what
// indexes
function getSelectedIndices(options, values) {
  let out = [];
  let toMatch;
  if (Array.isArray(options) && Array.isArray(values) && options.length > 0 && values.length > 0) {
    if (values.length === 0) {
      toMatch = [];
    } else if (values.length === 1) {
      toMatch = [values[0]];
    } else {
      toMatch = [values[0], values[values.length - 1]];
    }
    toMatch = toMatch.map(option => option.value);
    for (var i = 0; i < options.length; i++) {
      if (toMatch.includes(options[i].value)) {
        out.push(i);
      }
    }
  } else {
    return [];
  }
  return out;
}

class AntdRangeSelect extends React.Component {
  state = {
    visuallySelectedIndices: getSelectedIndices(this.props.options, this.props.value) || [],
    actuallySelectedIndices: getSelectedIndices(this.props.options, this.props.value) || []
  };
  // Setup            -----------------------------------------------------------------
  // Update the state when either options or props change
  // This can get tricky since mobx doesn't notice when individual items in a watched array change.
  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.options) !== JSON.stringify(this.props.options) ||
      JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value)
    ) {
      let selected = getSelectedIndices(nextProps.options, nextProps.value);
      this.setState({
        actuallySelectedIndices: selected,
        visuallySelectedIndices: selected
      });
    }
  }
  // Change Handlers -----------------------------------------------------------------
  handleVisibleChange = ixs => {
    this.setState({ visuallySelectedIndices: ixs });
  };
  handleActualChange = ixs => {
    let selectedVals = this.props.options.slice(ixs[0], ixs[1] + 1).map(x => ({ value: x.value, label: x.label }));
    // Set indices component is tracking
    this.setState({ actuallySelectedIndices: ixs });
    this.handleVisibleChange(ixs);
    // Update values invoking fetchData
    this.props.onChange(selectedVals);
  };
  render = () => {
    let currentOptions = this.props.options || []; // [label+value]
    let currentValues = this.props.value || []; // [label+value]
    let numChoices = currentOptions.length;
    let marks = {};

    // Mark the end points
    if (currentOptions.length > 0) {
      [0, currentOptions.length - 1].forEach(ix => {
        marks[ix] = {
          style: {},
          label: (currentOptions[ix] && currentOptions[ix].label) || ''
        };
      });
    }
    // Build tooltips
    // These get kinda complicated, but general idea is that they're offset in the
    // same way that the antd slider would, but using percentages rather than pixels
    // (Antd was running into an issue where tooltips wouldn't update when resizing the dock)
    let tooltips = [];
    if (numChoices > 0) {
      for (var i = 0; i < this.state.visuallySelectedIndices.length; i++) {
        let ix = this.state.visuallySelectedIndices[i];
        let opt = currentOptions[ix];
        if (typeof opt !== 'undefined') {
          let label = opt.label;
          let key = `${this.props.name}-range-selector-label-${i}`;
          let pct = ((100 / (numChoices - 1)) * ix).toFixed(5) + '%';
          tooltips.push(
            <div key={key} className='tooltip-container' style={{ left: pct }}>
              <div className='tooltip-label'>{label}</div>
            </div>
          );
        }
      }
    }

    return (
      <div className='custom-range-selector'>
        <div className='custom-range-selector-parts'>
          <div className='tooltip-part-container'>
            <div className='tooltip-buffer'>
              <div className='tooltip-tray'>
                {tooltips}
              </div>
            </div>
          </div>
          <div className='antd-part-container'>
            <Slider
              min={0}
              max={numChoices - 1}
              marks={marks}
              range={true}
              tooltipVisible={false}
              tipFormatter={ix => (currentOptions[ix] && currentOptions[ix].label) || ''}
              onChange={this.handleVisibleChange}
              onAfterChange={this.handleActualChange}
              defaultValue={this.state.actuallySelectedIndices}
              value={this.state.visuallySelectedIndices}
            />
          </div>
        </div>
      </div>
    );
  };
}

decorate(AntdRangeSelect, {
  state: observable,
  handleVisibleChange: action,
  handleActualChange: action
});
export default observer(AntdRangeSelect);
