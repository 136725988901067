import React, { useEffect, useRef } from 'react';


export const ErrorModal = ({ isOpen, onClose, modalContent }) => {

    if (!isOpen) return null;

    return (
        <div className='error-modal-overlay'>
            <div className='error-modal-container'>
                <div className='error-modal-header'>
                <strong className='font-weight-bold' style={{fontSize: '1.5em'}}>
                    Error
                </strong>
                <button className='error-modal-closeButton' onClick={onClose}>&times;</button>
                </div>
                <div style={{marginTop: '55px'}}>
                <p style={{marginTop: '5px', fontSize: '16px'}}>
                    {modalContent}</p>
                <p>Note:</p>
                <ul style={{fontSize: '12px', paddingLeft: 8, marginLeft: 8}}>
                    <li>Please attempt the process again using <mark>'RETRY'</mark> button.</li>
                    <li>If the problem persists, kindly direct your inquiry to <a href="mailto:getsupport@simfoni.com">"getsupport@simfoni.com".</a></li>
                </ul>
                </div>
            </div>
        </div>
    )
}
