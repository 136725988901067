import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
//  !!! RANDOM BUG WITH BELOW, DOESN'T ALWAYS SHOWS PROJECT, SOMETIMES EVALUATES TO UNDEFINED.. to fix this, changed using this to this.props.data.project_id
// const projectId = extractToken(window.location.pathname, '/', 3);

export default class ScenarioSettingsRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <span>
        <Link to={'/sourcing/projects/'+this.props.data.project_id+'/scenarios/'+this.props.data.id+'/ScenarioManager'} >
          <span className="fa-layers fa-fw fa-2x link_style">
            <FontAwesomeIcon icon={['fal', 'cogs']} style={{marginTop: '6px'}} />
          </span>
        </Link>
      </span>
    );
  }
};
