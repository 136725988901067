import React from 'react';
import PropTypes from 'prop-types';

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from '../HCGlobal';

import Highcharts from 'highcharts/highstock';

//basic barchart options.. not using react library. Highcharts already loaded in asset pipeline
class ScrolledBarChart extends React.Component {
  //fires on initial mount
  componentDidMount() {
    setHighChartsGlobals();
    this.loadChart();
  }
  componentWillUnmount() {
    // This feels dirty, but gotta do it to useHTML with labels
    // When using highcharts {tooltip: {outside: true}} option,
    // it appends the tooltip to the outside body of the page.
    // It doesn't automatically remove it when chart is rerendered.
    // Manually remove them.
    let nodes = document.querySelectorAll('.highcharts-tooltip-container');
    nodes = Array.prototype.slice.call(nodes);
    nodes.map(el => el.remove())
  }

  // //mount data ON CHANGE
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.barData !== this.props.barData) {
      setHighChartsGlobals();
      this.loadChart();
    } //end if statement state check
  } //end component did update

  //load chart function with options and cart drawer
  //issue with separating options and chart drawer.. probably something to do with it being a js drawer.. and maybe an async issue
  loadChart = () => {
    console.log('Loading bar chart id', this.props.renderInDivId);
    let that = this;
    let maxToUse = Math.min(this.props.columns_shown - 1 || 0, (this.props.barData.length || 0) - 1);
    let columnsInData = this.props.barData.length;
    let columnsRequested = this.props.columns_shown;
    let declareScrollBar = columnsRequested < columnsInData; // Don't show scrollbar if there are less than the requested number of suppliers

    var options = {
      chart: {
        type: 'bar'
      },
      subtitle: {
        text: this.props.barTitle
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'category',
        categories: this.props.barData.map(x => x.name),
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          step: 1,
          formatter: function(){
            if (/^\d+$/.test(`${this.value}`)){
              let record = that.props.barData[this.value];
              return record.name;
            } else {
              return this.value;
            }
          },
          // Wrap labels if visualization_client_configs.wrap_label = true
          ...(
            this.props.wrapLabel
            ? {
              useHTML: true,
              allowOverlap: true,
            }
            : {}
          ),
          style: {
            ...(
              this.props.wrapLabel
              ? {
                wordBreak: 'break-word',
                textOverflow: 'allow',
                textAlign: 'right',
              }
              : {}
            )
          }
        },
        minorTickLength: 0,
        tickLength: 0,
        // Set the chart to scroll if columns_shown is provided
        ...(declareScrollBar
          ? {
              scrollbar: {
                enabled: true,
                barBackgroundColor: '#c1c1c1',
                barBorderColor: '#c1c1c1',
                barBorderRadius: 7,
                barBorderWidth: 2,
                buttonArrowColor: '#333333',
                buttonBackgroundColor: '#fafafa',
                buttonBorderColor: '#fafafa',
                buttonBorderRadius: 0,
                buttonBorderWidth: 1,
                // height: 20/14,
                liveRedraw: undefined,
                margin: 1,
                minWidth: 3,
                rifleColor: '#c1c1c1',
                showFull: true,
                step: 0.2,
                trackBackgroundColor: '#fafafa',
                trackBorderColor: '#fafafa',
                trackBorderRadius: 0,
                trackBorderWidth: 2,
                zIndex: 3
              }
            }
          : {}),
        max: maxToUse
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        title: {
          text: this.props.barYTitle
        }
      },
      series: [
        {
          allowPointSelect: true,
          data: this.props.barData
        }
      ],
      legend: {
        enabled: false
      },
      tooltip: {
        outside: true
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: e => {
                //console.log(e.point.name); console.log(e.point.category); console.log(e.point.y);
                //send back the filterParam assigned in props - this names the field being sent
                //send back the e.point.name BUT in react-select format, so the selector could read it right out of mobxState
                this.props.handleFilterFunction(this.props.filterParam, [
                  { label: `${e.point.name}`, value: e.point.name }
                ]);
              }
            }
          }
        }
      }
    }; //end options
    console.log('BAR CHART OPTIONS', options);
    //use javascript to render to id.. id name passed via props
    var hcBarData = Highcharts.chart(this.props.renderInDivId, options);
  };

  render() {
    return <div id={this.props.renderInDivId} style={{ height: this.props.height }}></div>;
  }
}

export default ScrolledBarChart;
