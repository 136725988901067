import React from 'react';
import PropTypes from 'prop-types';

//hc setHighChartsGlobals function
import { setHighChartsGlobals } from '../HCGlobal';

import Highcharts from 'highcharts';

//basic barchart options.. not using react library. Highcharts already loaded in asset pipeline

// PROPS:
// barYTitle: Determines labels for axis
// barZ
class BarChart extends React.Component {
  //fires on initial mount
  componentDidMount() {
    setHighChartsGlobals();
    this.loadChart();
  }

  componentWillUnmount() {
    // This feels dirty, but gotta do it to useHTML with labels
    // When using highcharts {tooltip: {outside: true}} option,
    // it appends the tooltip to the outside body of the page.
    // It doesn't automatically remove it when chart is rerendered.
    // Manually remove them.
    let nodes = document.querySelectorAll('.highcharts-tooltip-container');
    nodes = Array.prototype.slice.call(nodes);
    nodes.map(el => el.remove());
  }

  // //mount data ON CHANGE
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.barData !== this.props.barData) {
      setHighChartsGlobals();
      this.loadChart();
    } //end if statement state check
  } //end component did update

  //load chart function with options and cart drawer
  //issue with separating options and chart drawer.. probably something to do with it being a js drawer.. and maybe an async issue
  loadChart = () => {
    console.log('Loading bar chart id', this.props.renderInDivId);
    var filterParamPresent = !(this.props.filterParam === null || this.props.filterParam === '');
    var options = {
      chart: {
        type: 'column',
        inverted: true
      },
      subtitle: {
        text: this.props.barTitle
      },
      title: {
        text: null
      },
      xAxis: {
        type: 'category',
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0,
        title: {
          text: this.props.xTitle
        },
        labels: {
          ...(
            this.props.wrapLabel
            ? {
              useHTML: true,
              allowOverlap: true,
            }
            : {}
          ),
          style: {
            ...(
              this.props.wrapLabel
              ? {
                wordBreak: 'break-word',
                textOverflow: 'allow',
                textAlign: 'right',
              }
              : {}
            )
          }
        }
      },
      yAxis: [
        {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          title: {
            text: this.props.yTitle
          },
          opposite: true,
        },
        {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          title: {
            text: this.props.zTitle,
          }
        }
      ],
      series: [
        { allowPointSelect: (filterParamPresent ? true : false), name: this.props.yTitle, data: this.props.barData },
        { allowPointSelect: (filterParamPresent ? true : false), name: this.props.zTitle, data: this.props.barData.map(x => {return {name: x.name, y: x.z}}), yAxis: 1,
          color: '#5CB85C'}
      ],
      legend: {
        enabled: this.props.enableLegend
      },
      tooltip: {
        outside: true // Render tooltip outside svg element box
      },
      plotOptions: {
        series: {
          cursor: (filterParamPresent ? 'pointer' : ''),
          point: {
            events: {
              click: e => {
                //console.log(e.point.name); console.log(e.point.category); console.log(e.point.y);
                //send back the filterParam assigned in props - this names the field being sent
                //send back the e.point.name BUT in react-select format, so the selector could read it right out of mobxState
                if (filterParamPresent) {
                  this.props.handleFilterFunction(this.props.filterParam, [
                    { label: `${e.point.name}`, value: e.point.name }
                  ]);
                }
              }
            }
          }
        }
      }
    }; //end options
    //use javascript to render to id.. id name passed via props
    var hcBarData = Highcharts.chart(this.props.renderInDivId, options);
  };

  render() {
    return <div id={this.props.renderInDivId} style={{ height: this.props.height }}></div>;
  }
}

export default BarChart;
