import React from "react";
import { render } from 'react-dom';

//other components built
import Badge from "../../../../badges/Badge";
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns

//filters
import DockRenderer from "../../../../filters/DockRenderer";
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls
import Select from 'react-select'; //select box for filter
import PlayButton from '../../shared/PlayButton'; //play button that executes scenario

//ag grid
import GridRenderer from "../../../../grids/GridRenderer";
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import {zeroNull, extractToken} from "../../../../services/Utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pull, cloneDeep, castArray } from 'lodash';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//scope selection form part
import ScopeSelection from '../../../../forms/sourcing/ScopeSelection';
import ConstraintAwardSelection from '../../../../forms/sourcing/ConstraintAwardSelection';
import ConstraintOperatorSelection from '../../../../forms/sourcing/ConstraintOperatorSelection';
import SupplierSelection from '../../../../forms/sourcing/SupplierSelection';

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

//file import form
import SpreadSheetImport  from '../../../../forms/sourcing/FileImportation/SpreadSheetImport'

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class SubsetConstraints extends React.Component {
		// Set the project url by splitting up the pathname by / and selecting the proper location of the project and scenario Id in url
		projectId = extractToken(window.location.pathname, '/', 3);
		scenarioId = extractToken(window.location.pathname, '/', 5);
		mobxState = {
			formState: {
				scope: {label:null, value:null},
				scopeValue: {label:null, value:null},
				awardOperator: {label:null, value:null},
				suppliersAwarded: null,
				formValid: false,
				showHelperIcons: false
			},
			customOptions: {
      },
			cardData: {},
			tableData: {
				"subset_constraints": []
			},
			selectedModels: {
			}, //multi select data model for http
			selectedOptions: {
			}, //multi select data model in filter value/label format
			dockOptions: {
				isVisible: false,
				size: 0.2
			}, //sidebar defaults
			pivotData: [],
			pivotState: [],
			pivotFilter: [],
			filterModelNames: [], //autobuilds filters and clear functions
			submitMessage: false, //controls message that confirms constraint was saved
			submitAllScopes: false, //changes submission if "Apply Constraint to All" is Selected
			scopeNames: [], //holds names of scope_values
			allScopesObj: [], //holds multiple objects to submit if Apply constraint to all is selected
			customSchemaRowCount: null, //default amount of rows from 'custom_schemas' table
			isLoadingTableData: false, //fired off when show enteries is selected
			inDeletion: false, //toggles icon and "Deleting selected rows" caption
		};

		//mount data through http requests
		componentDidMount() {
			//get the grid grow count then call the data loader to load data into the view
	    this.getCustomRowCount();
		}

		//////////////////HTTP/////////////////
		getCustomRowCount = () => {
	    axios
	      .get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } })
	      .then(response => {
	        this.mobxState.customSchemaRowCount = response.data.custom_schemas[0].schema_value;
	        this.fetchData()
	      })
	      .catch(console.log.bind(console)); //error catcher
	  }
		//data loader
		fetchData = () => {
		  //quick workaround -- pull simple dropdown value from nested component instead of passing around state
		  var table_rows = this.mobxState.customSchemaRowCount || window.$('#table_rows_dropdown').val();

		  //merge the filter model with the amount of rows selected
		  let models_with_rows = { ...this.mobxState.selectedModels, ...{ table_rows: table_rows } };
 		  // console.log("ROWS ", table_rows)
		  //set up custom options for components, then get data for components
		  // Merge System Aliases Into Custom Options (Keeping existing values)
		  grabSystemAliases().then(systemAliases => {
		    this.mobxState.customOptions = {
		      ...systemAliases,
		      ...(this.mobxState.customOptions || {}) // Prefer existing values
		    };
		  });

		  axios
		    .get('/v1/projects/' + this.projectId + '/project_field_schemas', { params: { project_id: this.projectId } })
		    .then(response => {
		      //get map of field labels from API
		      this.mobxState.customOptions = {
		        ...this.mobxState.customOptions,
		        ...(response.data || {})
		      };
		      //get values and the corresponding labels from jsonb data in db
		      let item_name = response.data.project_field_schemas[0].item_level; //get names from scopes

		      //scope type names (value and label)
		      let scope_fields_data = [];
		      Object.values(item_name).forEach(element => {
		        scope_fields_data.push({ value: element.field_name, label: element.field_alias });
		      });

		      //after ag grid is set up, get the rest of the data
		      //table data load
					this.mobxState.isLoadingTableData = true //loading project rows
		      axios
		        .get('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/subset_constraints', {
		          params: models_with_rows
		        })
		        .then(response => {
		          console.log("data response:");
		          console.log(response.data);
							this.mobxState.customSchemaRowCount = null; //set to null so value from dropdown is used
							this.mobxState.isLoadingTableData = false
		          //replace value in grid with label
		          response.data.subset_constraints.forEach(function(i) {
		            //find and replace scope value with label
		            for (let x of scope_fields_data) {
		              if (i.scope == x.value) {
		                i.scope = x.label;
		              }
		            }
		          });

		          this.mobxState.tableData = response.data;

		          //set the column definitions for ag grid, look into buildColumnDefs class to see options
		          this.mobxState.columnDefsSubsetConstraints = gridColumns.buildColumnDefs({
		            customOptions: this.mobxState.customOptions,
		            columnArray: ['subset_constraints'],
		            customFieldSchema: this.mobxState.customOptions.project_field_schemas[0],
		            dataType: 'subset_constraint' //special tag to signify that this is competition_constraint data
		          });
		          //autofit the table columns (hits the child function), this runs after the columns were created
		          this.autofitChild();
		        });
		    })
		    .catch(console.log.bind(console)); //error catcher

		}; //end fetchData()


		//FORM HANDLING FUNCTIONS
		//handle submission by sending the formState to server
		handleFormSubmit = (event) => {

			if (this.mobxState.submitAllScopes === false) {
				axios.post('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/subset_constraints', {
					scope: this.mobxState.formState.scope.value,
					scope_value: this.mobxState.formState.scopeValue.value,
					award_operator: this.mobxState.formState.awardOperator.value,
					suppliers: this.mobxState.formState.suppliersAwarded,
				})
					.then(() => { { this.mobxState.submitMessage = true } setTimeout(() => { this.mobxState.submitMessage = false }, 7000) }) //shows constraint saved message
					.then((response) => {
						console.log(response);
						this.fetchData();
						this.mobxState.formState.showHelperIcons = false;
					});
			} else {
				this.createFinalForm()
				console.log("All scopes OBJ ", this.mobxState.allScopesObj)
				for (let i = 0; i < this.mobxState.allScopesObj.length; i++) {
					axios.post('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/subset_constraints', {
						scope: this.mobxState.allScopesObj[i].scope,
						scope_value: this.mobxState.allScopesObj[i].scope_value,
					})
					// console.log(this.mobxState.allScopesObj[i])
					if (this.mobxState.allScopesObj.length - 1 === i) {
						// console.log("Constraints Submitted")
						{ this.mobxState.submitMessage = true }
						setTimeout(() => { this.mobxState.submitMessage = false }, 7000)
						this.fetchData();
						this.mobxState.formState.showHelperIcons = false;
					}
				} //end of for loop
				//shows constraint saved message
			}
		}

	//react-select form state handler
	handleFormChange = (field, value) => {
	  console.log(field, value);
	  console.log('ALL SCOPES ', this.mobxState.submitAllScopes);
	  this.mobxState.formState[field] = value;
	  //call validationResult
	  this.validateForm();
	};

	// Assign values from form state to scope values
	handleAllScopes = (allScopes) => {
		this.mobxState.submitAllScopes = true;
		let scopeNames = [];

		console.log("SCOPES ",allScopes)
		for (let scope of allScopes.value) {
			console.log(scope)
			if (scope === '999888777' || null) {

				continue;
			} else {
				scopeNames.push(scope)
			}
		}

		// scopeNames.pop() //remove null

		this.mobxState.scopeNames = [...scopeNames]
	}

	//form that gets submitted if Apply Constraint to all is selected
	createFinalForm = () => {
		let finalForms = [];
		let formState = cloneDeep(this.mobxState.formState) //remove proxy
		let { scopeValue, ...rest } = formState //remove scopeValue
		console.log("FORM STATE ", formState)

		let scopeNames = [...this.mobxState.scopeNames]
		for (let i = 0; i < scopeNames.length; i++) {
			console.log(scopeNames[i])
			finalForms.push({
				scope_value: scopeNames[i],
				scope: rest.scope.value,
			})
			this.mobxState.allScopesObj = [...finalForms] //form where each scope value has its own full form to submit
			// console.log(this.mobxState.allScopesObj)
		}
	}


	validateForm = () => {
		//just check that all form variables have a value
		// console.log("FORMSTATE ", this.mobxState.formState)
		if(
			this.mobxState.formState.scope.value != null
			&&
			this.mobxState.formState.scopeValue.value != null
		){
			this.mobxState.formState.formValid = true;
			this.mobxState.formState.showHelperIcons = true;
			//check that allows scopeValue to be null if scope is overall
		} else if(
				this.mobxState.formState.scope.value === 'overall'
				&&
				this.mobxState.formState.scopeValue.value == null
			){
				this.mobxState.formState.formValid = true;
				this.mobxState.formState.showHelperIcons = true;
		} else {
			this.mobxState.formState.formValid = false;
			this.mobxState.formState.showHelperIcons = false;
		};
		console.log(this.mobxState.formState);
	}
	//END FORM HANDLING

	deleteRequest = toSendArr => {
		this.mobxState.inDeletion = true
	  console.log('IN DELETION');
	  axios
	    .post('/v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/subset_constraints/batch_destroy', {
	      id_set: toSendArr
	    })
	    .then(response => {
				this.mobxState.inDeletion = false
	      console.log(response);
	      this.fetchData();
	    });
	};

	//////////////////END HTTP/////////////////

	//for sidebar dock to change sizes
	handleSizeChange = size => {
		this.mobxState.dockOptions.size = size;
	}

	//sidebar dock toggle
	toggleDock = () => {
		this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
	}

	//reloads the grid with new data after form from spreadsheet is submitted
	handleSheetSubmit=()=>{
		console.log("reloading grid")
		this.fetchData();
	};
	//resets submitAllScopes in state
	resetSelectAll = () =>{
		this.mobxState.submitAllScopes = false;
	}

	render() {

		const hcHeight = '300px';

		return (

			<div>

				{/* Small Left Toggle
				<div onClick={this.toggleDock} className="sidebar_button filter_toggle"><i className="glyphicon glyphicon-chevron-left"></i></div>
				*/}

				<div className="jumbotron jumbotron_full_width">
					<div className="jumbotron_full_width_container">
						<div className="row">
							<div className="col-sm-5">
								<span className="fa-layers fa-fw fa-2x">
									<FontAwesomeIcon icon={['fas', 'circle']} color="#009C9C" style={{ marginTop: '4px' }} />
									<span className="fa-layers-text fa-inverse" style={{ fontWeight: '900', fontSize: '14px', marginTop: '4px' }}>Ss</span>
								</span>
								<b className="dash_header">Manage subset constraints</b>
							</div>
							<div className="col-sm-2">
							</div>
							<div className="col-sm-3">
							</div>
							<div className="col-sm-2 gps">
								<span style={{ marginRight: '17px' }}><PlayButton /></span>
								<FontAwesomeIcon icon={['fad', 'location-arrow']} color="#53C8C5" transform='grow-15 left-10' style={{}} />
								<Link to={'/sourcing/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/ScenarioManager'} className='link_style'>
									Next: Constraints Overview
								</Link>
							</div>
						</div>
					</div>
				</div>

				{/* BADGES */}

				{/* FORM */}
				<div className="row">
					<div className="col-sm-12">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg clearfix">
								<b>Constraint Creation </b>
								<span className="pull-right">
									{/* Constraint Saved Message */}
									<Bounce left when={this.mobxState.submitMessage}>
										<b className="text-success" style={{ marginRight: '1.5em', marginTop: '4px' }}>
											Constraint Saved
											</b>
									</Bounce>

									<Bounce left when={this.mobxState.formState.showHelperIcons}>
										<span>
											<FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{ marginRight: '15px' }} />
										</span>
									</Bounce>
									<button type="button" disabled={!this.mobxState.formState.formValid} className="btn btn-success btn-md" onClick={this.handleFormSubmit}>
										<FontAwesomeIcon icon={['fal', 'save']} />&nbsp; Save Constraint
									</button>
									&nbsp;
											{/*
												<button type="button" className="btn btn-primary btn-md" onClick={this.handleFormSubmit}>
												<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Bulk Add
												</button>
											*/}

										{/* file importation button */}
											<button
												data-toggle="tooltip" data-placement="top" title="Import constraints from an Excel SpreadSheet or CSV file."
												onClick={() => { console.log("clicked") }}
												className="btn btn-primary"
												type="button"
												data-toggle="collapse"
												data-target="#importForm"
												aria-expanded="false"
												aria-controls="importForm">
												<FontAwesomeIcon icon={['fal', 'file-import']} />&nbsp; Import Excel/CSV
												</button>

								</span>
							</div>
							{/* collapsing form for importing data from external excel or csv file */}
							<div className="row">
								<div className="col-lg-12">
									<div className="collapse multi-collapse" id="importForm">
										<div className="card card-body">
											{/* holds import excel and csv import forms and buttons */}
											<SpreadSheetImport
												requiredCols={['scope', 'scope value']}
												tableName={"subset_constraints"} //name of DB that the form will submit to
												handleSheetSubmit={this.handleSheetSubmit}
												systemFieldCols={['scope', 'scope_value']}
												isConstraint={true}
												projectId={this.projectId}
												scenarioId={this.scenarioId}
												optionalCols={[]}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="panel-body">

								<form>
									<div className="row">
										<div className="col-sm-12">
											<div className={'top_panel_info'}>Select a subset of data to optimize on</div>
										</div>
									</div>
									<br />

									<div className="row">
										<ScopeSelection
											projectId={this.projectId}
											handleFormChange={this.handleFormChange}
											scopeCssClass={"col-sm-4"}
											scopeValueCssClass={"col-sm-4"}
											onSelectAll={this.handleAllScopes}
											resetSelectAll={this.resetSelectAll} //keeps select select all from applying between dropdown selections
											onlyCustomFieldScope={true}
										/>
										<div className="col-sm-4">
										</div>
									</div>
									<br />


								</form>

							</div>
						</div>
					</div>{/* END col-sm-12*/}
				</div>{/* END row*/}
				{/* END FORM*/}

				{/* PROJECT GRID */}
				<div className="row">
					<div className="col-lg-12">
						{/* AG Grid */}
						<GridRenderer
							gridHeight={"550px"}
							columnDefs={this.mobxState.columnDefsSubsetConstraints}
							rowData={this.mobxState.tableData.subset_constraints}

							fetchData={this.fetchData}
							handleClear={this.handleClear}
							toggleDock={this.toggleDock}
							pageId={8}

							savedState={this.mobxState.pivotState}
							isPivotMode={true}
							gridTitle={"Constraint Details"}

							setClick={click => this.clickChild = click}
							enableSetResizeChild={true}
							setResize={click => this.autofitChild = click}

							deleteRequest={this.deleteRequest}
							idColumn={"id"}

							modelsToUpdateArr={[{ url: 'v1/projects/' + this.projectId + '/scenarios/' + this.scenarioId + '/subset_constraints/', idName: "id" }]}

							singleClickEdit={true}
							suppressClickEdit={false}

							isSaveable={true}
							isAwardable={false}
							isDeletable={true}
							useCustomSchemaRowCount={true}
							isLoadingTableData={this.mobxState.isLoadingTableData}
							inDeletion={this.mobxState.inDeletion}
						/>

					</div>
				</div>{/* END CHART ROW */}

				<br />
				{/* Sidebar */}
				<DockRenderer
					position={'right'}
					size={this.mobxState.dockOptions.size}
					dimMode={'none'}
					isVisible={this.mobxState.dockOptions.isVisible}
					onVisibleChange={this.handleVisibleChange}
					onSizeChange={this.handleSizeChange}
					fluid={true}

					toggleDock={this.toggleDock}
					handleClear={this.handleClear}

					value={this.mobxState.selectedOptions}
					options={this.mobxState.cardData}
					closeMenuOnSelect={true}
					maxMenuHeight={400}
					handleFilter={this.handleFilter}
					customOptions={this.mobxState.customOptions}
					filterModelNames={this.mobxState.filterModelNames}
				/>

			</div>
		);
		}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(SubsetConstraints, {
	mobxState: observable
})

export default observer(SubsetConstraints);

// export default SubsetConstraints;
