import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///!!! USED IN THE SETUP AND PROJECTS MODULES SO FAR, BE CAREFUL EDITING, MAKE SURE IT DOESN'T BREAK OTHER FORMS!!!
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

class SchemaForm extends React.Component {

  render() {
    const formDisplay = []
    let i = 0;
    if (typeof this.props.projectFieldSchema[this.props.schemaLevel] !== 'undefined' && this.props.projectFieldSchema[this.props.schemaLevel]){
      Object.entries(this.props.projectFieldSchema[this.props.schemaLevel]).map(([key, value]) => {
        formDisplay.push(
          <tr key={i}>
            {/*
            <td>
              <b style={{fontSize: '14px', display: 'flex', alignItems: 'center', height: '40px'}}>Item {_.startCase(value.field_name)} </b>
            </td>
            */}
            <td>
              <Input
                value={value.field_alias}
                placeholder={value.field_alias || 'Enter custom field alias'}
                className="form-control"
                onChange={(e) => this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.field_alias', e.target.value)}
              />
            </td>
            <td>
              <Select
                value={{value: value.schema.type, label: value.schema.type}}
                onChange={e => this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.schema.type', e.value)}
                options={[{label:"numeric", value: "number"}, {label:"string", value: "string"}]}
                placeholder={'Select field type'}
                key={key}
              />
            </td>
            <td>
              {this.props.projectFieldSchema[this.props.schemaLevel][value.field_name]['schema']['type'] == 'string' &&

              <input
                value={value.schema.enum || ''} //provide a '' so value won't be null
                placeholder={'Optional: enter comma separated list'}
                className="form-control"
                onChange={(e) => {
                            let enumArray = []
                            if (e.target.value){
                              //make array values with entries if entry exists
                              enumArray = e.target.value.split(','); //convert list to array
                            } else {
                               //null the value if nothing is entered into input
                              enumArray = null;
                            };
                            this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.schema.enum', enumArray);
                          }}
                onBlur={(e) => {
                            let enumArray = []
                            if (e.target.value){
                              //make array values with entries if entry exists
                              enumArray = e.target.value.split(','); //convert list to array
                              //trim function below doesn't allow any spaces because its per letter, this needs to happen after the word is typed probably not per letter on change -- USED SPECIFICALLY IN ONBLUR.. using this in onChange doesn't allow user to enter any spaces as they're typing
                              enumArray = enumArray.map(string => string.trim()); //trim each value to take out extra spaces
                            } else {
                               //null the value if nothing is entered into input
                              enumArray = null;
                            };
                            this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.schema.enum', enumArray);
                          }}
              />

              || this.props.projectFieldSchema[this.props.schemaLevel][value.field_name]['schema']['type'] == 'number' &&
                <div>
                  <span>
                    Min:
                    <InputNumber
                      type={'number'}
                      value={value.schema.minimum}
                      placeholder={'Optional'}
                      onChange={(e) => {this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.schema.minimum', e)}}
                    />
                  </span>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span>
                    Max:
                    <InputNumber
                      type={'number'}
                      value={value.schema.maximum}
                      placeholder={'Optional'}
                      onChange={(e) => {this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.schema.maximum', e)}}
                    />
                  </span>
                </div>
              }
            </td>
            <td>
              <InputNumber
                type={'number'}
                value={value.display_order}
                onChange={(e) => {this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.display_order', e)}}
              />
            </td>
            <td>
              <div style={{marginTop: '7px'}}>
                <Checkbox
                  onChange={(e) => {this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.hidden', !e.target.checked)}}
                  checked={!value.hidden}
                >
                </Checkbox>
              </div>
            </td>
            <td>
              <div style={{marginTop: '7px'}}>
                <Checkbox
                  onChange={(e) => {this.props.handleFormChange(this.props.schemaLevel+'.'+value.field_name+'.required', e.target.checked)}}
                  checked={value.required}
                >
                </Checkbox>
              </div>
            </td>
            <td>
              <div>
                <span>
                  <a data-toggle="collapse" href={'#modal_'+value.field_name+'_'+this.props.schemaLevel}>
                    <span className="glyphicon glyphicon-remove-circle modal_glyphi text-danger" aria-hidden="true" style={{marginTop: '10px'}}></span>
                    <span> Remove </span>
                  </a>
                </span>
                <div id={'modal_'+value.field_name+'_'+this.props.schemaLevel} className="panel-collapse collapse">
                  <div>
                    Delete field and all related data?
                    &nbsp;
                    <a data-toggle="collapse" href={'#modal_'+value.field_name+'_'+this.props.schemaLevel} onClick={() => this.props.handleDeleteField(this.props.schemaLevel, value.field_name)}>Yes</a>
                    &nbsp;&nbsp;
                    <a data-toggle="collapse" href={'#modal_'+value.field_name+'_'+this.props.schemaLevel}>No </a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        );
        i++;
      })
    }

    return (
      <div>

        <form>
          <table className="table table-striped">
            <thead>   
              <tr>
                {/* <th style={{width:'14%'}}><b>Field In System</b></th> */}
                <th><b>Field Name</b></th>
                <th><b>Type</b></th>
                <th><b>Accepted Values</b></th>
                <th><b>Display Order</b></th>
                <th><b>Visible</b></th>
                <th><b>Required</b></th>
                <th style={{width:'10%'}}><b>Deletion</b></th>
              </tr>
            </thead>
            <tbody>
              {formDisplay}
            </tbody>
          </table>
        </form>

      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(SchemaForm, {
  mobxState: observable
});

export default observer(SchemaForm);

// export default ProjectOverview;
