import React from 'react';
import NumericInput from 'react-numeric-input'; //numeric input box
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//handles rendring form components and their validation
class SupplierSelection extends React.Component {

  mobxState = {
    suppliersAwarded: [], //options for select box
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  fetchData = () => {
    ////////////////////
    //FORM LOAD
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  render(){

    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }

    return (
      <div>


        <b>Bidders</b>
        <NumericInput
          onChange={(e) => this.props.handleFormChange('suppliersAwarded', e)}
          className="form-control"
          min={0}
          placeholder="Enter number of bidders to award to"
        />
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(SupplierSelection, {
	mobxState: observable
})

export default observer(SupplierSelection);

// export default SupplierSelection
