import React from "react";
import { render } from 'react-dom';

//other components built
import GridColumn from "../../../../services/GridColumn"; //builds ag-grid columns

//filters
import DockRenderer from "../../../../filters/DockRenderer";
import FilterQuickAction from "../../../../filters/FilterQuickAction"; //filter controls
import Select from 'react-select'; //select box for filter

//forms
import { AutoComplete, Input, Checkbox } from 'antd';

//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//security
import {checkRole} from '../../../../security/SecurityUtilities';

//state
import { decorate, observable, computed, action } from "mobx";
import { observer } from "mobx-react";

//utility
import { grabSystemAliases } from  '../../../../services/SystemAliases';
import {zeroNull, extractToken} from "../../../../services/Utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Modal, Button }  from 'react-bootstrap';

//spin loader icon
import SpinLoader from '../../../../loaders/SpinLoader';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

class Templates extends React.Component {
		// Set the project url by splitting up the pathname by / and selecting the proper location (3) of the project Id in url
		projectId = extractToken(window.location.pathname, '/', 3);

		mobxState = {
			submitMessage: false,
			projectFilter: [],
			customOptions: {},
			formState: {
				projectSelectValue: false,
				importScenarios: false,
				importSuppliers: false,
				importItems: false,
				importBids: false,
				importCustomFields: false,
				importReports: false,
				importReportsAndCustom: false,
			},
			hasItemLevel: null, //project has item_level custom fields
			hasBidLevel: null,	//project has bid level custom fields
			hasProjectLevel: null, //project has project_level custom fields
			hasItems: null,
			hasBids: null,
			hasFreightBrackets: null,
			hasResources: null,
			showConfirmModal: false, //boolean to toggle submit confirm modal
			validForm: false,
			itemIds: null, //holds item ids to delete
			bidsIds: null, //holds bids ids to delete
			resourceIds: null, //holds list of resource ids to delete
			freightBracketIds: null, //holds freight bracket ids to delete
			fieldSchemaId: null,
			isLoading: false, //controls visibility of spinner icon
		};

		//mount data through http requests
		componentDidMount() {
			//call the data loader to load data into the view
			this.fetchData();
		}

		//////////////////HTTP/////////////////
		//data loader
		fetchData = () => {
			//auto populate project dropdown
			axios.get('/v1/projects/index_filter')
			.then(response => {
				this.mobxState.projectFilter = response.data.projects;
			})

		} //end fetchData()

		//check for existing items, bids, item or bid level schema in current project
		handleCustomSchemaCheck = async () => {
				//check for custom fields
				await	axios
						.get(`/v1/projects/${this.projectId}/project_field_schemas`)
						.then((result) => {
							console.log(result)
							this.mobxState.fieldSchemaId = result.data.project_field_schemas[0].id;
							let bid_level = result.data.project_field_schemas[0].bid_level;
							let item_level = result.data.project_field_schemas[0].item_level;
							let project_level = result.data.project_field_schemas[0].project_level;
							// console.log("BID LEVEL ", bid_level,"ITEM LEVEL ", item_level)
							if(_.isEmpty(bid_level)){
								console.log("NO BID CUSTOM FIELDS")
								this.mobxState.hasBidLevel = false;
							} else {
								this.mobxState.hasBidLevel = true;
							}
							if(_.isEmpty(project_level)){
								console.log("NO PROJECT CUSTOM FIELDS")
								this.mobxState.hasProjectLevel = false;
							} else {
								this.mobxState.hasProjectLevel = true;
							}
							if(_.isEmpty(item_level)){
								console.log("NO ITEM CUSTOM FIELDS")
								this.mobxState.hasitemLevel = false;
							} else {
								this.mobxState.hasitemLevel = true;
							}
						})
			}
		//check for existing items in project
		handleItemCheck = async () => {
				//check for items
				await axios
					.get(`/v1/projects/${this.projectId}/items/items_exist`)
					.then((result) => {
						console.log("ITEMS IN PROJECT ", result.data.hasItems)
						let projectItems = result.data.hasItems;
						if(projectItems === false){
							console.log("NO ITEMS IN CURRENT PROJECT")
							this.mobxState.hasItems = false;
							this.mobxState.hasBids = false;
						} else {
							this.mobxState.hasItems = true;
						}
					})
			}
		//check for existing bids in project
		handleBidCheck = async () => {
				//check for bids
				await axios
					.get(`/v1/projects/${this.projectId}/bids/bids_exist`)
					.then((result) => {
						console.log("Bid in project", result.data.hasBids)
						let bids = result.data.hasBids;
						if(bids === false){
							console.log("NO BIDS IN CURRENT  PROJECT")
							this.mobxState.hasBids = false;
						} else {
							this.mobxState.hasBids = true;
						}
					})
			}
		//check for existing freight_brackets in project (includes freight_bids)
		handleFreightBracketCheck = async () => {
			//check for freight brackets
			await axios
				.get(`/v1/projects/${this.projectId}/freight_brackets`)
				.then((result) => {
					// console.log("Freight Result", result.data.freight_brackets)
					let freightBrackets = result.data.freight_brackets
					if(freightBrackets.length === 0){
						console.log("No Freight Brackets in current project")
						this.mobxState.hasFreightBrackets = false;
					} else {
						this.mobxState.hasFreightBrackets = true;
					}
				})
		}

		//Checks for pre-existing resources (Called "Reports in the form")
		handleResourceCheck = async () => {
			//check for resources
			await axios
				.get(`/v1/resources/project_resources/${this.projectId}`)
				.then((result) => {
					let resources = result.data.resources
					if(resources.length === 0) {
						console.log("No Resources in project")
						this.mobxState.hasResources = false;
					} else {
						this.mobxState.hasResources = true;
						console.log("Resource Result ", result.data.resources)
					}
			})
		}

		//POST METHODS

		//submits scenarios
		handleScenarioSubmit = async () => {
			let paramsToSend = { clone_into_project_id: this.projectId };
			this.mobxState.isLoading = true;
			 await axios
					.post('/v1/projects/'+this.mobxState.formState.projectSelectValue+'/scenarios/clone_all', paramsToSend)
					.then(result => {
						console.log('Project scenarios cloned with result', result);
						// this.mobxState.submitMessage = true;
					})
					.catch(e => {
						alert('Something went wrong in the scenario cloning process. Please check your inputs and try again.');
					});
					console.log(`Importing all Scenarios from Project: ${this.mobxState.formState.projectSelectValue} into Project: ${this.projectId}`)
		}

		//clone bidders
		handleSupplierSubmit = async () => {
				let paramsToSend = { clone_into_project_id: this.projectId }
				await axios
					.post(`/v1/projects/${this.mobxState.formState.projectSelectValue}/project_suppliers/clone_all`, paramsToSend)
					.then(result => {
						console.log('Bidders Cloned', result);
					})
					.catch((error) => {
						console.log(error)
						this.mobxState.isLoading = false;
					})
		}

		//clone items
		handleItemSubmit = async () => {
				//send cloned item data
				let paramsToSend = { clone_into_project_id: this.projectId }
				await	axios
					.post(`/v1/projects/${this.mobxState.formState.projectSelectValue}/items/clone_all`, paramsToSend, {timeout: 420000})//7 minute timeout
					.then(result => {
						console.log("Items Cloned ", result)
					})
					.catch(e => {
						alert('Something went wrong in the item cloning process. Please check your inputs and try again.');
					})
		}
		//submits bids
		handleBidSubmit = async () => {
				let paramsToSend = { clone_into_project_id: this.projectId }
				//will create items then any bids belonging to it
		    await axios
		      .post(`/v1/projects/${this.mobxState.formState.projectSelectValue}/bids/clone_all`, paramsToSend, {timeout: 420000})//7 minute timeout
		      .then(result => {
		        console.log('Bids Cloned ', result);
		      })
		      .catch(e => {
						console.log(e)
		        alert('Something went wrong in the Bid cloning process. Please check your inputs and try again.');
		        this.mobxState.submitMessage = false;
						this.mobxState.isLoading = false;
		      });
		}

		//clone project field schemas
		handleSubmitCustomFields = async () => {
				let paramsToSend = { clone_into_project_id: this.projectId };
	      //hit clone custom_fields endpoint
	      axios
	        .put(
	          `/v1/projects/${this.mobxState.formState.projectSelectValue}/project_field_schemas/clone_all`,
	          paramsToSend
	        )
	        .then(result => {
	          console.log('Cloning Custom Fields ', result);
	        })
					.catch((error) => {
						console.log(error)
					})
		}

		//CLONE resources (Called "Reports on the client side")
		handleResourceSubmit = async () => {
			if(this.mobxState.formState['importReports']) {
			let paramsToSend = { clone_into_project_id: this.projectId }
			console.log("In Resource Submit", paramsToSend, this.mobxState.formState.projectSelectValue)
			//hit clone resource endpont
			await axios
				.post(`/v1/resources/project_resources/${this.mobxState.formState.projectSelectValue}/clone_all`, paramsToSend)
				.then(result => {
					console.log('Cloning Project Resources ', result);
				})
				.catch((error) => {
					console.log(error)
				})
			} else {
				console.log("No resources")
			}
		}

		//clone freight brackets
		handleFreightBracketSubmit = async () => {
				let paramsToSend = { clone_into_project_id: this.projectId }
				//will create freight_brackets and any freight_bids that belong if they exist
				await axios
					.post(`/v1/projects/${this.mobxState.formState.projectSelectValue}/freight_brackets/clone_all`, paramsToSend)
					.then(result => {
						console.log("Freight Brackets/ Freight Bids ", result)
						// this.mobxState.submitMessage = true;
					})
					.catch((error) => {
						console.log(error)
						this.mobxState.isLoading = false;
					})
		}

		handleItemSubmit = async () => {
			let paramsToSend = {'clone_into_project_id': this.projectId};
			//hit clone custom_fields endpoint
			this.mobxState.isLoading = true;
			await axios
				.post(`/v1/projects/${this.mobxState.formState.projectSelectValue}/project_field_schemas/clone_all`,paramsToSend)
				.then(result => {
					console.log('Cloning Custom Fields ', result);
				})
				.catch((error) => {
					console.log(error)
					this.mobxState.isLoading = false;
				})

			//send cloned item data
			await	axios
				.post(`/v1/projects/${this.mobxState.formState.projectSelectValue}/items/clone_all`, paramsToSend)
				.then(result => {
					console.log("Items Cloned ", result)
					this.mobxState.isLoading = false;
					// this.mobxState.submitMessage = true;
				})
				.catch(e => {
					alert('Something went wrong in the item cloning process. Please check your inputs and try again.');
					this.mobxState.submitMessage = false;
					this.mobxState.isLoading = false;
				})
		}

		//submits scenarios
		handleScenarioSubmit = async () => {
			if(this.mobxState.formState['importScenarios']) {
				let paramsToSend = { clone_into_project_id: this.projectId };
			this.mobxState.isLoading = true;
		   await axios
		      .post('/v1/projects/'+this.mobxState.formState.projectSelectValue+'/scenarios/clone_all', paramsToSend)
		      .then(result => {
		        console.log('Project scenarios cloned with result', result);
						// this.mobxState.submitMessage = true;
		      })
		      .catch(e => {
		        alert('Something went wrong in the scenario cloning process. Please check your inputs and try again.');
						this.mobxState.submitMessage = false;
						this.mobxState.isLoading = false;
		      });
					console.log(`Importing all bidders from Project: ${this.mobxState.formState.projectSelectValue} into Project: ${this.projectId}`)
				} else {
					console.log("No Scenarios")
				}
		}

		//DELETE METHODS (Always Confirm Before Using!)
		//deletes bid_level and item_level custom_fields for current project
		handleDeleteCustomFields = async () => {
			let paramsToSend = {"schema_id": this.mobxState.fieldSchemaId}
			console.log("schema_id", this.mobxState.fieldSchemaId)
			axios
				.delete(`/v1/projects/${this.projectId}/project_field_schemas/${this.mobxState.fieldSchemaId}`)
				.then((result) => {
					console.log(`Deleted custom_fields for Project: ${this.projectId}`)
				})
				.catch((error) => {
					console.log(error)
				})
		}

		handleDeleteItems = async () => {
		//get list of items in project to delete
		console.log("Deleting Items")
		await	axios
			.get(`/v1/projects/${this.projectId}/items`)
			.then((itemList) => {
				itemList = itemList || {}
				let itemIds = []
				Object.entries(itemList.data.items).map(([key, value]) => {
					itemIds.push(value.id)
				})
				this.mobxState.itemIds = itemIds
			})

		await	axios
				.post(`/v1/projects/${this.projectId}/items/batch_destroy` , {"id_set": this.mobxState.itemIds}, {timeout: 420000})//7 minute timeout
				.then((result) => {
					console.log(`Deleted Items for Project: ${this.projectId}`)
				})
				.catch((error) => {
					console.log(error)
				})
		}

		handleDeleteBids = async () => {
			//get list of bids to delete
			console.log("DELETING BIDS")
			await	axios
				.get(`/v1/projects/${this.projectId}/bids`)
				.then((bidList) => {
					if(bidList.length > 0 && bidList !== null){
						let bidIds = []
						Object.entries(bidList.data.items).map(([key, value]) => {
							bidIds.push(value.id)
						})
						this.mobxState.bidsIds = bidIds
						deleteBids()
					}
				})

				let deleteBids = async () => {
					await	axios
							.post(`/v1/projects/${this.projectId}/bids/batch_destroy` , {"id_set": this.mobxState.bidIds},{timeout: 420000})//7 minute timeout
							.then((result) => {
								console.log(`Deleted Bids for Project: ${this.projectId}`)
							})
							.catch((error) => {
								console.log(error)
							})
				}
		}
		//get list of freight brackets to delete then deletes (will delete any freight_bids via cascade)
		handleDeleteFreightBrackets = async () => {
			await axios
				.get(`/v1/projects/${this.projectId}/freight_brackets`)
				.then((bracketList) => {
					console.log("BRACKETLIST ", bracketList)
					if(bracketList.length > 0 || bracketList !== null){
						let bracketIds = []
						Object.entries(bracketList.data.freight_brackets).map(([key, value]) => {
							// console.log(value.id)
							bracketIds.push(value.id)
						})
						this.mobxState.freightBracketIds = bracketIds
						let deleteFreightBrackets	= async () => {
							await axios
								.post(`/v1/projects/${this.projectId}/freight_brackets/batch_destroy`, {"id_set": this.mobxState.freightBracketIds})
								.then((results) => {
									console.log(`Deleted Freight Brackets and Freight bids from Project: ${this.projectId}`)
								})
								.catch((error) => {
									console.log(error)
								})
						}
						deleteFreightBrackets()
					}
				})
		}

		//resets state after submit
		resetProjectState = () => {
			this.mobxState.hasItemLevel = null;
			this.mobxState.hasBidsLevel = null;
			this.mobxState.hasProjectLevel = null;
			this.mobxState.hasItems = null;
			this.mobxState.hasFreightBrackets = null;
			this.mobxState.itemIds = null;
			this.mobxState.bidsIds = null;
			this.mobxState.freight_brackets = null;
			this.mobxState.fieldSchemaId = null;
		}


		//FORM HANDLING FUNCTIONS
		handleFormChangeAndState = (fieldName, selection) => {
			console.log('handleFormChange() with:', fieldName, selection);
			this.mobxState.formState[fieldName] = selection;
			//call to make sure objects don't get imported w/o dependencies
			this.correctDependencies(fieldName, selection);
			this.checkFormValidity();
			console.log(fieldName, ' state changed to ', this.mobxState.formState[fieldName]);
		}

		//function used in modal after user confirms deletion of old existing data
		handleConfirmedSubmit = async () => {
			console.log("Deleting and replacing")
			//delete and or submit values based on if they are true in the state
			this.mobxState.isLoading = true;
			if(this.mobxState.formState['importCustomFields'] === true){
				//updates bid_level and item_level custom_fields for current project
				// await this.handleDeleteCustomFields()
				//submit new data in relevant columns
				await this.handleSubmitCustomFields()
			}
			//import scenarios and or resources if they are selected
			//brings in constraints as well
			if(this.mobxState.formState['importScenarios'] === true){
				await this.handleScenarioSubmit()
			}

			//create resources (Called "Reports on the client side")
			if(this.mobxState.formState['importReports'] === true) {
				await this.handleResourceSubmit()
			}

			//create bidders
			if(this.mobxState.formState['importSuppliers'] === true) {
				await this.handleSupplierSubmit()
			}

			if(this.mobxState.formState['importBids'] === true){
				//delete exisiting
				await this.handleDeleteFreightBrackets();
				//create freight bids and brackets
				await this.handleFreightBracketSubmit()
				//delete bids
				await this.handleDeleteBids()
				//delete items and also delete any bids belonging
				await this.handleDeleteItems()
				//create items and then bids
				await this.handleBidSubmit()

			}

			if(this.mobxState.formState['importItems'] === true && this.mobxState.formState['importBids'] === false){
				console.log("Importing items no bids")
				//delete bids
				await this.handleDeleteBids()
				//delete items and also delete any bids belonging
				await this.handleDeleteItems()
				//create items if bids aren't selected
				await this.handleItemSubmit()
			}

			this.resetProjectState()
			this.mobxState.isLoading = false;
			this.mobxState.submitMessage = true;
			this.mobxState.showConfirmModal = false;
		}

	  handleSubmit = _.debounce(async (fieldName, selection) => {
			this.mobxState.isLoading = true; //toggle loading icon when checking current project
			console.log('id', this.mobxState.formState.projectSelectValue);
			console.log(this.mobxState.formState)
			//project to import scenarios from
			let paramsToSend = {'clone_into_project_id': this.projectId};


			//check for existing fields and show confirm modal if fields exist so they can be deleted
			await this.handleCustomSchemaCheck()
		  await this.handleItemCheck()
		  await this.handleBidCheck()
			await this.handleFreightBracketCheck()
			await this.handleResourceCheck()

			//if project has data confirm deletion else submit
			if (
			 this.mobxState.hasBids ||
			 this.mobxState.hasItems ||
			 this.mobxState.hasItemLevel ||
			 this.mobxState.hasFreightBrackets ||
			 this.mobxState.hasBidLevel ||
			 this.mobxState.hasResources === true
		 	) {
				//bypass normal submit methods and use delete methods in handleConfirmedSubmit() before submitting
			 	console.log('CONFIRM DELETE')
				this.mobxState.isLoading = false
		 		this.mobxState.showConfirmModal = true
			}
			//submit data if project is empty
		  else {
				//conditional in each function determines if function submits data
				console.log("Normal Submit")
				this.mobxState.isLoading = true;
				//create custom_fields
				if(this.mobxState.formState['importCustomFields'] === true) {
					//delete existing
					// await this.handleDeleteCustomFields()
					await this.handleSubmitCustomFields()
				}

				//import scenarios and or resources if they are selected
				if(this.mobxState.formState['importScenarios'] === true){
					await this.handleScenarioSubmit()
				}

				//create suppliers
				if(this.mobxState.formState['importSuppliers'] === true) {
					await this.handleSupplierSubmit()
				}

				if(this.mobxState.formState['importBids'] === true){
					//create freight bids and brackets
					await this.handleFreightBracketSubmit()

					//create items and bids
					await this.handleBidSubmit()
				}

				//create resources (Called "Reports on the client side")
				if(this.mobxState.formState['importReports']) {
					await this.handleResourceSubmit()
				}

				//use if bids isnt selected
				if(this.mobxState.formState['importItems'] === true && this.mobxState.formState['importBids'] === false){
					//create items if bids aren't selected
					await this.handleItemSubmit()
				}

				//showConfirmModalConfirmModal message and reset selection
				this.resetProjectState()
				this.mobxState.isLoading = false;
				this.mobxState.submitMessage = true;
			}

	  });


		//ensures dependecies are follow on import.. aka can't have bids without bidders or items
		correctDependencies = (fieldName, selection) => {
			if(this.mobxState.formState['importScenarios'] == true) {
				this.mobxState.formState['importSuppliers'] = true;
			}
			if(this.mobxState.formState['importBids'] == true) {
				this.mobxState.formState['importSuppliers'] = true;
				this.mobxState.formState['importItems'] = true;
				this.mobxState.formState['importCustomFields'] = true;
			}
			if(this.mobxState.formState['importItems'] == true) {
				this.mobxState.formState['importCustomFields'] = true;
			}
			if(this.mobxState.formState['importReports'] == true) {
				this.mobxState.formState['importCustomFields'] = true
				this.mobxState.formState['importReportsAndCustom'] = true;
			}
		};
		//toggle the variables to showConfirmModalConfirmModal
		checkFormValidity = () => {
			//check if projectFilter is selected and anything to import is selected
			if (this.mobxState.formState.projectSelectValue) {
				if (
					this.mobxState.formState['importScenarios'] ||
					this.mobxState.formState['importSuppliers'] ||
					this.mobxState.formState['importItems'] ||
					this.mobxState.formState['importBids'] ||
					this.mobxState.formState['importCustomFields'] ||
					this.mobxState.formState['importReports']
				) {
					this.mobxState.validForm = true
				} else {
					this.mobxState.validForm = false
				}
			}
		}

		//END FORM HANDLING

		//////////////////END HTTP/////////////////

		//for sidebar dock to change sizes
		handleSizeChange = size => {
			this.mobxState.dockOptions.size = size;
		}

		//sidebar dock toggle
		toggleDock = () => {
			this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible
		}

		render() {

			const hcHeight = '300px';
			//need to add form validation
			const validate = value => {
	      return !value || value.length < 3 ? 'Must be at least three characters' : null;
	    };

			const canScenarioW = checkRole({'model':'scenario', 'permission':'w'});
			const canProjectW = checkRole({'model':'project', 'permission':'w'});
			const canItemW = checkRole({'model':'item', 'permission':'w'});
			const canBidW = checkRole({'model':'bid', 'permission':'w'});

			return (

				<div>

					<div className="jumbotron jumbotron_full_width">
						<div className="jumbotron_full_width_container">
							<div className="row">
								<div className="col-sm-5">
									<span className="fa-layers fa-fw fa-2x">
										<FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#009C9C" style={{marginTop: '4px'}} />
										<FontAwesomeIcon icon={['fal', 'cloud-upload']} color="white" transform="shrink-4" style={{marginTop: '2px'}} />
									</span>
									&nbsp;
									<b className="dash_header"> Import Templates </b>

								</div>
								<div className="col-sm-1">
								</div>
								<div className="col-sm-1">
								</div>
								<div className="col-sm-5 gps">
									<FontAwesomeIcon icon={['fad', 'location-arrow']} color="#53C8C5" transform='grow-15 left-10' style={{}} />
									<Link to={'/sourcing/projects/'+this.projectId+'/bids'} className='link_style'>
										Next: Manage Project Bids
									</Link>
								</div>
							</div>

						</div>
					</div>

					{/* CONSTRAINTS */}
					<div className="row">
	          <div className="col-lg-12">
	            <div className="panel panel-default">
                <div className="panel-heading panel_bg clearfix">
                  <b>Data to Import Into This Project</b>
									<span className="pull-right" style={{display:'inline-flex'}}>
										{/* Constraint Saved Message */}
											<Bounce left when={this.mobxState.submitMessage}>
												<b className="text-success" style={{marginRight: '1.5em',marginTop: '4px'}}>
													Data Saved
												</b>
											</Bounce>
											{/* SPINNER ICON LOADER */}
											{this.mobxState.isLoading &&
												<span style={{marginRight:'1em', marginBottom: '-1em'}}>
													<SpinLoader
														style={{color:'#42A5F5', marginRight:'15px'}}
														size={'2x'}
														title={"Importing Data"}
													/>
												</span>
											}

										<Bounce left when={this.mobxState.validForm}>
											<span>
												<FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{marginRight:'15px', marginTop: '-3px'}}/>
											</span>
										</Bounce>
										<button type="button" disabled={!this.mobxState.validForm} className="btn btn-success btn-md"
											onClick={this.handleSubmit}>
											<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Run Data Import
										</button>
									</span>
                </div> {/*end panel heading */}

                <div className="panel-body">
									<div className="row">
										<div className="col-sm-12">
											<b>Select Project to Import Data From</b>
											<div style={{paddingTop: '10px'}}>
												<Select
													onChange={(e) => this.handleFormChangeAndState('projectSelectValue', e.value)}
													options={this.mobxState.projectFilter}
													//value = {this.mobxState.formState.projectSelectValue}
													closeMenuOnSelect={true}
													maxMenuHeight = {400}
													placeholder={'Select Existing Project'}
												/>
											</div>
										</div>
										<div className="col-sm-0">
										</div>
									</div>
									<br/>
									<br/>
									<b>Select Data to Import</b>
                  <div className="table-responsive">
                    <table className="table table-striped table_no_borders" cellSpacing="0" width="100%">
                      <thead>
                        <tr>
                          <th style={{width:'3%'}}></th>
													<th style={{width:'30%'}}></th>
                          <th style={{width:'67%'}}></th>
                        </tr>
                      </thead>

                      <tbody>
												{canScenarioW &&
													<tr>
	                          <td>
															<span className="fa-layers fa-fw fa-2x">
																<FontAwesomeIcon icon={['fas', 'circle']} style={{color:'#1690FF'}} />
																<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Sc</span>
															</span>
														</td>
														<td>
															<div style={{marginTop:'5px'}}>
																<Checkbox
																	name='importScenarios'
																	onChange={(e) => this.handleFormChangeAndState('importScenarios', e.target.checked)}
																	checked={this.mobxState.formState.importScenarios}
																>
																	Scenarios
																</Checkbox>
															</div>
														</td>
	                          <td>
															This will bring over all scenarios and related suppliers. If you have bids that are not in the project but in a scenario, it will likely be infeasibile.
													  </td>
	                        </tr>
												}
													{canProjectW &&
														<tr>
		                          <td>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{color:'#1690FF'}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Bi</span>
																</span>
		                          </td>
														  <td>
															{ /* Supplier Import */}
																<div style={{marginTop:'5px'}}>
																	<Checkbox
																		name='importSuppliers'
																		onChange={(e) => this.handleFormChangeAndState('importSuppliers', e.target.checked)}
																		checked={this.mobxState.formState.importSuppliers}
																	>
																		Bidders
																	</Checkbox>
																</div>
														  </td>
		                          <td>
																Bidder invitations
															</td>
		                        </tr>
													}
													{canItemW &&
														<tr>
		                          <td>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{color:'#1690FF'}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>It</span>
																</span>
		                          </td>
															<td>
																<div style={{marginTop:'5px'}}>
																	<Checkbox
																		name='importItems'
																	  onChange={(e) => this.handleFormChangeAndState('importItems', e.target.checked)}
																		checked={this.mobxState.formState.importItems}
																	>
																		Items
																	</Checkbox>
																</div>
															</td>

															<td>
																Item structure without bids
															</td>
		                        </tr>
													}
													{canBidW &&
														<tr>
		                          <td>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{color:'#1690FF'}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Bi</span>
																</span>
		                          </td>
															<td>
																<div style={{marginTop:'5px'}}>
																	<Checkbox
																		name='importBids'
																		onChange={(e) => this.handleFormChangeAndState('importBids', e.target.checked)}
																		checked={this.mobxState.formState.importBids}
																	>
																		Bids
																	</Checkbox>
																</div>
															</td>
		                          <td>
																Actual bid data
															</td>
		                        </tr>
													}
													{canBidW &&
														<tr>
		                          <td>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{color:'#1690FF'}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Cf</span>
																</span>
		                          </td>
															<td>
																<div style={{marginTop:'5px'}}>
																	<Checkbox
																		name='importCustomFields'
																		onChange={(e) => this.handleFormChangeAndState('importCustomFields', e.target.checked)}
																		checked={this.mobxState.formState.importCustomFields}
																	>
																		Custom fields
																	</Checkbox>
																</div>
															</td>
		                          <td>
																Bid and item custom field setup
															</td>
		                        </tr>
													}
													{canProjectW &&
														<tr>
															<td>
																<span className="fa-layers fa-fw fa-2x">
																	<FontAwesomeIcon icon={['fas', 'circle']} style={{color:'#1690FF'}} />
																	<span className="fa-layers-text fa-inverse" style={{fontWeight:'900', fontSize:'14px'}}>Re</span>
																</span>
															</td>
															<td>
																<div style={{marginTop:'5px'}}>
																	<Checkbox
																		name='importReports'
																		onChange={(e) => this.handleFormChangeAndState('importReports', e.target.checked)}
																		checked={this.mobxState.formState.importReports}
																	>
																		Reports
																	</Checkbox>
																</div>
															</td>
															<td>
																Custom reporting templates saved in the project
															</td>
														</tr>
													}
                      </tbody>
                    </table>
                  </div> {/*end table responsive */}
                </div> {/*end panel body */}
	            </div> {/*end panel */}
	          </div> {/*end lg 12 */}
	        </div> {/*end row */}

					<br />
						{/* Modal for showConfirmModaling confirm message before cloning */}
						<Modal show={this.mobxState.showConfirmModal } onHide={() => {this.mobxState.showConfirmModal = false}}>
							<Modal.Header closeButton>
								<Modal.Title className="text-danger" ><b>Data already exists in this project</b> </Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<h4>
									Importing will delete some existing project data that you have checked in the form. This operation cannot be undone.
									Are you sure that you want to continue?
								</h4>
									{this.mobxState.isLoading &&
										<span style={{display:'inline-flex', paddingLeft:'10px'}}>
											<SpinLoader
												style={{color:'#42A5F5', display:'inline', marginRight:'15px'}}
												size={'2x'}
												title={"Importing Data"}
											/>
										</span>
								 	}
							</Modal.Body>
							<Modal.Footer>
								<Button variant="secondary" onClick={() => {
											this.mobxState.formState['importSuppliers'] = false;
											this.mobxState.formState['importItems'] = false;
											this.mobxState.formState['importBids'] = false;
											this.mobxState.formState['importCustomFields'] = false;
											this.mobxState.formState['importReports'] = false;
											this.mobxState.formState['importScenarios'] = false;
											this.mobxState.validForm = false;
											this.mobxState.showConfirmModal = false;
										}}>
									Close
								</Button>
								<Button className="btn btn-success" onClick={async () => {
										console.log("Cloning", this.mobxState.formState)
										await this.handleConfirmedSubmit()
									}}>
									Clone From Project
								</Button>
							</Modal.Footer>
						</Modal>

				</div>
			);
		}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Templates, {
	mobxState: observable
})

export default observer(Templates);

// export default Templates;
