import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

class AliasForm extends React.Component {
  render() {
    const formDisplay = [];
    const pages = this.props.pages || [];
    pages.forEach((page, ix) => {
      if (this.props.pagesToEdit.includes(page.id)) {
        formDisplay.push(
          <tr key={`module-settings-form-input-${page.id}`}>
            {/*<td>{page.id}</td>*/}
            <td>{page.name}</td>
            {/*<td>{page.description}</td>*/}
            <td>
              <div style={{ marginTop: '7px' }}>
                <Checkbox
                  onChange={e => {
                    this.props.handleFormChange([ix, 'enabled'], e.target.checked);
                  }}
                  checked={page.enabled}
                />
              </div>
            </td>
            <td>
              <Input
                value={page.custom_name}
                placeholder={page.custom_name || 'Enter a custom name for this field'}
                className='form-control'
                onChange={e => this.props.handleFormChange([ix, 'custom_name'], e.target.value)}
              />
            </td>

            {/*<td>{JSON.stringify(page)}</td>*/}
          </tr>
        );
      }
    });
    return (
      <div>
        <form>
          <table className='table table-striped'>
            <tbody>
              <tr>
                {/* <th style={{width:'14%'}}><b>Field In System</b></th> */}
                <th>
                  <b>Page Name</b>
                </th>
                <th>
                  <b>Enabled</b>
                </th>
                <th>
                  <b>Custom Name</b>
                </th>
              </tr>
              {formDisplay}
            </tbody>
          </table>
        </form>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(AliasForm, {
  mobxState: observable
});

export default observer(AliasForm);

// export default ProjectOverview;
