import axios from 'axios';

// InjectedStyle
// USAGE:
// let style = new InjectedStyle();
// style.addRule('body', [['background', 'yellow']])
//      .addRule('#test-div', {'color', 'red'})
//      .apply();
export class InjectedStyle {
  constructor(prefix, rules) {
    console.log(InjectedStyle.count++);
    rules = rules || [];
    this.rules = [];
    this.element = document.createElement('style');
    this.element.type = 'text/css';
    this.element.id = `injected-style-${prefix}-${InjectedStyle.count}`;
    if (rules.length > 0) {
      this.addRules(rules);
    }
  }

  // selector: css selector as a string
  // style: style to apply as object (e.g. {"background-color": "red"})
  addRule(selector, style) {
    let attrSettingLines = [];
    Object.entries(style).forEach(([attr, value]) => {
      attrSettingLines.push(`${attr}: ${value} ;`);
    });
    this.rules.push(`${selector} { ${attrSettingLines.join(' ')} } `);
    return this;
  }
  addRules(rules) {
    rules.forEach(([selector, style]) => {
      this.addRule(selector, style);
    });
    return this;
  }
  clearRules() {
    this.rules = [];
    this.refresh();
  }
  refresh() {
    this.element.innerHTML = this.rules.join(' \n');
  }
  apply() {
    this.element.innerHTML = this.rules.join(' \n');
    document.getElementsByTagName('head')[0].appendChild(this.element);
    return this;
  }
  remove() {
    this.element.remove();
    return this;
  }
  loadClientColorScheme(config) {
    //prettier-ignore
    this.addRules([
      // The sidebar background
      ['#root #sidebar', {
          background: config.sidebar_background.value,
          color: config.sidebar_text.value
      }],
      // sub list style
      ['#root #sidebar ul ul a', {
        background: config.sidebar_submenu_background.value,
        color: config.sidebar_submenu_text.value
      }],
      ['#root #sidebar ul li a:hover', {
          background: config.sidebar_hover_background.value,
          color: config.sidebar_hover_text.value,
      }],
      ['#root #sidebar ul li.active', {
          background: config.sidebar_submenu_background.value,
          color: config.sidebar_submenu_text.value,
      }],
      // [x] The main header background color
      ['#root nav', {
          background: config.main_header_background.value,
          'border-bottom-color': config.main_header_border_bottom.value
      }],
      // [x] The "top_line" in the main header
      ['#root #top_line', {
          'background-color': config.top_line.value
      }],
      // [x] The panel-header (right now it's in the panel_bg class)
      ['#root .panel-default > .panel_bg', {
          'background-color': `${config.panel_header_background.value} !important` // Need important to deal with previous override
      }],
      // [x] The panel-header text color
      ['#root .panel-default > .panel-heading', {
          color: config.panel_header_text.value
      }],
      // [x] The color of icons in main navbar
      ['#root #navbar .glyphicon', {
          color: config.main_header_icon.value
      }],
      ['#root #navbar .glyphicon:hover', {
          color: config.main_header_hover_icon.value
      }],
      // [x] The color of icons in panel headers
      ['#root .panel-default > .panel-heading *:not(button)>.glyphicon', {
          color: config.panel_header_icon.value
      }],
      ['#root .panel-default > .panel-heading *:not(button)>.glyphicon:hover', {
          color: config.panel_header_hover_icon.value
      }],
      // [x] Color of icons in badges
      ['#root #badge-section .glyphicon', {
          color: config.badge_icon_color.value
      }],
      ['#root .legacy-badge .glyphicon', {
          color: config.badge_icon_color.value
      }],
      // [x] Color of AG Grid Header
      ['#root .ag-theme-material .ag-header-cell', {
          'background-color': config.grid_header_color.value
      }],
      ['#root .ag-theme-material .ag-header-cell:hover', {
          'background-color': config.grid_header_hover_color.value
      }]
    ]);
    return this;
  }
}

InjectedStyle.count = 0;

export const injectCustomerColorScheme = () => {
  return new Promise(resolve => {
    axios.get('/v1/custom_schemas', { params: { name: 'client_color_scheme' } }).then(resp => {
      let schema_definition =
        resp &&
        resp.data &&
        resp.data.custom_schemas &&
        resp.data.custom_schemas[0] &&
        resp.data.custom_schemas[0].schema_definition;
      if (typeof schema_definition !== 'undefined') {
        let settings = schema_definition;

        const injector = (
          new InjectedStyle('client_color_scheme', [])
          // .loadClientColorScheme(settings)
          // .apply()
        );

        // Save color scheme to local storage
        localStorage['clientColorScheme'] = JSON.stringify(schema_definition, null, 2);
        resolve({
          styleInjector: injector,
          clientColorScheme: schema_definition
        });
      } else {
        console.log('No client_color_scheme defined.');
        resolve({
          styleInjector: new InjectedStyle('client_color_scheme', []),
          clientColorScheme: schema_definition
        });
      }
    });
  });
};

export default InjectedStyle;
