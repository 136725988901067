// Imports
// Adapted from https://react-select.com/async
import React from 'react';

//state
import { decorate, observable, computed, action, trace } from 'mobx';
import { observer } from 'mobx-react';

import moment from 'moment';
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, Select, Spin, DatePicker } from 'antd';
const { RangePicker } = DatePicker;

// There's some mapping that needs to go on here
// For the sake of compatibility, I'm going to assume we're getting:
//   -- Options: [{value+label}]
//   -- Value:   [{value+label}]

class AntdRangeSelect extends React.Component {
  makeMonthRange = (startMoment, endMoment) => {
    // Make sure dates inputs are sorted
    if (startMoment.isAfter(endMoment)) {
      [startMoment, endMoment] = [endMoment, startMoment];
    }
    // Duplicate arguments since moments are mutable
    startMoment = startMoment.clone();
    endMoment = endMoment.clone();
    // Add days till range is generated
    let out = [];
    let cur = startMoment;
    while (cur.isSameOrBefore(endMoment)) {
      out.push(cur);
      cur = cur.clone();
      cur.add(1, 'month');
    }
    return out;
  };

  render = () => {
    let currentOptions = this.props.options || []; // [label+value]
    let currentValues = this.props.value || []; // [label+value]
    let startDate = null;
    let endDate = null;
    if (currentValues.length === 0) {
    } else if (currentValues.length === 1) {
      startDate = moment.utc(currentValues[0].value, 'YYYY-MM');
      endDate = moment.utc(currentValues[0].value, 'YYYY-MM');
    } else {
      startDate = moment.utc(currentValues[0].value, 'YYYY-MM');
      endDate = moment.utc(currentValues[currentValues.length - 1].value, 'YYYY-MM');
    }
    return (
      <RangePicker
        picker="month"
        style={{ width: '100%' }}
        value={[startDate, endDate]}
        getPopupContainer={() => document.querySelector('#filter-lists')}
        onChange={(moments, dateStrings) => {
          if (moments === null) {
            // CASE: Picker was cleared
            this.props.onChange([]);
          } else {
            let dateRange = this.makeMonthRange(
              moment.utc(moments[0].toISOString().slice(0, 10) + 'T00:00:00Z'),
              moment.utc(moments[1].toISOString().slice(0, 10) + 'T00:00:00Z')
            );
            let newValues = dateRange.map(m => {
              return {
                label: m.format('YYYY-MM'),
                value: m.format('YYYY-MM')
              };
            });
            this.props.onChange(newValues);
          }
        }}
      />
    );
  };
}

decorate(AntdRangeSelect, {
  state: observable
});
export default observer(AntdRangeSelect);
