import React from 'react';
import { render } from 'react-dom';

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, Select as AntdSelect, TreeSelect } from 'antd';
import AntdMultiSelect from '../../../../filters/AntdMultiSelect';
import AntdSearchedMultiSelect from '../../../../filters/AntdSearchedMultiSelect';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';

//security
import { checkRole } from '../../../../security/SecurityUtilities';

const _ = require('lodash'); //extra utilities

class DefaultFiltersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {}
  componentWillReceiveProps(nextProps) {
    // We need to set default values so they're in custom_fields if missing
    let filledInState = { ...nextProps.defaultFormState };
    this.setState(filledInState);
  }
  // Input building methods ==========================================================================================
  // Change --------------------------------------------------------------
  validateState = (name, value) => true;

  handleChange = (name, value) => {
    // Update the state
    if (this.validateState(name, value)) {
      this.setState({ ...{ [name]: value } });
    }
  };
  // Submission --------------------------------------------------------------
  validateSubmit = state => true;
  stateToParams = state => {
    // Grab values from the select options
    let filterValues = {};
    ['date__year', 'date__year_month'].forEach(k => {
      if (state[k] && state[k].length > 0) {
        filterValues[k] = state[k].map(x => x.value);
      }
    });
    let paramsForPut = {
      name: 'default_date_filter_value',
      schema_definition: filterValues
    };
    return paramsForPut;
  };
  handleSubmit = async () => {
    let paramsForPut = this.stateToParams(this.state);
    if (this.validateSubmit(paramsForPut)) {
      axios.put(`/v1/custom_schemas/${this.props.editId}`, paramsForPut).then(resp => {
        window.location.reload();
      });
    }
  };
  render() {
    // Format custom fields
    return (
      <div>
        <div className='row'>
          <div className='col-xs-6'>
            <div className='form-group'>
              <label htmlFor={`form-date__year-input`}>Year</label>
              <AntdSearchedMultiSelect
                name='date__year'
                optionSearch={this.props.searchFunctions.searchFunctions['date__year']}
                value={this.state['date__year'] || []}
                onChange={value => this.handleChange('date__year', value)}
                options={this.props.modelOptions['date__year'] || []}
                closeMenuOnSelect={this.props.closeMenuOnSelect}
                maxMenuHeight={this.props.maxMenuHeight}
                placeholder='Please Select Value(s)'
                key={'system-filter-select-' + 'date__year'}
                popupContainerSelector={'body'}
              />
            </div>
          </div>
          <div className='col-xs-6'>
            <div className='form-group'>
              <label htmlFor={`form-date__year-input`}>Year Month</label>
              <AntdSearchedMultiSelect
                name='date__year_month'
                optionSearch={this.props.searchFunctions.searchFunctions['date__year_month']}
                value={this.state['date__year_month'] || []}
                onChange={value => this.handleChange('date__year_month', value)}
                options={this.props.modelOptions['date__year_month'] || []}
                closeMenuOnSelect={this.props.closeMenuOnSelect}
                maxMenuHeight={this.props.maxMenuHeight}
                placeholder='Please Select Value(s)'
                key={'system-filter-select-' + 'date__year_month'}
                popupContainerSelector={'body'}
              />
            </div>
          </div>
        </div>
        <br />
        <button type='button' className='btn btn-primary btn-block' onClick={this.handleSubmit}>
          <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Publish Changes
        </button>
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(DefaultFiltersForm, {
  mobxState: observable
});

export default observer(DefaultFiltersForm);

// export default ProjectOverview;
