import React from 'react';
import { render } from 'react-dom';

//other components built
import AsyncBadge from '../../../../badges/AsyncBadge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select from 'react-select'; //select box for filter
import FilterCountIndicator from '../../../../filters/FilterCountIndicator'
import FilterTray from '../../../../filters/FilterTray'

//highcharts
import CustomBadgeRenderer from '../../../../badges/custom/CustomBadgeRenderer';
import CustomChartRenderer from '../../../../charts/custom/CovidChartRenderer';

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, deleteIfEmpty } from '../../../../services/Utilities';
import { grabSystemAliases } from '../../../../services/SystemAliases';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { processingFunctions, formatFiltersForServer } from '../../../../services/filter_helpers';
import { asyncLoadCharts } from '../../../../services/ChartUtilities';
const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();

class CategoryBreakdown extends React.Component {
  pageId = 203; // CHANGE THIS FOR OTHER PAGES
  autofitFunctions = []; // Have to keep track of autoclick childs rather than setting componentwide property
  mobxState = {
    cardData: {
      spendCount: 0,
      supplierCount: 0,
      contractCount: 0
    },
    chartRenderData: [],
    tableData: {
      transactions: []
    },
    isLoadingTableData: [false, false],
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    bottomDockOptions: {
      isVisible: false,
      size: 0.2
    },
    pivotData: [],
    pivotState: [],
    pivotStateByCategory: [],
    pivotStateBySupplierGroup: [],
    pivotFilter: [],
    filterModelNames: [], //autobuilds filters and clear functions
    customFilterModelNames: [],
    filterOptions: {},
    customFilterOptions: {},
    customOptions: {
      grouped_transactions__category_id: 'Category Code',
      grouped_transactions__group: 'Supplier Group',
      grouped_transactions__transaction_count: '# of Transactions',
      grouped_transactions__transaction_total: 'Total Spend (USD)',
      grouped_transactions__transaction_avg: 'Avg Transaction (USD)',
      transaction__id: 'ID'
    },
    filtersForServer: {} // Filters in normal form to use for the server
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }

  //////////////////HTTP/////////////////
  //helpers for system filters
  //helpers for custom filters
  //setup filters
  // TODO:
  // - Remove system filters from customOptions once we have api call to populate aliases in place
  setupFilters = async () => {
    const urls = {
      system: '/v1/system_filters',
      systemOptions: '/v1/transactions/system_field_options',
      custom: '/v1/custom_filters_formatted',
      customOptions: '/v1/transactions/custom_field_options',
      systemOptionsSearchUrl: `/v1/transactions/system_field_options`,
      customOptionsSearchUrl: `/v1/transactions/custom_field_options`
    };
    const [systemFilterResp, customFilterResp] = await Promise.all([
      axios.get(urls.system, {
        params: processingFunctions.system.filters.formatParams({
          enabled: true,
          page_id: this.pageId
        })
      }),
      axios.get(urls.custom, {
        params: processingFunctions.custom.filters.formatParams({
          page_id: this.pageId,
          enabled: true
        })
      })
    ]);

    let systemFilters = processingFunctions.system.filters.formatResp(systemFilterResp);
    let customFilters = processingFunctions.custom.filters.formatResp(customFilterResp);

    // Handle System Filters First
    let systemFilterModelNames = [];
    let systemFilterOptions = {};
    let systemFilterCustomOptions = {};
    let systemFilterWidgetTypes = {};

    systemFilters
      .filter(filter => filter.enabled)
      .forEach(filter => {
        let { name, widget_type } = filter; // Name of filter in the table, usually this is the column name of the sequelize model as well
        systemFilterModelNames.push(name); // Construct Select
        systemFilterWidgetTypes[name] = widget_type;
      });

    // Get options (name-label format) for our selects in model-nested format
    // then convert it to name-label format
    systemFilterOptions = await axios
      .post(
        urls.systemOptions,
        processingFunctions.system.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          system_fields: systemFilterModelNames
        })
      )
      .then(processingFunctions.system.options.formatResp);

    // Then Handle Custom Filters
    let customFilterModelNames = [];
    let customFilterOptions = {};
    let customFilterCustomOptions = {};
    let customFilterWidgetTypes = {};

    // Manipulate custom filters into a format where they can be handled
    // by the constructors for our filter panel
    customFilters.forEach(filter => {
      let [name, data] = _.toPairs(filter)[0]; // _.toPairs splits object into list of [key, value] pairs
      customFilterModelNames.push(name);
      customFilterCustomOptions[name] = data.field_alias; // Construct the filter
      customFilterWidgetTypes[name] = data.widget_type;
    });

    customFilterOptions = await axios
      .post(
        urls.customOptions,
        processingFunctions.custom.options.formatParams({
          selected_filters: this.mobxState.filtersForServer,
          custom_fields: customFilterModelNames
        })
      )
      .then(processingFunctions.custom.options.formatResp);

    // ------------------------------------------------------------
    // Finally Setup The Filters
    // Use the current filter generation that gets triggered when filterModelNames change
    this.mobxState.filterModelNames = systemFilterModelNames;
    this.mobxState.customFilterModelNames = customFilterModelNames;

    this.mobxState.filterOptions = systemFilterOptions;
    this.mobxState.customFilterOptions = customFilterOptions;

    this.mobxState.filterWidgetTypes = systemFilterWidgetTypes;
    this.mobxState.customFilterWidgetTypes = customFilterWidgetTypes;

    this.mobxState.customOptions = {
      ...this.mobxState.customOptions,
      ...systemFilterCustomOptions,
      ...customFilterCustomOptions
    };
    this.mobxState.optionSearchFunctions = processingFunctions.general.makeSearchFunctions({
      system_filters: systemFilters,
      custom_filters: customFilters,
      system_url: urls.systemOptionsSearchUrl,
      custom_url: urls.customOptionsSearchUrl,
      selected_filters: this.mobxState.filtersForServer,
      system_fields: systemFilterModelNames,
      custom_fields: customFilterModelNames
    });
  };
  //data loader
  fetchData = () => {
    // Restore filters
    this.mobxState.selectedModels = JSON.parse(localStorage.getItem('selectedModels')) || {}; //multi select data model for http
    this.mobxState.selectedOptions = JSON.parse(localStorage.getItem('selectedOptions')) || {}; //multi select data model in filter value/label format
    this.mobxState.filtersForServer = JSON.parse(localStorage.getItem('filtersForServer')) || {};
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = window.$('#table_rows_dropdown').val();
    //merge the filter model with the amount of rows selected
    this.setupFilters();

    //merge the filter model with the amount of rows selected
    let models_with_rows = {
      ...this.mobxState.filtersForServer,
      ...{ table_rows: table_rows || [] }
    };
    //custom pivot configurations load
    axios
      .get('/v1/resources/index_filter', { params: { page_id: this.pageId } })
      .then(response => {
        this.mobxState.pivotFilter = response.data.resources;
      })
      .catch(console.log.bind(console)); //error catcher

    //load custom chart data for this page (updates mobxState.chartRenderData)
    asyncLoadCharts(this.mobxState, {
      page_id: this.pageId,
      selected_models: this.mobxState.filtersForServer
    });

    // Merge System Aliases Into Custom Options (Keeping existing values)
    grabSystemAliases().then(systemAliases => {
      this.mobxState.customOptions = {
        ...systemAliases,
        ...(this.mobxState.customOptions || {}) // Prefer existing values
      };
    });

    //set up custom options for components, then get data for components
    // axios.get("/v1/ref_cmfields_labels")
    axios
      .get('/v1/custom_schemas', { params: { name: 'category' } }) //query the project custom field schema for all projects
      .then(response => {
        //get map of field labels from API
        this.mobxState.customSchema = response.data;
        // Add them into customOptions
        // TODO: Remove this when we have aliases properly working
        Object.keys(
          (this.mobxState.customSchema.custom_schemas[0] &&
            this.mobxState.customSchema.custom_schemas[0].schema_definition) ||
            {}
        ).map(field => {
          let k = 'category__' + field;
          let v = this.mobxState.customSchema.custom_schemas[0].schema_definition[field].field_alias;
          this.mobxState.customOptions[k] = v;
        });

        //after ag grid is set up, get the rest of the data
        //table data load
        [
          [
            '/v1/transactions_category_breakdown_by_category',
            'by_category',
            'columnDefsCategoryBreakdownByCategory',
            0
          ],
          [
            '/v1/transactions_category_breakdown_by_supplier_group',
            'by_supplier_group',
            'columnDefsCategoryBreakdownBySupplierGroup',
            1
          ]
        ].forEach(([url, tableName, columnDefsName, autofitIndex]) => {
          this.mobxState.isLoadingTableData[autofitIndex] = true
          axios.post(url, models_with_rows).then(response => {
            this.mobxState.tableData[tableName] = response.data.grouped_transactions;
            this.mobxState[columnDefsName] = gridColumns.buildColumnDefs({
              customOptions: this.mobxState.customOptions,
              columnArray: ['spend_category_breakdown_' + tableName],
              customFieldSchema: this.mobxState.customSchema.custom_schemas[0] || {},
              dataType: 'spend_category', //special tag to signify what custom fields to look at?
              metadata: {
                custom_field_1_handler: event => {
                  this.handleCustomFilter('category__custom_field_1', [{ label: event.value, value: event.value }]);
                },
                custom_field_2_handler: event => {
                  this.handleCustomFilter('category__custom_field_2', [{ label: event.value, value: event.value }]);
                },
                custom_field_3_handler: event => {
                  this.handleCustomFilter('category__custom_field_3', [{ label: event.value, value: event.value }]);
                },
                custom_field_4_handler: event => {
                  this.handleCustomFilter('category__custom_field_4', [{ label: event.value, value: event.value }]);
                },
                custom_field_5_handler: event => {
                  this.handleCustomFilter('category__custom_field_4', [{ label: event.value, value: event.value }]);
                },
                supplier_group_handler: event => {
                  this.handleFilter('supplier__group', [{ label: event.value, value: event.value }]);
                },
                supplier_name_handler: event => {
                  this.handleFilter('supplier__name', [{ label: event.value, value: event.value }]);
                },
                item_description_handler: event => {
                  this.handleFilter('transaction__item_description', [{ label: event.value, value: event.value }]);
                }
              }
            });
            this.mobxState.isLoadingTableData[autofitIndex] = false
            this.autofitFunctions[autofitIndex]();
          });
        });
      })
      .catch(console.log.bind(console)); //error catcher
  }; //end fetchData() ///////////////
  deleteRequest = toSendArr => {};
  //////////////////END HTTP/////////////////
  handleChartFilter = (filterParam, selectedFilter) => {
    // KLUDGE : If the filterParam begins with 'custom_fields.' then it should be treated as if
    // a custom_filter was selected
    if (filterParam.indexOf('custom_fields.') !== -1) {
      return this.handleCustomFilter(filterParam.replace(/custom_fields./, ''), selectedFilter);
    } else {
      return this.handleFilter(filterParam, selectedFilter);
    }
  };

  //handles dropdown and chart filters
  handleFilter = (filterParam, selectedFilter) => {
    // In the state, pick the model based on filter that is sent and turn it into the array of values of the filter.
    // This goes as params to http request
    // Update state with selected options, removing the property if none selected
    // Equivalent to this.mobxState[filterParam] = selectedFilter.map(a => a.value)
    // but handles nested assignment
    this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, filterParam);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions[filterParam] = selectedFilter;
    if (_.isEmpty(this.mobxState.selectedOptions[filterParam])) {
      delete this.mobxState.selectedOptions[selectedFilter];
    }
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);

    // Update stored filters
    localStorage.setItem('selectedOptions', JSON.stringify(this.mobxState.selectedOptions));
    localStorage.setItem('filtersForServer', JSON.stringify(this.mobxState.filtersForServer));
    localStorage.setItem('selectedModels', JSON.stringify(this.mobxState.selectedModels));
    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  handleCustomFilter = (filterParam, selectedFilter) => {
    //in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
    this.mobxState.selectedModels['custom_fields'] = this.mobxState.selectedModels['custom_fields'] || {};
    this.mobxState.selectedModels['custom_fields'][filterParam] = selectedFilter.map(a => a.value);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedModels, `custom_fields`);

    //also store the selections in the original form. This goes back to the filter as selected values
    this.mobxState.selectedOptions['custom_fields'] = this.mobxState.selectedOptions['custom_fields'] || {};
    this.mobxState.selectedOptions.custom_fields[filterParam] = selectedFilter;
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields.${filterParam}`);
    deleteIfEmpty(this.mobxState.selectedOptions, `custom_fields`);
    // Update which filters for params
    this.mobxState.filtersForServer = formatFiltersForServer(this.mobxState.selectedModels);
    // Update stored filters
    localStorage.setItem('selectedOptions', JSON.stringify(this.mobxState.selectedOptions));
    localStorage.setItem('filtersForServer', JSON.stringify(this.mobxState.filtersForServer));
    localStorage.setItem('selectedModels', JSON.stringify(this.mobxState.selectedModels));

    //fetch called, it reads the filter params from state
    this.fetchData();
  };
  //handles dropdown and chart filters
  handleClear = () => {
    //in the state, clear the model. This goes as params to http request
    this.mobxState.selectedModels = {};

    // Clear the filters we send to the server
    this.mobxState.filtersForServer = {};

    //also store the explicit cleared selections in the original form. This goes back to the filter as selected values
    //loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
    //from ["supplier", "item"] to {"supplier":[], "item":[]}
    let newSelectedOptions = { custom_fields: {} };
    this.mobxState.filterModelNames.forEach(name => {
      newSelectedOptions[name] = [];
    });
    this.mobxState.customFilterModelNames.forEach(name => {
      newSelectedOptions.custom_fields[name] = [];
    });
    this.mobxState.selectedOptions = newSelectedOptions;
    localStorage.setItem('selectedOptions', '{}');
    localStorage.setItem('filtersForServer', '{}');
    localStorage.setItem('selectedModels', '{}');

    //fetch called, it reads the filter params from state
    this.fetchData();
  };

  //for sidebar dock to change sizes
  handleSizeChange = size => {
    this.mobxState.dockOptions.size = size;
  };

  //sidebar dock toggle
  toggleDock = () => {
    this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible;
  };
  toggleBottomDock = () => {
    this.mobxState.bottomDockOptions.isVisible = !this.mobxState.bottomDockOptions.isVisible;
  }

  render() {
    const hcHeight = '300px';

    return (
      <div>
        {/* Small Left Toggle */}
        <div onClick={this.toggleDock} className='sidebar_button filter_toggle'>
          <i className='glyphicon glyphicon-chevron-left'></i>
        </div>
        <div
          className='jumbotron jumbotron_full_width'
          style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
        >
          <div className='jumbotron_full_width_container'>
            <div className='row'>
              <div className='col-sm-12'>
                <span className='fa-layers fa-fw fa-2x'>
                  <FontAwesomeIcon
                    icon={['fas', 'circle']}
                    transform='grow-3'
                    color='#009c9c'
                    style={{ marginTop: '4px' }}
                  />
                  <FontAwesomeIcon
                    icon={['fal', 'folders']}
                    color='white'
                    transform='shrink-5'
                    style={{ marginTop: '3px' }}
                  />
                </span>
                &nbsp;
                <b className='dash_header'>Category Breakdown </b>
                <FilterCountIndicator
                  handleClick={this.toggleBottomDock}
                  selectedModels={this.mobxState.selectedModels}/>
                <span style={{ minWidth: '28.608vw', float: 'right' }}>
                  {/* Conditionally rendered dropdown if project exists or else it will display "create a project" text*/}
                </span>
              </div>
            </div>
            <FilterTray
              isVisible={this.mobxState.bottomDockOptions.isVisible}
              handleToggleDock={this.toggleBottomDock}
              selectedModels={this.mobxState.selectedModels}
              customOptions={this.mobxState.customOptions}
              />
            <br/>
          </div>
        </div>
        {/* BADGES */}
        <div className='row'>
          <CustomBadgeRenderer
            handleFilter={this.handleChartFilter}
            toggleDock={this.toggleDock}
            handleClear={this.handleClear}
            chartRenderData={this.mobxState.chartRenderData}
          />
        </div>
        <br />
        {/* SUMMARY CHARTS */}
        <div className='row'>
          <CustomChartRenderer
            handleFilter={this.handleChartFilter}
            toggleDock={this.toggleDock}
            handleClear={this.handleClear}
            chartRenderData={this.mobxState.chartRenderData}
          />
        </div>{' '}
        {/* END ROW */}
        {/* END SUMMARY CHARTS */}
        {/* GRID */}
        <div className='row'>
          <div className='col-lg-6'>
            {/* AG Grid */}
            <GridRenderer
              gridHeight={'650px'}
              columnDefs={this.mobxState.columnDefsCategoryBreakdownByCategory}
              autoGroupColumnDef={{
                  minWidth: 300,
                  headerName: 'Category',
                  cellRendererParams: {
                    innerRenderer: 'passthroughRowGroupInnerRenderer'
                }}}
              groupDefaultExpanded={0}
              rowData={this.mobxState.tableData['by_category']}
              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={this.pageId}
              savedState={this.mobxState.pivotStateByCategory}
              pivotMode={true}
              isPivotMode={true}
              gridTitle={'Category Breakdown'}
              setClick={click => (this.clickChild = click)}
              deleteRequest={this.deleteRequest}
              idColumn={'id'}
              //modelsToUpdateArr={false}
              //enableSetResizeChild={true}
              //setResize={click => (this.autofitFunctions[0] = click)}
              enableSetResizeChildColumns={true}
              setResizeToColumnWidth={click => (this.autofitFunctions[0] = click)}
              singleClickEdit={true}
              suppressClickEdit={false}
              isSaveable={false}
              isAwardable={false}
              isDeletable={false}
              isTableRowSelectable={false}
              isLoadingTableData={this.mobxState.isLoadingTableData[0]}
            />
          </div>
          <div className='col-lg-6'>
            {/* AG Grid */}
            <GridRenderer
              gridHeight={'650px'}
              columnDefs={this.mobxState.columnDefsCategoryBreakdownBySupplierGroup}
              autoGroupColumnDef={{
                  minWidth: 300,
                  headerName: 'Supplier',
                  cellRendererParams: {
                    innerRenderer: 'passthroughRowGroupInnerRenderer'
              }}}
              groupDefaultExpanded={0}
              rowData={this.mobxState.tableData['by_supplier_group']}
              fetchData={this.fetchData}
              handleClear={this.handleClear}
              toggleDock={this.toggleDock}
              pageId={this.pageId}
              savedState={this.mobxState.pivotStateBySupplierGroup}
              pivotMode={true}
              isPivotMode={true}
              gridTitle={'Supplier Breakdown'}
              setClick={click => (this.clickChild = click)}
              deleteRequest={this.deleteRequest}
              idColumn={'id'}
              //modelsToUpdateArr={false}
              //enableSetResizeChild={true}
              //setResize={click => (this.autofitFunctions[1] = click)}
              enableSetResizeChildColumns={true}
              setResizeToColumnWidth={click => (this.autofitFunctions[1] = click)}
              singleClickEdit={true}
              suppressClickEdit={false}
              isSaveable={false}
              isAwardable={false}
              isDeletable={false}
              isTableRowSelectable={true}
              isLoadingTableData={this.mobxState.isLoadingTableData[1]}
            />
          </div>
        </div>
        <br />
        {/* Sidebar */}
        <DockRenderer
          position={'right'}
          size={this.mobxState.dockOptions.size}
          dimMode={'none'}
          isVisible={this.mobxState.dockOptions.isVisible}
          onVisibleChange={this.handleVisibleChange}
          onSizeChange={this.handleSizeChange}
          fluid={true}
          toggleDock={this.toggleDock}
          handleClear={this.handleClear}
          // Current Filters----------------------------------
          value={this.mobxState.selectedOptions}
          // Select Names-------------------------------------
          filterModelNames={this.mobxState.filterModelNames}
          customFilterModelNames={this.mobxState.customFilterModelNames}
          // Widget Types-------------------------------------
          filterWidgetTypes={this.mobxState.filterWidgetTypes}
          customFilterWidgetTypes={this.mobxState.customFilterWidgetTypes}
          // Options------------------------------------------
          options={this.mobxState.filterOptions}
          customFilterOptions={this.mobxState.customFilterOptions}
          // Handlers-----------------------------------------
          handleFilter={this.handleFilter}
          handleCustomFilter={this.handleCustomFilter}
          // Search Functions --------------------------------
          optionSearchFunctions={this.mobxState.optionSearchFunctions}
          // Visual options-----------------------------------
          customOptions={this.mobxState.customOptions}
          // Callbacks----------------------------------------
          closeMenuOnSelect={true}
          maxMenuHeight={400}
        />
      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(CategoryBreakdown, {
  mobxState: observable
});

export default observer(CategoryBreakdown);

// export default CategoryBreakdown ;
