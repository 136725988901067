import React from 'react';
import { render } from 'react-dom';

import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col, TreeSelect } from 'antd';
import Select from 'react-select';

import axios from 'axios';

import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatForTreeSelect, removeKeys } from '../../../../services/Utilities';

const _ = require('lodash');

/*
NOTES:
  This form is wired wierd.
  project_grid_rows uses `custom_schemas.value` rather than schema definition for some unknown reason.
*/

class SetupSettings extends React.Component {
  mobxState = {
    options: {
      project_grid_rows: [
        { value: 1000, label: '1 Thousand' },
        { value: 10000, label: '10 Thousand' },
        { value: 50000, label: '50 Thousand' },
        { value: 100000, label: '100 Thousand' }
      ],
      project_category_settings: {
        enabled: [
          { value: true, label: 'True' },
          { value: false, label: 'False' }
        ],
        default_category_id: []
      }
    },
    customSchemas: {
      project_grid_rows: null,
      project_category_settings: null
    }
  };
  componentDidMount() {
    this.fetchData();
  }
  fetchData = () => {
    // Load category options (Needs background processing so start it early)
    axios.post('/v1/categories_index', {}).then(response => {
      let tmpResult = formatForTreeSelect(response.data.categories, [
        r => (r.custom_fields && r.custom_fields.custom_field_1) || '',
        r => (r.custom_fields && r.custom_fields.custom_field_2) || '',
        r => (r.custom_fields && r.custom_fields.custom_field_3) || '',
        r => (r.custom_fields && r.custom_fields.custom_field_4) || '',
        r => (r.custom_fields && r.custom_fields.custom_field_5) || ''
      ]);
      this.mobxState.options['project_category_settings']['default_category_id'] = tmpResult;
    });

    // Load custom schemas
    axios.get('/v1/custom_schemas', { params: { name: 'project_grid_rows' } }).then(response => {
      this.mobxState.customSchemas.project_grid_rows =
        response.data && response.data.custom_schemas && response.data.custom_schemas[0];
    });

    axios.get('/v1/custom_schemas', { params: { name: 'project_category_settings' } }).then(response => {
      this.mobxState.customSchemas['project_category_settings'] =
        response.data && response.data.custom_schemas && response.data.custom_schemas[0];
    });

    // Set flags indicating form options have loaded
  };

  handleChange = (propertyPath, fieldValue) => {
    _.set(this.mobxState.customSchemas, propertyPath, fieldValue);
    this.handleSubmit();
  };
  handleSubmit = () => {
    // Update custom_schemas.schema_value
    axios.put(
      `/v1/custom_schemas/${this.mobxState.customSchemas['project_grid_rows'].id}`,
      this.mobxState.customSchemas['project_grid_rows']
    )
    axios.put(
      `/v1/custom_schemas/${this.mobxState.customSchemas['project_category_settings'].id}`,
      this.mobxState.customSchemas['project_category_settings']
    )
  };
  findObjectWithValue = (optionsArray, value) => {
    return optionsArray.find(o => o.value === value);
  };

  render() {
    return (
      <div>
        {/* Project Rows Form */}
        <div>
          <div
            className='jumbotron jumbotron_full_width'
            style={{ maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap' }}
          >
            <div className='jumbotron_full_width_container'>
              <div className='row'>
                <div className='col-sm-5'>
                  <span className='fa-layers fa-fw fa-2x'>
                    <FontAwesomeIcon
                      icon={['fas', 'circle']}
                      transform='grow-3'
                      color='#53C8C5'
                      style={{ marginTop: '4px' }}
                    />
                    <FontAwesomeIcon
                      icon={['fas', 'folders']}
                      color='white'
                      transform='shrink-5'
                      style={{ marginTop: '3px' }}
                    />
                  </span>
                  &nbsp;
                  <b className='dash_header'>Edit Setup Settings</b>
                </div>
                <div className='col-sm-2'></div>
                <div className='col-sm-2'></div>
                <div className='col-sm-3 gps'>
                  <FontAwesomeIcon
                    icon={['fad', 'location-arrow']}
                    color='#53C8C5'
                    transform='grow-15 left-10'
                    style={{}}
                  />
                  <Link to={'/sourcing/projects/'}>Next: Manage Projects</Link>
                </div>
              </div>
            </div>
          </div>

          {/* Project Category Form */}
          <div className='row'>
            <div className='col-sm-12'>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg clearfix'>
                  <b>Set Default Amount of Table Rows</b>
                </div>
                <div className='panel-body'>
                  <Select
                    onChange={valueLabel => this.handleChange(['project_grid_rows', 'schema_value'], valueLabel.value)}
                    options={this.mobxState.options.project_grid_rows}
                    value={this.findObjectWithValue(
                      this.mobxState.options.project_grid_rows,
                      this.mobxState.customSchemas.project_grid_rows &&
                        this.mobxState.customSchemas.project_grid_rows.schema_value
                    )}
                    closeMenuOnSelect={true}
                    maxMenuHeight={400}
                    placeholder={'Please select option'}
                  />
                </div>
              </div>
            </div>
            {/* END col-sm-12*/}
          </div>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg clearfix'>
                  <b>Project Category Enabled?</b>
                </div>
                <div className='panel-body'>
                  <Select
                    onChange={valueLabel =>
                      this.handleChange(['project_category_settings', 'schema_definition', 'enabled'], valueLabel.value)
                    }
                    options={this.mobxState.options.project_category_settings.enabled}
                    value={this.findObjectWithValue(
                      this.mobxState.options.project_category_settings &&
                        this.mobxState.options.project_category_settings.enabled,
                      this.mobxState.customSchemas.project_category_settings &&
                        this.mobxState.customSchemas.project_category_settings.schema_definition &&
                        this.mobxState.customSchemas.project_category_settings.schema_definition.enabled
                    )}
                    closeMenuOnSelect={true}
                    maxMenuHeight={400}
                    placeholder={'Please select option'}
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-8'>
              <div className='panel panel-default'>
                <div className='panel-heading panel_bg clearfix'>
                  <b>Set Default Category</b>
                </div>
                <div className='panel-body'>
                  <TreeSelect
                    onChange={value => this.handleChange(['project_category_settings', 'schema_definition', 'default_category_id'], value)}
                    value={
                      this.mobxState.customSchemas.project_category_settings &&
                      this.mobxState.customSchemas.project_category_settings.schema_definition &&
                      this.mobxState.customSchemas.project_category_settings.schema_definition.default_category_id &&
                      `${this.mobxState.customSchemas.project_category_settings.schema_definition.default_category_id}`
                    }
                    treeData={this.mobxState.options.project_category_settings.default_category_id || []}
                    filterTreeNode={(input, node) => node.title.toLowerCase().includes(input.toLowerCase())}
                    treeDefaultExpandAll={false}
                    style={{ width: '100%' }}
                    showSearch={true}
                    closeMenuOnSelect={true}
                    maxMenuHeight={400}
                    className='form-control'
                    dropdownClassName={'antd-filter-dropdown'}
                    placeholder={'Please select option'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

decorate(SetupSettings, {
  mobxState: observable
});

export default observer(SetupSettings);
