import React from 'react';
import { render } from 'react-dom';
import ReactDOM from 'react-dom';
//http requests
import axios from 'axios';
//import queryString from 'query-string'; // parsing query string

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull, extractToken } from '../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//inputs for forms
import NewItemNameInput from './NewItemNameInput';
import NewItemQuantityInput from './NewItemQuantityInput';
import NewItemWeightInput from './NewItemWeightInput';
import NewItemRatioInput from './NewItemRatioInput';

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

//imports for custom fields form
import { Form, Input, Button } from 'antd';
import NumericInput from 'react-numeric-input';
import { element } from 'prop-types';

//Item Import Form
import SpreadSheetImport from '../FileImportation/SpreadSheetImport';

//Lodash utilities
import { uniq, union } from 'lodash';

class CreateNewItemForm extends React.Component {

    projectId = this.props.projectId



    //state
  mobxState = {
    formState: {
      itemName: null, //data for item name input
      itemQuantity: null, //data for item quantity
      itemWeight: null, //data for item weight (optional)
      itemRatio: null, //data for item ratio (optional)
      custom_fields: {}, //holds data from custom_fields
      custom_fields_submit: {},//holds custom field data as well as null values for empty input fields
      formValid: false,
      showHelperIcons: false,
      sortOptions: [{ id: 'display_order', desc: true }],
      submitMessage: false
    },
    customForm: {
      //holds data for dynamically generated form
      response_data: {}
    },
    customOptions: [],
    cardData: {},
    tableData: {
      new_items: []
    },
    selectedModels: {}, //multi select data model for http
    selectedOptions: {}, //multi select data model in filter value/label format
    dockOptions: {
      isVisible: false,
      size: 0.2
    }, //sidebar defaults
    pivotData: [],
    pivotState: [],
    pivotFilter: [],
    filterModelNames: ['Cat_Desc', 'Cat1', 'Cat2', 'Cat3', 'Cat4', 'year', 'month'], //autobuilds filters and clear functions
    submitMessage: false //controls message that confirms constraint was saved
  };

  componentDidMount() {
    this.fetchData();
  }

   //Form submission
   handleFormSubmit = event => {

    //sort through custom fields to add null values to empty input fields
    let filledValues = []; //values for filled of custom_fields inputs
    let originalValues = []; //complete list of custom_fields from database

    //holds values for filled of custom_fields inputs
    Object.entries(this.mobxState.formState.custom_fields).forEach((item) => {
      filledValues.push([item[0],item[1]])
    })
    //complete list of custom_fields from database with added value of null
    Object.entries(this.mobxState.customForm.response_data).forEach((item) => {
      originalValues.push( [item[0], item = null] )
    })

    let filledArr = originalValues.concat(filledValues)
    let filteredArr = union(filledArr) //remove duplicates
    this.mobxState.formState.custom_fields_submit = Object.fromEntries(filteredArr)
    //console.log(custom_fields_submit_object)

    axios
      .post(`/v1/projects/${this.projectId}/items`, {
        name: this.mobxState.formState.itemName,
        quantity: this.mobxState.formState.itemQuantity,
        project_id: this.projectId,
        weight: this.mobxState.formState.itemWeight,
        ratio: this.mobxState.formState.itemRatio,
        custom_fields: this.mobxState.formState.custom_fields_submit
      })
      .then(() => {{this.mobxState.submitMessage = true}setTimeout(() => {
          this.mobxState.submitMessage = false}, 7000) }) //shows item saved message

      .then(response => {
        this.props.handleSheetSubmit();
    //clears all custom fields inputs otherwise only numeric inputs will clear on submit\
        this.mobxState.customForm.response_data = [];
        this.mobxState.formState.custom_fields = {};
        this.fetchData();
        this.mobxState.formState.showHelperIcons = false;
      });
  };

  //HTTP request for data to populate form
  fetchData = () => {
    //Dynamic Form
    axios
      .get(`/microservices/v1/projects/${this.projectId}/items/item_custom_form`, { params: {} })
      .then(response => {
        //console.log(response.data.custom_fields_list)
        this.mobxState.customForm.response_data = response.data.custom_fields_list;
      })
      .catch(error => {
        console.log(error);
      });
  };

  //Clears inputs on form
  // clearInputs = e => {
  //   this.mobxState.customForm.response_data = [];
  //   this.mobxState.formState.custom_fields = {};
  //   this.mobxState.formState.itemName = null;
  //   this.mobxState.formState.itemQuantity = null;
  //   this.mobxState.formState.itemWeight = null;
  //   this.mobxState.formState.itemRatio =  null;
  //   this.fetchData();
  //   this.validateForm();
  // };

  //react-select form state handlers
  handleFormChange = (field, value) => {
    console.log('handleFormChange with params:', field, value);
    this.mobxState.formState[field] = value;
    //call validationResult
    this.validateForm();
  };
  handleCustomFormChange = (field, value) => {
    this.mobxState.formState.custom_fields[field] = value;
    //call validate form
    this.validateForm();
  };

  validateForm = () => {
    //For now all custom fields do not need to be filled out
    let formConditionsMet =
      this.mobxState.formState.itemName !== null &&
      this.mobxState.formState.itemQuantity !== null &&
      Object.keys(this.mobxState.formState.custom_fields).length > 0;
    //for now individual custom fields can be left empty jsonb attribute will be added to determine if a field can be empty

    let formValid = formConditionsMet;

    if (formValid) {
      this.mobxState.formState.formValid = true;
      this.mobxState.formState.showHelperIcons = true;
    } else {
      this.mobxState.formState.formValid = false;
      this.mobxState.formState.showHelperIcons = false;
    }
    // console.log(this.mobxState.formState);
  };

    render(){
        return(
            <div className="row">
              <div className="col-sm-12">
                <div className="panel panel-default">
                  <div className="panel-heading panel_bg clearfix">
                    <b>Create a New Item</b>
                    <span className="pull-right">
                      {/* Constraint Saved Message */}
                      <Bounce left when={this.mobxState.submitMessage}>
                        <b className="text-success" style={{ marginRight: '17px', marginTop: '4px' }}>
                          Item Saved
                        </b>
                      </Bounce>
                      <Bounce left when={this.mobxState.formState.showHelperIcons}>
                        <span>
                          <FontAwesomeIcon
                            icon={['fad', 'long-arrow-alt-right']}
                            size="2x"
                            transform="down-3 grow-10"
                            color="#53C8C5"
                            style={{ marginRight: '15px' }}
                          />
                        </span>
                      </Bounce>
                      <button
                        type="button"
                        disabled={!this.mobxState.formState.formValid}
                        className="btn btn-success btn-md"
                        onClick={this.handleFormSubmit}
                      >
                        <FontAwesomeIcon icon={['fal', 'save']} />&nbsp; Save Item
                      </button>
                      &nbsp;
                  { /* <button type="button"
                        className="btn btn-primary btn-md disabled"
                        onClick={this.handleFormSubmit}>
                        <FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Bulk Add
                      </button>*/}
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#importForm"
                        aria-expanded="false"
                        aria-controls="importForm">
                          <FontAwesomeIcon icon={['fal', 'file-import']} />&nbsp; Import Excel/CSV
                      </button>
                    </span>
                  </div>
                 {/* collapsing form for importing data from external excel or csv file */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="collapse multi-collapse" id="importForm">
                        <div className="card card-body">
                          {/* holds import excel and csv import forms and buttons */}
                          <SpreadSheetImport
                            requiredCols={['name','quantity']}//columns required to be in spreadsheet for form submission
                            tableName={"items"} //name of DB that the form will submit to
                            projectId={this.projectId}
                            handleSheetSubmit={this.props.handleSheetSubmit}
                            systemFieldCols={['name', 'weight','quantity', 'ratio', 'baseline_price','baseline_supplier']}
                            isConstraint={false}
                            scenarioId={null}
                            optionalCols={['baseline_price', 'weight', 'ratio','baseline_supplier']} //cols that arent required and arent custom aliases
                            templateToBuild={'items'} //used for deciding what kind of spreadsheet template to download
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="panel-body">
                    <form id="main-form">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className={'top_panel_info'}>Input a Name and Quantity for a new item</div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <NewItemNameInput
                          formField={'itemName'}
                          projectId={this.projectId}
                          handleFormChange={this.handleFormChange}
                          scopeCssClass={'col-lg-5'}
                        />

                        <NewItemQuantityInput
                          projectId={this.projectId}
                          handleFormChange={this.handleFormChange}
                          scopeCssClass={'col-lg-5'}
                          formField={'itemQuantity'}
                        />

                      </div>
                      {/* End row */}
                      <br />
                      <div className="row">
                        <div className="col-sm-12">
                          <div className={'top_panel_info'}>Input values for Item Weight and Ratio (Optional)</div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <NewItemWeightInput
                          projectId={this.projectId}
                          handleFormChange={this.handleFormChange}
                          scopeCssClass={'col-lg-5'}
                          formField={'itemWeight'}
                        />

                        <NewItemRatioInput
                          formField={'itemRatio'}
                          projectId={this.projectId}
                          handleFormChange={this.handleFormChange}
                          scopeCssClass={'col-lg-5'}
                        />

                      </div>
                      <br />
                      {/* //Custom fields Form */}
                      <div className="row custom-fields">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="top_panel_info">Input a name for Custom Fields</div>
                            </div>
                          </div>
                          <div className="table-responsive item-create-tables" sorted={this.mobxState.formState.sortOptions}>
                            {/* STRING FIELDS */}
                            <div className="col-lg-12 tab">
                              {/* dynamically generated custom field form */}
                              {/* <div className="custom-form-header">
                                <b>Input a Value Custom Fields</b>
                              </div> */}
                              <table className="table table-striped table-hover">
                                <thead>
                                  <tr>
                                    <th>Field Name</th>
                                    {/* <th>Field Alias</th>
                                      <th>Display Order</th> */}
                                    <th>Value Input</th>
                                  </tr>
                                </thead>
                                <tbody className="input-tables">
                                  {/* Maps table rows based on schema type */}
                                  {Object.entries(this.mobxState.customForm.response_data).map(([key, value]) => {
                                    if (value.schema.type == 'string') {
                                      //console.log(key, value)
                                      return (
                                        <tr key={key} ref="textInput">
                                          <td key={value.field_alias}>{value.field_alias}</td>
                                          {/* <td key={value.field_name}>{value.field_name}</td> */}
                                          {/* <td key={value.display_order}>{value.display_order}</td> */}
                                          <td key={value.table_alias}>
                                            <Input
                                              formfield={'custom_fields'}
                                              className="custom-input"
                                              placeholder="Enter a text value"
                                              name={value.field_name}
                                              onChange={e =>this.handleCustomFormChange(value.field_name, e.target.value)}
                                              allowClear
                                            />
                                          </td>
                                        </tr>
                                      );
                                                  }
                                                  else if (value.schema.type == 'number') {
                                      return (
                                        <tr key={key}>
                                          <td key={value.field_alias}>{value.field_alias}</td>
                                          {/* <td key={value.field_name}>{value.field_name}</td> */}
                                          {/* <td key={value.display_order}>{value.display_order}</td> */}
                                          <td key={value.table_alias}>
                                            <NumericInput
                                              //strict={true}
                                              formfield={'custom_fields'}
                                              name={value.field_name}
                                              onChange={e => this.handleCustomFormChange(value.field_name, e)}
                                              className="form-control custom-input"
                                              min={0}
                                              placeholder="Input a number"
                                            />
                                          </td>
                                        </tr>
                                      );
                                    } else {
                                      console.log('Date Type Schema');
                                    }
                                  })}
                                </tbody>
                              </table>
                            </div>{' '}
                            {/* End of col-lg-6*/}
                          </div>{' '}
                          {/* End of .create-tables container */}
                        </div>
                      </div>{' '}
                      {/*END OF ROW */}
                    </form>
                  </div>
                </div>
              </div>
              {/* END col-sm-12*/}
            </div>
        );
    }
}

decorate(CreateNewItemForm, {
    mobxState: observable
})

export default observer(CreateNewItemForm);
