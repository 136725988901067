import React from 'react';
import PropTypes from 'prop-types';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//highcharts
import BarChart from '../basics/BarChart';
import ParallelBarChart from '../basics/ParallelBarChart';
import PieChart from '../basics/PieChart';
import LineChart from '../basics/LineChart';
import MultiLineChart from '../basics/MultiLineChart';
import PercentageCircle from '../basics/PercentageCircle';
import FunnelChart from '../basics/FunnelChart';
import PieChartLabel from '../basics/PieChartLabel';
import BubbleChart from '../basics/BubbleChart';
import TableChart from '../basics/TableChart';
import EyeChart from '../basics/EyeChart';
import ScrolledBarChart from '../basics/ScrolledBarChart';
import SpinLoader from '../../loaders/SpinLoader';
import { checkRole } from '../../security/SecurityUtilities';
import withRole from '../../security/withRole';

//filters
import FilterQuickAction from '../../filters/FilterQuickAction'; //filter controls
import VisualizationConfigWidget from './ConfigQuickAction';

//Renders the custom charts based on API call
class CustomChartRenderer extends React.Component {
  mobxState = {
    customCharts: []
  };

  componentWillMount() {
    checkRole({ model: 'visualization_client_config', permission: 'w' }).then(res => {
      this.mobxState['hasChartEditPermission'] = res;
    });
    checkRole({ model: 'builder_admin', permission: 'a' }).then(res => {
      this.mobxState['hasSuperUserPermission'] = res;
    });
  }

  //!!!BUBBLE CHART NOT SET UP YET - UNTESTED
  render() {
    const customCharts = [];

    for (const [index, value] of this.props.chartRenderData.entries()) {
      if (value.chart_type === 'badge') {
        // Skip badges, they are handled by CustomBadgeRenderer
        continue;
      } else if (value.chart_type === 'table') {
        customCharts.push(
          <div key={index} className={'col-lg-' + value.column_size}>
            <TableChart
              renderInDivId={'chart_' + value.id}
              handleFilterFunction={this.props.handleFilter}
              tableTitle={value.chart_title}
              height={value.height + 'px'}
              filterParam={value.filter_param}
              columnDefs={value.column_definitions}
              rowData={value.chart_data}
              handleClear={this.props.handleClear}
              toggleDock={this.props.toggleDock}
              isLoadingTableData={value.loading}
            />
          </div>
        );
      } else {
        customCharts.push(
          <div key={index} className={'col-lg-' + value.column_size}>
            <div className='panel panel-default'>
              <div className='panel-heading panel_bg'>
                <b>{value.name}</b>
                <span className='pull-right'>
                  {this.mobxState['hasChartEditPermission'] || this.mobxState['hasSuperUserPermission'] ? (
                    <VisualizationConfigWidget
                      value={value}
                      handleChange={(name, v) => {
                        value[name] = v;
                      }}
                      handleSubmit={() => {
                        axios
                          .put(`/v1/visualization_client_configs/${value.id}`, value)
                          .then(() => window.location.reload());
                      }}
                      handleClone={() => {
                        axios
                          .post(`/v1/visualization_client_configs/${value.id}/clone`)
                          .then(result => window.location.reload());
                      }}
                      hasChartEditPermission={this.mobxState['hasChartEditPermission']}
                      hasSuperUserPermission={this.mobxState['hasSuperUserPermission']}
                    />
                  ) : (
                    <React.Fragment />
                  )}

                  <FilterQuickAction handleClear={this.props.handleClear} toggleDock={this.props.toggleDock} />
                </span>
              </div>
              <div className='panel-body'>
                <div className='col-lg-12'>
                  {(value.loading && (
                    <div className='hv-centered-container' style={{ height: value.height + 'px' }}>
                      <SpinLoader style={{ color: '#42A5F5' }} size={'3x'} />
                    </div>
                  )) ||
                    (value.chart_type == 'parallel_bar' && (
                      <ParallelBarChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        barData={value.chart_data}
                        barTitle={value.chart_title}
                        xTitle={value.x_title}
                        yTitle={value.y_title}
                        zTitle={value.z_title}
                        enableLegend={value.enable_legend}
                        wrapLabel={value.wrap_label}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                        columns_shown={value.columns_shown}
                      />
                    )) ||
                    (value.chart_type == 'scrolled_bar' && (
                      <ScrolledBarChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        barData={value.chart_data}
                        barTitle={value.chart_title}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                        columns_shown={value.columns_shown}
                        wrapLabel={value.wrap_label}
                      />
                    )) ||
                    (value.chart_type == 'pie' && (
                      <PieChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        pieData={value.chart_data}
                        pieTitle={value.chart_title}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                      />
                    )) ||
                    (value.chart_type == 'line' && (
                      <LineChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        lineData={[value.chart_data]}
                        lineTitle={value.chart_title}
                        lineYTitle={value.y_title}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                        lineType={'area'}
                      />
                    )) ||
                    (value.chart_type == 'multiline' && (
                      <MultiLineChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        lineData={value.chart_data}
                        lineTitle={value.chart_title}
                        lineYTitle={value.y_title}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                        lineType={'line'}
                      />
                    )) ||
                    (value.chart_type == 'funnel' && (
                      <FunnelChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        funnelData={value.chart_data}
                        funnelTitle={value.chart_title}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                      />
                    )) ||
                    (value.chart_type == 'bubble' && (
                      <BubbleChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        bubbleData={value.chart_data}
                        bubbleTitle={value.chart_title}
                        xTitle={value.x_title}
                        yTitle={value.y_title}
                        zTitle={value.z_title}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                      />
                    )) ||
                    (value.chart_type == 'bar' && (
                      <BarChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        barData={value.chart_data}
                        barTitle={value.chart_title}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                        wrapLabel={value.wrap_label}
                      />
                    )) ||
                    (value.chart_type == 'eye' && (
                      <EyeChart
                        renderInDivId={'chart_' + value.id}
                        handleFilterFunction={this.props.handleFilter}
                        barData={value.chart_data}
                        barTitle={value.chart_title}
                        height={value.height + 'px'}
                        filterParam={value.filter_param}
                      />
                    )) ||
                    ((typeof value.chart_type === 'undefined' ||
                      value.chart_type === null ||
                      value.chart_type === '') && <div></div>)}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return <div>{customCharts}</div>;
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(CustomChartRenderer, {
  mobxState: observable
});

export default observer(CustomChartRenderer);
