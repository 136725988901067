// Imports
// Adapted from https://react-select.com/async
import React from 'react';

import { Select, Spin } from 'antd';

// Options
// Actually show all the filters applied?
export default class FilterCountIndicator extends React.Component {
  filterCount = () => {
    let countFilters = obj => {
      let count = 0;
      Object.keys(obj).forEach(k => {
        if (Array.isArray(obj[k])){
          count += (obj[k].length >= 1 ? 1 : 0);
        } else if (typeof obj[k] === 'object'){
          count += countFilters(obj[k]);
        }
      });
      return count;
    }
    let ms = JSON.parse(JSON.stringify(this.props.selectedModels));
    return countFilters(ms);
  };
  render = () => {
    let numFilters = this.filterCount();
    return numFilters === 0 ? (
      <React.Fragment />
    ) : (
      <button type='button' className='btn btn-brand pull-right' onClick={() => this.props.handleClick()}>
        {numFilters} Filter{numFilters === 1 ? '' : 's'} Applied
        &nbsp;
        <span className='glyphicon glyphicon-filter' />
      </button>
    );
  };
}

//
