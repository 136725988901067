import React from 'react'

//other components built
import Badge from "../../../badges/Badge"

//filters
import DockRenderer from "../../../filters/DockRenderer"
import FilterQuickAction from "../../../filters/FilterQuickAction" //filter controls
import Select from 'react-select' //select box for filter

//trello board
import Board from 'react-trello'
import KanbanModal from "../../../kanban/KanbanModal"

//http requests
import axios from 'axios'

//state
import { decorate, observable, computed, action } from "mobx"
import { observer } from "mobx-react"

//utility
import {zeroNull} from "../../../services/Utilities"

// import FontAwesome from 'react-fontawesome'
// import faStyles from 'font-awesome/css/font-awesome.css'

//note: its about 10-20% faster to build filters manually instead of the automated FilterRender class
class ProcessOverview extends React.Component {

		mobxState = {
			customOptions: [],
			modalId: 'kanbanModal',
			modalCard: {"Request_Title": "Request Title"},
			cardTasks: [{"Task_Name":"test"}],
			cardData: {
				"boardData": {"lanes": []},
				"contractValue": 0,
				"contractCount": 0,
				"contractSupplierCount": 0,
				"supplierContractSum": [], //charts
				"on_contract_count": [], //charts
				"dbe_count": [], //charts
				"cat_1_top_sum": [], //charts
				"year_month_sum_line": [],
			},
			selectedModels: {
			}, //multi select data model for http
			selectedOptions: {
			}, //multi select data model in filter value/label format
			dockOptions: {
				isVisible: false,
				size: 0.2
			}, //sidebar defaults
			pivotData: [],
			pivotState: [],
			pivotFilter: [],
			filterModelNames: ['Request_Title', 'year', 'month', 'Released_User', 'Status_ID'] //autobuilds filters and clear functions
		};

		//mount data through http requests
		componentDidMount() {
			//call the data loader to load data into the view
			this.fetchData();
		}

		//////////////////HTTP/////////////////
		//data loader
		fetchData = () => {
			//quick workaround -- pull simple dropdown value from nested component instead of passing around state
			var table_rows = window.$("#table_rows_dropdown").val();

			//set up custom options for components, then get data for components
			axios.get("/v1/ref_prfields_labels")
			.then((response) => {
				//get map of field labels from API
				this.mobxState.customOptions = response.data;
				//include any custom field names not in db
				this.mobxState.customOptions = {...this.mobxState.customOptions, ...{"year": "Created Year", "month": "Created Month"}};
				//merge the filter model with the amount of rows selected
				let models_with_rows = {...this.mobxState.selectedModels, ...{"table_rows": table_rows}};
				//after ag grid is set up, get the rest of the data
				//axios.get("/companies/"+companyToken+"/spend_data.json", { params: models_with_rows })
				axios.get("/microservices/v1/analytics/user_board", { params: models_with_rows })
				.then(response => {
					this.mobxState.cardData = response.data;
					console.log("LANES ", response.data);
				})
			})
			.catch(console.log.bind(console)) //error catcher
		}

		deleteRequest = (toSendArr) => {
			console.log("IN DELETION");
	    axios.post('/v1/cm_contracts1/batch_destroy', {"id_set": toSendArr})
	    .then((response) => {
				console.log(response);
				this.fetchData();
	    });
		}
		//////////////////END HTTP/////////////////

	  // //handles dropdown and chart filters
		// handleFilter = (filterParam, selectedFilter) => {
		// 	console.log('Handle Filter Callback: ');
		// 	console.log('selectedFilter ', selectedFilter); console.log('filterParam ', filterParam);
		// 	//in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
		// 	this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
		// 	//also store the selections in the original form. This goes back to the filter as selected values
		// 	this.mobxState.selectedOptions[filterParam] = selectedFilter;
		// 	//fetch called, it reads the filter params from state
		// 	this.fetchData();
		// }

		//handles dropdown and chart filters
		handleFilter = (filterParam, selectedFilter) => {
			console.log('Handle Filter Callback: ');
			console.log('selectedFilter ', selectedFilter); console.log('filterParam ', filterParam);
			//in the state, pick the model based on filter that is sent and turn it into the array of values of the filter. This goes as params to http request
			//NOTE.. THIS HAS TO BE ARRAY COMING IN HERE.. if filter is not multi select, then it does not default to array and you have to wrap the selection in array
			this.mobxState.selectedModels[filterParam] = selectedFilter.map(a => a.value);
			//also store the selections in the original form. This goes back to the filter as selected values
			this.mobxState.selectedOptions[filterParam] = selectedFilter;
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

	    //handles dropdown and chart filters
		handleClear = () => {
			//in the state, clear the model. This goes as params to http request
			this.mobxState.selectedModels = {};
			//also store the explicit cleared selections in the original form. This goes back to the filter as selected values
			//loop below takes array of column names and attaches blank arrays to them to explicitly clear the filter
			//from ["supplier", "item"] to {"supplier":[], "item":[]}
			const optionsClear = {};
			for (const key of this.mobxState.filterModelNames) { optionsClear[key] = [];}
			this.mobxState.selectedOptions = optionsClear;
			//add the template filter to clear as well.. because Temp_ID is not in the filterModelNames because it is a separate filter in a separate place from the rest of the filters
			this.mobxState.selectedOptions['Temp_ID'] = [];
			//fetch called, it reads the filter params from state
			this.fetchData();
		}

		handleBoardStatusChange = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
			console.log("Changing ticket# ", cardDetails.title);
			console.log("Target Lane ID# ", targetLaneId)
			axios.put("/v1/pr_tble1/"+cardDetails.id, {"Released_User": targetLaneId})
			.then(response => { console.log(response.data);});
			//console.log("in drag end: ", cardId, sourceLaneId, targetLaneId, position, cardDetails)
		}

		//this is to change the positions of the cards only. react-trello is sending all board data to this method,
		//this basically loops through all lanes and cards and sends the order into the server
		//very inefficient to send all data over, but may be the only way to get all lane positions from react-trello
		handleTrelloDataChange = nextData => {
			//console.log('DATA CHANGE: ');
			nextData.lanes.forEach(function (lane, laneIndex) {
				lane.cards.forEach(function (card, cardIndex) {
					axios.put("/v1/pr_tble1/"+card.id, {"Reason_Code": cardIndex});
					//.then(response => { console.log(response.data);});
				});
			});
		}

		//handles click of the kanban card
		handleCardClick = (cardId, metadata, laneId) => {
			console.log("Clicked Card ID# ", cardId);
			//initial request to get the task details, this is needed to get the Temp_ID from the ticket
			axios.get("/v1/pr_tble1/"+cardId).then(taskDetails => {

				console.log("taskDetails::: ", taskDetails);
				this.mobxState.modalCard = taskDetails.data; // Do not DELETE, these modal details are used in other modal calls
				console.log("modalCard::: ",this.mobxState.modalCard);
				//actual request to get the relevant tasks
				axios.get("/microservices/v1/pm/show_ticket_tasks/"+cardId, {params: {"Temp_ID": taskDetails.data.Temp_ID}})
				.then(response => {
					console.log("Card Tasks::: ", response.data.tasks);
					this.mobxState.cardTasks = response.data.tasks;
					window.$('#'+this.mobxState.modalId).modal('show');//window because jquery/bs loaded in index before react
				});
			})
		}

		//handles title update from the inline text library with a title update of the ticket in the API
    handleModalTitleChange = (titleObject) => {
			// Update your model from here
			axios.put("/v1/pr_tble1/"+this.mobxState.modalCard.Tket_ID, {"Request_Title": titleObject.title})
			.then(response => {
				this.fetchData();
				console.log(response.data);
			});
		}

		//delete ticket thru api
		handleTicketDelete = (id) => {
			console.log("DELETE TICKET");

			// Update your model from here
			axios.delete("/v1/pr_tble1/"+id)
			.then(response => {
				this.fetchData();
				console.log(response.data);
				window.$('#'+this.mobxState.modalId).modal('hide');//window because jquery/bs loaded in index before react
			});
		}

		//for sidebar dock to change sizes
		handleSizeChange = size => {
			this.mobxState.dockOptions.size = size;
		}

		//sidebar dock toggle
		toggleDock = () => {
			this.mobxState.dockOptions.isVisible = !this.mobxState.dockOptions.isVisible
		}

		render() {

			return (

				<div>

					{/* Small Left Toggle */}
					<div onClick={this.toggleDock}  className="sidebar_button filter_toggle"><i className="glyphicon glyphicon-chevron-left"></i></div>

					<div className="jumbotron jumbotron_full_width">
						<div className="jumbotron_full_width_container">
							<b className="dash_header">The Procurement Board</b>
              {/* BADGES */}
              <div className="row">
                <div className="col-lg-4">
                  <Badge
                    badgeNumber={zeroNull(this.mobxState.cardData.ticketCount).toLocaleString('en-US')}
                    badgeName={'Tickets'}
                    faClass={'tag'}
                  />
                </div>
                <div className="col-lg-4">
                  <Badge
                    badgeNumber={zeroNull(this.mobxState.cardData.lateTicketCount).toLocaleString('en-US')}
                    badgeName={'Late Tickets'}
                    faClass={'asterisk'}
                  />
                </div>
                <div className="col-lg-4">
                  <Badge
                    badgeNumber={zeroNull(this.mobxState.cardData.avgTicketDayDuration).toLocaleString('en-US')+" Days"}
                    badgeName={'Average Ticket Duration'}
                    faClass={'clock'}
                  />
                </div>
              </div>

						</div>
					</div>

					<div className="row">
						<div className="col-sm-12">
							<div className="panel panel-default">
								<div className="panel-heading panel_bg">
									<b>Process Management Ticket Template</b>
								</div>
								<div className="panel-body">
									<Select
										value = {this.mobxState.selectedOptions['Temp_ID']}
										onChange={(e) => this.handleFilter(['Temp_ID'], e)}
										options={this.mobxState.cardData.Temp_ID}
										isMulti
										closeMenuOnSelect={true}
										maxMenuHeight = {400}
										placeholder={'Select From List of Created Process Management Templates'}
									/>
								</div>
							</div>
						</div>
					</div>

					{/* PROCESS BOARD */}
          <div className="row">
						<div className="col-lg-12">
              {/* react-trello */}
              <div className="panel panel-default">
                <div className="panel-heading panel_bg">
                  <b>Tickets By Assignee, Creation Date and Status </b>
                  <span className="pull-right">
										<KanbanModal
											modalId={this.mobxState.modalId}
											modalCard={this.mobxState.modalCard}
											tasks={this.mobxState.cardTasks}
											handleModalTitleChange={this.handleModalTitleChange}
											handleTicketDelete={this.handleTicketDelete}
										/>
                    <FilterQuickAction handleClear={this.handleClear} toggleDock={this.toggleDock} />
                  </span>
                </div>
                <div className="panel-body">
									<Board data={this.mobxState.cardData.boardData}
										// editable
										draggable
										onDataChange={this.handleTrelloDataChange}
										eventBusHandle={this.setEventBus}
										onCardClick={this.handleCardClick}
										handleDragStart={this.handleDragStart}
										handleDragEnd={this.handleBoardStatusChange}
										handleTicketDelete={this.handleTicketDelete}
									/>
                </div>
              </div>
						</div>
					</div>{/* END BOARD ROW */}

					<br />
					{/* Sidebar */}
					<DockRenderer
						position={'right'}
						size={this.mobxState.dockOptions.size}
						dimMode={'none'}
						isVisible={this.mobxState.dockOptions.isVisible}
						onVisibleChange={this.handleVisibleChange}
						onSizeChange={this.handleSizeChange}
						fluid={true}

						toggleDock={this.toggleDock}
						handleClear={this.handleClear}

						value={this.mobxState.selectedOptions}
						options={this.mobxState.cardData}
						closeMenuOnSelect={true}
						maxMenuHeight = {400}
						handleFilter={this.handleFilter}
						customOptions={this.mobxState.customOptions}
						filterModelNames={this.mobxState.filterModelNames}
					/>

				</div>
			);
		}

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(ProcessOverview, {
	mobxState: observable
})

export default observer(ProcessOverview);

// export default ProcessOverview;
