import React from 'react';
import { render } from 'react-dom';

//other components built
import Badge from '../../../../badges/Badge';
import GridColumn from '../../../../services/GridColumn'; //builds ag-grid columns
import UrlSniffer from '../../../../services/UrlSniffer'; //work with rails paths to get project token
import SchemaForm from '../../../../forms/SchemaForm'; //the form renderer for custom fields
import ProjectDropdownForm from '../../../../forms/ProjectDropdownForm'; //the form renderer for project dropdown

//react reveal for to reveal components and validations
import Bounce from 'react-reveal/Fade';

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls

//forms
import { AutoComplete, Input, InputNumber, Checkbox, Slider, Row, Col } from 'antd';
import Select from 'react-select'; //select box for filter

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//router
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

//utility
import { zeroNull, deleteIfEmpty, extractToken, newJsonFieldSchema } from '../../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const _ = require('lodash'); //extra utilities

// Making Instance of class > it's object now
const gridColumns = new GridColumn();
const urlSniffer = new UrlSniffer();

class Schema extends React.Component {
  projectId = extractToken(window.location.pathname, '/', 3);
  mobxState = {
    projectFieldSchema: {
      schema_definition: {}
    },
    insightsSchema: {
      schema_definition: {
        categories: 4
      }
    },
    projectDropdownSchema: {
      schema_definition: {
        status: [],
        department: []
      }
    },
    formState: {
      formValid: true
    },
  };

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  };

  //////////////////HTTP/////////////////

  //data loader
  fetchData = () => {
    //quick workaround -- pull simple dropdown value from nested component instead of passing around state
    var table_rows = window.$('#table_rows_dropdown').val();

    //set up custom options for components, then get data for components
    // axios.get("/v1/ref_cmfields_labels")
    axios
      .get('/v1/custom_schemas', { params: { name: 'project' } }) //query the project custom field schema for all projects
      .then(response => {
        //get map of field labels from API
        this.mobxState.customSchema = response.data;
        //include any custom field names not in db
        this.mobxState.customOptions = {
          ...this.mobxState.customOptions,
          ...{project__name: 'Project Name', project__department: 'Department', project__status: 'Project Status', name: 'Project Name', year: 'Created Year', month: 'Created Month', ID: 'Row ID', Cat_ID: 'Project ID', Cat_Desc: 'Project Desc', DateCreated: 'Created Date', URL_Addr: 'URL', Ref_XDate: 'Ref Date', proj_desc: 'Desc 2', Annual_Period: 'Year', MostRecent_Period: 'Most Recent Year', RFI_Scoring: 'RFI Scoring', Pay_Term: 'Pmt Term', Shipment_qty: 'Ship Qty', RFI_End_Time: 'RFI End', RFP_End_Time: 'RFP End', Base_Currency: 'Currency', Mfg_Name: 'Mfg', volume: 'Volume', Cat1: 'Cat1', Cat2: 'Cat2', Cat3: 'Cat3', Cat4: 'Cat4', LastMod_Date: 'Last Modified', Proj_Type: 'Type', ProjGrp: 'Group', RoundNo: 'Round', MultiRound: 'Multi Round', Supp_No: 'Supp No', supplier: 'Supplier', project__incomplete_project: 'Exclude from Reports'}
        };

        //set up custom options for components, then get data for components
  			axios.get('/v1/custom_schemas/', { params: {'name': 'project'} })
  			.then((response) => {
  				//add to customOptions
  				this.mobxState.customOptions = {...this.mobxState.customOptions, ...response.data};
          //store the projectFieldSchema of the project to loop thru and create the form later
          if(response.data.custom_schemas){
            this.mobxState.projectFieldSchema = response.data.custom_schemas[0];
          }
  			})
  			.catch(console.log.bind(console)) //error catcher

      })
      .catch(console.log.bind(console)); //error catcher

    //get the categories
    axios
      .get('/v1/custom_schemas', { params: { name: 'insights' } }) //query the project custom field schema for all projects
      .then(response => {
        //get map of field labels from API
        this.mobxState.insightsSchema = response.data.custom_schemas[0];
      })
      .catch(console.log.bind(console)) //error catcher

    //get project dropdowns
    axios
      .get('/v1/custom_schemas', { params: { name: 'project_dropdowns' } }) //query the project status
      .then(response => {
        //get map of field labels from API
        this.mobxState.projectDropdownSchema = response.data.custom_schemas[0];
      })
      .catch(console.log.bind(console)) //error catcher
  }; //end fetchData() ///////////////

  handleAddField = fieldLevel => {
    console.log('Editing: ', fieldLevel);
    //get all schema fields in array
    let schemaFields = Object.keys(this.mobxState.projectFieldSchema[fieldLevel]);
    let schemaFieldNumbers = [];
    console.log('Schema Fields', schemaFields);
    //loop thru custom fields
    schemaFields.forEach((element) => {
      //push in the number only of the custom field
      schemaFieldNumbers.push(
        Math.trunc(extractToken(element, '_', 2))
      );
    });
    //sort array of fields
    let schemaFieldNumbersSorted = _.sortBy(schemaFieldNumbers);
    //take last field which is now suppposed to be the highest number
    let lastSchemaFieldNumber = _.last(schemaFieldNumbersSorted);
    //Null check if no number exists
    lastSchemaFieldNumber = lastSchemaFieldNumber ? lastSchemaFieldNumber : 0;
    //add 1 to the last schema field number and create a new custom field name
    let newCustomFieldName = 'custom_field_'.concat(lastSchemaFieldNumber + 1);
    //creates the actual json object for new schema that can be inserted into the jsonb field in db params: (name of new field, number to put into display_order)
    let newFieldSchema = newJsonFieldSchema(newCustomFieldName, lastSchemaFieldNumber + 1);
    console.log('New Field Schema', newFieldSchema);
    //create a new schema entry for the new custom field.. combine the already existing schema and add an object to it with new field
    this.mobxState.projectFieldSchema[fieldLevel] = {
      ...this.mobxState.projectFieldSchema[fieldLevel],
      ...newFieldSchema
    };
    //SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/custom_schemas/'+this.mobxState.projectFieldSchema.id, { [fieldLevel] : this.mobxState.projectFieldSchema[fieldLevel] }) //query the project custom field schema for all projects
      .then(response => {
        console.log(response);
      }).catch(console.log.bind(console)) //error catcher;
  }

  handleDeleteField = (fieldLevel, fieldName) => {
    console.log('Deleting: ', fieldLevel, fieldName, this.mobxState.projectFieldSchema);

    delete this.mobxState.projectFieldSchema[fieldLevel][fieldName];
    //SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/custom_schemas/'+this.mobxState.projectFieldSchema.id, { [fieldLevel] : this.mobxState.projectFieldSchema[fieldLevel] }) //query the project custom field schema for all projects
      .then(response => {
        console.log(response);
      }).catch(console.log.bind(console)) //error catcher;
  }

  //handles change in the projectFieldSchma by looking up the path of the property given in param with lodash and setting the property to the fieldValue
  handleFormChange = (propertyPath, fieldValue) => {
    console.log('handleFormChange', propertyPath, fieldValue);
    //set the value in schema
    _.set(this.mobxState.projectFieldSchema, propertyPath, fieldValue);
    //extract the fieldLevel in order to use it in api call
    let fieldLevel = extractToken(propertyPath, '.', 0);
    console.log(this.mobxState.projectFieldSchema[fieldLevel]);
    //SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/custom_schemas/'+this.mobxState.projectFieldSchema.id, { [fieldLevel] : this.mobxState.projectFieldSchema[fieldLevel] }) //query the project custom field schema for all projects
      .then(response => {
        console.log(response);
      }).catch(console.log.bind(console)) //error catcher;
  };

  //handles insights schema change
  handleInsightsFormChange = (propertyPath, fieldValue) => {
    //set the value in schema
    _.set(this.mobxState.insightsSchema, propertyPath, fieldValue);
    //extract the fieldLevel in order to use it in api call
    let fieldLevel = extractToken(propertyPath, '.', 0);
    console.log(this.mobxState.insightsSchema[fieldLevel]);
    //SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/custom_schemas/'+this.mobxState.insightsSchema.id, { [fieldLevel] : this.mobxState.insightsSchema[fieldLevel] }) //set entire schema_definition field in db to the mobxState which should be {categories: n}
      .then(response => {
        console.log(response);
      }).catch(console.log.bind(console)) //error catcher;
  };

  remapSchema = () => {
    console.log('remapping schema');
    axios
      .get('/v1/projects/remap_schema', { params: {} })
      .then(response => {
        console.log('remap_schema response', response);
        window.location.reload();
      })
      .catch(console.log.bind(console)); //error catcher
  };

  //Inas: handles projectProjectDropdownSchema
  handleProjectDropdownAdd = (fieldName, dropdownName) => {
    let arrayOptions = this.mobxState.projectDropdownSchema[fieldName][dropdownName];
    arrayOptions.push('');
    this.mobxState.projectDropdownSchema[fieldName][dropdownName] = arrayOptions.sort();

    // SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/custom_schemas/'+this.mobxState.projectDropdownSchema.id, { [fieldName] : this.mobxState.projectDropdownSchema[fieldName] }) //query the project dropdown
      .then(response => {
        this.fetchData();
        console.log(response);
      }).catch(console.log.bind(console)) //error catcher;
  };

  handleProjectDropdownSort = (fieldName, dropdownName) => {
    //sort incasesensitive
    let arrayOptions = this.mobxState.projectDropdownSchema[fieldName][dropdownName];
    arrayOptions = _.orderBy(arrayOptions, [arrayOptions => arrayOptions.toLowerCase()], ['asc']);
    this.mobxState.projectDropdownSchema[fieldName][dropdownName] = arrayOptions;

    // SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/custom_schemas/'+this.mobxState.projectDropdownSchema.id, { [fieldName] : this.mobxState.projectDropdownSchema[fieldName] }) //query the project dropdown
      .then(response => {
        console.log(response);
      }).catch(console.log.bind(console)) //error catcher;
  };

  handleProjectDropdownChange = (fieldName, dropdownName, dropdownValue, dropdownIndex) => {
    //set the value in schema
    this.mobxState.projectDropdownSchema[fieldName][dropdownName][dropdownIndex] = dropdownValue;

    // SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/custom_schemas/'+this.mobxState.projectDropdownSchema.id, { [fieldName] : this.mobxState.projectDropdownSchema[fieldName] }) //query the project dropdown
      .then(response => {
        console.log(response);
      }).catch(console.log.bind(console)) //error catcher;
  };

  handleProjectDropdownDelete = (fieldName, dropdownName, optionValue) => {
    let arrayOptions = this.mobxState.projectDropdownSchema[fieldName][dropdownName];
    arrayOptions = arrayOptions.filter(arrayOptions => arrayOptions !== optionValue);
    this.mobxState.projectDropdownSchema[fieldName][dropdownName] = arrayOptions;

    // SAVING SCHEMA CHANGES in db
    axios
      .put('/v1/custom_schemas/'+this.mobxState.projectDropdownSchema.id, { [fieldName] : this.mobxState.projectDropdownSchema[fieldName] }) //query the project dropdown
      .then(response => {
        this.fetchData();
      }).catch(console.log.bind(console)) //error catcher;

  }

  //////////////////END HTTP/////////////////

  render() {

    return (
      <div>

				<div className="jumbotron jumbotron_full_width" style={{maxHeight: '71.5px', overflow: 'visible', whiteSpace: 'nowrap'}}>
					<div className="jumbotron_full_width_container">
            <div className="row">
              <div className="col-sm-5">
                <span className="fa-layers fa-fw fa-2x">
                  <FontAwesomeIcon icon={['fas', 'circle']} transform="grow-3" color="#53C8C5" style={{marginTop: '4px'}} />
                  <FontAwesomeIcon icon={['fas', 'folders']} color="white" transform="shrink-5" style={{marginTop: '3px'}} />
                </span>
                &nbsp;
                <b className="dash_header">Edit Project Fields</b>
              </div>
			        <div className="col-sm-2"></div>
			        <div className="col-sm-2"></div>
			        <div className="col-sm-3 gps">
			          <FontAwesomeIcon
			            icon={["fad", "location-arrow"]}
			            color="#53C8C5"
			            transform="grow-15 left-10"
			            style={{}}
			          />
			          <Link to={"/sourcing/projects/"}>
			            Next: Manage Projects
			          </Link>
			        </div>
            </div>
					</div>
				</div>

				{/* PROJECT FORM */}
				<div className="row">
					<div className="col-sm-12">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg clearfix">
									<b>Project Fields</b>

									<span className="pull-right">
										{/* Constraint Saved Message
										<Bounce left when={this.mobxState.submitMessage}>
											<b className="text-success" style={{marginRight: '1.5em',marginTop: '4px'}}>
											  Constraint Saved
											</b>
							  		</Bounce>
										<Bounce left when={this.mobxState.formState.showHelperIcons}>
											<span>
												<FontAwesomeIcon icon={['fad', 'long-arrow-alt-right']} size='2x' transform="down-3 grow-10" color="#53C8C5" style={{marginRight:'15px'}}/>
											</span>
										</Bounce>
                    */}
										<button type="button" disabled={!this.mobxState.formState.formValid} className="btn btn-primary btn-md" onClick={() => this.handleAddField('schema_definition')}>
											<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Add Custom Field
										</button>
									</span>
							</div>
							<div className="panel-body">

								<div className="row">
									<div className="col-sm-12">
										<div className={'top_panel_info'}>Custom fields for all projects in the system. These fields describe or provide additional info about the project itself. Organizational metrics such as project savings, project category should be used here. </div>
									</div>
								</div>
								<br />

                {this.mobxState.projectFieldSchema &&
                  <SchemaForm
                    handleFormChange={this.handleFormChange}
                    handleDeleteField={this.handleDeleteField}
                    projectFieldSchema={this.mobxState.projectFieldSchema}
                    schemaLevel={'schema_definition'}
                  />
                }


							</div>
						</div>
					</div>{/* END col-sm-12*/}
				</div>{/* END row*/}

        {/* CATEGORIZATION FORM */}
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default">
              <div className="panel-heading panel_bg clearfix">
                <b>Insights Settings</b>
              </div>
              <div className="panel-body">
                <span>
                  <a data-toggle="collapse" href={'#modal_remap'}>
                    <span className="glyphicon glyphicon-remove-circle modal_glyphi text-danger" aria-hidden="true" style={{marginTop: '10px'}}></span>
                    <span> Remap Schema From eSourcing </span>
                  </a>
                </span>
                <div id={'modal_remap'} className="panel-collapse collapse">
                  <div>
                    This will delete the entire schema and import a new one. Are you sure there is no project data already saved with the current schema?
                    &nbsp;
                    <a data-toggle="collapse" href={'#modal_remap'} onClick={() => this.remapSchema()}>Yes</a>
                    &nbsp;&nbsp;
                    <a data-toggle="collapse" href={'#modal_remap'}>No </a>
                  </div>
                </div>
                <br/>
                <br/>
                <b>Number of categories in insights from 1 to 4</b>
                <div>
                  <InputNumber
                    type={'number'}
                    min={1}
                    max={4}
                    value={this.mobxState.insightsSchema.schema_definition.categories}
                    onChange={(e) => {this.handleInsightsFormChange('schema_definition.categories', e)}}
                  />
                </div>

              </div>
            </div>
          </div>{/* END col-sm-12*/}
				</div>{/* END row*/}

        {/* STATUS FORM */}
				<div className="row">
					<div className="col-sm-12">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg clearfix">
								<b>Project Status</b>
                <span className="pull-right">
									<button type="button" className="btn btn-primary btn-md" onClick={() => this.handleProjectDropdownAdd('schema_definition', 'status')}>
										<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Add Option
									</button>
								</span>
							</div>

							<div className="panel-body">

                {this.mobxState.projectDropdownSchema &&
                  <ProjectDropdownForm
                    handleFormBlur={this.handleProjectDropdownSort}
                    handleFormChange={this.handleProjectDropdownChange}
                    handleFormDelete={this.handleProjectDropdownDelete}
                    projectDropdownSchema={this.mobxState.projectDropdownSchema.schema_definition.status}
                    schemaLevel={'schema_definition'}
                    projectDropdown={'status'}
                  />
                }

							</div>
						</div>
					</div>
				</div>{/* END STATUS FORM*/}

        {/* DEPARTMENT FORM */}
        <div className="row">
					<div className="col-sm-12">
						<div className="panel panel-default">
							<div className="panel-heading panel_bg clearfix">
								<b>Project Department</b>
                <span className="pull-right">
									<button type="button" className="btn btn-primary btn-md" onClick={() => this.handleProjectDropdownAdd('schema_definition', 'department')}>
										<FontAwesomeIcon icon={['fas', 'plus']} />&nbsp; Add Option
									</button>
								</span>
							</div>

							<div className="panel-body">

                {this.mobxState.projectDropdownSchema &&
                  <ProjectDropdownForm
                    handleFormBlur={this.handleProjectDropdownSort}
                    handleFormChange={this.handleProjectDropdownChange}
                    handleFormDelete={this.handleProjectDropdownDelete}
                    projectDropdownSchema={this.mobxState.projectDropdownSchema.schema_definition.department}
                    schemaLevel={'schema_definition'}
                    projectDropdown={'department'}
                  />
                }

							</div>
						</div>
					</div>
				</div>{/* END DEPARTMENT FORM*/}

      </div>
    );
  }
}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(Schema, {
  mobxState: observable
});

export default observer(Schema);

// export default ProjectOverview;
