
//http requests
import axios from 'axios';

//extra utilities
const _ = require('lodash');

//get systemAliases for "custom_schemas" table and converts values for export in the spreadsheet i.e... price -> Bid Price etc...
//require argument is for only outputting strings to use in the spreadsheet import defaults as false
export const convertToSystemAliases = (table, nonCustomCols, require=false) => {
  return axios
    .get('/v1/custom_schemas', { params: { name: 'system_aliases' } }) //query the project custom field schema for all projects
    .then((response) => {
      // console.log("RESULTS ", response.data.custom_schemas[0].schema_definition, table, nonCustomCols)
      let customSchemaNames = response.data.custom_schemas[0].schema_definition
      let nonCustom = _.cloneDeep(nonCustomCols) //remove nested proxies
      console.log(nonCustom)
      //get field_name and field_alias for table after fetching from "custom_schemas" table
      let curentTableList = {}
      //get list of field names and aliases based on what table we need
      Object.entries(customSchemaNames).map(([key, value]) => {
        if(table === key){
          if(key === 'bid'){
            console.log("construct bid form")
            //combine item and bid lists
            curentTableList = [...customSchemaNames.bid, ...customSchemaNames.item]
            console.log(curentTableList)
          } else {
            curentTableList = [...value]
            console.log(curentTableList)
          }
        }
      })
      /*if a value has an alias replace it with the alias and remove the original.
      strings are for spreadsheet imports and objects for spreadsheet exports */
      let newAliasedArr = []
      console.log(curentTableList)
        Object.entries(curentTableList).map(([key, value]) => {
          for(let field of nonCustom) {
            if(require === true && field === value.field_name){
              _.pull(nonCustom, field)
              newAliasedArr.push(value.field_alias) //strings for item import
            } else if (require === false && field.field_alias === value.field_name) {
              _.pull(nonCustom, field)
              newAliasedArr.push({'field_alias': value.field_alias}) //objects for spreadsheet export
            }
          }
        })
      let aliasedHeaders = [...nonCustom, ...newAliasedArr]
      console.log(aliasedHeaders)
      return aliasedHeaders
    })
    .catch((error) => {
      console.log(error)
    })
};

//opposite of above function takes in aliased value and converts it to the corresponding system_field value i.e.. Item Name -> name
export const covertAliasToField = (table, formHeaders) => {
  return axios
    .get('/v1/custom_schemas', { params: { name: 'system_aliases' } }) //query the project custom field schema for all projects)
    .then((response) => {
      let customSchemaNames = response.data.custom_schemas[0].schema_definition
      //get field_name and field_alias for table after fetching from "custom_schemas" table
      let curentTableList = {}
      const bid = /\bbid\b/ //use regex to only allow 'bid'

      Object.entries(customSchemaNames).map(([key, value]) => {
        if(table === key){
          if (table.match(bid)) {
            //combine item and bid lists
            curentTableList = [...customSchemaNames.bid, ...customSchemaNames.item]
            console.log('on bids',curentTableList)
          }
           else {
            curentTableList = [...value]
            console.log('not on',curentTableList)
          }
        }
      })
      //convert values back to system field keep and keep the same order
      Object.entries(curentTableList).map(([key, value]) => {
        for(let [i, field] of formHeaders.entries()) {
          // console.log(i, field, key, value)
          if(_.lowerCase(field) === _.lowerCase(value.field_alias)){
            console.log("match", i, field, value.field_alias)
            formHeaders.splice(i, 1, value.field_name)
          }
        }
      })

      let convertedHeaders = [...formHeaders]

      return convertedHeaders
    }) //end of .then
    .catch((error) => {
      console.log(error)
    })
};

//get list of table's field_aliases (mainly used so an aliased column can be highlighted as a required field)
export const getAliasList = (table) => {
  return axios
    .get('/v1/custom_schemas', { params: { name: 'system_aliases' } }) //query the project custom field schema for all projects)
    .then((response) => {
      let customSchemaNames = response.data.custom_schemas[0].schema_definition
      //get field_name and field_alias for table after fetching from "custom_schemas" table
      let curentTableList = [] //changed from {} -- looks like Daniel set up the wrong variable type initially because this var is later mutated to an array
      const bid = /\bbid\b/ //use regex to only allow 'bid'

      Object.entries(customSchemaNames).map(([key, value]) => {
        if(table === key){
          console.log('In key', key);
          if (table.match(bid)) {
            //combine item and bid lists
            curentTableList = [...customSchemaNames.bid, ...customSchemaNames.item]
            console.log('on bids',curentTableList)
          }
           else {
            curentTableList = [...value]
            console.log('not on',curentTableList)
          }
        }
      })
      let tableAliasList = [];
      curentTableList.every(val => tableAliasList.push(val.field_alias)) //MISTAKE HERE curentTableList.every is not a function.. MAYBE ON CONSTRAINTS JUST DON'T CALL THIS FUNCTION
      return tableAliasList

    }) //end of .then
    .catch((error) => {
      console.log(error)
    })
}
