import React from 'react';
import Select from 'react-select'; //select box for filter
//http requests
import axios from 'axios';
//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
//utility
import {zeroNull, extractToken} from '../../services/Utilities';
//icons
import merge from '../../../assets/images/icons/merge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { element } from 'prop-types';

//handles rendring form components and their validation
class QualitativeConstraintSelection extends React.Component {

//taken from scope selection renamed to qulitative selection

  mobxState = {
    qualitativeSelect: [], //options for select box
    qualitativeValueSelect: [], //options for select box
    showQualitativeValueSelect: false, //conditional render for select box
    qualitativeValueDefaultValue: null, //default selected value
    qualitativeValueSelectedValue: null
  }

  //mount data through http requests
  componentDidMount() {
    //call the data loader to load data into the view
    this.fetchData();
  }
  

  //CHECK PROJECT ID

  //////////////////HTTP/////////////////
  //data loader
  
  fetchData = () => {
   
    ////////////////////
    //FORM LOAD
    //QUALITATIVES:
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/qualitative_constraints', {params: {} })
    .then((response) => {
      this.mobxState.qualitativeSelect = response.data.bids;  
    });
    //END FORM LOAD
    //////////////////

  } //end fetchData()

  //handle selecting the qualitative in the dropdown and update qualitative_value dropdown in the state


  handleQualitativeSelection = (selectedQualitative) => {

    console.log('selectedQualitative ', selectedQualitative.value);
    //don't show dropdown for qualitative_value on overall qualitative
    if (selectedQualitative.value != 'overall') {
      this.mobxState.showQualitativeValueSelect = true;
    } else {
      //hide dropdown
      this.mobxState.showQualitativeValueSelect = false;
    }

    //update form state in parent component
    this.props.handleFormChange('custom_field_name', selectedQualitative);

    //NEED TO GIVE REACT SELECT DEFAULT VALUE
    //clear qualitative_value selected state in parent and here in child because switching qualitatives should clear selection for a new qualitativeValue list related to that qualitative
    this.mobxState.qualitativeValueSelectedValue = null;
    this.props.handleFormChange('custom_field_value', {name:null});

    //get the qualitativeValue dropdown list by sending a request with the selected qualitative in the params
    axios.get('/microservices/v1/projects/'+this.props.projectId+'/constraints/qualitative_values', {params: {attribute: selectedQualitative.value} })
    .then((response) => {
      //console.log(response.data.qualitative_values)
      this.mobxState.qualitativeValueSelect = response.data.list_values; //sorted values for dropdown from micro service
    });
  }

  //handle local state and callback
  handleQualitativeValueSelection = (qualitativeName, selectedQualitativeValue) => {
    //change the state in the parent with callback
    this.props.handleFormChange(qualitativeName, selectedQualitativeValue);
    //local state, in order to handle the value
    this.mobxState.qualitativeValueSelectedValue = selectedQualitativeValue;
  }

  render(){
    console.log(this.mobxState.qualitativeSelect)
    // const validate = value => {
    //   return !value || value.length < 3 ? 'Must be at least three characters' : null;
    // }
    
    return (
      <div>
        <div className={this.props.scopeCssClass}> {/* kept css styles unchanged to maintain consistency */}
          <div className="row">
            <div className="col-lg-2">
              <div>
                <FontAwesomeIcon icon={['fad', 'columns']} mask={['fas', 'circle']} size='4x' transform="shrink-6" color="#4CABA9" style={{marginRight:'20px'}}/>
              </div>
            </div>
            <div className="col-lg-10">
              {/* main form that is always rendered */}
              <b>Custom Fields</b>
              <Select
                onChange={this.handleQualitativeSelection}
                options={this.mobxState.qualitativeSelect}
                closeMenuOnSelect={true}
                maxMenuHeight = {400}
                placeholder={'Select custom fields'}
              />
            </div>
          </div>
        </div>

        <div className={this.props.scopeValueCssClass}>

        {/* Dependent dropdown menu that appears after selection is made in the first menu */}
          {this.mobxState.showQualitativeValueSelect && 

              <div>
                <div className={'pull-left'}>
                  <img src={merge} style={{transform: 'rotate(90deg)', marginTop: '25px'}} />
                </div>

                <div className={''}>
                  <b>Custom Field Value</b>
                  <Select
                    onChange={(e) => this.handleQualitativeValueSelection('custom_field_value', e)}
                    options={this.mobxState.qualitativeValueSelect}
                    closeMenuOnSelect={true}
                    filterOption={() => true}
                    maxMenuHeight = {400}
                    placeholder={'Specify data value to apply constraint to'}
                    defaultValue={this.mobxState.qualitativeValueDefaultValue}
                    value={this.mobxState.qualitativeValueSelectedValue}
                  />
                </div>
              </div>
          }
        </div>
      </div>
    )
  }

}

// when using decorate, all fields should be specified (a class might have many more non-observable internal fields after all)
decorate(QualitativeConstraintSelection, {
	mobxState: observable
})

export default observer(QualitativeConstraintSelection);

// export default QualitativeConstraintSelection
