import React from 'react';
import { Modal } from 'react-bootstrap';
import { render } from 'react-dom';

//filters
import DockRenderer from '../../../../filters/DockRenderer';
import FilterQuickAction from '../../../../filters/FilterQuickAction'; //filter controls
import Select, { components } from 'react-select'; //select box for filter

import ReactDom from 'react-dom';

//ag grid
import GridRenderer from '../../../../grids/GridRenderer';

//http requests
import axios from 'axios';

//state
import { decorate, observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';

//utility
import { zeroNull } from '../../../../services/Utilities';
const _ = require('lodash'); //extra utilities

// https://github.com/JedWatson/react-select/issues/2597
// Inserts a button to configure element into multiselect value (shown when menu is not open)

export const MultiValueContainer = callback => props => {
  let ConfigureButton = ConfigureButtonFactory(props.data)(callback);

  return (
    <components.MultiValueContainer {...props}>
      {/* Label for the select */}
      {props.children[0]}
      {/* Edit Button */}
      <ConfigureButton {...props} />
      {/* Remove Button */}
      {props.children[1]}
    </components.MultiValueContainer>
  );
};

const ConfigureButtonFactory = data => callback => props => {
  const mouseDown = event => {
    event.stopPropagation(); // Swallow event so react-select doesn't open menu
    return callback(data);
  };
  return (
    <div className='custom-react-select-configure-button' onMouseDown={mouseDown}>
      <div className='glyphicon glyphicon-cog' />
    </div>
  );
};
